import { useMemo } from 'react';
import propTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { Label, Select } from '@kiper/ui';
import gql from 'graphql-tag';

export default function PlaceSelect({
  sourceNodeId,
  fieldName,
  usePlaceId,
  invalid,
  isEditable,
  isDisabled,
  value,
  disabledValues,
  ...props
}) {
  const filters = { sourceNodeId, fieldName, usePlaceId };

  const { data, loading } = useQuery(PlaceSelect.fetchQuery, {
    fetchPolicy: 'no-cache',
    skip: !sourceNodeId,
    variables: {
      filters,
    },
  });

  const places = useMemo(() => {
    if (!data) return [];
    return data.placeSelect?.map(i => ({
      ...i,
      isDisabled: disabledValues?.some(j => j.value === Number(i.value)),
    }));
  }, [data, disabledValues]);

  if (!isEditable) return <Label>{value && value.label}</Label>;

  const getValue = () => {
    return value &&
      data?.placeSelect?.length &&
      (typeof value === 'number' || typeof value === 'string')
      ? data.placeSelect.find(i => Number(i.value) === Number(value))
      : value;
  };

  return (
    <Select
      {...props}
      isDisabled={!sourceNodeId || isDisabled}
      isLoading={loading}
      options={places}
      invalid={Number(invalid)}
      value={getValue()}
    />
  );
}

PlaceSelect.fetchQuery = gql`
  query placeSelect($filters: PlaceSelectQueryInput!) {
    placeSelect(filters: $filters) {
      value
      label
    }
  }
`;
PlaceSelect.propTypes = {
  sourceNodeId: propTypes.number,
  fieldName: propTypes.arrayOf(propTypes.string).isRequired,
  usePlaceId: propTypes.bool,
  invalid: propTypes.bool,
  name: propTypes.string.isRequired,
  value: propTypes.any,
  isEditable: propTypes.bool,
  disabledValues: propTypes.array,
  isDisabled: propTypes.bool,
};

PlaceSelect.defaultProps = {
  sourceNodeId: undefined,
  isDisabled: false,
  invalid: false,
  usePlaceId: false,
  isEditable: true,
  value: '',
  disabledValues: [],
};
