import { useEffect, useMemo, useRef, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from 'react-apollo';
import { Table, TableLoader } from '@kiper/ui';
import { invite as inviteGql } from '@kiper/monitoring-graphql';
import { useFilters } from '@kiper/hooks';
import { Container, TableContainer } from './styles';
import emptyStatePlaceholder from '../../../assets/images/empty-state-users.svg';
import Pagination from '../../../components/Pagination';
import PageActions from '../../../components/PageActions';
import EmptyState from '../../../components/EmptyState';
import ListItem from './ListItem';
import useCurrentLoggedContext from '../../../hooks/useCurrentLoggedContext';
import { userProfile } from '../../../constants';

const column = x => `list.table-header.${x}`;

const initialFilters = {
  searchText: '',
  page: 1,
  pagesize: 10,
  orderby: 'start',
  ordination: true,
};
export default function List({ route }) {
  const [t] = useTranslation('invite');
  const [emptyList, setEmptyList] = useState(true);

  const { loggedContext } = useCurrentLoggedContext();

  const {
    filters,
    types,
    handlePageChange,
    handlePageSize,
    handleChange,
    handleSort,
  } = useFilters({
    ...initialFilters,
    sourceNodeId: loggedContext.topNodeId,
  });

  const columns = useRef([
    {
      name: t(column('condominium')),
      align: 'left',
      width: '20%',
      order: 'condominium.name',
      type: types.STRING,
    },
    {
      name: t(column('host-name')),
      align: 'left',
      width: '30%',
      order: 'host.name',
      type: types.STRING,
    },
    {
      name: t(column('event-title')),
      align: 'left',
      width: '20%',
      order: 'name',
      type: types.STRING,
    },
    {
      name: t(column('event-start')),
      align: 'left',
      width: '15%',
      order: 'start',
      type: types.DATE_TIME,
    },
    {
      name: t(column('event-end')),
      align: 'left',
      width: '15%',
      order: 'end',
      type: types.DATE_TIME,
    },
  ]);

  const [getInvites, { data, loading }] = useLazyQuery(
    inviteGql.invitesPaginated,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        setEmptyList(false);
      },
    },
  );

  const userProfilesWithCondominiumAsPartner =
    loggedContext?.profileId === userProfile.doorman ||
    loggedContext?.profileId === userProfile.condominiumMaster;

  useEffect(() => {
    const { searchText, sourceNodeId } = filters;
    if (
      !!searchText ||
      sourceNodeId !== loggedContext.topNodeId ||
      userProfilesWithCondominiumAsPartner
    )
      getInvites({ variables: { filters } });
    else setEmptyList(true);
  }, [filters]);

  const {
    invitesPaginated: { collection, pagination },
  } = useMemo(
    () => data ?? { invitesPaginated: { collection: [], pagination: {} } },
    [data],
  );

  return (
    <Container>
      <PageActions
        filters={filters}
        handleChange={handleChange}
        route={route}
        entity="invite"
      />
      {(emptyList && !loading) || (!collection?.length && !loading) ? (
        <EmptyState
          placeholder={emptyStatePlaceholder}
          text={emptyList ? t('list.empty-state-init') : t('list.empty-state')}
        />
      ) : (
        <TableContainer>
          <Table
            columns={columns}
            sort={{
              handleSort,
              ordination: filters.ordination,
              orderBy: filters.orderBy,
            }}
          >
            {!loading && !!collection.length && (
              <tbody>
                {collection.map(item => (
                  <ListItem key={item.id} data={item} />
                ))}
              </tbody>
            )}
          </Table>
          {loading && <TableLoader />}
          <Pagination
            filters={filters}
            pagination={pagination}
            handlePageSize={handlePageSize}
            next={() => !!pagination?.hasNext && handlePageChange(1)}
            previous={() => !!pagination?.hasPrevious && handlePageChange(-1)}
          />
        </TableContainer>
      )}
    </Container>
  );
}

List.propTypes = {
  route: propTypes.object.isRequired,
};
