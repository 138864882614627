import { useCallback, useState } from 'react';
import propTypes from 'prop-types';
import { useMutation } from 'react-apollo';
import { Trans, useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { useSwal } from '@kiper/hooks';
import { apolloErrorHandler } from '@kiper/fns';
import { Flex, Text, CheckBox } from '@kiper/ui';
import { mutation } from '@kiper/monitoring-graphql/access_fingerprint';
import { queries } from '@kiper/monitoring-graphql/dweller/detail';
import { colors } from '@kiper/theme';
import Modal from '../Modal';
import { HandStyle, HandWithFingerStyle, Label } from './styles';
import Hand from './Hand';
import LeftHand from './LeftHand';
import RightHand from './RightHand';
import Instructions from './Instructions';
import * as S from './styles';

const fingers = [];
const fingersRegistered = new Array(10).fill(null);
export default function GenericFingerprintModal({
  open,
  onToggle,
  onToggleParent,
  deviceId,
  personId,
  refetchDevices,
}) {
  const [t] = useTranslation('user');
  const { toast } = useSwal();

  const [registerFeedback, setRegisterFeedback] = useState({
    message: '',
    status: '',
  });
  const [handSelected, setHandSelected] = useState(-1);
  const [readingFinger, setReadingFinger] = useState(false);
  const [deletingFinger, setDeletingFinger] = useState(false);
  const [instruction, setInstruction] = useState(true);
  const [panic, setPanic] = useState(false);

  const queryArgs = {
    query: queries.dweller,
    variables: {
      personId: +personId,
    },
  };

  const [register] = useMutation(mutation.registerFingerprint, {
    refetchQueries: [{ ...queryArgs }],
    onCompleted: ({ registerFingerprint }) => {
      if (registerFingerprint.response.status === 'success') {
        refetchDevices();
        const successMessage = t(
          'put.devices.modal.instructions.feedback-register-success',
        );
        setRegisterFeedback({
          message: successMessage,
          status: registerFingerprint.response.status,
        });
        toast.fire({
          title: successMessage,
          icon: 'success',
        });
        onToggle(false);
        onToggleParent(false);
      } else {
        const errorMessage = t(
          'put.devices.modal.instructions.feedback-register-error',
        );

        setReadingFinger(false);
        setRegisterFeedback({
          message: errorMessage,
          status: registerFingerprint.response.status,
        });
        toast.fire({
          title: errorMessage,
          icon: 'error',
        });
      }
      setReadingFinger(false);
    },
    onError: err => {
      setReadingFinger(false);
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({
          title: formattedErrors.join('\n'),
          icon: 'error',
        });
      }
    },
  });

  const handleToggleModal = () => {
    onToggle(false);
    onToggleParent(false);
    setReadingFinger(false);
  };

  const captureFingerprint = useCallback(
    index => {
      setReadingFinger(true);

      register({
        variables: {
          personId,
          deviceId,
          fingerIndex: index,
          panic,
        },
      });
    },
    [readingFinger, panic],
  );

  const removeFingerprint = () => {
    setDeletingFinger(true);
  };

  const handleFingerprintClicked = index => {
    setRegisterFeedback({
      status: '',
      message: '',
    });

    if (readingFinger || deletingFinger) {
      return;
    }

    const fingerFound = fingers.find(finger => finger.index === index);
    if (fingerFound) {
      removeFingerprint(fingerFound);
      return;
    }

    captureFingerprint(index);
  };

  const renderSelectHand = () => {
    return (
      <>
        <Row>
          <Col md="12">
            <Flex flex={1} justifyContent="center">
              <Text className="mt-2 mb-2">
                {t('put.devices.modal.instructions.select-hand')}
              </Text>
            </Flex>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <HandStyle hover>
              <Hand left onClick={() => setHandSelected(0)} />
              <Text>{t('put.devices.modal.instructions.left-hand')}</Text>
            </HandStyle>
          </Col>
          <Col md="6">
            <HandStyle hover>
              <Hand onClick={() => setHandSelected(1)} />
              <Text fontSize="16px" lineHeight="20px">
                {t('put.devices.modal.instructions.right-hand')}
              </Text>
            </HandStyle>
          </Col>
        </Row>
      </>
    );
  };

  const HandWithFingerSelector = ({ selectedHand }) => {
    const handProps = {
      t,
      fingersRegistered,
      readingFinger,
      deletingFinger,
      handleFingerprint: handleFingerprintClicked,
    };

    return (
      <Flex
        gridGap="32px"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Row>
          <Flex
            width="100%"
            flex={1}
            alignItems="center"
            justifyContent="center"
            padding="0 16px"
          >
            <Text
              textAlign="center"
              fontSize="14px"
              lineHeight="20px"
              color={colors.blackShades(0.8)}
            >
              <Trans>{t('put.devices.modal.instructions.select-finger')}</Trans>
            </Text>
          </Flex>
        </Row>
        <Row>
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gridGap="16px"
            padding="0 16px"
          >
            <S.PanicCheckArea
              gridGap="8px"
              flex={1}
              alignItems="center"
              justifyContent="center"
            >
              <CheckBox
                disabled={readingFinger}
                id="panic"
                className="form-control"
                name="panic"
                checked={panic}
                onChange={e => setPanic(e.target.checked)}
              />
              <Label for="panic" info>
                {t('put.devices.modal.instructions.panic')}
              </Label>
            </S.PanicCheckArea>
            <HandWithFingerStyle>
              {selectedHand === 'left' && <LeftHand {...handProps} />}
              {selectedHand === 'right' && <RightHand {...handProps} />}
            </HandWithFingerStyle>
            {registerFeedback.status && (
              <Text
                textAlign="center"
                fontSize="14px"
                lineHeight="16px"
                color={
                  registerFeedback.status === 'success'
                    ? colors.primary500
                    : colors.dangerPrimary
                }
              >
                {registerFeedback.message}
              </Text>
            )}
          </Flex>
        </Row>
      </Flex>
    );
  };

  HandWithFingerSelector.propTypes = {
    selectedHand: propTypes.oneOf(['left', 'right']).isRequired,
  };

  if (instruction) {
    return (
      <Modal
        title={t('put.devices.modal.insert.fingerprint-insert')}
        open={open}
        toggle={handleToggleModal}
      >
        <Instructions setInstruction={setInstruction} />
      </Modal>
    );
  }

  return (
    <Modal
      size="md"
      title={`${t('put.devices.modal.insert.fingerprint-insert')}`}
      open={open}
      toggle={handleToggleModal}
    >
      <>
        {handSelected < 0 && renderSelectHand()}
        {handSelected === 0 && <HandWithFingerSelector selectedHand="left" />}
        {handSelected === 1 && <HandWithFingerSelector selectedHand="right" />}
      </>
    </Modal>
  );
}

GenericFingerprintModal.propTypes = {
  open: propTypes.bool.isRequired,
  onToggle: propTypes.func.isRequired,
  refetchDevices: propTypes.func.isRequired,
  onToggleParent: propTypes.func.isRequired,
  deviceId: propTypes.number.isRequired,
  personId: propTypes.number.isRequired,
};
