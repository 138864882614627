import gql from 'graphql-tag';

export const responseFingerprintFeedbackFragment = gql`
  fragment responseFeedbackParts on ResponseFeedback {
    status
    message
  }
`;

export const responseFingerprintFragment = gql`
  fragment responseFingerprintParts on ResponseFingerprint {
    response {
      ...responseFeedbackParts
    }
  }
  ${responseFingerprintFeedbackFragment}
`;
