import { useMemo } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-apollo';
import { person as personGql } from '@kiper/monitoring-graphql';
import { Label, Select } from '@kiper/ui';
import useCurrentLoggedContext from '../../../hooks/useCurrentLoggedContext';

import { GroupLabelContainer, GroupBadge } from './styles';

function EventSettingsOrigin({
  sourceNodeId,
  isClearable,
  invalid,
  value,
  fieldName,
  usePersonId,
  isEditable,
  ...props
}) {
  const { loggedContext } = useCurrentLoggedContext();
  const [t] = useTranslation('event-settings');

  const { data, loading } = useQuery(personGql.personSelect, {
    variables: {
      filters: {
        fieldName,
        sourceNodeId,
        usePersonId,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  if (!isEditable) return <Label>{value && value.label}</Label>;

  const groupedOptions = useMemo(
    () => [
      {
        value: Number(loggedContext?.partner?.personContextId),
        label: loggedContext?.partner?.name,
        contextId: Number(loggedContext?.partner?.personContextId),
      },
      {
        label: t('list.condominiums'),
        options: data?.personSelect || [],
        grouped: true,
      },
    ],
    [data, loggedContext],
  );

  const formatGroupLabel = group => (
    <>
      {group.grouped && (
        <GroupLabelContainer>
          <span>{group.label}</span>
          <GroupBadge>{group.options.length}</GroupBadge>
        </GroupLabelContainer>
      )}
    </>
  );

  const getValue = () => {
    if (value && (typeof value === 'number' || typeof value === 'string')) {
      return data?.personSelect.find(
        x => Number(x.contextId) === Number(value),
      );
    }

    return value;
  };

  return (
    <Select
      {...props}
      value={getValue()}
      options={groupedOptions}
      formatGroupLabel={formatGroupLabel}
      isLoading={loading}
      invalid={Number(invalid)}
    />
  );
}

export default EventSettingsOrigin;

EventSettingsOrigin.propTypes = {
  sourceNodeId: propTypes.number.isRequired, // nó de início da busca
  fieldName: propTypes.arrayOf(propTypes.string).isRequired,
  value: propTypes.any,
  usePersonId: propTypes.bool,
  onSelect: propTypes.func,
  isClearable: propTypes.bool,
  invalid: propTypes.bool,
  isEditable: propTypes.bool,
};

EventSettingsOrigin.defaultProps = {
  onSelect: null,
  isClearable: false,
  usePersonId: false,
  invalid: false,
  isEditable: true,
  value: null,
};
