import { Fragment, useState, useEffect } from 'react';
import { dweller as dwellerGql } from '@kiper/monitoring-graphql';
import { Divider, Flex, Label } from '@kiper/ui';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-apollo';
import { FormTemporaryAccess, TemporaryAccessItem } from './components';
import AddMore from '../../../AddMore';
import { AddMoreContainer } from '../../../styles';

const PlaceTemporaryAccess = ({
  values,
  isEditable,
  onChange,
  errors,
  personContextId,
}) => {
  const [t] = useTranslation('user');

  const [
    globalAvailablePlacesOptions,
    setGlobalAvailablePlacesOptions,
  ] = useState([]);

  const { loading: loadingPlaces, refetch } = useQuery(
    dwellerGql.detail.queries.temporaryAccessPlaces,
    {
      fetchPolicy: 'network-only',
      skip: !personContextId,
      variables: {
        personContextId,
      },
      onCompleted: data => {
        if (!data) return;

        const places = data?.temporaryAccessPlaces?.map(place => ({
          value: place.id,
          label: place.name,
        }));
        setGlobalAvailablePlacesOptions(places);
      },
    },
  );

  useEffect(() => {
    if (isEditable) refetch();
  }, [isEditable]);

  const handleChange = (index, value) => {
    const newValues = [...values];
    newValues[index] = value;
    onChange('placeTemporaryAccess', newValues);
  };

  const handleCreateNewTemporaryAccess = () => {
    const newValues = [...values];

    const newTemporaryAccess = {
      place: [],
      startDate: '',
      endDate: '',
    };

    newValues.push(newTemporaryAccess);
    onChange('placeTemporaryAccess', [...newValues]);
  };

  const handleRemoveSelection = removedOption =>
    setGlobalAvailablePlacesOptions(prevState => [removedOption, ...prevState]);

  const handleAddSelection = selectionValue =>
    setGlobalAvailablePlacesOptions(prevState =>
      prevState.filter(option => option.value !== selectionValue),
    );

  const handleRemoveTemporaryAccessItem = ({
    index,
    deletedSelectedOptions,
  }) => {
    setGlobalAvailablePlacesOptions(prevState => [
      ...deletedSelectedOptions,
      ...prevState,
    ]);
    const newValues = [...values];
    newValues.splice(index, 1);
    onChange('placeTemporaryAccess', newValues);
  };

  return (
    <Flex flexDirection="column" gridGap="8px" pb="24px">
      <Label info block $withoutMarginBottom>
        {t('put.access-data.place-temporary-access.title')}
      </Label>
      <Flex flexDirection="column">
        {!isEditable ? (
          <Flex flexDirection="column" gridGap="8px">
            {values.map((value, index, self) => (
              <Fragment key={value.startDate + value.endDate}>
                <TemporaryAccessItem t={t} value={value} />
                {index < self.length - 1 && <Divider />}
              </Fragment>
            ))}
          </Flex>
        ) : (
          <Flex flexDirection="column" gridGap="4px" width="100%">
            <Flex width="100%" flexDirection="column">
              {values.map((value, index, self) => (
                <Fragment key={value.key}>
                  <FormTemporaryAccess
                    t={t}
                    availableOptionsPlaces={globalAvailablePlacesOptions}
                    temporaryAccessValue={value}
                    onChange={itemValue => handleChange(index, itemValue)}
                    onDelete={deletedSelectedOptions =>
                      handleRemoveTemporaryAccessItem({
                        index,
                        deletedSelectedOptions,
                      })
                    }
                    loadingPlaces={loadingPlaces}
                    onAddSelection={handleAddSelection}
                    onRemoveSelection={handleRemoveSelection}
                    errors={errors?.placeTemporaryAccess?.[index]}
                  />
                  {index < self.length - 1 && <Divider />}
                </Fragment>
              ))}
            </Flex>
            <AddMoreContainer>
              <AddMore
                text={t('put.access-data.place-temporary-access.add-more')}
                onClick={handleCreateNewTemporaryAccess}
              />
            </AddMoreContainer>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

PlaceTemporaryAccess.propTypes = {
  isEditable: propTypes.bool.isRequired,
  onChange: propTypes.func.isRequired,
  errors: propTypes.any,
  personContextId: propTypes.any.isRequired,
  values: propTypes.arrayOf(
    propTypes.shape({
      place: propTypes.arrayOf(
        propTypes.shape({
          id: propTypes.any.isRequired,
          name: propTypes.string.isRequired,
        }),
      ),
      startDate: propTypes.string,
      endDate: propTypes.string,
    }),
  ),
};

PlaceTemporaryAccess.defaultProps = {
  values: [],
  errors: {},
};

export default PlaceTemporaryAccess;
