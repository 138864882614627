import { useEffect, useState } from 'react';

import firstBy from 'thenby';
import { eventGenerator } from '@kiper/monitoring-graphql';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from 'react-apollo';

const useEventGeneratorQueries = () => {
  const [eventTemplateOptions, setEventTemplateOptions] = useState([]);
  const [condominium, setCondominium] = useState(null);
  const [t] = useTranslation('event-generator');

  const [getEventTemplates, { loading: loadingEventTemplates }] = useLazyQuery(
    eventGenerator.query.eventTemplates,
    {
      onCompleted: ({ eventTemplates }) => {
        const formatEventTemplates = eventTemplates?.map(template => ({
          ...template,
          description: `${template.eventType} - ${template.description}`,
          template: template?.template ? JSON.parse(template.template) : {},
        }));

        setEventTemplateOptions(() =>
          [
            { eventType: 102, description: t('event.102') },
            ...formatEventTemplates,
          ].sort(firstBy('eventType')),
        );
      },
    },
  );

  useEffect(() => {
    if (condominium) {
      getEventTemplates();
    }
  }, [condominium]);

  return {
    eventTemplateOptions,
    condominium,
    setCondominium,
    loadingEventTemplates,
  };
};

export default useEventGeneratorQueries;
