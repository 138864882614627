import { useEffect, useMemo } from 'react';
import propTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { Label, Select } from '@kiper/ui';
import gql from 'graphql-tag';
import { useFilterSearchParams } from '@kiper/hooks';

const mapperOptions = data => {
  return data.map(x => ({ ...x, personContextId: x.contextId }));
};

const PersonSelect = ({
  sourceNodeId,
  isClearable,
  autoSingleOptionSelection,
  invalid,
  value,
  fieldName,
  usePersonId,
  isEditable,
  inAttendance,
  additionalOptions,
  isSearchFilter,
  filterLinkedFields,
  name,
  onChange,
  ...props
}) => {
  const { data, loading, refetch } = useQuery(PersonSelect.fetchQuery, {
    variables: {
      filters: {
        fieldName,
        sourceNodeId,
        usePersonId,
        inAttendance,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const options = useMemo(
    () => [
      ...additionalOptions,
      ...(data?.personSelect ? mapperOptions(data.personSelect) : []),
    ],
    [data, additionalOptions],
  );

  const {
    appendParam,
    removeParam,
    setSelectFilterValueFromQuery,
  } = useFilterSearchParams({
    key: name,
    options,
    filterLinkedFields,
    onChange: ({ queryValue }) => onChange(queryValue),
  });

  useEffect(() => {
    if (isSearchFilter) setSelectFilterValueFromQuery();
    return () => null;
  }, [data]);

  useEffect(() => {
    if (autoSingleOptionSelection && options.length === 1) {
      appendParam({ queryValue: options[0].value });
      onChange(options[0]);
    }
  }, [options]);

  if (!isEditable) return <Label>{value && value.label}</Label>;

  const handleChange = eventData => {
    if (isSearchFilter) {
      if (eventData?.value) {
        appendParam({ queryValue: eventData.value });
      } else {
        removeParam();
        refetch();
      }
    }

    if (onChange) onChange(eventData);
  };

  return (
    <Select
      value={
        options.find(i => Number(i.value) === Number(value)) || value || null
      }
      onChange={handleChange}
      isClearable={isClearable}
      isLoading={loading}
      options={options}
      invalid={Number(invalid)}
      {...props}
    />
  );
};

PersonSelect.propTypes = {
  sourceNodeId: propTypes.number.isRequired, // nó de início da busca
  fieldName: propTypes.arrayOf(propTypes.string).isRequired,
  value: propTypes.any,
  usePersonId: propTypes.bool,
  onSelect: propTypes.func,
  onChange: propTypes.func,
  isClearable: propTypes.bool,
  invalid: propTypes.bool,
  isEditable: propTypes.bool,
  inAttendance: propTypes.bool,
  isSearchFilter: propTypes.bool,
  autoSingleOptionSelection: propTypes.bool,
  additionalOptions: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string.isRequired,
      value: propTypes.number.isRequired,
      personContextId: propTypes.number.isRequired,
    }),
  ),
  name: propTypes.string,
  filterLinkedFields: propTypes.array,
};

PersonSelect.defaultProps = {
  onSelect: null,
  onChange: null,
  isClearable: false,
  autoSingleOptionSelection: false,
  usePersonId: false,
  invalid: false,
  isEditable: true,
  isSearchFilter: false,
  value: null,
  inAttendance: false,
  additionalOptions: [],
  name: '',
  filterLinkedFields: [],
};

PersonSelect.fetchQuery = gql`
  query person($filters: PersonSelectQueryInput!) {
    personSelect(filters: $filters) {
      value
      label
      contextId
    }
  }
`;

export default PersonSelect;
