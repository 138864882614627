import { useEffect, useMemo } from 'react';
import { useLazyQuery } from 'react-apollo';
import { queries } from '@kiper/monitoring-graphql/triage';
import { useGetDescriptions } from '../../QuestionsAndAnswers/hooks';

const useGetSubtriages = ({ filteredTriage, filteredSubtriage }) => {
  const [getSubtriages, { data, loading }] = useLazyQuery(queries.subtriages, {
    fetchPolicy: 'cache-first',
  });
  const { handleGetDescription } = useGetDescriptions();

  const handleMoveSelectedToFirstPosition = (array, selectedItem) => {
    if (!array?.length) return [];
    if (!selectedItem) return array;

    const filteredArray = array?.filter(item => item?.id !== selectedItem?.id);

    return [selectedItem, ...filteredArray];
  };

  const subtriages = useMemo(() => {
    const modifiedSubtriages = data?.subtriages?.map(subtriage => ({
      ...subtriage,
      description: handleGetDescription(subtriage),
    }));

    return handleMoveSelectedToFirstPosition(
      modifiedSubtriages,
      filteredSubtriage,
    );
  }, [data]);

  useEffect(() => {
    if (filteredTriage?.id) {
      getSubtriages({
        variables: {
          triageId: +filteredTriage?.id,
        },
      });
    }
  }, [filteredTriage]);

  return { subtriages, loading };
};

export default useGetSubtriages;
