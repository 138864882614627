import { useMemo } from 'react';

export const useFormPlacesSelect = ({
  onChange,
  onRemoveSelection,
  onAddSelection,
  temporaryAccessValue,
  availableOptionsPlaces,
}) => {
  const selectedPlacesOptions = useMemo(
    () =>
      temporaryAccessValue.place.map(place => ({
        value: place.id,
        label: place.name,
        temporaryAccessId: place.temporaryAccessId ?? null,
      })),
    [temporaryAccessValue?.place, availableOptionsPlaces],
  );

  const individualPlaceOptions = useMemo(
    () => [...selectedPlacesOptions, ...availableOptionsPlaces],
    [availableOptionsPlaces, selectedPlacesOptions],
  );

  const handleChangePlaces = changedPlaceValue =>
    onChange({
      ...temporaryAccessValue,
      place: changedPlaceValue.map(place => ({
        id: place.value,
        name: place.label,
        temporaryAccessId: place.temporaryAccessId ?? null,
      })),
    });

  const placesOptionsControl = {
    handleAddSelection: value => {
      handleChangePlaces([
        ...selectedPlacesOptions,
        availableOptionsPlaces.find(place => place.value === value),
      ]);
      onAddSelection(value);
    },
    handleRemoveSelection: value => {
      const removedOption = selectedPlacesOptions.find(
        place => place.value === value,
      );
      onRemoveSelection(removedOption);

      const withoutRemovedPlace = selectedPlacesOptions.filter(
        place => place.value !== value,
      );
      handleChangePlaces(withoutRemovedPlace);
    },
  };

  const placesId = `places-${crypto.randomUUID()}`;

  return {
    selectedPlacesOptions,
    individualPlaceOptions,
    placesOptionsControl,
    placesId,
  };
};
