import styled, { css } from 'styled-components';
import { Col as col, Input as input } from 'reactstrap';
import { PlaceHolder as placeholder, Flex, Text } from '@kiper/ui';
import {
  FaRegCalendar,
  FaSignInAlt,
  FaRegUserCircle,
  FaMapMarkerAlt,
  FaChevronDown,
  FaChevronUp,
  FaRegBell,
} from 'react-icons/fa';

import { FiPhone } from 'react-icons/fi';

export const CalendarIcon = styled(FaRegCalendar)`
  margin-right: 10px;
  color: ${props => props.theme.colors.highBlack};
`;

export const DwellerIcon = styled(FaSignInAlt)`
  margin-right: 10px;
  color: ${props => props.theme.colors.highBlack};
`;

export const AttendantIcon = styled(FaRegUserCircle)`
  margin-right: 10px;
  color: ${props => props.theme.colors.highBlack};
`;

export const LocationIcon = styled(FaMapMarkerAlt)`
  margin-right: 10px;
  color: ${props => props.theme.colors.highBlack};
`;

export const ChevronDownIcon = styled(FaChevronDown)`
  color: ${props => props.theme.colors.highBlack};
`;

export const ChevronUpIcon = styled(FaChevronUp)`
  color: ${props => props.theme.colors.highBlack};
`;

export const BellIcon = styled(FaRegBell)``;

export const PhoneIcon = styled(FiPhone)``;

export const Container = styled.div`
  max-height: 688px;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

export const Col = styled(col)`
  margin-bottom: 15px;
  padding: 0;
  position: sticky;
  z-index: 1;
  top: 0;
`;

export const Input = styled(input)`
  border-color: ${props => props.theme.colors.secondary400};
  width: 100% !important;
  ::placeholder {
    color: ${props => props.theme.colors.mediumBlack};
  }
`;

export const EventRowContainer = styled.div`
  display: flex;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 2px;
    top: 0;
    bottom: 0;
    left: 0;
    background: ${props => props.theme.colors.secondary200};
    margin-left: 18px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EventStatus = styled.div`
  margin-top: 3px;
  position: relative;
  text-align: center;
  border: 2px solid;
  box-sizing: border-box;
  border-radius: 100px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 8px;
  z-index: 5;

  ${({ theme, telephony, critical }) => {
    if (!telephony && critical) {
      return css`
        background-color: ${theme.colors.danger50};
        border-color: ${theme.colors.danger500};
        svg {
          color: ${theme.colors.danger900};
        }
      `;
    }

    if (telephony) {
      return css`
        background-color: ${theme.colors.violet50};
        border-color: ${theme.colors.violet500};
        svg {
          color: ${theme.colors.violet900};
        }
      `;
    }

    return css`
      background-color: ${theme.colors.white};
      border-color: ${theme.colors.secondary400};
      svg {
        color: ${theme.colors.highBlack};
      }
    `;
  }}
`;

export const EventInfoContainer = styled(Flex)`
  flex: 1;
  margin: 0 16px 8px 16px;
  border: 1px solid;
  border-radius: 4px;
  padding: 8px 16px;
  flex-direction: column;
  align-items: center;

  ${({ theme, telephony, critical }) => {
    if (!telephony && critical) {
      return css`
        background-color: ${theme.colors.danger50};
        border-color: ${theme.colors.danger500};
      `;
    }

    if (telephony) {
      return css`
        background-color: ${theme.colors.violet50};
        border-color: ${theme.colors.violet500};
      `;
    }

    return css`
      background-color: ${theme.colors.white};
      border-color: ${theme.colors.secondary400};
    `;
  }}
`;

export const EventInfo = styled(Flex)`
  flex: 1;
  justify-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

export const EventDetailContainer = styled(Flex)`
  margin: 8px 16px;
  padding: 16px 0;
  background: ${props => props.theme.colors.secondary50};
  border-radius: 8px;
  flex: 1;
  width: 100%;
`;

export const EventDetail = styled(Flex)`
  border-right: 1px solid ${props => props.theme.colors.secondary400};
  flex-direction: column;
  margin-left: 16px;
  padding-right: 24px;
`;

export const TextInfo = styled(Text)`
  font-family: Lato;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.mediumBlack};
`;

export const TextTitle = styled(Text)`
  font-family: Lato;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;

  ${({ theme, telephony, critical }) => {
    if (!telephony && critical) {
      return css`
        color: ${theme.colors.danger500};
      `;
    }

    if (telephony) {
      return css`
        color: ${theme.colors.violet500};
      `;
    }

    return css`
      color: ${theme.colors.highBlack};
    `;
  }}
`;

export const PlaceHolder = styled(placeholder)`
  .line {
    height: 60px;
    width: 427px;
    margin: 15px 0 15px 30px;
  }
`;
