import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Flex } from '@kiper/ui';
import { useNextAction, useInitialization, useChangeUnity } from './hooks';
import { UserGuUnSelect } from '../../Select';
import StepStructure, { StepTextTypeColor } from '../StepStructure';

const StepGetOnlyUnity = ({ event }) => {
  const { t } = useTranslation('guided-attendance');

  const { nextAction } = useNextAction({ event });

  const { values, setFieldValue, errors, invalidForm } = useInitialization({
    nextAction,
    t,
  });

  const { handleChangeUnity } = useChangeUnity({
    values,
    setFieldValue,
    event,
  });

  return (
    <StepStructure
      type={StepTextTypeColor.SPEAK}
      text={t('step-get-only-unity.title')}
      hasPreviousStepButton
    >
      <Flex gridGap="8px" flexDirection="column" width="100%">
        <UserGuUnSelect
          name="stepGetOnlyUnity.unity"
          placeholder={t('step-get-only-unity.placeholder')}
          onChange={handleChangeUnity}
          sourceNodeId={event?.condominium?.treeNodeId}
          value={values?.stepGetOnlyUnity?.unity}
          guLoad={false}
          invalid={invalidForm}
          autoFocus
        />
        {invalidForm && (
          <ErrorMessage>{errors?.stepGetOnlyUnity?.unity?.value}</ErrorMessage>
        )}
      </Flex>
    </StepStructure>
  );
};

export default StepGetOnlyUnity;
StepGetOnlyUnity.propTypes = {
  event: propTypes.object.isRequired,
};
