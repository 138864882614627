import { useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { events as eventsGraphql } from '@kiper/monitoring-graphql';
import Select from './styles';

const EventCategorySelect = ({
  isClearable,
  invalid,
  setFieldValue,
  setFieldLabel,
  ...props
}) => {
  const [t] = useTranslation('report');
  const [value, setValue] = useState(null);

  const { data, loading } = useQuery(eventsGraphql.eventCategorySelect, {
    variables: { onlyAvaliable: false, search: '' },
  });

  const mapCategories = eventCategoryData => {
    const categories = [];
    const eventsByCategory = new Map();

    for (let index = 0; index < eventCategoryData.length; index += 1) {
      const current = eventCategoryData[index];
      const categoriesData = current?.categories;
      const eventId = current.value;

      if (categoriesData) {
        const toAdd = categoriesData.reduce((result, category) => {
          const { fieldName, name } = category;

          const hasCategory = eventsByCategory.has(fieldName);
          const events = [eventId];

          if (!hasCategory) result.push({ value: fieldName, label: name });
          else events.push(...eventsByCategory.get(fieldName));

          eventsByCategory.set(fieldName, events);

          return result;
        }, []);

        categories.push(...toAdd);
      }
    }
    return { categories, eventsByCategory };
  };

  const { groupedOptions, groupedEvents } = useMemo(() => {
    if (!data?.eventCategorySelect?.length) return { groupedOptions: {} };

    const events = data.eventCategorySelect.map(({ value: id, label }) => ({
      value: id,
      label,
    }));

    const { categories, eventsByCategory } = mapCategories(
      data.eventCategorySelect,
    );

    return {
      groupedOptions: {
        categories: {
          label: t('details.event-category'),
          options: categories,
        },
        events: {
          label: t('details.event'),
          options: events,
        },
      },
      groupedEvents: eventsByCategory,
    };
  }, [data]);

  const options = useMemo(() => {
    if (!groupedOptions?.events) {
      if (value) setFieldValue(null);
      return {};
    }

    const events = { ...groupedOptions?.events };

    return { ...groupedOptions, events };
  }, [groupedOptions]);

  useEffect(() => {
    const events = { ...groupedOptions?.events };
    const fieldValue = [];
    const fieldLabel = [];

    if (value?.length) {
      for (let index = 0; index < value.length; index += 1) {
        const currentValue = value[index];
        const isCategory = groupedEvents?.has(currentValue?.value);

        if (isCategory) {
          const eventsOfCategory = groupedEvents?.get(currentValue.value);
          const eventsLabels = events?.options?.filter(event =>
            eventsOfCategory?.some(current => current === event.value),
          );

          fieldValue.push(...eventsOfCategory);
          fieldLabel.push(...eventsLabels?.map(i => i?.label));

          events.options = events?.options?.filter(
            event =>
              !eventsOfCategory?.some(current => current === event.value),
          );
        } else {
          fieldValue.push(currentValue);
        }
      }

      if (fieldValue?.length) {
        setFieldValue(fieldValue);
      }
      if (fieldLabel?.length) {
        setFieldLabel(fieldLabel);
      }
    } else {
      setFieldValue(null);
    }
  }, [value]);

  return (
    <Select
      {...props}
      value={value}
      onChange={setValue}
      options={Object?.values(options)}
      isClearable={isClearable}
      isLoading={loading}
      placeholder={t('details.events-placeholder')}
    />
  );
};

EventCategorySelect.propTypes = {
  isClearable: propTypes.bool,
  invalid: propTypes.bool,
  setFieldValue: propTypes.func.isRequired,
  setFieldLabel: propTypes.func,
};

EventCategorySelect.defaultProps = {
  isClearable: true,
  invalid: false,
  setFieldLabel: () => {},
};

export default EventCategorySelect;
