import { useMemo, useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNetworkStatus } from '@kiper/hooks';
import { FiWifi, FiWifiOff, FiAlertTriangle } from 'react-icons/fi';
import { Alert } from './styles';
import useCurrentLoggedContext from '../useCurrentLoggedContext';

function useAlert() {
  const [t] = useTranslation('common');

  const { networkStatus } = useNetworkStatus();
  const { loggedContext } = useCurrentLoggedContext();
  const [online, setOnline] = useState(null);

  const ONE_DAY = 24 * 60 * 60 * 1000;

  const calculateDaysRemaining = () => {
    const targetDate = new Date('08-26-2024');
    targetDate.setHours(23, 59, 59, 999);
    const currentDate = new Date();
    const timeDifference = targetDate - currentDate;
    const daysDifference = Math.ceil(timeDifference / ONE_DAY);
    return daysDifference > 0 ? daysDifference : 0;
  };

  const daysRemaining = calculateDaysRemaining();

  useEffect(() => {
    if (!networkStatus) {
      setOnline('offline');

      return;
    }

    if (online === 'offline' && networkStatus) {
      setOnline('online');

      setTimeout(() => setOnline(null), 3000);
    }
  }, [networkStatus]);

  const hasAlertVisible = useMemo(() => !networkStatus || online === 'online', [
    networkStatus,
    online,
  ]);

  const isExpiringLicenseDate = useMemo(
    () => loggedContext?.partner?.personContextId === '1182',
    [loggedContext],
  );

  const alertComponent = ({ isAttendancePage }) => {
    return (
      <>
        {!networkStatus ? (
          <Alert
            background="dangerSecondary"
            icon={FiWifiOff}
            text={t('feedback.offline')}
            fixed={!isAttendancePage}
          />
        ) : (
          online === 'online' && (
            <Alert
              background="successPrimary"
              icon={FiWifi}
              text={t('feedback.online')}
              fixed={!isAttendancePage}
            />
          )
        )}
        {isExpiringLicenseDate && (
          <Alert
            background="warningAlert"
            icon={FiAlertTriangle}
            text={t('common:feedback.suspended-license', { daysRemaining })}
          />
        )}
      </>
    );
  };

  alertComponent.propTypes = {
    isAttendancePage: propTypes.bool.isRequired,
  };

  return {
    hasAlertVisible,
    alertComponent,
  };
}

export default useAlert;
