import { Suspense, useContext, useEffect, useMemo } from 'react';
import propTypes from 'prop-types';

import { matchPath, useLocation } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';

import { ThemeProvider } from 'styled-components';

import { useTranslation } from 'react-i18next';

import theme from '@kiper/theme';

import { Box, GlobalStyle, Loader } from '@kiper/ui';
import { useSmartLook } from '@kiper/smartlook';
import { runtimeConfig } from '@kiper/fns';

import { useToggle } from '@kiper/hooks';
import Header from '../Header';
import Menu from '../Menu';
import authContext from '../../services/auth/context';
import { useCurrentLoggedContext, useAlert } from '../../hooks';
import { ContentBody } from './styles';
import useViewRules from '../../hooks/useViewRules';

import { NotAllowed } from '../NotAllowed';
import {
  sendAmplitudeData,
  setAmplitudeUserProperties,
  initAmplitude,
} from '../../services/amplitude';

const isDevelopment = process.env.RAZZLE_ENV === 'development';

const firebase = !isDevelopment ? require('../../services/firebase') : null;

const setCurretUserGlobalEnv = whoAmI => {
  window.current_user = whoAmI;
};

const Root = ({ route, location }) => {
  const [t] = useTranslation('common');
  const { pathname } = useLocation();
  const { whoAmI } = useContext(authContext);
  const { loggedContext } = useCurrentLoggedContext();
  const { alertComponent: Alert } = useAlert();
  const [isCollapsed, toggleCollapse] = useToggle(true);

  initAmplitude(loggedContext?.email);

  setAmplitudeUserProperties({
    userName: loggedContext?.name,
    partner: loggedContext?.partner?.name,
    role: loggedContext?.profileName,
  });

  useEffect(() => {
    const onUnload = () => {
      if (localStorage.getItem('monitoring') === 'opened') {
        localStorage.setItem('monitoring', 'closed');
        sendAmplitudeData('stop monitoring', {
          partner: loggedContext?.partner?.name,
        });
      }
    };
    window.addEventListener('beforeunload', onUnload);
  }, []);

  useEffect(() => {
    if (pathname.includes('/attendance')) {
      if (
        !localStorage.getItem('monitoring') ||
        localStorage.getItem('monitoring') === 'closed'
      ) {
        localStorage.setItem('monitoring', 'opened');
        sendAmplitudeData('open monitoring', {
          partner: loggedContext?.partner?.name,
        });
      }
    }

    if (!pathname.includes('/attendance'))
      if (localStorage.getItem('monitoring') === 'opened') {
        localStorage.setItem('monitoring', 'closed');
        sendAmplitudeData('stop monitoring', {
          partner: loggedContext?.partner?.name,
        });
      }
  }, [pathname]);

  const { permissions } = useViewRules();

  const hasPermission = requiredPermissions =>
    requiredPermissions?.length
      ? requiredPermissions.every(requiredPermission =>
          permissions.some(
            permission =>
              permission.applicationFeature.fieldName === requiredPermission,
          ),
        )
      : true;

  const routes = useMemo(() => {
    return permissions?.length
      ? route.routes.map(i => {
          const { requiredPermissions } = i;

          return !hasPermission(requiredPermissions)
            ? { ...i, component: NotAllowed }
            : i;
        })
      : route.routes;
  }, [route, permissions, location]);

  const currentRoute = useMemo(
    () => routes.find(routeObj => matchPath(location.pathname, routeObj)),
    [routes, location],
  );

  const isAttendancePage = useMemo(
    () =>
      currentRoute?.path === '/attendance' ||
      currentRoute?.path === '/guided-attendance',
    [currentRoute],
  );

  const isProduction = runtimeConfig.RAZZLE_ENV === 'production';

  const { identify } = useSmartLook({
    isProduction,
    user: loggedContext,
    consentMessage: t('smartlook.consent-message'),
  });

  useEffect(() => {
    setCurretUserGlobalEnv(loggedContext);
    if (loggedContext?.personContextId) identify();
  }, [loggedContext]);

  useEffect(() => {
    if (firebase) {
      const firebaseInstance = firebase.getFireInstance();
      firebase.logEvent(firebaseInstance, 'page_view', {
        page_title: currentRoute && currentRoute.name,
        page_location: location.pathname,
      });
    }
  }, [location]);

  useEffect(() => {
    if (whoAmI) setCurretUserGlobalEnv(whoAmI);
  }, [whoAmI]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {whoAmI && (
        <>
          <Menu
            isCollapsed={isCollapsed}
            toggleCollapse={toggleCollapse}
            inAttendancePage={isAttendancePage}
          />

          <Box height="100vh" ml={isCollapsed ? '60px' : '240px'}>
            {!isAttendancePage && <Header />}
            <ContentBody isAttendancePage={isAttendancePage}>
              <Suspense fallback={<Loader fullHeight />}>
                <Alert isAttendancePage={isAttendancePage} />
                {loggedContext && renderRoutes(routes)}
              </Suspense>
            </ContentBody>
          </Box>
        </>
      )}
    </ThemeProvider>
  );
};

export default Root;

Root.propTypes = {
  route: propTypes.object.isRequired,
  location: propTypes.object.isRequired,
};
