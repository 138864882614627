import styled, { createGlobalStyle } from 'styled-components';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';

export const ScrollArea = styled(ScrollMenu)`
  padding: 8px;
  width: 100%;
`;

export const GlobalStyle = createGlobalStyle`
.react-horizontal-scrolling-menu--wrapper {
  align-items: center;
  margin: 0 -2px;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE e Edge */
  scrollbar-width: none; /* Firefox */
}`;
