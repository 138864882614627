import propTypes from 'prop-types';
import Place from './Place';
import { useCurrentLoggedContext } from '../../../../hooks';
import { Container, MapContainer } from './styles';

const CondoMap = ({
  places,
  selectedPlace,
  setSelectedPlace,
  urlMap,
  event,
  deviceStatus,
}) => {
  const { loggedContext } = useCurrentLoggedContext();

  return (
    <Container>
      <MapContainer url={urlMap}>
        {places?.map((place, i) => (
          <Place
            key={i}
            place={place}
            selectedPlace={selectedPlace}
            setSelectedPlace={setSelectedPlace}
            event={event}
            loggedContext={loggedContext}
            deviceStatus={deviceStatus}
          />
        ))}
      </MapContainer>
    </Container>
  );
};

export default CondoMap;

CondoMap.propTypes = {
  places: propTypes.array,
  selectedPlace: propTypes.number,
  urlMap: propTypes.string,
  setSelectedPlace: propTypes.func.isRequired,
  event: propTypes.object.isRequired,
  deviceStatus: propTypes.array.isRequired,
};

CondoMap.defaultProps = {
  places: [],
  selectedPlace: null,
  urlMap: null,
};
