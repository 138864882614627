import { useCallback } from 'react';

const useUnityDwellerQuery = ({ condominiumPersonContextId, filters, t }) => {
  const handleGetQueryVariables = useCallback(() => {
    if (!condominiumPersonContextId || !filters) return null;
    const variables = {
      condominiumPersonContextId,
      filters: {
        searchName: filters?.searchName,
        page: filters?.page,
        pageSize: filters?.pageSize,
        unityId: filters?.unity?.id || null,
      },
    };

    return variables;
  }, [filters]);

  const tableColumns = [
    {
      name: t('step-get-unity.unity'),
      align: 'left',
      width: '40%',
    },
    {
      name: t('step-get-unity.dweller'),
      align: 'left',
      width: '60%',
    },
  ];

  return { handleGetQueryVariables, tableColumns };
};

export default useUnityDwellerQuery;
