import { useCallback, useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';

import * as yup from 'yup';

import { useTranslation } from 'react-i18next';
import { useFormikContext, useFormik } from 'formik';
import { useLazyQuery } from 'react-apollo';

import { Button, ErrorMessage, Label, Select } from '@kiper/ui';

import { queries } from '@kiper/monitoring-graphql/dweller/detail';

import Modal from '../Modal';
import { accessTypesDict } from '../../constants';
import useLoggedContext from '../../hooks/useCurrentLoggedContext';

import { Form, FormGroup, Actions } from './styles';

import GenericFingerprintModal from '../GenericFingerprintModal';
import PutUserFingerprintModal from '../PutUserFingerprintModal';
import UserDeviceTag from './UserDeviceTag';
import UserDeviceRf from './UserDeviceRf';
import UserDeviceFingerprint from './UserDeviceFingerprint';
import UserDeviceFacial from './UserDeviceFacial';
import { UserFacialModal } from '../Modals';

export default function PutUserDeviceModal({
  onToggle,
  onSubmit,
  deviceObject,
  existDevice,
  setExistDevice,
  loading,
  personId,
  setParentFieldValue,
  devicesValues,
  refetch,
  isEdition,
}) {
  const [t] = useTranslation('user');
  const { values: fatherValues } = useFormikContext();
  const isEdit = !!deviceObject;
  const { loggedContext } = useLoggedContext();

  const [fingerprintModalToggle, setFingerprintModalToggle] = useState(false);
  const [
    genericFingerprintModalToggle,
    setGenericFingerprintModalToggle,
  ] = useState(false);
  const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);
  const [showFacialModal, setShowFacialModal] = useState(false);

  const formik = useFormik({
    initialValues: deviceObject || {
      type: '',
      value: '',
      counter: 0,
      description: '',
    },
    validationSchema: yup.object({
      type: yup.string().required(t('common:feedback.required-field')),
      value: yup.string().max(50),
      description: yup
        .string()
        .max(200)
        .nullable(),
      counter: yup.number().when('type', type =>
        type === 'rf'
          ? yup
              .number()
              .min(0, t('feedback.invalid-cpu-counter'))
              .required(t('feedback.invalid-cpu-counter'))
          : yup.number().nullable(),
      ),
    }),
    onSubmit: deviceValues => {
      if (deviceValues.type === 'fingerPrint') {
        return setFingerprintModalToggle(true);
      }

      return onSubmit({
        ...deviceValues,
        description: deviceValues?.description?.trim(),
        isEdit,
        lastValue: deviceObject?.value,
      });
    },
  });

  const {
    values,
    handleSubmit,
    setFieldValue,
    touched,
    errors,
    resetForm,
  } = formik;

  const [
    getAccessMediaTypes,
    { loading: loadingAccessMediaTypes },
  ] = useLazyQuery(queries.dwellerAccessMediaTypesAvailable, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ dwellerAccessMediaTypesAvailable }) => {
      const accessMediaTypesAvailable = dwellerAccessMediaTypesAvailable?.map(
        accessMedia => {
          if (accessMedia.fieldName === accessTypesDict.fingerprint) {
            return { ...accessMedia, fieldName: 'fingerPrint' };
          }
          return accessMedia;
        },
      );
      setDeviceTypeOptions(accessMediaTypesAvailable);
    },
  });

  useEffect(() => {
    setFieldValue('terminal', values?.condominium?.value.place?.name);
  }, [values?.condominium]);

  useEffect(() => {
    if (existDevice) setExistDevice(false);
  }, [values.value]);

  const showOption = useCallback(
    deviceType => {
      if (isEdit) return isEdit && values.type === deviceType;
      return !fatherValues.devices.some(x => x.type === deviceType);
    },
    [fatherValues.devices, values.type],
  );

  const { isTag, isRf, isFingerprint, isFacial } = useMemo(
    () => ({
      isTag: values.type === accessTypesDict.tag,
      isRf: values.type === accessTypesDict.rf,
      isFingerprint: values.type === accessTypesDict.fingerPrint,
      isFacial: values.type === accessTypesDict.facial,
    }),
    [values?.type],
  );

  useEffect(() => {
    const result = [];
    const currentDevices = fatherValues?.devices;

    if (!isEdition) {
      const tags = currentDevices?.filter(({ type }) => type === 'tag');
      const rfs = currentDevices?.filter(({ type }) => type === 'rf');

      const tagSelect = {
        fieldName: 'tag',
        description: t('put.devices.modal.insert.select-option-tag'),
      };
      const rfSelect = {
        fieldName: 'rf',
        description: t('put.devices.modal.insert.select-option-control'),
      };

      if (tags.length < 5 || (isEdit && deviceObject?.type === 'tag'))
        result.push(tagSelect);

      if (rfs.length < 5 || (isEdit && deviceObject?.type === 'rf'))
        result.push(rfSelect);

      setDeviceTypeOptions(result);
    } else {
      getAccessMediaTypes({
        variables: {
          personId,
          isUpdate: !!isEdit,
        },
      });
    }
  }, []);

  const handleCloseModals = () => {
    setShowFacialModal(false);
    onToggle();
  };

  return (
    <Modal
      size="md"
      title={
        isEdit
          ? t('put.devices.modal.insert.title-update')
          : t('put.devices.modal.insert.title-registration')
      }
      open
      toggle={onToggle}
    >
      <>
        <Form onSubmit={handleSubmit} noValidate>
          <FormGroup>
            <Label for="exampleSelect">
              {t('put.devices.modal.insert.device')}
            </Label>
            <Select
              isDisabled={!!values.id || isEdit}
              options={deviceTypeOptions}
              name="type"
              onChange={({ fieldName }) => {
                resetForm();
                setFieldValue('type', fieldName);
              }}
              value={deviceTypeOptions?.find(
                ({ fieldName }) => fieldName === values.type,
              )}
              isLoading={loadingAccessMediaTypes}
              width="100%"
              invalid={touched.type && errors.type}
              placeholder={t('put.devices.modal.insert.select')}
              getOptionValue={option => option.fieldName}
              getOptionLabel={option => option.description}
              isOptionDisabled={option => option.personHasAccessFacial}
            />
            {touched.type && errors.type && (
              <ErrorMessage>{errors.type}</ErrorMessage>
            )}
          </FormGroup>

          {isTag && (
            <UserDeviceTag
              formik={formik}
              showOption={showOption}
              existDevice={existDevice}
              t={t}
              loggedContext={loggedContext}
            />
          )}

          {isRf && (
            <UserDeviceRf formik={formik} existDevice={existDevice} t={t} />
          )}

          {isFingerprint && (
            <UserDeviceFingerprint
              formik={formik}
              t={t}
              personId={personId}
              isEdition={isEdit}
              devicesValues={devicesValues?.devices}
            />
          )}

          {isFacial && (
            <UserDeviceFacial formik={formik} t={t} personId={personId} />
          )}

          <Actions>
            <Button
              color="secondary"
              className="mr-3"
              type="button"
              onClick={onToggle}
              outline
            >
              {t('put.devices.modal.insert.cancel')}
            </Button>
            {isFingerprint || isFacial ? (
              <Button
                outline
                color="primary"
                onClick={() => {
                  if (isFingerprint) {
                    if (values?.fingerprintPlace.model === 'fpTerminal') {
                      setFingerprintModalToggle(true);
                    } else {
                      setGenericFingerprintModalToggle(true);
                    }
                  } else {
                    setShowFacialModal(true);
                  }
                }}
                disabled={
                  isFingerprint
                    ? !(
                        values?.fingerprintCondominium &&
                        values?.fingerprintPlace
                      )
                    : !(values?.facialCondominium && values?.facialPlace)
                }
              >
                {t('common:continue')}
              </Button>
            ) : (
              <Button
                type="submit"
                color="primary"
                loading={loading}
                disabled={loading}
              >
                {t(`put.devices.modal.insert.${loading ? 'saving' : 'save'}`)}
              </Button>
            )}
          </Actions>
        </Form>

        {!!fingerprintModalToggle && (
          <PutUserFingerprintModal
            devicesValues={devicesValues?.devices}
            setFieldValue={setParentFieldValue}
            userId={+personId}
            condominium={values?.fingerprintCondominium}
            deviceId={values?.fingerprintPlace?.deviceId}
            accessFingerprintId={+values?.id}
            onToggle={setFingerprintModalToggle}
            open={fingerprintModalToggle}
            onToggleParent={onToggle}
          />
        )}

        {!!genericFingerprintModalToggle && (
          <GenericFingerprintModal
            devicesValues={devicesValues?.devices}
            setFieldValue={setParentFieldValue}
            personId={+personId}
            condominium={values?.fingerprintCondominium}
            deviceId={values?.fingerprintPlace?.deviceId}
            accessFingerprintId={+values?.id}
            onToggle={setGenericFingerprintModalToggle}
            open={genericFingerprintModalToggle}
            onToggleParent={onToggle}
            refetchDevices={refetch}
          />
        )}
        {!!showFacialModal && (
          <UserFacialModal
            onToggle={handleCloseModals}
            open={showFacialModal}
            personId={+personId}
            deviceId={values?.facialPlace?.deviceId}
            refetchDevices={refetch}
            isEdit={isEdit}
          />
        )}
      </>
    </Modal>
  );
}

PutUserDeviceModal.propTypes = {
  onToggle: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
  existDevice: propTypes.bool.isRequired,
  setExistDevice: propTypes.func.isRequired,
  loading: propTypes.bool.isRequired,
  refetch: propTypes.func.isRequired,
  isEdition: propTypes.bool.isRequired,
  deviceObject: propTypes.object,
  personId: propTypes.number,
  setParentFieldValue: propTypes.func,
  devicesValues: propTypes.object,
};

PutUserDeviceModal.defaultProps = {
  deviceObject: null,
  personId: null,
  setParentFieldValue: () => {},
  devicesValues: null,
};
