import styled from 'styled-components';

export const DynamicPaneSizeContainer = styled.div`
  width: 100%;

  @media screen and (min-width: 1980px) {
    height: 300px;
  }
  @media screen and (max-width: 1920px) {
    height: 300px;
  }
  @media screen and (max-width: 1600px) {
    height: 250px;
  }
`;
