import { useContext, useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import * as i18n from 'react-i18next';
import { useSwal } from '@kiper/hooks';
import {
  formatDistanceDate,
  formatTimerLabel,
  getUserLang,
  toUpperCamelCase,
} from '@kiper/fns';
import { FiClock } from 'react-icons/fi';
import { withErrorBoundary, Flex, Text, Tooltip, UserAvatar } from '@kiper/ui';
import { EventAttendanceContext } from '../../store';
import * as S from './styles';

const EventCard = ({ event, ...props }) => {
  const [currentEventId, setCurrentEventId] = useState(null);
  const [t] = i18n.useTranslation('event_attendance');
  const { toast } = useSwal();
  const lang = useMemo(() => getUserLang().split('-')[0], []);
  const eventDate = formatDistanceDate(event.eventDate, t);

  const {
    loading,
    play,
    eventInAttendance,
    eventOnHoldInAttendance,
  } = useContext(EventAttendanceContext);

  const { isInAttendance, isInAttendanceOnHold } = useMemo(
    () => ({
      isInAttendance: eventInAttendance?.eventId === event.eventId,
      isInAttendanceOnHold: eventOnHoldInAttendance?.eventId === event.eventId,
    }),
    [eventInAttendance, eventOnHoldInAttendance],
  );

  const { isVideoCall, isEventScheduled, observation } = useMemo(
    () => ({
      isVideoCall:
        event?.additionalInformation &&
        JSON.parse(event?.additionalInformation)?.videoCall,
      isEventScheduled:
        event?.additionalInformation &&
        JSON.parse(event?.additionalInformation)?.eventScheduled,
      observation:
        event?.additionalInformation &&
        JSON.parse(event?.additionalInformation)?.observation,
    }),
    [event],
  );

  const ImAttending = isInAttendance || isInAttendanceOnHold;

  const showEventSchedulerPlaceOrObservation =
    isEventScheduled && (event?.place?.name || observation);

  const placeOrObservation = event?.place?.name
    ? event.place.name
    : observation;

  const handleStart = () => {
    if (loading) {
      return;
    }

    if (event?.attendant?.id && !ImAttending) {
      const attendantName = event?.attendant?.name;
      const title = t('attendance-already-has-attendant', { attendantName });

      toast.fire({ title, icon: 'error' });

      return;
    }

    setCurrentEventId(event.eventId);
    play.getEvent(event.eventId);
  };

  useEffect(() => {
    if (!loading) {
      setCurrentEventId(null);
    }
  }, [loading]);

  if (!event?.condominium) return null;

  return (
    <>
      <S.EventContainer
        {...props}
        attend={ImAttending}
        critical={event?.isCritical}
        onClick={handleStart}
        isLoading={currentEventId === event.eventId && loading}
        isTelephony={!!event.ownerId}
        isEventScheduled={isEventScheduled}
      >
        <Flex mb={10} width="100%" flexDirection="column">
          <Text
            fontFamily="Nunito Sans"
            fontWeight={700}
            className="event-title"
          >
            {isVideoCall
              ? t('video-call-answering')
              : toUpperCamelCase(`${event?.eventTypeDescription[lang]}`)}
          </Text>
          <Text
            opacity={0.8}
            fontFamily="Nunito Sans"
            fontWeight={300}
            fontSize="12px"
          >
            {event?.condominium?.name}
          </Text>
          {showEventSchedulerPlaceOrObservation && (
            <S.TextEllipsis title={placeOrObservation}>
              {placeOrObservation}
            </S.TextEllipsis>
          )}
        </Flex>
        <Flex
          alignItems="flex-end"
          flex={1}
          width="100%"
          justifyContent="space-between"
        >
          {!!eventDate && (
            <Text fontFamily="Nunito Sans" fontSize="12px" fontWeight={300}>
              {eventDate}
            </Text>
          )}

          {event.attendant && (
            <Flex justifyContent="flex-end">
              <UserAvatar
                src={`//avatar.kiper.com.br/${event.attendant.email}`}
                size={30}
                name={event.attendant.name}
                round="100%"
              />
            </Flex>
          )}
        </Flex>
        {event?.onHold && (
          <>
            <Flex
              id={`timerContainer-${event.eventId}`}
              mt="4px"
              width="100%"
              alignItems="center"
            >
              <Flex
                py="3px"
                px="6px"
                bg="lightGrey"
                borderRadius="8px"
                alignItems="center"
                width="150px"
              >
                <FiClock size={14} fontSize={14} />
                <Text fontSize="12px" ml="5px">
                  {`${
                    event?.onHold?.waitTimer
                      ? formatTimerLabel(event.onHold.waitTimer)
                      : ''
                  } (${
                    event.onHold.returnsOn
                      ? formatDistanceDate(event.onHold.returnsOn, t)
                      : ''
                  })`}
                </Text>
              </Flex>
            </Flex>
            <Tooltip
              placement="bottom"
              target={`timerContainer-${event.eventId}`}
            >
              <Flex justifyContent="flex-start">
                <S.Ul>
                  <S.Li>
                    <Text fontWeight="600">{`${t('list.added-by')}: `}</Text>
                    {event?.onHold?.addedBy?.name}
                  </S.Li>
                  <S.Li>
                    <Text fontWeight="600">{`${t('list.reason')}: `}</Text>
                    {event?.onHold?.reason}
                  </S.Li>
                </S.Ul>
              </Flex>
            </Tooltip>
          </>
        )}
      </S.EventContainer>
    </>
  );
};

EventCard.propTypes = {
  event: propTypes.shape({
    eventId: propTypes.string,
    eventDate: propTypes.string,
    eventType: propTypes.number,
    ownerId: propTypes.number,
    isCritical: propTypes.bool,
    additionalInformation: propTypes.string,
    eventSettings: propTypes.shape({
      priority: propTypes.number,
      isCritical: propTypes.bool,
      showPopUp: propTypes.bool,
      showAlert: propTypes.bool,
    }),
    eventTypeDescription: propTypes.shape({
      en: propTypes.string,
      es: propTypes.string,
      pt: propTypes.string,
    }),
    condominium: propTypes.shape({
      name: propTypes.string,
    }),
    attendant: propTypes.object,
    onHold: propTypes.shape({
      waitTimer: propTypes.number,
      returnsOn: propTypes.string,
      reason: propTypes.string,
      addedBy: propTypes.shape({
        name: propTypes.string,
      }),
    }),
    place: propTypes.shape({
      name: propTypes.string,
    }),
  }).isRequired,
};

const EventCardComponent = withErrorBoundary(EventCard, {
  i18n,
  translationKey: 'event_attendance',
  defaultTitleMessage: 'list.error-boundary.fallback-title',
  customErrorMessage: '',
});

export default EventCardComponent;
