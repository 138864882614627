import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex, Button, KiperButton } from '@kiper/ui';
import { FaTrash as DeleteIcon } from 'react-icons/fa';
import CarLogo from '../../../../assets/images/vehicle-car.svg';
import MotorcycleLogo from '../../../../assets/images/vehicle-motorcycle.svg';
import {
  VehicleCard,
  VehicleModel,
  VehicleLabel,
  VehiclePlate,
  ActionsContainer,
  DeleteContainer,
  VehicularTagIcon,
} from './styles';
import vehicleTypes from './VehicleTypes';
import AddMore from '../AddMore';

const VehicleData = ({ data, create, onDelete, onCreate, onEdit }) => {
  const [t] = useTranslation('condominium');

  const openModalCreate = () => {
    onCreate();
  };

  const openModalEdit = () => {
    onEdit(data);
  };

  return (
    <>
      {!create ? (
        <VehicleCard flexDirection="column">
          <Flex
            flexDirection="row"
            alignItens="flex-start"
            justifyContent="space-between"
            gridGap="16px"
          >
            <Flex
              flexDirection="row"
              alignItens="flex-start"
              justifyContent="flex-start"
              gridGap="16px"
            >
              <img
                width="50px"
                src={!data.type ? CarLogo : MotorcycleLogo}
                alt="vehicle"
              />
              <Flex flexDirection="column">
                <VehicleModel>{data.model}</VehicleModel>
                <Flex flexDirection="row">
                  <VehicleLabel>
                    {vehicleTypes(t)[data.type]?.label ?? data.type}
                  </VehicleLabel>
                  {data.tag && (
                    <VehicularTagIcon
                      title={t('details.vehicle.vehicular-tag-linked')}
                    />
                  )}
                </Flex>
              </Flex>
            </Flex>
            <DeleteContainer>
              <KiperButton
                color="secondary"
                variant="text"
                onClick={() => onDelete(data.id)}
                rounded
                icon={<DeleteIcon />}
              />
            </DeleteContainer>
          </Flex>
          <Flex flexDirection="row" gridGap="4px">
            <VehicleLabel>{`${t('details.vehicle.plate')}:`}</VehicleLabel>
            <VehiclePlate>{data.plate}</VehiclePlate>
          </Flex>
          <Flex flexDirection="column" grid="4px">
            <ActionsContainer>
              <Button type="button" color="primary" onClick={openModalEdit}>
                {t('details.vehicle.edit')}
              </Button>
            </ActionsContainer>
          </Flex>
        </VehicleCard>
      ) : (
        <VehicleCard flexDirection="column">
          <Flex justifyContent="end" height="170px">
            <AddMore text={t('details.unity.add')} onClick={openModalCreate} />
          </Flex>
        </VehicleCard>
      )}
    </>
  );
};

export default VehicleData;

VehicleData.propTypes = {
  data: propTypes.object.isRequired,
  create: propTypes.bool,
  onDelete: propTypes.func,
  onCreate: propTypes.func,
  onEdit: propTypes.func,
};

VehicleData.defaultProps = {
  create: false,
  onDelete: null,
  onCreate: null,
  onEdit: null,
};
