import { useState } from 'react';
import { useQuery } from 'react-apollo';
import { queries } from '@kiper/monitoring-graphql/condominium/detail';

const useFetchAccessProfilesNames = ({ condominiumPersonContextId }) => {
  const [accessProfilesList, setAccessProfilesList] = useState({});
  const { loading: loadingAccessProfiles } = useQuery(queries.accessProfiles, {
    fetchPolicy: 'cache-first',
    variables: {
      personContextId: Number(condominiumPersonContextId),
      onlyNoInvites: false,
    },
    onCompleted: ({ accessProfiles }) => {
      if (accessProfiles) {
        const accessProfilesListNamesById = accessProfiles.reduce(
          (acc, accessProfile) => ({
            ...acc,
            [accessProfile.id]: accessProfile.name,
          }),
          {},
        );

        setAccessProfilesList({ ...accessProfilesListNamesById });
      }
    },
  });

  return [accessProfilesList, loadingAccessProfiles];
};

export default useFetchAccessProfilesNames;
