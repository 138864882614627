import Report from './Report';

const breadcrumb = (label, to) => ({ label, to });
const requiredPermissions = ['monitoringReports'];

export default [
  {
    name: 'Report',
    path: '/reports',
    exact: true,
    component: Report,
    breadcrumb: [breadcrumb('common:breadcrumb.report', '/reports')],
    requiredPermissions,
  },
  {
    name: 'Report',
    path: '/reports/:id',
    exact: true,
    component: Report,
    breadcrumb: [breadcrumb('common:breadcrumb.report', '/reports')],
    requiredPermissions,
  },
];
