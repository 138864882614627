// Helper function to calculate a check digit
const calculateCheckDigit = (cpf, factor) => {
  let total = 0;
  for (let i = 0; i < factor - 1; i++) {
    total += cpf[i] * (factor - i);
  }
  const remainder = (total * 10) % 11;
  return remainder === 10 ? 0 : remainder;
};

const handleValidateCPF = cpf => {
  const cleanCPF = cpf.replace(/\D/g, '');

  // Check if the cleanCPF has 11 digits or is a repeated sequence
  if (cleanCPF.length !== 11 || /^(\d)\1+$/.test(cleanCPF)) return false;

  // Check both verifying digits
  const firstCheckDigit = calculateCheckDigit(cleanCPF, 10);
  const secondCheckDigit = calculateCheckDigit(cleanCPF, 11);

  return firstCheckDigit === +cleanCPF[9] && secondCheckDigit === +cleanCPF[10];
};

export default handleValidateCPF;
