import propTypes from 'prop-types';
import { Text, Box, Flex } from '@kiper/ui';
import { useTheme } from 'styled-components';

const EmptyStateIcon = ({ title, text, icon: Icon, ...props }) => {
  const theme = useTheme();

  return (
    <Flex {...props} flexDirection="column" alignItems="center">
      <Icon size={42} className="mb-2" color={theme.colors.highBlack} />
      <Box marginBottom="4px" textAlign="center">
        <Text fontSize={14} fontWeight={700} lineHeight="20px" letter="0.25px">
          {title}
        </Text>
      </Box>
      <Box textAlign="center">
        <Text fontSize={14} fontWeight={400} lineHeight="20px" letter="0.25px">
          {text}
        </Text>
      </Box>
    </Flex>
  );
};

export default EmptyStateIcon;

EmptyStateIcon.propTypes = {
  title: propTypes.string.isRequired,
  text: propTypes.string.isRequired,
  icon: propTypes.any.isRequired,
};
