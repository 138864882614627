import { useMemo } from 'react';
import { useGuidedAttendance } from '../../../../hooks';
import useMutations from './useMutations';

const useNextAction = ({ event }) => {
  const { handleNextAction, handleValidHasSubtriage } = useGuidedAttendance();
  const { handleInsertStepHistory, handleCreateTriage } = useMutations();

  const hasSubtriage = useMemo(() => {
    return handleValidHasSubtriage(event?.condominium);
  }, [event]);

  const nextAction = async stepData => {
    Promise.all([
      handleCreateTriage(stepData, event?.eventId),
      handleInsertStepHistory(stepData, event?.eventId),
    ]);

    return handleNextAction({
      values: stepData,
      fieldValue: 'stepAttendanceGetReason',
      featureFlag: hasSubtriage,
    });
  };

  return { nextAction };
};

export default useNextAction;
