import styled from 'styled-components';
import PersonSelect from '../../../components/PersonSelect';

export const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CondoSelect = styled(PersonSelect)`
  width: 100%;
`;
