import styled from 'styled-components';
import { Flex, Modal as modal } from '@kiper/ui';

export const Modal = styled(modal)``;

export const Container = styled(Flex)`
  display: flex;
  max-height: calc(100vh - 150px);
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;
`;

export const ActivityContainer = styled(Flex)`
  position: relative;
  display: flex;
  gap: 12px;
  flex-direction: column;

  &::before {
    content: '';
    position: absolute;
    width: 2px;
    top: 0;
    bottom: 0;
    left: 0;
    background: ${props => props.theme.colors.secondary200};
    margin-left: 18px;
  }
`;

export const ActivityTypeStatus = styled.div`
  position: relative;
  text-align: center;
  box-sizing: border-box;
  border-radius: 100px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 8px;
  z-index: 5;
  justify-content: center;
  background: ${props =>
    props.theme.colors[props.background] || props.theme.colors.secondary50};
  color: ${props =>
    props.theme.colors[props.color] || props.theme.colors.secondary900};

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const TreatmentText = styled.div`
  overflow: hidden;
  color: ${props => props.theme.colors.secondary700};
`;

export const ShowMoreButton = styled.div`
  width: 56px;
  cursor: pointer;
  color: ${props => props.theme.colors.linkPurple};
`;
