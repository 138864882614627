import { useMutation } from 'react-apollo';
import { useSwal } from '@kiper/hooks';
import { useTranslation } from 'react-i18next';
import { relayCommand } from '@kiper/monitoring-graphql/attendance';

const DEVICE_ACTIONS = {
  open: 'open',
  'open/keep': 'pulse',
  close: 'close',
};

const DEVICE_SUCCESS_MESSAGES = t => ({
  open: t('success-feedback.turn-on'),
  'open/keep': t('success-feedback.pulse'),
  close: t('success-feedback.turn-off'),
});

const DEVICE_ERROR_MESSAGES = t => ({
  open: t('error-feedback.turn-on'),
  'open/keep': t('error-feedback.pulse'),
  close: t('error-feedback.turn-off'),
});

const useCommandRelay = ({ eventId }) => {
  const { t } = useTranslation('modals/iot-modal');
  const { toast } = useSwal();

  const [handleRelayMutation] = useMutation(relayCommand);

  const handleCommandRelay = ({ relayId, deviceId, command }) => {
    handleRelayMutation({
      variables: {
        eventId,
        relayId,
        deviceId,
        command: DEVICE_ACTIONS[command],
      },
    })
      .then(() =>
        toast.fire({
          title: DEVICE_SUCCESS_MESSAGES(t)[command],
          icon: 'success',
        }),
      )
      .catch(() =>
        toast.fire({ title: DEVICE_ERROR_MESSAGES(t)[command], icon: 'error' }),
      );
  };

  return { handleCommandRelay };
};

export default useCommandRelay;
