import { useTranslation } from 'react-i18next';
import { useSwal } from '@kiper/hooks';

export const useDeleteItem = ({ values, handleChange }) => {
  const [t] = useTranslation('user');
  const { confirm } = useSwal();

  const handleDeleteItem = async index => {
    const confirmDelete = await confirm({
      title: t('put.access-data.modal.delete-allow-contact.title'),
      text: t('put.access-data.modal.delete-allow-contact.text'),
      cancelButtonText: t('put.access-data.modal.delete-allow-contact.cancel'),
      confirmButtonText: t(
        'put.access-data.modal.delete-allow-contact.confirm',
      ),
    });
    if (confirmDelete) {
      const modifiedContactScheduleIntervals = [...values];
      modifiedContactScheduleIntervals.splice(index, 1);
      handleChange('contactSchedule', modifiedContactScheduleIntervals);
    }
  };

  return handleDeleteItem;
};
