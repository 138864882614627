import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex } from '@kiper/ui';
import {
  useNextAction,
  useForm,
  useFetchGetDwellers,
  useStepDwellerValidation,
  useValidationSchema,
} from './hooks';
import { ActionButtons, DocumentsSection, DwellerSelect } from './components';
import StepStructure, { StepTextTypeColor } from '../StepStructure';

const StepDwellerValidation = ({ event }) => {
  const { t } = useTranslation('guided-attendance');

  const { nextAction } = useNextAction({ event });

  const {
    handleMaxCharacters,
    handleGetValidationSchema,
    invalidDwellerValidationSchema,
    schema,
  } = useValidationSchema({ t });

  const {
    values,
    setFieldValue,
    setFieldTouched,
    setContextTree,
    handleSubmit,
    touch,
    error,
    value,
  } = useForm({
    nextAction,
    schema,
  });

  const {
    fieldsValidated,
    handleValidateDocument,
    checkFieldBeenValidated,
    handleSelectDweller,
    handleChangeInvalidValidationStatus,
    documents,
    title,
  } = useStepDwellerValidation({
    t,
    value,
    values,
    setFieldValue,
    setFieldTouched,
    setContextTree,
    invalidDwellerValidationSchema,
    handleGetValidationSchema,
    handleMaxCharacters,
  });

  const { dwellers, loading } = useFetchGetDwellers({
    unityId: values?.stepGetOnlyUnity?.unity?.id,
    handleChangeInvalidValidationStatus,
  });

  return (
    <StepStructure
      type={StepTextTypeColor.SPEAK}
      text={title}
      hasPreviousStepButton
    >
      <Flex width="100%">
        <Flex gridGap="8px" flexDirection="column" width="100%">
          <DwellerSelect
            searchText={values.stepAttendanceGetName?.name}
            value={value}
            touch={touch}
            error={error}
            dwellers={dwellers}
            loading={loading}
            handleSelectDweller={handleSelectDweller}
            fieldsValidated={fieldsValidated}
            t={t}
          />
          <DocumentsSection
            value={value}
            touch={touch}
            error={error}
            setFieldValue={setFieldValue}
            handleMaxCharacters={handleMaxCharacters}
            handleValidateDocument={handleValidateDocument}
            checkFieldBeenValidated={checkFieldBeenValidated}
            documents={documents}
            t={t}
          />
          <ActionButtons
            value={value}
            fieldsValidated={fieldsValidated}
            handleChangeInvalidValidationStatus={status =>
              handleChangeInvalidValidationStatus(status)
            }
            handleSubmit={handleSubmit}
            t={t}
          />
        </Flex>
      </Flex>
    </StepStructure>
  );
};

export default StepDwellerValidation;
StepDwellerValidation.propTypes = {
  event: propTypes.object.isRequired,
};
