import styled from 'styled-components';
import { Card as card } from '@kiper/ui';

export const Card = styled(card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px;
`;

export const Container = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 480px;
`;
