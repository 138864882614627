import { Box, Button, Flex, Text } from '@kiper/ui';
import { MdArrowBack } from 'react-icons/md';
import styled from 'styled-components';

export const Container = styled(Flex)`
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: 1366px) {
    gap: 16px;
  }
`;

export const Label = styled(Text)`
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: ${props => props.theme.colors.highBlack};
  margin-bottom: 8px;

  @media screen and (max-width: 1600px) {
    line-height: 20px;
  }
`;

export const Title = styled(Text)`
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  color: ${props => props.theme.colors.highBlack};

  @media screen and (min-width: 1920px) {
    font-size: 24px;
  }

  @media screen and (min-width: 1366px) {
    font-size: 18px;
    line-height: 20px;
  }
`;

export const TitleContainer = styled(Flex)`
  align-items: center;
  gap: 8px;
  align-self: flex-start;

  @media screen and (max-width: 1600px) {
    gap: 4px;
  }
`;

export const Icon = styled(Box)`
  padding: 10px;
  background: ${({ theme }) => theme.colors.gray100};
  border-radius: 50%;
  align-self: flex-start;
`;

export const ButtonReturnStep = styled(Button).attrs({
  variant: 'text',
  color: 'neutral',
  size: 'small',
  icon: <MdArrowBack size={20} />,
})`
  padding: 4px;
`;
