import { useContext, useRef, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { MdSettings, MdEdit } from 'react-icons/md';
import { IoMdAdd, IoIosEye } from 'react-icons/io';
import {
  Pagination,
  EmptyState,
  Table,
  TableLoader,
  KiperButton,
} from '@kiper/ui';
import propTypes from 'prop-types';
import sharedGql from '@kiper/shared-schema/src/graphql';
import { useQuery } from 'react-apollo';
import { ButtonsContainer, TableContainer } from './styles';
import emptyStatePlaceholder from '../../../assets/images/empty-state-event-settings.svg';
import Modal from '../Modal';

const EventSettingsListPage = ({
  t,
  loading,
  collection,
  history,
  filters,
  pagination,
  handlePageSize,
  handleChange,
  isEditable,
  originContext,
  admin,
  reloadPage,
}) => {
  const theme = useContext(ThemeContext);
  const { data } = useQuery(sharedGql.events.eventSelect, {
    variables: { onlyAvaliable: false },
  });

  const [modalSettings, updateModalSettings] = useState({ opened: false });

  const OpenModal = (eventType, routedToType, id) => {
    updateModalSettings({
      id,
      eventType,
      routedToType,
      opened: !modalSettings.opened,
      personContextId: originContext.contextId,
      partnerConfig: originContext.contextId === originContext.value,
      hasCoPartner: originContext.hasCoPartner,
    });
  };

  const closeModal = reload => {
    updateModalSettings({
      opened: !modalSettings.opened,
    });
    if (reload && reloadPage) reloadPage();
  };

  const events = (data?.eventSelect ?? []).reduce(
    (eventsMap, { label, value }) => {
      // eslint-disable-next-line no-param-reassign
      eventsMap[value] = label;
      return eventsMap;
    },
    {},
  );

  const edit = id => {
    history.push({
      pathname: `/event-settings/${id}`,
      state: { isEditable, originContext },
    });
  };

  const createPage = eventType => {
    history.push({
      pathname: `/event-settings/create`,
      state: { eventType, originContext },
    });
  };

  const view = id => {
    history.push({
      pathname: `/event-settings/${id}`,
      state: { isEditable: false, originContext },
    });
  };

  const getButtons = (personContextId, eventType, id) => {
    if (Number(personContextId) !== Number(originContext.contextId)) {
      return (
        <>
          <KiperButton icon={<IoIosEye />} onClick={() => view(id)} />
          <KiperButton
            icon={<IoMdAdd />}
            onClick={() => createPage(eventType)}
          />
        </>
      );
    }

    return <KiperButton icon={<MdEdit />} onClick={() => edit(id)} />;
  };

  const columns = useRef([
    {
      name: t('list.event'),
      align: 'left',
      width: '5%',
    },
    {
      name: t('list.event-name'),
      align: 'left',
      width: '20%',
    },
    {
      name: t('list.closes-another-event'),
      align: 'left',
      width: '17%',
    },
    {
      name: t('list.priority'),
      align: 'left',
      width: '5%',
    },
    {
      name: t('list.notification'),
      align: 'center',
      width: '5%',
    },
    {
      name: t('list.critical'),
      align: 'center',
      width: '5%',
    },
    {
      name: t('list.monitored'),
      align: 'center',
      width: '5%',
    },

    {
      name: t('list.routedTo.collumn'),
      align: 'left',
      width: '10%',
    },
    {
      name: t('list.configuredBy.collumn'),
      align: 'left',
      width: '15%',
    },
  ]);

  return (
    <>
      {modalSettings.opened && (
        <Modal
          title={t('list.modalSettings')}
          open={modalSettings.opened}
          toggle={closeModal}
          id="modalSettings"
          t={t}
          modalSettings={modalSettings}
        />
      )}

      {collection && !collection[0] && !loading ? (
        <EmptyState
          placeholder={emptyStatePlaceholder}
          text={t('list.empty-state.title')}
          message={t('list.empty-state.message')}
        />
      ) : (
        <TableContainer>
          <Table columns={columns}>
            {!loading && collection && (
              <tbody>
                {collection.map(
                  ({
                    id,
                    eventType,
                    priority,
                    showPopUp,
                    automaticClose,
                    closeEvent,
                    isCritical,
                    configuredby,
                    routedTo,
                    personContextId,
                  }) => (
                    <tr key={id}>
                      <td>{eventType}</td>
                      <td>{events[eventType]}</td>
                      <td>{closeEvent}</td>
                      <td>{t(`list.priority-values.${priority}`)}</td>
                      <td className="text-center text-primary">
                        {showPopUp ? (
                          <FaCheck />
                        ) : (
                          <FaTimes color={theme.colors.dangerSecondary} />
                        )}
                      </td>
                      <td className="text-center text-primary">
                        {isCritical ? (
                          <FaCheck />
                        ) : (
                          <FaTimes color={theme.colors.dangerSecondary} />
                        )}
                      </td>
                      <td className="text-center text-primary">
                        {!automaticClose ? (
                          <FaCheck />
                        ) : (
                          <FaTimes color={theme.colors.dangerSecondary} />
                        )}
                      </td>
                      <td>{t(`list.routedTo.${routedTo.routedToType}`)}</td>
                      {!admin && (
                        <td>
                          {t(
                            `list.configuredBy.${configuredby.configuredByType}`,
                          )}
                        </td>
                      )}
                      <td className="text-center">
                        <ButtonsContainer>
                          {getButtons(personContextId, eventType, id)}

                          <KiperButton
                            icon={<MdSettings />}
                            id="settings"
                            onClick={() =>
                              OpenModal(
                                eventType,
                                routedTo.routedToType,
                                routedTo?.id,
                              )
                            }
                          />
                        </ButtonsContainer>
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            )}
          </Table>
          {loading && <TableLoader />}
          <Pagination
            pagination={{
              page: filters.page,
              pageSize: filters.pageSize,
              totalPages: pagination.totalPages,
              totalResults: pagination.totalResults,
            }}
            translate={t}
            handlePageSize={handlePageSize}
            next={() => handleChange('page', filters.page + 1)}
            previous={() => handleChange('page', filters.page - 1)}
          />
        </TableContainer>
      )}
    </>
  );
};

EventSettingsListPage.propTypes = {
  t: propTypes.func.isRequired,
  loading: propTypes.bool.isRequired,
  collection: propTypes.array.isRequired,
  history: propTypes.object.isRequired,
  filters: propTypes.object.isRequired,
  pagination: propTypes.object,
  handlePageSize: propTypes.func.isRequired,
  handleChange: propTypes.func.isRequired,
  isEditable: propTypes.bool,
  originContext: propTypes.object,
  admin: propTypes.bool,
  reloadPage: propTypes.func,
};

EventSettingsListPage.defaultProps = {
  pagination: propTypes.shape({
    totalPages: 0,
    totalResults: 0,
  }),
  originContext: null,
  isEditable: true,
  admin: false,
  reloadPage: null,
};

export default EventSettingsListPage;
