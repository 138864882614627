import styled from 'styled-components';
import { FormGroup as fg } from 'reactstrap';

export const FormGroup = styled(fg)`
  width: 250px;
  margin-right: 15px;

  input {
    width: 100% !important;
  }
`;
