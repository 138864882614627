import { useContext, useEffect, useMemo, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import { useHistory, useRouteMatch } from 'react-router-dom';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  EmptyState,
  Box,
  Text,
  Flex,
  ScrollBar,
  Alert,
  Tooltip,
  PlaceHolder,
  TabContent,
  TabPane,
  OptionSwitch,
} from '@kiper/ui';
import { FiPlus, FiCheck, FiWifiOff } from 'react-icons/fi';
import { useTheme } from 'styled-components';

import { runtimeConfig } from '@kiper/fns';
import EventCard from './EventCard';
import useAttendance from '../../../hooks/useAttendance';
import {
  useAlert,
  useAttendanceRemoteConfig,
  useCurrentLoggedContext,
} from '../../../hooks';

import {
  EmptyContainer,
  ModalButton,
  BellIcon,
  ClockIcon,
  TechnicalAlertsIcon,
  Badge,
  Nav,
  NavItem,
  NavLink,
  Button,
} from './styles';
import attendancePlaceHolder from '../../../assets/images/empty-state-attendance.svg';
import useEmergency from '../useEmergency';
import EmergencyCommandContext from '../emergencyCommandContext';
import AttendanceContext from '../attendanceContext';
import NewAttendance from '../NewAttendance';
import { EmptyStateIcon } from '../../../components/EmptyStateIcon';
import { TriageProvider } from '../../../store';
import { technicalAlertsEventsList } from './technicalAlertsEventsList';

const EventAttendanceList = props => {
  const attendance = useAttendance();
  const [activeTab, setActiveTab] = useState('attendanceQueue');

  const providerValues = useMemo(
    () => ({ ...attendance, activeTab, setActiveTab }),
    [attendance, activeTab, setActiveTab],
  );

  return (
    <AttendanceContext.Provider value={providerValues}>
      <TriageProvider>
        <EventAttendance {...props} />
      </TriageProvider>
    </AttendanceContext.Provider>
  );
};

export default EventAttendanceList;

const loadingStyle = {
  height: '85px',
  width: '194px',
  margin: '8px',
  padding: '8px',
  borderRadius: '10px',
};

const EventAttendance = ({ route }) => {
  const { loggedContext } = useCurrentLoggedContext();
  const history = useHistory();

  useEffect(() => {
    if (runtimeConfig.RAZZLE_ENV === 'production') {
      history.push('/guided-attendance');
    }
  }, []);

  const [t] = useTranslation('event_attendance');
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const { attendanceList, activeTab, setActiveTab } = useContext(
    AttendanceContext,
  );
  const {
    events,
    inAttendance,
    eventsOnHold,
    inAttendanceOnHold,
    loading,
    error,
    filteredEvents,
    resetFilters,
  } = attendanceList;
  const { hasAlertVisible } = useAlert();

  const toggleTab = tab => {
    if (tab !== activeTab) {
      setActiveTab(tab);
    }
  };

  const toogleModal = () => {
    setOpen(!open);
  };

  const currentEventId = useRouteMatch({
    path: '/attendance/:id?',
  })?.params?.id;

  const emergencyCommand = useEmergency({ eventId: currentEventId });

  /* BLOCO EXPERIMENTAL DE ALERTAS TÉCNICOS (PROVISÓRIO PARA TESTES CLOUD-3749) */

  const { showTechnicalReviewFeature } = useAttendanceRemoteConfig(
    loggedContext,
  );

  const eventsWithTechnicalAlerts = useMemo(
    () =>
      events.filter(event =>
        technicalAlertsEventsList.includes(event.eventType),
      ),
    [events],
  );

  const attendanceEvents = useMemo(
    () =>
      showTechnicalReviewFeature
        ? events.filter(
            event => !technicalAlertsEventsList.includes(event.eventType),
          )
        : events,
    [events],
  );

  const inAttendanceDefault = useMemo(
    () =>
      showTechnicalReviewFeature
        ? !!inAttendance &&
          !technicalAlertsEventsList.includes(inAttendance?.eventType) &&
          inAttendance
        : !!inAttendance && inAttendance,
    [inAttendance],
  );

  const inAttendanceTechnicalAlerts = useMemo(
    () =>
      technicalAlertsEventsList.includes(inAttendance?.eventType) &&
      !!inAttendance &&
      inAttendance,
    [inAttendance],
  );

  /* FIM BLOCO EXPERIMENTAL DE ALERTAS TÉCNICOS */

  const handleGetEventCount = count => {
    if (count > 99) return '99+';
    return count;
  };

  return (
    <Flex>
      {error && (
        <Alert
          background="dangerSecondary"
          icon={FiWifiOff}
          secondaryText={
            error?.message === 'reconnecting...' ? t('reconnecting') : undefined
          }
          text={t('server-offline')}
          fixed
        />
      )}
      <Flex
        flexDirection="column"
        width={210}
        zIndex={9}
        backgroundColor="white"
        boxShadow={`4px 0px 4px ${theme.colors.hoverGrey}`}
        height="100vh"
        flex="0 0 auto"
      >
        <Box position="sticky">
          <Nav tabs>
            <NavItem>
              <NavLink
                active={activeTab === 'attendanceQueue'}
                onClick={() => toggleTab('attendanceQueue')}
              >
                <BellIcon active={Number(activeTab === 'attendanceQueue')} />
                {attendanceEvents.length > 0 && (
                  <Badge>{handleGetEventCount(attendanceEvents.length)}</Badge>
                )}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'onHoldQueue'}
                onClick={() => toggleTab('onHoldQueue')}
              >
                <ClockIcon active={Number(activeTab === 'onHoldQueue')} />
                {eventsOnHold.length > 0 && (
                  <Badge>{eventsOnHold.length}</Badge>
                )}
              </NavLink>
            </NavItem>

            {showTechnicalReviewFeature && (
              <NavItem>
                <NavLink
                  active={activeTab === 'technicalAlerts'}
                  onClick={() => toggleTab('technicalAlerts')}
                >
                  <TechnicalAlertsIcon
                    active={Number(activeTab === 'technicalAlerts')}
                  />
                  {eventsWithTechnicalAlerts.length > 0 && (
                    <Badge>{eventsWithTechnicalAlerts.length}</Badge>
                  )}
                </NavLink>
              </NavItem>
            )}
          </Nav>
        </Box>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="attendanceQueue">
            <Flex
              pb="10px"
              flexDirection="column"
              height={`calc(100vh - ${hasAlertVisible || error ? 100 : 40}px)`}
              width="100%"
            >
              {inAttendanceDefault && (
                <Box>
                  <EventCard
                    isLoading={emergencyCommand.isLoading}
                    critical={inAttendanceDefault.isCritical}
                    key={`with-me-${inAttendanceDefault.eventId}`}
                    event={inAttendanceDefault}
                  />
                </Box>
              )}

              <Flex
                m="12px 8px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text
                  truncate
                  fontWeight="bold"
                  fontSize={14}
                  lineHeight="20px"
                  color={theme.colors.primary500}
                >
                  {t('attendanceQueue')}
                </Text>
                <Button
                  id="add-new-attendance"
                  data-cy="add-new-attendance"
                  icon={<FiPlus />}
                  disabled={!!inAttendance}
                  onClick={toogleModal}
                  variant="out"
                  size="sm"
                  rounded
                />

                <Tooltip placement="bottom" target="add-new-attendance">
                  {t('add-new-attendance')}
                </Tooltip>
              </Flex>

              {!!filteredEvents.length && (
                <Box mb="8px" ml="8px" mr="8px">
                  <OptionSwitch
                    option={filteredEvents[0]?.condominium?.name}
                    switchOnClick={resetFilters}
                  />
                </Box>
              )}

              <ScrollBar>
                {loading && (
                  <PlaceHolder style={loadingStyle} type="line" lines={5} />
                )}
                {!loading && attendanceEvents.length < 1 && (
                  <EmptyStateIcon
                    m="56px 8px 8px 8px"
                    icon={FiCheck}
                    title={t('no-events')}
                    text={t('no-events-detailed')}
                  />
                )}

                {!loading &&
                  attendanceEvents.map(event => (
                    <EventCard
                      key={event.eventId}
                      event={event}
                      critical={event.isCritical}
                      borderColor={
                        currentEventId && currentEventId === event.eventId
                          ? 'secondary400'
                          : null
                      }
                    />
                  ))}
              </ScrollBar>
            </Flex>
          </TabPane>

          <TabPane tabId="onHoldQueue">
            <Flex
              pb="10px"
              flexDirection="column"
              height={`calc(100vh - ${hasAlertVisible || error ? 100 : 40}px)`}
              width="100%"
            >
              {inAttendanceOnHold && (
                <Box>
                  <EventCard
                    isLoading={emergencyCommand.isLoading}
                    critical={inAttendanceOnHold.isCritical}
                    key={`with-me-${inAttendanceOnHold.eventId}`}
                    event={inAttendanceOnHold}
                  />
                </Box>
              )}

              <Flex m="12px 8px" height="34px" alignItems="center">
                <Text
                  truncate
                  fontWeight="bold"
                  fontSize={14}
                  lineHeight="20px"
                  color={theme.colors.primary500}
                >
                  {t('on-hold-queue')}
                </Text>
              </Flex>

              <ScrollBar>
                {loading && (
                  <PlaceHolder style={loadingStyle} type="line" lines={5} />
                )}
                {!loading && eventsOnHold.length < 1 && (
                  <EmptyStateIcon
                    m="56px 8px 8px 8px"
                    icon={FiCheck}
                    title={t('no-events')}
                    text={t('no-events-detailed')}
                  />
                )}

                {!loading &&
                  eventsOnHold.map(event => (
                    <EventCard
                      key={event.eventId}
                      event={event}
                      critical={event.isCritical}
                      borderColor={
                        currentEventId && currentEventId === event.eventId
                          ? 'secondary400'
                          : null
                      }
                    />
                  ))}
              </ScrollBar>
            </Flex>
          </TabPane>

          {showTechnicalReviewFeature && (
            <TabPane tabId="technicalAlerts">
              <Flex
                pb="10px"
                flexDirection="column"
                height={`calc(100vh - ${
                  hasAlertVisible || error ? 100 : 40
                }px)`}
                width="100%"
              >
                {inAttendanceTechnicalAlerts && (
                  <Box>
                    <EventCard
                      isLoading={emergencyCommand.isLoading}
                      critical={inAttendanceTechnicalAlerts.isCritical}
                      key={`with-me-${inAttendanceTechnicalAlerts.eventId}`}
                      event={inAttendanceTechnicalAlerts}
                    />
                  </Box>
                )}

                <Flex
                  m="12px 8px"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text
                    truncate
                    fontWeight="bold"
                    fontSize={14}
                    lineHeight="20px"
                    color={theme.colors.primary500}
                  >
                    {t('technical-alerts')}
                  </Text>
                </Flex>

                {!!filteredEvents.length && (
                  <Box mb="8px" ml="8px" mr="8px">
                    <OptionSwitch
                      option={filteredEvents[0]?.condominium?.name}
                      switchOnClick={resetFilters}
                    />
                  </Box>
                )}

                <ScrollBar>
                  {loading && (
                    <PlaceHolder style={loadingStyle} type="line" lines={5} />
                  )}
                  {!loading && eventsWithTechnicalAlerts.length < 1 && (
                    <EmptyStateIcon
                      m="56px 8px 8px 8px"
                      icon={FiCheck}
                      title={t('no-events')}
                      text={t('no-events-detailed')}
                    />
                  )}

                  {!loading &&
                    eventsWithTechnicalAlerts.map(event => (
                      <EventCard
                        key={event.eventId}
                        event={event}
                        critical={event.isCritical}
                        borderColor={
                          currentEventId && currentEventId === event.eventId
                            ? 'secondary400'
                            : null
                        }
                      />
                    ))}
                </ScrollBar>
              </Flex>
            </TabPane>
          )}
        </TabContent>
      </Flex>
      <Flex flex={1} width="100%">
        {currentEventId ? (
          <EmergencyCommandContext.Provider value={emergencyCommand}>
            {renderRoutes(route.routes)}
          </EmergencyCommandContext.Provider>
        ) : (
          <EmptyContainer>
            <EmptyState
              placeholder={attendancePlaceHolder}
              text={t('list.empty-state.title')}
              message={t('list.empty-state.message')}
            >
              <ModalButton onClick={toogleModal} disabled={!!inAttendance}>
                {t('newAttendance')}
              </ModalButton>
            </EmptyState>
          </EmptyContainer>
        )}
      </Flex>
      <NewAttendance open={open} handleToggle={toogleModal} />
    </Flex>
  );
};

EventAttendance.propTypes = {
  route: propTypes.object.isRequired,
};
