import propTypes from 'prop-types';
import { Flex } from '@kiper/ui';
import { Container, SidebarHeader, SidebarBody, Scroll } from './styles';

const SidebarPage = ({ header, filterButton, children }) => {
  return (
    <Container className="sidebar-page">
      <SidebarHeader>
        {header}
        {filterButton && filterButton}
      </SidebarHeader>
      <Flex height="100%" overflow="auto">
        <Scroll>
          <SidebarBody>{children}</SidebarBody>
        </Scroll>
      </Flex>
    </Container>
  );
};

export default SidebarPage;

SidebarPage.propTypes = {
  header: propTypes.any.isRequired,
  children: propTypes.any.isRequired,
  filterButton: propTypes.element,
};

SidebarPage.defaultProps = {
  filterButton: null,
};
