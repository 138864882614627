import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FiHome, FiUser } from 'react-icons/fi';
import { GrLicense } from 'react-icons/gr';
import { FaRegAddressBook } from 'react-icons/fa';
import { useQuery } from 'react-apollo';
import { queries } from '@kiper/monitoring-graphql/condominium/detail';
import { useMemo } from 'react';
import { Flex, Spinner, SplitSidebar } from '@kiper/ui';
import * as S from './styles';
import CondoInfoAddress from './CondoInfoAddress';
import CondoInfoManager from './CondoInfoManagers';
import CondoInfoLicenses from './CondoInfoLicenses';
import CondoInfoCaretaker from './CondoInfoCaretaker';

const CondoInfo = ({ condominiumPersonContextId }) => {
  const [t] = useTranslation('guided-attendance');

  const { data, loading } = useQuery(queries.condominiumInfoAttendance, {
    skip: !condominiumPersonContextId,
    variables: {
      condominiumPersonContextId,
    },
  });

  const { caretakers, address, licenses, management } = useMemo(() => {
    if (!data) return {};

    const filteredCaretakers = data.condominiumInfoAttendance.condominiumWorkers?.filter(
      item => {
        if (item.profile.fieldName === 'caretaker') {
          let hasContactSchedule = false;
          item.personContextParams.forEach(element => {
            if (element.name === 'contactSchedule') hasContactSchedule = true;
          });
          return hasContactSchedule;
        }
      },
    );

    const filteredSyndic = data.condominiumInfoAttendance.condominiumWorkers?.filter(
      item => {
        return item.profile.fieldName !== 'caretaker';
      },
    );

    const filteredAddress =
      data.condominiumInfoAttendance.addresses.length > 0
        ? data.condominiumInfoAttendance.addresses.find(x => x.isPrincipal)
        : null;

    const {
      condominiumLicenses,
      managementCompany,
    } = data.condominiumInfoAttendance;

    return {
      syndic: filteredSyndic,
      caretakers: filteredCaretakers,
      address: filteredAddress,
      licenses: condominiumLicenses,
      management: managementCompany,
    };
  }, [data]);

  return (
    <S.Wrapper>
      <SplitSidebar.HeaderPane hasBorder>
        {t('quick-menu.condominium-info.title')}
      </SplitSidebar.HeaderPane>
      {loading || !data ? (
        <Flex
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner size={28} />
        </Flex>
      ) : (
        <S.Content loading={loading}>
          <CondoInfoLicenses
            icon={<GrLicense size={18} />}
            licenses={licenses}
          />
          <CondoInfoAddress icon={<FiHome size={18} />} address={address} />
          {management && (
            <CondoInfoManager
              icon={<FiUser size={18} />}
              management={management}
            />
          )}
          {caretakers &&
            caretakers.map(caretaker => (
              <CondoInfoCaretaker
                key={caretaker.id}
                icon={<FaRegAddressBook size={18} />}
                caretaker={caretaker}
              />
            ))}
        </S.Content>
      )}
    </S.Wrapper>
  );
};

export default CondoInfo;

CondoInfo.propTypes = {
  condominiumPersonContextId: propTypes.number.isRequired,
};
