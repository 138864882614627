import styled from 'styled-components';

import { FormGroup as formGroup, Input as input } from 'reactstrap';
import { Modal as modal } from '@kiper/ui';

export const TableContainer = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  border-radius: 5px;
  border: 1px solid ${props => props.theme.colors.secondary400};

  th {
    position: sticky;
    top: 0;
    background: ${props => props.theme.colors.secondary200};
    border-bottom: 2px solid ${props => props.theme.colors.secondary600};

    :first-child {
      border-top-left-radius: 4px;
    }

    :last-child {
      border-top-right-radius: 4px;
    }
  }
`;

export const FormGroup = styled(formGroup)`
  margin-bottom: 0;
  width: 100%;
  padding-right: 16px;
`;

export const Modal = styled(modal)`
  .modal-body {
    padding: 0;
  }
`;

export const Input = styled(input)`
  border-radius: 8px;
  border-color: ${props => props.theme.colors.secondary400};
  width: 100% !important;
  ::placeholder {
    color: ${props => props.theme.colors.secondary400};
  }
`;
