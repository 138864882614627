import styled from 'styled-components';
import { Text } from '@kiper/ui';

export const Label = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
  color: ${props => props.theme.colors.mediumBlack};
`;
