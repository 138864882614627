import { useMemo } from 'react';
import propTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { queries } from '@kiper/monitoring-graphql/condominium/detail';
import Select from './styles';

const AccessProfileSelect = ({
  isClearable,
  invalid,
  value,
  sourceNodeId,
  ...props
}) => {
  const { data, loading } = useQuery(queries.accessProfilesByTreeNodeId, {
    skip: !sourceNodeId,
    variables: {
      sourceNodeId,
    },
  });

  const toSelectValue = accessProfile => {
    const { id, name } = accessProfile;

    if (!id || !name) return null;
    return {
      value: id,
      label: name,
    };
  };

  const accessProfiles = useMemo(() => {
    if (!data?.accessProfilesByTreeNodeId.length) return [];

    return data.accessProfilesByTreeNodeId.map(toSelectValue);
  }, [data]);

  return (
    <Select
      {...props}
      value={value}
      isClearable={isClearable}
      isLoading={loading}
      options={accessProfiles}
      isDisabled={!sourceNodeId}
    />
  );
};

AccessProfileSelect.propTypes = {
  value: propTypes.any,
  isClearable: propTypes.bool,
  invalid: propTypes.bool,
  sourceNodeId: propTypes.number.isRequired,
};

AccessProfileSelect.defaultProps = {
  isClearable: false,
  invalid: false,
  value: null,
};

export default AccessProfileSelect;
