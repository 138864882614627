import { useContext } from 'react';
import { useGuidedAttendance } from '../../../../hooks';
import useMutations from './useMutations';
import { GuidedAttendanceContext } from '../../../../store';

const useNextAction = ({ event }) => {
  const { handleNextAction } = useGuidedAttendance();
  const { updateTriage, stepHistory } = useMutations();
  const { contextTree } = useContext(GuidedAttendanceContext);

  const handleUpdateTriage = async value => {
    const variables = {
      eventId: event?.eventId,
      triageId: value?.stepAttendanceGetReason?.id,
    };

    if (value?.stepGetOnlyUnity) {
      const { stepGetOnlyUnity } = value || {};
      const serializedTriageJson = stepGetOnlyUnity?.unity?.treeNodeId
        ? { guUnTreeNodeId: stepGetOnlyUnity.unity.treeNodeId }
        : {};

      if (value?.stepAttendanceGetName?.name)
        serializedTriageJson.identifier = value.stepAttendanceGetName.name;

      if (Object.keys(serializedTriageJson).length > 0)
        variables.serializedTriage = JSON.stringify(serializedTriageJson);

      await updateTriage({
        variables,
      });
    }
  };

  const handleInsertStepHistory = value => {
    const eventId = event?.eventId;
    const stepId = contextTree?.currentStep?.id;
    const unity = value?.stepGetOnlyUnity?.unity;
    const unityGroup = value?.stepGetOnlyUnity?.unityGroup;

    const data = {
      unityId: unity?.id,
      unity: unity?.label,
      unityGroup,
    };

    return stepHistory({
      variables: {
        eventId,
        stepId,
        data: JSON.stringify(data),
      },
    });
  };

  const nextAction = async value => {
    Promise.all([handleUpdateTriage(value), handleInsertStepHistory(value)]);

    const handleNextFn = await handleNextAction({
      values: value,
      fieldValue: 'stepGetOnlyUnity',
    });

    return handleNextFn;
  };

  return { nextAction };
};

export default useNextAction;
