export const accessTypesDict = {
  rf: 'rf',
  tag: 'tag',
  qrCode: 'qrCode',
  fingerprint: 'fingerprint', // verificar a remoção desta opção, pois o fieldname correto do device é fingerPrint
  facial: 'facial',
  fingerPrint: 'fingerPrint',
};

export const SyncStatus = {
  NotSynced: 0,
  Synchronizing: 1,
  Synchronized: 2,
  CpuNotFound: 3,
};

export const inviteColor = {
  status: {
    active: 'primary500',
    inactive: 'secondary500',
  },
  freeAccess: 'extraPrimary',
};

export const placeTypes = {
  unity: 'unity',
  vehicleGate: 'vehicleGate',
  floodgate: 'floodgate',
  commonArea: 'commonArea',
  unityGroup: 'unityGroup',
  stock: 'stock',
  securityCabin: 'securityCabin',
  walkerGate: 'walkerGate',
  machineRoom: 'machineRoom',
  lobby: 'lobby',
};

export const deviceTypes = {
  cpu: 'cpu',
  cpuk3: 'cpuk3',
  cpuk4: 'cpuk4',
  qr: 'qr',
  iot: 'iot',
  vehicular: 'vehicular',
  voice: 'voice',
  powerSource: 'powerSource',
  monitoringCamera: 'monitoringCamera',
  locker: 'locker',
  ticketGate: 'ticketGate',
  access: 'access',
  vms: 'vms',
  register: 'register',
  accessVehicle: 'accessVehicle',
  accessVehicleV2: 'accessVehicleV2',
  alarmCenter: 'alarmCenter',
  interphone: 'interphone',
  indoor: 'indoor',
  fpTerminal: 'fpTerminal',
  facial: 'dsk1t671m',
  facialIntelbras: 'faceAccessTerminal',
  dvr: 'dvr',
  cameraLPR: 'cameraLPR',
  porterDelivery: 'porterDelivery',
  nvr: 'nvr',
};

export const eventTypeDevicesReport = [
  100,
  108,
  109,
  110,
  111,
  116,
  117,
  118,
  120,
  121,
  124,
  125,
  126,
  132,
  133,
  134,
  135,
  136,
  150,
  159,
  185,
  186,
  187,
  188,
  199,
  200,
  209,
  219,
  220,
  221,
  223,
  224,
  225,
  226,
  228,
  235,
  236,
  237,
  239,
  240,
  241,
  242,
];

export const deviceIdTypes = {
  interphone: 21,
  vehicular: 5,
  accessVehicular: 15,
  accessVehicularV2: 32,
  kvoice: 6,
  access: 12,
  indoor: 22,
  facial: 24,
  facialIntelbras: 25,
  kvoiceT: 20,
  alarmCentralIntelbras: 26,
  porterDelivery: 30,
  alarmCenterJfl: 31,
};

export const isFacialDeviceType = device =>
  device?.deviceTypeId === deviceIdTypes.facial ||
  device?.deviceTypeId === deviceIdTypes.facialIntelbras;

export const profileColor = {
  operator: 'blue900',
  partner: 'blue900',
  partnerGroup: 'blue900',
  operationSupervisor: 'blue900',
  technician: 'blue900',
  condominiumMaster: 'blue900',
  resident: 'primary500',
  residentOwner: 'primary500',
  owner: 'primary500',
  master: 'primary500',
  guest: 'info500',
  authorized: 'info500',
  roomer: 'info500',
  caretaker: 'purple500',
  subCondominiumManager: 'purple500',
  condominiumManager: 'purple500',
  doorman: 'purple500',
  condominiumManagerResident: 'purple500',
  serviceProvider: 'orange500',
  employee: 'orange500',
  condominium: 'secondary500',
  condominiumGroup: 'secondary500',
  accountUser: 'secondary500',
  admin: 'secondary500',
  externalApplication: 'secondary500',
  familiar: 'primary500',
  doormanMonitoring: 'purple500',
};

export const profileTypes = {
  operator: 'operator',
  guest: 'guest',
  authorized: 'authorized',
  partner: 'partner',
  master: 'master',
  resident: 'resident',
  caretaker: 'caretaker',
  condominium: 'condominium',
  partnerGroup: 'partner-group',
  condominiumGroup: 'condominium-group',
  operationSupervisor: 'operation-supervisor',
  subCondominiumManager: 'sub-condominium-manager',
  technician: 'technician',
  owner: 'owner',
  serviceProvider: 'service-provider',
  roomer: 'roomer',
  residentOwner: 'resident-owner',
  accountUser: 'account-user',
  admin: 'admin',
  condominiumManager: 'condominium-manager',
  doorman: 'doorman',
  condominiumMaster: 'condominium-master',
  employee: 'employee',
  externalApplication: 'external-application',
  condominiumManagerResident: 'condominium-manager-resident',
  familiar: 'familiar',
  callAgent: 'call-agent',
  relationshipAgent: 'relationship-agent',
  doormanMonitoring: 'doorman-monitoring',
};

export const userProfile = Object.freeze({
  professionalSyndic: 2,
  janitor: 3,
  operationSupervisor: 11,
  serviceProvider: 15,
  doorman: 22,
  condominiumMaster: 23,
  relationshipAgent: 32,
  doormanMonitoring: 33,
  operator: 6,
});

export const indoorDeviceAccessTypes = Object.freeze({
  VEHICULAR: 'vehicular',
  PEDESTRIAN: 'pedestrian',
});

export const activityTypes = {
  TreatmentStart: 1,
  TreatmentStop: 2,
  TreatmentEnd: 3,
  AnsweredCall: 4,
  OutgoingCall: 5,
  DoorCommand: 6,
  ShowPlayback: 7,
  PutOnHold: 8,
  PutBackOnHold: 9,
  ExitGuidedAttendance: 10,
  CallStatus: 11,
  StepHistory: 12,
  RelayCommand: 13,
  RiskSituationDetected: 14,
  TechnicalReview: 15,
  ServiceOrder: 16,
};

export const eventTypes = {
  attendanceAdditional: 900,
  answeredCall: 102,
  intrusionDetection: 810,
  emergency: 122,
  notClosed: 114,
  lowBattery: 156,
  ride: 128,
  breakIn: 103,
  didNotOpen: 112,
  didNotClose: 114,
  deviceOfflineController: 125,
  rideDetected: 128,
  nobodyPassed: 164,
  openDoorAlert: 411,
  deviceOfflineFacial: 711,
  keepOpenActivity: 375,
  closeActivity: 376,
  turnOnActivity: 377,
  turnOffActivity: 378,
  generalActivity: 379,
  alarmTrigger: 320,
};

export const doorStatusTypes = {
  Closed: 'Closed',
  Opened: 'Opened',
  KeepOpened: 'KeepOpened',

  // IOT Status
  On: 'On',
  Off: 'Off',
  Offline: 'Offline',
};

export const destinationTypes = {
  externalPhone: 0,
  intercom: 1,
  intercomAta: 2,
  intercomGeneric: 3,
};

export const destinationTypesByCode = {
  '0': 'externalPhone',
  '1': 'intercom',
  '2': 'intercomAta',
  '3': 'intercomGeneric',
};

export const contactTypes = {
  email: 1,
  phone: 2,
};

export const registerFacialStatus = Object.freeze({
  success: 'success',
  timeout: 'timeout',
  error: 'error',
  errorWithMessage: 'errorWithMessage',
});

export const syncFacialDeviceStatus = Object.freeze({
  started: 0,
  success: 1,
  error: 2,
});

export const deviceModelFirmware = Object.freeze({
  DEVICE_UNAPPROVED_MODEL: 0,
  DEVICE_UNAPPROVED_FIRMWARE: 1,
});

export const actionRedisEvents = Object.freeze({
  NEW_EVENT: 1,
  UPDATE_EVENT: 2,
  REMOVE_EVENT: 3,
  LIST_EVENT: 4,
  UPDATE_CONDOMINIUM_STATUS: 5,
});

export const actionRedisEventDescription = Object.freeze({
  NEW_EVENT: 'NEW_EVENT',
  NEW_EVENT_IN_ATTENDANCE: 'NEW_EVENT_IN_ATTENDANCE',
  UPDATE_EVENT: 'UPDATE_EVENT',
  UPDATE_EVENT_ON_HOLD: 'UPDATE_EVENT_ON_HOLD',
  UPDATE_EVENT_NOT_FOUND: 'UPDATE_EVENT_NOT_FOUND',
  REMOVE_EVENT: 'REMOVE_EVENT',
  LIST_EVENT: 'LIST_EVENT',
  UPDATE_CONDOMINIUM_STATUS: 'UPDATE_CONDOMINIUM_STATUS',
  DEFAULT: 'DEFAULT',
});

export const fingerNames = Object.freeze({
  LeftPinky: 0,
  LeftRing: 1,
  LeftMiddle: 2,
  LeftIndex: 3,
  LeftThumb: 4,
  RightThumb: 5,
  RightIndex: 6,
  RightMiddle: 7,
  RightRing: 8,
  RightPinky: 9,
});

export const fingersHandEnum = Object.freeze({
  [fingerNames.LeftPinky]: 'left-pinky',
  [fingerNames.LeftRing]: 'left-ring',
  [fingerNames.LeftMiddle]: 'left-middle',
  [fingerNames.LeftIndex]: 'left-index',
  [fingerNames.LeftThumb]: 'left-thumb',
  [fingerNames.RightThumb]: 'right-thumb',
  [fingerNames.RightIndex]: 'right-index',
  [fingerNames.RightMiddle]: 'right-middle',
  [fingerNames.RightRing]: 'right-ring',
  [fingerNames.RightPinky]: 'right-pinky',
});

export const triageIds = Object.freeze({
  janitor: 10,
  others: 9,
  serviceProvision: 8,
  residentEntry: 7,
  orderDelivery: 6,
  mail: 5,
  foodDelivery: 4,
  exit: 3,
  visits: 2,
  skip: 1,
});

export const triageTranslate = Object.freeze({
  1: 'skip',
  2: 'visit',
  3: 'exit',
  4: 'food-delivery',
  5: 'mail',
  6: 'order-delivery',
  7: 'resident-entry',
  8: 'service-provision',
  9: 'others',
  10: 'janitor',
});

export const provideServiceTo = Object.freeze({
  UNITY: 'unity',
  CONDOMINIUM: 'condominium',
});

export const authorizationStatus = Object.freeze({
  REQUEST_AUTHORIZATION: 0,
  AUTHORIZED: 1,
  UNREGISTERED: 3,
});

export const callStatus = Object.freeze({
  AUTHORIZED: 0,
  NOT_AUTHORIZED: 1,
  AT_THE_CONCIERGE: 2,
  UNAVAILABLE_CONTACTS: 3,
});

export const closureReasonFieldName = Object.freeze({
  LACK_OF_COMMUNICATION: 'lackOfCommunication',
  ALARM_ACKNOWLEDGMENT_NO_ISSUE_FOUND: 'AlarmAcknowledgmentNoIssueFound',
});

export const technicalAlertsEventsList = [235, 901];

export const riskSituationDetectedStatus = Object.freeze({
  AlarmAcknowledgmentNoIssueFound: 'AlarmAcknowledgmentNoIssueFound',
  AlarmAcknowledgmentIssueFound: 'AlarmAcknowledgmentIssueFound',
});

export const fieldRequirementType = Object.freeze({
  HIDDEN: 0,
  OPTIONAL: 1,
  REQUIRED: 2,
});

export const erpLicenses = {
  remote_concierge: 'remote_concierge',
  access_control: 'access_control',
  porter_delivery: 'porter_delivery',
  nonexistent: 'nonexistent',
  video_call: 'video_call',
};

export const condominiumStatusTypes = Object.freeze({
  ACTIVE: 'active',
  UNDER_IMPLEMENTATION: 'under-implementation',
});

export const condominiumParamsTypes = Object.freeze({
  MONITORING_ENABLED: 'monitoringEnabled',
  ERP_LICENSE: 'erpLicense',
  CONDOMINIUM_HAS_VIDEOCALL_ENABLED: 'condominiumHasVideoCallEnabled',
  VIDEOCALL_CONFIGURATION: 'videoCallConfiguration',
});

export const daysOfWeekEnum = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const daysMap = Object.freeze({
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
});

export const dialStatus = Object.freeze({
  Answer: 1,
  Busy: 2,
  Cancel: 3,
  NoAnswer: 4,
  Congestion: 5,
  Chanunavail: 6,
  PushError: 7,
  ForwardAta: 8,
});

export const transientPersonTypes = Object.freeze({
  'service-provider': 1,
  visitor: 2,
});

export const triageToTransientPersonType = Object.freeze({
  8: 'service-provider',
  2: 'visitor',
});

export const lockerDeliveryStatus = {
  1: 'imported',
  2: 'waiting-data',
  3: 'validated',
  4: 'reserved',
  5: 'size-not-available',
  6: 'deposited',
  7: 'receiver-notified',
  8: 'withdrawal',
  9: 'returned',
  10: 'reverse-logistic',
  11: 'canceled',
};

export const dwellerValidationStatus = Object.freeze({
  NONE: 0,
  INVALID: 1,
  VALID: 2,
  NO_DWELLERS: 3,
});

export const accessAfterHoursOption = Object.freeze({
  REQUEST_AUTHORIZATION_SYNDIC: 0,
  NOTIFY_SYNDIC: 1,
  NOTIFY_DWELLER: 2,
  DISMISS_SERVICE_PROVIDER: 3,
});

export const untreatedEventOptionsEnum = Object.freeze({
  IMAGE: 4,
  AUDIO: 5,
  EVENT: 6,
  ACCESS_CONTROL: 7,
  SYSTEM_OFFLINE: 8,
  HELP_ME: 9,
  ABSENCE_OF_RULES: 10,
});

export const triageTypeEnum = Object.freeze({
  OrderDelivery: 1,
  ServiceProvision: 2,
  Visits: 3,
  Guest: 4,
  ResidentEntry: 5,
  Exit: 6,
  Janitor: 7,
  Emergency: 8,
  Skip: 9,
  Mail: 10,
  FoodDelivery: 11,
  Others: 12,
});

export const triageTypeTranslate = Object.freeze({
  1: 'order-delivery',
  2: 'service-provision',
  3: 'visit',
  4: 'guest',
  5: 'resident',
  6: 'exit',
  7: 'janitor',
  8: 'emergency',
  9: 'skip',
  10: 'mail',
  11: 'food-delivery',
  12: 'others',
});

export const subtriageTypeEnum = Object.freeze({
  Delivery: 10000,
  Mail: 10001,
  LargeHeavy: 10002,
  Small: 10003,
  Pharmacy: 10004,
  Water: 10005,
  Gas: 10006,
  MarketFairButcher: 10007,
  ForPorter: 10008,
  MaintenanceWorkWithNoise: 20000,
  RecyclableCollection: 20001,
  ForTheCondominium: 20002,
  UnityServices: 20003,
  Realtors: 20004,
  TowTruck: 20005,
  Elevator: 20006,
  ElectricityWater: 20007,
  ServiceProvisionMoving: 20008,
  PetShop: 20009,
  VisitEntrance: 30000,
  GuestEntrance: 40000,
  EntranceWithoutDevice: 50000,
  GeneralQuestions: 50001,
  DeviceRequest: 50002,
  Register: 50003,
  NoRegistration: 50004,
  ResidentEntryAutomationsIoT: 50005,
  ResidentEntryMoving: 50006,
  ResidentEntryKeepDoorGateOpen: 50007,
  ResidentEntryComplaints: 50008,
  ExitVisitor: 60000,
  ExitServiceProvider: 60001,
  JanitorKeepDoor: 70000,
  JanitorAutomationsIoT: 70001,
  ImageReport: 70002,
  CollectInformation: 70003,
  TechnicalIssues: 70004,
  JanitorComplaints: 70005,
  CivilPolice: 80000,
  FederalPolice: 80001,
  FireDepartment: 80002,
  Ambulance: 80003,
  CourtOfficial: 80004,
  CondominiumEmergencContacts: 80005,
});

export const CONDOMINIUM_SPECIFICATION_TYPE = Object.freeze({
  Commercial: 0,
  Residential: 1,
  Hybrid: 2,
});

export const CONDOMINIUM_SPECIFICATION_FORMAT = Object.freeze({
  Horizontal: 0,
  Vertical: 1,
});

export const CONDOMINIUM_SPECIFICATION_TYPE_TRANSLATE = Object.freeze({
  0: 'commercial',
  1: 'residential',
  2: 'hybrid',
});
export const CONDOMINIUM_SPECIFICATION_FORMAT_TRANSLATE = Object.freeze({
  0: 'horizontal',
  1: 'vertical',
});

export default accessTypesDict;
