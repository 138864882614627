import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { useMutation, useQuery } from 'react-apollo';
import { useTheme } from 'styled-components';
import { Flex, KiperButton, Spinner, Text, Table, Pagination } from '@kiper/ui';
import { apolloErrorHandler, formatDate } from '@kiper/fns';

import { queries, mutations } from '@kiper/monitoring-graphql/dweller/detail';

import { useFilters, useSwal, useToggle } from '@kiper/hooks';
import {
  MdAdd,
  MdDeleteOutline,
  MdOutlineModeEditOutline,
} from 'react-icons/md';

import { ExpectedGuestModal } from '../../../../components/Modals';

const ExpectedGuest = ({ personContextId, user, isBlocked }) => {
  const [t] = useTranslation('user');
  const theme = useTheme();
  const { swal, toast } = useSwal();
  const [toRemove, setToRemove] = useState(null);

  const [editionMode, setEditionMode] = useState(false);

  const [invitesModalOpen, toggleInvitesModalOpen] = useToggle(false);

  const columns = useRef([
    {
      name: t('put.access-data.expected-guest.table.name'),
      align: 'left',
      width: '30%',
      order: '',
    },
    {
      name: t('put.access-data.expected-guest.table.start-date'),
      align: 'left',
      width: '15%',
      order: '',
    },
    {
      name: t('put.access-data.expected-guest.table.end-date'),
      align: 'left',
      width: '15%',
      order: '',
    },
    {
      name: t('put.access-data.expected-guest.table.created-by'),
      align: 'left',
      width: '30%',
    },
    { key: 4, name: '', align: 'right', width: '5%' },
  ]);

  const initialFilters = {
    page: 1,
    pagesize: 5,
    orderby: '',
    ordination: false,
  };

  const { handlePageChange, handlePageSize, filters } = useFilters(
    initialFilters,
  );

  const [deleteExpectedGuest] = useMutation(mutations.deleteExpectedGuests, {
    onCompleted: () => {
      toast.fire({
        icon: 'success',
        title: t(
          'put.access-data.expected-guest.feedbacks.removed-successfully',
        ),
      });
    },
    update: cache => {
      const expectedGuestsQuery = {
        query: queries.expectedGuestsByDweller,
        variables: {
          personContextId,
          filters,
        },
      };

      try {
        const { expectedGuestsByDweller } = cache.readQuery(
          expectedGuestsQuery,
        );

        const newExpectedGuestsCollection = expectedGuestsByDweller.collection;

        const guestIndex = newExpectedGuestsCollection.findIndex(
          guest => guest.inviteId === toRemove.inviteId,
        );

        newExpectedGuestsCollection.splice(guestIndex, 1);

        setToRemove(null);

        return cache.writeQuery({
          ...expectedGuestsQuery,
          data: {
            expectedGuestsByDweller: {
              ...expectedGuestsByDweller,
              collection: newExpectedGuestsCollection,
              pagination: {
                ...expectedGuestsByDweller.pagination,
                totalResults:
                  expectedGuestsByDweller.pagination.totalResults - 1,
              },
            },
          },
        });
      } catch (err) {
        return err;
      }
    },
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const { data, loading } = useQuery(queries.expectedGuestsByDweller, {
    variables: { personContextId, filters },
    fetchPolicy: 'cache-first',
  });

  const handleDelete = guest => {
    deleteExpectedGuest({
      variables: { inviteId: Number(guest.inviteId) },
    });
  };

  const showRemoveAlert = guest => {
    setToRemove(guest);
    swal.fire({
      title: t('put.access-data.expected-guest.feedbacks.remove'),
      text: t('put.access-data.expected-guest.feedbacks.really-remove'),
      cancelButtonText: t('put.access-data.expected-guest.cancel'),
      confirmButtonText: t('put.access-data.expected-guest.confirm'),
      showLoaderOnConfirm: true,
      preConfirm: () => handleDelete(guest),
      allowOutsideClick: () => !swal.isLoading(),
    });
  };

  return (
    <>
      {invitesModalOpen ? (
        <ExpectedGuestModal
          user={user}
          filters={filters}
          editionMode={!!editionMode}
          personContextId={Number(personContextId)}
          inviteId={Number(editionMode)}
          onToggle={toggleInvitesModalOpen}
        />
      ) : null}

      <Flex flex={1} flexDirection="column">
        <Flex
          mt="20px"
          mb="15px"
          alignItems="center"
          justifyContent="space-between"
          flex={1}
        >
          <Text color="highBlack">
            {t('put.access-data.expected-guest.title')}
          </Text>
          <KiperButton
            type="button"
            disabled={isBlocked}
            onClick={() => {
              toggleInvitesModalOpen(true);
              setEditionMode(false);
            }}
            rightIcon={<MdAdd />}
          >
            {t('put.access-data.expected-guest.add')}
          </KiperButton>
        </Flex>

        {loading && (
          <Flex p="30px" alignItem="center" justifyContent="center">
            <Spinner size="30px" />
          </Flex>
        )}

        {!loading && (
          <Flex
            width="100%"
            bg="gray50"
            m="5px"
            borderRadius="5px"
            border={`1px solid ${theme.colors.secondary400}`}
          >
            {!data?.expectedGuestsByDweller?.collection.length ? (
              <Flex flex={1} justifyContent="center" p={10}>
                <Text>
                  {t('put.access-data.expected-guest.table.empty-list')}
                </Text>
              </Flex>
            ) : (
              <Flex
                borderRadius="8px"
                bg="white"
                flex={1}
                flexDirection="column"
              >
                <Table columns={columns}>
                  <tbody style={{ background: 'white' }}>
                    {data?.expectedGuestsByDweller?.collection.map(
                      (guest, i) => (
                        <tr key={i}>
                          <td align="left">
                            <Text mr="10px" fontSize="14px">
                              {guest.inviteName}
                            </Text>
                          </td>

                          <td align="left">
                            <Text>
                              {
                                formatDate(guest.startDate, 'DD/MM - HH:mm')
                                  .formatted
                              }
                            </Text>
                          </td>

                          <td align="left">
                            <Text>
                              {
                                formatDate(guest.endDate, 'DD/MM - HH:mm')
                                  .formatted
                              }
                            </Text>
                          </td>

                          <td align="left">
                            {guest?.createPersonInvite || '-'}
                          </td>

                          <td align="right">
                            <Flex justifyContent="end">
                              <Flex>
                                <KiperButton
                                  type="button"
                                  size="md"
                                  onClick={() => {
                                    toggleInvitesModalOpen(true);
                                    setEditionMode(guest.inviteId);
                                  }}
                                  icon={
                                    <MdOutlineModeEditOutline fontSize={20} />
                                  }
                                />
                              </Flex>
                              <Flex mx="4px">
                                <KiperButton
                                  type="button"
                                  size="md"
                                  color="danger"
                                  onClick={() => showRemoveAlert(guest)}
                                  icon={<MdDeleteOutline fontSize={20} />}
                                />
                              </Flex>
                            </Flex>
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </Table>
                <Pagination
                  translate={t}
                  pagination={data?.expectedGuestsByDweller?.pagination}
                  handlePageSize={handlePageSize}
                  next={() => handlePageChange(1)}
                  previous={() => handlePageChange(-1)}
                />
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    </>
  );
};

ExpectedGuest.propTypes = {
  personContextId: propTypes.number.isRequired,
  user: propTypes.object.isRequired,
  isBlocked: propTypes.bool.isRequired,
};

export default ExpectedGuest;
