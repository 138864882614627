import propTypes from 'prop-types';
import { Overlay, Title, Message, ActionsContainer, Button } from './styles';

const ScreenState = ({ title, message, image, actions }) => {
  return (
    <Overlay>
      {image}
      <Title>{title}</Title>
      <Message>{message}</Message>
      <ActionsContainer>
        {actions?.map(({ text, color, variant, margin, onClick }, index) => (
          <Button
            color={color}
            onClick={onClick}
            variant={variant ?? 'contained'}
            margin={margin}
            key={`screen-state-action-${index}`}
          >
            {text}
          </Button>
        ))}
      </ActionsContainer>
    </Overlay>
  );
};

ScreenState.propTypes = {
  title: propTypes.string.isRequired,
  actions: propTypes.array.isRequired,
  message: propTypes.string.isRequired,
  image: propTypes.any.isRequired,
};

export default ScreenState;
