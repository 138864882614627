import { useMemo, useContext } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text, Tooltip, KiperButton } from '@kiper/ui';
import {
  FiAlertTriangle,
  FiList,
  FiNavigation,
  FiMapPin,
  FiPackage,
} from 'react-icons/fi';
import { BiChip } from 'react-icons/bi';
import { MdLogout, MdLogin } from 'react-icons/md';
import { getUserLang, formatDistanceDate, toUpperCamelCase } from '@kiper/fns';
import { useToggle } from '@kiper/hooks';
import emergencyCommandContext from '../../emergencyCommandContext';
import {
  profileTypes,
  profileColor,
  destinationTypes,
  contactTypes,
} from '../../../../constants';
import { sendAmplitudeData } from '../../../../services/amplitude';
import { useCurrentLoggedContext, useVoice } from '../../../../hooks';
import { EmergencyPopover } from '../Popovers';
import NoKiperVoiceAgentModal from '../../../../components/Modals/NoKiperVoiceAgentModal';
import NoKiperVoiceModal from '../../../../components/Modals/NoKiperVoiceModal';
import UserInfo from '../../../../components/UserInfo';
import {
  Badge,
  TextTitle,
  TextName,
  TextDescription,
  TextAction,
} from './styles';

const EventDetailsHeader = ({
  event,
  popoverVisible,
  onClickEventTimeline,
  onClickLockerHistory,
  onClickEventActivity,
  onClickIot,
  disabled,
}) => {
  const { clickToCall, loading } = useVoice();

  const [
    openNoKiperVoiceAgentModal,
    toggleOpenNoKiperVoiceAgentModal,
  ] = useToggle(false);

  const [openNoKiperVoiceModal, toggleOpenNoKiperVoiceModal] = useToggle(false);

  const [t] = useTranslation('event_attendance');
  const emergency = useContext(emergencyCommandContext);

  const [emergencyPopoverVisible, toggleEmergencyPopoverVisible] = useToggle();

  const lang = useMemo(() => {
    return getUserLang().split('-')[0];
  }, []);

  const { loggedContext } = useCurrentLoggedContext();

  const hasKiperVoiceAgent = !!loggedContext?.personContextParams?.find(
    item => item.name === 'kiperVoiceAgent' && item.id,
  );

  const hasKiperVoice = loggedContext?.partner?.hasKiperVoice;

  const { prefixPABX, extension, codeAta, isVideoCall } = useMemo(
    () => ({
      prefixPABX:
        event?.person?.unityGroup?.serializedParams &&
        JSON.parse(event.person.unityGroup.serializedParams)?.prefixPABX,
      extension:
        event?.person?.unity?.serializedParams &&
        JSON.parse(event.person.unity.serializedParams)?.extension,
      codeAta:
        event?.person?.unityGroup?.serializedParams &&
        JSON.parse(event.person.unityGroup.serializedParams)?.codeAta,
      isVideoCall: event?.additionalInformation?.videoCall,
    }),
    [event],
  );

  const handleClickToCall = callObject => {
    // change the true statement for a kiper voice validator
    if (!hasKiperVoice) return toggleOpenNoKiperVoiceModal();
    if (!hasKiperVoiceAgent) return toggleOpenNoKiperVoiceAgentModal();

    const { destination, destinationKind } = callObject;

    const personContextId = event?.person
      ? event?.person.personContexts[0].id
      : null;

    const voiceCallInput = {
      destination: String(destination),
      destinationKind,
      [personContextId && 'personContextId']: +personContextId,
    };

    if (
      voiceCallInput.destinationKind === destinationTypes?.intercomAta &&
      codeAta
    ) {
      voiceCallInput.codAta = codeAta;
    }

    const feedbackText =
      destinationKind === destinationTypes.intercomAta
        ? t('making-call-to-extension', {
            number: destination,
          })
        : t('making-call-to', { number: destination });

    return clickToCall({
      voiceCallInput,
      eventId: event?.eventId,
      feedbackText,
      partner: event?.partner?.name,
      condominium: event?.condominium?.name,
      eventCode: event?.eventType,
    });
  };

  const options = [];

  if (extension) {
    options.push({
      label: t('intercom'),
      destination: `${prefixPABX || ''}${extension}`,
      destinationKind: destinationTypes.intercomAta,
    });
  }

  if (event?.person?.phone) {
    options.push({
      name: event.person.name,
      label: t('phone'),
      destination: event.person.phone,
      contactTypeId: contactTypes.phone,
      destinationKind: destinationTypes.externalPhone,
    });
  }

  if (event?.person?.additionalContacts?.length) {
    const additionalContacts = event.person.additionalContacts.filter(
      contact => contact.contactTypeId === contactTypes.phone,
    );

    if (
      event?.person?.phone &&
      additionalContacts.length &&
      event.person.phone !== additionalContacts[0].contact
    ) {
      options.push({
        name: event.person.name,
        label: t('second-phone'),
        destination: event?.person?.additionalContacts[0].contact,
        contactTypeId: event?.person?.additionalContacts[0].contactTypeId,
        destinationKind: destinationTypes.externalPhone,
      });
    }
  }

  const iotEvent = useMemo(() => {
    if (event?.additionalInformation?.iotEvent?.activatedBy && event?.device) {
      const { activatedBy } = event?.additionalInformation?.iotEvent;

      const split = activatedBy.split(' ');
      const action = split[split.length - 1];
      const description = activatedBy.replace(action, '');

      return {
        name: event?.device?.name,
        description,
        action,
      };
    }

    if (event?.additionalInformation?.triggeredBy?.rfNotFound) {
      return {
        name: t('common:feedback.not-found', {
          device: 'RF',
        }),
        description: `ID ${event?.additionalInformation?.triggeredBy.rfNotFound}`,
      };
    }

    if (event?.additionalInformation?.triggeredBy?.tagNotFound) {
      return {
        name: t('common:feedback.not-found', {
          device: 'Tag',
        }),
        description: `ID ${event?.additionalInformation?.triggeredBy.tagNotFound}`,
      };
    }
    return null;
  }, [event]);

  const placeSerializedParams = useMemo(
    () =>
      event?.place?.serializedParams
        ? JSON.parse(event.place.serializedParams)
        : null,
    [event],
  );

  const sendAmplitude = key => {
    sendAmplitudeData(key, {
      eventId: event?.eventId,
      user: loggedContext?.email,
      condominium: event?.condominium?.name,
      partner: loggedContext?.partner?.name,
    });
  };

  const personProfile = useMemo(() => {
    const profileFieldName =
      event?.person?.personContexts?.[0]?.profile?.fieldName;
    if (profileFieldName) {
      return t(`common:profiles.${profileTypes[profileFieldName]}`);
    }
    return t(`common:profiles.${profileTypes.resident}`);
  }, [event?.person]);

  return (
    <>
      <Flex
        mt="10px"
        bg="white"
        justifyContent="space-between"
        px="16px"
        pb="12px"
        zIndex={3}
      >
        <Flex flex={1} flexDirection="column">
          <Flex alignItems="center" justifyContent="space-between" mb="4px">
            <Text mb="4px" fontSize="18px" fontWeight="700">
              {event?.coPartner &&
                event?.partner?.name &&
                `${event?.partner?.name} - `}
              {!!event?.condominium?.name && event.condominium.name}
            </Text>

            <Flex>
              <>
                <Box
                  id="locker-history-container"
                  mr="8px"
                  height="fit-content"
                >
                  <KiperButton
                    color="neutral"
                    variant="outlined"
                    id="locker-history"
                    icon={<FiPackage />}
                    rounded
                    onClick={() => {
                      sendAmplitude('clicked on locker history header menu');
                      onClickLockerHistory();
                    }}
                    disabled={!!disabled || !event.hasLocker}
                  />
                </Box>
                <Tooltip placement="bottom" target="locker-history-container">
                  {t('details.fast-actions.locker-history')}
                </Tooltip>
              </>

              <Box id="event-history-container" mr="8px" height="fit-content">
                <KiperButton
                  color="neutral"
                  variant="outlined"
                  id="event-history"
                  icon={<FiList />}
                  rounded
                  onClick={() => {
                    sendAmplitude('clicked on event history header menu');
                    onClickEventTimeline();
                  }}
                  disabled={!!disabled}
                />
              </Box>
              <Tooltip placement="bottom" target="event-history-container">
                {t('details.fast-actions.event-history')}
              </Tooltip>

              <Box id="event-activity-container" mr="8px" height="fit-content">
                <KiperButton
                  color="neutral"
                  variant="outlined"
                  id="event-activity"
                  icon={<FiNavigation />}
                  rounded
                  onClick={() => {
                    sendAmplitude('clicked on event activity header menu');
                    onClickEventActivity();
                  }}
                  disabled={!!disabled}
                />
                <Tooltip placement="bottom" target="event-activity-container">
                  {t('details.fast-actions.event-activity')}
                </Tooltip>
              </Box>

              <Box id="iot-container" height="fit-content" mr="8px">
                <KiperButton
                  color="neutral"
                  variant="outlined"
                  id="iot"
                  icon={<BiChip />}
                  rounded
                  onClick={() => {
                    sendAmplitude('clicked on iot header menu');
                    onClickIot();
                  }}
                  disabled={
                    !!disabled ||
                    (!event?.iotDevices?.length && !event?.alarmDevices?.length)
                  }
                />
              </Box>
              <Tooltip placement="bottom" target="iot-container">
                {t('Iot')}
              </Tooltip>

              <Box id="emergency-popover-container" height="fit-content">
                <KiperButton
                  color="danger"
                  variant={emergency.isActive ? 'contained' : 'out'}
                  onClick={() => {
                    sendAmplitude('clicked on emergency header menu');
                    toggleEmergencyPopoverVisible();
                  }}
                  id="emergency-popover"
                  icon={<FiAlertTriangle />}
                  rounded
                  disabled={!!disabled}
                />
                <Tooltip
                  placement="bottom"
                  target="emergency-popover-container"
                >
                  {t('details.header.emergency.title')}
                </Tooltip>
              </Box>
            </Flex>
          </Flex>

          <Flex>
            {emergency?.status && emergency.status !== 'not-active' ? (
              <Badge
                color={
                  emergency.isLoading ? 'warningSecondary' : 'dangerSecondary'
                }
                fontColor={emergency.isLoading ? 'black' : 'white'}
                noCapitalize
              >
                {t(`details.header.emergency.${emergency.status}`)}
              </Badge>
            ) : null}
            <Flex
              justifyContent="flex-start"
              alignItems="center"
              flexWrap="wrap"
            >
              {!!event?.creationDate && (
                <>
                  <Text fontWeight="bold" color="mediumBlack">
                    {isVideoCall
                      ? t('video-call-answering')
                      : toUpperCamelCase(
                          `${event?.eventTypeDescription[lang]}`,
                        )}
                  </Text>
                  <Text mx="10px"> - </Text>
                  <Text mr="10px" color="mediumBlack">
                    {formatDistanceDate(event?.creationDate, t)}
                  </Text>
                </>
              )}
              {event.place && (
                <Badge fontColor="white" color="infoPrimary">
                  {!placeSerializedParams && event.place.name}
                  {!!placeSerializedParams &&
                  placeSerializedParams?.external ? (
                    <>
                      <MdLogout />
                      {t('details.header.place.external', {
                        placeName: event.place.name,
                      })}
                    </>
                  ) : (
                    <>
                      <MdLogin />
                      {t('details.header.place.internal', {
                        placeName: event.place.name,
                      })}
                    </>
                  )}
                </Badge>
              )}
              {event?.additionalInformation?.contactId?.partitionId &&
                event?.additionalInformation?.contactId?.argumentId && (
                  <Box ml="5px">
                    <Badge fontColor="black" color="info500">
                      <FiMapPin />

                      {event.additionalInformation.contactId.argumentType ===
                      'ZONE'
                        ? t('details.header.zone-partition', {
                            zone:
                              event?.additionalInformation?.contactId
                                ?.argument ||
                              event.additionalInformation.contactId.argumentId,
                            partition:
                              event?.additionalInformation?.contactId
                                ?.partition ||
                              event.additionalInformation.contactId.partitionId,
                          })
                        : t('details.header.user-partition', {
                            user:
                              event?.additionalInformation?.contactId
                                ?.argument ||
                              event.additionalInformation.contactId.argumentId,
                            partition:
                              event?.additionalInformation?.contactId
                                ?.partition ||
                              event.additionalInformation.contactId.partitionId,
                          })}
                    </Badge>
                  </Box>
                )}
            </Flex>
          </Flex>

          <Flex flex={1}>
            {event?.device?.serializedParams &&
              JSON.parse(event?.device?.serializedParams)?.extension && (
                <>
                  <Flex mr="20px" flexDirection="column">
                    <Text mb="4px" color="secondary700" fontSize="12px">
                      {t('called-by')}
                    </Text>
                    <UserInfo
                      noAvatar
                      options={[
                        {
                          name: event?.device?.name,
                          label: t('device'),
                          destination: JSON.parse(
                            event?.device?.serializedParams,
                          )?.extension,
                          destinationKind: destinationTypes.intercom,
                        },
                      ]}
                      disabled={loading}
                      user={event.device}
                      contactType="phone"
                      secondaryInfo={`${t('extension')} ${
                        JSON.parse(event?.device?.serializedParams)?.extension
                      }`}
                      popoverVisible={popoverVisible}
                      onClickPhone={handleClickToCall}
                    />
                  </Flex>
                </>
              )}

            {iotEvent && (
              <Flex flexDirection="column">
                <TextTitle>{t('fired-by')}</TextTitle>
                <TextName>{iotEvent?.name}</TextName>
                <Flex flexDirection="row">
                  <TextDescription>{iotEvent?.description}</TextDescription>
                  <TextAction>{iotEvent?.action}</TextAction>
                </Flex>
              </Flex>
            )}

            {event?.person && (
              <>
                <Flex mr="20px" flexDirection="column">
                  <Text mb="4px" color="secondary700" fontSize="12px">
                    {t('fired-by')}
                  </Text>
                  <UserInfo
                    options={options}
                    disabled={loading}
                    badge
                    user={event.person}
                    contactType="phone"
                    profile={personProfile}
                    profileColor={profileColor.resident}
                    popoverVisible={popoverVisible}
                    onClickPhone={handleClickToCall}
                  />
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
      {emergencyPopoverVisible && (
        <EmergencyPopover
          isOpen={!!emergencyPopoverVisible}
          onClose={toggleEmergencyPopoverVisible}
        />
      )}
      {openNoKiperVoiceAgentModal && (
        <NoKiperVoiceAgentModal
          open={openNoKiperVoiceAgentModal}
          onToggle={toggleOpenNoKiperVoiceAgentModal}
        />
      )}

      {openNoKiperVoiceModal && (
        <NoKiperVoiceModal
          open={openNoKiperVoiceModal}
          onToggle={toggleOpenNoKiperVoiceModal}
        />
      )}
    </>
  );
};

EventDetailsHeader.propTypes = {
  event: propTypes.any.isRequired,
  popoverVisible: propTypes.bool.isRequired,
  disabled: propTypes.bool,
  onClickIot: propTypes.func.isRequired,
  onClickEventTimeline: propTypes.func.isRequired,
  onClickLockerHistory: propTypes.func.isRequired,
  onClickEventActivity: propTypes.func.isRequired,
  formik: propTypes.shape({
    setFieldValue: propTypes.func,
  }),
};

EventDetailsHeader.defaultProps = {
  disabled: false,
  formik: null,
};

export default EventDetailsHeader;
