import { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { Table, Card, TableLoader, Tooltip } from '@kiper/ui';
import { phoneMask } from '@kiper/fns';
import Pagination from '../../../components/Pagination';
import EmptyState from '../../../components/EmptyState';
import GuestStatus from '../../../components/GuestStatus';
import SmsStatus from '../../../components/SmsStatus';
import emptyStatePlaceholder from '../../../assets/images/empty-state-users.svg';
import AdamSender from '../../../components/AdamSender';
import CopyInviteGuestUrl from '../../../components/CopyInviteGuestUrl';

const column = x => `guest-list.table-header.${x}`;

const initialFilters = {
  page: 1,
  pagesize: 10,
  orderby: 'guest.name',
  ordination: false,
};

export default function GuestList({ inviteId }) {
  const [t] = useTranslation('invite');

  const [filters, setFilters] = useState(initialFilters);

  const handlePageChange = quantity =>
    setFilters(x => ({ ...x, page: x.page + quantity }));

  const handlePageSize = pagesize =>
    setFilters(x => ({ ...x, pagesize: Number(pagesize), page: 1 }));

  const handleSort = orderby =>
    setFilters(x => ({
      ...x,
      orderby,
      ordination: orderby !== x.orderby ? false : !x.ordination,
    }));

  const columns = [
    {
      name: t(column('guest-name')),
      align: 'left',
      width: '25%',
      order: 'guest.name',
    },
    {
      name: t(column('guest-email')),
      align: 'left',
      width: '20%',
      order: 'guest.email',
    },
    {
      name: t(column('guest-phone')),
      align: 'left',
      width: '15%',
      order: 'guest.phone',
    },
    {
      name: t(column('guest-sms-status')),
      align: 'left',
      width: '10%',
      order: 'guest-status',
    },
    {
      name: t(column('guest-status')),
      align: 'left',
      width: '10%',
      order: 'guest-status',
    },
    {
      name: t(column('guest-cpu-status')),
      align: 'left',
      width: '10%',
    },
    {
      name: t(column('guest-invite-link')),
      align: 'left',
      width: '10%',
    },
  ];

  const { loading, data } = useQuery(GuestList.guestQuery, {
    variables: {
      inviteId,
      filters,
    },
  });

  const { guests } = data || { guests: {} };
  const { collection, pagination } = guests;

  return (
    <Card>
      <Card.Header>
        <Card.Title>{t('guest-list.title')}</Card.Title>
      </Card.Header>
      <Card.Body>
        {!collection && !loading ? (
          <EmptyState
            placeHolder={emptyStatePlaceholder}
            text={t('guest-list.empty-state')}
          />
        ) : (
          <>
            <Table
              columns={columns}
              sort={{
                handleSort,
                ordination: filters.ordination,
                orderby: filters.orderby,
              }}
            >
              {!loading && collection && (
                <tbody>
                  {collection.map(item =>
                    !item?.excludedDate ? (
                      <tr key={item.id}>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{phoneMask(item.phoneNumber).formatted}</td>
                        <td>
                          <SmsStatus status={item.smsStatus} />
                        </td>
                        <td>
                          <GuestStatus status={item.accessStatus} />
                        </td>
                        <td className="text-center">
                          <AdamSender
                            type="guest"
                            identifier={Number(item.id)}
                            status={item.syncStatus}
                          />
                        </td>
                        <td>
                          <CopyInviteGuestUrl
                            guestId={item.id}
                            disabled={!item.phoneNumber}
                          />
                        </td>
                      </tr>
                    ) : (
                      <>
                        <tr key={item.id} id={`tr-${item.id}`} disabled>
                          <td disabled>{item.name}</td>
                          <td disabled>{item.email}</td>
                          <td disabled>
                            {phoneMask(item.phoneNumber).formatted}
                          </td>
                          <td>
                            <SmsStatus status={item.smsStatus} />
                          </td>
                          <td>
                            <GuestStatus status={item.accessStatus} />
                          </td>
                          <td />
                        </tr>
                        <Tooltip placement="top" target={`tr-${item.id}`}>
                          {t('guest-list.guest-removed')}
                        </Tooltip>
                      </>
                    ),
                  )}
                </tbody>
              )}
            </Table>
            {loading && <TableLoader />}
            <Pagination
              filters={filters}
              pagination={pagination}
              handlePageSize={handlePageSize}
              next={() => handlePageChange(1)}
              previous={() => handlePageChange(-1)}
            />
          </>
        )}
      </Card.Body>
    </Card>
  );
}

GuestList.propTypes = {
  inviteId: propTypes.number.isRequired,
};

GuestList.guestQuery = gql`
  query guests($inviteId: Int!, $filters: GuestQueryInput!) {
    guests(inviteId: $inviteId, filters: $filters) {
      collection {
        id
        name
        phoneNumber
        email
        accessStatus
        smsStatus
        syncStatus
        excludedDate
      }
      pagination {
        totalPages
        totalResults
        page
        pageSize
      }
    }
  }
`;
