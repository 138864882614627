import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Tr,
  Td,
  DataContainer,
  ColumnData,
  Badge,
  ProfileData,
} from './styles';
import { profileColor, profileTypes } from '../../../../constants';

export const NameChildren = ({ primaryData, secondaryData, personId }) => {
  const [t] = useTranslation('common');
  return (
    <DataContainer>
      {personId ? (
        <Link to={`/users/${personId}`} target="_blank">
          <ColumnData link>{primaryData}</ColumnData>
        </Link>
      ) : (
        <ColumnData>{primaryData}</ColumnData>
      )}
      <Badge color={profileColor[secondaryData]}>
        {t(`profiles.${profileTypes[secondaryData]}`)}
      </Badge>
      <ProfileData>{t(`profiles.${profileTypes[secondaryData]}`)}</ProfileData>
    </DataContainer>
  );
};

export const DefaultChildren = ({ primaryData, secondaryData, thirdData }) => (
  <DataContainer>
    {primaryData && <ColumnData>{primaryData}</ColumnData>}
    {secondaryData && <ColumnData>{secondaryData}</ColumnData>}
    {thirdData && <ColumnData>{thirdData}</ColumnData>}
  </DataContainer>
);

const UserItem = ({ data, columns, last }) => (
  <Tr last={last}>
    {columns?.map(
      (
        { width, children: Children, primary, secondary, third, personId },
        index,
      ) => (
        <Td width={width} key={`user-report-column-${index}`}>
          <Children
            primaryData={data[primary]}
            secondaryData={data[secondary]}
            thirdData={data[third]}
            personId={data[personId]}
          />
        </Td>
      ),
    )}
  </Tr>
);

UserItem.propTypes = {
  data: propTypes.any.isRequired,
  columns: propTypes.array.isRequired,
  last: propTypes.bool.isRequired,
};

NameChildren.propTypes = {
  primaryData: propTypes.any.isRequired,
  secondaryData: propTypes.any.isRequired,
  personId: propTypes.any.isRequired,
};

DefaultChildren.propTypes = {
  primaryData: propTypes.any.isRequired,
  secondaryData: propTypes.any.isRequired,
  thirdData: propTypes.any.isRequired,
};

export default UserItem;
