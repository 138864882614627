import useReduceRemoteConfigFlags from './useReduceRemoteConfigFlags';

const UserPaths = {
  EXPECTED_GUEST: 'users.form.features.expectedGuest',
  CONTACT_ORDER: 'users.contactPriorityOrder',
  SHOW_PLACE_TEMPORARY_ACCESS: 'users.showPlaceTemporaryAccess',
};

export const useUserRemoteConfig = (loggedContext, condominium = null) => {
  const remoteConfig = useReduceRemoteConfigFlags(
    loggedContext,
    UserPaths,
    condominium,
  );

  const showExpectedGuest = remoteConfig[UserPaths.EXPECTED_GUEST];
  const showContactOrder = remoteConfig[UserPaths.CONTACT_ORDER];
  const showPlaceTemporaryAccess =
    remoteConfig[UserPaths.SHOW_PLACE_TEMPORARY_ACCESS];

  return {
    showExpectedGuest,
    showContactOrder,
    showPlaceTemporaryAccess,
  };
};
