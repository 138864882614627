import propTypes from 'prop-types';

import {
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';

import { FiPhone, FiChevronDown } from 'react-icons/fi';

import { useToggle } from '@kiper/hooks';

import {
  UserAvatar,
  KiperButton,
  Flex,
  Badge,
  Popover,
  PopoverBody,
  PopoverHeader,
  Text,
} from '@kiper/ui/src';

import { CustomButton as Button } from './styles';

const UserCard = ({
  user,
  disabled,
  badge,
  profile,
  profileColor,
  onClickAvatar,
  onClickPhone,
  options,
  loading,
  buttonText,
  noAvatar,
  secondaryInfo,
}) => {
  const [dropdownOpen, toggleDropdown, setValue] = useToggle(false);

  const ContactsOptions = () => {
    const phoneContactsOptions =
      options?.filter(opt => opt.contactTypeId === 2) || []; // 2 = CONTACT TYPE PHONE

    if (phoneContactsOptions.length === 1) {
      return (
        <KiperButton
          onClick={() => {
            onClickPhone(options[0]);
            setValue(true);
          }}
          disabled={disabled}
          size="sm"
          variant="out"
          dropdown
          rounded={!buttonText}
          icon={<FiPhone size={12} />}
        >
          {buttonText}
        </KiperButton>
      );
    }

    if (phoneContactsOptions.length > 1) {
      return (
        <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle nav>
            <KiperButton
              size="sm"
              variant="out"
              dropdown
              rightIcon={<FiChevronDown />}
              icon={<FiPhone size={12} />}
            >
              {buttonText}
            </KiperButton>
          </DropdownToggle>
          <DropdownMenu>
            {options.map((item, i) => (
              <DropdownItem
                key={i}
                onClick={() => {
                  onClickPhone(item);
                  setValue(true);
                }}
                disabled={loading}
              >
                <Flex disabled flexDirection="column">
                  <Text>{item.label}</Text>
                  <Text fontSize="11px" color="secondary500">
                    {item.phone}
                  </Text>
                </Flex>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </ButtonDropdown>
      );
    }

    return '';
  };

  return (
    <Flex alignItems="center">
      {!noAvatar && (
        <UserAvatar
          src={`//avatar.kiper.com.br/${user && user?.email}`}
          size={40}
          name={user && user?.name}
          round="100%"
          onClick={onClickAvatar}
          id={`profile-avatar-${user?.id}`}
        />
      )}
      <Flex ml={noAvatar ? 0 : '10px'} mr="5px" flexDirection="column">
        <Text block>{user?.name}</Text>
        {!badge && user?.email && <Text fontSize="14px">{user?.email}</Text>}
        {badge && <Badge color={profileColor}>{profile}</Badge>}
        {secondaryInfo && (
          <Text fontSize="12px" y="3.5px" color="secondary700">
            {secondaryInfo}
          </Text>
        )}
      </Flex>

      {options?.length > 0 && (
        <Flex ml="5px">
          <ContactsOptions />
        </Flex>
      )}
    </Flex>
  );
};

UserCard.propTypes = {
  buttonText: propTypes.string,
  loading: propTypes.bool,
  options: propTypes.array,
  user: propTypes.any.isRequired,
  badge: propTypes.bool.isRequired,
  profile: propTypes.any.isRequired,
  onClickAvatar: propTypes.func,
  onClickPhone: propTypes.func,
  profileColor: propTypes.string.isRequired,
  noAvatar: propTypes.bool,
  secondaryInfo: propTypes.string,
  disabled: propTypes.bool,
};

UserCard.defaultProps = {
  buttonText: null,
  loading: false,
  disabled: false,
  options: null,
  onClickAvatar: null,
  onClickPhone: null,
  noAvatar: false,
  secondaryInfo: null,
};

const UserInfo = ({
  disabled,
  user,
  badge,
  profile,
  profileColor,
  onClickAvatar,
  onClickPhone,
  popoverVisible,
  ...props
}) => (
  <>
    <UserCard
      disabled={disabled}
      user={user}
      badge={badge}
      profile={profile}
      profileColor={profileColor}
      onClickAvatar={onClickAvatar}
      onClickPhone={onClickPhone}
      {...props}
    />
    {!!onClickAvatar && popoverVisible && (
      <Popover
        isOpen={popoverVisible}
        target={`profile-avatar-${user?.id}`}
        toggle={onClickAvatar}
        placement="bottom"
        hideArrow
        fade
        trigger="legacy"
        innerClassName="customInner"
        popperClassName="customPopper"
      >
        <PopoverHeader>
          <UserCard
            disabled={disabled}
            user={user}
            badge={badge}
            profile={profile}
          />
        </PopoverHeader>
        <PopoverBody>
          <Flex ml="10px" mr="5px" flexDirection="column">
            <Flex flexDirection="column" mx="10px" width="fit-content">
              <Text fontSize="14px" fontWeight="bold">
                GU + Unidade
              </Text>
              <Text>Bloco 2 - AP 101</Text>
            </Flex>
            <Flex flexDirection="column" mx="10px" width="fit-content">
              <Text fontSize="14px" fontWeight="bold">
                Telefone
              </Text>
              <Text>+55 19 99999999</Text>
            </Flex>
            <Button color="secondary" outline block>
              Ver Perfil
            </Button>
          </Flex>
        </PopoverBody>
      </Popover>
    )}
  </>
);

export default UserInfo;

UserInfo.propTypes = {
  user: propTypes.object.isRequired,
  // contactType: propTypes.oneOf(['email', 'phone']),
  badge: propTypes.bool,
  disabled: propTypes.bool,
  profile: propTypes.string,
  profileColor: propTypes.string,
  popoverVisible: propTypes.bool,
  onClickAvatar: propTypes.func,
  onClickPhone: propTypes.func,
};

UserInfo.defaultProps = {
  // contactType: 'email',
  disabled: false,
  badge: false,
  profile: 'Morador',
  profileColor: 'primary50',
  popoverVisible: false,
  onClickAvatar: null,
  onClickPhone: null,
};
