import { useState } from 'react';

const defaultRemoveCompareItemFromTuple = (a, b) => a !== b;

export default function useToggleTuple(
  config = {
    initialTuple: [],
    compare: defaultRemoveCompareItemFromTuple,
  },
) {
  const [tuple, setTuple] = useState(config.initialTuple);

  const toggleItemTuple = item => {
    if (!item)
      throw new Error(
        `O item da tupla não pode ser undefined, vazio ou nulo. Item: ${item} (type: ${typeof item})`,
      );

    let newList = [...tuple];

    if (tuple.includes(item)) {
      newList = newList.filter(i => config.compare(i, item));
    } else {
      newList.push(item);
    }

    setTuple(newList);
  };

  return [tuple, toggleItemTuple, setTuple];
}
