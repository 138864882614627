import { useContext, useMemo } from 'react';
import propTypes from 'prop-types';
import useCurrentLoggedContext from './useCurrentLoggedContext';
import { FeatureFlagContext } from '../services/feature-flag';

export default function useFeatureFlag({ flag, feature }) {
  const featureFlagContext = useContext(FeatureFlagContext);
  const { loggedContext } = useCurrentLoggedContext();

  const { flags: featureFlags, loading } = featureFlagContext;

  const eventFlag = useMemo(() => featureFlags?.[flag]?.[feature] || null, []);

  const { partnerContextId, personContextId } = useMemo(
    () => ({
      partnerContextId: loggedContext?.partner?.personContextId,
      personContextId: loggedContext?.personContextId,
    }),
    [loggedContext],
  );

  const showFeature = useMemo(() => {
    if (!!eventFlag && !loading) {
      if (!eventFlag?.users?.length && !eventFlag?.partners?.length) {
        return true;
      }
      if (eventFlag?.users?.some(id => +personContextId === id)) {
        return true;
      }
      if (eventFlag?.partners?.some(id => +partnerContextId === id)) {
        return true;
      }
      return false;
    }
    return false;
  }, [eventFlag]);

  return { showFeature };
}

useFeatureFlag.propTypes = {
  flag: propTypes.string.isRequired,
  feature: propTypes.string.isRequired,
};
