import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { FaRegCalendarCheck, FaRegCalendarTimes } from 'react-icons/fa';
import { MdLocalPlay, MdEvent } from 'react-icons/md';
import { Card, Badge, Label, Grid } from '@kiper/ui';
import { formatDate, compareDateFromNow } from '@kiper/fns';
import { inviteColor } from '../../../constants';
import { Row, Icon, SpaceBetWeen } from './styles';
import FreeAccess from '../../../components/Badges/FreeAccess';

const status = x => `status.${x}`;

const EventInfo = ({ event }) => {
  const [t] = useTranslation('invite');

  return (
    <Card>
      <Card.Header>
        <Card.Title>{t('event.infoTitle')}</Card.Title>
        {compareDateFromNow(event.end).isAfter ? (
          <Badge color={inviteColor.status.active}>
            <FaRegCalendarCheck size={15} />
            {t(status('active'))}
          </Badge>
        ) : (
          <Badge color={inviteColor.status.inactive}>
            <FaRegCalendarTimes size={15} />
            {t(status('inactive'))}
          </Badge>
        )}
      </Card.Header>
      <Card.Body>
        <Grid.Col>
          <Row>
            <Icon>
              <MdLocalPlay size={20} />
            </Icon>
            <div>
              <Label info>{t('event.name')}</Label>
              <Label>{event.name}</Label>
            </div>
          </Row>
          <Row>
            <Icon>
              <MdEvent size={20} />
            </Icon>
            <SpaceBetWeen>
              <div>
                <Label info>{t('event.start')}</Label>
                <Label>{formatDate(event.start, 'L - HH:mm').formatted}</Label>
              </div>
              <div>
                <Label info>{t('event.end')}</Label>
                <Label>{formatDate(event.end, 'L - HH:mm').formatted}</Label>
              </div>
            </SpaceBetWeen>
          </Row>
          <Row last>
            <FreeAccess free={event?.isFreeAccess} />
          </Row>
        </Grid.Col>
      </Card.Body>
    </Card>
  );
};

EventInfo.propTypes = {
  event: propTypes.object.isRequired,
};

export default EventInfo;
