import { useState, useEffect } from 'react';
import { addSeconds, subSeconds, parseZonedTime } from '@kiper/fns';

const useVideo = ({ eventDate }) => {
  const [isLive, setIsLive] = useState(false);
  const [frozen, setFrozen] = useState(true);
  const [time, setTime] = useState(parseZonedTime(eventDate));
  const [active, setActive] = useState(false);

  useEffect(() => {
    let interval = null;
    if (active) {
      interval = setInterval(() => {
        setTime(addSeconds(time, 1));
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [time, active]);

  const onForward = async () => {
    if (!active) setActive(true);

    const result = addSeconds(time, 10);

    await setTime(result);

    return result;
  };

  const onRewind = async () => {
    if (!active) setActive(true);

    const result = subSeconds(time, 10);

    await setTime(result);

    return result;
  };

  const onLiveClicked = live => setIsLive(live);

  const onTurnLiveOn = () => {
    setActive(true);
    setFrozen(false);
  };

  const onFrozenClicked = () => {
    setActive(!active);
    setFrozen(x => !x);
  };

  const resetTime = () => setTime(parseZonedTime(eventDate));

  return {
    isLive,
    time,
    frozen,
    onLiveClicked,
    onForward,
    onRewind,
    onFrozenClicked,
    onTurnLiveOn,
    resetTime,
  };
};

export default useVideo;
