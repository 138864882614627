import styled from 'styled-components';
import { ScrollBar, Flex } from '@kiper/ui';

export const FilterButtonItem = styled(Flex)`
  flex: 0 0 1;
  flex-shrink: 0;
  background-color: ${props =>
    props.selected ? props.theme.colors.primary500 : props.theme.colors.white};
  color: ${props =>
    props.selected ? props.theme.colors.white : props.theme.colors.primary500};
  padding: 10px;
  height: 36px;
  border-radius: 4px;
  border: solid 1px ${props => props.theme.colors.primary500};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & + & {
    margin-left: 5px;
  }
`;

export const PlaceScrollbar = styled(ScrollBar)`
  display: flex;
  padding-bottom: 15px;
  margin-top: 0;
  margin-left: 16px;
  flex: 1;
`;
