import styled from 'styled-components';
import { Label as label } from '@kiper/ui/src';

export const AvatarContainer = styled.div`
  margin: 10px;
`;

export const Label = styled(label)`
  margin: 0;
  & + & {
    margin-bottom: 6px;
  }
`;
