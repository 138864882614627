import { useLayoutEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TableLoader, Table, Pagination } from '@kiper/ui';
import { useFilters } from '@kiper/hooks';
import TableRow from './TableRow';
import EmptyState from './EmptyState';
import { createColumns, INITIAL_FILTERS } from '../settings';
import {
  useFetchLockerDeliveryHistory,
  useFormatLockerDeliveryHistoryData,
} from '../hooks';
import { TableContainer, TableFooter } from './styles';

const LockerDeliveryHistoryTable = ({ condominiumPersonContextId }) => {
  const tableContainerRef = useRef(null);
  const [t] = useTranslation('modals/locker-delivery-history-modal');
  const columns = createColumns(t);

  const [tableContainerHeight, setTableContainerHeight] = useState('auto');

  const { filters, setFilters, handlePageChange } = useFilters(INITIAL_FILTERS);

  useLayoutEffect(() => {
    if (tableContainerRef?.current) {
      tableContainerRef.current.scrollTo(0, 0);
      setTableContainerHeight(`${tableContainerRef.current.clientHeight}px`);
    }
  }, [filters, tableContainerRef?.current]);

  const [
    lockerDeliveryData,
    loadingDeliveryHistoryInfo,
  ] = useFetchLockerDeliveryHistory({
    condominiumPersonContextId,
    filters,
    setFilters,
  });

  const [
    listLockerDeliveryHistory,
    pagination,
  ] = useFormatLockerDeliveryHistoryData(
    lockerDeliveryData?.lockerDeliveryHistory,
  );

  if (loadingDeliveryHistoryInfo)
    return (
      <TableContainer height={tableContainerHeight}>
        <TableLoader />
      </TableContainer>
    );

  return (
    <>
      <TableContainer ref={tableContainerRef} height={tableContainerHeight}>
        {listLockerDeliveryHistory?.length ? (
          <>
            <Table columns={columns}>
              <tbody>
                {listLockerDeliveryHistory.map(info => (
                  <TableRow key={info.id} rowData={info} />
                ))}
              </tbody>
            </Table>
            <TableFooter>
              <Pagination
                fixedPageSize
                translate={t}
                pagination={pagination}
                handlePageChange={handlePageChange}
                next={() => handlePageChange(1)}
                previous={() => handlePageChange(-1)}
              />
            </TableFooter>
          </>
        ) : (
          <EmptyState
            title={t('empty-state.title')}
            description={t('empty-state.description')}
          />
        )}
      </TableContainer>
    </>
  );
};

export default LockerDeliveryHistoryTable;

LockerDeliveryHistoryTable.propTypes = {
  condominiumPersonContextId: propTypes.any.isRequired,
};
