import styled from 'styled-components';
import { FormGroup as formGroup } from 'reactstrap';

export const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  gap: 8px;
  width: 100%;
`;

export const FieldWrapper = styled(formGroup)`
  all: unset;
  grid-column: span ${props => props.col};
`;
