import { Flex, MdIcons } from '@kiper/ui';
import propTypes from 'prop-types';
import * as S from './styles';

function InformationTopics({ iconName, title, subtitle }) {
  return (
    <Flex gridGap="16px">
      <S.WrapperIcon>
        <MdIcons mdIconName={iconName} size="24" />
      </S.WrapperIcon>
      <Flex flexDirection="column">
        <S.Text opacity="0.9" size="16px" weight="600" py="12px">
          {title}
        </S.Text>
        <S.Text opacity="0.7">{subtitle}</S.Text>
      </Flex>
    </Flex>
  );
}

export default InformationTopics;

InformationTopics.propTypes = {
  iconName: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
  subtitle: propTypes.string.isRequired,
};
