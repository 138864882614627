import propTypes from 'prop-types';
import { useFormikContext, ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-apollo';
import { removeProperty, apolloDataErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { useHistory } from 'react-router-dom';
import {
  UserAvatar,
  Input as DebouncedInput,
  KiperButton,
  Label,
  ErrorMessage as ErrorMessageComponent,
} from '@kiper/ui';
import { dweller as dwellerGql } from '@kiper/monitoring-graphql';
import KiperAccount from '../../../components/KiperAccount';
import {
  Col,
  MainInfoContainer,
  AvatarContainer,
  FormGroup,
  Flex,
} from './styles';
import KiperEmailInput from '../../../components/KiperEmailInput';
import PersonPhoneInput from '../../../components/PersonPhoneInput';
import { Documents } from './Document';
import { Contacts } from './Contacts';

const UserInfo = ({
  isEdition,
  handleEditMode,
  editMode,
  savedEmail,
  savedDocuments,
  onSaveEmail,
}) => {
  const [t] = useTranslation('user');
  const { toast, linkToast } = useSwal();
  const history = useHistory();
  const {
    values,
    setFieldValue: handleChange,
    errors,
    touched,
    setFieldTouched,
  } = useFormikContext();

  const [update, { loading }] = useMutation(
    dwellerGql.register.mutations.updateDweller,
    {
      onCompleted: () => {
        onSaveEmail(values.email || null);
        toast.fire({ icon: 'success', title: t('put.success-message') });
        handleEditMode();
      },
      onError: err => {
        const formattedErrors = apolloDataErrorHandler(err);
        if (formattedErrors?.specificities) {
          linkToast({
            title: formattedErrors.message,
            showCloseButton: true,
            icon: 'error',
            linkLabel: formattedErrors.specificities.personName,
            href: `/users/${formattedErrors.specificities.personId}`,
            timer: 0,
          });
        } else if (formattedErrors && formattedErrors.length) {
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
        }
      },
    },
  );

  const filterEmptyDocuments = documents => {
    return documents
      .map(({ __typename, isUpdated, ...doc }) => {
        const newDoc = doc;

        if (!doc.value && doc.id) {
          Object.assign(newDoc, { isDeleted: true });
        } else if (isUpdated) {
          Object.assign(newDoc, { isUpdated });
        }

        return newDoc;
      })
      .filter(
        ({ documentTypeId, value, id }) => id || (documentTypeId && value),
      );
  };

  const handleUpdate = () => {
    update({
      variables: {
        dweller: {
          personId: Number(values.personId),
          name: values.name,
          phone: values?.phone?.length
            ? values.phone?.replace(/\s/gi, '')
            : null,
          email: values.email ? values.email : null,
          accesses: [],
          devices: values?.devices
            ? values.devices.map(
                ({ id, type, value, counter, blocked, description }) => ({
                  id,
                  type,
                  value,
                  counter,
                  blocked,
                  description,
                }),
              )
            : [],
          documents: filterEmptyDocuments(values.documents),
          additionalContacts: values.additionalContacts?.map(i => {
            return {
              ...removeProperty(i, '__typename'),
              contact: i.contact?.replace(/\s/gi, ''),
            };
          }),
        },
      },
    });
  };

  const isKiperAccount = () => values.id && values.email && <KiperAccount />;

  return (
    <>
      <MainInfoContainer isEdition={isEdition}>
        <AvatarContainer xs={4}>
          <UserAvatar
            size={200}
            round="100%"
            src={values.email && `//avatar.kiper.com.br/${values.email}`}
            name={values.name}
          />
        </AvatarContainer>
        <Col xs={8}>
          <FormGroup>
            <KiperEmailInput
              disabled={
                !(!isEdition || (editMode && savedEmail === null)) &&
                !(isEdition && editMode && values.canChangeEmail)
              }
              checkPerson={!(isEdition && editMode && values.canChangeEmail)}
              showToolTip
              onRedirectClick={userInfo =>
                history.push(`/users/${userInfo.id}`)
              }
            />
          </FormGroup>
          <FormGroup>
            <Label info block>
              {t('put.name')}
            </Label>
            {!editMode && isEdition ? (
              <Label>{values.name}</Label>
            ) : (
              <>
                <DebouncedInput
                  className="form-control"
                  type="text"
                  placeholder={t('put.placeholders.name')}
                  name="name"
                  id="name"
                  value={values.name}
                  onChange={handleChange}
                  delay={400}
                  invalid={touched.name && !!errors.name}
                  onBlur={() => setFieldTouched('name', true, true)}
                />
                <ErrorMessage component={ErrorMessageComponent} name="name" />
              </>
            )}
          </FormGroup>

          <FormGroup>
            <PersonPhoneInput
              label={t('put.phone')}
              placeholder={t('put.placeholders.phone')}
              setFieldTouched={setFieldTouched}
              onChange={handleChange}
              disabled={!editMode && isEdition}
              checkPerson={!(isEdition && editMode)}
              onRedirectClick={userInfo =>
                history.push(`/users/${userInfo.id}`)
              }
            />
          </FormGroup>

          <Contacts editMode={editMode} isEdition={isEdition} values={values} />

          <Documents
            editMode={editMode || !isEdition}
            savedDocuments={savedDocuments}
            checkPerson={!(isEdition && editMode)}
            onRedirectClick={userInfo => history.push(`/users/${userInfo.id}`)}
          />
          {isKiperAccount()}
        </Col>
      </MainInfoContainer>
      {editMode && (
        <Flex justify="flex-end" margin="15px 0 0 0">
          <KiperButton
            type="button"
            color="secondary"
            variant="out"
            style={{ marginRight: '10px' }}
            onClick={handleEditMode}
          >
            {t('put.cancel')}
          </KiperButton>
          <KiperButton
            type="button"
            color="primary"
            onClick={handleUpdate}
            loading={loading}
            disabled={
              loading ||
              !!errors?.documents?.length ||
              !!errors?.additionalContacts?.length ||
              errors?.phone
            }
          >
            {loading ? t('buttons:saving') : t('buttons:save')}
          </KiperButton>
        </Flex>
      )}
    </>
  );
};

export default UserInfo;

UserInfo.propTypes = {
  handleEditMode: propTypes.func.isRequired,
  editMode: propTypes.bool.isRequired,
  isEdition: propTypes.bool,
  savedEmail: propTypes.string,
  savedDocuments: propTypes.array,
  onSaveEmail: propTypes.func.isRequired,
};

UserInfo.defaultProps = {
  isEdition: false,
  savedEmail: null,
  savedDocuments: [],
};
