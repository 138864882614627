import styled from 'styled-components';
import { Button, Label as label } from '@kiper/ui/src';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled(label)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.25px;
  margin: 0;
`;

export const Info = styled.div`
  margin-left: 10px;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
`;

export const LabelContainer = styled.div`
  display: flex !important;
  flex-direction: column !important;
  margin: 10px 0;
  width: fit-content;
`;

export const CustomButton = styled(Button)`
  margin-top: 10px;
`;

export const IconButton = styled(Button)`
  margin: 0 5px;
`;
