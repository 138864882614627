import { useRef, useState, useMemo, useLayoutEffect, useEffect } from 'react';
import propTypes from 'prop-types';
import { Spinner, Text } from '@kiper/ui';
import { useLazyQuery } from 'react-apollo';
import { query } from '@kiper/monitoring-graphql/camera';
import { MdVideocamOff } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useDevices } from '../../../../../hooks';
import {
  Container,
  SpinnerContainer,
  CameraNotFound,
  Img,
  PlaceContainer,
} from './styles';

const LoadingComponent = () => (
  <SpinnerContainer>
    <Spinner size={24} />
  </SpinnerContainer>
);

const Camera = ({ event }) => {
  const [t] = useTranslation('event_attendance');

  const { mainCamera } = useDevices({
    event,
  });

  const [cameraOff, setCameraOff] = useState(false);
  const [load, setLoad] = useState(true);

  const camRef = useRef(null);

  const [liveFetch, { loading }] = useLazyQuery(query.cameraLive, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ cameraLive: live }) => {
      if (camRef.current) {
        camRef.current.src = live?.url;
      }
    },
  });

  useEffect(() => {
    if (mainCamera?.id) {
      liveFetch({
        variables: {
          cameraId: mainCamera.id,
          startDateTime: null,
          condominiumPersonContextId: event?.condominium?.personContext?.id,
        },
      });
    } else {
      setCameraOff(true);
    }
  }, []);

  useLayoutEffect(() => {
    return () => {
      if (camRef?.current) {
        camRef.current.src = '';
      }
    };
  }, []);

  const placeSerializedParams = useMemo(
    () =>
      event?.place?.serializedParams
        ? JSON.parse(event.place.serializedParams)
        : null,
    [event],
  );

  const showPlaceName = useMemo(
    () => !!(placeSerializedParams?.external && event.place?.name),
    [placeSerializedParams],
  );

  return (
    <Container showStatus={!loading && !cameraOff && !load}>
      <>
        {!cameraOff && (loading || load) && (
          <LoadingComponent radius={+showPlaceName} />
        )}
        {cameraOff && !loading && (
          <CameraNotFound radius={+showPlaceName}>
            <MdVideocamOff size={24} />
          </CameraNotFound>
        )}
        {!loading && !cameraOff && (
          <Img
            ref={camRef}
            alt={mainCamera?.name}
            onError={() => {
              setCameraOff(true);
              setLoad(false);
            }}
            onLoad={() => setLoad(false)}
            radius={+!showPlaceName}
          />
        )}
      </>

      {showPlaceName && (
        <PlaceContainer radius={+showPlaceName}>
          <Text fontSize="12px">
            {t('details.header.place.place-direction', {
              direction: t(
                `details.header.place.direction-${
                  placeSerializedParams.external ? 'external' : 'internal'
                }`,
              ),
              placeName: event.place.name,
            })}
          </Text>
        </PlaceContainer>
      )}
    </Container>
  );
};

export default Camera;

Camera.propTypes = {
  event: propTypes.object.isRequired,
};
