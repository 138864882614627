import {
  MdDone,
  MdDoneAll,
  MdHighlightOff,
  MdNoSim,
  MdError,
} from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Badge } from '@kiper/ui';

export const Sended = () => {
  const [t] = useTranslation('invite');
  return (
    <Badge color="primary">
      <MdDoneAll size={15} />
      {t('guest.sms-status.sended')}
    </Badge>
  );
};

export const Waiting = () => {
  const [t] = useTranslation('invite');
  return (
    <Badge color="warning">
      <MdDone size={15} />
      {t('guest.sms-status.waiting')}
    </Badge>
  );
};

export const Error = () => {
  const [t] = useTranslation('invite');
  return (
    <Badge color="danger">
      <MdError size={15} />
      {t('guest.sms-status.error')}
    </Badge>
  );
};

export const Undeliverable = () => {
  const [t] = useTranslation('invite');
  return (
    <Badge color="info">
      <MdHighlightOff size={15} />
      {t('guest.sms-status.undeliverable')}
    </Badge>
  );
};

export const NotSent = () => {
  const [t] = useTranslation('invite');
  return (
    <Badge color="danger">
      <MdNoSim size={15} />
      {t('guest.sms-status.not-sent')}
    </Badge>
  );
};
