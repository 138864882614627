import propTypes from 'prop-types';
import Badge from '@kiper/ui/src/Badge';
import { useTranslation } from 'react-i18next';
import Modal from '../../Modal';
import * as S from './styles';

const GadgetInfoDetailsModal = ({ onToggle, gadgetInfo }) => {
  const { t } = useTranslation('user');

  const getLabelKey = label => {
    return label.toLowerCase().replace(/\s/, '-');
  };

  const readStringValue = (label, value) => (
    <S.GadgetInfoObjectValueContent key={getLabelKey(label)}>
      <S.GadgetInfoObjectValueContentLabel>{`${label}`}</S.GadgetInfoObjectValueContentLabel>

      <S.GadgetInfoObjectValueContentStringValue>
        {value}
      </S.GadgetInfoObjectValueContentStringValue>
    </S.GadgetInfoObjectValueContent>
  );

  const readNumberValue = (label, value) => (
    <S.GadgetInfoObjectValueContent key={getLabelKey(label)}>
      <S.GadgetInfoObjectValueContentLabel>{`${label}`}</S.GadgetInfoObjectValueContentLabel>

      <Badge>{value.toString()}</Badge>
    </S.GadgetInfoObjectValueContent>
  );

  const readBooleanValue = (label, value) => (
    <S.GadgetInfoObjectValueContent key={getLabelKey(label)}>
      <S.GadgetInfoObjectValueContentLabel>{`${label}`}</S.GadgetInfoObjectValueContentLabel>

      <Badge color={value ? 'primary500' : 'danger500'}>
        {value.toString()}
      </Badge>
    </S.GadgetInfoObjectValueContent>
  );

  const readGadgetObject = (gadgetsInfos, level = 0) => {
    const gadgetsInfosKeys = Object.keys(gadgetsInfos);

    return gadgetsInfosKeys.map(infoKey => {
      const gadgetInfoLabel = infoKey;
      const gadgetInfoValue = gadgetsInfos[infoKey];
      const gadgetInfoValueType = typeof gadgetInfoValue;

      if (gadgetInfoValueType === 'boolean') {
        return readBooleanValue(gadgetInfoLabel, gadgetInfoValue);
      }
      if (gadgetInfoValueType === 'number') {
        return readNumberValue(gadgetInfoLabel, gadgetInfoValue);
      }
      if (gadgetInfoValueType === 'string') {
        return readStringValue(gadgetInfoLabel, gadgetInfoValue);
      }

      // eslint-disable-next-line no-restricted-globals
      const ignoreLevel = !isNaN(Number(gadgetInfoLabel));
      const levelIncrement = ignoreLevel ? 0 : 1;

      return (
        <S.GadgetInfoDetailsObjectContent key={getLabelKey(gadgetInfoLabel)}>
          {!ignoreLevel && (
            <S.GadgetInfoObjectTitle>{`${gadgetInfoLabel}`}</S.GadgetInfoObjectTitle>
          )}

          <S.GadgetInfoDetailsObjectContentData ignoreLevel={ignoreLevel}>
            {readGadgetObject(gadgetInfoValue, level + levelIncrement)}
          </S.GadgetInfoDetailsObjectContentData>
        </S.GadgetInfoDetailsObjectContent>
      );
    });
  };

  return (
    <Modal
      size="md"
      title={t('gadget-details.modal-title')}
      open
      toggle={onToggle}
    >
      <S.GadgetInfoDetailsModalContainer>
        {readGadgetObject(gadgetInfo)}
      </S.GadgetInfoDetailsModalContainer>
    </Modal>
  );
};

GadgetInfoDetailsModal.propTypes = {
  onToggle: propTypes.func.isRequired,
  gadgetInfo: propTypes.object.isRequired,
};

export { GadgetInfoDetailsModal };
