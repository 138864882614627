import { useContext, useEffect } from 'react';
import { Input } from '@kiper/ui';
import { InputWrapper } from './styles';
import { CondominiumRulesContext } from '../../CondominiumRules';
import { sendAmplitudeData } from '../../../../../services/amplitude';
import { TriageContext } from '../../../../../store';

const Text = () => {
  const { textFilter, setTextFilter, eventType } = useContext(
    CondominiumRulesContext,
  );
  const { triageContext } = useContext(TriageContext);

  const handleChangeText = (name, value) => {
    if (value.length >= 3 || value.length === 0) {
      sendAmplitudeData(`condoRules filter by text`, {
        text: value,
        eventType,
      });
      setTextFilter(value);
    }
  };

  useEffect(() => {
    return () => {
      setTextFilter('');
    };
  }, []);

  useEffect(() => {
    setTextFilter(triageContext.filterText);
  }, [triageContext.filterText]);

  return (
    <InputWrapper>
      <Input
        delay={500}
        name="filter-text-input"
        value={textFilter}
        onChange={handleChangeText}
        placeholder="Pesquisar"
      />
    </InputWrapper>
  );
};

export default Text;
