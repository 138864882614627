import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex } from '@kiper/ui';
import { getUnityDwellerPaginated } from '@kiper/monitoring-graphql/guided_attendance';
import StepStructure, { StepTextTypeColor } from '../StepStructure';
import { FiltersFields, UnityDweller } from './components';
import { InfiniteScrollTable } from '../../InfiniteScroll';
import { useGuidedAttendance } from '../../../hooks';
import {
  useForm,
  useNextAction,
  useUnityDwellerQuery,
  useWindowDimension,
} from './hooks';

const StepAttendanceGetUnity = ({ event }) => {
  const { t } = useTranslation('guided-attendance');

  const { handleGetTriageSelected } = useGuidedAttendance();
  const { nextAction } = useNextAction({ event });
  const { values, setFieldValue, handleSelect } = useForm({ nextAction, t });
  const { handleGetQueryVariables, tableColumns } = useUnityDwellerQuery({
    condominiumPersonContextId: event?.condominium?.personContextId,
    filters: values.stepAttendanceGetUnity?.filters,
    t,
  });
  const { maxHeightDropDown, tableRef } = useWindowDimension();

  return (
    <StepStructure
      type={StepTextTypeColor.SPEAK}
      text={t('step-get-unity.title', {
        triage: t(`step-get-unity.triage.${handleGetTriageSelected(values)}`),
      })}
      hasPreviousStepButton
    >
      <Flex flexDirection="column" width="100%" gridGap="16px">
        <FiltersFields
          event={event}
          values={values}
          setFieldValue={setFieldValue}
        />
        <InfiniteScrollTable
          tableRef={tableRef}
          columns={tableColumns}
          variables={handleGetQueryVariables()}
          query={getUnityDwellerPaginated}
          tableMaxHeight={maxHeightDropDown}
          hover
          renderListItem={({ item, trRef }) => (
            <UnityDweller
              dweller={item}
              handleSelect={handleSelect}
              ref={trRef}
            />
          )}
        />
      </Flex>
    </StepStructure>
  );
};

export default StepAttendanceGetUnity;

StepAttendanceGetUnity.propTypes = {
  event: propTypes.shape({
    eventId: propTypes.string,
    condominium: propTypes.shape({
      personContextId: propTypes.number,
    }),
  }).isRequired,
};
