import InvitesPage from './List';
import InviteDetails from './Details';

const breadcrumb = (label, to) => ({ label, to });
const requiredPermissions = ['invitationManagement'];

export default [
  {
    name: 'List of invites',
    path: '/invites',
    exact: true,
    component: InvitesPage,
    breadcrumb: [breadcrumb('common:breadcrumb.invites', '/invites')],
    requiredPermissions,
  },
  {
    name: 'Invite detail',
    path: '/invites/:id',
    exact: true,
    component: InviteDetails,
    breadcrumb: [
      breadcrumb('common:breadcrumb.invites', '/invites'),
      breadcrumb('common:breadcrumb.detail', '/invites/:id'),
    ],
    requiredPermissions,
  },
];
