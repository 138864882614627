import { useQuery } from 'react-apollo';
import { queries } from '@kiper/monitoring-graphql/delivery';
import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';

const useFetchLockerDeliveryHistory = ({
  condominiumPersonContextId,
  filters,
  setFilters,
}) => {
  const { toast } = useSwal();

  const { page, pageSize } = filters;

  const onError = err => {
    const formattedErrors = apolloErrorHandler(err);
    if (formattedErrors && formattedErrors.length) {
      toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
  };

  const {
    data: lockerDeliveryData,
    loading: loadingDeliveryHistoryInfo,
    refetch,
  } = useQuery(queries.lockerDeliveryHistory, {
    skip: !condominiumPersonContextId,
    variables: {
      condominiumPersonContextId,
      filters: {
        page,
        pageSize,
      },
    },
    onCompleted: data => {
      if (data?.lockerDeliveryHistory?.pagination) {
        setFilters({
          ...data.lockerDeliveryHistory.pagination,
        });
      }
    },
    onError,
  });

  return [lockerDeliveryData, loadingDeliveryHistoryInfo, refetch];
};

export default useFetchLockerDeliveryHistory;
