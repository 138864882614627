import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { eventTriage } from '@kiper/monitoring-graphql/attendance';
import { useMutation } from 'react-apollo';

const useUpdateTriage = event => {
  const { toast } = useSwal();
  const [updateTriage] = useMutation(eventTriage, {
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const handleUpdateTriage = value => {
    if (!value?.stepAttendanceGetUnity) return null;

    const variables = {
      eventId: event?.eventId,
      triageId: value?.stepAttendanceGetReason?.id,
    };

    const { stepAttendanceGetUnity } = value || {};
    const serializedTriageJson = stepAttendanceGetUnity?.unity?.treeNodeId
      ? { guUnTreeNodeId: stepAttendanceGetUnity.unity.treeNodeId }
      : {};

    if (value?.stepAttendanceGetName?.name)
      serializedTriageJson.identifier = value.stepAttendanceGetName.name;

    if (Object.keys(serializedTriageJson).length > 0)
      variables.serializedTriage = JSON.stringify(serializedTriageJson);

    return updateTriage({
      variables,
    });
  };

  return { handleUpdateTriage };
};

export default useUpdateTriage;
