import propTypes from 'prop-types';
import { Flex } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import {
  profileTypes,
  authorizationStatus,
  profileColor,
} from '../../../constants';

import * as S from './styles';

const transientDetailName = Object.freeze({
  VEHICLE_PLATE: 'vehiclePlate',
});

const columns = [
  {
    key: 'name',
    label: 'name',
    size: 3,
    align: 'initial',
    additionalKeys: ['profileName', 'companyName'],
  },
  {
    key: 'phoneNumber',
    label: 'phone-number',
    size: 2,
    align: 'initial',
  },
  {
    key: 'documentCpf',
    label: 'document-cpf',
    size: 2,
    align: 'initial',
  },
  {
    key: 'vehiclePlate',
    label: 'vehicle-plate',
    size: 2,
    align: 'initial',
  },
  {
    key: 'status',
    label: 'status',
    size: 3,
    align: 'initial',
  },
];

const FindRegisterItem = ({ item, handleSelectOption }) => {
  const { t } = useTranslation('guided-attendance');

  const handleStatusColor = status => {
    switch (status) {
      case authorizationStatus.NOT_AUTHORIZED:
        return 'danger500';
      case authorizationStatus.AUTHORIZED:
        return 'primary500';
      case authorizationStatus.REQUEST_AUTHORIZATION:
        return 'highBlack';
      default:
        return 'highBlack';
    }
  };

  const handleStatusValue = status => {
    switch (status) {
      case authorizationStatus.NOT_AUTHORIZED:
        return t('step-find-register.status.not-authorized');
      case authorizationStatus.AUTHORIZED:
        return t('step-find-register.status.authorized');
      case authorizationStatus.REQUEST_AUTHORIZATION:
        return t('step-find-register.status.request-authorization');
      default:
        return t('step-find-register.status.request-authorization');
    }
  };

  const handleTypeValue = profileName => {
    return t(`common:profiles.${profileTypes[profileName]}`);
  };

  const handleGetValue = (key, value) => {
    const result = {
      status: handleStatusValue(value),
      profileName: handleTypeValue(value),
    };

    if (result[key]) return result[key];

    return value;
  };

  const IsVehiclePlateNoValue = (value, field) => {
    return !(!value && field === transientDetailName.VEHICLE_PLATE);
  };

  const isProfileName = 'profileName';
  return (
    <>
      {columns.map(column => (
        <S.PlaceCol
          sm={column.size}
          lg={column.size}
          xl={column.size}
          key={column.key}
          onClick={e => handleSelectOption(e, item)}
        >
          {IsVehiclePlateNoValue(item[column.key], column.key) && (
            <Flex flexDirection="column" alignItems={column.align}>
              <S.LabelText>
                {t(`step-find-register.column.${column.label}`)}
              </S.LabelText>
              <S.ValueText
                color={handleStatusColor(item[column.key])}
                title={handleGetValue(column.key, item[column.key])}
              >
                {handleGetValue(column.key, item[column.key])}
              </S.ValueText>
              {!!column?.additionalKeys?.length &&
                column?.additionalKeys?.map(key => (
                  <S.ValueText
                    key={key}
                    color={
                      key === isProfileName
                        ? profileColor[item[key]]
                        : handleStatusColor(item[key])
                    }
                    title={handleGetValue(key, item[key])}
                  >
                    {handleGetValue(key, item[key])}
                  </S.ValueText>
                ))}
            </Flex>
          )}
        </S.PlaceCol>
      ))}
    </>
  );
};

export default FindRegisterItem;

FindRegisterItem.propTypes = {
  item: propTypes.object.isRequired,
  handleSelectOption: propTypes.func.isRequired,
};
