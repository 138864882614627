import { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { accessTag } from '@kiper/monitoring-graphql';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { MdRefresh } from 'react-icons/md';
import { InputGroupAddon, FormFeedback } from 'reactstrap';
import {
  ButtonIcon,
  Select,
  InputContainer,
  FlexContainer,
  IconContainer,
} from './styles';

export default function SelectAvailableTag({
  partnerTreeNodeId,
  invalid,
  value,
  name,
  onChange,
  ...props
}) {
  const [t] = useTranslation('user');
  const { data, refetch, loading } = useQuery(accessTag.listAvaiables, {
    skip: !partnerTreeNodeId,
    variables: { partnerTreeNodeId },
  });

  const avaiableTags = useMemo(() => {
    if (!data) return [];
    const items = [];

    if (value && !data.accessTagAvaiables.find(x => x.tagId === value)) {
      items.push({ tagId: value });
    }

    items.push(...data.accessTagAvaiables);
    return items;
  }, [data, value]);

  return (
    <InputContainer>
      <FlexContainer>
        <Select
          value={value ? { label: value, value } : null}
          placeholder={t('common:select')}
          noOptionsMessage={() =>
            t('put.devices.modal.insert.empty-device-select')
          }
          onCreateOption={option => {
            onChange({ target: { name, value: option } });
          }}
          getNewOptionData={option => ({
            value: option,
            label: `${t('put.devices.modal.insert.create')} "${option}"`,
          })}
          onChange={option =>
            onChange({ target: { name, value: option.value } })
          }
          invalid={invalid || (!loading && !avaiableTags.length)}
          options={avaiableTags.map(({ tagId }) => ({
            label: tagId,
            value: tagId,
          }))}
          isLoading={loading}
          {...props}
        />
      </FlexContainer>
      <IconContainer>
        <InputGroupAddon addonType="append">
          <ButtonIcon
            onClick={() => refetch()}
            rounded
            variant="out"
            type="button"
            disabled={loading}
          >
            <MdRefresh />
          </ButtonIcon>
        </InputGroupAddon>
        <FormFeedback>
          {t('put.devices.modal.insert.errors.no-tags-avaiable')}
        </FormFeedback>
      </IconContainer>
    </InputContainer>
  );
}

SelectAvailableTag.propTypes = {
  partnerTreeNodeId: propTypes.number.isRequired,
  invalid: propTypes.bool,
  value: propTypes.any,
  name: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
};

SelectAvailableTag.defaultProps = {
  invalid: false,
  value: '',
};
