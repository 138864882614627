import {
  Col as col,
  Card as card,
  CardHeader as cardheader,
  CardBody as cardbody,
  FormGroup as formgroup,
  Form as form,
} from 'reactstrap';
import { Label as label, H5 as h5 } from '@kiper/ui';
import styled, { css } from 'styled-components';
import PersonSelect from '../../../components/PersonSelect';

export const BlockFullWidth = styled.div`
  word-break: break-all;
`;

export const Card = styled(card)`
  margin: 10px 0;

  ${props =>
    props.border
      ? css`
          border-color: ${props.border};
        `
      : null}
`;

export const CardHeader = styled(cardheader)`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
`;

export const CardBody = styled(cardbody)`
  padding: 20px 10px;
`;

export const Form = styled(form)``;

export const Flex = styled.div`
  display: flex;
  ${props =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
`;

export const H5 = styled(h5)`
  margin-bottom: 0;
`;

export const MainInfoContainer = styled.div`
  display: flex;
  ${props =>
    props.isEdition &&
    css`
      margin-bottom: 15px;
    `}
`;

export const AvatarContainer = styled(col)`
  display: flex;
  justify-content: center;
`;

export const Col = styled(col)`
  padding: ${props => (props.separator ? '0 15px 0 0' : '0')};
  & + & {
    padding: 0;
  }
`;

export const Optional = styled(label)`
  margin-left: 5px;
  color: ${props => props.theme.colors.secondary400};
`;

export const FooterActions = styled(Flex)`
  justify-content: flex-end;
  padding: 0 15px 15px 0;
`;

export const FormGroup = styled(formgroup)`
  margin-bottom: 12px;
`;

export const PermissionsContainer = styled.div`
  background-color: #f9f9fa;
  padding: 10px;
  padding-bottom: 0;
  border: 1px solid #b5b5b6;
  border-radius: 5px;
  margin-top: 5px;
`;

export const PermissionList = styled.ul`
  padding-left: 20px;
`;

export const PermissionItem = styled.li`
  font-family: NunitoSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.7);
`;

export const PermissionDescription = styled.p`
  font-family: NunitoSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 5px;
  padding: 0;
`;

export const CondoSelect = styled(PersonSelect)`
  width: 100%;
`;
