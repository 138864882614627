import styled from 'styled-components';
import {
  Nav as nav,
  NavLink as navLink,
  Badge as badge,
  Flex,
} from '@kiper/ui';

export const Container = styled(Flex)`
  margin-right: ${({ mr }) => mr || '24px'};
  position: sticky;
  border-color: ${props => props.theme.colors.secondary300};
`;

export const Nav = styled(nav)`
  border-bottom: none;
`;

export const NavLink = styled(navLink)`
  padding-right: 10px !important;
  padding-left: 0px !important;
  border-bottom: none !important;
  cursor: pointer;

  font-family: Lato;

  div {
    border-bottom: 2px solid
      ${props => (props.active ? props.theme.colors.primary500 : 'transparent')} !important;
  }
  svg {
    color: ${({ active, theme }) =>
      active ? theme.colors.primary500 : theme.colors.lowBlack};
  }
  span {
    margin-left: 0 !important;
    border-bottom: none;
  }
`;

export const Badge = styled(badge)`
  position: absolute;
  border-radius: 20px;
  width: auto;
  height: 18px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.dangerSecondary};
  font-weight: 700;
  font-size: 10px;
  top: -10px;
  left: 32px;
  padding: 0 6px;
`;
