import styled, { css } from 'styled-components';
import { FormGroup as formgroup, Row as row } from 'reactstrap';
import {
  Label as label,
  InputMask as inputMask,
  KiperButton as button,
  ErrorMessage,
  Input as input,
} from '@kiper/ui';

export const Label = styled(label)``;
export const InputMask = styled(inputMask)``;
export const Input = styled(input)`
  ${props =>
    props.invalid &&
    css`
      border-color: ${props.theme.colors.dangerPrimary};
    `}
`;
export const ErrorMessageComponent = styled(ErrorMessage)``;

export const Wrapper = styled.div`
  display: flex;
  gap: 3px;

  button {
    padding: 0 3px;
  }
`;

export const Row = styled(row)`
  width: 100%;
  margin: 0;
  flex-wrap: nowrap;
  gap: 10px;
`;

export const FormGroup = styled(formgroup)`
  margin-bottom: 12px;
  width: 100%;
`;

export const IconButton = styled(button)`
  margin: 16px 0 0 10px;
`;

export const Flex = styled.div`
  display: flex;
  ${props =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `}
`;
