import styled from 'styled-components';
import { Button as btn, Col as col } from 'reactstrap';

import { Link as link } from 'react-router-dom';

export const Container = styled(col)`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
`;

export const Img = styled.img``;

export const Button = styled(btn)`
  margin-bottom: 20px;
  &:active {
    transform: translateY(2px);
  }
`;

export const Col = styled(col)`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EmptyInfo = styled.p`
  text-align: center;
`;
