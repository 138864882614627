import { useMemo } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-apollo';
import { queries } from '@kiper/monitoring-graphql/condominium/detail';
import { Label } from '@kiper/ui';

import { formatDeviceTypeLabel } from '../../../pages/Condominiums/utils';
import { StyledSelect, GroupLabelContainer, GroupBadge } from './styles';

function DeviceAvailable({
  placeId,
  isClearable,
  invalid,
  value,
  isEditable,
  ...props
}) {
  const [t] = useTranslation('condominium');

  const { data, loading } = useQuery(queries.placeAvailableDevices, {
    fetchPolicy: 'cache-and-network',
    skip: isEditable,
    variables: {
      placeId,
    },
  });

  if (isEditable) {
    return <Label>{value?.label}</Label>;
  }

  const groupedOptions = useMemo(() => {
    if (data?.placeAvailableDevices)
      return [
        {
          label: t('details.modal.device.condominium-devices'),
          options: data.placeAvailableDevices?.condominiumDevices || [],
        },
        {
          label: t('details.modal.device.partner-devices'),
          options: data.placeAvailableDevices?.partnerDevices || [],
        },
      ];

    return [];
  }, [data]);

  const formatGroupLabel = group => (
    <>
      {!!group.options.length && (
        <GroupLabelContainer>
          <span>{group.label}</span>
          <GroupBadge>{group.options.length}</GroupBadge>
        </GroupLabelContainer>
      )}
    </>
  );

  return (
    <StyledSelect
      {...props}
      value={value}
      options={groupedOptions}
      formatGroupLabel={formatGroupLabel}
      isLoading={loading}
      invalid={Number(invalid)}
      getOptionValue={option => option.id}
      getOptionLabel={formatDeviceTypeLabel}
    />
  );
}

export default DeviceAvailable;

DeviceAvailable.propTypes = {
  placeId: propTypes.number.isRequired,
  value: propTypes.any,
  isClearable: propTypes.bool,
  invalid: propTypes.bool,
  isEditable: propTypes.bool,
};

DeviceAvailable.defaultProps = {
  isClearable: false,
  invalid: false,
  isEditable: true,
  value: null,
};
