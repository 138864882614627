/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import { responseFingerprintFragment } from './fragments';

export const registerFingerprint = gql`
  mutation registerFingerprint(
    $personId: Int!
    $deviceId: Int!
    $fingerIndex: Int!
    $panic: Boolean
  ) {
    registerFingerprint(
      personId: $personId
      deviceId: $deviceId
      fingerIndex: $fingerIndex
      panic: $panic
    ) {
      ...responseFingerprintParts
    }
  }
  ${responseFingerprintFragment}
`;

export const accessFingerprintRemove = gql`
  mutation accessFingerprintRemove($personId: Int!, $fingerprintId: Int!) {
    accessFingerprintRemove(personId: $personId, fingerprintId: $fingerprintId)
  }
`;
