import { useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import {
  FiLock,
  FiAlertCircle,
  FiAlertTriangle,
  FiChevronDown,
} from 'react-icons/fi';
import { useDebounce } from '@kiper/hooks';
import { Badge, Flex, KiperButton, Text, Tooltip } from '@kiper/ui';
import { deviceIdTypes, doorStatusTypes } from '../../constants';
import * as S from './styles';

const DoorStatus = ({ t, doorStatus, doorKeepOpenedReason }) => {
  switch (doorStatus) {
    case doorStatusTypes.Opened:
      return (
        <Badge color="warning500">
          <>
            <FiAlertCircle />
            <Text>{t('details.cameras.commands.opened')}</Text>
          </>
        </Badge>
      );
    case doorStatusTypes.Closed:
      return (
        <Badge color="secondary800">
          <>
            <FiLock />
            <Text>{t('details.cameras.commands.closed')}</Text>
          </>
        </Badge>
      );
    case doorStatusTypes.KeepOpened:
      return (
        <>
          <Badge id="keep-opened-status-badge" color="orange500">
            <>
              <FiAlertTriangle />
              <Text>{t('details.cameras.commands.kept-open')}</Text>
            </>
          </Badge>
          {doorKeepOpenedReason && (
            <Tooltip placement="top" target="keep-opened-status-badge">
              <Text fontWeight="600" fontSize="14px" lineHeight="20px">
                {t('details.cameras.commands.kept-open-badge-tooltip')}
              </Text>
              <br />
              <Text fontWeight="400" fontSize="14px" lineHeight="20px">
                {doorKeepOpenedReason}
              </Text>
            </Tooltip>
          )}
        </>
      );

    // IOT STATUS
    case doorStatusTypes.On:
      return (
        <Badge color="primary500">
          <Text>{t('on')}</Text>
        </Badge>
      );
    case doorStatusTypes.Off:
      return (
        <Badge color="danger500">
          <Text>{t('off')}</Text>
        </Badge>
      );
    case doorStatusTypes.Offline:
      return (
        <Badge color="secondary600">
          <Text>{t('offline')}</Text>
        </Badge>
      );
    default:
      return null;
  }
};

const CommandsDevice = ({
  device,
  secondaryCommands,
  primaryCommand,
  doorStatus,
  doorKeepOpenedReason,
  placeName,
  icon,
  disabled,
  justifyContent,
  hideStatus,
  t,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const isFacialDevice =
    device?.deviceTypeId === deviceIdTypes.facial ||
    device?.deviceTypeId === deviceIdTypes.facialIntelbras;

  const hideDoorStatus = useMemo(
    () =>
      hideStatus ||
      (isFacialDevice &&
        device?.parentDeviceId === device?.id &&
        JSON.parse(device?.serializedParams)?.notAllowOpening),
    [device],
  );

  const debounce = useDebounce({ delay: 2000 });

  const handleDebounce = option => {
    setLoading(option.label);
    debounce(() => {
      setLoading(false);
    });
  };

  return (
    <Flex
      flex={1}
      width="100%"
      justifyContent={justifyContent}
      alignItems="center"
      flexWrap="wrap"
      gridGap="8px"
    >
      <Flex gridGap="8px">
        {icon && <Flex alignItems="center">{icon}</Flex>}
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          gridGap="4px"
        >
          <Text fontSize="14px">{placeName || device.name}</Text>
          {!hideDoorStatus && (
            <DoorStatus
              t={t}
              doorStatus={doorStatus}
              doorKeepOpenedReason={doorKeepOpenedReason}
            />
          )}
        </Flex>
      </Flex>
      <Flex alignItems="center">
        {primaryCommand && (
          <KiperButton
            disabled={
              primaryCommand.label === loading || primaryCommand.disabled
            }
            onClick={() => {
              primaryCommand.onClick();
              handleDebounce(primaryCommand);
            }}
            variant="outlined"
            color="neutral"
            icon={primaryCommand.icon}
            {...primaryCommand.props}
          >
            {primaryCommand?.label}
          </KiperButton>
        )}
        {secondaryCommands?.length > 0 && (
          <Flex ml="4px">
            <Dropdown direction="down" isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle tag="nav" aria-expanded={dropdownOpen}>
                <KiperButton
                  variant="outlined"
                  color="neutral"
                  disabled={disabled}
                  icon={<FiChevronDown />}
                />
              </DropdownToggle>
              <DropdownMenu right>
                {secondaryCommands.map((command, i) => (
                  <S.DropdownKiperButton
                    block
                    key={i}
                    disabled={
                      command.label === loading ||
                      !command.onClick ||
                      command.disabled
                    }
                    variant="text"
                    color="neutral"
                    p="2px"
                    onClick={() => {
                      command.onClick();
                      handleDebounce(command);
                      toggle();
                    }}
                    {...command.props}
                  >
                    {command?.label}
                  </S.DropdownKiperButton>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default CommandsDevice;

CommandsDevice.propTypes = {
  secondaryCommands: propTypes.arrayOf(
    propTypes.shape({
      onClick: propTypes.func,
      label: propTypes.string,
      disabled: propTypes.bool,
      icon: propTypes.element,
      props: propTypes.object,
    }),
  ),
  primaryCommand: propTypes.shape({
    onClick: propTypes.func,
    label: propTypes.string,
    disabled: propTypes.bool,
    icon: propTypes.element,
    props: propTypes.object,
  }),
  icon: propTypes.node,
  doorStatus: propTypes.string,
  doorKeepOpenedReason: propTypes.string,
  device: propTypes.shape({
    id: propTypes.number,
    parentDeviceId: propTypes.number,
    name: propTypes.string,
    status: propTypes.number,
    deviceTypeId: propTypes.number,
    serializedParams: propTypes.string,
  }).isRequired,
  t: propTypes.any.isRequired,
  disabled: propTypes.bool,
  placeName: propTypes.string,
  justifyContent: propTypes.string,
  hideStatus: propTypes.bool,
};

CommandsDevice.defaultProps = {
  icon: null,
  secondaryCommands: [],
  primaryCommand: null,
  disabled: false,
  placeName: null,
  doorStatus: null,
  doorKeepOpenedReason: null,
  justifyContent: 'start',
  hideStatus: false,
};

DoorStatus.propTypes = {
  doorStatus: propTypes.string,
  doorKeepOpenedReason: propTypes.string,
  t: propTypes.func.isRequired,
};

DoorStatus.defaultProps = {
  doorStatus: null,
  doorKeepOpenedReason: null,
};
