import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { Flex, Spinner, SplitSidebar } from '@kiper/ui';
import { Fragment } from 'react';
import { firstBy } from 'thenby';
import EmptyState from './EmptyState';
import {
  useCurrentLoggedContext,
  useLastAttendancesByEventType,
} from '../../../../hooks';
import { eventTypes } from '../../../../constants';
import IntrusionDetectionListItem from './IntrusionDetection';
import * as S from './styles';

const LastAttendanceEvents = ({
  condominiumPersonContextId,
  eventType,
  eventId,
}) => {
  const [t] = useTranslation('event_attendance');
  const { lastAttendances, loading } = useLastAttendancesByEventType({
    eventType,
    condominiumPersonContextId,
  });
  const { loggedContext } = useCurrentLoggedContext();

  const headerTitle = t(`details.last-attendances.${eventType}.title`);

  const listItemByEventType = attendance => ({
    [eventTypes.intrusionDetection]: (
      <IntrusionDetectionListItem
        t={t}
        attendance={attendance}
        eventId={eventId}
      />
    ),
  });

  const renderBody = () => {
    if (!lastAttendances || !lastAttendances.length) return <EmptyState />;

    const lastAttendancesOrdered = lastAttendances?.sort(
      firstBy(
        attendance =>
          attendance?.attendant?.id === Number(loggedContext.personContextId),
        'desc',
      ).thenBy(('creationDate', 'desc')),
    );

    return (
      <Flex width="100%" height="100%" p="8px">
        <S.AttendancesList>
          {lastAttendancesOrdered.map(attendance => (
            <Fragment key={attendance.eventId}>
              {listItemByEventType(attendance)[eventType]}
            </Fragment>
          ))}
        </S.AttendancesList>
      </Flex>
    );
  };

  return (
    <S.Wrapper>
      <SplitSidebar.HeaderPane hasBorder>{headerTitle}</SplitSidebar.HeaderPane>
      {loading && !lastAttendances?.length ? (
        <Flex
          alignItems="center"
          width="100%"
          height="100%"
          justifyContent="center"
        >
          <Spinner size="28px" />
        </Flex>
      ) : (
        <Flex width="100%" height="100%" overflowY="auto" overflowX="hidden">
          {renderBody()}
        </Flex>
      )}
    </S.Wrapper>
  );
};

export default LastAttendanceEvents;

LastAttendanceEvents.propTypes = {
  condominiumPersonContextId: propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
  ]).isRequired,
  eventType: propTypes.number.isRequired,
  eventId: propTypes.string.isRequired,
};
