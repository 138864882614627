import { useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { apolloErrorHandler } from '@kiper/fns';
import { Form, FormGroup } from 'reactstrap';
import { createAttendance } from '@kiper/monitoring-graphql/attendance';
import propTypes from 'prop-types';

import { Modal, Label, Button, ErrorMessage } from '@kiper/ui';

import { ModalActions } from './styles';
import CondominiumSelect from '../../Select/Condominium';

const NewAttendanceModal = ({ open, handleToggle }) => {
  const [t] = useTranslation('event_attendance');

  const [condominium, setCondominium] = useState();
  const [error, setError] = useState();

  const handleChange = value => {
    setError(null);
    setCondominium(value);
  };

  const toggleModal = () => {
    setError(null);
    setCondominium();
    handleToggle();
  };

  const [fetch, { loading }] = useMutation(createAttendance, {
    onCompleted: () => {
      toggleModal();
    },
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        setError(formattedErrors);
        setCondominium();
      }
    },
  });

  const handleSubmit = e => {
    e.preventDefault();
    if (!condominium)
      return setError(t('newAttendanceModal.condominiuns.selectAnCondo'));

    return fetch({
      variables: {
        condominiumId: Number(condominium.contextId),
      },
    });
  };

  return (
    <Modal
      title={t('newAttendanceModal.title')}
      open={open}
      toggle={toggleModal}
    >
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label info>{t('newAttendanceModal.condominiuns.label')}</Label>
          <CondominiumSelect
            autoFocus
            id="select-condominium"
            placeholder={t('newAttendanceModal.condominiuns.placeholder')}
            name="sourceNodeId"
            value={condominium}
            showContextId
            onChange={e => {
              handleChange(e);
            }}
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </FormGroup>
        <ModalActions>
          <Button
            color="secondary"
            outline
            className="mr-3"
            type="button"
            onClick={toggleModal}
          >
            {t('buttons:cancel')}
          </Button>
          <Button
            type="submit"
            color="primary"
            loading={loading}
            disabled={loading}
          >
            {loading
              ? t('buttons:saving')
              : t('newAttendanceModal.createButton')}
          </Button>
        </ModalActions>
      </Form>
    </Modal>
  );
};

export default NewAttendanceModal;

NewAttendanceModal.propTypes = {
  open: propTypes.bool.isRequired,
  handleToggle: propTypes.func.isRequired,
};
