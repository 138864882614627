import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useMemo } from 'react';
import useCurrentLoggedContext from './useCurrentLoggedContext';

const rulesGql = gql`
  query rules($userContextId: ID) {
    getRules(userContextId: $userContextId) {
      permissions {
        ableToRead
        ableToWrite
        applicationFeature {
          id
          applicationId
          name
          fieldName
          icon
          params
        }
      }
    }
  }
`;

export default function useViewRules({ path } = {}) {
  const { loggedContext } = useCurrentLoggedContext();

  const { data, loading } = useQuery(rulesGql, {
    skip: !loggedContext,
    variables: {
      userContextId: loggedContext && loggedContext.personContextId,
    },
  });

  const values = useMemo(() => {
    if (!data) return { permissions: [], loading };

    const permissions = data.getRules.permissions || [];
    let permission;
    if (path)
      permission = permissions.find(
        x => x.applicationFeature.fieldName === path,
      );

    if (
      permission &&
      permission.applicationFeature &&
      permission.applicationFeature.params &&
      typeof permission.applicationFeature.params === 'string'
    )
      permission.applicationFeature.params = JSON.parse(
        permission.applicationFeature.params,
      );

    return { loading, permission, permissions };
  }, [data]);

  return values;
}
