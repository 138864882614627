import { mutation } from '@kiper/monitoring-graphql/device';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from 'react-apollo';
import { apolloDataErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { dweller as dwellerGql } from '@kiper/monitoring-graphql';
import { useTranslation } from 'react-i18next';
import { ItemContainer, Item } from '../Devices/styles';
import { VehicleContainer } from './styles';
import Vehicle from './VehicleData';
import RegisterVehicleModal from './RegisterVehicleModal';
import vehicleTypes from './VehicleTypes';

const Vehicles = () => {
  const [t] = useTranslation('condominium');
  const { values: fatherValues } = useFormikContext();
  const { toast, confirm, linkToast } = useSwal();
  const { dialog } = useSwal();
  const [vehicles, setVehicles] = useState(null);
  const [vehicleOld, setVehicledOld] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [vehicleForModal, setVehicleForModal] = useState({});

  const onError = err => {
    const formattedErrors = apolloDataErrorHandler(err);

    if (formattedErrors?.specificities) {
      linkToast({
        title: formattedErrors.message,
        icon: 'error',
        linkLabel: formattedErrors.specificities.personName,
        href: `/users/${formattedErrors.specificities.personId}`,
        timer: 5000,
      });
      return;
    }
    if (typeof formattedErrors === 'string') {
      toast.fire({ title: formattedErrors, icon: 'error' });
      return;
    }
    if (formattedErrors && formattedErrors.length) {
      toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
  };

  function checkAndShowTagChangedDialog(vehicle, oldVehicle) {
    if (vehicle?.tag && vehicle.tag !== oldVehicle.tag) {
      dialog({
        title: t('details.vehicle.tag-changed-dialog-title'),
        text: t('details.vehicle.tag-changed-dialog-message'),
        icon: 'success',
        confirmButtonText: t('details.understood'),
      });
    }
  }

  const [fetch] = useLazyQuery(dwellerGql.detail.queries.vehiclesByDweller, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ vehiclesByDweller }) => {
      setVehicles(vehiclesByDweller);
    },
    onError,
  });

  const [removeVehicle] = useMutation(
    dwellerGql.detail.mutations.removeVehicle,
    {
      onCompleted: () => {
        toast.fire({
          icon: 'success',
          title: t('details.removed-successfully'),
        });
      },
      onError,
    },
  );

  const [createVehicle] = useMutation(
    dwellerGql.detail.mutations.createVehicle,
    {
      onCompleted: vehicle => {
        toast.fire({
          icon: 'success',
          title: t('details.updated-successfully'),
        });
        checkAndShowTagChangedDialog(vehicle.createVehicle, vehicleOld);
        setVehicles(prevVehicles => [...prevVehicles, vehicle?.createVehicle]);
        setModalVisible(false);
      },
      onError,
    },
  );

  const [checkDeviceExistMutation] = useMutation(mutation.checkDeviceExist, {
    onError,
  });

  const [updateVehicle] = useMutation(
    dwellerGql.detail.mutations.updateVehicle,
    {
      onCompleted: vehicle => {
        toast.fire({
          icon: 'success',
          title: t('details.updated-successfully'),
        });
        const updated = vehicle?.updateVehicle;
        const updatedVehicles = vehicles.map(v => {
          if (v.id === updated.id) {
            return updated;
          }
          return v;
        });
        checkAndShowTagChangedDialog(vehicle?.updateVehicle, vehicleOld);
        setVehicles(updatedVehicles);
        setModalVisible(false);
      },
      onError,
    },
  );

  const checkDeviceAvailability = async (device, type) => {
    const deviceExist = await checkDeviceExistMutation({
      variables: {
        deviceId: device,
        type: type,
      },
    });

    return deviceExist.data.checkDeviceExist;
  };

  const handleRemove = async vehicleId => {
    const deleted = await confirm({
      title: t('details.vehicle.remove-dialog-title'),
      text: t('details.vehicle.remove-dialog-message'),
      cancelButtonText: t('buttons:cancel'),
      confirmButtonText: t('buttons:confirm'),
    });
    if (deleted) {
      removeVehicle({
        variables: { id: parseInt(vehicleId, 10) },
      });
      setVehicles(vehicles.filter(vehicle => vehicle.id !== vehicleId));
    }
  };

  const showEditModal = vehicle => {
    setVehicleForModal(vehicle);
    setModalVisible(true);
  };

  const showCreateModal = () => {
    setVehicleForModal({});
    setModalVisible(true);
  };

  const toggleOpenModalRegisterVehicle = () => {
    setModalVisible(prevState => !prevState);
  };

  const handleSave = async vehicle => {
    const changedTagValue = !vehicles.some(
      v => v.id === vehicle?.id && vehicle.tag === v.tag,
    );

    const changedPlateValue = !vehicles.some(
      v => v.id === vehicle?.id && vehicle.plate === v.plate,
    );

    const type = vehicleTypes(t)[vehicle.type]?.value;

    if (
      vehicle?.tag &&
      changedTagValue &&
      (await checkDeviceAvailability(vehicle.tag, 'tagVehicular'))
    )
      return;

    if (
      vehicle?.plate &&
      changedPlateValue &&
      (await checkDeviceAvailability(vehicle.plate, type))
    )
      return;

    setVehicledOld({ ...vehicle });

    if (vehicle?.id) {
      const { creationDate, __typename, ...vehicleToUpdate } = vehicle;
      updateVehicle({
        variables: {
          vehicleId: vehicle.id,
          vehicle: { ...vehicleToUpdate, type },
        },
      });
    } else {
      createVehicle({
        variables: {
          personId: parseInt(fatherValues.personId, 10),
          vehicle: { ...vehicle, type },
        },
      });
    }
  };

  useEffect(() => {
    fetch({
      variables: { personId: parseInt(fatherValues.personId, 10) },
    });
  }, []);

  return (
    <VehicleContainer>
      {modalVisible && (
        <RegisterVehicleModal
          opened={modalVisible}
          onToggle={toggleOpenModalRegisterVehicle}
          onSave={handleSave}
          typesOptions={vehicleTypes(t)}
          data={vehicleForModal}
          loadingSave={false}
        />
      )}
      <ItemContainer>
        {(vehicles ?? []).map(vehicle => (
          <Item key={vehicle.id} separator={1} devicesLength={vehicles.length}>
            <Vehicle
              data={vehicle}
              onDelete={handleRemove}
              onEdit={showEditModal}
            />
          </Item>
        ))}
        {vehicles && (
          <Item separator={1} devicesLength={vehicles.length}>
            <Vehicle create onCreate={showCreateModal} />
          </Item>
        )}
      </ItemContainer>
    </VehicleContainer>
  );
};

export default Vehicles;
