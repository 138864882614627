import { useContext, useMemo, useCallback } from 'react';
import propTypes from 'prop-types';
import * as i18n from 'react-i18next';
import { FiClock } from 'react-icons/fi';
import { useSwal } from '@kiper/hooks';
import {
  formatDistanceDate,
  getUserLang,
  apolloErrorHandler,
  formatTimerLabel,
} from '@kiper/fns';
import { Flex, Text, Tooltip, UserAvatar, withErrorBoundary } from '@kiper/ui';
import attendanceContext from '../../attendanceContext';
import { EventContainer, Li, TextEllipsis, Ul } from './styles';

function EventCard({ event, critical, isLoading }) {
  const [t] = i18n.useTranslation('event_attendance');
  const { toast } = useSwal();
  const { play, attendanceList, loading } = useContext(attendanceContext);
  const { inAttendance, inAttendanceOnHold } = attendanceList;

  const { isEventScheduled, observation } = useMemo(
    () => ({
      isEventScheduled:
        event?.additionalInformation &&
        JSON.parse(event?.additionalInformation)?.eventScheduled,
      observation:
        event?.additionalInformation &&
        JSON.parse(event?.additionalInformation)?.observation,
    }),
    [event],
  );

  const lang = useMemo(() => getUserLang().split('-')[0], []);

  const isInAttendance = useMemo(() => {
    if (!inAttendance && !inAttendanceOnHold) return false;

    if (inAttendanceOnHold) {
      return inAttendanceOnHold.eventId === event.eventId;
    }

    return inAttendance.eventId === event.eventId;
  }, [inAttendance]);

  // eslint-disable-next-line
  const start = useCallback(async () => {
    if (loading) {
      return null;
    }

    if (isInAttendance) {
      return null;
    }

    if (event?.attendant?.id && !isInAttendance) {
      toast.fire({
        title: t('attendance-already-has-attendant', {
          attendantName: event?.attendant?.name,
        }),
        icon: 'error',
      });
      return null;
    }

    try {
      return await play.getEvent(event.eventId);
    } catch (err) {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length)
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
  }, [isInAttendance, event, inAttendance, inAttendanceOnHold, loading]);

  const eventDate = useMemo(() => formatDistanceDate(event.eventDate, t), [
    isInAttendance,
    event,
  ]);

  if (!event?.condominium) return null;

  const showEventSchedulerPlaceOrObservation =
    isEventScheduled && (event?.place?.name || observation);

  const placeOrObservation = event?.place?.name
    ? event.place.name
    : observation;

  return (
    <>
      <EventContainer
        flexDirection="column"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
        borderRadius="4px"
        borderWidth={1}
        borderStyle="solid"
        p="8px"
        m="8px"
        attend={isInAttendance}
        critical={critical}
        onClick={start}
        isLoading={isLoading}
        isTelephony={!!event.ownerId}
        isEventScheduled={isEventScheduled}
      >
        <Flex mb={10} flex={1} width="100%" flexDirection="column">
          <Text
            fontFamily="Nunito Sans"
            fontWeight={700}
            color={isInAttendance ? 'white' : 'black'}
            className="event-title"
          >
            {!!event.eventTypeDescription && event.eventTypeDescription[lang]}
          </Text>
          <Text
            opacity={0.8}
            fontFamily="Nunito Sans"
            fontWeight={300}
            fontSize="12px"
            color={isInAttendance ? 'white' : 'black'}
          >
            {event?.condominium?.name}
          </Text>
          {showEventSchedulerPlaceOrObservation && (
            <TextEllipsis title={placeOrObservation}>
              {placeOrObservation}
            </TextEllipsis>
          )}
        </Flex>
        <Flex
          flex={1}
          alignItems="flex-end"
          width="100%"
          justifyContent={eventDate ? 'flex-start' : 'space-between'}
        >
          {!!eventDate && (
            <Flex flex={1}>
              <Text
                fontFamily="Nunito Sans"
                fontSize="12px"
                fontWeight={300}
                color={isInAttendance ? 'white' : 'black'}
              >
                {eventDate}
              </Text>
            </Flex>
          )}

          {event.attendant && (
            <Flex justifyContent="flex-end">
              <UserAvatar
                src={`//avatar.kiper.com.br/${event.attendant.email}`}
                size={30}
                name={event.attendant.name}
                round="100%"
              />
            </Flex>
          )}
        </Flex>
        {event?.onHold && (
          <>
            <Flex
              id={`timerContainer-${event.eventId}`}
              mt="4px"
              width="100%"
              alignItems="center"
            >
              <Flex
                py="3px"
                px="6px"
                bg="lightGrey"
                borderRadius="8px"
                alignItems="center"
                width="150px"
              >
                <FiClock size={14} fontSize={14} />
                <Text fontSize="12px" ml="5px">
                  {`${
                    event?.onHold?.waitTimer
                      ? formatTimerLabel(event.onHold.waitTimer)
                      : ''
                  } (${
                    event.onHold.returnsOn
                      ? formatDistanceDate(event.onHold.returnsOn, t)
                      : ''
                  })`}
                </Text>
              </Flex>
            </Flex>
            <Tooltip
              placement="bottom"
              target={`timerContainer-${event.eventId}`}
            >
              <Flex justifyContent="flex-start">
                <Ul>
                  <Li>
                    <Text fontWeight="600">{`${t('list.added-by')}: `}</Text>
                    {event?.onHold?.addedBy?.name}
                  </Li>
                  <Li>
                    <Text fontWeight="600">{`${t('list.reason')}: `}</Text>
                    {event?.onHold?.reason}
                  </Li>
                </Ul>
              </Flex>
            </Tooltip>
          </>
        )}
      </EventContainer>
    </>
  );
}

EventCard.propTypes = {
  event: propTypes.shape({
    eventId: propTypes.string,
    eventDate: propTypes.string,
    eventType: propTypes.number,
    ownerId: propTypes.number,
    additionalInformation: propTypes.string,
    eventSettings: propTypes.shape({
      priority: propTypes.number,
      isCritical: propTypes.bool,
      showPopUp: propTypes.bool,
      showAlert: propTypes.bool,
    }),
    eventTypeDescription: propTypes.shape({
      en: propTypes.string,
      es: propTypes.string,
      pt: propTypes.string,
    }),
    condominium: propTypes.shape({
      name: propTypes.string,
    }),
    attendant: propTypes.object,
    onHold: propTypes.shape({
      waitTimer: propTypes.number,
      returnsOn: propTypes.string,
      reason: propTypes.string,
      addedBy: propTypes.shape({
        name: propTypes.string,
      }),
    }),
    place: propTypes.shape({
      name: propTypes.string,
    }),
  }).isRequired,
  critical: propTypes.bool,
  isLoading: propTypes.bool,
};

EventCard.defaultProps = {
  critical: false,
  isLoading: false,
};

const EventCardComponent = withErrorBoundary(EventCard, {
  i18n,
  translationKey: 'event_attendance',
  defaultTitleMessage: 'list.error-boundary.fallback-title',
  customErrorMessage: '',
});

export default EventCardComponent;
