export const defaultSettingsFallbackComponent = {
  errorProps: {
    defaultTitleMessage: '',
    customErrorMessage: '',
  },
  ErrorComponent: null,
  error: null,
  t: () => null,
};

export const defaultSettingsWithErrorBoundary = {
  i18n: null,
  translationKey: '',
  defaultTitleMessage: '',
  customErrorMessage: '',
  CustomErrorComponent: null,
};
