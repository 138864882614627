import styled from 'styled-components';
import { Row as row, Form as form } from 'reactstrap';
import { Button as button, ErrorMessage as errorMessage } from '@kiper/ui';

export const ErrorMessage = styled(errorMessage)``;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 16px 0;
`;

export const ActionsWrapperButtons = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const Row = styled(row)`
  width: 100%;
  height: 100%;
  gap: 16px;
  padding: 0 16px;
`;

export const Form = styled(form)`
  height: 100%;
  padding: 0 16px;
`;

export const Button = styled(button)`
  border-radius: 8px;

  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

export const ClearButton = styled(Button)`
  color: ${props => props.theme.colors.secondary700};
`;

export const ExpandButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;

  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.secondary700};
`;

export const Divider = styled.div`
  height: 1px;
  width: calc(100% + 32px);
  margin: 16px -16px 0 -16px;
  background: ${props => props.theme.colors.secondary300};
  border-radius: 2px;
`;
