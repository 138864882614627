import { useState, useEffect, useRef, useMemo, useContext } from 'react';
import propTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { MdVideocamOff } from 'react-icons/md';
import { useQuery } from 'react-apollo';
import { query } from '@kiper/monitoring-graphql/camera';

import { Spinner } from '@kiper/ui';
import { ThemeContext } from 'styled-components';

import {
  CameraItem,
  Img,
  SpinnerContainer,
  ImageNotFound,
  ExpandIcon,
  ThumbContainer,
  ImageOverlay,
} from './styles';

const CAMERA_TIMEOUT = 60000;

const CameraThumbnail = ({
  camera,
  handleClickThumbnail,
  date,
  condominiumPersonContextId,
}) => {
  const theme = useContext(ThemeContext);

  const [imageLoading, setImageLoading] = useState(true);
  const [imageError, setImageError] = useState(false);
  const [timedout, setTimedout] = useState(false);

  const { data, loading, error } = useQuery(query.cameraThumbnail, {
    fetchPolicy: 'cache-first',
    variables: {
      cameraId: camera.id,
      dateTime: date,
      condominiumPersonContextId,
    },
  });

  const handleLoadingImage = () => {
    setImageLoading(false);
  };

  const handleErrorImage = () => {
    setImageLoading(false);

    setImageError(true);
  };

  const imageErrorRef = useRef(imageError);
  const imageLoadingRef = useRef(imageLoading);

  imageErrorRef.current = imageError;
  imageLoadingRef.current = imageLoading;

  useEffect(() => {
    setTimeout(() => {
      if (!imageErrorRef.current && imageLoadingRef.current) {
        setImageLoading(false);
        setTimedout(true);
      }
    }, CAMERA_TIMEOUT);
  }, []);

  const missingThumbnail = useMemo(() => !camera?.thumbnail, [camera]);

  const hasImage = useMemo(
    () =>
      !error &&
      !imageError &&
      !loading &&
      !timedout &&
      data?.cameraPlayerThumbnail?.url,
    [error, imageError, loading, timedout, data],
  );

  const hasExceededTime = useMemo(
    () =>
      !imageError &&
      !missingThumbnail &&
      !error &&
      !imageLoading &&
      !loading &&
      timedout,
    [imageError, missingThumbnail, error, imageLoading, loading, timedout],
  );

  const hasImageError = useMemo(
    () => imageError || missingThumbnail || (error && !loading && !timedout),
    [imageError, missingThumbnail, error, loading, timedout],
  );

  const hasLoadingEvent = useMemo(
    () =>
      loading || (imageLoading && !error && !imageError && !missingThumbnail),
    [loading, imageLoading, error, imageError, missingThumbnail],
  );

  return (
    <CameraItem onClick={handleClickThumbnail}>
      {hasLoadingEvent && (
        <SpinnerContainer>
          <Spinner color={theme.colors.secondary50} />
        </SpinnerContainer>
      )}
      {hasImageError && (
        <ImageNotFound>
          <MdVideocamOff size={24} color={theme.colors.dangerSecondary} />
        </ImageNotFound>
      )}
      {hasExceededTime && (
        <ImageNotFound>
          <ExpandIcon size={24} color={theme.colors.secondary50} />
        </ImageNotFound>
      )}
      {hasImage && (
        <ThumbContainer>
          <LazyLoad offset={50}>
            <Img
              height={78}
              onLoad={handleLoadingImage}
              onError={handleErrorImage}
              src={data?.cameraPlayerThumbnail?.url}
              alt={camera.name}
            />
            <ImageOverlay>
              <ExpandIcon size={24} color={theme.colors.secondary50} />
            </ImageOverlay>
          </LazyLoad>
        </ThumbContainer>
      )}
    </CameraItem>
  );
};

CameraThumbnail.propTypes = {
  camera: propTypes.object.isRequired,
  handleClickThumbnail: propTypes.func.isRequired,
  date: propTypes.any,
  condominiumPersonContextId: propTypes.number,
};

CameraThumbnail.defaultProps = {
  condominiumPersonContextId: null,
  date: null,
};

export default CameraThumbnail;
