import Digifort from './Digifort';
import EmailToAsk from './EmailToAsk';
import KiperVoice from './KiperVoice';
import Alarm from './Alarm';

const breadcrumb = (label, to) => ({ label, to });
const requiredPermissions = ['systemSettings'];

export default [
  {
    name: 'Email to ask',
    path: '/system-settings/emailToAskTagRf',
    exact: true,
    component: EmailToAsk,
    breadcrumb: [
      breadcrumb('common:breadcrumb.system-settings', '/system-settings'),
      breadcrumb('common:breadcrumb.detail', '/system-settings/emailToAsk'),
    ],
    requiredPermissions,
  },
  {
    name: 'Digifort integration',
    path: '/system-settings/digifortIntegration',
    exact: true,
    component: Digifort,
    breadcrumb: [
      breadcrumb('common:breadcrumb.system-settings', '/system-settings'),
      breadcrumb(
        'common:breadcrumb.detail',
        '/system-settings/digifortIntegration',
      ),
    ],
    requiredPermissions,
  },

  {
    name: 'Kiper voice integration',
    path: '/system-settings/kiperVoiceIntegration',
    exact: true,
    component: KiperVoice,
    breadcrumb: [
      breadcrumb('common:breadcrumb.system-settings', '/system-settings'),
      breadcrumb(
        'common:breadcrumb.detail',
        '/system-settings/kiperVoiceIntegration',
      ),
    ],
    requiredPermissions,
  },
  {
    name: 'Alarm integration',
    path: '/system-settings/alarmTransmitter',
    exact: true,
    component: Alarm,
    breadcrumb: [
      breadcrumb('common:breadcrumb.system-settings', '/system-settings'),
      breadcrumb(
        'common:breadcrumb.detail',
        '/system-settings/AlarmTransmitter',
      ),
    ],
    requiredPermissions,
  },
];
