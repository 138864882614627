export default class Stream {
  constructor(args) {
    const autoStart = args.autoStart || false;

    this.url = args.url;
    this.refreshRate = args.refreshRate || 250;
    this.onStart = args.onStart || null;
    this.onFrame = args.onFrame || null;
    this.onStop = args.onStop || null;
    this.onLoad = args.onLoad || null;
    this.onError = args.onError || null;
    this.callbacks = {};
    this.running = false;
    this.frameTimer = 0;

    this.img = args.img;
    this.img.crossOrigin = 'anonymous';
    this.img.src = this.url;

    if (autoStart) {
      this.img.onload = this.start;
    }
  }

  handleImageLoad() {
    if (this.img.complete) {
      this.startFrameTimer();
    } else {
      this.stop();
    }
  }

  handleAnimationFrame() {
    if (this.img && this.onFrame) {
      try {
        this.onFrame(this.img);
      } catch (e) {
        console.error(e);
        this.stop();
      }
    }
  }

  startFrameTimer() {
    if (!this.frameTimer) {
      setTimeout(() => this.onLoad(), this.refreshRate);
      this.frameTimer = setInterval(() => {
        this.handleAnimationFrame();
      }, this.refreshRate);

      if (this.onStart) {
        this.onStart();
      }
    }
  }

  start() {
    this.setRunning(true);
  }

  stop() {
    clearInterval(this.frameTimer);
    this.setRunning(false);
  }

  setRunning(running) {
    this.running = running;

    if (this.running) {
      this.img.src = this.url;
      this.img.onload = () => {
        this.handleImageLoad();
      };

      this.img.onerror = () => {
        this.onError();
      };
    }
  }
}
