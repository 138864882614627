import styled, { css } from 'styled-components';
import {
  Page as page,
  Card as card,
  Nav as nav,
  NavItem as navItem,
  NavLink as navLink,
} from '@kiper/ui';
import { FiLoader as loaderIcon } from 'react-icons/fi';
import { MdCheckCircle as checkIcon } from 'react-icons/md';

const hideWhenPrint = `
  @media print {
    display: none;
    height: 0;
  }
`;

export const PageTitle = styled(page.Title)`
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;

  color: ${props => props.theme.colors.highBlack};
  ${hideWhenPrint};
`;

export const Hr = styled.hr`
  ${hideWhenPrint};
`;

export const Page = styled(page)``;

export const Card = styled(card)`
  width: 100%;
  flex-direction: column;
  padding: 0;
  margin: 0;
  border-radius: 8px;
  z-index: 999;
  ${hideWhenPrint};
`;

export const Nav = styled(nav)`
  display: flex;
  gap: 10px;
  margin: 8px 0 20px 16px;
  border-bottom: unset;
`;

export const NavItem = styled(navItem)`
  width: fit-content;
  margin-left: 0 !important;
  margin-bottom: -2px !important;
`;

export const NavLink = styled(navLink)`
  width: 100%;
  height: auto;
  padding: 8px 12px !important;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.highBlack} !important;

  ${props =>
    !props.active &&
    css`
      border-bottom: 2px solid ${props.theme.colors.white} !important;
    `};
`;

export const Overlay = styled.div`
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  position: absolute;
  z-index: 1;
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 1) 100%
  );

  @media print {
    display: none;
    height: 0;
    width: 0;
    z-index: 0;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 30%;
`;

export const ReportStateText = styled.span`
  font-size: 18px;
  font-weight: 400;
  color: ${props => props.theme.colors.highBlack};
  margin: 16px;
`;

export const LoaderIcon = styled(loaderIcon)`
  font-size: 40px;
  margin: 16px;
`;

export const CheckIcon = styled(checkIcon)`
  margin: 20px 0;
  font-size: 40px;
`;
