import { useMemo } from 'react';
import propTypes from 'prop-types';
import { MdSync } from 'react-icons/md';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { useTranslation } from 'react-i18next';
import { KiperButton } from '@kiper/ui';

export default function PersonSync({ personContextId, syncStatus, onSync }) {
  const { toast } = useSwal();
  const [t] = useTranslation('user');

  const [sync, { loading }] = useMutation(PersonSync.syncMutation, {
    variables: { personContextId },
    onCompleted: () => {
      toast.fire({ title: t('list.sync-success'), icon: 'success' });

      if (onSync) onSync();
    },
    onError: args => {
      const formattedErrors = apolloErrorHandler(args);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  // eslint-disable-next-line no-unused-vars
  const isSynchronized = useMemo(
    () =>
      [
        PersonSync.syncStatusMap.synchronized,
        PersonSync.syncStatusMap.synchronizing,
      ].includes(syncStatus),
    [syncStatus],
  );

  return (
    <KiperButton
      icon={<MdSync />}
      disabled={loading}
      onClick={sync}
      loading={loading}
    />
  );
}

PersonSync.propTypes = {
  personContextId: propTypes.number.isRequired,
  onSync: propTypes.func,
  syncStatus: propTypes.oneOf([0, 1, 2, 3]),
};

PersonSync.defaultProps = {
  onSync: undefined,
  syncStatus: 0,
};

PersonSync.syncStatusOptions = [
  { id: 0, name: 'not_synced' },
  { id: 1, name: 'synchronizing' },
  { id: 2, name: 'synchronized' },
  { id: 3, name: 'cpu_not_found' },
];

PersonSync.syncStatusMap = PersonSync.syncStatusOptions.reduce((acc, curr) => {
  acc[curr.name] = curr.id;
  return acc;
}, {});

PersonSync.syncMutation = gql`
  mutation adamSenderSync($personContextId: Int!) {
    personSync(personContextId: $personContextId)
  }
`;
