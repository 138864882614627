import styled from 'styled-components';

export const CounterIcon = styled.span`
  position: absolute;
  right: -18px;
  top: -10px;
  font-size: 10px;
  color: white;
  background-color: red;
  border-radius: 50px;
  padding: 0px 5px 0px 5px;
`;
