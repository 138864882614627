import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import {
  Form,
  Divider,
  Actions,
  ActionsWrapperButtons,
  Button,
  ClearButton,
  ExpandButton,
} from './styles';

const ReportForm = ({
  children,
  formikBag,
  hasAdvanced,
  showAdvanced,
  expandAdvanced,
  resetValues,
  loading,
  setHeaderFilters,
}) => {
  const [t] = useTranslation('report');

  const { handleSubmit, resetForm, isValid, dirty, status } = formikBag;

  const clearValues = () => {
    resetForm();
    setHeaderFilters({});
    resetValues();
  };

  return (
    <Form inline onSubmit={handleSubmit}>
      {children}
      <Divider />
      <Actions>
        {hasAdvanced ? (
          <ExpandButton
            outline
            className="mr-3"
            type="button"
            onClick={expandAdvanced}
          >
            {showAdvanced ? <MdExpandLess /> : <MdExpandMore />}
            {t('details.button-advanced')}
          </ExpandButton>
        ) : (
          <div />
        )}
        <ActionsWrapperButtons>
          <ClearButton
            color="secondary"
            variant="out"
            style={{ marginRight: '10px' }}
            type="button"
            onClick={clearValues}
            disabled={loading}
          >
            {t('details.button-clear')}
          </ClearButton>
          <Button
            type="submit"
            color="primary"
            loading={loading}
            disabled={loading || !(isValid && dirty) || status}
          >
            {t('details.button-submit')}
          </Button>
        </ActionsWrapperButtons>
      </Actions>
    </Form>
  );
};

ReportForm.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  formikBag: PropTypes.object.isRequired,
  expandAdvanced: PropTypes.func.isRequired,
  hasAdvanced: PropTypes.bool,
  showAdvanced: PropTypes.any.isRequired,
  resetValues: PropTypes.func,
  loading: PropTypes.bool,
  setHeaderFilters: PropTypes.func.isRequired,
};

ReportForm.defaultProps = {
  resetValues: () => null,
  hasAdvanced: true,
  loading: false,
};

export default ReportForm;
