import { useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-apollo';
import { queries } from '@kiper/monitoring-graphql/condominium/list';
import { Table, TableLoader } from '@kiper/ui';
import Pagination from '../../../components/Pagination';
import CondominiumListItem from './CondominiumListItem';
import EmptyState from '../../../components/EmptyState';
import useCurrentLoggedContext from '../../../hooks/useCurrentLoggedContext';
import emptyStatePlaceholder from '../../../assets/images/empty-state-users.svg';
import { Block, TableContainer } from '../styles';
import CondominiumsPageActions from '../../../components/CondominiumsPageActions/index';
import { condominiumStatusTypes, erpLicenses } from '../../../constants';

const column = str => `list.table-header.${str}`;

const columns = t => [
  {
    name: t(column('name')),
    align: 'left',
  },
  {
    name: t(column('address')),
    align: 'left',
  },
  {
    name: t(column('license')),
    align: 'left',
  },
  {
    name: t(column('activation-status')),
    align: 'left',
  },
];

const licensesValues = Object.values(erpLicenses);
const condominiumStatusValues = Object.values(condominiumStatusTypes);

const initialFilters = {
  searchText: '',
  licenses: [],
  status: condominiumStatusValues,
  page: 1,
  pagesize: 10,
  orderby: 'name',
  ordination: false,
};

export default function CondominiumList({ route }) {
  const [t] = useTranslation('condominium');
  const { loggedContext } = useCurrentLoggedContext();

  const [filters, setFilters] = useState(initialFilters);

  const { data, loading } = useQuery(queries.condominiums, {
    fetchPolicy: 'no-cache',
    variables: {
      filters: {
        ...filters,
        licenses: filters.licenses.length > 0 ? filters.licenses : '',
        status: filters.status.length > 0 ? filters.status : '',
        sourceNodeId: filters.sourceNodeId || loggedContext.topNodeId,
      },
    },
  });

  const { condominiums } = data || { condominiums: {} };
  const { collection, pagination } = condominiums;

  const handleChange = (name, value) =>
    setFilters(x => ({ ...x, [name]: value, page: 1 }));

  const handlePageChange = quantity =>
    setFilters(x => ({ ...x, page: x.page + quantity }));

  const handlePageSize = p =>
    setFilters(x => ({ ...x, page: 1, pagesize: Number(p) }));

  return (
    <Block>
      <CondominiumsPageActions
        filters={filters}
        onChange={handleChange}
        route={route}
        onlySearch
        title={t('list.title')}
        t={t}
        licensesOptions={licensesValues.map(value => ({
          label: t(`list.erp_licenses.${value}`),
          value,
        }))}
        statusOptions={condominiumStatusValues.map(value => ({
          label: t(`list.activation-status-types.${value}`),
          value,
        }))}
      />
      {collection && !collection[0] && !loading ? (
        <EmptyState
          placeholder={emptyStatePlaceholder}
          text={t('list.empty-state')}
        />
      ) : (
        <TableContainer color="#fff">
          <Table columns={columns(t)}>
            {!loading && collection && (
              <tbody>
                {collection.map(condominium => (
                  <CondominiumListItem
                    condominium={condominium}
                    key={condominium.personContextId}
                  />
                ))}
              </tbody>
            )}
          </Table>
          {loading && <TableLoader />}
          <Pagination
            filters={filters}
            pagination={pagination}
            handlePageSize={handlePageSize}
            next={() => handlePageChange(1)}
            previous={() => handlePageChange(-1)}
          />
        </TableContainer>
      )}
    </Block>
  );
}

CondominiumList.propTypes = {
  route: propTypes.object.isRequired,
};
