import { useEffect } from 'react';
import propTypes from 'prop-types';

import { ErrorMessage, Flex, Input, Label } from '@kiper/ui';
import { FormGroup } from 'reactstrap';

const EventFields = ({ fields, setFieldValue, touched, errors }) => {
  useEffect(() => {
    return () => {
      fields.forEach(field => {
        setFieldValue(field.name, '');
      });
    };
  }, []);

  return (
    <Flex gridGap="24px">
      {fields.map((field, i) => (
        <FormGroup key={i}>
          <Label>{field.label}</Label>
          <Input
            placeholder={field.placeholder}
            name={field.name}
            onChange={setFieldValue}
            value={field.value}
            invalid={touched?.[field.name] && !!errors?.[field.name]}
          />
          {touched?.[field.name] && !!errors?.[field.name] && (
            <ErrorMessage>{errors?.[field.name]}</ErrorMessage>
          )}
        </FormGroup>
      ))}
    </Flex>
  );
};

export default EventFields;

EventFields.propTypes = {
  fields: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string.isRequired,
      name: propTypes.string.isRequired,
      placeholder: propTypes.string.isRequired,
      value: propTypes.string.isRequired,
    }),
  ).isRequired,
  setFieldValue: propTypes.func.isRequired,
  touched: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
};
