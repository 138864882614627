import { useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex, Label } from '@kiper/ui';
import { useCurrentLoggedContext } from '../../../../hooks';
import PersonSelect from '../../../../components/PersonSelect';
import { userProfile } from '../../../../constants';
import { UserGuUnSelect } from '../../../../components/Select';
import ProfileSelect from '../../../../components/ProfileSelect';
import * as S from './styles';

export default function UsersFilter({ handleChange, filters }) {
  const [t] = useTranslation('user');
  const { loggedContext } = useCurrentLoggedContext();
  const [condominium, setCondominium] = useState(null);
  const [unity, setUnity] = useState(null);
  const [accessProfile, setAccessProfile] = useState([]);

  const handleKeyDown = event => {
    if (event.key === 'Enter' && event.shiftKey === false)
      event.preventDefault();
  };

  const handleChangeCondominium = condominiumFilter => {
    if (condominiumFilter) {
      handleChange('sourceNodeId', Number(condominiumFilter?.value));
      setCondominium(condominiumFilter);
    } else {
      handleChange('sourceNodeId', loggedContext?.topNodeId);
      setCondominium(null);
    }
    setUnity(null);
  };

  const handleChangeUnity = unityFilter => {
    if (!condominium) {
      handleChange('sourceNodeId', loggedContext?.topNodeId);
      setUnity(null);
      setCondominium(null);
    } else if (unityFilter && unityFilter?.treeNodeId) {
      handleChange('sourceNodeId', Number(unityFilter?.treeNodeId));
      setUnity(unityFilter);
    } else {
      handleChange('sourceNodeId', Number(condominium?.value));
      setUnity(null);
    }
  };

  const handleChangeAccessProfile = accessProfileFilter => {
    if (accessProfileFilter?.length) {
      setAccessProfile(accessProfileFilter);

      handleChange(
        'profileIds',
        accessProfileFilter?.map(a => Number(a?.value)),
      );
    } else {
      setAccessProfile([]);
      handleChange('profileIds', []);
    }
  };

  return (
    <S.Container row>
      <S.Form inline onKeyDown={handleKeyDown}>
        <Flex gridGap="16px" flex={1} alignItems="baseline">
          <Flex flex={3}>
            <S.FormGroup>
              <Flex
                width="100%"
                flexDirection="column"
                gridGap="8px"
                alignItems="flex-start"
              >
                <Label weight={400}>{t(`list.condominium-select-label`)}</Label>
                <PersonSelect
                  isSearchFilter
                  autoSingleOptionSelection
                  filterLinkedFields={['unity']}
                  width="100%"
                  placeholder={t(`list.condominium-select`)}
                  name="condominium"
                  sourceNodeId={filters?.sourceNodeId}
                  fieldName={['condominium']}
                  value={condominium}
                  isClearable
                  onChange={handleChangeCondominium}
                />
              </Flex>
            </S.FormGroup>
          </Flex>
          <Flex flex={2}>
            <S.FormGroup>
              <Flex
                width="100%"
                flexDirection="column"
                gridGap="8px"
                alignItems="flex-start"
              >
                <Label weight={400}>{t(`list.unity-select-label`)}</Label>
                <UserGuUnSelect
                  isSearchFilter
                  name="unity"
                  onChange={handleChangeUnity}
                  placeholder={t(`list.unity-select`)}
                  sourceNodeId={filters?.sourceNodeId}
                  value={unity}
                  isDisabled={
                    !condominium &&
                    loggedContext?.profileId !== userProfile.doorman
                  }
                />
              </Flex>
            </S.FormGroup>
          </Flex>

          <Flex flex={3}>
            <S.FormGroup>
              <Flex
                width="100%"
                flexDirection="column"
                gridGap="8px"
                alignItems="flex-start"
              >
                <Label weight={400}>{t('common:search')}</Label>
                <S.Input
                  width="100%"
                  type="text"
                  name="searchText"
                  delay={600}
                  placeholder={t('common:search')}
                  value={filters?.searchText}
                  onChange={handleChange}
                />
              </Flex>
            </S.FormGroup>
          </Flex>

          <Flex flex={2}>
            <S.FormGroup>
              <Flex
                width="100%"
                flexDirection="column"
                gridGap="8px"
                alignItems="flex-start"
              >
                <Label weight={400}>{t(`list.profile-select-label`)}</Label>
                <ProfileSelect
                  width="100%"
                  classNamePrefix="select-profile"
                  isMulti
                  isClearable
                  isSearchFilter
                  placeholder={t(`list.profile-select`)}
                  name="accessProfile"
                  onChange={handleChangeAccessProfile}
                  value={accessProfile}
                  sourceNodeId={condominium?.value || filters?.sourceNodeId}
                />
              </Flex>
            </S.FormGroup>
          </Flex>
        </Flex>
      </S.Form>
    </S.Container>
  );
}

UsersFilter.propTypes = {
  filters: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
};
