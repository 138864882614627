import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@kiper/ui';
import { useContext } from 'react';
import { QuestionsContainer, InputGroup, Input, Label, Select } from './styles';
import { TriageContext } from '../../../../../store';

const PREFIX_TRANSLATE = 'details.triage.label';

const Questions = ({ setFieldValue, event, values, touched, errors }) => {
  const [t] = useTranslation('event_attendance');
  const { fillAction, setTriageContext } = useContext(TriageContext);

  const handleChangeGuUn = item => {
    setFieldValue('guUn', item);
    setTriageContext({
      filterGUUnit: item,
    });
    fillAction('guUn', event.evendId);
  };

  const handleChangeInput = (name, value) => {
    if (value.length) fillAction(name, event.evendId);
    setFieldValue(name, value);
  };

  const unityTreeNodeId = event?.additionalInformation?.unityTreeNodeId;

  return (
    <QuestionsContainer>
      <InputGroup xs={12}>
        <Label for="identifier">{t(`${PREFIX_TRANSLATE}.identifier`)}</Label>
        <Input
          name="identifier"
          onChange={handleChangeInput}
          value={values.identifier}
        />
        {!!touched?.identifier && !!errors?.identifier && (
          <ErrorMessage>{errors.identifier}</ErrorMessage>
        )}
      </InputGroup>
      <InputGroup xs={12} xl={6}>
        <Label for="guUn">{t(`${PREFIX_TRANSLATE}.gu-un`)}</Label>
        <Select
          name="guUn"
          onChange={handleChangeGuUn}
          sourceNodeId={event?.condominium?.personContext?.treeNodeId}
          value={values.guUn}
          guUnFilter={unityTreeNodeId}
          guLoad={false}
          menuPlacement="top"
        />
        {!!touched?.guUn && !!errors?.guUn && (
          <ErrorMessage>{errors.guUn}</ErrorMessage>
        )}
      </InputGroup>
      <InputGroup xs={12} xl={6}>
        <Label for="dweller">{t(`${PREFIX_TRANSLATE}.dweller`)}</Label>
        <Input
          name="dweller"
          onChange={handleChangeInput}
          value={values.dweller}
        />
        {!!touched?.dweller && !!errors?.dweller && (
          <ErrorMessage>{errors.dweller}</ErrorMessage>
        )}
      </InputGroup>
    </QuestionsContainer>
  );
};

export default Questions;

Questions.propTypes = {
  event: propTypes.object.isRequired,
  setFieldValue: propTypes.func.isRequired,
  values: propTypes.object.isRequired,
  touched: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
};
