import styled from 'styled-components';
import { Text } from '@kiper/ui';

export const Container = styled.div`
  padding: 8px 4px;
  border-bottom: 1px ${props => props.theme.colors.secondary300} solid;
  width: 100%;
`;

export const Title = styled(Text)`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  width: max-content;
  color: ${({ theme }) => theme.colors.blackShades(0.8)};
  opacity: 0.9;
`;
