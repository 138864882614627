import styled from 'styled-components';

export const Container = styled.div`
  margin-right: 10px;
`;

export const Text = styled.span`
  color: ${props => props.theme.colors.white};
  margin-right: 5px;
`;
