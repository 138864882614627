import styled, { css } from 'styled-components';
import { FormGroup as formGroup } from 'reactstrap';
import {
  Label as label,
  Input as input,
  ErrorMessage as errorMessage,
  KiperButton as button,
} from '@kiper/ui';

import { MdAdd, MdRemove } from 'react-icons/md';

export const FormGroup = styled(formGroup)`
  margin-bottom: 12px;
`;

export const Label = styled(label)``;

export const DebouncedInput = styled(input)``;

export const ErrorMessage = styled(errorMessage)``;

export const Optional = styled(label)`
  margin-left: 5px;
  color: ${props => props.theme.colors.secondary400};
`;

export const IconButton = styled(button)`
  margin-left: 10px;
`;

export const AddIcon = styled(MdAdd)``;

export const RemoveIcon = styled(MdRemove)``;

export const Flex = styled.div`
  display: flex;
  ${props =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `}
`;
