import { useState, useEffect, useContext } from 'react';
import { useQuery } from 'react-apollo';
import { event as eventsGql } from '@kiper/monitoring-graphql';
import attendanceContext from './attendanceContext';
import { EventAttendanceContext } from '../../store';

const commandStates = {
  ACTIVE: 'active',
  NOT_ACTIVE: 'not-active',
  ACTIVATING: 'activating',
  DEACTIVATING: 'deactivating',
  ACTIVATING_ERROR: 'activating-error',
  DEACTIVATING_ERROR: 'deactivating-error',
};

const useCommand = ({
  activateRequest,
  deactivateRequest,
  eventId,
  getInitialStatus,
  isFallback,
}) => {
  const eventQuery = useQuery(eventsGql.getSingleV2, {
    variables: { eventId },
    fetchPolicy: 'no-cache',
    skip: !eventId,
  });
  const [status, setStatus] = useState(null);

  const context = isFallback
    ? useContext(EventAttendanceContext)
    : useContext(attendanceContext);

  const event = eventQuery.data?.eventSingleV2;

  useEffect(() => {
    const onReceiveStatus = ({ condominiumId, isActive }) => {
      if (event?.condominium?.personContext?.id !== condominiumId) return;

      setStatus(isActive ? commandStates.ACTIVE : commandStates.NOT_ACTIVE);
    };

    context.condominiumStatus.subscribe(onReceiveStatus);

    return () => context.condominiumStatus.unsubscribe(onReceiveStatus);
  }, [event, status]);

  useEffect(() => {
    if (event) {
      Promise.resolve(getInitialStatus(event)).then(isActive =>
        setStatus(isActive ? commandStates.ACTIVE : commandStates.NOT_ACTIVE),
      );
    }
  }, [event?.eventId]);

  const activate = async () => {
    try {
      setStatus(commandStates.ACTIVATING);

      await activateRequest({ event });
    } catch (e) {
      setStatus(commandStates.ACTIVATING_ERROR);
    }
  };

  const deactivate = async () => {
    try {
      setStatus(commandStates.DEACTIVATING);

      await deactivateRequest({ event });
    } catch (e) {
      setStatus(commandStates.DEACTIVATING_ERROR);
    }
  };

  const isActivating = status === commandStates.ACTIVATING;
  const isDeactivating = status === commandStates.DEACTIVATING;
  const hasActivatingError = status === commandStates.ACTIVATING_ERROR;
  const hasDeactivatingError = status === commandStates.DEACTIVATING_ERROR;

  const isActive =
    status === commandStates.ACTIVE || isDeactivating || hasDeactivatingError;
  const hasError = hasActivatingError || hasDeactivatingError;
  const isLoading = eventQuery.loading || isActivating || isDeactivating;
  const toggle = isActive ? deactivate : activate;

  return {
    activate,
    deactivate,
    toggle,
    status,
    isActive,
    isActivating,
    isDeactivating,
    isLoading,
    hasActivatingError,
    hasDeactivatingError,
    hasError,
  };
};

export default useCommand;
