import { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';
import { GuidedAttendanceContext } from '../../../../store';
import { sendAmplitudeData } from '../../../../services/amplitude';

const useForm = ({ nextAction, t }) => {
  const { setContextTree } = useContext(GuidedAttendanceContext);
  const [selectedSubtriage, setSelectedSubtriage] = useState(false);

  const {
    setFieldValue,
    handleSubmit,
    values,
    errors,
    touched,
  } = useFormikContext();

  const schema = yup.object({
    stepGetSubtriage: yup.object({
      id: yup.number().required(t('common:feedback.required-field')),
      type: yup.number().required(t('common:feedback.required-field')),
      description: yup.string(),
      descriptionEs: yup.string().nullable(),
      descriptionEn: yup.string().nullable(),
    }),
  });

  const handleChange = subtriage => {
    const subtriageObject = {
      id: +subtriage?.id,
      type: subtriage?.type,
      description: subtriage?.description,
      descriptionEs: subtriage?.descriptionEs,
      descriptionEn: subtriage?.descriptionEn,
    };
    setFieldValue('stepGetSubtriage', subtriageObject);
    sendAmplitudeData('chose subtriage', subtriageObject);
    setSelectedSubtriage(true);
  };

  useEffect(() => {
    if (!values?.stepGetSubtriage) {
      setFieldValue('stepGetSubtriage', {});
    }
    setContextTree({
      formikSchema: schema,
      nextAction: async stepData => {
        const nextStep = await nextAction(stepData);
        return { nextStep, currentStepData: stepData };
      },
    });
  }, []);

  useEffect(() => {
    if (selectedSubtriage && !!values?.stepGetSubtriage?.id) {
      handleSubmit();
    }
  }, [values?.stepGetSubtriage]);

  return { values, handleChange, errors, touched };
};

export default useForm;
