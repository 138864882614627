import propTypes from 'prop-types';
import { TableCell, Tr } from './styles';

const TableRow = ({ rowData }) => {
  if (!rowData) return null;

  const {
    depositDate,
    depositTime,
    unityGroupName,
    unityName,
    status,
    withdrawalDate,
    withdrawalTime,
    withdrawalCode,
  } = rowData;

  return (
    <Tr>
      <TableCell>
        {depositDate}
        <br />
        {depositTime}
      </TableCell>
      <TableCell>{unityGroupName}</TableCell>
      <TableCell>{unityName}</TableCell>
      <TableCell>{status}</TableCell>
      <TableCell>
        {withdrawalDate}
        <br />
        {withdrawalTime}
      </TableCell>
      <TableCell>{withdrawalCode}</TableCell>
    </Tr>
  );
};

TableRow.propTypes = {
  rowData: propTypes.object,
};

TableRow.defaultProps = {
  rowData: null,
};

export default TableRow;
