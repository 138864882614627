import styled, { css } from 'styled-components';
import {
  Button as button,
  Badge as KBadge,
  Label as label,
  Text,
} from '@kiper/ui';
import { Col as col, Row as row } from 'reactstrap';
import { FaRegCalendar } from 'react-icons/fa';
import { FiAlertTriangle } from 'react-icons/fi';

export const SideContainer = styled.div`
  display: flex;
`;

export const Left = styled(SideContainer)`
  flex-direction: column;
`;

export const Row = styled(row)``;

export const Col = styled(col)``;

export const Button = styled(button)`
  margin: 0 10px 5px 10px;
  border: none;
  width: 100%;
  color: ${props => props.theme.colors.secondary600};
  text-align: left;

  :hover,
  &&&:focus {
    background-color: ${props => props.theme.colors.secondary100};
    color: ${props => props.theme.colors.secondary600};
  }
`;

export const EmergencyIcon = styled(FiAlertTriangle)`
  margin-right: 5px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-left: 1px solid ${props => props.theme.colors.gray5};
`;

export const UsersContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const UserContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  padding: 25px 0 10px 0;
`;

export const DangerButton = styled(({ inDanger, ...props }) => (
  <Button {...props} />
))`
  background-color: ${props =>
    props.theme.colors[props.inDanger ? 'dangerLight' : 'whiteSurface']};
  color: ${props =>
    props.theme.colors[props.inDanger ? 'dangerPrimary' : 'dangerSecondary']};

  :hover,
  &&&:focus {
    ${props =>
      props.inDanger
        ? css`
            background-color: ${props.theme.colors.dangerPrimary};
          `
        : ''}

    color: ${props =>
      props.theme.colors[props.inDanger ? 'whiteSurface' : 'dangerSecondary']};
    box-shadow: none;
  }
`;

export const Badge = styled(({ fontColor, noCapitalize, ...props }) => (
  <KBadge {...props} />
))`
  ${props =>
    props.noCapitalize
      ? css`
          text-transform: none;
        `
      : ''}


  ${props =>
    props.color in props.theme.colors
      ? css`
          background-color: ${props.theme.colors[props.color]};
        `
      : ''}

  ${props =>
    props.fontColor
      ? css`
          color: ${props.fontColor};
        `
      : ''}
`;

export const Label = styled(label)`
  font-size: 12px;
  color: ${props => props.theme.black};
  font-weight: bold;
  margin-bottom: 0;
`;

export const EventDateContainer = styled.div`
  display: flex;
  padding: 10px 0;
`;

export const EventDate = styled.span`
  margin-left: 5px;

  ${props =>
    css`
      color: ${props.theme.colors.mediumBlack};
    `}
`;

export const CalendarIcon = styled(FaRegCalendar)``;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextTitle = styled(Text)`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  color: ${props => props.theme.colors.mediumBlack};
`;

export const TextName = styled(Text)`
  margin-bottom: 2px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${props => props.theme.colors.highBlack};
`;

export const TextDescription = styled(Text)`
  font-size: 12px;
  line-height: 18px;
  margin-right: 4px;
  color: ${props => props.theme.colors.mediumBlack};
`;

export const TextAction = styled(Text)`
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  color: ${props => props.theme.colors.highBlack};
`;
