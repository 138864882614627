import CondominiumList from './List';
import CondominiumDetail from './Detail';

const breadcrumb = (label, to) => ({ label, to });

const requiredPermissions = ['condominiumManagement'];

export default [
  {
    name: 'List of condominiums',
    path: '/condominiums',
    exact: true,
    component: CondominiumList,
    breadcrumb: [breadcrumb('common:breadcrumb.condominiums', '/condominiums')],
    requiredPermissions,
  },
  {
    name: 'Condominium detail',
    path: '/condominiums/:id',
    exact: true,
    component: CondominiumDetail,
    breadcrumb: [
      breadcrumb('common:breadcrumb.condominiums', '/condominiums'),
      breadcrumb('common:breadcrumb.detail', '/condominiums/:id'),
    ],
    requiredPermissions,
  },
];
