import {
  createContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import propTypes from 'prop-types';
import { useMutation } from 'react-apollo';
import { useSwal, useToggle } from '@kiper/hooks';
import { useTranslation } from 'react-i18next';
import { attendance as attendanceGql } from '@kiper/monitoring-graphql';
import { apolloErrorHandler } from '@kiper/fns';
import { firstBy } from 'thenby';
import { useHistory } from 'react-router-dom';
import useCurrentLoggedContext from '../../hooks/useCurrentLoggedContext';
import { eventTypes } from '../../constants';

export const EventAttendanceContext = createContext();

const EVENTS_TO_GET_GUIDED_ATTENDANCE = [
  eventTypes.attendanceAdditional,
  eventTypes.answeredCall,
  eventTypes.intrusionDetection,
  eventTypes.alarmTrigger,
];

const INITIAL_STATE_ATTENDANCE = {
  events: [],
  showQueue: true,
  loadingQueue: true,
};

const INITIAL_STATE_POPUP = {
  visible: false,
  eventId: null,
  condoName: null,
};

const ACTIONS = Object.freeze({
  NEW: 'NEW',
  UPDATE: 'UPDATE',
  REMOVE: 'REMOVE',
  UPDATE_CONDOMINIUM_STATUS: 'UPDATE_CONDOMINIUM_STATUS',
});

const TAB_IDS = Object.freeze({
  ATTENDANCE_QUEUE: 'attendance-queue',
  ON_HOLD_QUEUE: 'on-hold-queue',
  ON_TECHNICAL_REVIEW_QUEUE: 'on-technical-review-queue',
});

const GUIDED_ATTENDANCE_PATH = '/guided-attendance';

export const EventAttendanceProvider = ({ children }) => {
  const { loggedContext } = useCurrentLoggedContext();
  const [
    filteredByCondominiumContextId,
    setFilteredByCondominiumContextId,
  ] = useState(null);
  const { toast } = useSwal();
  const [t] = useTranslation('event_attendance');
  const history = useHistory();

  const [goToFallback, toggleFallback] = useToggle(false);
  const condominiumStatusListeners = useRef([]);

  const [activeTab, setActiveTab] = useState(TAB_IDS.ATTENDANCE_QUEUE);

  const [data, setData] = useReducer((state, action) => {
    const copyState = { ...state };
    let result;
    switch (action.type) {
      case ACTIONS.NEW:
        result = {
          ...copyState,
          events: [...copyState.events, action.data],
        };
        break;
      case ACTIONS.UPDATE: {
        const eventIndex = state.events.findIndex(
          event => event.eventId === action.data.eventId,
        );

        let events;

        if (eventIndex !== -1) {
          events = state.events.map(event =>
            event.eventId === action.data.eventId ? action.data : event,
          );
        } else {
          events = [...state.events, action.data];
        }

        result = {
          ...copyState,
          events,
        };
        break;
      }
      case ACTIONS.REMOVE:
        result = {
          ...copyState,
          events: state.events.filter(
            event => event.eventId !== action.data.eventId,
          ),
        };
        break;
      default:
        result = { ...copyState, ...action };
    }
    return result;
  }, INITIAL_STATE_ATTENDANCE);

  const [popupData, setPopupData] = useReducer((state, action) => {
    const copyState = { ...state };
    const newState = {
      ...copyState,
      ...action,
      inAttendance: data?.events.some(
        x => x.attendant?.id === +loggedContext.personContextId,
      ),
    };
    return newState;
  }, INITIAL_STATE_POPUP);

  const handleClearData = () => {
    setData({ ...INITIAL_STATE_ATTENDANCE });
  };

  const handleClearPopupData = () => {
    setPopupData({ ...INITIAL_STATE_POPUP });
  };

  const handleClearEventFilter = () => {
    setFilteredByCondominiumContextId(null);
  };

  const handleCheckToClearEventFilter = () => {
    if (filteredByCondominiumContextId && data?.events?.length) {
      const filteredEvents = data?.events?.filter(
        event =>
          event.condominium.personContextId ===
            filteredByCondominiumContextId &&
          !event.onHold &&
          !event.onTechnicalReview,
      );

      const noFilteredEvents = !filteredEvents?.length;

      const onlyInAttendance =
        filteredEvents?.length === 1 && filteredEvents?.some(x => x.attendant);

      if (noFilteredEvents || onlyInAttendance) {
        handleClearEventFilter();
      }
    }
  };

  const handleFilterByCondominiumContextId = condominiumPersonContextId => {
    if (
      !filteredByCondominiumContextId ||
      filteredByCondominiumContextId !== condominiumPersonContextId
    ) {
      setFilteredByCondominiumContextId(condominiumPersonContextId);
    }
  };

  const onError = err => {
    const formattedErrors = apolloErrorHandler(err);
    if (formattedErrors && formattedErrors.length) {
      toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
  };

  const [fetchEvent, getEventProps] = useMutation(attendanceGql.get, {
    onError,
  });

  const [fetchDropEvent, getDropProps] = useMutation(attendanceGql.drop, {
    onCompleted: () => {
      history.push(GUIDED_ATTENDANCE_PATH);
      handleClearEventFilter();
    },
    onError,
  });

  const [fetchFinishEvent, getFinishProps] = useMutation(attendanceGql.finish, {
    onCompleted: () => {
      toast.fire({
        icon: 'success',
        title: t('finished-successfully'),
      });
      history.push(GUIDED_ATTENDANCE_PATH);

      handleCheckToClearEventFilter();
    },
    onError,
  });

  const loading =
    getEventProps.loading || getDropProps.loading || getFinishProps.loading;

  const getFilteredEvents = (events, condominiumContextId) => {
    return events?.filter(event => {
      const isSameCondominium =
        !condominiumContextId ||
        event?.condominium?.personContextId === condominiumContextId;

      return (
        event.attendant?.id !== Number(loggedContext.personContextId) &&
        !event.onHold &&
        !event.onTechnicalReview &&
        isSameCondominium
      );
    });
  };

  const {
    eventQueue,
    eventInAttendance,
    eventOnHoldQueue,
    eventOnHoldInAttendance,
    eventOnTechnicalReviewQueue,
    eventOnTechnicalReviewInAttendance,
  } = useMemo(
    () => ({
      eventQueue: getFilteredEvents(
        data?.events,
        filteredByCondominiumContextId,
      )
        .sort(
          firstBy('ownerId', 'desc')
            .thenBy('isCritical', 'desc')
            .thenBy(
              event =>
                event?.additionalInformation &&
                !!JSON.parse(event.additionalInformation)?.eventScheduled,
              'desc',
            )
            .thenBy('priority', 'desc')
            .thenBy('eventDate'),
        )
        ?.slice(0, 100),
      eventOnHoldQueue: data?.events
        ?.filter(
          event =>
            event.attendant?.id !== Number(loggedContext.personContextId) &&
            event.onHold,
        )
        .sort(firstBy('eventDate')),
      eventInAttendance: data?.events?.find(
        event =>
          event?.attendant?.id === +loggedContext.personContextId &&
          !event.onHold,
      ),
      eventOnHoldInAttendance: data?.events?.find(
        event =>
          event?.attendant?.id === +loggedContext.personContextId &&
          event.onHold,
      ),
      eventOnTechnicalReviewQueue: data?.events
        ?.filter(
          event =>
            event.attendant?.id !== Number(loggedContext.personContextId) &&
            event.onTechnicalReview,
        )
        .sort(firstBy('eventDate')),
      eventOnTechnicalReviewInAttendance: data?.events?.find(
        event =>
          event?.attendant?.id === +loggedContext.personContextId &&
          event.onTechnicalReview,
      ),
    }),
    [data?.events, filteredByCondominiumContextId],
  );

  const providerValues = useMemo(
    () => ({
      ...data,
      setData,
      ACTIONS,
      handleClearData,
      eventQueue,
      eventInAttendance,
      eventOnHoldQueue,
      eventOnHoldInAttendance,
      eventOnTechnicalReviewQueue,
      eventOnTechnicalReviewInAttendance,
      loading,
      popupData,
      setPopupData,
      handleClearPopupData,
      goToFallback,
      toggleFallback,
      filteredByCondominiumContextId,
      handleFilterByCondominiumContextId,
      handleCheckToClearEventFilter,
      handleClearEventFilter,
      tabControl: {
        TAB_IDS,
        setActiveTab,
        activeTab,
      },
      play: {
        getEvent: eventId => fetchEvent({ variables: { eventId } }),
        ...getEventProps,
      },
      stop: {
        dropEvent: () => fetchDropEvent({ variables: { message: 'STOP' } }),
        ...getDropProps,
      },
      finish: {
        finishEvent: ({ message, untreatedEventOptionId }) =>
          fetchFinishEvent({
            variables: { message, untreatedEventOptionId },
          }),
        ...getFinishProps,
      },
      condominiumStatusListeners,
      condominiumStatus: {
        subscribe: callback =>
          condominiumStatusListeners.current.push(callback),
        unsubscribe: callback => {
          condominiumStatusListeners.current = condominiumStatusListeners.current.filter(
            fn => fn !== callback,
          );
        },
      },
    }),
    [
      data,
      popupData,
      eventQueue,
      eventInAttendance,
      eventOnHoldQueue,
      eventOnHoldInAttendance,
      eventOnTechnicalReviewQueue,
      eventOnTechnicalReviewInAttendance,
      loading,
      activeTab,
    ],
  );

  useEffect(() => {
    const hasFilter = !!filteredByCondominiumContextId;
    const emptyEventQueue = !eventQueue?.length;
    const notEventInAttendance = !eventInAttendance;
    if (hasFilter && emptyEventQueue && notEventInAttendance) {
      handleClearEventFilter();
    }
  }, [eventQueue]);

  useEffect(() => {
    const inAttendance =
      eventInAttendance ||
      eventOnHoldInAttendance ||
      eventOnTechnicalReviewInAttendance;
    if (inAttendance) {
      const { eventId } = inAttendance;

      if (EVENTS_TO_GET_GUIDED_ATTENDANCE.includes(inAttendance?.eventType))
        history.push(`${GUIDED_ATTENDANCE_PATH}/${eventId}`);
      else history.push(`${GUIDED_ATTENDANCE_PATH}/${eventId}/fallback`);
    } else if (history?.location?.pathname !== GUIDED_ATTENDANCE_PATH) {
      history.push(GUIDED_ATTENDANCE_PATH);
    }
  }, [
    eventInAttendance,
    eventOnHoldInAttendance,
    eventOnTechnicalReviewInAttendance,
    history,
  ]);

  return (
    <EventAttendanceContext.Provider value={providerValues}>
      {children}
    </EventAttendanceContext.Provider>
  );
};

export default { EventAttendanceContext, EventAttendanceProvider };

EventAttendanceProvider.propTypes = {
  children: propTypes.oneOfType([propTypes.node, propTypes.element]),
};

EventAttendanceProvider.defaultProps = {
  children: undefined,
};
