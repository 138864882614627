import { useEffect, useMemo } from 'react';
import { ErrorMessage, Label } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import PersonSelect from '../../../../../../components/PersonSelect';
import { FormGroup } from '../../../styles';
import useCurrentLoggedContext from '../../../../../../hooks/useCurrentLoggedContext';
import { UserGuUnSelect } from '../../../../../../components/Select';
import PlaceTypeLabel from '../../../../../../components/PlaceTypeLabel';

export default function AccessNode({
  isEditable,
  isCreation,
  handleChange,
  isFieldVisible,
  values,
  touched,
  errors,
}) {
  const { loggedContext } = useCurrentLoggedContext(); // Externizar esse context no componente pai -> só nao deixar exibir os filhos quando nao existir o loggedContext
  const [t] = useTranslation('user');

  if (!loggedContext) return <div>Carregando</div>;

  useEffect(() => {
    if (values?.condominium)
      handleChange('condominium', {
        ...values.condominium,
      });

    if (values?.unity && values?.unityGroup) {
      handleChange('unity', {
        targetUnity: values.unity,
        targetUnityGroup: values.unityGroup,
        parentName: values.unityGroup.label,
        gu: { ...values.unityGroup },
        value: values.unity.treeNodeId || '',
      });
    }
  }, []);

  const invalid = useMemo(() => {
    return {
      condominium: touched.condominium && !!errors.condominium,
      unityGroup: touched.unityGroup && !!errors.unityGroup,
      unity: touched.unity && !!errors.unity,
    };
  }, [values, errors, touched]);

  const handleChangeUnity = e => {
    if (e) {
      const targetUnityGroup = { ...e.gu };
      const targetUnity = { ...e };
      delete targetUnity.gu;
      handleChange('unity', {
        targetUnity,
        targetUnityGroup,
        parentName: targetUnityGroup.label,
        gu: { ...targetUnityGroup },
        value: targetUnity?.treeNodeId || '',
      });
    } else {
      handleChange('unity', {
        targetUnity: '',
        targetUnityGroup: '',
        parentName: '',
        gu: {},
        value: '',
      });
    }
  };

  const handleChangeCondominium = e =>
    handleChange('condominium', {
      ...e,
      value: (e && e.value) || '',
    });

  const valuesUnity = useMemo(() => {
    if (!values) return '-';
    if (!values.unity?.label && !values.unityGroup?.label) return '-';
    if (!values.unityGroup?.label) return `${values.unity.label}`;
    if (!values.unity?.label) return `${values.unityGroup.label}`;

    return `${values.unityGroup.label} - ${values.unity.label}`;
  }, [values]);

  return (
    <>
      <FormGroup inline>
        {isCreation && (
          <Label style={{ fontSize: '14px' }}>
            {t('put.access-data.condominium-selection')}
          </Label>
        )}
        <Label htmlFor="condominium" block info>
          {t('put.access-data.condominium')}
        </Label>
        <PersonSelect
          width="100%"
          placeholder={t('put.access-data.condominium-select')}
          name="condominium"
          fieldName={['condominium']}
          sourceNodeId={loggedContext.topNodeId}
          value={values.condominium}
          isEditable={isCreation}
          onChange={handleChangeCondominium}
          invalid={invalid.condominium}
        />
        {invalid.condominium && (
          <ErrorMessage>{t('common:feedback.required-field')}</ErrorMessage>
        )}
      </FormGroup>
      {isFieldVisible('unity', values.profile.value) && (
        <>
          {!isEditable ? (
            <>
              <FormGroup inline>
                <PlaceTypeLabel
                  labelText={values.unity ? values.unity.placeTypeLabel : null}
                  type="unity"
                />
                <Label>{valuesUnity}</Label>
              </FormGroup>
            </>
          ) : (
            <FormGroup inline>
              <PlaceTypeLabel
                labelText={values.unity ? values.unity.placeTypeLabel : null}
                type="unity"
              />
              <UserGuUnSelect
                name="unity"
                isClearable={false}
                onChange={handleChangeUnity}
                sourceNodeId={values.condominium.value}
                value={{
                  ...values.unity,
                  parentName: values?.unityGroup?.label,
                }}
                guLoad={false}
                isDisabled={!values.condominium && !values.profile}
              />
              {invalid.unity && (
                <ErrorMessage>
                  {t('common:feedback.required-field')}
                </ErrorMessage>
              )}
            </FormGroup>
          )}
        </>
      )}
    </>
  );
}
AccessNode.propTypes = {
  values: propTypes.object.isRequired,
  errors: propTypes.object,
  isFieldVisible: propTypes.func.isRequired,
  touched: propTypes.object,
  isEditable: propTypes.bool.isRequired,
  isCreation: propTypes.bool.isRequired,
  handleChange: propTypes.func.isRequired,
};

AccessNode.defaultProps = {
  errors: {},
  touched: {},
};
