import styled from 'styled-components';
import { H4 as h4, Button as button, Flex } from '@kiper/ui';
import { Form as form, FormGroup as formGroup, Row as row } from 'reactstrap';
import { MdClose } from 'react-icons/md';
import { FiPhoneIncoming } from 'react-icons/fi';

export const EventContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  height: 100vh;

  @media screen and (min-width: 1980px) {
    max-width: ${({ fallback }) =>
      fallback ? 'calc(100vw - 605px)' : 'calc(100vw - 815px)'};
  }

  @media screen and (max-width: 1920px) {
    max-width: ${({ fallback }) =>
      fallback ? 'calc(100vw - 605px)' : 'calc(100vw - 815px)'};
  }

  @media screen and (max-width: 1600px) {
    max-width: ${({ fallback }) =>
      fallback ? 'calc(100vw - 395px)' : 'calc(100vw - 605px)'};
  }
`;

export const HeaderTitle = styled(h4)`
  margin-bottom: 0px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderFooter = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const HalfContainer = styled.div``;

export const Container = styled.div`
  flex-direction: column;
  display: flex;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.white};
  height: 100%;
`;

export const Form = styled(form)`
  padding: 0 20px;
`;

export const FormGroup = styled(formGroup)``;

export const UserContainer = styled(HalfContainer)`
  margin-bottom: 10px;
  & + & {
    margin-left: 20px;
  }
`;

export const Button = styled(button)`
  align-self: flex-end;
`;

export const TextRight = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-start;
  height: 100%;
  padding-bottom: 20px;
`;

export const UsersContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0 0 20px;
  margin-bottom: 20px;
  background-color: #fff;
  border-bottom: 1px solid #d9d9da;
`;

export const CloseIcon = styled(MdClose)`
  font-size: 24px;
  color: ${props =>
    props.variant === 'danger' ? props.theme.colors.whiteSurface : '#c3c3c3'};
  cursor: pointer;
`;

export const FabContainer = styled.div`
  position: fixed;
  right: 20px;
  bottom: 90px;
`;

export const Fab = styled.div`
  height: 60px;
  width: 60px;
  padding: 10px;
  border-radius: 30px;
  background-color: #1b1b1c;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FabPhoneIcon = styled(FiPhoneIncoming)`
  color: #fff;
  font-size: 30px;
`;

export const Row = styled(row)`
  margin: 0;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
`;

export const WhiteLabelRow = styled(FlexRow)`
  align-items: center;
  color: ${props => props.theme.colors.whiteSurface};
  font-weight: bold;

  span {
    padding-left: 10px;
  }
`;
