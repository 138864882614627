import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex, ErrorMessage, Spinner } from '@kiper/ui';
import StepStructure, { StepTextTypeColor } from '../StepStructure';
import OptionsSelector from '../../OptionsSelector';
import { useForm, useNextAction, useQueries } from './hooks';
import { triageTypeTranslate } from '../../../constants';

const StepGetSubtriage = ({ event }) => {
  const { t } = useTranslation('guided-attendance');
  const { nextAction } = useNextAction({ eventId: event?.eventId });
  const { values, handleChange, errors, touched } = useForm({ nextAction, t });
  const { subtriages, loading } = useQueries({ values });

  return (
    <StepStructure
      type={StepTextTypeColor.SPEAK}
      text={t('step-get-subtriage.title', {
        name: values?.stepAttendanceGetName?.name,
        triageType: t(
          `step-get-subtriage.triage-type.${
            triageTypeTranslate[values?.stepAttendanceGetReason?.type]
          }`,
        ),
      })}
      hasPreviousStepButton
    >
      {loading && (
        <Flex justifyContent="center" width="100%" py="20px">
          <Spinner size={24} />
        </Flex>
      )}
      {!loading && !!subtriages?.length && (
        <OptionsSelector
          options={subtriages}
          name="stepGetSubtriage"
          handleChange={handleChange}
          value={values?.stepGetSubtriage?.id}
        />
      )}
      {!!touched?.stepGetSubtriage && !!errors?.stepGetSubtriage?.id && (
        <ErrorMessage>{errors.stepGetSubtriage?.id}</ErrorMessage>
      )}
    </StepStructure>
  );
};

export default StepGetSubtriage;

StepGetSubtriage.propTypes = {
  event: propTypes.object.isRequired,
};
