import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Page, Breadcrumb, Input, Alert } from '@kiper/ui';
import propTypes from 'prop-types';
import EventSettingsListPage from '@kiper/shared-pages/src/pages/EventSettings/List';
import { sharedSchemaGraphql as queries } from '@kiper/monitoring-graphql';
import { useLazyQuery } from 'react-apollo';
import { Form } from 'reactstrap';
import { FiAlertCircle } from 'react-icons/fi';
import { useCurrentLoggedContext } from '../../../hooks';
import { EventSettingsOriginSelect } from '../../../components/Select';
import { FormGroup } from './styles';

export default function EventSettingsList({ route, history }) {
  const [t] = useTranslation('event-settings');
  const { loggedContext } = useCurrentLoggedContext();
  const { origin } = history.location.state || { origin: null };

  const [originContext, updateOriginContext] = useState(
    origin || {
      label: loggedContext?.partner?.name,
      value: loggedContext?.partner?.personContextId,
      contextId: loggedContext?.partner?.personContextId,
      hasCoPartner: loggedContext?.partner?.hasCoPartner,
    },
  );

  const setOriginContext = values => {
    updateOriginContext({
      ...values,
      hasCoPartner: loggedContext?.partner?.hasCoPartner,
    });
  };

  const [filters, setFilters] = useState({
    searchText: '',
    page: 1,
    pageSize: 10,
  });

  const handleChange = (name, value) =>
    setFilters(x => ({
      ...x,
      [name]: value,
    }));

  const handlePageSize = p =>
    setFilters(x => ({ ...x, page: 1, pageSize: Number(p) }));

  const personContextId = parseInt(
    originContext
      ? originContext.contextId
      : loggedContext?.partner?.personContextId,
    10,
  );

  const [getData, { data, loading }] = useLazyQuery(
    queries.eventSettings.eventSettings,
    {
      fetchPolicy: 'no-cache',
      variables: {
        search: {
          ...filters,
          personContextId,
        },
      },
    },
  );

  useEffect(() => {
    getData();
  }, []);

  const { collection = [], pagination } = data?.eventSettings ?? {
    pagination: {},
  };

  useEffect(() => {
    if (origin) setOriginContext(origin);
  }, [origin]);

  return (
    <Page>
      <Breadcrumb stack={route.breadcrumb} formatter={t} />
      <Page.Title>{t('list.title')}</Page.Title>
      <Page.Actions>
        <Form inline>
          <FormGroup>
            <EventSettingsOriginSelect
              placeholder={t('list.origin-select')}
              name="condominium"
              sourceNodeId={loggedContext.topNodeId}
              fieldName={['condominium']}
              isClearable
              onChange={setOriginContext}
              value={originContext}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              name="searchText"
              placeholder={t('common:search')}
              value={filters.searchText}
              delay={600}
              onChange={handleChange}
            />
          </FormGroup>
        </Form>
      </Page.Actions>

      <hr />

      {!personContextId && (
        <Alert
          background="warningPrimary"
          icon={FiAlertCircle}
          text={t('list.alert.message')}
        />
      )}
      <EventSettingsListPage
        collection={collection}
        loading={loading}
        t={t}
        handleChange={handleChange}
        filters={filters}
        handlePageSize={handlePageSize}
        history={history}
        pagination={pagination}
        isEditable={!!personContextId}
        originContext={originContext}
        reloadPage={getData}
      />
    </Page>
  );
}

EventSettingsList.propTypes = {
  route: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
};
