import styled from 'styled-components';

export const Container = styled.div`
  min-width: 48px;
  max-width: 48px;
  height: 100vh;
  padding: 4px;
  background-color: ${props => props.theme.colors.white};
  text-align: center;
  z-index: 2;
  border-left: 1px solid ${props => props.theme.colors.secondary300};
`;

export const TabbarItemContainer = styled.div`
  padding: 8px 4px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.secondary50};
  }

  &.is-active {
    background-color: ${props => props.theme.colors.primary50};
    color: ${props => props.theme.colors.primary500};
  }

  & + & {
    margin-top: 4px;
  }
`;
