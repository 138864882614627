import { useContext, useMemo } from 'react';
import propTypes from 'prop-types';
import { Flex } from '@kiper/ui';
import { formatHoursAndMinutesUtc } from '@kiper/fns';
import EventCard from './EventCard';
import { EventAttendanceContext } from '../../store';

import * as S from './styles';

const SimplifiedEventCard = ({ event, eventOnHover, setEventOnHover }) => {
  const { eventInAttendance } = useContext(EventAttendanceContext);

  const { eventDate, isEventScheduled } = useMemo(
    () => ({
      eventDate: event?.eventDate
        ? formatHoursAndMinutesUtc(event?.eventDate)
        : null,
      isEventScheduled:
        event?.additionalInformation &&
        JSON.parse(event?.additionalInformation)?.eventScheduled,
    }),
    [event],
  );

  return (
    <Flex onMouseEnter={() => setEventOnHover(event.eventId)}>
      {eventOnHover === event?.eventId ? (
        <EventCard event={event} onMouseLeave={() => setEventOnHover(null)} />
      ) : (
        <S.SimplifiedEventCard
          attend={eventInAttendance?.eventId === event?.eventId}
          critical={event?.isCritical}
          isTelephony={!!event?.ownerId}
          isEventScheduled={isEventScheduled}
        >
          {eventDate && (
            <S.EventDateText id="event-title">{eventDate}</S.EventDateText>
          )}
        </S.SimplifiedEventCard>
      )}
    </Flex>
  );
};

export default SimplifiedEventCard;

SimplifiedEventCard.propTypes = {
  event: propTypes.object.isRequired,
  setEventOnHover: propTypes.func.isRequired,
  eventOnHover: propTypes.string,
};
SimplifiedEventCard.defaultProps = { eventOnHover: null };
