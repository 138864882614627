import { useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import propTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { ThemeContext } from 'styled-components';
import { FiNavigation } from 'react-icons/fi';

import { event as eventGql } from '@kiper/monitoring-graphql';
import CustomInput from '@kiper/ui/src/Input';
import { useToggle, useSwal } from '@kiper/hooks';
import { Flex, Text, KiperButton, PlaceHolder, Badge } from '@kiper/ui';
import { formatDistanceDate, apolloErrorHandler } from '@kiper/fns';

import {
  TextInfo,
  TextTitle,
  EventDetailContainer,
  EventDetail as EventDetailHeader,
} from './styles';
import { profileTypes, profileColor } from '../../../constants';
import { EventActivityModal } from '..';
import UserInfo from '../../UserInfo';

const loaderStyle = {
  height: '40px',
  width: '1000px',
  marginBottom: '10px',
  borderRadius: '4px',
};

function EventDetail({ eventId, eventDate }) {
  const { t } = useTranslation('modals/event-timeline-modal');
  const [eventActivityModal, toggleEventActivityModalOpen] = useToggle(false);
  const theme = useContext(ThemeContext);
  const { toast } = useSwal();

  const { data, loading } = useQuery(eventGql.eventTimelineDetail, {
    fetchPolicy: 'network-only',
    variables: {
      eventId,
    },
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const { eventTimelineDetail } = data || { eventTimelineDetail: {} };

  const ShotBy = () => {
    if (
      eventTimelineDetail?.dweller?.person &&
      eventTimelineDetail?.dweller?.profile?.fieldName
    ) {
      return (
        <UserInfo
          user={eventTimelineDetail.dweller.person}
          badge
          profile={t(
            `common:profiles.${
              profileTypes[eventTimelineDetail.dweller.profile.fieldName]
            }`,
          )}
          profileColor={
            profileColor[eventTimelineDetail.dweller.profile.fieldName]
          }
        />
      );
    }

    const additionalInformation = JSON.parse(
      eventTimelineDetail.additionalInformation,
    );

    if (
      additionalInformation?.attendant &&
      additionalInformation?.attendant?.name &&
      additionalInformation?.attendant?.profile
    ) {
      return (
        <>
          {additionalInformation?.attendant?.name}
          {!!additionalInformation?.attendant?.profile && (
            <Badge color="blue900">
              {t(
                `common:profiles.${
                  profileTypes[
                    additionalInformation?.attendant?.profile.fieldName
                  ]
                }`,
              )}
            </Badge>
          )}
        </>
      );
    }

    return <>-</>;
  };

  return (
    <>
      {eventActivityModal && (
        <EventActivityModal
          eventId={eventId}
          eventDate={eventDate}
          onToggle={toggleEventActivityModalOpen}
        />
      )}
      {loading ? (
        <PlaceHolder style={loaderStyle} type="line" lines={6} />
      ) : (
        <EventDetailContainer>
          <EventDetailHeader>
            <TextTitle mb="24px">{t('attendance-information')}</TextTitle>
            <Flex mb="24px" flexDirection="column">
              <TextInfo mb="4px">
                <Trans>{t('shot-by')}</Trans>
              </TextInfo>
              <ShotBy />
            </Flex>
            <Flex mb="24px" flexDirection="column">
              <TextInfo mb="4px">
                <Trans>{t('attended-by')}</Trans>
              </TextInfo>
              {eventTimelineDetail?.attendant?.person &&
              eventTimelineDetail?.attendant?.profile?.fieldName ? (
                <UserInfo
                  user={eventTimelineDetail.attendant.person}
                  badge
                  profile={t(
                    `common:profiles.${
                      profileTypes[
                        eventTimelineDetail.attendant.profile.fieldName
                      ]
                    }`,
                  )}
                  profileColor={
                    profileColor[
                      eventTimelineDetail.attendant.profile.fieldName
                    ]
                  }
                />
              ) : (
                <Text height="44px">-</Text>
              )}
            </Flex>
            <Flex flexDirection="column">
              <TextInfo mb="4px">{t('device')}</TextInfo>
              {eventTimelineDetail?.device ? (
                <>
                  <Text>{eventTimelineDetail?.device?.name}</Text>
                  {JSON.parse(eventTimelineDetail?.device?.serializedParams)
                    ?.extension && (
                    <Text
                      fontSize="12px"
                      fontWeight="400"
                      lineHeight="18px"
                      color={theme.colors.mediumBlack}
                    >
                      {`Ramal ${
                        JSON.parse(
                          eventTimelineDetail?.device?.serializedParams,
                        )?.extension
                      }`}
                    </Text>
                  )}
                </>
              ) : (
                <Text height="44px">-</Text>
              )}
            </Flex>
          </EventDetailHeader>
          <Flex m="0 16px 0 24px" flexDirection="column" flex={1}>
            <TextTitle mb="24px">{t('attendance-detail')}</TextTitle>
            <Flex mb="24px">
              <Flex mr="32px" flexDirection="column">
                <TextInfo>{t('occurred-on')}</TextInfo>
                <Text>
                  {eventTimelineDetail?.creationDate
                    ? formatDistanceDate(eventTimelineDetail?.creationDate, t)
                    : '-'}
                </Text>
              </Flex>
              <Flex mr="32px" flexDirection="column">
                <TextInfo>{t('closed-on')}</TextInfo>
                <Text>
                  {eventTimelineDetail?.treatmentEndDate
                    ? formatDistanceDate(
                        eventTimelineDetail?.treatmentEndDate,
                        t,
                      )
                    : '-'}
                </Text>
              </Flex>
              <Flex flexDirection="column">
                <TextInfo>{t('fired-on-access')}</TextInfo>
                <Text>{eventTimelineDetail?.place?.name || '-'}</Text>
              </Flex>
            </Flex>

            <Flex flexDirection="row">
              <Flex mb="16px" flexDirection="column">
                <TextInfo mb="4px">{t('event-activities')}</TextInfo>
                <KiperButton
                  variant="out"
                  color="primary"
                  onClick={toggleEventActivityModalOpen}
                  icon={<FiNavigation />}
                >
                  {t('click-here-to-view')}
                </KiperButton>
              </Flex>
            </Flex>

            <Flex flex={1} flexDirection="column">
              <TextInfo mb="4px">{t('event-record')}</TextInfo>
              <CustomInput
                type="textarea"
                onChange={() => {}}
                name="treatment"
                value={eventTimelineDetail?.treatment || ''}
                readOnly
                rows={5}
              />
            </Flex>
          </Flex>
        </EventDetailContainer>
      )}
    </>
  );
}

export default EventDetail;

EventDetail.propTypes = {
  eventId: propTypes.string.isRequired,
  eventDate: propTypes.string.isRequired,
};
