import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex, Table, Text } from '@kiper/ui';
import { useInfiniteScroll } from './hooks';
import { TableContainer } from './styles';

const renderListItemDefault = props => {
  return (
    <tr ref={props?.trRef} {...props}>
      {props?.index}
    </tr>
  );
};

const InfiniteScrollTable = ({
  columns,
  variables,
  query,
  renderListItem,
  onCompletedCallback,
  onErrorCallback,
  hover,
  tableRef,
  tableMaxHeight,
}) => {
  const { t } = useTranslation('common');

  const { state, loader, hasMore, lastBookElementRef } = useInfiniteScroll(
    query,
    variables,
    onCompletedCallback,
    onErrorCallback,
  );

  return (
    <TableContainer ref={tableRef} maxheight={tableMaxHeight}>
      <Table columns={columns} hover={hover}>
        <tbody>
          {state?.map((item, index) => {
            if (state?.length === index + 1) {
              return renderListItem({
                trRef: lastBookElementRef,
                item,
                index,
                key: index,
              });
            }
            return renderListItem({
              item,
              index,
              key: index,
            });
          })}
          {loader && (
            <tr>
              <td colSpan={12}>
                <Flex justifyContent="center">
                  <Text color="secondary400" fontSize="12px">
                    {t('loading')}
                  </Text>
                </Flex>
              </td>
            </tr>
          )}

          {!state?.length && !loader && !hasMore && (
            <tr>
              <td colSpan={12}>
                <Flex justifyContent="center">
                  <Text color="secondary400" fontSize="12px">
                    {t('no-items-found')}
                  </Text>
                </Flex>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default InfiniteScrollTable;

InfiniteScrollTable.propTypes = {
  renderListItem: propTypes.func,
  variables: propTypes.object,
  query: propTypes.object,
  columns: propTypes.any.isRequired,
  onCompletedCallback: propTypes.func,
  onErrorCallback: propTypes.func,
  hover: propTypes.bool,
  tableRef: propTypes.object,
  tableMaxHeight: propTypes.string,
};

InfiniteScrollTable.defaultProps = {
  renderListItem: renderListItemDefault,
  variables: null,
  query: null,
  onCompletedCallback: null,
  onErrorCallback: null,
  hover: false,
  tableRef: null,
  tableMaxHeight: null,
};
