import { isPossiblePhoneNumber } from 'libphonenumber-js';
import * as yup from 'yup';
import { handleValidateCPF } from '@kiper/fns';
import { transientPersonTypes } from '../constants';

const transientCategories = Object.freeze({
  CONTACT: 1,
  VEHICLE: 2,
  DOCUMENT: 3,
  COMPANY: 4,
});

const transientTypes = Object.freeze({
  companyName: transientCategories.COMPANY,
  contactPhoneNumber: transientCategories.CONTACT,
  documentCpf: transientCategories.DOCUMENT,
  vehiclePlate: transientCategories.VEHICLE,
  documentRg: transientCategories.DOCUMENT,
});

const serviceProviderSchema = t => ({
  companyName: yup
    .string()
    .trim()
    .min(3, t('common:feedback.min', { number: 3 }))
    .required(t('common:feedback.required-field')),
});

const initialDefaultSchema = t => ({
  name: yup
    .string()
    .trim()
    .min(3, t('common:feedback.min', { number: 3 }))
    .required(t('common:feedback.required-field')),
});
const CPF_REGEX = /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$/;
const ONLY_NUMBER_REGEX = /^\d+$/;

const useTransientPersonSettings = () => {
  const handleGetPlaceholder = (t, name) => {
    const placeholder = {
      clientName: t('step-register-service-provider.placeholder.client-name'),
      contactPhoneNumber: t(
        'step-register-service-provider.placeholder.contact-phone-number',
      ),
      companyName: t('step-register-service-provider.placeholder.company-name'),
      documentCpf: t('step-register-service-provider.placeholder.document-cpf'),
      documentRg: t('step-register-service-provider.placeholder.document-rg'),
      vehiclePlate: t(
        'step-register-service-provider.placeholder.vehicle-plate',
      ),
    };

    if (name in placeholder) {
      return placeholder[name];
    }

    return '';
  };

  const handleGetLabel = (t, name) => {
    const label = {
      clientName: t('step-register-service-provider.label.client-name'),
      contactPhoneNumber: t(
        'step-register-service-provider.label.contact-phone-number',
      ),
      companyName: t('step-register-service-provider.label.company-name'),
      documentCpf: t('step-register-service-provider.label.document-cpf'),
      documentRg: t('step-register-service-provider.label.document-rg'),
      vehiclePlate: t('step-register-service-provider.label.vehicle-plate'),
    };

    if (name in label) {
      return label[name];
    }

    return '';
  };

  const handleGetMask = name => {
    if (name === 'documentCpf') {
      return '999.999.999-99';
    }
    return null;
  };

  const generateFieldValidation = (t, name, isRequired) => {
    let validation;

    const schema = {
      contactPhoneNumber: yup.string().test({
        message: t('step-register-service-provider.feedback.phone'),
        test: phone => {
          if (!phone) return true;
          return isPossiblePhoneNumber(phone.replace(/\s/gi, ''));
        },
      }),
      documentCpf: yup
        .string()
        .trim()
        .matches(
          CPF_REGEX,
          t('step-register-service-provider.feedback.invalid-cpf'),
        )
        .test(
          'valid-cpf',
          t('step-register-service-provider.feedback.invalid-cpf'),
          value => (value ? handleValidateCPF(value) : true),
        ),
      documentRg: yup
        .string()
        .trim()
        .matches(ONLY_NUMBER_REGEX, t('common:feedback.only-numbers')),
      vehiclePlate: yup.string().trim(),
    };

    if (name in schema) {
      validation = schema[name];
    }

    if (isRequired && validation) {
      validation = validation.required(t('common:feedback.required-field'));
    }

    return validation;
  };

  const handleGenerateSchema = (t, fields) => {
    if (!fields.length) return null;

    const schema = {};

    fields.forEach(field => {
      const { name, isRequired } = field;
      if (name) {
        schema[name] = generateFieldValidation(t, name, isRequired);
      }
    });

    return schema;
  };

  const handleGenerateInitialValues = fields => {
    if (!fields.length) return null;

    const initialValues = {};

    fields.forEach(field => {
      const { name } = field;
      if (name) {
        initialValues[name] = '';
      }
    });

    return initialValues;
  };

  const handleCreateTransientPersonDetails = ({
    transientPersonType,
    values,
  }) => {
    const transientPersonSettingsValues = { ...values };
    if (transientPersonSettingsValues?.name)
      delete transientPersonSettingsValues.name;
    if (transientPersonSettingsValues?.companyName)
      delete transientPersonSettingsValues.companyName;
    if (transientPersonSettingsValues?.transientPersonId)
      delete transientPersonSettingsValues.transientPersonId;

    const transientPersonDetails = [];

    if (transientPersonType === transientPersonTypes['service-provider']) {
      transientPersonDetails.push({
        name: 'companyName',
        value: values.companyName,
        type: transientTypes.companyName,
      });
    }

    if (Object.keys(transientPersonSettingsValues).length > 0) {
      Object.keys(transientPersonSettingsValues).forEach(key => {
        const detail = {
          name: key,
          value: values[key],
          type: transientTypes[key],
        };
        if (values[key]) transientPersonDetails.push(detail);
      });
    }

    return transientPersonDetails;
  };

  const handleGetInitialValues = ({
    transientPersonType,
    name,
    transientPersonSettings,
  }) => {
    let initialValues = {
      name,
    };

    if (transientPersonType === transientPersonTypes['service-provider']) {
      initialValues.companyName = '';
    }

    if (transientPersonSettings?.length) {
      const settingsInitialValues = handleGenerateInitialValues(
        transientPersonSettings,
      );
      if (settingsInitialValues) {
        initialValues = { ...initialValues, ...settingsInitialValues };
      }
    }

    return initialValues;
  };

  const handleGetValidationSchema = ({
    transientPersonType,
    transientPersonSettings,
    t,
  }) => {
    let schema = { ...initialDefaultSchema(t) };

    if (transientPersonType === transientPersonTypes['service-provider'])
      schema = { ...schema, ...serviceProviderSchema(t) };

    if (transientPersonSettings?.length) {
      const additionalSchema = handleGenerateSchema(t, transientPersonSettings);
      if (additionalSchema) schema = { ...schema, ...additionalSchema };
    }
    return schema;
  };

  return {
    handleGetPlaceholder,
    handleGetLabel,
    handleGetMask,
    handleGetInitialValues,
    handleGetValidationSchema,
    handleCreateTransientPersonDetails,
  };
};

export default useTransientPersonSettings;
