import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex, ErrorMessage, Spinner } from '@kiper/ui';
import StepStructure, { StepTextTypeColor } from '../StepStructure';

import useNextAction from './hooks/useNextAction';
import useForm from './hooks/useForm';
import useQueries from './hooks/useQueries';
import OptionsSelector from '../../OptionsSelector';

const StepAttendanceGetReason = ({ event }) => {
  const { t } = useTranslation('guided-attendance');

  const { nextAction } = useNextAction({ event });
  const { values, handleChange, errors, touched } = useForm({ nextAction, t });
  const { triages, loading } = useQueries({ event });

  return (
    <StepStructure
      type={StepTextTypeColor.SPEAK}
      text={t('step-get-reason.title')}
      hasPreviousStepButton
    >
      {loading && (
        <Flex justifyContent="center" width="100%" py="20px">
          <Spinner size={24} />
        </Flex>
      )}
      {!loading && !!triages?.length && (
        <OptionsSelector
          options={triages}
          name="stepAttendanceGetReason"
          handleChange={triage => handleChange(triage, event?.eventId)}
          value={values?.stepAttendanceGetReason?.id}
        />
      )}
      {!!touched?.stepAttendanceGetReason &&
        !!errors?.stepAttendanceGetReason?.id && (
          <ErrorMessage>{errors.stepAttendanceGetReason?.id}</ErrorMessage>
        )}
    </StepStructure>
  );
};

export default StepAttendanceGetReason;

StepAttendanceGetReason.propTypes = {
  event: propTypes.object.isRequired,
};
