import styled from 'styled-components';
import { Input as InputUi, Label as LabelUi } from '@kiper/ui';
import { Col, Row } from 'reactstrap';
import { UserGuUnSelect } from '../../../../../components/Select';

export const QuestionsContainer = styled(Row)`
  margin: 0 -7px;
`;

export const InputGroup = styled(Col)`
  margin: 5px 0;
`;

export const Label = styled(LabelUi)`
  width: 100%;
  color: ${props => props.theme.colors.secondary800};
`;

export const Input = styled(InputUi)`
  width: 100%;
  color: ${props => props.theme.colors.secondary700};
  border: 1px solid ${props => props.theme.colors.secondary800};
`;

export const Select = styled(UserGuUnSelect)`
  .select__control {
    border-color: ${props => props.theme.colors.secondary800} !important;
  }
`;
