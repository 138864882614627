import { getUserLanguageCode } from '@kiper/fns';

const useGetDescriptions = () => {
  const handleMountDescription = object => {
    if (typeof object?.description === 'string') {
      return {
        ...object,
        description: {
          pt: object?.description,
          en: object?.descriptionEn,
          es: object?.descriptionEs,
        },
      };
    }
    return object;
  };

  const handleGetDescription = object => {
    if (!object) {
      return '';
    }
    const language = getUserLanguageCode();

    let copyObject = { ...object };
    copyObject = handleMountDescription(copyObject);
    const description =
      copyObject?.description?.[language] || copyObject?.description?.pt;

    return description;
  };

  return {
    handleGetDescription,
  };
};

export default useGetDescriptions;
