import { useState, useEffect, useMemo } from 'react';
import { useMutation } from 'react-apollo';
import { adamSender } from '@kiper/monitoring-graphql';
import { useTranslation } from 'react-i18next';
import { useSwal } from '@kiper/hooks';
import { sendAmplitudeData } from '../services/amplitude';

export const DEVICE_ACTIONS = {
  open: 'open',
  keep_opened: 'open/keep',
  close: 'close',
  keep_opened_cleaning: 'cleaning',
  keep_opened_truck: 'truck',
};

const useDevices = ({ event, newCameras, triageId = null }) => {
  const [t] = useTranslation('event_attendance');
  const { toast } = useSwal();

  const [places, setPlaces] = useState([]);
  const [interlocks, setInterlocks] = useState([]);
  const [devices, setDevices] = useState([]);
  const [cameras, setCameras] = useState([]);
  const [mainCamera, setMainCamera] = useState(event.mainCamera);
  const [selectedPlace, setSelectedPlace] = useState(event.place?.id);
  const [deviceLoading, setDeviceLoading] = useState(null);
  const [openedDevices, setOpenedDevices] = useState([]);
  const [keepOpenedDevices, setKeepOpenedDevices] = useState([]);

  const onError = () => {
    toast.fire({ title: t('details.commands.error-message'), icon: 'error' });
  };

  const [openDeviceMutation] = useMutation(adamSender.openDevice, {
    fetchPolicy: 'no-cache',
    onError,
    onCompleted: () =>
      toast.fire({
        title: t('details.commands.feedbacks.open'),
        icon: 'success',
      }),
  });
  const [closeDeviceMutation] = useMutation(adamSender.closeDevice, {
    fetchPolicy: 'no-cache',
    onError,
    onCompleted: () =>
      toast.fire({
        title: t('details.commands.feedbacks.close'),
        icon: 'success',
      }),
  });
  const [keepDeviceOpenedMutation] = useMutation(adamSender.keepDeviceOpened, {
    fetchPolicy: 'no-cache',
    onError,
    onCompleted: () =>
      toast.fire({
        title: t('details.commands.feedbacks.keep-open'),
        icon: 'success',
      }),
  });

  const [keepDeviceOpenedV2Mutation] = useMutation(
    adamSender.keepDeviceOpenedV2,
    {
      fetchPolicy: 'no-cache',
      onError,
      onCompleted: () =>
        toast.fire({
          title: t('details.commands.feedbacks.keep-open'),
          icon: 'success',
        }),
    },
  );

  const eventCameras = useMemo(() => {
    if (newCameras?.length) return newCameras;
    return [];
  }, [event, newCameras]);

  useEffect(() => {
    const eventPlaces = event?.condominium?.personContext?.places?.filter(
      place =>
        !JSON.parse(place?.serializedParams)?.ignoredTriages?.includes(
          +triageId,
        ),
    );
    if (eventPlaces?.length) {
      setPlaces([...eventPlaces]);
      if (
        !event?.place?.id ||
        !eventPlaces.find(x => x.id === event?.place?.id)
      ) {
        setSelectedPlace(eventPlaces?.[0].id);
      } else {
        setSelectedPlace(event?.place?.id);
      }
    } else {
      setPlaces([]);
      setSelectedPlace(event?.place?.id);
    }

    if (event?.cameras && event?.mainCamera) {
      setCameras(newCameras);
    } else {
      setCameras(null);
    }
  }, [event, newCameras]);

  useEffect(() => {
    if (places?.length) {
      if (selectedPlace) {
        setDevices(places?.find(i => i.id === selectedPlace)?.devices);
        setInterlocks(
          places?.find(i => i.id === selectedPlace)?.interlockedPlaces,
        );
        setCameras(eventCameras?.filter(i => i.placeId === selectedPlace));
      } else {
        setDevices(
          places
            .filter(i => i.devices)
            .map(i => i.devices)
            ?.reduce((a, b) => [...a, ...b]),
        );
        setCameras(eventCameras);
      }
    } else {
      setDevices([]);
      setInterlocks([]);
      setCameras([]);
    }
  }, [places, selectedPlace]);

  useEffect(() => {
    if (event?.mainCamera || cameras?.length) {
      const contains = cameras?.find(
        x =>
          x.placeId === selectedPlace &&
          JSON.parse(x.serializedParams)?.isPrincipal,
      );

      if (contains) {
        setMainCamera(contains);
      } else {
        setMainCamera(cameras?.[0]);
      }
    } else {
      setMainCamera(null);
    }
  }, [cameras, selectedPlace]);

  const isDeviceOpened = id => openedDevices.find(i => i.id === id);

  const isDeviceKeepOpened = id => keepOpenedDevices.find(i => i.id === id);

  const handleDevice = async (
    command,
    device,
    keepOpenDoorReason = false,
    data = {},
  ) => {
    let result;

    const mutationKeepDeviceOpened = keepOpenDoorReason
      ? keepDeviceOpenedV2Mutation
      : keepDeviceOpenedMutation;

    setDeviceLoading(device.id);

    const variables = {
      deviceHandler: {
        deviceId: device.id,
        doorId: device.doorId,
        eventId: event.eventId,
      },
    };

    switch (command) {
      case DEVICE_ACTIONS.open:
        result = await openDeviceMutation({
          variables,
        });
        if (result && result.data && result.data.openDevice) {
          setDeviceLoading(null);
          setOpenedDevices([...openedDevices, device]);
        }

        sendAmplitudeData('open the door', {
          eventId: event?.eventId,
          eventCode: event?.eventType,
          partner: event?.partner?.name,
          condominium: event?.condominium?.name,
        });

        break;
      case DEVICE_ACTIONS.keep_opened:
        result = await mutationKeepDeviceOpened({
          variables: {
            deviceHandler: {
              ...variables.deviceHandler,
              keepOpenedType: 0,
              ...(keepOpenDoorReason && { reason: data?.reason }),
            },
          },
        }).then(() =>
          toast.fire({
            title: t('details.commands.feedbacks.keep-open'),
            icon: 'success',
          }),
        );

        if (result && result.data && result.data.keepDeviceOpened) {
          setDeviceLoading(null);
          setKeepOpenedDevices([...keepOpenedDevices, device]);
        }

        sendAmplitudeData('keep the door', {
          eventId: event?.eventId,
          eventCode: event?.eventType,
          partner: event?.partner?.name,
          condominium: event?.condominium?.name,
        });

        break;
      case DEVICE_ACTIONS.keep_opened_cleaning:
        result = await keepDeviceOpenedMutation({
          variables: {
            deviceHandler: {
              ...variables.deviceHandler,
              keepOpenedType: 2,
            },
          },
        }).then(() =>
          toast.fire({
            title: t('details.commands.feedbacks.keep-open-delayed', {
              min: 5,
            }),
            icon: 'success',
          }),
        );

        if (result && result.data && result.data.keepDeviceOpened) {
          setDeviceLoading(null);
          setKeepOpenedDevices([...keepOpenedDevices, device]);
        }

        sendAmplitudeData('keep the door for cleaning', {
          eventId: event?.eventId,
          eventCode: event?.eventType,
          partner: event?.partner?.name,
          condominium: event?.condominium?.name,
        });

        break;
      case DEVICE_ACTIONS.keep_opened_truck:
        result = await keepDeviceOpenedMutation({
          variables: {
            deviceHandler: {
              ...variables.deviceHandler,
              keepOpenedType: 1,
            },
          },
        }).then(() =>
          toast.fire({
            title: t('details.commands.feedbacks.keep-open-delayed', {
              min: 2,
            }),
            icon: 'success',
          }),
        );

        if (result && result.data && result.data.keepDeviceOpened) {
          setDeviceLoading(null);
          setKeepOpenedDevices([...keepOpenedDevices, device]);
        }

        sendAmplitudeData('keep the door for truck', {
          eventId: event?.eventId,
          eventCode: event?.eventType,
          partner: event?.partner?.name,
          condominium: event?.condominium?.name,
        });

        break;

      default:
        result = await closeDeviceMutation({
          variables,
        });

        if (result && result.data && result.data.closeDevice) {
          setDeviceLoading(null);
          setOpenedDevices(openedDevices.filter(i => i.id !== device.id));
          setKeepOpenedDevices(
            keepOpenedDevices.filter(i => i.id !== device.id),
          );
        }

        sendAmplitudeData('close the door', {
          eventId: event?.eventId,
          eventCode: event?.eventType,
          partner: event?.partner?.name,
          condominium: event?.condominium?.name,
        });
        break;
    }
  };

  const isDeviceLoading = id => id === deviceLoading;

  return {
    cameras,
    mainCamera,
    places,
    devices,
    interlocks,
    selectedPlace,
    isDeviceOpened,
    isDeviceKeepOpened,
    handleDevice,
    setSelectedPlace,
    isDeviceLoading,
  };
};

export default useDevices;
