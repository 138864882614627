import { forwardRef } from 'react';
import propTypes from 'prop-types';
import { Flex, Text } from '@kiper/ui';
import { Td, Tr } from './styles';

const UnityDweller = forwardRef(({ dweller, handleSelect }, ref) => {
  return (
    <Tr ref={ref} onClick={() => handleSelect(dweller)}>
      <Td>
        <Flex flexDirection="column" gridGap="4px">
          <Text>{dweller?.unity?.description}</Text>
          <Text fontSize="12px" color="secondary400">
            {dweller?.unity?.unityGroup?.description}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Text title={dweller?.name}>{dweller?.name}</Text>
      </Td>
    </Tr>
  );
});

UnityDweller.displayName = 'UnityDweller';

export default UnityDweller;

UnityDweller.propTypes = {
  dweller: propTypes.object.isRequired,
  handleSelect: propTypes.func.isRequired,
};
