import { useContext } from 'react';
import propTypes from 'prop-types';
import { PlusCircle } from 'react-feather';
import { ThemeContext } from 'styled-components';
import { AddMore as AddMoreContainer, Text } from './styles';

const AddMore = ({ text, size, ...props }) => {
  const theme = useContext(ThemeContext);

  return (
    <AddMoreContainer {...props}>
      <PlusCircle
        color={theme.colors.primary500}
        fill={theme.colors.highWhite}
        size={size || 42}
      />
      <Text>{text}</Text>
    </AddMoreContainer>
  );
};

AddMore.propTypes = {
  text: propTypes.string.isRequired,
  size: propTypes.number,
};

AddMore.defaultProps = {
  size: null,
};

export default AddMore;
