import { Popover as ReactStrapPopover } from 'reactstrap';

import ErrorBoundary from '../ErrorBoundary';

import { Popover as StyledPopover } from './styles';

export { PopoverHeader, PopoverBody, PopoverFooter } from './styles';

export const Popover = ({ isOpen, toggle, ...props }) => (
  <ErrorBoundary
    onError={err => {
      console.log(err);
      toggle(false);
    }}
  >
    <StyledPopover
      isOpen={isOpen}
      toggle={toggle}
      placement="left"
      hideArrow
      trigger="legacy"
      innerClassName="customInner"
      popperClassName="customPopper"
      fade={false}
      {...props}
    />
  </ErrorBoundary>
);
Popover.propTypes = ReactStrapPopover.propTypes;
