import { useState } from 'react';
import Proptypes from 'prop-types';

import { Container, Tooltip } from './styles';

const defineColorPointFinger = finger => {
  return finger ? `#449D44` : 'gray';
};

const LeftHandFingers = ({
  handleClickFinger,
  readingFinger,
  fingerEffect,
  fingersRegistered,
  t,
}) => {
  const [fingerOver, setFingerOver] = useState(undefined);

  const mIn = fingerIndex => () => {
    setFingerOver(fingerIndex);
  };

  const mOut = () => {
    setFingerOver(undefined);
  };

  return (
    <Container>
      {fingerOver !== undefined &&
        (fingersRegistered[fingerOver] ? (
          <Tooltip remove>{t(`remove_finger`)}</Tooltip>
        ) : (
          <Tooltip>{t(`put.devices.modal.instructions.add-finger`)}</Tooltip>
        ))}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="156.032"
        height="234.048"
        viewBox="0 0 156.032 234.048"
      >
        <g>
          {readingFinger && (
            <rect
              fill="red"
              className="bar"
              opacity="0.5"
              x="0"
              y="0%"
              width="156.032"
              height="3"
            />
          )}
        </g>
        <g className={readingFinger ? `reading` : ``}>
          <path
            className="highlight_path"
            d="M104.837,39.008a19.346,19.346,0,0,1,9.752,2.623V39.008a19.49,19.49,0,0,1,29.256-16.881V19.5a19.5,19.5,0,0,1,39.008,0v2.623a19.49,19.49,0,0,1,29.256,16.881V92.654a24.269,24.269,0,0,1,14.628-4.886A14.646,14.646,0,0,1,241.365,102.4v43.991a86.446,86.446,0,0,1-25.482,61.525l-16.13,16.14a34.389,34.389,0,0,1-24.136,10H138.969a53.7,53.7,0,0,1-53.636-53.636V58.512A19.523,19.523,0,0,1,104.837,39.008Zm-9.752,141.4A43.938,43.938,0,0,0,138.969,224.3h36.648a24.531,24.531,0,0,0,17.241-7.148l16.13-16.13a76.776,76.776,0,0,0,22.625-54.63V102.4a4.884,4.884,0,0,0-4.876-4.876,14.646,14.646,0,0,0-14.628,14.628V141.4a4.876,4.876,0,0,1-9.752,0V39.008a9.752,9.752,0,1,0-19.5,0V102.4a4.876,4.876,0,0,1-9.752,0V19.5a9.752,9.752,0,0,0-19.5,0V102.4a4.876,4.876,0,0,1-9.752,0V39.008a9.752,9.752,0,0,0-19.5,0V102.4a4.876,4.876,0,0,1-9.752,0V58.512a9.752,9.752,0,0,0-19.5,0Z"
            transform="translate(-85.333)"
          />
        </g>
        <g>
          <g
            onClick={handleClickFinger(0)}
            onMouseEnter={mIn(0)}
            onMouseLeave={mOut}
          >
            <circle
              className={fingerEffect[0]}
              cy="58.47709"
              cx="19.01602"
              r="10px"
            />
            <ellipse
              ry="5.5"
              rx="5.5"
              cy="58.47709"
              cx="19.01602"
              strokeWidth="0"
              fill={defineColorPointFinger(fingersRegistered[0])}
            />
            <path
              className="finger click"
              d="m15.89103,49.24998c0,0 -2.25,1.25 -2.39104,1.24999c0.14104,0.00001 -3.10896,3.75001 -3.25,3.75c0.14104,0.00001 -0.35896,4.25 -0.5,4.25c0.14104,0 0.14104,6.75 0,6.75c0.14104,0 -0.10896,40.24998 -0.24999,40.24998c0.14103,0 19.89102,-1 19.74999,-1c0.14103,0 -0.35897,-5.5 -0.5,-5.5c0.14103,0 0.39103,-41.99998 0.39103,-41.99998c0,0 -1,-3.24999 -1,-3.24999c0,0 -2,-2 -2.14103,-2.00001c0.14103,0.00001 -2.35897,-1.99999 -2.35897,-1.99999c0,0 -3,-1 -3.14103,-1c0.14103,0 -2.60896,0 -2.75,0c0.14104,0 -1.85896,0.5 -1.85896,0.5z"
              fillOpacity="0"
              strokeWidth="0"
              fill="#050505"
            />
          </g>

          <g
            onClick={handleClickFinger(1)}
            onMouseEnter={mIn(1)}
            onMouseLeave={mOut}
          >
            <circle
              className={fingerEffect[1]}
              cy="38.7271"
              cx="48.516"
              r="10px"
            />
            <ellipse
              ry="5.5"
              rx="5.5"
              cy="38.7271"
              cx="48.516"
              strokeWidth="0"
              fill={defineColorPointFinger(fingersRegistered[1])}
            />
            <path
              className="finger click"
              d="m58.45396,41.37402c0,0 -0.125,-2.74993 -0.20535,-2.74994c0.08036,0 -0.16964,-2.49994 -0.24999,-2.49994c0.08036,0 -0.79462,-1.74996 -0.87498,-1.74996c0.08036,0 -0.79462,-1.24997 -0.87498,-1.24997c0.08036,0 -1.54461,-2.12495 -1.62496,-2.12495c0.08036,0 -2.04459,-0.99998 -2.12495,-0.99998c0.08036,0 -3.16957,-0.62498 -3.24992,-0.62499c0.08036,0 -2.91957,0.25 -2.79458,0.25c0.125,0 -1.87496,0.87498 -1.95531,0.87498c0.08036,0 -2.04459,1.24997 -2.12495,1.24997c0.08035,0 -1.41961,1.37497 -1.49996,1.37497c0.08035,0 -0.91962,1.74996 -0.99998,1.74996c0.08035,0 -0.41963,1.99995 -0.49999,1.99995c0.08035,0 -0.16964,2.12495 -0.24999,2.12495c0.08035,0 -0.04464,1.74996 -0.125,1.74996c0.08035,0 -0.04464,61.74853 -0.125,61.74853c0.08035,0 19.70489,0 19.70489,0"
              opacity="0.5"
              fillOpacity="0"
              strokeWidth="0"
              fill="#070707"
            />
          </g>

          <g
            onClick={handleClickFinger(2)}
            onMouseEnter={mIn(2)}
            onMouseLeave={mOut}
          >
            <circle
              className={fingerEffect[2]}
              cy="19.47711"
              cx="77.51599"
              r="10px"
            />
            <ellipse
              ry="5.5"
              rx="5.5"
              id="svg_19"
              cy="19.47711"
              cx="77.51599"
              strokeWidth="0"
              fill={defineColorPointFinger(fingersRegistered[2])}
            />
            <path
              className="finger click"
              d="m88.07825,24.80583c0,0 -0.12499,-3.45035 -0.20535,-3.45035c0.08036,0 -0.16964,-3.13668 -0.24999,-3.13668c0.08035,0 -0.79463,-2.19568 -0.87498,-2.19568c0.08035,0 -0.79463,-1.56834 -0.87498,-1.56834c0.08035,0 -1.54461,-2.66618 -1.62496,-2.66618c0.08035,0 -2.0446,-1.25466 -2.12495,-1.25466c0.08035,0 -3.16957,-0.78418 -3.24992,-0.78418c0.08035,0 -2.91958,0.31368 -2.79458,0.31368c0.125,0 -1.87496,1.09783 -1.95531,1.09783c0.08035,0 -2.04459,1.56834 -2.12495,1.56834c0.08036,0 -1.41961,1.72518 -1.49996,1.72518c0.08035,0 -0.91963,2.19568 -0.99998,2.19568c0.08035,0 -0.41963,2.50934 -0.49999,2.50934c0.08036,0 -0.16964,2.66618 -0.24999,2.66618c0.08035,0 -0.04464,2.19568 -0.125,2.19568c0.08036,0 -0.04464,77.47594 -0.125,77.47594c0.08036,0 19.70489,0 19.70489,0"
              opacity="0.5"
              fillOpacity="0"
              strokeWidth="0"
              fill="#000000"
            />
            <path
              className="click"
              d="m72.34559,11.2c0,0 -3.08663,3.74999 -3.09562,3.74999c0.00899,0 0.00899,13.8 0,13.8c0.00899,0 17.26602,-0.525 17.26602,-0.525c0,0 -2.24482,-17.47499 -2.24482,-17.47499c0,0 -5.89264,-0.75 -5.90164,-0.75"
              opacity="0.5"
              fillOpacity="0"
              strokeWidth="0"
              fill="#757070"
            />
          </g>

          <g
            onClick={handleClickFinger(3)}
            onMouseEnter={mIn(3)}
            onMouseLeave={mOut}
          >
            <ellipse
              ry="5.5"
              rx="5.5"
              cy="38.4771"
              cx="107.76598"
              strokeWidth="0"
              fill={defineColorPointFinger(fingersRegistered[3])}
            />
            <circle
              className={fingerEffect[3]}
              cy="38.4771"
              cx="107.76598"
              r="10px"
            />

            <path
              className="finger click"
              d="m116.95257,40.74903c0,0 -0.12499,-2.74994 -0.20535,-2.74994c0.08036,0 -0.16964,-2.49994 -0.24999,-2.49994c0.08035,0 -0.79463,-1.74996 -0.87498,-1.74996c0.08035,0 -0.79463,-1.24997 -0.87498,-1.24997c0.08035,0 -1.54461,-2.12495 -1.62496,-2.12495c0.08035,0 -2.0446,-0.99997 -2.12495,-0.99997c0.08035,0 -3.16957,-0.62499 -3.24992,-0.62499c0.08035,0 -2.91958,0.25 -2.79458,0.25c0.125,0 -1.87496,0.87497 -1.95531,0.87497c0.08035,0 -2.04459,1.24997 -2.12495,1.24997c0.08036,0 -1.41961,1.37497 -1.49996,1.37497c0.08035,0 -0.91963,1.74996 -0.99998,1.74996c0.08035,0 -0.41963,1.99995 -0.49999,1.99995c0.08036,0 -0.16964,2.12495 -0.24999,2.12495c0.08035,0 -0.04464,1.74996 -0.125,1.74996c0.08036,0 -0.04464,61.74853 -0.125,61.74853c0.08036,0 19.70489,0 19.70489,0"
              opacity="0.5"
              fillOpacity="0"
              strokeWidth="0"
              fill="#000000"
            />
            <path
              className="click"
              d="m102.59558,30.19999c0,0 -3.08663,3.74999 -3.09562,3.74999c0.00899,0 0.00899,13.8 0,13.8c0.00899,0 17.26602,-0.525 17.26602,-0.525c0,0 -2.24482,-17.47499 -2.24482,-17.47499c0,0 -5.89264,-0.75 -5.90164,-0.75"
              opacity="0.5"
              fillOpacity="0"
              strokeWidth="0"
              fill="#757070"
            />
          </g>

          <g
            onClick={handleClickFinger(4)}
            onMouseEnter={mIn(4)}
            onMouseLeave={mOut}
          >
            <ellipse
              ry="5.5"
              rx="5.5"
              id="svg_23"
              cy="106.97707"
              cx="137.01596"
              strokeWidth="0"
              fill={defineColorPointFinger(fingersRegistered[4])}
            />
            <circle
              className={fingerEffect[4]}
              cy="106.97707"
              cx="137.01596"
              r="10px"
            />
            <path
              className="finger click"
              d="m146.32687,103.87253c0,0 -0.24999,-2.99993 -0.24999,-2.99993c0,0 -0.87498,-2.12495 -0.87498,-2.12495c0,0 -2.49994,-1.37497 -2.49994,-1.37497c0,0 -2.87493,-0.24999 -2.95529,-0.25c0.08036,0 -1.7946,0.37499 -1.87496,0.37499c0.08036,0 -2.54458,0.99998 -2.62494,0.99998c0.08036,0 -2.41958,1.37497 -2.49994,1.37497c0.08036,0 -2.04459,2.37495 -2.12495,2.37494c0.08036,0 -2.16959,2.74994 -2.24995,2.74993c0.08036,0 -1.41961,3.37492 -1.49996,3.37492c0.08036,0 -0.29463,4.2499 -0.37499,4.2499c0.08036,0 0.45535,29.24931 0.45535,29.24931c0,0 19.62453,0 19.54417,-0.00001"
              opacity="0.5"
              fillOpacity="0"
              strokeWidth="0"
              fill="#050505"
            />
            <path
              d="m131.84557,98.69996c0,0 -3.08663,3.74999 -3.09562,3.74999c0.00899,0 0.00899,13.8 0,13.8c0.00899,0 17.26602,-0.525 17.26602,-0.525c0,0 -2.24482,-17.47499 -2.24482,-17.47499c0,0 -5.89264,-0.75 -5.90164,-0.75"
              opacity="0.5"
              fillOpacity="0"
              strokeWidth="0"
              fill="#757070"
            />
          </g>
        </g>
      </svg>
    </Container>
  );
};

LeftHandFingers.propTypes = {
  handleClickFinger: Proptypes.func.isRequired,
  readingFinger: Proptypes.bool.isRequired,
  fingerEffect: Proptypes.arrayOf(Proptypes.string).isRequired,
  fingersRegistered: Proptypes.arrayOf(Proptypes.shape({})).isRequired,
  t: Proptypes.func.isRequired,
};

export default LeftHandFingers;
