import { useCallback, useEffect, useRef, useState } from 'react';

const useMultipleToggles = initialStates => {
  const [states, setStates] = useState(initialStates);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const toggleState = useCallback(key => {
    if (isMounted.current) {
      setStates(prevStates => ({
        ...prevStates,
        [key]: !prevStates[key],
      }));
    }
  }, []);

  return [states, toggleState];
};

export default useMultipleToggles;
