import { useMemo } from 'react';
import propTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { Select } from '@kiper/ui';
import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { queries } from '@kiper/monitoring-graphql/condominium/detail';

export default function CondominiumRuleCategories({
  value,
  categories,
  ...props
}) {
  const { toast } = useSwal();

  const { data, loading } = useQuery(queries.condominiumRulesCategories, {
    skip: !!categories.length,
    fetchPolicy: 'cache-first',
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const options = useMemo(
    () => categories || data?.condominiumRulesCategories,
    [categories, data],
  );

  const getValue = () => {
    return value && options?.length
      ? options?.find(x => +x.id === +value.id)
      : value;
  };

  return (
    <Select
      options={options}
      value={getValue()}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      isLoading={loading}
      {...props}
    />
  );
}

CondominiumRuleCategories.propTypes = {
  value: propTypes.any,
  categories: propTypes.array,
};

CondominiumRuleCategories.defaultProps = {
  value: null,
  categories: [],
};
