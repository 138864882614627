import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

const YesOrNoQuestionType = ({ question, answer }) => {
  const { t } = useTranslation('guided-attendance');

  return (
    <S.YesOrNoContainer>
      <S.Text>{question}</S.Text>
      <S.Badge color={answer.yesNoAnswer ? 'primary100' : 'danger100'}>
        <S.Text color={answer.yesNoAnswer ? 'primary900' : 'danger900'}>
          {answer.yesNoAnswer ? t('quick-menu.yes') : t('quick-menu.no')}
        </S.Text>
      </S.Badge>
    </S.YesOrNoContainer>
  );
};

export default YesOrNoQuestionType;

YesOrNoQuestionType.propTypes = {
  question: propTypes.string.isRequired,
  answer: propTypes.shape({
    yesNoAnswer: propTypes.bool.isRequired,
  }).isRequired,
};
