import propTypes from 'prop-types';
import { createFilter } from 'react-select';
import { ErrorMessage, Select } from '@kiper/ui';
import { useMemo } from 'react';
import NoDwellersMessage from './NoDwellerMessage';

const DwellerSelect = ({
  dwellers,
  loading,
  handleSelectDweller,
  value,
  touch,
  error,
  fieldsValidated,
  searchText,
  t,
}) => {
  const filterOptions = createFilter({
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
  });

  const noDwellersOption = useMemo(() => !loading && !dwellers?.length, [
    loading,
    dwellers,
  ]);

  return (
    <>
      {noDwellersOption ? (
        <NoDwellersMessage t={t} />
      ) : (
        <>
          <Select
            options={dwellers}
            isLoading={loading}
            name="stepDwellerValidation.dweller"
            placeholder={t('step-dweller-validation.placeholder')}
            value={value('dweller')}
            width="100%"
            onChange={handleSelectDweller}
            delay={500}
            inputValue={!value('dweller') ? searchText : ''}
            invalid={!!touch('dweller') && !!error('dweller')}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            loadingMessage={() => t('common:loading')}
            isClearable={!fieldsValidated?.length}
            autoFocus={!value('dweller')}
            openMenuOnFocus={!value('dweller')}
            filterOption={filterOptions}
            noOptionsMessage={({ inputValue }) =>
              inputValue
                ? t(`step-dweller-validation.feedback.error.no-dweller-found`, {
                    search: inputValue,
                  })
                : null
            }
          />
          {!!touch('dweller') && !!error('dweller') && (
            <ErrorMessage>
              {t(
                'step-dweller-validation.feedback.error.necessary-select-dweller',
              )}
            </ErrorMessage>
          )}
        </>
      )}
    </>
  );
};

export default DwellerSelect;

DwellerSelect.propTypes = {
  dwellers: propTypes.array,
  loading: propTypes.bool.isRequired,
  handleSelectDweller: propTypes.func.isRequired,
  value: propTypes.func.isRequired,
  touch: propTypes.func.isRequired,
  error: propTypes.func.isRequired,
  fieldsValidated: propTypes.array.isRequired,
  searchText: propTypes.string.isRequired,
  t: propTypes.func.isRequired,
};

DwellerSelect.defaultProps = {
  dwellers: [],
};
