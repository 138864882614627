import { useMemo } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@kiper/ui';
import { FiNavigation } from 'react-icons/fi';
import { getUserLanguageCode } from '@kiper/fns';

import { Link } from 'react-router-dom';
import {
  Tr,
  Td,
  DataContainer,
  ColumnData,
  Badge,
  ProfileData,
  Button,
  Container,
  ContentText,
} from './styles';
import {
  eventTypeDevicesReport,
  profileColor,
  profileTypes,
} from '../../../../constants';

export const UnityGroupChildren = ({
  primaryData,
  secondaryData,
  additionalInformation,
}) => {
  const [t] = useTranslation('common');
  const {
    dtmfUnityName,
    dtmfUnityGroupName,
    dtmfOrigin,
    dtmfDestination,
  } = useMemo(() => {
    const additional = JSON?.parse(additionalInformation);

    return {
      dtmfUnityName: additional?.dtmf?.unityName?.toString(),
      dtmfUnityGroupName:
        additional?.dtmf?.unityGroupName?.toString() ||
        additional?.unityNumber?.toString(),
      dtmfOrigin: additional?.dtmf?.origin?.toString(),
      dtmfDestination: additional?.dtmf?.destination?.toString(),
    };
  }, [additionalInformation]);

  return (
    <>
      {(dtmfOrigin || dtmfDestination) && (
        <DataContainer>
          <ColumnData>
            {dtmfOrigin ? `${t('origin')} ${dtmfOrigin}` : primaryData}
          </ColumnData>
          <ColumnData>
            {dtmfDestination
              ? `${t('destination')} ${dtmfDestination}`
              : secondaryData}
          </ColumnData>
        </DataContainer>
      )}
      <DataContainer>
        <ColumnData>{dtmfUnityGroupName ?? primaryData}</ColumnData>
        <ColumnData>{dtmfUnityName ?? secondaryData}</ColumnData>
      </DataContainer>
    </>
  );
};

UnityGroupChildren.propTypes = {
  additionalInformation: propTypes.string,
  primaryData: propTypes.string,
  secondaryData: propTypes.string,
};

UnityGroupChildren.defaultProps = {
  additionalInformation: null,
  primaryData: null,
  secondaryData: null,
};

export const OwnerChildren = ({
  primaryData,
  secondaryData,
  additionalInformation,
  eventTypeValue,
  ...rest
}) => {
  const [t] = useTranslation('common');

  const {
    tagNotFound,
    rfNotFound,
    zonePartition,
    userPartition,
    argumentTypeNotMapped,
    attendant,
    personId,
    inviteId,
    camName,
    additionalDeviceName,
  } = useMemo(() => {
    const additional = JSON?.parse(additionalInformation);
    const contactId = additional?.contactId;
    const device = additional?.device;

    const isZone = contactId?.argumentType === 'ZONE';
    const isUser =
      contactId?.argumentType && contactId?.argumentType !== 'ZONE';
    const notMapped = contactId && !contactId?.argumentType;

    const argument =
      contactId?.argument?.toString() || contactId?.argumentId?.toString();
    const partition =
      contactId?.partition?.toString() || contactId?.partitionId?.toString();

    const attendantType = additional?.attendant || null;

    return {
      tagNotFound: additional?.triggeredBy?.tagNotFound?.toString(),
      rfNotFound: additional?.triggeredBy?.rfNotFound?.toString(),
      zonePartition:
        isZone &&
        t('zone-partition', {
          zone: argument,
          partition,
        }),
      userPartition:
        isUser &&
        t('user-partition', {
          user: argument,
          partition,
        }),
      argumentTypeNotMapped:
        notMapped &&
        t('argument-not-mapped-partition', {
          argumentId: argument,
          partition,
        }),
      attendant: attendantType,
      personId: additional?.personId,
      inviteId: additional?.inviteId,
      camName: additional?.camera?.name || additional?.camera?.camName,
      additionalDeviceName: device?.deviceName,
    };
  }, [additionalInformation]);

  const deviceName =
    eventTypeDevicesReport?.includes(rest.eventTypeValue) &&
    rest?.deviceName ? (
      <ColumnData>{rest?.deviceName}</ColumnData>
    ) : null;

  if (
    !rest?.personName &&
    attendant?.name &&
    [370, 371, 372].includes(eventTypeValue)
  ) {
    return (
      <DataContainer>
        {attendant.name}
        <Badge color="blue900">
          {!!attendant?.profile &&
            t(`profiles.${profileTypes[attendant?.profile.fieldName]}`)}
        </Badge>
      </DataContainer>
    );
  }

  if (tagNotFound || rfNotFound) {
    return (
      <DataContainer>
        {deviceName}
        <ColumnData>
          {t('feedback.not-found', {
            device: tagNotFound ? 'Tag' : 'RF',
          })}
        </ColumnData>
        <ContentText>{`ID ${tagNotFound || rfNotFound}`}</ContentText>
      </DataContainer>
    );
  }

  if (primaryData && secondaryData) {
    return (
      <DataContainer>
        {deviceName}
        {personId || inviteId ? (
          <Link
            to={personId ? `/users/${personId}` : `/invites/${inviteId}`}
            target="_blank"
          >
            <ColumnData link>{primaryData}</ColumnData>
          </Link>
        ) : (
          <ColumnData>{primaryData}</ColumnData>
        )}
        <Badge color={profileColor[secondaryData]}>
          {t(`profiles.${profileTypes[secondaryData]}`)}
        </Badge>
        <ProfileData>
          {t(`profiles.${profileTypes[secondaryData]}`)}
        </ProfileData>
      </DataContainer>
    );
  }

  const eventShottedBy =
    zonePartition || userPartition || camName || argumentTypeNotMapped;

  if (eventShottedBy) {
    return (
      <DataContainer>
        {deviceName}
        <ColumnData>{eventShottedBy}</ColumnData>
      </DataContainer>
    );
  }

  if (deviceName || additionalDeviceName) {
    return (
      <DataContainer>
        <ColumnData>{rest?.deviceName || additionalDeviceName}</ColumnData>
      </DataContainer>
    );
  }

  return <></>;
};

OwnerChildren.propTypes = {
  additionalInformation: propTypes.string,
  eventTypeValue: propTypes.number,
  primaryData: propTypes.string,
  secondaryData: propTypes.string,
};

OwnerChildren.defaultProps = {
  additionalInformation: null,
  eventTypeValue: null,
  primaryData: null,
  secondaryData: null,
};

export const DefaultChildren = ({ primaryData, secondaryData }) => {
  return (
    <DataContainer>
      <ColumnData>{primaryData}</ColumnData>
      <ColumnData>{secondaryData}</ColumnData>
    </DataContainer>
  );
};

DefaultChildren.propTypes = {
  primaryData: propTypes.string,
  secondaryData: propTypes.string,
};

DefaultChildren.defaultProps = {
  primaryData: null,
  secondaryData: null,
};

export const ActionChildren = ({ primaryData, onClick, tooltipMessage }) => (
  <>
    <Button
      className="event-activity-modal"
      size="sm"
      id={`event-activity-${primaryData}`}
      color="primary"
      icon={<FiNavigation />}
      onClick={() => onClick(primaryData)}
    />
    <Tooltip placement="bottom" target={`event-activity-${primaryData}`}>
      {tooltipMessage}
    </Tooltip>
  </>
);

ActionChildren.propTypes = {
  onClick: propTypes.func,
  primaryData: propTypes.string,
  tooltipMessage: propTypes.string,
};

ActionChildren.defaultProps = {
  onClick: null,
  primaryData: null,
  tooltipMessage: null,
};

export const EventChildren = ({ primaryData, secondaryData }) => {
  const extractJsonFromText = input => {
    const startIndex = input.indexOf('{');
    const endIndex = input.lastIndexOf('}');
    if (startIndex !== -1 && endIndex > startIndex) {
      return input.substring(startIndex, endIndex + 1);
    }

    return null;
  };

  const normalizeData = () => {
    const language = getUserLanguageCode();

    const firstPart = primaryData.split(' - ')[0];
    let parsedSecondPart = extractJsonFromText(primaryData);

    try {
      parsedSecondPart = JSON.parse(parsedSecondPart)[language];
    } catch (e) {
      return primaryData;
    }

    return `${firstPart} - ${parsedSecondPart}`;
  };

  return (
    <DataContainer>
      <ColumnData>{normalizeData()}</ColumnData>
      <ColumnData>{secondaryData}</ColumnData>
    </DataContainer>
  );
};

EventChildren.propTypes = {
  primaryData: propTypes.string,
  secondaryData: propTypes.string,
};

EventChildren.defaultProps = {
  primaryData: null,
  secondaryData: null,
};

const EventRow = ({ data, columns, last }) => (
  <Tr last={last}>
    {columns?.map(
      ({
        width,
        children: Children,
        primary,
        secondary,
        onClick,
        tooltipMessage,
        additionalInformation,
      }) => {
        const validData =
          !!data[primary] ||
          !!data[secondary] ||
          !!data[additionalInformation] ||
          !!data.deviceName;

        return (
          <Td width={width} key={`user-report-column-${primary}`}>
            <Container hasContent={validData}>
              {validData && (
                <Children
                  primaryData={data[primary]}
                  secondaryData={data[secondary]}
                  additionalInformation={data[additionalInformation]}
                  onClick={onClick}
                  tooltipMessage={tooltipMessage}
                  {...data}
                />
              )}
            </Container>
          </Td>
        );
      },
    )}
  </Tr>
);

EventRow.propTypes = {
  data: propTypes.any.isRequired,
  columns: propTypes.array.isRequired,
  last: propTypes.bool.isRequired,
};

export default EventRow;
