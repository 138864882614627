import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Badge } from '@kiper/ui';

const FreeAccess = ({ free }) => {
  const [t] = useTranslation('common');

  return (
    <Badge color="primary">
      {free ? t('badges.free-access') : t('badges.single-access')}
    </Badge>
  );
};
export default FreeAccess;

FreeAccess.propTypes = {
  free: propTypes.bool.isRequired,
};
