import { Suspense, useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary, Loader } from '@kiper/ui';
import { useQuery } from 'react-apollo';
import { queries } from '@kiper/monitoring-graphql/report';
import FormByType from './Form';
import ReportResult from './Templates';
import { EmptyState } from './EmptyState';
import { useReportFilters, SCREEN_STATE } from './hooks/useReportFilters';
import { Page, PageTitle, Card, Nav, NavItem, NavLink, Hr } from './styles';

const MESSAGE_ALIAS = {
  usersReport: 'users-report',
  eventsReport: 'events-report',
  vehiclesReport: 'vehicles-report',
};

const Report = () => {
  const [t] = useTranslation('report');
  const location = useLocation();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(
    location.state?.type || 'usersReport',
  );
  const [formProps, setFormProps] = useState(location.state);

  const { data: values, loading: loadingTemplates } = useQuery(
    queries.reportTemplates,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const {
    screenState,
    eventsData,
    usersData,
    vehiclesData,
    loading,
    paginationFilters,
    exportType,
    header,
    fetch,
    handlePageChange,
    handlePageSize,
    toggleFilters,
    exportPdf,
    exportXls,
    exportCsv,
    handleCancelPrint,
    handlePrint,
    setXlsFileName,
    setCsvFileName,
    setCondominiumName,
    setHeaderFilters,
  } = useReportFilters({ activeTab, formProps, t });

  const handleGenerate = data => fetch(data);

  const templates = useMemo(() => {
    return values?.reportTemplates ?? [];
  }, [values]);

  const toggleTab = ({ type, reportId, isCustom, reportFilters }) => {
    if (type !== activeTab) {
      toggleFilters();
      setXlsFileName('');
      setCsvFileName('');
      setActiveTab(type);
      setFormProps({ reportId, type, isCustom, reportFilters });
      setHeaderFilters({});
    }
  };

  useEffect(() => {
    if (!formProps && templates?.length) {
      const { fieldName, id, isCustom, reportFilters } = templates.find(
        a => a.fieldName === activeTab,
      );
      setFormProps({ reportId: id, type: fieldName, isCustom, reportFilters });
    }
  }, [templates]);

  useEffect(() => {
    if (formProps && location.state?.type !== activeTab) {
      history.push({
        pathname: `/reports/${activeTab}`,
        state: formProps,
      });
    }
  }, [activeTab, formProps]);

  const isEmpty = useMemo(
    () => screenState === SCREEN_STATE.EMPTY && !loading && !loadingTemplates,
    [screenState, loading, loadingTemplates],
  );

  return (
    <Page>
      <PageTitle>{t('list.title')}</PageTitle>
      <Hr />

      {loadingTemplates ? (
        <Loader />
      ) : (
        <>
          <Card>
            <Nav tabs>
              {templates.map(current => {
                const {
                  fieldName: type,
                  id: reportId,
                  isCustom,
                  reportFilters,
                } = current;
                const messageAlias = MESSAGE_ALIAS[type];
                return (
                  <NavItem key={reportId}>
                    <NavLink
                      active={type === activeTab}
                      onClick={() =>
                        toggleTab({ type, reportId, isCustom, reportFilters })
                      }
                    >
                      {t(`details.${messageAlias}-title`)}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
            <FormByType
              type={formProps?.type}
              reportFilters={formProps?.reportFilters}
              handleSubmit={handleGenerate}
              setXlsFileName={setXlsFileName}
              setCsvFileName={setCsvFileName}
              loading={loading}
              setCondominiumName={setCondominiumName}
              setHeaderFilters={setHeaderFilters}
            />
          </Card>
        </>
      )}
      {isEmpty && <EmptyState />}
      {screenState !== SCREEN_STATE.EMPTY && (
        <ReportResult
          type={formProps?.type}
          screenState={screenState}
          eventsData={eventsData}
          usersData={usersData}
          vehiclesData={vehiclesData}
          loading={loading}
          exportType={exportType}
          paginationFilters={paginationFilters}
          handlePageChange={handlePageChange}
          handlePageSize={handlePageSize}
          exportPdf={exportPdf}
          exportXls={exportXls}
          exportCsv={exportCsv}
          handleCancelPrint={handleCancelPrint}
          handlePrint={handlePrint}
          header={header}
        />
      )}
    </Page>
  );
};

export default function withErrorBoundary({ ...props }) {
  return (
    <Suspense fallback={<Loader fullHeight />}>
      <ErrorBoundary>
        <Report {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
