import styled, { css } from 'styled-components';
import {
  Toggler as toggler,
  Button as button,
  Badge as badge,
} from '@kiper/ui';
import {
  MdReplay10,
  MdForward10,
  MdPlayCircleFilled,
  MdPauseCircleFilled,
  MdFastRewind,
  MdFastForward,
} from 'react-icons/md';

import { FiMaximize2 } from 'react-icons/fi';

const Flex = styled.div`
  display: flex;
`;

const FlexCol = styled(Flex)`
  flex-direction: column;
`;

export const Wrapper = styled(Flex)`
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export const PlayerWrapper = styled(Flex)`
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  border-radius: ${props =>
    props.$hasVideoPlayerControl ? '8px 8px 0 0' : '8px'};
  align-items: center;
  justify-content: center;
  background-color: ${props =>
    !props.white ? props.theme.colors.gray900 : props.theme.colors.white};

  @media screen and (min-width: 1366px) {
    height: 220px;
  }

  @media screen and (min-width: 1920px) {
    height: 317px;
  }
`;

export const CameraItem = styled(FlexCol)`
  position: relative;
  justify-content: center;
  height: 78px;
  width: 120px;
  background-color: ${props => props.theme.colors.gray900};
  border: 1px solid ${props => props.theme.colors.secondary400};
  cursor: pointer;
  margin-right: 3px;
  border-radius: 8px;
`;

export const Img = styled.img`
  width: 100%;
  border-radius: 8px;
`;

export const ImgContainer = styled(Flex)`
  position: relative;
  background-color: ${props => props.theme.colors.gray900};
  border-radius: ${props =>
    props.$hasVideoPlayerControl ? '8px 8px 0 0' : '8px'};

  ${props =>
    !props.$hasVideoPlayerControl &&
    css`
      &:before {
        ${!props.loading &&
          ` content: "${props.live}";
    `}
        text-transform: uppercase;
        color: ${props.theme.colors.white};
        background: ${props.theme.colors.danger500};

        border-radius: 8px;
        padding: 4px 8px;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0;

        align-items: center;
        z-index: 1;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    `}
`;

export const VideoImg = styled(Img)`
  margin: 0 auto;
  border-radius: ${props =>
    props.$hasVideoPlayerControl ? '8px 8px 0 0' : '8px'};
  background: black;
  display: ${props => (props.isloading ? 'none' : 'block')};

  @media screen and (min-width: 1366px) {
    height: 220px;
  }

  @media screen and (min-width: 1920px) {
    height: 317px;
  }
`;

export const ImageNotFound = styled.div`
  color: ${props => props.theme.colors.secondary50};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 78px;
`;

export const VideoNotFound = styled(SpinnerContainer)`
  flex-direction: column;
`;

export const CameraListContainer = styled.div`
  display: flex;
  flex: 1;
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  height: max-content;
`;

export const CameraListThumbContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PlayerContainer = styled.div`
  height: 40px;
  background-color: ${props => props.theme.colors.gray900};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const Toggler = styled(toggler)``;

export const Button = styled(button)`
  width: fit-content;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ReplayIcon = styled(MdReplay10)`
  cursor: pointer;
`;

export const ForwardIcon = styled(MdForward10)`
  cursor: pointer;
`;

export const PlayIcon = styled(MdPlayCircleFilled)`
  cursor: pointer;
`;

export const PauseIcon = styled(MdPauseCircleFilled)`
  cursor: pointer;
`;

export const FastRewindIcon = styled(MdFastRewind)`
  cursor: pointer;
`;

export const FastForwardIcon = styled(MdFastForward)`
  cursor: pointer;
`;

export const Badge = styled(badge)`
  text-transform: uppercase;
  color: black;
  background-color: white;
`;

export const ExpandIcon = styled(FiMaximize2)`
  position: absolute;
`;

export const ThumbContainer = styled.div`
  position: relative;
`;

export const ImageOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  border-radius: 8px;
  transition: 0.5s ease;
  background-color: ${props => props.theme.colors.lowBlack};
  :hover {
    opacity: 1;
  }
`;
