import { useState } from 'react';
import propTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PopoverBody } from 'reactstrap';
import { FiGrid } from 'react-icons/fi';
import { Flex, Box, KiperButton } from '@kiper/ui';
import { useOutsideClick } from '@kiper/hooks';
import * as S from './styles';

const ProductHub = ({ applicationName, personApplications }) => {
  const [openDropDown, setOpenDropDown] = useState(false);

  const { t } = useTranslation('menu');

  const history = useHistory();

  const toggle = () => setOpenDropDown(!openDropDown);

  const handleRedirect = application => {
    if (application.name === applicationName) {
      history.push('./');
    } else {
      window.open(application.mainUrl, '_blank');
    }
  };

  const mainBoxRef = useOutsideClick(() => setOpenDropDown(false));

  return (
    <Box ref={mainBoxRef}>
      <KiperButton
        color="white"
        variant="text"
        icon={<FiGrid />}
        id="hubPopover"
        type="button"
      />
      <S.PopoverStyled
        placement="bottom"
        isOpen={openDropDown}
        target="hubPopover"
        toggle={toggle}
      >
        <PopoverBody>
          <Flex minWidth="250px" flexDirection="column">
            <S.Title>{t('change-to')}</S.Title>
            {personApplications.map(application => (
              <S.Flex
                key={application.id}
                onClick={() => handleRedirect(application)}
                flex={1}
                type="button"
              >
                <S.Icon
                  src={application.icon}
                  name={application.name}
                  size={24}
                  round="100%"
                />
                <S.IconText>{application.name}</S.IconText>
              </S.Flex>
            ))}
          </Flex>
        </PopoverBody>
      </S.PopoverStyled>
    </Box>
  );
};

ProductHub.propTypes = {
  applicationName: propTypes.string.isRequired,
  personApplications: propTypes.array,
};

ProductHub.defaultProps = {
  personApplications: [],
};

export default ProductHub;
