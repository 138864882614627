import { useContext } from 'react';
import propTypes from 'prop-types';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { Item, ItemWrapper } from './styles';

const Category = ({ label, onClick, itemId, selected }) => {
  const visibility = useContext(VisibilityContext);

  return (
    <ItemWrapper>
      <Item
        onClick={() => onClick(visibility)}
        itemId={itemId}
        tabIndex={0}
        selected={selected}
      >
        {label}
      </Item>
    </ItemWrapper>
  );
};

export default Category;

Category.propTypes = {
  label: propTypes.string.isRequired,
  onClick: propTypes.func.isRequired,
  itemId: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired,
  selected: propTypes.bool.isRequired,
};
