import eventPR from './event.pt.json';
import eventEN from './event.en.json';
import eventES from './event.es.json';

const resources = {
  en: eventEN,
  es: eventES,
  pt: eventPR,
};

export default resources;
