import { Flex } from '@kiper/ui';
import styled, { css } from 'styled-components';

export const SideMenu = styled(Flex)`
  ${props =>
    props.loading &&
    css`
      border-left: 1px solid ${props.theme.colors.secondary300};
    `}

  width: 334px;

  @media screen and (min-width: 1920px) {
    width: 500px;
    max-width: 500px;
  }
`;

export const DynamicPaneSizeContainer = styled.div`
  width: 100%;

  @media screen and (min-width: 1980px) {
    height: 300px;
  }
  @media screen and (max-width: 1920px) {
    height: 300px;
  }
  @media screen and (max-width: 1600px) {
    height: 250px;
  }
`;
