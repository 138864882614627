import propTypes from 'prop-types';
import { FaQrcode, FaWalking, FaCar, FaFingerprint } from 'react-icons/fa';
import { TbFaceId } from 'react-icons/tb';

import { Tooltip } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import { JustifyContent } from './styles';

const AccessTypes = ({
  hasFingerprint,
  hasRf,
  hasTag,
  hasQrCode,
  hasFaceId,
}) => {
  const [t] = useTranslation('user');

  return (
    <JustifyContent>
      {hasFingerprint && (
        <>
          <FaFingerprint size="20" id="user-fingerprint-icon" />
          <Tooltip placement="top" target="user-fingerprint-icon">
            {t('list.access-types.fingerPrint')}
          </Tooltip>
        </>
      )}
      {hasRf && (
        <>
          <FaCar size="20" id="user-rf-icon" />
          <Tooltip placement="top" target="user-rf-icon">
            {t('list.access-types.rf')}
          </Tooltip>
        </>
      )}
      {hasTag && (
        <>
          <FaWalking size="20" id="user-tag-icon" />
          <Tooltip placement="top" target="user-tag-icon">
            {t('list.access-types.tag')}
          </Tooltip>
        </>
      )}
      {hasQrCode && (
        <>
          <FaQrcode size="20" id="user-qrcode-icon" />
          <Tooltip placement="top" target="user-qrcode-icon">
            {t('list.access-types.qrCode')}
          </Tooltip>
        </>
      )}
      {hasFaceId && (
        <>
          <TbFaceId size="20" id="user-face-icon" />
          <Tooltip placement="top" target="user-face-icon">
            {t('list.access-types.faceId')}
          </Tooltip>
        </>
      )}
    </JustifyContent>
  );
};

export default AccessTypes;

AccessTypes.propTypes = {
  hasFingerprint: propTypes.bool,
  hasRf: propTypes.bool,
  hasTag: propTypes.bool,
  hasQrCode: propTypes.bool,
  hasFaceId: propTypes.bool,
};

AccessTypes.defaultProps = {
  hasFingerprint: false,
  hasRf: false,
  hasTag: false,
  hasQrCode: false,
  hasFaceId: false,
};
