import { formatDistanceDate, getUserLang, toUpperCamelCase } from '@kiper/fns';
import { Flex, Text } from '@kiper/ui';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MdOutlineCalendarToday } from 'react-icons/md';
import { ActionButtons } from './components';
import * as S from './styles';

function GuidedAttendanceDetailHeader({ event }) {
  const { t } = useTranslation('guided-attendance');
  const lang = getUserLang().split('-')[0];

  const getPartnerAndCondoName = () => {
    const partnerName = event?.partner?.name;
    let name = event?.condominium?.name;

    if (event?.coPartner && partnerName) {
      name = `${partnerName} - ${name}`;
    }

    return name;
  };

  return (
    <S.Header>
      <Flex flexDirection="column" gridGap="4px">
        <Text fontSize="16px" fontWeight="700" color="highBlack">
          {getPartnerAndCondoName()}
        </Text>
        <Flex gridGap="8px">
          <Text fontSize="12px" fontWeight="400" color="mediumBlack">
            {toUpperCamelCase(`${event?.eventTypeDescription[lang]}`)}
          </Text>
          <Flex gridGap="4px">
            <MdOutlineCalendarToday size={16} color="mediumBlack" />
            <Text fontSize="12px" fontWeight="400" color="mediumBlack">
              {formatDistanceDate(event?.eventDate, t)}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <ActionButtons event={event} t={t} />
    </S.Header>
  );
}

export default GuidedAttendanceDetailHeader;

GuidedAttendanceDetailHeader.propTypes = {
  event: propTypes.object,
};

GuidedAttendanceDetailHeader.defaultProps = {
  event: null,
};
