import propTypes from 'prop-types';

import { Flex, Badge, UserAvatar } from '@kiper/ui/src';

import { AvatarContainer, Label } from './styles';

function UserInfoVertical({ email, name, id, profileName, profileColor }) {
  return (
    <Flex
      pb="30px"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <AvatarContainer>
        <UserAvatar
          src={`//avatar.kiper.com.br/${email}`}
          size={64}
          name={name}
          round="100%"
          id={`profile-avatar-${id}`}
        />
      </AvatarContainer>
      <Label bolder center block>
        {name}
      </Label>
      {profileName && <Badge color={profileColor}>{profileName}</Badge>}
    </Flex>
  );
}

export default UserInfoVertical;

UserInfoVertical.propTypes = {
  id: propTypes.number.isRequired,
  email: propTypes.string,
  name: propTypes.string,
  profileName: propTypes.string,
  profileColor: propTypes.string,
};

UserInfoVertical.defaultProps = {
  name: null,
  email: null,
  profileName: null,
  profileColor: 'primary50',
};
