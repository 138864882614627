import { useContext, useEffect, useLayoutEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { GuidedAttendanceContext } from '../../../../store';
import { dwellerValidationStatus } from '../../../../constants';

const useForm = ({ nextAction, schema }) => {
  const { setContextTree } = useContext(GuidedAttendanceContext);

  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    handleSubmit,
  } = useFormikContext();

  const { touch, error, value } = useMemo(
    () => ({
      touch: prop => touched?.stepDwellerValidation?.[prop],
      error: prop => errors?.stepDwellerValidation?.[prop],
      value: prop => values?.stepDwellerValidation?.[prop],
    }),
    [touched, errors, values],
  );

  useEffect(() => {
    if (
      values?.stepDwellerValidation?.dwellerValidationStatus ===
      dwellerValidationStatus.INVALID
    )
      handleSubmit();
  }, [values?.stepDwellerValidation?.dwellerValidationStatus]);

  useLayoutEffect(() => {
    if (!values?.stepDwellerValidation)
      setFieldValue('stepDwellerValidation', {
        dweller: '',
        dwellerValidationStatus: dwellerValidationStatus.NONE,
      });
    else {
      setFieldValue('stepDwellerValidation', {
        ...values?.stepDwellerValidation,
        dwellerValidationStatus: dwellerValidationStatus.NONE,
      });
    }

    setContextTree({
      formikSchema: schema,
      nextAction: async stepData => {
        const nextStep = await nextAction(stepData);
        return { nextStep, currentStepData: stepData };
      },
    });
  }, []);

  return {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    setContextTree,
    handleSubmit,
    touch,
    error,
    value,
  };
};

export default useForm;
