import { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import LeftHandFingers from './LeftHandFingers';

const initialFingerEffectStyle = new Array(10).fill(`invisible`);

const LeftHand = ({
  deletingFinger,
  fingersRegistered,
  readingFinger,
  handleFingerprint,
  t,
}) => {
  const [fingerEffect, setFingerEffect] = useState(initialFingerEffectStyle);

  useEffect(() => {
    if (!readingFinger && !deletingFinger) {
      setFingerEffect(initialFingerEffectStyle);
    }
  }, [readingFinger, deletingFinger]);

  const handleClickFinger = clickedIndex => () => {
    if (readingFinger) {
      return;
    }
    setFingerEffect(
      fingerEffect.map((v, i) => (i === clickedIndex ? `pulse` : `invisible`)),
    );
    handleFingerprint(clickedIndex);
  };

  return (
    <LeftHandFingers
      fingersRegistered={fingersRegistered}
      handleClickFinger={handleClickFinger}
      readingFinger={readingFinger}
      fingerEffect={fingerEffect}
      t={t}
    />
  );
};

LeftHand.propTypes = {
  deletingFinger: Proptypes.bool.isRequired,
  fingersRegistered: Proptypes.arrayOf(Proptypes.shape({})).isRequired,
  readingFinger: Proptypes.bool.isRequired,
  handleFingerprint: Proptypes.func.isRequired,
  t: Proptypes.func.isRequired,
};

export default LeftHand;
