import { useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { firstBy } from 'thenby';
import { useTheme } from 'styled-components';

import { useTranslation } from 'react-i18next';
import { MdArrowForward, MdOutlineAccessTime } from 'react-icons/md';
import { useToggleData, useToggle, useDebounce } from '@kiper/hooks';
import { getCurrentTime } from '@kiper/fns';

import {
  FiHome,
  FiPhone,
  FiChevronDown,
  FiChevronUp,
  FiSearch,
} from 'react-icons/fi';
import { Box, Flex, KiperButton, Text, Tooltip } from '@kiper/ui';

import {
  profileTypes,
  profileColor,
  destinationTypes,
  contactTypes,
} from '../../../../constants';
import NoKiperVoiceAgentModal from '../../../Modals/NoKiperVoiceAgentModal';
import NoKiperVoiceModal from '../../../Modals/NoKiperVoiceModal';
import AlertOutsideContactTime from '../../../Modals/AlertOutsideContactTime';
import useCurrentLoggedContext from '../../../../hooks/useCurrentLoggedContext';

import UsersHostDetail from './UsersHostsDetail';
import { useRemoteConfig, useVoice } from '../../../../hooks';
import { sendAmplitudeData } from '../../../../services/amplitude';

import { StyledTooltip, Tr } from '../styles';

import { Badge, BadgeAllowAccess, Button } from './styles';
import VideocallStatus from './VideocallStatus';

function UsersHostsRow({
  item,
  event,
  formik,
  selectRef,
  setSourceNode,
  handleSearchInput,
  toggleTab,
  index,
  trRef,
  showVideoCallStatus,
  ...props
}) {
  const [openedRows, toggleOpenedRow] = useToggleData({});
  const { clickToCall } = useVoice();
  const { t } = useTranslation('event_attendance');
  const [allowedAccessTimeMessage, setAllowedAccessTimeMessage] = useState(
    null,
  );
  const [currentDestination, setCurrentDestination] = useState(null);
  const [currentDestinationKind, setCurrentDestinationKind] = useState(null);

  const theme = useTheme();

  const [
    openNoKiperVoiceAgentModal,
    toggleOpenNoKiperVoiceAgentModal,
  ] = useToggle(false);
  const [openNoKiperVoiceModal, toggleOpenNoKiperVoiceModal] = useToggle(false);
  const [loading, setLoading] = useState(false);

  const [
    openAlertOutsideContactTime,
    toggleOpenAlertOutsideContactTime,
  ] = useToggle(false);

  const { loggedContext } = useCurrentLoggedContext();
  const debounce = useDebounce({ delay: 2000 });

  const handleDebounce = option => {
    setLoading(option);
    debounce(() => {
      setLoading(false);
    });
  };

  const showExpectedGuest = useRemoteConfig({
    path: 'attendance.expectedGuest',
    partner: Number(loggedContext?.partner?.personContextId),
    user: Number(loggedContext?.personContextId),
  });

  const hasKiperVoiceAgent = !!loggedContext?.personContextParams?.find(
    x => x.name === 'kiperVoiceAgent' && x.id,
  );

  const hasKiperVoice = loggedContext?.partner?.hasKiperVoice;

  const priority = item?.personContextParamsCallPriority;

  const observation = item?.personContextParamsObservation;

  const { extension, prefixPabx, codeAta } = useMemo(
    () => ({
      extension: item?.unitySerializedParams
        ? JSON.parse(item?.unitySerializedParams)?.extension
        : null,
      prefixPabx: item.unityGroupSerializedParams
        ? JSON.parse(item?.unityGroupSerializedParams)?.prefixPABX
        : null,
      codeAta:
        item.unityGroupSerializedParams &&
        JSON.parse(item?.unityGroupSerializedParams)?.codeAta,
    }),
    [item],
  );

  const intercomNumber = useMemo(
    () => extension && `${prefixPabx ?? ''}${extension}`,
    [extension, prefixPabx],
  );

  const options = [];

  if (intercomNumber) {
    options.push({
      label: t('intercom'),
      phone: intercomNumber,
      type: 'extension',
    });
  }

  if (item?.phone) {
    options.push({
      label: t('phone'),
      phone: item.phone,
      type: 'phone',
    });
  }

  const additionalContacts = useMemo(() => {
    return (
      item?.additionalContacts?.filter(
        contact => contact.contactTypeId === contactTypes.phone,
      ) || []
    );
  }, [item]);

  // eslint-disable-next-line
  const handleClickToCall = callObject => {
    if (!hasKiperVoice) {
      return toggleOpenNoKiperVoiceModal();
    }
    if (hasKiperVoiceAgent) {
      setLoading(true);

      const voiceCallInput = {
        ...callObject,
        destination: String(callObject.destination),
        [!!item?.personContextId && 'personContextId']: item.personContextId,
      };

      if (
        voiceCallInput.destinationKind === destinationTypes?.intercomAta &&
        codeAta
      ) {
        voiceCallInput.codAta = codeAta;
      }

      const feedbackText =
        callObject.destinationKind === destinationTypes.intercomAta
          ? t('making-call-to-extension', {
              number: callObject.destination,
            })
          : t('making-call-to', { number: callObject.destination });

      clickToCall({
        voiceCallInput,
        eventId: event?.eventId,
        feedbackText,
        partner: event?.partner?.name,
        condominium: event?.condominium?.name,
        eventCode: event?.eventType,
      });
    } else {
      toggleOpenNoKiperVoiceAgentModal();
    }
  };

  const handleHoverObservationTooltip = () => {
    sendAmplitudeData('show user observation tooltip', {
      eventId: event?.eventId,
      condominium: event?.condominium?.name,
      user: loggedContext?.personContextId,
      partner: loggedContext?.partner?.name,
    });
  };

  const handleClickExpandUserDetail = () => {
    toggleOpenedRow(item.personContextId);
    if (!openedRows[item.personContextId])
      sendAmplitudeData('expand user detail', {
        eventId: event?.eventId,
        condominium: event?.condominium?.name,
        user: loggedContext?.personContextId,
        partner: loggedContext?.partner?.name,
        accessNotAllowed: !!allowedAccessTimeMessage,
      });
  };

  const personContextParamsContactOrder = JSON.parse(
    item?.personContextParamsContactOrder,
  );

  const personContextParamsAllowedAccesses =
    item?.personContextParamsAllowedAccesses;

  const compareAllowAccessTime = () => {
    const { today } = personContextParamsAllowedAccesses;
    if (today.length) {
      const currentTime = gmt => getCurrentTime(new Date(), gmt);

      const allowAccess = today.some(
        allowedTime =>
          currentTime(allowedTime.gmt) >= allowedTime.startTime &&
          currentTime(allowedTime.gmt) <= allowedTime.endTime,
      );

      if (allowAccess) return null;

      const denyAccessBefore = today
        .sort(firstBy('startTime', 'asc'))
        .find(
          allowedTime => currentTime(allowedTime.gmt) < allowedTime.startTime,
        );

      if (denyAccessBefore)
        return t('user:list.deny-access-before-badge', {
          time: denyAccessBefore.startTime,
        });

      const denyAccessAfter = today
        .sort(firstBy('endTime', 'desc'))
        .find(
          allowedTime => currentTime(allowedTime.gmt) > allowedTime.endTime,
        );

      if (denyAccessAfter)
        return t('user:list.deny-access-after-badge', {
          time: denyAccessAfter.endTime,
        });
    }

    return null;
  };

  useEffect(() => {
    if (personContextParamsAllowedAccesses?.today?.length) {
      setAllowedAccessTimeMessage(compareAllowAccessTime());
    }
  }, [personContextParamsAllowedAccesses?.today]);

  const handleVerifyTime = ({ destination, destinationKind }) => {
    if (item?.isOutsideContactTime) {
      setCurrentDestination(destination);
      setCurrentDestinationKind(destinationKind);
      return toggleOpenAlertOutsideContactTime();
    }
    return handleClickToCall({ destination, destinationKind });
  };

  const handleConfirm = () => {
    toggleOpenAlertOutsideContactTime(false);
    handleClickToCall({
      destination: currentDestination,
      destinationKind: currentDestinationKind,
    });
  };

  const handleCancel = () => {
    toggleOpenAlertOutsideContactTime(false);
  };

  return (
    <>
      <Tr ref={trRef} className="voice-list-user">
        <td align="left">
          <Flex flexDirection="column">
            <Text mb="5px" fontSize="14px">
              {item?.unityName || ''}
            </Text>
            <Text fontSize="12px" color="secondary400">
              {item?.unityGroupName || ''}
            </Text>
          </Flex>
        </td>
        <td align="left">
          <Flex maxWidth="500px" flexDirection="column">
            <Text mb="5px" fontSize="14px">
              {item?.personName}
            </Text>
            <Flex>
              {item?.profileFieldName && (
                <Flex mr="4px">
                  <Badge color={profileColor[item.profileFieldName]}>
                    {t(
                      `common:profiles.${profileTypes[item.profileFieldName]}`,
                    )}
                  </Badge>
                </Flex>
              )}
              {item?.personContextParamsObservation && (
                <Flex ml="4px">
                  <Badge
                    id={`observation-${item?.personContextId}${index}`}
                    color="warning100"
                    onMouseEnter={handleHoverObservationTooltip}
                  >
                    {t(`user:put.observation`)}
                  </Badge>
                  <StyledTooltip
                    placement="bottom"
                    target={`observation-${item?.personContextId}${index}`}
                  >
                    {item?.personContextParamsObservation}
                  </StyledTooltip>
                </Flex>
              )}

              {showExpectedGuest &&
                item?.guest &&
                JSON.parse(item?.guest)?.countGuest > 0 && (
                  <Flex
                    onClick={() => {
                      handleSearchInput('searchText', item.personName);
                      sendAmplitudeData('invites badge click', {
                        user: loggedContext?.email,
                        partner: loggedContext?.partner?.name,
                        condominium: event?.condominium?.name,
                        host: item?.personName,
                        unit: item?.unityName,
                      });
                      toggleTab('invites');
                    }}
                    ml="4px"
                  >
                    <Badge color="purple50">
                      <Flex alignItems="center">
                        <Text mr="5px" fontSize="12px" color="purple900">
                          {t('user:list.amount-guests', {
                            amount: JSON.parse(item?.guest)?.countGuest,
                          })}
                        </Text>
                        <MdArrowForward
                          color={theme.colors.purple900}
                          size="12px"
                        />
                      </Flex>
                    </Badge>
                  </Flex>
                )}

              {allowedAccessTimeMessage &&
                !!personContextParamsAllowedAccesses?.today.length && (
                  <BadgeAllowAccess>
                    <MdOutlineAccessTime size={16} />
                    {allowedAccessTimeMessage}
                  </BadgeAllowAccess>
                )}
            </Flex>
          </Flex>
        </td>

        <td>{priority || ''}</td>

        <td>
          <Flex alignItems="center">
            <Box
              order={personContextParamsContactOrder?.intercom}
              id={`intercom-container-${item.personId}${index}`}
              ml="10px"
            >
              <Button
                disabled={
                  !intercomNumber ||
                  loading === 'intercom' ||
                  personContextParamsContactOrder?.intercom === 3
                }
                variant="out"
                large={
                  personContextParamsContactOrder?.intercom === 0 ||
                  personContextParamsContactOrder === null
                }
                rounded
                onClick={() => {
                  handleVerifyTime({
                    destination: intercomNumber,
                    destinationKind: destinationTypes.intercomAta,
                  });

                  handleDebounce('intercom');
                }}
                icon={<FiHome />}
              />
            </Box>
            <Tooltip
              placement="bottom"
              target={`intercom-container-${item.personId}${index}`}
            >
              <Text fontWeight="bold">{t('intercom')}</Text>
              <br />

              <Text fontStyle="italic">
                {intercomNumber
                  ? `${t('extension')} ${intercomNumber}`
                  : t('no-intercom')}
              </Text>
            </Tooltip>

            <Box
              order={personContextParamsContactOrder?.main}
              id={`phone-container-${item.personId}${index}`}
              ml="10px"
            >
              <Button
                size="sm"
                disabled={
                  !item?.personPhone ||
                  loading === 'phone' ||
                  personContextParamsContactOrder?.main === 3
                }
                variant="out"
                rounded
                large={personContextParamsContactOrder?.main === 0}
                onClick={() => {
                  handleVerifyTime({
                    destination: item?.personPhone,
                    destinationKind: destinationTypes.externalPhone,
                  });
                  handleDebounce('phone');
                }}
                icon={<FiPhone />}
              />
              <Tooltip
                placement="bottom"
                target={`phone-container-${item.personId}${index}`}
              >
                <Text fontWeight="bold">{t('phone')}</Text>
                <br />
                <Text fontStyle="italic">
                  {item?.personPhone ? item?.personPhone : t('no-phone')}
                </Text>
              </Tooltip>
            </Box>

            <Box
              order={personContextParamsContactOrder?.additional}
              id={`second-phone-container-${item.personId}${index}`}
              data-cy={`second-phone-container-${item.personId}`}
              ml="10px"
            >
              <Button
                size="sm"
                disabled={
                  !additionalContacts.length ||
                  loading === 'second-phone' ||
                  personContextParamsContactOrder?.additional === 3
                }
                variant="out"
                rounded
                large={personContextParamsContactOrder?.additional === 0}
                onClick={() => {
                  handleVerifyTime({
                    destination: additionalContacts?.[0]?.contact,
                    destinationKind: destinationTypes.externalPhone,
                  });
                  handleDebounce('second-phone');
                }}
                icon={<FiPhone />}
              />
              <Tooltip
                placement="bottom"
                target={`second-phone-container-${item.personId}${index}`}
              >
                <Text fontWeight="bold">{t('second-phone')}</Text>
                <br />
                <Text fontStyle="italic">
                  {additionalContacts.length
                    ? additionalContacts[0].contact
                    : t('no-second-phone')}
                </Text>
              </Tooltip>
            </Box>
          </Flex>
        </td>
        {showVideoCallStatus && (
          <td>
            <VideocallStatus
              status={item.videocall?.dialStatus}
              personId={item?.personId}
            />
          </td>
        )}

        <td>
          <Flex justifyContent="center">
            <Box id={`search-container-${item.personId}${index}`} ml="5px">
              <KiperButton
                size="sm"
                disabled={!item?.unityGroupName && !item?.unityName}
                onClick={() => {
                  selectRef.current.select.inputRef.focus();
                  handleSearchInput(
                    'sourceNodeId',
                    `${item.treeNodeParentTreeNodeId}`,
                  );
                  setSourceNode({
                    value: item.treeNodeParentTreeNodeId,
                    label: item.unityName,
                    parentName: item.unityGroupName,
                  });
                }}
                variant="out"
                icon={<FiSearch />}
                rounded
              />
              <Tooltip
                placement="bottom"
                target={`search-container-${item.personId}${index}`}
              >
                <Text fontWeight="bold">{t('unity-search')}</Text>
                <br />
                <Text fontStyle="italic">
                  {item?.unityGroupName && item?.unityName ? '' : t('no-unity')}
                </Text>
              </Tooltip>
            </Box>

            <KiperButton
              variant="text"
              color="neutral"
              rounded
              icon={
                openedRows[item.personContextId] ? (
                  <FiChevronUp />
                ) : (
                  <FiChevronDown />
                )
              }
              onClick={handleClickExpandUserDetail}
            />
          </Flex>
        </td>
      </Tr>
      {openedRows[item.personContextId] && (
        <tr className="collapse show">
          <UsersHostDetail
            intercomNumber={intercomNumber}
            loading={loading}
            observation={observation}
            priority={priority}
            handleClickToCall={handleClickToCall}
            handleDebounce={handleDebounce}
            data={item}
            colsSpan={5}
            allowedAccesses={personContextParamsAllowedAccesses}
            {...props}
          />
        </tr>
      )}

      {openNoKiperVoiceAgentModal && (
        <NoKiperVoiceAgentModal
          open={openNoKiperVoiceAgentModal}
          onToggle={toggleOpenNoKiperVoiceAgentModal}
        />
      )}

      {openNoKiperVoiceModal && (
        <NoKiperVoiceModal
          open={openNoKiperVoiceModal}
          onToggle={toggleOpenNoKiperVoiceModal}
        />
      )}

      {openAlertOutsideContactTime && (
        <AlertOutsideContactTime
          open={openAlertOutsideContactTime}
          onToggle={toggleOpenAlertOutsideContactTime}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </>
  );
}

export default UsersHostsRow;

UsersHostsRow.propTypes = {
  item: propTypes.object.isRequired,
  event: propTypes.object,
  onIntercomClick: propTypes.func,
  formik: propTypes.shape({
    values: propTypes.object,
    setFieldValue: propTypes.func,
  }),
  toggleTab: propTypes.func,
  handleSearchInput: propTypes.func,
  index: propTypes.number.isRequired,
  trRef: propTypes.any,
  selectRef: propTypes.oneOfType([
    propTypes.func,
    propTypes.shape({ current: propTypes.any }),
  ]),
  setSourceNode: propTypes.func,
  showVideoCallStatus: propTypes.bool,
};

UsersHostsRow.defaultProps = {
  event: null,
  onIntercomClick: null,
  formik: null,
  toggleTab: null,
  handleSearchInput: null,
  trRef: null,
  selectRef: null,
  setSourceNode: null,
  showVideoCallStatus: false,
};
