import propTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { Grid } from 'react-virtualized';
import AutoSizer from 'react-virtualized-auto-sizer';
import * as S from './styles';

import CameraThumbnail from './CameraThumbnail';

const LIST_HEIGHT = 100;
const ROW_HEIGHT = 78;
const COLUMN_WIDTH = 120;

const CamerasList = ({ ...props }) => {
  const { cameras, handleClickThumbnail, event, isLive, time } = props;

  if (!cameras?.length) return null;

  const camerasLength = useMemo(() => cameras.length, [cameras]);

  const CameraItem = ({ camera, key, style }) => (
    <S.CameraListThumbContainer style={style} key={key}>
      <CameraThumbnail
        camera={camera}
        handleClickThumbnail={() => handleClickThumbnail(camera)}
        condominiumPersonContextId={event?.condominium?.personContext?.id}
        date={!isLive ? time : null}
      />
    </S.CameraListThumbContainer>
  );

  CameraItem.propTypes = {
    camera: propTypes.any.isRequired,
    key: propTypes.any.isRequired,
    style: propTypes.any.isRequired,
  };

  const thumbItems = useCallback(
    ({ columnIndex, key, style }) => {
      const camera = cameras[columnIndex];

      return <CameraItem camera={camera} key={key} style={style} />;
    },
    [cameras],
  );

  return (
    <S.CameraListContainer>
      <AutoSizer disableHeight>
        {({ width }) => (
          <Grid
            height={LIST_HEIGHT}
            width={width}
            columnCount={camerasLength}
            columnWidth={COLUMN_WIDTH}
            rowCount={1}
            rowHeight={ROW_HEIGHT}
            cellRenderer={thumbItems}
          />
        )}
      </AutoSizer>
    </S.CameraListContainer>
  );
};

export default CamerasList;

CamerasList.propTypes = {
  cameras: propTypes.array,
  event: propTypes.any.isRequired,
  handleClickThumbnail: propTypes.func.isRequired,
  isLive: propTypes.bool.isRequired,
  time: propTypes.any.isRequired,
};

CamerasList.defaultProps = {
  cameras: [],
};
