import {
  Label,
  Input,
  Button,
  Flex,
  ErrorMessage as ErrorMessageComponent,
  Select,
} from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import propTypes from 'prop-types';
import {
  Form,
  FormGroup,
  FormGroups,
  ModalActions,
  UppercaseInput,
} from './styles';
import Modal from '../../../../../components/Modal';
import { useCurrentLoggedContext, useRemoteConfig } from '../../../../../hooks';

const RegisterVehicleModal = ({
  onToggle,
  opened,
  onSave,
  typesOptions,
  data,
  loadingSave,
}) => {
  const [t] = useTranslation('condominium');
  const { loggedContext } = useCurrentLoggedContext();

  const initialValues = {
    plate: '',
    model: '',
    type: typesOptions[0],
    tag: '',
  };

  const validationSchema = yup.object({
    plate: yup
      .string()
      .min(1, t('common:feedback.min', { number: 1 }))
      .required(t('common:feedback.required-field')),
    model: yup.string(),
    type: yup.string().required(),
    tag: yup.string(),
  });

  const onSubmit = values => onSave(values);

  const { values, setFieldValue, touched, errors, handleSubmit } = useFormik({
    initialValues: data || initialValues,
    validationSchema,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const showTag = useRemoteConfig({
    path: 'users.vehiclesTag',
    partner: Number(loggedContext?.partner?.personContextId),
    user: Number(loggedContext?.personContextId),
  });

  return (
    <Modal
      title={t('details.unity.register-vehicle-modal.title')}
      open={opened}
      toggle={onToggle}
    >
      <Flex
        gridGap="16px"
        width="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Form onSubmit={handleSubmit}>
          <FormGroups>
            <FormGroup>
              <Label for="type" block>
                {t('details.unity.register-vehicle-modal.vehicle-type')}
              </Label>
              <Select
                width="100%"
                name="type"
                value={typesOptions[values.type]}
                options={typesOptions.map((opt, index) => ({
                  label: opt.label,
                  value: index,
                }))}
                invalid={touched.type && !!errors.type}
                onChange={e => setFieldValue('type', e.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="model" block>
                {t('details.unity.register-vehicle-modal.model')}
              </Label>
              <Input
                type="text"
                className="form-control"
                name="model"
                value={values.model}
                onChange={setFieldValue}
                invalid={!!errors.model}
              />
            </FormGroup>

            <FormGroup>
              <Label for="plate" block>
                {t('details.unity.register-vehicle-modal.plate')}
              </Label>
              <Input
                type="text"
                className="form-control"
                name="plate"
                value={values.plate}
                onChange={setFieldValue}
                invalid={!!errors.plate}
              />
              {!!errors.plate && (
                <ErrorMessageComponent>{errors?.plate}</ErrorMessageComponent>
              )}
            </FormGroup>

            {showTag && (
              <FormGroup>
                <Label for="tag" block>
                  {t('details.unity.register-vehicle-modal.tag')}
                </Label>
                <UppercaseInput
                  type="text"
                  className="form-control"
                  name="tag"
                  value={values.tag}
                  onChange={setFieldValue}
                  invalid={!!errors.tag}
                />
                {!!errors.tag && (
                  <ErrorMessageComponent>{errors?.tag}</ErrorMessageComponent>
                )}
              </FormGroup>
            )}
          </FormGroups>

          <ModalActions>
            <Button
              color="secondary"
              outline
              className="mr-3"
              type="button"
              onClick={onToggle}
            >
              {t('buttons:cancel')}
            </Button>
            <Button
              type="submit"
              color="primary"
              loading={loadingSave}
              disabled={loadingSave}
            >
              {loadingSave ? t('buttons:saving') : t('buttons:save')}
            </Button>
          </ModalActions>
        </Form>
      </Flex>
    </Modal>
  );
};

RegisterVehicleModal.propTypes = {
  onToggle: propTypes.func.isRequired,
  onSave: propTypes.func.isRequired,
  opened: propTypes.bool.isRequired,
  loadingSave: propTypes.bool.isRequired,
  data: propTypes.shape({
    type: propTypes.string,
    model: propTypes.string,
    plate: propTypes.string,
  }).isRequired,
  typesOptions: propTypes.arrayOf(
    propTypes.shape({ value: propTypes.any, label: propTypes.string }),
  ).isRequired,
};

export default RegisterVehicleModal;
