import { Observable, from } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { auth } from '@kiper/monitoring-graphql';
import { runtimeConfig } from '@kiper/fns';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { setMap, types, get, remove } from '@kiper/cookie';
import 'cross-fetch/polyfill';

const errorLink = request => {
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([createHttpLink({ uri: runtimeConfig.RAZZLE_GRAPHQL_URL })]),
  });
  return onError(({ graphQLErrors, forward, operation }) => {
    if (!graphQLErrors) return undefined;

    if (
      !graphQLErrors.some(
        x => x.message.includes('unauthorized') || x.message.includes('401'),
      )
    )
      return undefined;

    const refresh = get(types.refresh, request);
    if (!refresh) return undefined;
    return new Observable(observer => {
      client
        .mutate({
          mutation: auth.refreshTokenMutation,
          variables: { refreshToken: refresh },
        })
        .then(res => {
          const refreshToken = res && res.data && res.data.refreshToken;
          setMap({
            [types.authorization]: refreshToken.accessToken,
            [types.refresh]: refreshToken.refreshToken,
          });
          operation.setContext(({ headers = {} }) => ({
            ...headers,
            authorization: `Bearer ${refreshToken.accessToken}`,
          }));
        })
        .then(() => {
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };

          forward(operation).subscribe(subscriber);
        })
        .catch(e => {
          remove([
            types.partnerContext,
            types.authorization,
            types.refresh,
            types.context,
            types.topNodeId,
            types.topContextId,
          ]);

          observer.error(e);
        });
    });
  });
};

export default errorLink;
