import { useContext } from 'react';
import propTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { MdOutlineConstruction } from 'react-icons/md';
import { TabContent, TabPane } from '@kiper/ui';
import { EventAttendanceContext } from '../../../store';
import {
  useAttendanceRemoteConfig,
  useCurrentLoggedContext,
} from '../../../hooks';
import TabHeaderPage from '../../../components/TabHeaderPage/TabHeaderPage';
import EventAttendanceQueue from './EventAttendanceQueue';
import EventOnHoldQueue from './EventOnHoldQueue';
import EventOnTechnicalReviewQueue from './EventOnTechnicalReviewQueue';
import SimplifiedEventList from '../../../components/SimplifiedEventList';
import { useTabs } from './hooks';
import * as S from './styles';

const EventList = ({ hide, toggleNewEventModal }) => {
  const {
    eventOnTechnicalReviewQueue,
    filteredByCondominiumContextId,
    tabControl: { TAB_IDS, activeTab },
  } = useContext(EventAttendanceContext);
  const { loggedContext } = useCurrentLoggedContext();
  const history = useHistory();

  const { showTechnicalReviewFeature } = useAttendanceRemoteConfig(
    loggedContext,
  );

  const { tabs, handleChangeTab } = useTabs();

  if (showTechnicalReviewFeature) {
    const eventsTechnicalReviewQueueLength =
      eventOnTechnicalReviewQueue?.length;

    tabs.push({
      title: null,
      tabId: TAB_IDS.ON_TECHNICAL_REVIEW_QUEUE,
      showTab: true,
      icon: <MdOutlineConstruction size={20} />,
      count: eventsTechnicalReviewQueueLength,
    });
  }

  const isFallback = history?.location?.pathname.includes('fallback');

  if (isFallback && activeTab === TAB_IDS.ATTENDANCE_QUEUE) {
    return <SimplifiedEventList />;
  }

  return (
    <S.Container hide={+hide} hasAFilter={+!!filteredByCondominiumContextId}>
      <TabHeaderPage
        tabs={tabs}
        activeTab={activeTab}
        onChangeTab={handleChangeTab}
        mt="16px"
        p="0 8px"
        mr="0"
        borderBottom="2px solid"
        borderColor="tabBorderColor"
      />
      <TabContent activeTab={activeTab}>
        <TabPane tabId={TAB_IDS.ATTENDANCE_QUEUE}>
          <EventAttendanceQueue toggleNewEventModal={toggleNewEventModal} />
        </TabPane>
        <TabPane tabId={TAB_IDS.ON_HOLD_QUEUE}>
          <EventOnHoldQueue />
        </TabPane>
        {showTechnicalReviewFeature && (
          <TabPane tabId={TAB_IDS.ON_TECHNICAL_REVIEW_QUEUE}>
            <EventOnTechnicalReviewQueue />
          </TabPane>
        )}
      </TabContent>
    </S.Container>
  );
};

export default EventList;

EventList.propTypes = {
  hide: propTypes.bool,
  toggleNewEventModal: propTypes.func.isRequired,
};

EventList.defaultProps = {
  hide: false,
};
