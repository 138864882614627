import { Label } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { Container, Header, CustomInput } from './styles';

const CallPriority = ({ values, handleChange, isEditable }) => {
  const [t] = useTranslation('user');

  return (
    <Container>
      <Header>
        <Label style={{ fontSize: '14px' }}>
          {t('put.access-data.call-priority.title')}
        </Label>
        <Label info style={{ fontSize: '14px' }}>
          {t('put.access-data.call-priority.info')}
        </Label>
      </Header>
      <CustomInput
        disabled={!isEditable}
        type="range"
        id="callPriority"
        name="callPriority"
        min={1}
        max={50}
        value={values.callPriority}
        onChange={e => handleChange('callPriority', Number(e.target.value))}
      />
      <Label style={{ fontSize: '14px', marginBottom: 10 }}>
        {`${t('put.access-data.call-priority.call-priority')} ${
          values.callPriority
        }`}
      </Label>
      <div />
    </Container>
  );
};

CallPriority.propTypes = {
  values: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  isEditable: propTypes.bool.isRequired,
};

export default CallPriority;
