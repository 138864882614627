import {
  ScheduleType,
  TextType,
  YesOrNoQuestionType,
} from '../../QuestionAnswerTypes';

const questionAnswerType = Object.freeze({
  YES_NO: 0,
  TEXT: 1,
  SCHEDULE: 2,
});

const useQuestionAnswerType = () => {
  const handleQuestionAnswerType = ({ description, answer }) => {
    const type = answer?.questionAnswerType;
    switch (type) {
      case questionAnswerType.YES_NO:
        return <YesOrNoQuestionType question={description} answer={answer} />;
      case questionAnswerType.TEXT:
        return <TextType question={description} answer={answer} />;
      case questionAnswerType.SCHEDULE:
        return <ScheduleType question={description} answer={answer} />;
      default:
        return null;
    }
  };

  return {
    handleQuestionAnswerType,
  };
};

export default useQuestionAnswerType;
