const vehicleTypesOptions = t => [
  {
    label: t('details.unity.register-vehicle-modal.type-option-car'),
    value: 'car',
  },
  {
    label: t('details.unity.register-vehicle-modal.type-option-motorcycle'),
    value: 'motocycle',
  },
];

export default vehicleTypesOptions;
