import { useEffect, useContext, useState } from 'react';
import { SocketContext } from '../services/socket';

export default function useFingerprintReceived() {
  const { subscribe, unsubscribe } = useContext(SocketContext);

  const [fingerprint, setFingerprint] = useState([]);

  const resetFingerprint = () => setFingerprint(null);

  const onMessage = ({ action, item }) => {
    if (action === 9) setFingerprint(item);
  };

  useEffect(() => {
    subscribe(`fingerprintReceived`, onMessage);

    return () => unsubscribe(`fingerprintReceived`);
  }, []);

  return { fingerprint, resetFingerprint };
}
