import propTypes from 'prop-types';
import {
  Sended,
  Waiting,
  Error as ErrorStatus,
  Undeliverable,
  NotSent,
} from './SmsStatus';

const statusEnum = {
  null: Waiting,
  1: Waiting,
  2: Sended,
  3: ErrorStatus,
  4: Undeliverable,
  5: NotSent,
};
const SmsStatus = ({ status }) => {
  const Component = statusEnum[status];
  if (Component) return <Component />;
  return <Waiting />;
};

SmsStatus.propTypes = {
  status: propTypes.number,
};

SmsStatus.defaultProps = {
  status: null,
};

export default SmsStatus;
