import { useContext } from 'react';
import propTypes from 'prop-types';
import { SidebarContext } from '../Sidebar';

import { TabbarItemContainer } from './styles';

const TabbarItem = ({ id, children }) => {
  const { onSelect, selected, active } = useContext(SidebarContext);

  const handleSelected = () => {
    onSelect(id);
  };

  return (
    <TabbarItemContainer
      className={active && id === selected && 'is-active'}
      onClick={handleSelected}
    >
      {children}
    </TabbarItemContainer>
  );
};

export default TabbarItem;

TabbarItem.propTypes = {
  id: propTypes.string.isRequired,
  children: propTypes.any.isRequired,
};
