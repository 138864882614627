import { Label, Input, ErrorMessage, Button } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';
import propTypes from 'prop-types';
import { systemSettings as gqlOperations } from '@kiper/monitoring-graphql';
import { useMutation } from 'react-apollo';
import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import Modal from '../../../../../components/Modal';
import { FormGroup, Form, ModalActions } from './styles';

const MESSAGE_PREFIX = 'details.alarmSettings.modal';

const AlarmModal = ({ toggle, opened, data, refetch, id }) => {
  const [t] = useTranslation('system-settings');
  const { toast } = useSwal();

  const onCompleted = operation => {
    toast.fire({
      title: t(`details.alarmSettings.${operation}-success`),
      icon: 'success',
    });
  };

  const onError = err => {
    const formattedErrors = apolloErrorHandler(err);
    if (formattedErrors && formattedErrors.length) {
      toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
  };

  const [onSave, { loading: saveLoading }] = useMutation(
    gqlOperations.createAlarmSetting,
    {
      onCompleted: () => onCompleted('create'),
      onError,
    },
  );

  const [onUpdate, { loading: updateLoading }] = useMutation(
    gqlOperations.updateAlarmSetting,
    {
      onCompleted: () => onCompleted('update'),
      onError,
    },
  );

  const loading = saveLoading || updateLoading;

  const onSubmit = async values => {
    if (data?.id) {
      await onUpdate({
        variables: {
          data: {
            id: data.id,
            name: values.name,
          },
        },
      });
    } else {
      await onSave({
        variables: {
          data: {
            name: values.name,
          },
        },
      });
    }
    toggle();
    refetch();
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(t(`${MESSAGE_PREFIX}.inputs.description.validation`)),
  });

  const formikBag = useFormik({
    initialValues: data || {
      name: '',
    },
    validationSchema,
    onSubmit,
  });

  const { handleSubmit, values, errors, touched, setFieldValue } = formikBag;

  return (
    <Modal
      title={t(`${MESSAGE_PREFIX}.title`)}
      toggle={toggle}
      open={opened}
      id={id}
    >
      <FormikProvider value={formikBag}>
        <Form className="form" onSubmit={handleSubmit} noValidate>
          <FormGroup>
            <Label>{t(`${MESSAGE_PREFIX}.inputs.description.label`)}</Label>
            <Input
              type="text"
              className="form-control"
              placeholder={t(
                `${MESSAGE_PREFIX}.inputs.description.placeholder`,
              )}
              name="name"
              value={values.name}
              onChange={setFieldValue}
              invalid={touched.name && !!errors.name}
            />
            {touched.name && errors.name && (
              <ErrorMessage>{errors.name}</ErrorMessage>
            )}
          </FormGroup>

          <ModalActions>
            <Button
              color="secondary"
              outline
              className="mr-3"
              type="button"
              onClick={toggle}
            >
              {t('buttons:cancel')}
            </Button>

            <Button
              id="video-settings-modal-save-button"
              type="submit"
              loading={loading}
              color="primary"
              disabled={loading}
            >
              {loading ? t('buttons:saving') : t('buttons:save')}
            </Button>
          </ModalActions>
        </Form>
      </FormikProvider>
    </Modal>
  );
};

AlarmModal.propTypes = {
  id: propTypes.string.isRequired,
  toggle: propTypes.func.isRequired,
  opened: propTypes.bool.isRequired,
  refetch: propTypes.func.isRequired,
  data: propTypes.object,
};

AlarmModal.defaultProps = {
  data: null,
};

export default AlarmModal;
