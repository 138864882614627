import propTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import InputGroup from 'reactstrap/lib/InputGroup';

import { FiCopy } from 'react-icons/fi';

import { Label, Input, KiperButton, Flex, Tooltip } from '@kiper/ui';

import Modal from '../../../../../components/Modal';
import { FormGroup, InputGroupTextStyled } from './styles';

const MESSAGE_PREFIX = 'details.alarmSettings.token';

const TokenModal = ({ toggle, opened, id, token }) => {
  const [t] = useTranslation('system-settings');

  return (
    <Modal
      title={t(`${MESSAGE_PREFIX}.title`)}
      toggle={toggle}
      open={opened}
      id={id}
    >
      <FormGroup>
        <Label>{t(`${MESSAGE_PREFIX}.label`)}</Label>
        <Flex alignItems="center">
          <InputGroup>
            <Input
              disabled
              type="text"
              className="form-control"
              name="description"
              value={token}
            />
            <InputGroupTextStyled>
              <KiperButton
                onClick={() => navigator.clipboard.writeText(token)}
                id="copy-token-container"
                variant="text"
                color="neutral"
                icon={<FiCopy />}
              />
            </InputGroupTextStyled>
          </InputGroup>

          <Flex id="copy-token-container" ml="5px" alginItems="center">
            <Tooltip placement="bottom" target="copy-token-container">
              {t('details.alarmSettings.token.copy-token')}
            </Tooltip>
          </Flex>
        </Flex>
      </FormGroup>
    </Modal>
  );
};

TokenModal.propTypes = {
  id: propTypes.string.isRequired,
  toggle: propTypes.func.isRequired,
  opened: propTypes.bool.isRequired,
  token: propTypes.string,
};

TokenModal.defaultProps = {
  token: '',
};

export default TokenModal;
