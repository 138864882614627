import styled from 'styled-components';
import {
  Row as row,
  Col as col,
  CustomInput as customInput,
  FormGroup as formGroup,
} from 'reactstrap';
import { MdInfoOutline } from 'react-icons/md';

export const Container = styled.div``;

export const Row = styled(row)``;

export const Col = styled(col)`
  /* background: red; */
`;
export const FormGroup = styled(formGroup)`
  margin-bottom: 20px;
`;

export const InfoContainer = styled.div`
  border: 1px solid ${props => props.theme.colors.secondary400};
  border-radius: 4px;
  padding: 10px;
`;

export const InfoIcon = styled(MdInfoOutline).attrs({
  size: 18,
})`
  color: ${props => props.theme.colors.infoPrimary};
  margin-right: 15px;
`;

export const InfoTitleContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 10px;
`;

export const InfoContent = styled.div``;

export const HeaderContainer = styled.div`
  margin-bottom: 15px;
`;

export const CustomInput = styled(customInput)`
  input {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }
  label {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.7);
  }
`;
