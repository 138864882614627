import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { eventTriage } from '@kiper/monitoring-graphql/attendance';
import { insertStepHistory } from '@kiper/monitoring-graphql/guided_attendance';
import { useMutation } from 'react-apollo';

const useMutations = () => {
  const { toast } = useSwal();

  const [updateTriage] = useMutation(eventTriage, {
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const [stepHistory] = useMutation(insertStepHistory, {
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  return { updateTriage, stepHistory };
};

export default useMutations;
