import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, Label, Select } from '@kiper/ui';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useQuery } from 'react-apollo';
import { queries } from '@kiper/monitoring-graphql/pre_register';
import { useQuery as useQueryString } from '@kiper/hooks';
import UsersPageActions, { tabIds } from '../../../components/UsersPageActions';
import useCurrentLoggedContext from '../../../hooks/useCurrentLoggedContext';
import UnityListWithPendingApproval from './UnityListWithPendingApproval';
import initEmptyStatePlaceholder from '../../../assets/images/pending-approval-empty-state.svg';
import { sendAmplitudeData } from '../../../services/amplitude';
import EmptyState from '../../../components/EmptyState';
import * as S from './styles';

const mapperOptionsCondominium = data => {
  return data.map(x => ({
    ...x,
    label: x.name,
    value: Number(x.personContextId),
  }));
};

const mapperOptionsUnities = data => {
  return data?.map(x => ({
    ...x,
    label: `${x.unityGroup.name} - ${x.unity.name}`,
    value: Number(x.unity.id),
  }));
};

export default function PendingApproval({ route }) {
  const history = useHistory();
  const query = useQueryString();

  const [t] = useTranslation('user');

  const { loggedContext } = useCurrentLoggedContext();

  const [condominium, setCondominium] = useState(null);
  const [unity, setUnity] = useState([]);
  const [unities, setUnities] = useState([]);
  const [unitiesOptions, setUnitiesOptions] = useState([]);
  const [unityUsers, setUnityUsers] = useState({});
  const [unityIdFetched, setUnityIdFetched] = useState([]);

  const { data: condominiumData, loading: loadingCondominiums } = useQuery(
    queries.preRegisterCondominiumsFilter,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const [getUnities, { loading: loadingUnities }] = useLazyQuery(
    queries.preRegisterUnitiesFilter,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: unitiesData => {
        setUnities(unitiesData.preRegisterUnitiesFilter);
        setUnitiesOptions(
          mapperOptionsUnities(unitiesData.preRegisterUnitiesFilter),
        );
      },
    },
  );

  const [
    getUsersWithPendingApproval,
    { loading: loadingUsersWithPendingApproval },
  ] = useLazyQuery(queries.allPreRegisterUnitiesPendingApprovals, {
    fetchPolicy: 'cache-and-network',
    onCompleted: usersData => {
      setUnityUsers(prevState => ({
        ...prevState,
        [unityIdFetched]: [...usersData.allPreRegisterUnitiesPendingApprovals],
      }));
    },
  });

  const handleKeyDown = event => {
    if (event.key === 'Enter' && event.shiftKey === false)
      event.preventDefault();
  };

  const options = useMemo(
    () => [
      ...(condominiumData?.preRegisterCondominiumsFilter
        ? mapperOptionsCondominium(
            condominiumData?.preRegisterCondominiumsFilter,
          )
        : []),
    ],
    [condominiumData],
  );

  const handleChangeFilterCondominium = useCallback(
    condominiumFilter => {
      if (!condominiumFilter) {
        history.push({
          search: '',
        });
        sendAmplitudeData(`Filter condominium cleaned`);
        setUnity(null);
        setCondominium(null);
        setUnities([]);
      } else {
        history.push({
          search: `?condominium=${condominiumFilter?.name}`,
        });
        setUnity(null);
        getUnities({
          variables: {
            condominiumContextId: condominiumFilter?.value,
          },
        });
        setCondominium(condominiumFilter);

        sendAmplitudeData(`Filter pending approval users from condominium`, {
          condominium: condominiumFilter,
        });
      }
    },
    [unity],
  );

  useEffect(() => {
    if (options && query.has('condominium')) {
      const condominiumQueryStringNameIndex = options.findIndex(
        item => item.name === query.get('condominium').replace('%20', ' '),
      );
      if (condominiumQueryStringNameIndex >= 0)
        handleChangeFilterCondominium(options[condominiumQueryStringNameIndex]);
    } else {
      history.push({
        search: '',
      });
    }
  }, [options, condominium]);

  const handleChangeFilterUnities = useCallback(
    unityFilter => {
      if (!unityFilter) {
        sendAmplitudeData(`Filter unities cleaned`);
        setUnity(null);
        getUnities({
          variables: {
            condominiumContextId: condominium?.value,
          },
        });
      } else {
        setUnity(unityFilter);
        setUnities([unityFilter]);

        if (!(unityFilter.value in unityUsers)) {
          setUnityIdFetched(unityFilter.value);
          getUsersWithPendingApproval({
            variables: {
              unityId: unityFilter?.value,
            },
          });
        }

        sendAmplitudeData(
          `Filter pending approval users from unity or group unity`,
          {
            unity: unityFilter,
          },
        );
      }
    },
    [unity],
  );

  const handleOpenCollapse = useCallback(
    unityId => {
      if (unityId && !(unityId in unityUsers)) {
        setUnityIdFetched(unityId);
        getUsersWithPendingApproval({
          variables: {
            unityId,
          },
        });
      }
    },
    [unityUsers],
  );

  const loadingResults = loadingUnities || loadingUsersWithPendingApproval;

  const showPendingList = !!condominium || !!unities?.length;

  const condominiumUsersQuantity = useMemo(() => {
    const initialValue = 0;
    return unities.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.countPreRegister,
      initialValue,
    );
  }, [unities]);

  return (
    <S.Wrapper>
      <UsersPageActions
        t={t}
        route={route}
        title={t('pending-approval.title')}
        tabId={tabIds.PENDING_APPROVAL}
      >
        <S.Container>
          <S.Form inline onKeyDown={handleKeyDown}>
            <Flex width="50%" gridGap="16px" alignItems="center">
              <Flex flex={1}>
                <S.FormGroup>
                  <Flex
                    width="100%"
                    flexDirection="column"
                    gridGap="8px"
                    alignItems="flex-start"
                  >
                    <Label weight={400}>
                      {t(`list.condominium-select-label`)}
                    </Label>
                    <Select
                      isClearable
                      width="100%"
                      placeholder={t('pending-approval.select-condominium')}
                      name="sourceNodeId"
                      sourceNodeId={loggedContext.topNodeId}
                      fieldName={['condominium']}
                      value={condominium}
                      onChange={handleChangeFilterCondominium}
                      options={options}
                      isLoading={loadingCondominiums}
                    />
                  </Flex>
                </S.FormGroup>
              </Flex>
              <Flex flex={1} width="100%">
                <S.FormGroup>
                  <Flex
                    width="100%"
                    flexDirection="column"
                    gridGap="8px"
                    alignItems="flex-start"
                  >
                    <Label weight={400}>{t(`list.unity-select-label`)}</Label>
                    <Select
                      isClearable
                      width="100%"
                      placeholder={t(`list.unity-select`)}
                      name="unity"
                      sourceNodeId={condominium?.value}
                      fieldName={['unity']}
                      value={unity}
                      onChange={handleChangeFilterUnities}
                      options={unitiesOptions}
                      isLoading={loadingUnities}
                      isDisabled={!condominium?.value || !unitiesOptions.length}
                    />
                  </Flex>
                </S.FormGroup>
              </Flex>
            </Flex>
          </S.Form>
          {showPendingList ? (
            <UnityListWithPendingApproval
              condominium={condominium}
              unityUsers={unityUsers}
              unities={unities}
              onOpenCollapse={handleOpenCollapse}
              loadingDataTable={loadingUsersWithPendingApproval}
              loadingList={loadingResults}
              condominiumUsersQuantity={condominiumUsersQuantity}
              refetchUsersWithPendingApproval={getUsersWithPendingApproval}
              refetchUnities={getUnities}
            />
          ) : (
            <EmptyState
              placeholder={initEmptyStatePlaceholder}
              text={t('pending-approval.init-empty-state')}
            />
          )}
        </S.Container>
      </UsersPageActions>
    </S.Wrapper>
  );
}

PendingApproval.propTypes = {
  route: propTypes.any.isRequired,
};
