import styled, { css } from 'styled-components';

import { MdKeyboardTab } from 'react-icons/md';

export const Container = styled.aside`
  z-index: 1020;
  width: 240px;
  position: fixed;
  @media print {
    display: none;
    height: 0;
    width: 0;
  }

  ${props =>
    props.isCollapsed &&
    css`
      width: 60px;
    `}

  ${props =>
    props.isMouseIn &&
    css`
      width: 240px;
    `}
`;

export const Header = styled.div`
  background-color: ${props => props.theme.colorNavbarActive};
  cursor: ${props => (props.cursor ? 'pointer' : 'default')};
`;

export const CollapseIcon = styled(MdKeyboardTab)`
  transform: rotate(180deg);
  transition: all 0.25s ease-in-out;
`;

export const ExpandIcon = styled(MdKeyboardTab)``;

export const HeaderContainer = styled.div`
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  padding-left: 19px;
  padding-right: 19px;

  ${props =>
    props.isCollapsed &&
    css`
      justify-content: center;
      padding: 0;
    `}

  ${props =>
    props.isMouseIn &&
    css`
      justify-content: flex-start;
      padding-left: 19px;
      padding-right: 19px;
    `}
`;

export const Body = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px) !important;
  position: relative;
  height: calc(100% - 59px);
  border-top: 1px solid rgba(72, 94, 144, 0.16);
  border-right: 1px solid rgba(72, 94, 144, 0.16);
  overflow: hidden;
  z-index: 9;
  padding-top: 10px;
  align-items: flex-start;
  background: white;
`;

export const NavLabel = styled.li`
  list-style-type: none;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 23px;

  ${props =>
    props.isCollapsed &&
    css`
      border-top: 1px solid ${({ theme }) => theme.colors.secondary200};
      margin-left: 15px;
      margin-right: 15px;
      span {
        display: none;
      }
    `}
`;

export const MenuOptionsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 0;
`;

export const ListMenuItem = styled.li`
  line-height: 24px;
  padding-left: 20px;
  flex: 1;
  display: flex;
  align-items: center;
  list-style-type: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.secondary800};
  svg {
    color: ${({ theme }) => theme.colors.secondary600};
    margin-right: 15px;
    height: 18px;
    width: 18px;
  }
  span {
    color: ${({ theme }) => theme.colors.secondary800};
    font-size: 12px;
  }

  a {
    text-decoration: none;
  }

  border-left: 3px solid transparent;

  ${props =>
    props.active &&
    css`
      border-left-color: ${({ theme }) => theme.colors.primary600};
      color: ${({ theme }) => theme.colors.primary600};
      svg {
        color: ${({ theme }) => theme.colors.primary600};
      }
      span {
        color: ${({ theme }) => theme.colors.primary600};
      }
    `}

  ${props =>
    props.isCollapsed &&
    css`
      padding-left: 15px;
    `}
`;

export const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 0;
  padding-left: 15px;
  align-items: center;
  min-height: 60px;
`;
