import styled from 'styled-components';
import { Row as row, Col as col } from 'reactstrap';
import { MdVideocamOff } from 'react-icons/md';
import { Label as label, ScrollBar as scrollbar, Flex } from '@kiper/ui';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.white};
  min-width: 462px;
  margin-left: 16px;
  height: fit-content;
  width: 100%;
  margin-top: 10px;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  background-color: #f9f9fa;
  margin-bottom: 10px;
  overflow: auto hidden;
  white-space: nowrap;
`;

export const FilterButtonsContainer = styled.div`
  display: flex;
  margin-left: 10px;
  flex-direction: row;
`;

export const GridContainer = styled.div`
  flex: 1;
  align-items: center;
  overflow: auto;
  white-space: nowrap;
  overflow-x: hidden;
  width: 100%;
`;

export const CommandContainer = styled.div`
  max-height: 420px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10px;
  padding-right: 10px;
`;

export const Col = styled(col)`
  padding: 0;
`;

export const Row = styled(row)`
  margin: 0;
  width: 100%;
`;

export const ImageNotFound = styled.div`
  background-color: ${props => props.theme.colors.secondary100};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 78px;
`;

export const EmptyStateContainer = styled(ImageNotFound)`
  min-height: 360px;
  width: 100%;
  border-radius: 8px;
`;

export const EmptyStateText = styled(label)`
  margin-top: 10px;
  font-size: 14px;
  color: ${props => props.theme.colors.mediumBlack};
`;

export const CamOffIcon = styled(MdVideocamOff)``;

export const ScrollBar = styled(scrollbar)`
  display: flex;
  flex: 1;
`;

export const ObservationContainer = styled(Flex)`
  white-space: break-spaces;
  word-break: break-word;
  line-break: auto;
  max-height: 137px;
  border: 1px solid ${props => props.theme.colors.infoPrimary};
  padding: 8px;
  border-radius: 10px;
  background: ${props => props.theme.colors.infoLight};
  flex-direction: column;
  width: 100%;
  margin-bottom: 5px;
  margin-right: 16px;
`;

export const ObservationScroll = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
`;
