import { Flex } from '@kiper/ui';
import styled from 'styled-components';

export const Container = styled(Flex)`
  height: 100vh;
  flex: 1;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
`;

export const SideMenu = styled(Flex)`
  width: 334px;

  @media screen and (min-width: 1920px) {
    width: 500px;
    max-width: 500px;
  }
`;
