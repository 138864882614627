import { Star } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@kiper/ui';
import Badge from './styles';

const KiperAccount = () => {
  const [t] = useTranslation('user');
  return (
    <Badge id="kiperAccount" color="extra">
      <Star size="14" />
      Kiper Account
      <Tooltip placement="right" target="kiperAccount">
        {t('put.kiper-account-info')}
      </Tooltip>
    </Badge>
  );
};

export default KiperAccount;
