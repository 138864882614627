import { useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { eventTypes } from '../../../constants';

const useEventGeneratorValidationSchema = () => {
  const [videoCall, setVideoCall] = useState(false);
  const [t] = useTranslation('event-generator');

  const answeredCallValidationSchema = yup
    .object({
      deviceExtension: yup.mixed().when('videoCall', {
        is: () => !videoCall,
        then: yup.string().required(t('common:feedback.required-field')),
        otherwise: yup.string(),
      }),
      sipAccountId: yup.mixed().when('videoCall', {
        is: () => !!videoCall,
        then: yup.string().required(t('common:feedback.required-field')),
        otherwise: yup.string(),
      }),
    })
    .required();

  const otherEventValidationSchema = yup
    .object({
      template: yup
        .object({
          cmd: yup.string().required(
            t('common:feedback.required-field-custom', {
              field: t('event.cmd'),
            }),
          ),
        })
        .required(),
    })
    .required();

  const validationSchema = eventType => {
    switch (eventType) {
      case eventTypes.answeredCall:
        return answeredCallValidationSchema;
      default:
        return otherEventValidationSchema;
    }
  };

  return {
    validationSchema,
    videoCall,
    setVideoCall,
  };
};

export default useEventGeneratorValidationSchema;
