import { useMemo } from 'react';
import { Label } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';

export default function PlaceTypeLabel({ labelText, type }) {
  const [t] = useTranslation('user');

  const placeLabel = useMemo(() => {
    const text = t(`put.access-data.${type}`);
    if (!labelText) return text;

    return labelText;
  }, [type]);

  return (
    <Label info block htmlFor={type}>
      {placeLabel}
    </Label>
  );
}

PlaceTypeLabel.propTypes = {
  type: propTypes.oneOf([
    'unity',
    'unity-group',
    'type-unity-group',
    'type-unity',
  ]).isRequired,
  labelText: propTypes.string,
};

PlaceTypeLabel.defaultProps = {
  labelText: null,
};
