import styled from 'styled-components';
import { CustomInput as customInput } from 'reactstrap';

export const Container = styled.div`
  input[type='range']::-webkit-slider-thumb {
    background: ${({ theme }) => theme.colors.primary500};
    cursor: pointer;
    box-shadow: none;
  }
`;

export const Header = styled.div`
  margin-bottom: 15px;
`;

export const CustomInput = styled(customInput)`
  margin-bottom: 15px;
`;
