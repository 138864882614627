import GuidedAttendanceSettings from './GuidedAttendanceSettings';
import GuidedAttendanceSettingsEnableCondominium from './GuidedAttendanceSettingsEnableCondominium';

const breadcrumb = (label, to) => ({ label, to });

export default [
  {
    name: 'Guided attendance settings',
    path: '/guided-attendance-settings',
    exact: true,
    component: GuidedAttendanceSettings,
    breadcrumb: [
      breadcrumb(
        'common:breadcrumb.guided-attendance-settings',
        '/guided-attendance-settings',
      ),
    ],
  },
  {
    name: 'Guided attendance settings enable condominium',
    path: '/guided-attendance-settings/enable-condominium',
    exact: true,
    component: GuidedAttendanceSettingsEnableCondominium,
    breadcrumb: [
      breadcrumb(
        'common:breadcrumb.guided-attendance-settings',
        '/guided-attendance-settings',
      ),
      breadcrumb(
        'common:breadcrumb.enable-condominium',
        '/guided-attendance-settings/enable-condominium',
      ),
    ],
  },
];
