import {
  getQuestionsAndAnswers,
  getQuestionsAndAnswersByEventId,
} from '@kiper/monitoring-graphql/guided_attendance';
import { useContext, useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';
import { CondominiumRuleFilterContext } from '../../../../../../store';
import useGetDescriptions from './useGetDescriptions';

const useQueries = ({ condominiumPersonContextId, eventId }) => {
  const { selectedSubtriage, setFilterContext } = useContext(
    CondominiumRuleFilterContext,
  );
  const { handleGetDescription } = useGetDescriptions();

  const [getBySubtriageId] = useLazyQuery(getQuestionsAndAnswers, {
    fetchPolicy: 'network-only',
    onCompleted: ({ condominiumRuleQuestionsAndAnswers }) => {
      if (condominiumRuleQuestionsAndAnswers?.length) {
        setFilterContext({
          questions: condominiumRuleQuestionsAndAnswers,
          loading: false,
        });
      }
    },
    onError: () => {
      setFilterContext({
        loading: false,
      });
    },
  });

  const [getByEventId] = useLazyQuery(getQuestionsAndAnswersByEventId, {
    fetchPolicy: 'network-only',
    onCompleted: ({ condominiumRuleQuestionsAndAnswersByEventId }) => {
      const {
        triage,
        subtriage,
        questionsAndAnswer,
      } = condominiumRuleQuestionsAndAnswersByEventId;

      const filterContext = {
        loading: false,
      };
      if (questionsAndAnswer?.length) {
        filterContext.questions = questionsAndAnswer;
      }
      if (triage) {
        const triageObject = {
          ...triage,
          description: handleGetDescription(triage),
        };
        filterContext.filteredTriage = triageObject;
      }
      if (subtriage) {
        const subtriageObject = {
          ...subtriage,
          description: handleGetDescription(subtriage),
        };
        filterContext.filteredSubtriage = subtriageObject;
      }

      setFilterContext(filterContext);
    },
    onError: () => {
      setFilterContext({
        loading: false,
      });
    },
  });

  useEffect(() => {
    if (selectedSubtriage?.id) {
      setFilterContext({
        loading: true,
      });
      getBySubtriageId({
        variables: {
          condominiumPersonContextId,
          subtriageId: selectedSubtriage?.id,
        },
      });
    } else if (eventId && condominiumPersonContextId) {
      setFilterContext({
        loading: true,
      });
      getByEventId({
        variables: {
          condominiumPersonContextId,
          eventId,
        },
      });
    }
  }, [condominiumPersonContextId, selectedSubtriage, eventId]);
};

export default useQueries;
