import { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import * as yup from 'yup';
import { useMutation } from 'react-apollo';
import { apolloErrorHandler } from '@kiper/fns';
import { syncCondoByTransientPhoto } from '@kiper/monitoring-graphql/guided_attendance';
import { useSwal } from '@kiper/hooks';
import propTypes from 'prop-types';
import { AlertBar, MdIcons } from '@kiper/ui';
import { GuidedAttendanceContext } from '../../../store';
import { useGuidedAttendance } from '../../../hooks';
import StepStructure, { StepTextTypeColor } from '../StepStructure';

const StepMessageSyncTransientPerson = ({ event, eventId }) => {
  const { t } = useTranslation('guided-attendance');
  const { values, setFieldValue } = useFormikContext();
  const { handleNextAction } = useGuidedAttendance();
  const { toast } = useSwal();

  const { setContextTree } = useContext(GuidedAttendanceContext);

  const schema = yup.object({
    stepMessageSyncTransientPerson: yup.object({
      finishGuidedAttendance: yup.bool(),
    }),
  });

  const [syncCondo] = useMutation(syncCondoByTransientPhoto, {
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const nextAction = async stepData => {
    const nextStep = await handleNextAction({
      values: stepData,
      fieldValue: 'stepMessageSyncTransientPerson',
    });
    return { nextStep, currentStepData: stepData };
  };

  useEffect(() => {
    const { stepRegisterServiceProvider, stepFindRegister } = values;
    const transientPersonId =
      stepRegisterServiceProvider?.transientPersonId ||
      stepFindRegister?.transientPersonId;

    if (transientPersonId) {
      syncCondo({
        variables: {
          transientPersonId: String(transientPersonId),
          eventId,
          condominiumId: String(event?.condominium?.personContextId),
        },
      });
    }

    setFieldValue('stepMessageSyncTransientPerson', {
      finishGuidedAttendance: true,
    });

    setContextTree({
      formikSchema: schema,
      nextAction,
    });
  }, []);

  return (
    <StepStructure
      type={StepTextTypeColor.SPEAK}
      text={t('step-message-sync-transient-person.title', {
        name: values?.stepAttendanceGetName?.name,
      })}
      hasPreviousStepButton
      hasNextStepButton
    >
      <AlertBar
        width="100%"
        color="black"
        background="guidedAttendance.text.action"
        title={
          <Trans>{t('step-message-sync-transient-person.subtitle')}</Trans>
        }
        icon={<MdIcons mdIconName="info" size="20px" />}
      />
    </StepStructure>
  );
};

export default StepMessageSyncTransientPerson;

StepMessageSyncTransientPerson.propTypes = {
  event: propTypes.object.isRequired,
  eventId: propTypes.string.isRequired,
};
