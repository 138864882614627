import { useContext, useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { KiperButton } from '@kiper/ui';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

const Button = ({ children, onClick, hide }) => {
  const handleClick = () => {
    onClick();
  };

  return (
    <KiperButton onClick={() => handleClick()} hide={hide}>
      {children}
    </KiperButton>
  );
};

const PrevButton = () => {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleItemsWithoutSeparators,
    initComplete,
  } = useContext(VisibilityContext);

  const [hide, setHide] = useState(
    !initComplete || (initComplete && isFirstItemVisible),
  );
  useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setHide(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <KiperButton
      size="xs"
      icon={<MdKeyboardArrowLeft />}
      rounded
      variant="text"
      onClick={() => scrollPrev()}
      disabled={hide}
      direction="previous"
    />
  );
};

const NextButton = () => {
  const {
    isLastItemVisible,
    scrollNext,
    visibleItemsWithoutSeparators,
  } = useContext(VisibilityContext);

  const [hide, setHide] = useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible,
  );
  useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setHide(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return (
    <KiperButton
      size="xs"
      icon={<MdKeyboardArrowRight />}
      rounded
      variant="text"
      onClick={() => scrollNext()}
      disabled={hide}
      direction="next"
    />
  );
};

export { PrevButton, NextButton };

Button.propTypes = {
  children: propTypes.any.isRequired,
  onClick: propTypes.func.isRequired,
  hide: propTypes.bool.isRequired,
};
