import propTypes from 'prop-types';
import PlaceHolder from '../PlaceHolder';
import { LoaderContainer, Transparence } from './styles';

export default function TableLoader({ lines }) {
  return (
    <LoaderContainer>
      <Transparence />
      <PlaceHolder lines={lines} type="line" />
    </LoaderContainer>
  );
}

TableLoader.propTypes = {
  lines: propTypes.number,
};
TableLoader.defaultProps = {
  lines: 8,
};
