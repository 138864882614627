import propTypes from 'prop-types';
import { Entrance, Departure, NoEntry } from './GuestStatus';

const statusEnum = { null: NoEntry, '0': Entrance, '1': Departure };
const GuestStatus = ({ status }) => {
  const Component = statusEnum[status];
  return <Component />;
};

GuestStatus.propTypes = {
  status: propTypes.number,
};

GuestStatus.defaultProps = {
  status: null,
};

export default GuestStatus;
