import styled from 'styled-components';
import { Text } from '@kiper/ui';
import { FiFileText } from 'react-icons/fi';

export const FileIcon = styled(FiFileText)``;

export const Label = styled(Text)`
  display: block;
  white-space: break-spaces;
  word-break: break-word;
  line-break: auto;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400px;
  letter-spacing: 0.5px;
  line-height: 1.5;
  font-weight: 500;
  color: ${props => props.theme.colors.highBlack};
  padding-bottom: 70px;
`;
