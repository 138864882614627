import styled from 'styled-components';
import {
  Col as col,
  Row as row,
  Form as form,
  FormGroup as formGroup,
} from 'reactstrap';
import { Flex as flex } from '@kiper/ui';

export const Column = styled(col)``;
export const Flex = styled(flex)``;

export const Row = styled(row)``;

export const Form = styled(form)``;

export const ActionsRow = styled(row)`
  margin-top: 30px;
`;

export const FormGroup = styled(formGroup)`
  width: 100%;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;
