import currentLoggedContext from './context.query';
import setCurrentLoggedContext from './context.mutation';

export default {
  Query: {
    currentLoggedContext,
  },
  Mutation: {
    setCurrentLoggedContext,
  },
};
