import { MdArrowForward, MdArrowBack, MdDoNotDisturbOn } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Badge } from '@kiper/ui';

export const Entrance = () => {
  const [t] = useTranslation('invite');
  return (
    <Badge color="primary">
      <MdArrowForward size={15} />
      {t('guest.status.entrance')}
    </Badge>
  );
};

export const Departure = () => {
  const [t] = useTranslation('invite');
  return (
    <Badge color="info">
      <MdArrowBack size={15} />
      {t('guest.status.departure')}
    </Badge>
  );
};

export const NoEntry = () => {
  const [t] = useTranslation('invite');
  return (
    <Badge color="warning">
      <MdDoNotDisturbOn size={15} />
      {t('guest.status.noEntry')}
    </Badge>
  );
};
