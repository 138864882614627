import propTypes from 'prop-types';
import { useFeatureFlag } from '../services/feature-flag';

const useRemoteConfig = ({ path, partner, condominium, user, notAllowed }) => {
  const { flags, loading } = useFeatureFlag();
  const arr = path?.split('.');

  const getByPath = (obj, [prop, ...rest]) =>
    !rest.length ? obj?.[prop] : getByPath(obj?.[prop], rest);

  const contains = (values, id) => values?.some(i => i === id);

  const hasAccess = () => {
    const byPath = getByPath(flags, arr);

    const byPartners = byPath?.partners;
    const byCondominiums = byPath?.condominiums;
    const byUsers = byPath?.users;

    const containsPartner = contains(byPartners, partner);
    const containsCondominium = contains(byCondominiums, condominium);
    const containsUser = contains(byUsers, user);

    if (loading) return false;

    if (!byPartners?.length && !byCondominiums?.length && !byUsers?.length)
      return !notAllowed;

    if (byPartners !== null && !!containsPartner) return true;

    if (byCondominiums !== null && !!containsCondominium) return true;

    if (byUsers !== null && !!containsUser) return true;

    return false;
  };

  return hasAccess();
};

useRemoteConfig.propTypes = {
  path: propTypes.string.isRequired,
  partner: propTypes.number,
  condominium: propTypes.number,
  user: propTypes.number,
  notAllowed: propTypes.bool,
};

useRemoteConfig.defaultProps = {
  notAllowed: false,
};

export default useRemoteConfig;
