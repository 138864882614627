import styled, { css } from 'styled-components';
import { Col as col } from 'reactstrap';
import {
  Label as label,
  Input as input,
  DateTimePicker as dateTimePicker,
  ErrorMessage as errorMessage,
} from '@kiper/ui';
import PersonSelect from '../../../../components/PersonSelect';
import Profile from '../../../../components/ProfileSelect';
import EventCategory from '../EventCategorySelect';
import Place from '../../../../components/PlaceSelect';
import UnityPerson from '../../../../components/UnityPersonSelect';
import AccessProfile from '../AccessProfileSelect';

export const ErrorMessage = styled(errorMessage)``;

export const Col = styled(col)`
  padding: 0;
  min-width: ${props =>
    props.width ? `${props.width} !important` : '280px !important'};
  width: ${props => props?.width ?? '280px'};
`;

const selectCss = css`
  div:first-child,
  div:first-child > div {
    border-radius: 8px;
  }
`;

export const CondominiumSelect = styled(PersonSelect)`
  width: 100%;
  z-index: 9999999;
  ${selectCss}
`;

export const UnityPersonSelect = styled(UnityPerson)`
  ${selectCss}
`;

export const ProfileSelect = styled(Profile).attrs({
  styles: {
    valueContainer: (provided, state) => {
      if (state.hasValue)
        return {
          ...provided,
          maxHeight: '36px',
          overflow: 'auto',
        };

      return provided;
    },
  },
})`
  width: 100%;
  ${selectCss}
`;

export const AccessProfileSelect = styled(AccessProfile).attrs({
  styles: {
    valueContainer: (provided, state) => {
      if (state.hasValue)
        return {
          ...provided,
          maxHeight: '36px',
          overflow: 'auto',
        };

      return provided;
    },
  },
})`
  width: 100%;
  ${selectCss}
`;

export const EventCategorySelect = styled(EventCategory).attrs({
  styles: {
    valueContainer: (provided, state) => {
      if (state.hasValue)
        return {
          ...provided,
          maxHeight: '36px',
          overflow: 'auto',
        };

      return provided;
    },
  },
})`
  ${selectCss}
`;

export const PlaceSelect = styled(Place)`
  width: 100%;
  ${selectCss}
`;

export const Label = styled(label)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
  color: ${props => props.theme.colors.mediumHighBlack};
  margin-bottom: 5px !important;
  justify-content: unset !important;
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

export const DatePeriodWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  margin: 0;
`;

export const DateTimePicker = styled(dateTimePicker)`
  gap: 5px;

  div,
  div > input {
    border-radius: 8px;
  }
`;

export const Input = styled(input)`
  width: 100% !important;
  border-radius: 8px;
`;
