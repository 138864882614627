import styled, { css } from 'styled-components';
import { Card as card, Badge as badge } from '@kiper/ui';
import { FiLoader as loaderIcon } from 'react-icons/fi';
import { MdErrorOutline as errorIcon } from 'react-icons/md';
import { Row as row, Col as col } from 'reactstrap';

const hideWhenPrint = `
  @media print {
    display: none;
    height: 0;
  }
`;

const fontSize = css`
  @media print {
    font-size: 10px;
  }
  @media screen {
    font-size: 14px;
  }
`;

export const Tr = styled.tr`
  ${props =>
    !props.last
      ? css`
          border-bottom: 1px solid ${props.theme.colors.secondary100};
        `
      : css`
          border-bottom: 0;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        `}
`;

export const Td = styled.td`
  max-width: 0;
  padding: 5px 10px;
`;

export const DataContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const ColumnData = styled.span`
  ${fontSize};
  max-width: 100%;
  overflow-wrap: break-word;
  color: ${props => props.theme.colors.highBlack};
  ${props =>
    props.link &&
    css`
      text-decoration: underline;
    `}
`;

export const Badge = styled(badge)`
  ${hideWhenPrint};
`;

export const ProfileData = styled(ColumnData)`
  @media screen {
    display: none;
  }
`;

export const Card = styled(card)`
  border: 1px solid ${props => props.theme.colors.secondary100};
  @media print {
    padding: 0;
  }
`;

export const LoaderIcon = styled(loaderIcon)`
  font-size: 60px;
`;

export const ErrorIcon = styled(errorIcon)`
  font-size: 60px;
`;

export const HeaderContainer = styled.div`
  @media screen {
    display: none;
    height: 0;
  }
`;

export const Row = styled(row)``;

export const Col = styled(col)``;

export const Label = styled.span`
  ${props =>
    props.bold
      ? css`
          font-weight: 700;
        `
      : null};
  font-size: ${props => props.fontSize ?? 10}px;
  margin: 0;
`;

export const Ul = styled.ul``;

export const Li = styled.li`
  line-height: 1;
`;
