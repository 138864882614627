import { createPortal } from 'react-dom';
import propTypes from 'prop-types';
import { Flex, Modal, Button, Text } from '@kiper/ui';
import { MdOutlineWarningAmber } from 'react-icons/md';
import { Trans } from 'react-i18next';

const ConfirmResyncDevices = ({ onToggle, open, loading, onConfirm, t }) => {
  const modal = (
    <Modal
      title={t('details.resync-modal-title')}
      open
      toggle={onToggle}
      size="md"
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gridGap="24px"
        pt="16px"
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gridGap="16px"
        >
          <MdOutlineWarningAmber color="#DC3545" size={48} />
          <Text fontWeight="400" fontSize="14px">
            <Trans>{t('details.resync-confirm-modal-message')}</Trans>
          </Text>
        </Flex>
        <Flex justifyContent="flex-end" gridGap="16px" width="100%">
          <Button
            type="button"
            color="neutral"
            variant="outlined"
            onClick={onToggle}
          >
            {t('buttons:cancel')}
          </Button>
          <Button
            onClick={onConfirm}
            color="danger"
            variant="contained"
            disabled={loading}
          >
            {t('details.resync-modal-confirm')}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );

  return open ? createPortal(modal, document.getElementById('root')) : null;
};

ConfirmResyncDevices.propTypes = {
  onToggle: propTypes.func.isRequired,
  onConfirm: propTypes.func.isRequired,
  t: propTypes.func.isRequired,
  open: propTypes.bool.isRequired,
  loading: propTypes.bool.isRequired,
};

export default ConfirmResyncDevices;
