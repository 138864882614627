import styled from 'styled-components';
import { Col as col } from 'reactstrap';
import {
  Flex,
  Nav as nav,
  Text as text,
  NavItem as navItem,
  NavLink as navLink,
} from '@kiper/ui';
import { FiUsers, FiVideo } from 'react-icons/fi';

export const CommandsIcon = styled(FiVideo)`
  width: 16px;
  height: 16px;
  color: ${props => props.active && props.theme.colors.mediumBlack};
  margin-bottom: 0 !important;
`;

export const DwellersIcon = styled(FiUsers)`
  width: 16px;
  height: 16px;
  color: ${props => props.active && props.theme.colors.mediumBlack};
`;

export const Col = styled(col)``;

export const DeviceContainer = styled(col)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  margin-left: 5px;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.whiteSurface};
  border: ${props => `1px ${props.theme.colors.secondary300} solid`};

  img {
    margin-bottom: 10px;
    width: 33.3px;
    height: 66px;
  }
`;

export const NavContainer = styled(Flex)`
  position: sticky;
  top: 0;
  z-index: 2;
  background: ${props => props.theme.colors.white};
`;

export const NavLink = styled(navLink)`
  padding-bottom: 5px !important;
  align-items: center;
`;

export const Nav = styled(nav)`
  border-bottom: none;
  box-shadow: 0px 3px 3px ${props => props.theme.colors.secondary300};
`;

export const NavItem = styled(navItem)`
  padding-left: 6px;
`;

export const Text = styled(text)`
  font-size: 16px;
  line-height: 20px;
  margin-left: 6px;
  color: ${props => props.active && props.theme.colors.mediumBlack};
`;
