import { Flex, Input, KiperButton, Modal } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import propTypes from 'prop-types';

const RegisterGuestListModal = ({ values, setFieldValue, onToggle }) => {
  const { t } = useTranslation('user');

  const tKey = sufix => `put.access-data.expected-guest.${sufix}`;

  const [names, setNames] = useState('');

  const handleTextAreaChange = eventOrValue => {
    const value = eventOrValue?.target
      ? eventOrValue.target.value
      : eventOrValue;
    setNames(value);
  };

  const handleRegister = () => {
    const nameList = names.split('\n').filter(name => name.trim() !== '');

    if (nameList.length === 0) {
      return;
    }

    const sortedNewGuests = nameList
      .map(name => ({
        name: name.trim(),
        document: '',
        phone: '',
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    const existingGuests = [...values.guests];

    if (existingGuests[0]?.name?.trim() === '') {
      existingGuests[0] = sortedNewGuests.shift();
    }

    const allGuests = [...existingGuests, ...sortedNewGuests].sort((a, b) =>
      a.name.localeCompare(b.name),
    );

    setFieldValue('guests', allGuests);

    setNames('');
    onToggle();
  };

  return (
    <Modal open toggle={onToggle} size="md">
      <Flex flexDirection="column">
        <Input
          type="textarea"
          value={names}
          onChange={(_, value) => handleTextAreaChange(value)}
          placeholder={t(tKey('guest.add-guest-list-placeholder'))}
          rows={15}
        />
        <Flex mt="16px" justifyContent="space-between">
          <KiperButton variant="outlined" onClick={onToggle}>
            {t(tKey('cancel'))}
          </KiperButton>
          <KiperButton onClick={handleRegister}>
            {t(tKey('guest.register-name'))}
          </KiperButton>
        </Flex>
      </Flex>
    </Modal>
  );
};

RegisterGuestListModal.propTypes = {
  values: propTypes.object.isRequired,
  setFieldValue: propTypes.func.isRequired,
  onToggle: propTypes.func.isRequired,
};

export default RegisterGuestListModal;
