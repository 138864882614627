import styled from 'styled-components';

import { Text, KiperButton } from '@kiper/ui';

export const Container = styled.div`
  z-index: 999999;
  display: flex;
  position: absolute;
  padding: 16px 8px;
  background-color: ${props => props.theme.colors.secondary900};
  width: 234px;
  height: 126px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  bottom: 8px;
  right: 8px;
  flex-direction: column;
`;

export const Title = styled(Text)`
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.25px;
  align-items: center;
  font-size: 16px;
  font-style: Lato;
  color: ${props => props.theme.colors.white};
  margin-bottom: 8px;
`;

export const Subtitle = styled(Text)`
  font-weight: 400;
  line-height: 18px;
  align-items: center;
  font-size: 12px;
  color: ${props => props.theme.colors.mediumWhite};
  margin-bottom: 16px;
`;

export const Button = styled(KiperButton)`
  width: auto;
  padding-left: 14px;
  padding-right: 14px;

  span {
    font-size: 12px;
  }
  & + & {
    margin-left: 8px;
  }
`;
