import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  compare,
  isValidDate,
  addDaysFns,
  formatDateTime,
  setTime,
  toLocaleDateString,
} from '@kiper/fns';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Row } from './styles';
import ReportForm from './Form';
import ReportFilter from './ReportFilter';

const EventsReportForm = ({
  reportFilters,
  handleSubmit,
  setXlsFileName,
  setCsvFileName,
  loading,
  type,
  setHeaderFilters,
}) => {
  const [t] = useTranslation('report');
  const [isInvalidRange, setInvalidRange] = useState(false);
  const [showAdvanced, expandAdvanced] = useState(false);
  const [dates, setDates] = useState({
    initDate: '',
    endDate: '',
  });

  const isValidDateRange = () => {
    const { initDate, endDate } = dates;
    const { isAfter } = compare(new Date(initDate), new Date(endDate));
    return !isAfter;
  };

  const getDateFormatted = date => {
    if (!date) return '';

    return formatDateTime(date, 'YYYY-MM-DD HH:mm');
  };

  const formikBag = useFormik({
    initialValues: {
      condominiumTreeNodeId: null,
      initDate: '',
      endDate: '',
    },
    validationSchema: yup.object({
      condominiumTreeNodeId: yup.number().required(),
      initDate: yup
        .string()
        .test({ test: val => val && isValidDate(new Date(val)) })
        .required(),
      endDate: yup
        .string()
        .test({ test: val => val && isValidDate(new Date(val)) })
        .required(),
    }),
    onSubmit: handleSubmit,
  });

  const { values, setFieldValue, setValues, setStatus, errors } = formikBag;

  const setDateValue = ({ name, value }) => {
    setHeaderFilters(x => ({ ...x, [name]: toLocaleDateString(value) }));
    setDates({ ...dates, [name]: value });
    setFieldValue([name], getDateFormatted(value));
  };

  const clearValues = () => {
    const final = setTime(new Date(), { hours: 23, minutes: 59 });
    const initial = setTime(addDaysFns(final, -15), { hours: 0, minutes: 0 });

    const datesObj = {
      initDate: new Date(initial),
      endDate: new Date(final),
    };

    setDates(datesObj);

    setValues({
      initDate: getDateFormatted(datesObj.initDate),
      endDate: getDateFormatted(datesObj.endDate),
    });
  };

  useEffect(() => {
    let valid = true;

    const validDates = Object.values(dates).every(date =>
      isValidDate(new Date(date)),
    );

    if (validDates) valid = isValidDateRange();

    setInvalidRange(!valid);

    const newStatus = !valid
      ? { dates: t('details.feedback-invalid-range') }
      : null;

    setStatus(newStatus);
  }, [dates, errors]);

  useEffect(() => {
    const { initDate, endDate } = values;

    const initDateLabel = initDate?.replaceAll('/', '-');
    const endDateLabel = endDate?.replaceAll('/', '-');

    setXlsFileName(`${type} - ${initDateLabel}_${endDateLabel}.xlsx`);
    setCsvFileName(`${type} - ${initDateLabel}_${endDateLabel}.csv`);
  }, [values]);

  const hasAdvanced = useMemo(
    () => reportFilters?.some(({ isAdvanced }) => isAdvanced),
    [reportFilters],
  );

  const filters = useMemo(() => {
    if (!reportFilters?.length) return [];

    let newFilters = reportFilters;

    const hasInitDate = reportFilters.find(({ key }) => key === 'initDate');
    const hasEndDate = reportFilters.some(({ key }) => key === 'endDate');

    if (hasInitDate && hasEndDate) {
      newFilters = reportFilters.filter(
        ({ key }) => key !== 'initDate' && key !== 'endDate',
      );
      newFilters.push({ ...hasInitDate, key: 'initDate-endDate' });
    }

    if (hasAdvanced && !showAdvanced) {
      return newFilters.filter(({ isAdvanced }) => !isAdvanced);
    }
    return newFilters;
  }, [reportFilters, showAdvanced]);

  useEffect(() => {
    const initial = setTime(new Date(), { hours: 0, minutes: 0 });
    const final = setTime(new Date(), { hours: 23, minutes: 59 });

    const datesObj = {
      initDate: new Date(initial),
      endDate: new Date(final),
    };
    setFieldValue('initDate', getDateFormatted(initial));
    setFieldValue('endDate', getDateFormatted(final));

    setDates(datesObj);

    setHeaderFilters(x => ({
      ...x,
      initDate: toLocaleDateString(datesObj.initDate),
      endDate: toLocaleDateString(datesObj.endDate),
    }));
  }, []);

  return (
    <ReportForm
      formikBag={formikBag}
      resetValues={clearValues}
      loading={loading}
      expandAdvanced={() => expandAdvanced(!showAdvanced)}
      hasAdvanced={hasAdvanced}
      showAdvanced={showAdvanced}
      setHeaderFilters={setHeaderFilters}
    >
      <Row>
        {filters
          .sort((a, b) => a.sequence - b.sequence)
          .map(({ id, key }) => {
            if (key === 'initDate-endDate') {
              return (
                <ReportFilter
                  key={id}
                  type={key}
                  formProps={{
                    ...formikBag,
                    dates,
                    setDateValue,
                    isInvalidRange,
                  }}
                />
              );
            }
            return (
              <ReportFilter
                key={id}
                type={key}
                formProps={formikBag}
                formType={type}
                setHeaderFilters={setHeaderFilters}
              />
            );
          })}
      </Row>
    </ReportForm>
  );
};

EventsReportForm.propTypes = {
  reportFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      key: PropTypes.string,
      isRequired: PropTypes.bool,
      sequence: PropTypes.number,
    }),
  ).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setXlsFileName: PropTypes.func.isRequired,
  setCsvFileName: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  type: PropTypes.string.isRequired,
  setHeaderFilters: PropTypes.func.isRequired,
};

EventsReportForm.defaultProps = {
  loading: false,
};

export default EventsReportForm;
