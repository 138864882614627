import { useEffect, useMemo } from 'react';
import propTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { Label } from '@kiper/ui';
import { runtimeConfig } from '@kiper/fns';
import { dweller as dwellerGql } from '@kiper/monitoring-graphql';
import { useFilterSearchParams } from '@kiper/hooks';
import { profileTypes } from '../../constants';
import Select from './styles';

export default function ProfileSelect({
  isClearable,
  invalid,
  value,
  isEditable,
  isSearchFilter,
  onChange,
  filterLinkedFields,
  name,
  ...props
}) {
  const [t] = useTranslation('common');

  const { data, loading } = useQuery(
    dwellerGql.register.queries.dwellerAccessDataProfiles,
    {
      variables: {
        applicationKey: runtimeConfig.APPLICATION_KEY_APP,
      },
    },
  );

  const mapperProfileNames = profile => ({
    value: profile.value,
    label: t(`common:profiles.${profileTypes[profile.label]}`),
  });

  const { profileNamesOptions, accessDataProfiles } = useMemo(
    () => ({
      profileNamesOptions:
        data?.dwellerAccessDataProfiles.map(mapperProfileNames) || [],
      accessDataProfiles: data?.dwellerAccessDataProfiles || [],
    }),
    [data],
  );

  const {
    appendParam,
    removeParam,
    setSelectMultiValueFilterFromQuery,
  } = useFilterSearchParams({
    key: name,
    options: data?.dwellerAccessDataProfiles || [],
    filterLinkedFields,
    onChange: ({ queryValue }) => onChange(queryValue),
  });

  useEffect(() => {
    if (data && isSearchFilter) setSelectMultiValueFilterFromQuery();
  }, [data]);

  const profileTypeLabel = refLabel =>
    t(`common:profiles.${profileTypes[refLabel]}`);

  const normalizedSelectValue = useMemo(
    () =>
      Array.isArray(value)
        ? value.map(profileItem => ({
            ...profileItem,
            label: profileTypeLabel(profileItem?.label),
          }))
        : {
            ...value,
            label: profileTypeLabel(value?.label),
          },
    [value],
  );

  if (!isEditable)
    return <Label>{value && profileTypeLabel(value.label)}</Label>;

  const handleChange = eventData => {
    let profileTypeNameSelected;

    if (!eventData) {
      return onChange(null);
    }

    if (Array.isArray(eventData)) {
      if (eventData.length) {
        if (isSearchFilter) {
          const queryValue = eventData
            .map(item => String(item.value))
            .join(',');
          appendParam({
            queryValue,
          });
        }
        profileTypeNameSelected = accessDataProfiles.filter(profile =>
          eventData.find(eventItem => eventItem.value === profile.value),
        );
      } else {
        removeParam();
      }
    } else {
      profileTypeNameSelected = accessDataProfiles.find(
        profile => profile.value === eventData.value,
      );

      delete profileTypeNameSelected?.__typename;
    }

    return onChange(profileTypeNameSelected);
  };

  return (
    <Select
      {...props}
      value={value ? normalizedSelectValue : ''}
      onChange={handleChange}
      isClearable={isClearable}
      isLoading={loading}
      options={profileNamesOptions}
      invalid={Number(invalid)}
    />
  );
}

ProfileSelect.propTypes = {
  value: propTypes.any,
  isClearable: propTypes.bool,
  invalid: propTypes.bool,
  isEditable: propTypes.bool,
  onChange: propTypes.func.isRequired,
  isSearchFilter: propTypes.bool,
  name: propTypes.string,
  filterLinkedFields: propTypes.array,
};

ProfileSelect.defaultProps = {
  isClearable: false,
  invalid: false,
  isEditable: true,
  value: null,
  isSearchFilter: false,
  name: '',
  filterLinkedFields: [],
};
