import { useTranslation } from 'react-i18next';

const eventTypeVideoCall = 'videoCall';

const useEventGeneratorFields = values => {
  const [t] = useTranslation('event-generator');

  const eventFields = type => {
    const events = {
      102: [
        {
          label: t('event-field.extension-device'),
          name: 'deviceExtension',
          placeholder: t('event-field.extension-placeholder'),
          value: values.deviceExtension,
        },
      ],
      videoCall: [
        {
          label: t('event-field.sip-account-id'),
          name: 'sipAccountId',
          placeholder: t('event-field.sip-account-id-placeholder'),
          value: values.sipAccountId,
        },
      ],
    };

    return events?.[type] || null;
  };

  const handleGetEventFields = (eventType, videoCall) => {
    if (videoCall) return eventFields(eventTypeVideoCall);

    return eventFields(eventType);
  };

  const handleClearAnsweredCallFields = setFieldValue => {
    setFieldValue('deviceExtension', '');
    setFieldValue('sipAccountId', '');
  };

  const handleChangeVideoCall = (setVideoCall, setFieldValue) => {
    setVideoCall(call => !call);
    handleClearAnsweredCallFields(setFieldValue);
  };

  return {
    handleGetEventFields,
    handleChangeVideoCall,
  };
};

export default useEventGeneratorFields;
