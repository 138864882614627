import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import propTypes from 'prop-types';
import { useFormik } from 'formik';
import { useMutation } from 'react-apollo';
import { Form } from 'reactstrap';
import { useSwal } from '@kiper/hooks';
import { mutations } from '@kiper/monitoring-graphql/feedback';
import { apolloErrorHandler } from '@kiper/fns';
import { ErrorMessage, Flex, KiperButton, Modal } from '@kiper/ui';
import emojis from './emojis.json';
import SatisfactionRating from './SatisfactionRating';

import { Title, Textarea, Info } from './styles';
import useCurrentLoggedContext from '../../../hooks/useCurrentLoggedContext';

function FeedbackModal({ open, onToggle }) {
  const [t] = useTranslation('modals/feedback-modal');
  const { toast, dialog } = useSwal();
  const { loggedContext } = useCurrentLoggedContext();

  const [create, { loading }] = useMutation(mutations.newFeedback, {
    onCompleted: () => {
      onToggle();
      dialog({
        title: t('success.title'),
        text: t('success.text'),
        icon: 'success',
        confirmButtonText: t('success.confirmButton'),
      });
    },
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const onSubmit = values => {
    const { satisfactionRating, feedbackMessage } = values;
    const feedbackInput = {
      satisfactionRating,
      feedbackMessage,
      attendantPersonContextId: +loggedContext.personContextId,
    };

    create({
      variables: {
        feedbackInput,
      },
    });
  };

  const { values, setFieldValue, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      satisfactionRating: 0,
      feedbackMessage: '',
    },
    validationSchema: yup.object({
      satisfactionRating: yup
        .number()
        .test({
          message: t('validations.satisfactionRating'),
          test: x => x && x > 0,
        })
        .required(),
      feedbackMessage: yup
        .string()
        .test({
          message: t('validations.feedbackMessage'),
          test: x => x && x.length > 10,
        })
        .required(),
    }),
    onSubmit,
  });

  const handleChange = event => {
    setFieldValue(event.current.name, +event.current.value);
  };

  return (
    <Modal
      open={open}
      id="feedback-modal"
      data-cy="feedback-modal"
      title={t('title')}
      toggle={onToggle}
      size="md"
      data-testid="feedback-modal"
    >
      <Form data-testid="form" onSubmit={handleSubmit}>
        <Flex flexDirection="column">
          <Flex flexDirection="column" flex="1">
            <Title>{t('questions.satisfactionRating')}</Title>
            <Flex justifyContent="center">
              {emojis.map((x, i) => (
                <SatisfactionRating
                  key={i}
                  satisfactionValue={values.satisfactionRating}
                  emoji={x.emoji}
                  value={x.value}
                  handleChange={handleChange}
                />
              ))}
            </Flex>
            {!!touched?.satisfactionRating && !!errors?.satisfactionRating && (
              <ErrorMessage data-testid="satisfaction-rating-error">
                {errors.satisfactionRating}
              </ErrorMessage>
            )}
          </Flex>
          <Flex flexDirection="column" flex="1" my="12px">
            <Title>{t('questions.feedbackMessage')}</Title>
            <Textarea
              placeholder={t('questions.feedbackMessage-placeholder')}
              type="textarea"
              onChange={setFieldValue}
              name="feedbackMessage"
              value={values?.feedbackMessage || ''}
              rows={5}
              data-testid="feedback-message"
            />
            {!!touched?.feedbackMessage && !!errors?.feedbackMessage && (
              <ErrorMessage data-testid="feedback-message-error">
                {errors.feedbackMessage}
              </ErrorMessage>
            )}
          </Flex>
          <Flex bg="secondary100" p="16px" borderRadius="8px" mb="72px">
            <Info>
              <Trans>{t('description')}</Trans>
            </Info>
          </Flex>
          <Flex justifyContent="flex-end">
            <KiperButton
              color="neutral"
              className="mr-3"
              type="button"
              onClick={onToggle}
              variant="out"
              disabled={loading}
            >
              {t('buttons:cancel')}
            </KiperButton>
            <KiperButton
              type="submit"
              color="primary"
              data-testid="feedback-submit"
              disabled={loading}
            >
              {t('buttons:send')}
            </KiperButton>
          </Flex>
        </Flex>
      </Form>
    </Modal>
  );
}

export default FeedbackModal;

FeedbackModal.propTypes = {
  open: propTypes.bool.isRequired,
  onToggle: propTypes.func.isRequired,
};
