import { useMemo } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex, Modal } from '@kiper/ui';
import IotModalItem from './IotModalItem';

function IotModal({ event, onToggle }) {
  const { t } = useTranslation('modals/iot-modal');

  const iotDevices = useMemo(() => event?.iotDevices, [event]);

  const alarmDevices = useMemo(
    () => event?.alarmDevices?.filter(alarm => alarm.isOnline),
    [event],
  );

  return (
    <Modal title={t('title')} open toggle={onToggle} size="lg">
      <Flex borderRadius="8px" flexDirection="column">
        {!iotDevices.length && !alarmDevices.length && (
          <Flex alignItems="center" justifyContent="center">
            {t('iot-device-offline')}
          </Flex>
        )}
        {!!iotDevices.length &&
          iotDevices?.map(device => (
            <IotModalItem
              isOnline={device.isOnline}
              key={+device.id}
              deviceName={device.name}
              deviceId={+device.id}
              eventId={event?.eventId}
              type="IOT"
            />
          ))}
        {!!alarmDevices.length &&
          alarmDevices?.map(device => (
            <IotModalItem
              key={+device.id}
              deviceName={device.name}
              deviceId={+device.id}
              eventId={event?.eventId}
              type="ALARM"
            />
          ))}
      </Flex>
    </Modal>
  );
}

export default IotModal;

IotModal.propTypes = {
  onToggle: propTypes.func.isRequired,
  event: propTypes.any.isRequired,
};
