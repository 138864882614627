import { useEffect } from 'react';
import propTypes from 'prop-types';

const StepFallback = ({ eventId, history }) => {
  useEffect(() => {
    history.push(`/guided-attendance/${eventId}/fallback`);
  }, [eventId]);

  return null;
};

export default StepFallback;

StepFallback.propTypes = {
  history: propTypes.object.isRequired,
  eventId: propTypes.string.isRequired,
};
