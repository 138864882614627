import KiperSvg from './KiperSvg';
import { Wrapper, Spinner } from './styles';

const KiperSpinner = () => {
  return (
    <Wrapper>
      <Spinner>
        <div />
        <div />
      </Spinner>
      <KiperSvg />
    </Wrapper>
  );
};

export default KiperSpinner;
