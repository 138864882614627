import styled from 'styled-components';
import { Text, Flex, KiperButton } from '@kiper/ui';
import { FaRedhat } from 'react-icons/fa';
import { MdHeight } from 'react-icons/md';

export const Img = styled.img`
  margin-right: 16px;
`;

export const Subtitle = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: -0.03em;
  color: ${props => props.theme.colors.mediumHighBlack};
  margin-bottom: 40px;
  text-align: center;
`;

export const RuleText = styled(Text)`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  margin-left: 12px;
  color: ${props => props.theme.colors.mediumHighBlack};
`;

export const WidthIcon = styled(MdHeight)`
  transform: rotate(90deg);
`;

export const HatDisabledIcon = styled(FaRedhat)``;

export const HatDisabledContainer = styled(Flex)`
  padding: 6px;
  background-color: ${props => props.theme.colors.gray50};
  align-items: center;
  border-radius: 8px;
  &::before {
    content: '';
    position: absolute;
    border: 1px solid black;
    width: 31px;
    z-index: 9999;
    transform: rotate(45deg) translateY(2px);
  }
`;

export const FeedbackButton = styled(KiperButton)`
  width: 100%;
`;
