import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from 'react-apollo';
import { dwellers as dwellersQuery } from '@kiper/monitoring-graphql/dweller/list/query';
import { useTranslation } from 'react-i18next';
import {
  StyledSelect,
  OptionWrapper,
  Option,
  OptionDescription,
} from './styles';

const UnityPersonSelect = ({
  handleChange,
  value,
  sourceNodeId,
  hasPerson,
  ...props
}) => {
  const [t] = useTranslation('report');
  const [dataCollection, setDataCollection] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isFocused, setFocus] = useState(false);

  const [search, { loading }] = useLazyQuery(dwellersQuery, {
    fetchPolicy: 'network-only',
    onCompleted: data => setDataCollection(data?.dwellers?.collection ?? []),
  });

  const { groupedData, options } = useMemo(() => {
    if (!dataCollection?.length) return { groupedData: {}, options: [] };

    const dwellers = groupedData?.dwellers || [];
    const unityGroups = groupedData?.unityGroups || [];
    const unitys = groupedData?.unitys || [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < dataCollection.length; i++) {
      const unityGroup = dataCollection[i]?.unityGroup || {};
      const unity = dataCollection[i]?.unity || {};

      const unityGroupDesc = unityGroup?.name
        ? `${
            unityGroup?.placeTypeLabel ? `${unityGroup.placeTypeLabel}:` : ''
          } ${unityGroup.name}`
        : '';

      const unityDesc = unity?.name
        ? `${unity?.placeTypeLabel ? `${unity.placeTypeLabel}:` : ''} ${
            unity.name
          }`
        : '';

      if (hasPerson) {
        const dwellerDesc = dataCollection[i]?.profileName
          ? `${dataCollection[i].profileName}${
              unityDesc ? `, ${unityDesc}` : ''
            }${unityGroupDesc ? `, ${unityGroupDesc}` : ''}`
          : '';

        const hasDweller = dwellers.some(
          dweller => dweller.value === dataCollection[i]?.treeNodeId,
        );

        if (!hasDweller) {
          dwellers.push({
            value: dataCollection[i].treeNodeId,
            label: `${dataCollection[i].name}`,
            description: dwellerDesc,
          });
        }
      }

      if (unityGroup?.treeNodeId) {
        const hasUnityGroup = unityGroups.some(
          unitGroup => unitGroup.value === unityGroup.treeNodeId,
        );
        if (!hasUnityGroup)
          unityGroups.push({
            value: unityGroup.treeNodeId,
            label: unityGroupDesc,
          });
      }

      if (unity?.treeNodeId) {
        const hasUnity = unitys.some(unit => unit.value === unity.treeNodeId);
        if (!hasUnity)
          unitys.push({
            value: unity.treeNodeId,
            label: unityDesc,
            description: unityGroupDesc,
          });
      }
    }

    return {
      groupedData: {
        dwellers: { label: t('details.dweller'), options: dwellers },
        unityGroups: {
          label: t('details.unity-group'),
          options: unityGroups,
        },
        unitys: { label: t('details.unity'), options: unitys },
      },
      options: [...dwellers, ...unityGroups, ...unitys],
    };
  }, [dataCollection]);

  const handleSearch = searchText => {
    if (!searchText) setDataCollection([]);

    setSearchValue(searchText);

    if (searchText?.trim() && sourceNodeId) {
      search({
        variables: {
          filters: {
            searchText: searchText.trim() ?? '',
            sourceNodeId,
            ordination: false,
          },
        },
      });
    }
  };

  const formatOptionLabel = option => (
    <OptionWrapper>
      <Option>{option.label}</Option>
      {option?.description && (
        <OptionDescription>{option.description}</OptionDescription>
      )}
    </OptionWrapper>
  );

  const isOpen = useMemo(() => isFocused && searchValue?.trim(), [
    isFocused,
    searchValue,
  ]);

  return (
    <StyledSelect
      {...props}
      options={Object.values(groupedData)}
      value={value && options.find(i => i.value === value.value)}
      onChange={handleChange}
      inputValue={searchValue}
      onInputChange={handleSearch}
      delay={600}
      onFocus={() => setFocus(true)}
      menuIsOpen={isOpen}
      onBlur={() => {
        setFocus(false);
        setDataCollection([]);
      }}
      isClearable
      isLoading={loading}
      isDisabled={!sourceNodeId}
      formatOptionLabel={formatOptionLabel}
    />
  );
};

UnityPersonSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  sourceNodeId: PropTypes.number,
  hasPerson: PropTypes.bool,
};

UnityPersonSelect.defaultProps = {
  hasPerson: true,
  sourceNodeId: null,
  value: null,
};

export default UnityPersonSelect;
