import { useContext, useEffect } from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';
import { GuidedAttendanceContext, TriageContext } from '../../../../store';

const useForm = ({ nextAction, t }) => {
  const { setContextTree } = useContext(GuidedAttendanceContext);
  const { setTriageContext } = useContext(TriageContext);
  const {
    setFieldValue,
    handleSubmit,
    values,
    errors,
    touched,
  } = useFormikContext();

  const initialFilters = {
    unity: '',
    searchName: '',
    page: 1,
    pageSize: 5,
  };

  const schema = yup.object({
    stepAttendanceGetUnity: yup.object({
      filters: yup.object({
        unity: yup.object({ id: yup.number(), label: yup.string() }),
        searchName: yup.string(),
        page: yup.number(),
        pageSize: yup.number(),
      }),
      unity: yup
        .object({
          id: yup.number().required(t('common:feedback.required-field')),
          description: yup.string(),
          treeNodeId: yup.number(),
        })
        .required(t('common:feedback.required-field')),
      unityGroup: yup
        .object({
          id: yup.number(),
          description: yup.string(),
          treeNodeId: yup.number(),
        })
        .required(t('common:feedback.required-field')),
    }),
  });

  const handleSelect = option => {
    const unity = { ...option?.unity };
    const unityGroup = { ...option?.unity?.unityGroup };

    if (unity?.unityGroup) delete unity?.unityGroup;

    setTriageContext({
      filterGUUnit: {
        gu: {
          label: unityGroup?.description,
          treeNodeId: unityGroup?.treeNodeId,
        },
        label: unity?.description,
        treeNodeId: unity?.treeNodeId,
        parentName: unity?.unityGroup?.description,
        value: `${unity?.treeNodeId} ${unityGroup?.description} ${unity?.description}`,
      },
    });
    setFieldValue('stepAttendanceGetUnity.unity', unity);
    setFieldValue('stepAttendanceGetUnity.unityGroup', unityGroup);
  };

  useEffect(() => {
    const hasUnityDwellerSelected = !!values?.stepAttendanceGetUnity?.unity?.id;
    if (hasUnityDwellerSelected) handleSubmit();
  }, [values?.stepAttendanceGetUnity?.unity]);

  useEffect(() => {
    if (!values?.stepAttendanceGetUnity) {
      setFieldValue('stepAttendanceGetUnity', {
        unity: {},
        unityGroup: {},
        filters: initialFilters,
      });
    } else {
      setFieldValue('stepAttendanceGetUnity.unity', {});
      setFieldValue('stepAttendanceGetUnity.unityGroup', {});
    }

    setContextTree({
      formikSchema: schema,
      nextAction: async stepData => {
        const nextStep = await nextAction(stepData);
        return { nextStep, currentStepData: stepData };
      },
    });
  }, []);

  return { values, errors, touched, setFieldValue, handleSelect };
};

export default useForm;
