import { useGuidedAttendance } from '../../../../hooks';
import useMutations from './useMutations';

const useNextAction = ({ eventId }) => {
  const { handleNextAction } = useGuidedAttendance();
  const { handleInsertStepHistory } = useMutations();

  const nextAction = async stepData => {
    Promise.all([handleInsertStepHistory(stepData, eventId)]);

    return handleNextAction({
      values: stepData,
      fieldValue: 'stepGetSubtriage',
    });
  };

  return { nextAction };
};

export default useNextAction;
