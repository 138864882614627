import { useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import propTypes from 'prop-types';
import { ScrollBar, Flex } from '@kiper/ui';
import { useWindowDimensions } from '@kiper/hooks';
import { useTranslation } from 'react-i18next';
import { useDevices, useDeviceStatus } from '../../../../hooks';
import EventPlaces from '../EventPlaces';
import EventOnHold from '../EventOnHold';
import CondoMap from '../CondoMap';
import { Cameras } from '../Cameras';
import VehicularControlPanel from '../../../../components/VehicularControlPanel';
import { Col, DeviceContainer } from './styles';

const EventDetailsBody = ({ event, eventCameras, isInAttendance }) => {
  const { width } = useWindowDimensions();
  const [t] = useTranslation('event_attendance');
  const FULL_HD = width >= 1920;

  const {
    cameras,
    mainCamera,
    places,
    devices,
    interlocks,
    selectedPlace,
    setSelectedPlace,
    isDeviceLoading,
    isDeviceOpened,
    isDeviceKeepOpened,
    handleDevice,
  } = useDevices({
    event,
    newCameras: eventCameras,
  });

  const { deviceStatus } = useDeviceStatus({ places });

  useEffect(() => {
    if (event?.condominium?.personContext?.places?.length === 1)
      setSelectedPlace(event.condominium.personContext.places[0].id);
  }, [event]);

  const urlMap = event?.condominium?.personContext?.params?.find(
    x => x.name === 'urlMap',
  )?.value;
  const theme = useContext(ThemeContext);
  return (
    <Flex
      overflow="auto"
      bg="white"
      height="100%"
      flexDirection="column"
      borderTop={`2px solid ${theme.colors.gray100}`}
    >
      <Flex flexDirection="column" flex="1 1 0" minHeight="0">
        <EventPlaces
          places={places}
          selectedPlace={selectedPlace}
          setSelectedPlace={setSelectedPlace}
        />
        <ScrollBar>
          {event?.onHold && <EventOnHold event={event} />}
          <Flex>
            {FULL_HD && urlMap && (
              <CondoMap
                urlMap={urlMap}
                places={places}
                selectedPlace={selectedPlace}
                setSelectedPlace={setSelectedPlace}
                event={event}
                deviceStatus={deviceStatus}
              />
            )}
            <Cameras
              t={t}
              event={event}
              cameras={cameras}
              mainCamera={mainCamera}
              places={places}
              devices={devices}
              interlocks={interlocks}
              selectedPlace={selectedPlace}
              setSelectedPlace={setSelectedPlace}
              handleDevice={handleDevice}
              isDeviceLoading={isDeviceLoading}
              isDeviceOpened={isDeviceOpened}
              isDeviceKeepOpened={isDeviceKeepOpened}
              disabledActions={!isInAttendance}
              screenWidth={width}
              deviceStatus={deviceStatus}
            />
          </Flex>
          {event.eventType === 176 && (
            <Flex py="10px" flexWrap="wrap">
              {(event?.personDevices ?? []).map(({ id }) => (
                <Col key={id} md={3}>
                  <DeviceContainer>
                    <VehicularControlPanel
                      deviceId={id}
                      transparent
                      disabled={!isInAttendance}
                    />
                  </DeviceContainer>
                </Col>
              ))}
            </Flex>
          )}
        </ScrollBar>
      </Flex>
    </Flex>
  );
};

export default EventDetailsBody;

EventDetailsBody.propTypes = {
  event: propTypes.object.isRequired,
  isInAttendance: propTypes.bool.isRequired,
  eventCameras: propTypes.array,
};

EventDetailsBody.defaultProps = {
  eventCameras: [],
};
