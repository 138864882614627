import propTypes from 'prop-types';
import { Flex } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

const CondoInfoAddress = ({ icon, address }) => {
  const [t] = useTranslation('address');

  return (
    <S.CondoInfoContainer>
      <span>{icon}</span>
      <S.WrapperInfo>
        <Flex gridGap="4px">
          <strong>{t('city')}</strong>
          <S.InfoDescription>{address.city.name}</S.InfoDescription>
        </Flex>
        <Flex gridGap="4px">
          <strong>{t('neighborhood')}</strong>
          <S.InfoDescription>{address.district.name}</S.InfoDescription>
        </Flex>
        <Flex gridGap="4px">
          <strong>{t('street')}</strong>
          <S.InfoDescription>{address.streetName}</S.InfoDescription>
        </Flex>
        <Flex gridGap="4px">
          <strong>{t('number')}</strong>
          <S.InfoDescription>{address.addressNumber}</S.InfoDescription>
        </Flex>
      </S.WrapperInfo>
    </S.CondoInfoContainer>
  );
};

export default CondoInfoAddress;

CondoInfoAddress.propTypes = {
  icon: propTypes.element.isRequired,
  address: propTypes.object.isRequired,
};
