import styled from 'styled-components';

export const Block = styled.div`
  display: block;
  width: 100%;
`;

export const Flex = styled.div`
  flex: 1;
  display: flex;
  background-color: ${props => props.background || props.theme.colorContainer};
`;

export const TableContainer = styled.div`
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgba(72, 94, 144, 0.16);
`;

export const SpaceBetween = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;
