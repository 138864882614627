import { Flex, KiperRadioCustomButton } from '@kiper/ui';
import styled, { css } from 'styled-components';

export const OptionsContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  width: 100%;
`;

export const RadioButton = styled(KiperRadioCustomButton)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 24px 16px;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.colors.secondary800};
  transition: all 0.3s;

  ${props =>
    props.checked &&
    css`
      box-shadow: 0 0 0 1px ${props.theme.colors.primary500};
      background-color: ${props.theme.colors.primary20};
      border-color: ${props.theme.colors.primary500};
      color: ${props.theme.colors.primary500} !important;
      transition: all 0.3s;

      &:hover {
        background-color: ${props.theme.colors.primary50} !important;
      }
    `}
`;
