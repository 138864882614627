import { Flex } from '@kiper/ui';
import styled from 'styled-components';

export const Container = styled(Flex)`
  height: 100%;
  width: 100%;
`;

export const TableContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 4px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.tabBorderColor};
`;

export const ActionButton = styled.td`
  display: flex;
  justify-content: flex-end;
`;
