import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FiCheck } from 'react-icons/fi';
import { Flex, PlaceHolder, Text } from '@kiper/ui';
import { EventAttendanceContext } from '../../../store';
import { EmptyStateIcon } from '../../../components/EmptyStateIcon';
import EventCard from '../../../components/SimplifiedEventList/EventCard';
import { EventQueueContainer, Scrollbar } from './styles';

const loadingStyle = {
  height: '85px',
  width: '194px',
  margin: '8px',
  padding: '8px',
  borderRadius: '4px',
};

const EventOnHoldQueue = () => {
  const {
    eventOnHoldInAttendance,
    eventOnHoldQueue,
    loadingQueue,
  } = useContext(EventAttendanceContext);
  const [t] = useTranslation('event_attendance');
  return (
    <EventQueueContainer>
      {eventOnHoldInAttendance && (
        <EventCard event={eventOnHoldInAttendance} margin="8px" />
      )}

      <Flex m="12px 8px" height="34px" alignItems="center">
        <Text
          truncate
          fontWeight="bold"
          fontSize={14}
          lineHeight="20px"
          color="primary500"
        >
          {t('on-hold-queue')}
        </Text>
      </Flex>

      <Scrollbar>
        {loadingQueue && (
          <PlaceHolder style={loadingStyle} type="line" lines={5} />
        )}
        {!loadingQueue && !eventOnHoldQueue.length && (
          <EmptyStateIcon
            m="56px 8px 8px 8px"
            icon={FiCheck}
            title={t('no-events')}
            text={t('no-events-detailed')}
          />
        )}

        {!!eventOnHoldQueue.length &&
          eventOnHoldQueue.map(event => (
            <EventCard key={event.eventId} event={event} />
          ))}
      </Scrollbar>
    </EventQueueContainer>
  );
};

export default EventOnHoldQueue;
