import { useState, useEffect } from 'react';
import { firstBy } from 'thenby';
import replaceSpecialCharacters from 'replace-special-characters';

export default function useRulesFilters(rules = []) {
  const [filteredRules, setFilteredRules] = useState([]);
  const [textFilter, setTextFilter] = useState('');
  const [categoriesFilter, setCategoriesFilter] = useState([]);

  const textTreatment = text => replaceSpecialCharacters(text).toLowerCase();

  const checkTextFilter = ruleContent =>
    textTreatment(ruleContent).includes(
      textTreatment(textFilter).toLowerCase(),
    );

  const filter = () => {
    let newFilteredRules = [];

    if (categoriesFilter && categoriesFilter.length) {
      newFilteredRules = rules.filter(
        rule =>
          !!categoriesFilter.find(
            category => rule.condoRuleCategory.id === category.id,
          ),
      );
    } else {
      newFilteredRules = [...rules];
    }

    if (textFilter.length) {
      newFilteredRules = newFilteredRules.filter(
        rule =>
          checkTextFilter(rule.description) || checkTextFilter(rule.title),
      );
    }

    newFilteredRules = newFilteredRules.sort(
      firstBy((a, b) => a.condoRuleCategory.order - b.condoRuleCategory.order)
        .thenBy((a, b) => a.condoRuleCategory.id - b.condoRuleCategory.id)
        .thenBy('title', 'asc')
        .thenBy('description', 'asc'),
    );

    setFilteredRules(newFilteredRules);
  };

  useEffect(() => {
    filter();
  }, [categoriesFilter, textFilter]);

  useEffect(() => {
    setFilteredRules(rules);
  }, [rules]);

  return {
    filteredRules,
    textFilter,
    setTextFilter,
    categoriesFilter,
    setCategoriesFilter,
  };
}
