import { useEffect } from 'react';
import propTypes from 'prop-types';
import { useLazyQuery, useMutation, useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { apolloErrorHandler, removeProperty } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import {
  events as eventsGraphql,
  eventSettings as eventSettingsGraphql,
} from '@kiper/monitoring-graphql';
import EventSettingsPutPage from '@kiper/shared-pages/src/pages/EventSettings/Put';
import { Breadcrumb, Loader, Page, Alert, Flex } from '@kiper/ui';
import { FiAlertCircle } from 'react-icons/fi';
import useCurrentLoggedContext from '../../../hooks/useCurrentLoggedContext';

const queryArgs = {
  query: eventsGraphql.eventSelect,
  variables: {
    search: '',
  },
};

const updateEventSettingsCache = (cache, value) => {
  try {
    const { eventSettings } = cache.readQuery(queryArgs);
    const newEventSettings = [...eventSettings];

    const newEventSetting = { ...value };

    newEventSettings.unshift(newEventSetting);

    return cache.writeQuery({
      ...queryArgs,
      data: { eventSettings: newEventSettings },
    });
  } catch (err) {
    return err;
  }
};

const EventSettingsPut = ({ route, match, history }) => {
  const [t] = useTranslation('event-settings');
  const { isEditable = true, originContext, eventType = null } = history
    .location.state || {
    isEditable: true,
    originContext: null,
  };
  const { loggedContext } = useCurrentLoggedContext();

  const { toast, confirm } = useSwal();
  const { id } = match.params;

  const isEdition = !!id;

  const onError = err => {
    const formattedErrors = apolloErrorHandler(err);
    if (formattedErrors && formattedErrors.length) {
      toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
  };

  const [fetch, eventSettingsResult] = useLazyQuery(
    eventSettingsGraphql.eventSetting,
    {
      fetchPolicy: 'network-only',
      variables: { id },
      onError,
    },
  );

  useEffect(() => {
    if (isEdition) fetch();
  }, []);

  const eventsQueryResult = useQuery(eventsGraphql.eventSelect);

  const [create] = useMutation(eventSettingsGraphql.eventSettingCreate, {
    onCompleted: () => {
      toast.fire({ title: t('put.create-success'), icon: 'success' });
      setTimeout(
        () =>
          history.push({
            pathname: '/event-settings',
            state: { origin: originContext },
          }),
        600,
      );
    },
    onError,
    update: (cache, { data: { eventSettingCreate } }) =>
      updateEventSettingsCache(cache, eventSettingCreate),
  });

  const [update] = useMutation(eventSettingsGraphql.eventSettingUpdate, {
    onCompleted: () => {
      toast.fire({ title: t('put.update-success'), icon: 'success' });
      setTimeout(
        () =>
          history.push({
            pathname: '/event-settings',
            state: { origin: originContext },
          }),
        600,
      );
    },
    onError,
    update: (cache, { data: { eventSettingCreate } }) =>
      updateEventSettingsCache(cache, eventSettingCreate),
  });

  const [remove] = useMutation(eventSettingsGraphql.eventSettingRemove, {
    onCompleted: () => {
      toast.fire({ title: t('put.delete-success'), icon: 'success' });
      setTimeout(
        () =>
          history.push({
            pathname: '/event-settings',
            state: { origin: originContext },
          }),
        600,
      );
    },
    onError,
    update: cache => {
      try {
        const { eventSettings } = cache.readQuery(queryArgs);
        const modifiedEventSettings = [...eventSettings];

        const index = eventSettings.findIndex(x => x.id === id);
        if (index >= 0) modifiedEventSettings.splice(index, 1);

        return cache.writeQuery({
          ...queryArgs,
          data: { eventSettings: modifiedEventSettings },
        });
      } catch (err) {
        return err;
      }
    },
  });

  const getValuesFromSelect = (values, field) => {
    if (values[field]) {
      if (
        typeof values[field] === 'string' ||
        typeof values[field] === 'number'
      ) {
        return values[field];
      }
      return values[field].value;
    }
    return null;
  };

  const refineEntity = values => {
    return {
      ...values,
      closeEvent: getValuesFromSelect(values, 'closeEvent'),
      eventType: getValuesFromSelect(values, 'eventType'),

      personContextId: parseInt(
        getValuesFromSelect(values, 'personContextId') ||
          loggedContext?.partner?.personContextId,
        10,
      ),
    };
  };

  const handleSubmit = values => {
    return isEdition
      ? update({
          variables: {
            eventSetting: refineEntity(removeProperty(values, '__typename')),
          },
        })
      : create({
          variables: {
            eventSetting: refineEntity(values),
          },
        });
  };

  const handleDelete = async () => {
    const deleted = await confirm({
      title: t('put.modal.delete.title'),
      text: t('put.modal.delete.text'),
      cancelButtonText: t('buttons:cancel'),
      confirmButtonText: t('buttons:confirm'),
    });

    if (deleted) remove({ variables: { id } });
  };

  const isLoadingSetting =
    (isEdition && !eventSettingsResult.called) || eventSettingsResult.loading;

  const getValueContextId = value => {
    if (
      !value ||
      Number(loggedContext?.partner?.personContextId) === Number(value)
    ) {
      return {
        label: loggedContext?.partner?.name,
        value: loggedContext?.partner?.personContextId,
        contextId: loggedContext?.partner?.personContextId,
      };
    }
    return value;
  };

  const AlertInfo = ({ originObj }) => {
    const contextId =
      typeof originObj === 'number' || typeof originObj === 'string'
        ? originObj
        : originObj?.contextId;

    const isMyContext =
      Number(contextId) === Number(loggedContext?.partner?.personContextId);

    const textAlert = isMyContext
      ? t('put.alert.partner-info')
      : t('put.alert.condominium-info', { name: originObj?.label });

    return (
      <Alert
        background="warningPrimary"
        icon={FiAlertCircle}
        text={textAlert}
      />
    );
  };

  AlertInfo.propTypes = {
    originObj: propTypes.any.isRequired,
  };

  return (
    <Page>
      <Breadcrumb stack={route.breadcrumb} formatter={t} />
      <Flex justifyContent="space-between">
        <Page.Title>{t('put.title')}</Page.Title>
      </Flex>
      <hr />
      {!isEditable && (
        <Alert
          background="warningPrimary"
          icon={FiAlertCircle}
          text={t('put.alert.display-mode')}
          buttonText={t('list.custom-event')}
          buttonAction={() =>
            history.push({
              pathname: '/event-settings/create',
              state: { isEditable: true },
            })
          }
        />
      )}
      {eventsQueryResult.loading || isLoadingSetting ? (
        <Loader fullHeight />
      ) : (
        <EventSettingsPutPage
          t={t}
          onSubmit={handleSubmit}
          initialValue={
            isEdition ? eventSettingsResult.data.eventSetting : null
          }
          createEventType={eventType}
          events={eventsQueryResult.data.eventSelect}
          onDelete={isEdition ? handleDelete : null}
          cancelUrl="/event-settings"
          loggedContext={loggedContext}
          isEditable={isEditable}
          origin={originContext}
          AlertInfo={({ value }) => (
            <AlertInfo originObj={getValueContextId(value)} />
          )}
        />
      )}
    </Page>
  );
};

EventSettingsPut.propTypes = {
  route: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
};

export default EventSettingsPut;
