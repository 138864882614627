import { Label } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  InfoContainer,
  InfoIcon,
  InfoTitleContainer,
  InfoContent,
  FormGroup,
  CustomInput,
  HeaderContainer,
} from './styles';
import { useFeatureFlag } from '../../../../../../services/feature-flag';
import useCurrentLoggedContext from '../../../../../../hooks/useCurrentLoggedContext';
import { useRemoteConfig } from '../../../../../../hooks';

const FeaturesCheck = ({
  feature,
  t,
  index,
  handleChange,
  indexAccesses,
  isEditable,
}) => {
  const onChange = property => {
    const newFeature = {
      ...feature,
      [property]: !feature[property],
      [`${property}IsUpdated`]: !feature[`${property}IsUpdated`],
    };
    handleChange(newFeature, index);
  };

  return (
    <>
      <FormGroup>
        <Label info>{feature.name}</Label>
        {feature.ableToRead !== null && (
          <CustomInput
            type="checkbox"
            className="custom-switch"
            label={t('put.access-data.features.view')}
            name={`accesses[${indexAccesses}].permissions[${index}].ableToRead`}
            checked={feature.ableToRead}
            value={feature.ableToRead}
            disabled={Number(!feature.allowsEdit || !isEditable)}
            onChange={() => onChange('ableToRead')}
            id={`feature-switch-${feature.fieldName}-${indexAccesses}-read`}
          />
        )}
        {feature.ableToWrite !== null && (
          <CustomInput
            type="checkbox"
            className="custom-switch"
            label={t('put.access-data.features.edit')}
            name={`accesses[${indexAccesses}].permissions[${index}].ableToWrite`}
            checked={feature.ableToWrite}
            value={feature.ableToWrite}
            disabled={Number(!feature.allowsEdit) || !isEditable}
            onChange={() => onChange('ableToWrite')}
            id={`feature-switch-${feature.fieldName}-${indexAccesses}-write`}
          />
        )}
      </FormGroup>
    </>
  );
};

FeaturesCheck.propTypes = {
  feature: propTypes.object.isRequired,
  t: propTypes.func.isRequired,
  index: propTypes.number.isRequired,
  handleChange: propTypes.func.isRequired,
  indexAccesses: propTypes.number.isRequired,
  isEditable: propTypes.bool.isRequired,
};

export default function Features({
  values,
  handleChange,
  indexAccesses,
  isEditable,
}) {
  const [t] = useTranslation('user');
  const { loading: loadingFlags } = useFeatureFlag();
  const { loggedContext } = useCurrentLoggedContext();

  const onChange = (feature, index) => {
    const newPermissionFeature = [...values.permissions];
    newPermissionFeature.splice(index, 1, feature);
    handleChange('permissions', newPermissionFeature);
  };

  const hasFeatureAccess = fieldName =>
    useRemoteConfig({
      path: `users.form.features.${fieldName}`,
      partner: Number(loggedContext?.partner?.personContextId),
      condominium: values?.condominium?.personContextId,
    });

  return (
    <Container>
      <HeaderContainer>
        <Label style={{ fontSize: '14px' }} block>
          {t('put.access-data.features.title')}
        </Label>
        {isEditable && (
          <Label info block>
            {t('put.access-data.features.info')}
          </Label>
        )}
      </HeaderContainer>
      <Row>
        <Col xs={12} sm={12} md={5}>
          {values?.permissions?.map(
            (feature, index) =>
              !loadingFlags &&
              hasFeatureAccess(feature?.fieldName) && (
                <FeaturesCheck
                  key={index}
                  feature={feature}
                  t={t}
                  index={index}
                  handleChange={onChange}
                  values={values}
                  indexAccesses={indexAccesses}
                  isEditable={isEditable}
                />
              ),
          )}
        </Col>
        <Col xs={12} sm={12} md={7}>
          <InfoContainer>
            <InfoTitleContainer>
              <InfoIcon />
              <Label bolder style={{ margin: 0, fontSize: '14px' }}>
                {t('put.access-data.features.view-and-edit')}
              </Label>
            </InfoTitleContainer>
            <InfoContent>
              <Label style={{ fontSize: '14px' }}>
                {t('put.access-data.features.view-info')}
              </Label>
              <Label style={{ fontSize: '14px' }}>
                {t('put.access-data.features.edit-info')}
              </Label>
            </InfoContent>
          </InfoContainer>
        </Col>
      </Row>
    </Container>
  );
}

Features.propTypes = {
  values: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  indexAccesses: propTypes.number.isRequired,
  isEditable: propTypes.bool.isRequired,
};
