import styled, { css } from 'styled-components';

export const TableContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  width: 100%;

  ${props =>
    props.maxheight &&
    css`
      max-height: ${props.maxheight};
      overflow-y: auto;
      overflow-x: hidden;
    `}

  th {
    position: sticky;
    top: 0;
    background: ${props => props.theme.colors.secondary200};
    border-bottom: 2px solid ${props => props.theme.colors.secondary600};
    z-index: 1;
  }
`;
