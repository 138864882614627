import styled from 'styled-components';
import { FormGroup as formGroup, Form as form, Row } from 'reactstrap';
import { Flex, Label as label } from '@kiper/ui';

export const FormGroup = styled(formGroup)`
  margin-bottom: 12px;
`;

export const Form = styled(form)``;

export const Img = styled.img`
  width: 100%;
`;

export const FeedbackImg = styled.img`
  width: 50%;
`;

export const RowStyle = styled(Row)`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
`;

export const Label = styled(label)`
  margin-bottom: 0;
  color: ${props => props.theme.colors.blackShades(0.8)};
  cursor: inherit;
`;

export const HandStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  cursor: ${props => (props.hover ? 'pointer' : 'default')};

  .hand {
    fill: black;
  }

  ${props =>
    props.hover &&
    `
      :hover {
        color: ${props.theme.colors.primary500};
        background-color: transparent;
        .hand {
          opacity: 1;
          fill: ${props.theme.colors.primary500};
        }
      }
  `}
`;

export const HandWithFingerStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 12px;
  width: 220px;
  height: 270px;

  .click {
    cursor: pointer;
  }

  .reading {
    opacity: 0.5;
  }

  .invisible {
    visibility: hidden;
  }

  .bar {
    animation-name: moving;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }

  .pulse {
    fill: ${props => props.theme.colors.primary500};
    fill-opacity: 0.7;
    transform-origin: center center;
    animation-duration: 1s;
    animation-name: pulse;
    animation-iteration-count: infinite;
  }

  @keyframes pulse {
    from {
      stroke-opacity: 1;
      r: 0;
    }
    to {
      stroke-opacity: 0;
      r: 15;
    }
  }

  @keyframes moving {
    0% {
      y: 0%;
    }
    2% {
      y: 4%;
    }
    4% {
      y: 8%;
    }
    6% {
      y: 12%;
    }
    8% {
      y: 16%;
    }
    10% {
      y: 20%;
    }
    12% {
      y: 24%;
    }
    14% {
      y: 28%;
    }
    16% {
      y: 32%;
    }
    18% {
      y: 36%;
    }
    20% {
      y: 40%;
    }
    22% {
      y: 44%;
    }
    24% {
      y: 48%;
    }
    26% {
      y: 52%;
    }
    28% {
      y: 56%;
    }
    30% {
      y: 60%;
    }
    32% {
      y: 64%;
    }
    34% {
      y: 68%;
    }
    36% {
      y: 72%;
    }
    38% {
      y: 76%;
    }
    40% {
      y: 80%;
    }
    42% {
      y: 84%;
    }
    44% {
      y: 88%;
    }
    46% {
      y: 92%;
    }
    48% {
      y: 96%;
    }
    50% {
      y: 100%;
    }
    52% {
      y: 96%;
    }
    54% {
      y: 92%;
    }
    56% {
      y: 88%;
    }
    58% {
      y: 84%;
    }
    60% {
      y: 80%;
    }
    62% {
      y: 76%;
    }
    64% {
      y: 72%;
    }
    66% {
      y: 68%;
    }
    68% {
      y: 64%;
    }
    70% {
      y: 60%;
    }
    72% {
      y: 56%;
    }
    74% {
      y: 52%;
    }
    76% {
      y: 48%;
    }
    78% {
      y: 44%;
    }
    80% {
      y: 40%;
    }
    82% {
      y: 36%;
    }
    84% {
      y: 32%;
    }
    86% {
      y: 28%;
    }
    88% {
      y: 24%;
    }
    90% {
      y: 20%;
    }
    92% {
      y: 16%;
    }
    94% {
      y: 12%;
    }
    96% {
      y: 8%;
    }
    98% {
      y: 4%;
    }
    100% {
      y: 0%;
    }
  }
`;

export const Container = styled.div`
  position: relative;

  .highlight_path {
    fill: ${props => props.theme.colors.primary500};
  }

  .finger:hover {
    fill: ${props => props.theme.colors.stringGreenSurface};
    opacity: 0.8;
    fill-opacity: 0.5;
  }
`;

export const Tooltip = styled.div`
  position: absolute;
  top: -32px;
  left: 19%;
  padding: 3px;
  font-size: 12px;
  color: ${({ remove }) => (remove ? `red` : `white`)};
  width: 160px;
  background: ${props => props.theme.colors.mediumBlack};
  border: 1px solid white;
  border-radius: 4px;
  text-align: center;
  height: auto;
`;

export const PanicCheckArea = styled(Flex)`
  cursor: pointer;
`;
