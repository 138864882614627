import { useContext } from 'react';
import AnnounceKit from 'announcekit-react';
import propTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { Container, Text } from './styles';

const Announce = ({ label, user }) => {
  const theme = useContext(ThemeContext);

  return (
    <Container>
      <AnnounceKit
        widget="https://announcekit.app/widgets/v2/2RaIHC"
        user={{
          id: user?.personContextId,
          name: user?.name,
          email: user?.email,
        }}
        data={{
          partner: user?.partner?.name,
          role: user?.profileName,
        }}
      >
        <Text theme={theme}>{label}</Text>
      </AnnounceKit>
    </Container>
  );
};

Announce.propTypes = {
  label: propTypes.string.isRequired,
  user: propTypes.any,
};

Announce.defaultProps = {
  user: {},
};

export default Announce;
