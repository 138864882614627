import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Modal, Button, ErrorMessage, Input, Label } from '@kiper/ui';
import * as S from './styles';

export default function KeepOpenDoorReasonModal({
  onToggle,
  open,
  sendReason,
}) {
  const [t] = useTranslation('event_attendance');

  const schema = yup.object({
    reason: yup
      .string()
      .min(5, t('details.cameras.keep-open-command.reason-validation'))
      .required(t('common:feedback.required-field')),
  });

  const onSubmit = data => {
    sendReason(data);
  };

  const { values, setFieldValue, handleSubmit, touched, errors } = useFormik({
    validationSchema: schema,
    initialValues: {
      reason: '',
    },
    onSubmit,
  });

  return (
    <Modal
      open={open}
      toggle={onToggle}
      title={t('details.cameras.keep-open-command.modal-title')}
      size="md"
    >
      <S.Form onSubmit={handleSubmit} noValidate>
        <S.FormGroup>
          <S.Flex flexDirection="column" justifyContent="stretch" gridGap="8px">
            <Label size="16px" for="comments">
              {t('details.cameras.keep-open-command.label')}
            </Label>
            <Input
              type="textarea"
              id="reason"
              name="reason"
              placeholder={t('details.cameras.keep-open-command.placeholder')}
              rows={5}
              onChange={(key, value) => setFieldValue(key, value)}
              value={values.reason}
            />
            {touched.reason && !!errors.reason && (
              <ErrorMessage>{errors.reason}</ErrorMessage>
            )}
          </S.Flex>
        </S.FormGroup>
        <S.ActionsRow>
          <S.Column>
            <S.Actions>
              <Button
                color="secondary"
                className="mr-3"
                type="button"
                onClick={onToggle}
                outline
              >
                {t('buttons:cancel')}
              </Button>
              <Button type="submit" color="primary">
                {t('buttons:save')}
              </Button>
            </S.Actions>
          </S.Column>
        </S.ActionsRow>
      </S.Form>
    </Modal>
  );
}

KeepOpenDoorReasonModal.propTypes = {
  open: propTypes.bool.isRequired,
  onToggle: propTypes.func.isRequired,
  sendReason: propTypes.func.isRequired,
};
