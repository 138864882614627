import { useState, useMemo } from 'react';
import { sendAmplitudeData } from '../../../../../services/amplitude';
import { useCondominiumRemoteConfig } from '../../../../../hooks';
import { getCondominiumParam } from '../../../../../helpers';
import { condominiumParamsTypes, erpLicenses } from '../../../../../constants';

export const tabIds = Object.freeze({
  GENERAL_DATA: 'general-data',
  ATTENDANCE_SETTINGS: 'attendance-settings',
  APPLICATION_PARAMETERS: 'application-parameters',
  UNITY_CONFIGURATION: 'unity-configuration',
  ACTIVITY_MANAGEMENT: 'activity-management',
  SIP_CONFIGURATION: 'sip-configuration',
  CONDOMINIUM_RULES: 'condominium-rules',
  EXPORTATIONS: 'exportations',
});

export const useTabChange = (loggedContext, condominium, t, S) => {
  const [activeTab, setActiveTab] = useState('general-data');

  const { remoteConcierge, videoCall } = useMemo(() => {
    const filteredParams = getCondominiumParam({
      paramsList: condominium.params,
      paramName: condominiumParamsTypes.ERP_LICENSE,
    });

    if (!filteredParams.length) {
      return { remoteConcierge: false, videoCall: false };
    }

    const { value } = filteredParams[0];
    if (!value) {
      return { remoteConcierge: false, videoCall: false };
    }

    const { licenses } = JSON.parse(value);
    if (!licenses) {
      return { remoteConcierge: false, videoCall: false };
    }

    const remoteConcierge = licenses.some(
      ({ licenseType }) => licenseType == erpLicenses.remote_concierge,
    );
    const videoCall = licenses.some(
      ({ licenseType }) => licenseType == erpLicenses.video_call,
    );

    return { remoteConcierge, videoCall };
  }, [condominium]);

  const {
    showAttendanceSettings,
    showApplicationParameters,
    showActivityManagement,
    showVideoCallFeature,
  } = useCondominiumRemoteConfig(loggedContext, condominium);

  const tabs = [
    {
      title: t(`details.${tabIds.GENERAL_DATA}`),
      tabId: tabIds.GENERAL_DATA,
      showTab: true,
    },
    {
      title: t(`details.${tabIds.UNITY_CONFIGURATION}.title`),
      tabId: tabIds.UNITY_CONFIGURATION,
      showTab: true,
    },
    {
      title: t(`details.${tabIds.ATTENDANCE_SETTINGS}.title`),
      tabId: tabIds.ATTENDANCE_SETTINGS,
      showTab: showAttendanceSettings,
    },
    {
      title: t(`details.${tabIds.APPLICATION_PARAMETERS}.tab-title`),
      tabId: tabIds.APPLICATION_PARAMETERS,
      showTab: showApplicationParameters,
    },
    {
      title: t(`details.${tabIds.ACTIVITY_MANAGEMENT}.title`),
      tabId: tabIds.ACTIVITY_MANAGEMENT,
      showTab: showActivityManagement,
    },
    {
      title: t(`details.${tabIds.SIP_CONFIGURATION}.title`),
      tabId: tabIds.SIP_CONFIGURATION,
      showTab: showVideoCallFeature && (remoteConcierge || videoCall),
      icon: <S.NewTabIcon>{t(`details.new`)}</S.NewTabIcon>,
    },
    {
      title: t(`details.${tabIds.CONDOMINIUM_RULES}.tab-title`),
      tabId: tabIds.CONDOMINIUM_RULES,
      showTab: true,
    },
    {
      title: t(`details.${tabIds.EXPORTATIONS}.title`),
      tabId: tabIds.EXPORTATIONS,
      showTab: true,
      icon: <S.NewTabIcon>{t(`details.new`)}</S.NewTabIcon>,
    },
  ];

  const handleChangeTab = tab => {
    if (tab !== activeTab) {
      setActiveTab(tab);
      sendAmplitudeData(`change to condominium tab -> ${tab}`, {
        user: loggedContext?.email,
        condominium: condominium?.name,
        partner: loggedContext?.partner?.name,
      });
    }
  };

  return { activeTab, handleChangeTab, tabs };
};
