import { Fragment, useMemo } from 'react';
import propTypes from 'prop-types';
import { Flex } from '@kiper/ui';
import { LicenseBadges } from '../../../../../components/LicenseBadge';
import { erpLicenses } from '../../../../../constants';

export const HeaderTitle = ({ condominium, t }) => {
  const { licenseParams } = useMemo(() => {
    const noLicensesFound = { licenseParams: [] };
    if (!condominium?.params) return noLicensesFound;

    const filteredParams =
      condominium.params.filter(item => item.name === 'erpLicense') || [];

    if (filteredParams.length) {
      const { value } = filteredParams[0];
      return { licenseParams: JSON.parse(value) };
    }

    return noLicensesFound;
  }, [condominium]);

  if (!condominium) return null;

  return (
    <Flex alignItems="center" justifyContent="center" gridGap="8px">
      {condominium?.name || ''}
      {licenseParams?.licenses?.length ? (
        licenseParams.licenses.map(license => (
          <Fragment key={crypto.randomUUID()}>
            {license?.licenseType && (
              <LicenseBadges licenseName={license.licenseType} t={t} />
            )}
          </Fragment>
        ))
      ) : (
        <LicenseBadges licenseName={erpLicenses.nonexistent} t={t} />
      )}
    </Flex>
  );
};

HeaderTitle.propTypes = {
  condominium: propTypes.shape({
    name: propTypes.string,
    params: propTypes.arrayOf(
      propTypes.shape({
        name: propTypes.string,
        value: propTypes.string,
      }),
    ),
  }).isRequired,
  t: propTypes.func.isRequired,
};
