import { forwardRef, useEffect } from 'react';
import propTypes from 'prop-types';
import Player from './Player';

const MJPEGPlayer = forwardRef(
  ({ url, width, height, onLoad, onError, imgRef }, ref) => {
    useEffect(() => {
      if (!url) return null;
      const player = new Player(ref, url, imgRef, { onLoad, onError });
      player.start();

      return () => {
        player.stop();
      };
    }, [url]);

    return <canvas ref={ref} width={width} height={height} />;
  },
);

MJPEGPlayer.displayName = 'MJPEGPlayer';

export default MJPEGPlayer;

MJPEGPlayer.propTypes = {
  url: propTypes.string,
  width: propTypes.number,
  height: propTypes.number,
  onLoad: propTypes.func,
  onError: propTypes.func,
  imgRef: propTypes.object.isRequired,
};

MJPEGPlayer.defaultProps = {
  width: 300,
  url: null,
  height: 150,
  onLoad: null,
  onError: null,
};
