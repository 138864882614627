import propTypes from 'prop-types';
import {
  EmptyStateWrapper,
  EmptyStateTitle,
  EmptyStateDescription,
} from './styles';

const EmptyState = ({ title, description }) => (
  <EmptyStateWrapper>
    <EmptyStateTitle>{title}</EmptyStateTitle>
    <EmptyStateDescription>{description}</EmptyStateDescription>
  </EmptyStateWrapper>
);

EmptyState.propTypes = {
  title: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
};

export default EmptyState;
