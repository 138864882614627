import { useRef } from 'react';
import propTypes from 'prop-types';
import { Table } from '@kiper/ui';
import { DetailContainer, Label } from '../styles';
import { formatDateTime } from '../util';
import GuestStatus from '../../../GuestStatus';

const RowDetail = ({ data, t }) => {
  const columns = useRef([
    {
      name: t('guest-name'),
      align: 'left',
      width: '35%',
    },
    {
      name: t('event-start'),
      align: 'left',
      width: '25%',
    },
    {
      name: t('event-end'),
      align: 'left',
      width: '25%',
    },
    {
      name: t('status'),
      align: 'left',
      width: '15%',
    },
  ]);

  return (
    <DetailContainer colSpan="6">
      <Table columns={columns} striped sort={{}}>
        <tbody>
          {data?.inviteds?.map(item => (
            <tr key={item.id}>
              <td align="left">
                <Label block info>
                  {item?.name}
                </Label>
              </td>
              <td align="left">
                <Label block info>
                  {data?.start ? formatDateTime(data.start) : ''}
                </Label>
              </td>
              <td align="left">
                <Label block info>
                  {data?.end ? formatDateTime(data.end) : ''}
                </Label>
              </td>
              <td align="left">
                <GuestStatus status={item.accessStatus} />
                {/* <GuestStatusSelect
                  status={item.accessStatus}
                  onChange={console.log}
                /> */}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </DetailContainer>
  );
};

export default RowDetail;

RowDetail.propTypes = {
  data: propTypes.shape({
    start: propTypes.string,
    end: propTypes.string,
    inviteds: propTypes.arrayOf(
      propTypes.shape({
        id: propTypes.oneOfType([propTypes.number, propTypes.string]),
        accessStatus: propTypes.string,
      }),
    ),
  }).isRequired,
  t: propTypes.func.isRequired,
};
