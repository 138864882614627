import { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Badge, Text, Tooltip } from '@kiper/ui';
import { dialStatus } from '../../../../constants';

const VideocallStatus = ({ status, personId }) => {
  const [badge, setBadge] = useState({ color: '', text: '', tooltip: '' });
  const { t } = useTranslation('user');

  const getBadgeObject = () => {
    switch (status) {
      case dialStatus.NoAnswer:
        return {
          color: 'warning100',
          text: t('videocall-status.no-answer.text'),
          tooltip: t('videocall-status.no-answer.tooltip'),
        };
      case undefined:
        return {
          color: 'gray100',
          text: t('videocall-status.does-not-receive.text'),
          tooltip: t('videocall-status.does-not-receive.tooltip'),
        };
      default:
        return {
          color: 'danger100',
          text: t('videocall-status.connection-failure.text'),
          tooltip: t('videocall-status.connection-failure.tooltip'),
        };
    }
  };

  useEffect(() => {
    const badgeObject = getBadgeObject();
    setBadge(badgeObject);
  }, []);

  return (
    <>
      <Badge id={`videocallstatus-${status}-${personId}`} color={badge.color}>
        {badge.text}
      </Badge>
      <Tooltip
        placement="bottom"
        target={`videocallstatus-${status}-${personId}`}
      >
        <Trans>
          <Text>{badge.tooltip}</Text>
        </Trans>
      </Tooltip>
    </>
  );
};

export default VideocallStatus;

VideocallStatus.propTypes = {
  status: propTypes.number,
  personId: propTypes.number.isRequired,
};

VideocallStatus.defaultProps = {
  status: undefined,
};
