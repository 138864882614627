import styled from 'styled-components';
import { Select } from '@kiper/ui';

export const StyledSelect = styled(Select)`
  width: 100%;

  div:first-child {
    border-radius: 8px;
  }
`;

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const Option = styled.span`
  height: 16px;
`;

export const OptionDescription = styled.span`
  opacity: 0.6;
  height: 20px;
`;
