import { Button, Flex, Modal } from '@kiper/ui';
import propTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import faceExample from '../../../assets/images/sporadicFaceExample.png';
import InformationTopics from './InformationTopics';
import * as S from './styles';

const PREFIX = `details.attendance-settings.transient-person-settings.modal`;

const TransientPersonInformationModal = ({
  onToggle,
  open,
  transientPersonType,
}) => {
  const [t] = useTranslation('condominium');

  const translateParams = {
    transientPersonType: t(
      `details.attendance-settings.transient-person-settings.${transientPersonType}`,
    ),
    transientPersonTypePlural: t(
      `details.attendance-settings.transient-person-settings.${transientPersonType}s`,
    ),
  };

  return (
    <Modal
      open={open}
      id="sporadic-service-provider-information-modal"
      data-cy="sporadic-service-provider-information-modal"
      toggle={onToggle}
      size="lg"
    >
      <S.Container>
        <Flex width="100%" justifyContent="center">
          <img src={faceExample} alt="face-example" />
        </Flex>
        <Flex
          width="100%"
          justifyContent="center"
          flexDirection="column"
          gridGap="8px"
        >
          <S.Text weight="700" size="18px" align="center">
            {t(`${PREFIX}.title`, {
              transientPersonType: translateParams.transientPersonType,
            })}
          </S.Text>
          <Trans>
            <S.Text align="center">
              {t(`${PREFIX}.subtitle`, {
                ...translateParams,
              })}
            </S.Text>
          </Trans>
        </Flex>
        <Flex flexDirection="column" gridGap="10px">
          <S.Text weight="700" size="18px">
            {t(`${PREFIX}.title`)}
          </S.Text>
          <>
            <InformationTopics
              iconName="timer"
              title={t(
                `${PREFIX}.information-topics.temporary-and-flexible.title`,
              )}
              subtitle={t(
                `${PREFIX}.information-topics.temporary-and-flexible.subtitle`,
                {
                  transientPersonTypePlural:
                    translateParams.transientPersonTypePlural,
                },
              )}
            />
            <InformationTopics
              iconName="check"
              title={t(
                `${PREFIX}.information-topics.resident-authorization.title`,
              )}
              subtitle={t(
                `${PREFIX}.information-topics.resident-authorization.subtitle`,
                {
                  transientPersonTypePlural:
                    translateParams.transientPersonTypePlural,
                },
              )}
            />
            <InformationTopics
              iconName="familiar_face_and_zone"
              title={t(
                `${PREFIX}.information-topics.access-facial-recognition.title`,
              )}
              subtitle={t(
                `${PREFIX}.information-topics.access-facial-recognition.subtitle`,
                {
                  transientPersonType: translateParams.transientPersonType,
                },
              )}
            />
          </>
        </Flex>
        <Flex width="100%">
          <Button block centered onClick={onToggle}>
            {t('buttons:close')}
          </Button>
        </Flex>
      </S.Container>
    </Modal>
  );
};

export default TransientPersonInformationModal;

TransientPersonInformationModal.propTypes = {
  onToggle: propTypes.func.isRequired,
  open: propTypes.bool.isRequired,
  transientPersonType: propTypes.string.isRequired,
};
