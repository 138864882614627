import propTypes from 'prop-types';
import { GlobalStyle, ScrollArea } from './styles';
import { PrevButton, NextButton } from './Actions';

const Area = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <ScrollArea LeftArrow={PrevButton} RightArrow={NextButton}>
        {children}
      </ScrollArea>
    </>
  );
};

export default Area;

Area.propTypes = {
  children: propTypes.any.isRequired,
};
