import useReduceRemoteConfigFlags from './useReduceRemoteConfigFlags';

const AttendancePaths = {
  ALARM_PARTITION_COMMANDS: 'attendance.showAlarmPartitionCommands',
  SHOW_TECHNICAL_REVIEW_FEATURE: 'attendance.showTechnicalReviewFeature',
  QUICK_FACE_REGISTRATION: 'attendance.quickFaceRegistration',
  ENABLE_EVENT_AUTOMATIC_CATCH: 'attendance.eventAutomaticCatch',
  SHOW_EVENT_V2: 'attendance.eventV2',
  SHOW_EVENT_TRIAGE: 'attendance.eventTriage',
};

export const useAttendanceRemoteConfig = (
  loggedContext,
  condominium = null,
) => {
  const remoteConfig = useReduceRemoteConfigFlags(
    loggedContext,
    AttendancePaths,
    condominium,
  );

  const showAlarmPartitionCommands =
    remoteConfig[AttendancePaths.ALARM_PARTITION_COMMANDS];
  const showTechnicalReviewFeature =
    remoteConfig[AttendancePaths.SHOW_TECHNICAL_REVIEW_FEATURE];
  const enableQuickFaceRegistration =
    remoteConfig[AttendancePaths.QUICK_FACE_REGISTRATION];
  const enableEventAutomaticCatch =
    remoteConfig[AttendancePaths.ENABLE_EVENT_AUTOMATIC_CATCH];
  const showEventV2 = remoteConfig[AttendancePaths.SHOW_EVENT_V2];
  const showEventTriage = remoteConfig[AttendancePaths.SHOW_EVENT_TRIAGE];

  return {
    showAlarmPartitionCommands,
    showTechnicalReviewFeature,
    enableQuickFaceRegistration,
    enableEventAutomaticCatch,
    showEventV2,
    showEventTriage,
  };
};
