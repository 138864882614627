import { Flex } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import emptyStateImage from '../../../../../../assets/images/empty-state-map.svg';

const CondoMapEmptyState = () => {
  const { t } = useTranslation('guided-attendance');

  return (
    <S.EmptyStateContainer>
      <S.Img src={emptyStateImage} alt="empty-state-map" />
      <Flex flexDirection="column" gridGap="12px">
        <S.Title>{t('attendance-information.map.empty-state.title')}</S.Title>
        <S.Text>{t('attendance-information.map.empty-state.text')}</S.Text>
      </Flex>
    </S.EmptyStateContainer>
  );
};

export default CondoMapEmptyState;
