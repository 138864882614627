import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { useFormikContext, FieldArray } from 'formik';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { removeProperties, apolloDataErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import AddMore from '../AddMore';
import Device from './DeviceData';
import { ItemContainer, Item } from './styles';
import { AddMoreContainer, DeviceContainer } from '../styles';
import PutUserModalDevice from '../../../../components/PutUserDeviceModal';
import accessTypesDict from '../../../../constants';
import { GadgetInfoDetailsModal } from '../../../../components/Modals/GadgetInfoDetailsModal';

const Devices = ({ isEdition, editMode, refetch }) => {
  const [modalDevice, setModalDevice] = useState(false);
  const [t] = useTranslation('user');
  const [deviceToEdit, setDeviceToEdit] = useState(null);
  const [existDevice, setExistDevice] = useState(false);
  const { values: fatherValues, setFieldValue } = useFormikContext();
  const [gadgetInfoDetails, setGadgetInfoDetails] = useState(false);
  const [gadgetInfo, setGadgetInfo] = useState({});

  const handleError = (err, translation) => {
    const { toast, linkToast } = useSwal();

    const formattedErrors = apolloDataErrorHandler(err);

    if (formattedErrors?.specificities) {
      linkToast({
        title: formattedErrors.message,
        icon: 'error',
        linkLabel: formattedErrors.specificities.personName,
        href: `/users/${formattedErrors.specificities.personId}`,
        timer: 5000,
      });
    } else if (formattedErrors && formattedErrors.length) {
      toast.fire({
        title: formattedErrors
          .reduce((errors, current) => {
            return [...errors, translation(current) || current];
          }, [])
          .join('\n'),
        icon: 'error',
      });
    }
  };

  const [create, { loading: createLoading }] = useMutation(
    Devices.deviceCreate,
    {
      fetchPolicy: 'no-cache',
      onError: err => handleError(err, t),
    },
  );

  const [edit, { loading: editLoading }] = useMutation(Devices.deviceUpdate, {
    fetchPolicy: 'no-cache',
    onError: err => handleError(err, t),
  });

  const [checkDeviceExistMutation, { loading: checkLoading }] = useMutation(
    Devices.checkDeviceExist,
    {
      onCompleted: async ({ checkDeviceExist }) => {
        if (checkDeviceExist) setExistDevice(true);
      },
      onError: err => handleError(err, t),
    },
  );

  const loading = createLoading || editLoading || checkLoading;

  const checkDeviceAvailability = async device => {
    if (device.id) return true;

    const deviceExist = await checkDeviceExistMutation({
      variables: {
        deviceId: device.value,
        type: device.type,
      },
    });

    return !deviceExist.data.checkDeviceExist;
  };

  const onSubmit = async values => {
    let device = removeProperties({ ...values }, [
      '__typename',
      'lastCounter',
      'lastValue',
      'isEdit',
      'lastAccessDateTime',
      'lastAcessPlace',
      'facialStatus',
    ]);

    if (!(await checkDeviceAvailability(device))) return;

    const { devices } = fatherValues;
    const { isEdit } = values;

    if (fatherValues.personId) {
      const mutationName = device.id ? 'deviceUpdate' : 'deviceCreate';
      const save = mutationName === 'deviceUpdate' ? edit : create;

      const personId = Number(fatherValues.personId);

      if (
        device?.type === accessTypesDict.rf ||
        device?.type === accessTypesDict.tag
      ) {
        delete device?.finger;
        delete device?.fingerIndex;
        delete device?.panic;
        delete device?.mobile;
      }

      const saved = await save({ variables: { personId, device } });
      device = { ...saved.data[mutationName], lastCounter: values.lastCounter };
    }

    if (isEdit) {
      const index = devices.findIndex(
        x => x.value === values.lastValue && x.type === device.type,
      );
      const modifiedDevice = [...devices];
      modifiedDevice[index] = device;
      setFieldValue('devices', modifiedDevice);
    } else {
      setFieldValue('devices', [...devices, device]);
    }

    setModalDevice(false);
    setDeviceToEdit(null);
    setExistDevice(false);
  };

  const editDevice = device => {
    setDeviceToEdit(device);
    setModalDevice(true);
  };

  const toggleModalDevice = () => {
    setDeviceToEdit(null);
    setModalDevice(x => !x);
  };

  const toggleModalGadgetInfoDetails = _gadgetInfo => {
    setGadgetInfoDetails(x => !x);
    setGadgetInfo(_gadgetInfo);
  };

  return (
    <DeviceContainer>
      <ItemContainer>
        <FieldArray
          name="devices"
          render={({ remove, replace }) =>
            (fatherValues?.devices ?? []).map((device, i) => (
              <Item
                key={i}
                separator={1}
                devicesLength={fatherValues?.devices?.length}
              >
                <AddMoreContainer device={1}>
                  <Device
                    devicesValues={fatherValues}
                    setParentFieldValue={setFieldValue}
                    data={device}
                    onEdit={editDevice}
                    onDelete={() => remove(i)}
                    editMode={editMode}
                    isEdition={isEdition}
                    onSync={onSubmit}
                    personId={fatherValues.personId}
                    replace={replace}
                    index={i}
                    gadgetInfoDetails={() =>
                      toggleModalGadgetInfoDetails(device.mobile.gadgetInfo)
                    }
                  />
                </AddMoreContainer>
              </Item>
            ))
          }
        />
        {fatherValues?.devices?.length < 11 ? (
          <Item devicesLength={fatherValues?.devices?.length}>
            <AddMoreContainer>
              <AddMore
                text={t('put.devices.text-to-add')}
                onClick={toggleModalDevice}
              />
            </AddMoreContainer>
          </Item>
        ) : null}
      </ItemContainer>
      {modalDevice && (
        <PutUserModalDevice
          devicesValues={fatherValues}
          setParentFieldValue={setFieldValue}
          refetch={refetch}
          onToggle={toggleModalDevice}
          onSubmit={onSubmit}
          deviceObject={deviceToEdit}
          isEdition={isEdition}
          existDevice={existDevice}
          setExistDevice={setExistDevice}
          loading={loading}
          personId={+fatherValues?.personId}
        />
      )}

      {gadgetInfoDetails && (
        <GadgetInfoDetailsModal
          onToggle={toggleModalGadgetInfoDetails}
          gadgetInfo={gadgetInfo}
        />
      )}
    </DeviceContainer>
  );
};

Devices.propTypes = {
  isEdition: propTypes.bool,
  editMode: propTypes.bool,
  refetch: propTypes.func.isRequired,
};

Devices.defaultProps = {
  isEdition: false,
  editMode: false,
};

Devices.checkDeviceExist = gql`
  mutation checkDeviceExist($deviceId: String!, $type: String!) {
    checkDeviceExist(deviceId: $deviceId, type: $type)
  }
`;

Devices.deviceCreate = gql`
  mutation deviceCreate($personId: Int!, $device: PersonDeviceInput!) {
    deviceCreate(personId: $personId, device: $device) {
      id
      type
      value
      counter
      blocked
      description
      lastAccessDateTime
      lastAcessPlace
    }
  }
`;

Devices.deviceUpdate = gql`
  mutation deviceUpdate($personId: Int!, $device: PersonDeviceInput!) {
    deviceUpdate(personId: $personId, device: $device) {
      id
      type
      value
      counter
      blocked
      lastCounter
      description
      lastAccessDateTime
      lastAcessPlace
    }
  }
`;

export default Devices;
