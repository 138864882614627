import * as UI from '@kiper/ui';
import propTypes from 'prop-types';
import { Fragment } from 'react';
import * as S from './styles';

export default function TabHeaderPage({
  activeTab,
  tabs,
  onChangeTab,
  ...props
}) {
  const handleChangeTab = (tab, link) => {
    if (tab !== activeTab) {
      onChangeTab(tab, link);
    }
  };

  const handleGetEventCount = count => {
    if (count > 99) return '99+';
    return count;
  };

  return (
    <S.Container {...props}>
      <S.Nav tabs>
        {tabs.map(tab => (
          <Fragment key={tab.tabId}>
            {tab.showTab && (
              <UI.NavItem>
                <S.NavLink
                  active={activeTab === tab.tabId}
                  onClick={() => {
                    handleChangeTab(tab.tabId, tab.link);
                  }}
                >
                  <UI.Flex
                    alignItems="end"
                    flexDirection="column"
                    position="relative"
                    gridGap="6px"
                    px={tab.icon && !tab.title ? '13px' : '0'}
                  >
                    {tab.icon && tab.icon}

                    {tab.count > 0 && (
                      <S.Badge>{handleGetEventCount(tab.count)}</S.Badge>
                    )}

                    {tab.title && (
                      <UI.Text
                        ml="4px"
                        color={
                          activeTab === tab.tabId ? 'primary500' : 'mediumBlack'
                        }
                        fontSize="14px"
                      >
                        {tab.title}
                      </UI.Text>
                    )}
                    <div />
                  </UI.Flex>
                </S.NavLink>
              </UI.NavItem>
            )}
          </Fragment>
        ))}
      </S.Nav>
    </S.Container>
  );
}

TabHeaderPage.propTypes = {
  onChangeTab: propTypes.func.isRequired,
  activeTab: propTypes.string.isRequired,
  tabs: propTypes.arrayOf(
    propTypes.shape({
      tabId: propTypes.string.isRequired,
      showTab: propTypes.bool.isRequired,
      icon: propTypes.element,
      count: propTypes.number,
      title: propTypes.string,
      link: propTypes.string,
    }),
  ).isRequired,
};
