import propTypes from 'prop-types';
import { Flex, Spinner } from '@kiper/ui';
import GuidedQuickMenu from './GuidedQuickMenu';
import * as S from './styles';

const GuidedAttendanceSideMenu = ({
  event,
  viewTemplate,
  loadingViewTemplate,
}) => {
  const loadingQuickMenu = loadingViewTemplate || !viewTemplate;

  return (
    <S.SideMenu loading={Number(loadingQuickMenu)}>
      {loadingQuickMenu ? (
        <Flex alignItems="center" width="100%" justifyContent="center">
          <Spinner size="28px" />
        </Flex>
      ) : (
        <GuidedQuickMenu
          event={event}
          condominiumName={event?.condominium?.name}
          viewTemplate={viewTemplate}
          eventType={event?.eventType}
          condominiumPersonContextId={event?.condominium?.personContextId}
        />
      )}
    </S.SideMenu>
  );
};

export default GuidedAttendanceSideMenu;

GuidedAttendanceSideMenu.propTypes = {
  event: propTypes.object.isRequired,
  loadingViewTemplate: propTypes.bool.isRequired,
  viewTemplate: propTypes.shape({
    eventType: propTypes.number.isRequired,
    sideMenu: propTypes.shape({
      footer: propTypes.any.isRequired,
      menu: propTypes.string.isRequired,
    }).isRequired,
  }),
};

GuidedAttendanceSideMenu.defaultProps = {
  viewTemplate: null,
};
