import { useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { MdOutlineQuestionAnswer } from 'react-icons/md';
import { ErrorMessage, MdIcons } from '@kiper/ui';
import { useGuidedAttendance } from '../../../hooks';
import { GuidedAttendanceContext } from '../../../store';
import { provideServiceTo } from '../../../constants';
import StepStructure, { StepTextTypeColor } from '../StepStructure';

import * as S from './styles';

const StepUnityOrCondo = () => {
  const { setContextTree } = useContext(GuidedAttendanceContext);
  const [selectedChoice, setSelectedChoice] = useState(false);

  const { handleNextAction, handleGetTriageSelected } = useGuidedAttendance();
  const {
    values,
    setFieldValue,
    errors,
    touched,
    handleSubmit,
  } = useFormikContext();
  const { t } = useTranslation('guided-attendance');

  const schema = yup.object({
    stepUnityOrCondo: yup.object({
      choice: yup.string().required(t('common:feedback.required-field')),
    }),
  });

  const handleChange = choice => {
    setFieldValue('stepUnityOrCondo', { choice });
    setSelectedChoice(true);
  };

  const nextAction = async stepData => {
    const nextStep = await handleNextAction({
      values: stepData,
      fieldValue: 'stepUnityOrCondo',
    });
    return { nextStep, currentStepData: stepData };
  };

  useEffect(() => {
    const choice = !!values?.stepUnityOrCondo?.choice;

    if (choice && selectedChoice) handleSubmit();
  }, [values?.stepUnityOrCondo?.choice, selectedChoice]);

  useEffect(() => {
    if (!values?.stepUnityOrCondo) {
      setFieldValue('stepUnityOrCondo', {
        choice: '',
      });
    }

    setContextTree({
      formikSchema: schema,
      nextAction,
    });
  }, []);

  return (
    <StepStructure
      icon={<MdOutlineQuestionAnswer size={20} />}
      type={StepTextTypeColor.SPEAK}
      text={t('step-unity-or-condo.title', {
        triage: t(
          `step-unity-or-condo.triage.${handleGetTriageSelected(values)}`,
        ),
      })}
      hasPreviousStepButton
    >
      <S.OptionsContainer>
        <S.RadioButton
          variant="out"
          id="unity-choice"
          name="stepUnityOrCondo"
          label={t('step-unity-or-condo.unity')}
          value={values?.stepUnityOrCondo?.choice}
          width="100%"
          icon={<MdIcons mdIconName="home" size="20px" />}
          color={
            values?.stepUnityOrCondo?.choice === provideServiceTo.UNITY
              ? 'primary'
              : 'neutral'
          }
          checked={values?.stepUnityOrCondo?.choice === provideServiceTo.UNITY}
          onChange={() => handleChange(provideServiceTo.UNITY)}
        />

        <S.RadioButton
          variant="out"
          id="condominium-choice"
          name="stepUnityOrCondo"
          label={t('step-unity-or-condo.condominium')}
          value={values?.stepUnityOrCondo?.choice}
          width="100%"
          icon={<MdIcons mdIconName="corporate_fare" size="20px" />}
          color={
            values?.stepUnityOrCondo?.choice === provideServiceTo.CONDOMINIUM
              ? 'primary'
              : 'neutral'
          }
          checked={
            values?.stepUnityOrCondo?.choice === provideServiceTo.CONDOMINIUM
          }
          onChange={() => handleChange(provideServiceTo.CONDOMINIUM)}
        />
      </S.OptionsContainer>
      {!!touched?.stepUnityOrCondo?.choice &&
        !!errors?.stepUnityOrCondo?.choice && (
          <ErrorMessage>{errors.stepUnityOrCondo?.choice}</ErrorMessage>
        )}
    </StepStructure>
  );
};

export default StepUnityOrCondo;
