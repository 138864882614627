import { useRef, useMemo } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-apollo';
import { useFilters } from '@kiper/hooks';
import { Table, Pagination, TableLoader } from '@kiper/ui';
import { person as personGql } from '@kiper/monitoring-graphql';
import { Redirect } from 'react-router-dom';
import { TableContainer, Container } from './styles';
import PageActions from '../../../components/PageActions';
import SystemUserItem from './SystemUserItem';
import useCurrentLoggedContext from '../../../hooks/useCurrentLoggedContext';
import useViewRules from '../../../hooks/useViewRules';

const column = x => `list.table-header.${x}`;

const initialFilters = {
  searchText: '',
  page: 1,
  pagesize: 10,
  orderby: 'name',
  ordination: false,
};

function SystemUserList({ route }) {
  const [t] = useTranslation('system-user');
  const {
    filters,
    handlePageChange,
    handlePageSize,
    handleChange,
    handleSort,
  } = useFilters(initialFilters);

  const columns = useRef([
    {
      name: t(column('user')),
      align: 'left',
      width: '45%',
      order: 'name',
    },
    {
      name: t(column('profile')),
      align: 'left',
      width: '25%',
      order: 'profileName',
    },
    {
      name: t(column('creation-date')),
      align: 'left',
      width: '24%',
      order: 'creation',
    },
    { name: '', align: 'center', width: '6%' },
  ]);

  const { loggedContext } = useCurrentLoggedContext();

  const sourceNodeId = useMemo(
    () => filters.sourceNodeId || loggedContext.topNodeId,
    [filters, loggedContext],
  );

  const { data, loading, refetch } = useQuery(personGql.persons, {
    fetchPolicy: 'no-cache',
    variables: {
      filters: {
        ...filters,
        sourceNodeId,
      },
    },
  });

  const { persons } = data || { persons: {} };
  const { collection, pagination } = persons;

  const { permission, loading: loadingPermission } = useViewRules({
    path: 'systemUsersManagement',
  });

  if (
    !loadingPermission &&
    (!permission || !permission.ableToRead || !permission.ableToWrite)
  )
    return <Redirect to="/" />;

  return (
    <Container>
      <PageActions
        filters={filters}
        handleChange={handleChange}
        route={route}
        entity="system-user"
        registerLink="/system-user/register"
        onlySearch
      />
      <TableContainer>
        <Table
          columns={columns}
          sort={{
            handleSort,
            ordination: filters.ordination,
            orderby: filters.orderby,
          }}
        >
          {!loading && collection && (
            <tbody>
              {collection.map(item => (
                <SystemUserItem
                  key={item.personContextId}
                  data={item}
                  onDeleted={refetch}
                  canDelete={
                    loggedContext.personContextId !== item.personContextId
                  }
                />
              ))}
            </tbody>
          )}
        </Table>
        {loading && <TableLoader />}
        <Pagination
          translate={t}
          pagination={pagination}
          handlePageSize={handlePageSize}
          next={() => handlePageChange(1)}
          previous={() => handlePageChange(-1)}
        />
      </TableContainer>
    </Container>
  );
}

export default SystemUserList;

SystemUserList.propTypes = {
  route: propTypes.object.isRequired,
};
