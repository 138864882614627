import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  margin: 15px 0 10px 0;
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  margin: 0 -15px;
  display: flex;
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${props => props.theme.colors.secondary300};
  border-radius: 4px;
  padding: 10px;
  background-color: ${props =>
    props.blocked ? props.theme.colors.secondary300 : props.theme.colors.white};
`;

export const Line = styled.div`
  background-color: ${props => props.theme.colors.secondary600};
  border-radius: 4px;
  width: 15px;
  height: 2px;
  margin-bottom: 2px;
`;

export const Contact = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;

export const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid ${props => props.theme.colors.primary800};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;

  svg {
    color: ${props => props.theme.colors.primary800};
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
