import { useEffect, useContext, useState } from 'react';
import { firstBy } from 'thenby';
import { deviceIdTypes } from '../constants';
import { SocketContext } from '../services/socket';

export default function useDeviceStatus({ places }) {
  const { subscribe, unsubscribe, sendMessage } = useContext(SocketContext);

  const [deviceStatusMessage, setDeviceStatusMessage] = useState([]);

  const [deviceStatus, setDeviceStatus] = useState([]);

  const onMessage = ({ action, item }) => {
    switch (action) {
      case 8:
        setDeviceStatus(previousDeviceStatus => {
          if (previousDeviceStatus.length) {
            const deviceStatusModified = [...previousDeviceStatus];
            const index = previousDeviceStatus.findIndex(
              device =>
                device.serialNumber === item.serialNumber &&
                device.messageData.doorId === item.messageData.doorId,
            );
            if (index >= 0) {
              const { doorStatus } = deviceStatusModified[index].messageData;
              if (item.messageData.doorStatus !== doorStatus) {
                deviceStatusModified.splice(index, 1, item);
                return deviceStatusModified;
              }
            } else {
              deviceStatusModified.push(item);
              return deviceStatusModified;
            }
          }
          return [...previousDeviceStatus, item];
        });
        break;
      default:
        break;
    }
  };

  // TODO: Abstrair essa funçao para verificar se o device é uma controladora de porta para ser utilizada em outros lugares do sistema que possui troca de status, validar está regra
  const checkIfDeviceIsDoorController = device => {
    const isFacialDevice =
      device?.deviceTypeId === deviceIdTypes.facial ||
      device?.deviceTypeId === deviceIdTypes.facialIntelbras;

    const deviceIsNotOnlyAudio =
      device.deviceTypeId !== deviceIdTypes.interphone &&
      device?.deviceTypeId !== deviceIdTypes.facial &&
      device?.deviceTypeId !== deviceIdTypes.facialIntelbras &&
      !JSON.parse(device?.serializedParams)?.onlyAudio;

    if (deviceIsNotOnlyAudio) return 3;

    const facialWithAllowOpening =
      isFacialDevice &&
      device?.parentDeviceId === device?.id &&
      !JSON.parse(device?.serializedParams)?.notAllowOpening;

    if (facialWithAllowOpening) return 2;

    const interphoneWithUrlToOpen =
      device.deviceTypeId === deviceIdTypes.interphone &&
      !!JSON.parse(device?.serializedParams)?.urlToOpen;

    if (interphoneWithUrlToOpen) return 1;

    return 0;
  };

  useEffect(() => {
    if (places.length) {
      const devices = places?.reduce((acc, ctx) => {
        const devicesController = ctx?.devices
          ?.filter(
            device =>
              !!device.doorId &&
              device.deviceTypeId !== deviceIdTypes.interphone,
          )
          ?.sort(
            firstBy(d => checkIfDeviceIsDoorController(d), {
              direction: 'desc',
            }),
          );

        if (devicesController?.length && devicesController[0]?.serialNumber) {
          acc.push(`deviceStatusMessage.${devicesController[0]?.serialNumber}`);
        }

        return acc;
      }, []);
      setDeviceStatusMessage(devices);
    }
  }, [places]);

  useEffect(() => {
    if (deviceStatusMessage?.length) {
      deviceStatusMessage.forEach(x => subscribe(x, onMessage));
      sendMessage('deviceStatus', {
        channels: deviceStatusMessage,
        isSubscribe: true,
      });
    }
    return () =>
      deviceStatusMessage.length &&
      deviceStatusMessage.forEach(x => unsubscribe(x));
  }, [deviceStatusMessage]);

  return { deviceStatus };
}
