export default function entityToSelect({
  data,
  fields: [identifier = 'id', description = 'name'] = [],
}) {
  if (!data) throw new Error("you can't cast undefined");

  const value = data[identifier];
  const label = data[description];

  return { ...data, value, label };
}
