import propTypes from 'prop-types';
import { Spinner } from '@kiper/ui';
import { MdCheck, MdCloudUpload, MdLoop, MdPhotoCamera } from 'react-icons/md';

const iconMap = {
  sending: <Spinner />,
  sendPhoto: <MdCloudUpload />,
  sent: <MdCheck />,
  resend: <MdLoop />,
  camera: <MdPhotoCamera />,
};

const PlayerButtonContent = ({ text, icon, iconRight }) => {
  return (
    <>
      {iconRight ? (
        <>
          {text}
          {icon && iconMap[icon]}
        </>
      ) : (
        <>
          {icon && iconMap[icon]}
          {text}
        </>
      )}
    </>
  );
};

export default PlayerButtonContent;

PlayerButtonContent.propTypes = {
  text: propTypes.string.isRequired,
  icon: propTypes.string,
  iconRight: propTypes.bool,
};

PlayerButtonContent.defaultProps = {
  iconRight: false,
  icon: null,
};
