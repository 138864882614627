import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { eventTypes } from '../../../../constants';

const initialValues = {
  cameraId: null,
  cameraName: null,
  howAct: null,
  zoneInformation: null,
};

const useCameraInfo = ({ event }) => {
  const [cameraInfo, setCameraInfo] = useState(initialValues);
  const { t } = useTranslation('guided-attendance');
  const history = useHistory();

  const handleGetIntrusionDetectionCameraInfo = additionalInformation => {
    const cameraId = additionalInformation?.camera?.id;
    const cameraName = additionalInformation?.camera?.name;
    const howAct = additionalInformation?.camera?.howAct;
    const channelId = additionalInformation?.camera?.channelId;
    const zoneInformation = t('step-intrusion-detection.zone-information', {
      channelId,
      camName: cameraName,
    });

    return { cameraId, cameraName, howAct, zoneInformation };
  };

  const handleGetAlarmTriggerCameraInfo = additionalInformation => {
    const cameraId = additionalInformation?.camera?.id;
    const cameraName = additionalInformation?.camera?.name;
    const howAct = additionalInformation?.howAct;
    const zone = additionalInformation?.contactId?.argumentId;
    const zoneInformation = t('step-intrusion-detection.zone-information', {
      channelId: zone,
      camName: cameraName,
    });

    return { cameraId, cameraName, howAct, zoneInformation };
  };

  const handleGetCameraInfo = ({ eventType, additionalInformation }) => {
    const additionalInformationParsed = additionalInformation
      ? JSON.parse(additionalInformation)
      : null;

    if (!additionalInformationParsed) return null;

    switch (eventType) {
      case eventTypes.intrusionDetection:
        return handleGetIntrusionDetectionCameraInfo(
          additionalInformationParsed,
        );
      case eventTypes.alarmTrigger:
        return handleGetAlarmTriggerCameraInfo(additionalInformationParsed);
      default:
        return null;
    }
  };

  useEffect(() => {
    if (event) {
      const { eventType, additionalInformation } = event;
      const info = handleGetCameraInfo({ eventType, additionalInformation });
      if (!info?.cameraId) {
        history.push(`/guided-attendance/${event?.eventId}/fallback`);
      }
      setCameraInfo(info);
    }
  }, [event]);

  return { ...cameraInfo };
};

export default useCameraInfo;
