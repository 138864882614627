import styled from 'styled-components';

export const IotItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  gap: 16px;
`;

export const WrapperSpinner = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const WrapperRelays = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: stretch;
  gap: 8px;
`;
