import { useEffect, useMemo, useRef } from 'react';
import propTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { Label } from '@kiper/ui';
import { condominiumSelect } from '@kiper/monitoring-graphql/attendance';
import { StyledSelect as Select, OptionContainer, OpacitySpan } from './styles';

// value = treenodeId
// entityId: personId,
// contextId: personContextId,
// label: personName
function CondominiumSelect({
  isClearable,
  invalid,
  value,
  isEditable,
  autoFocus,
  showContextId,
  ...props
}) {
  const { data, loading } = useQuery(condominiumSelect, {
    fetchPolicy: 'cache-and-network',
  });

  const options = useMemo(
    () =>
      data?.condominiumSelect?.map(x => ({
        ...x,
        personContextId: x.contextId,
      })) || [],
    [data],
  );

  if (!isEditable) return <Label>{value && value.label}</Label>;

  const labelValue = option => {
    if (showContextId && option?.contextId) {
      return (
        <OptionContainer>
          <span>{option.label}</span>
          <OpacitySpan>{option.contextId}</OpacitySpan>
        </OptionContainer>
      );
    }

    return option.label;
  };

  const filterCondominium = (option, inputValue) => {
    return (
      option.data.label.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.data.contextId.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const widthStyle = {
    singleValue: provided => ({
      ...provided,
      width: '100%',
    }),
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocus && inputRef) {
      inputRef.current.select.focus();
    }
  }, [inputRef]);

  return (
    <Select
      ref={inputRef}
      value={options.find(i => Number(i.value) === Number(value)) ?? value}
      isClearable={isClearable}
      isLoading={loading}
      options={options}
      invalid={Number(invalid)}
      getOptionLabel={labelValue}
      filterOption={filterCondominium}
      styles={widthStyle}
      {...props}
    />
  );
}

export default CondominiumSelect;

CondominiumSelect.propTypes = {
  value: propTypes.any,
  isClearable: propTypes.bool,
  invalid: propTypes.bool,
  isEditable: propTypes.bool,
  autoFocus: propTypes.bool,
  showContextId: propTypes.bool,
};

CondominiumSelect.defaultProps = {
  isClearable: false,
  invalid: false,
  isEditable: true,
  value: null,
  autoFocus: false,
  showContextId: false,
};
