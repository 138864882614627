import propTypes from 'prop-types';
import { Text, TextContainer } from './styles';

const TextType = ({ question, answer }) => {
  return (
    <TextContainer>
      <Text>{question}</Text>
      <Text>{answer?.textAnswer}</Text>
    </TextContainer>
  );
};

export default TextType;

TextType.propTypes = {
  question: propTypes.string.isRequired,
  answer: propTypes.shape({
    textAnswer: propTypes.string.isRequired,
  }).isRequired,
};
