import { setMap, types } from '@kiper/cookie';
import { auth } from '@kiper/monitoring-graphql';

const setCurrentLoggedContext = async (
  _,
  { currentLoggedContext },
  { client },
) =>
  Promise.all([
    client.writeQuery({
      query: auth.currentLoggedContext,
      data: {
        currentLoggedContext,
      },
    }),
    setMap({
      [types.context]: currentLoggedContext.personContextId,
      [types.partnerContext]: currentLoggedContext.partner.personContextId,
      [types.topNodeId]: currentLoggedContext.topNodeId,
      [types.topContextId]: currentLoggedContext.topContextId,
    }),
  ]);

export default setCurrentLoggedContext;
