import { useTranslation } from 'react-i18next';
import { Flex, KiperButton, Text } from '@kiper/ui';
import propTypes from 'prop-types';
import Modal from '../Modal';

import successGif from '../../assets/images/success.gif';
import warningGif from '../../assets/images/warning.gif';
import { FeedbackImg } from './styles';

export default function PutUserFingerprintModalFeedback({
  onToggle,
  open,
  fingerprint,
  onTryAgain,
}) {
  const [t] = useTranslation('user');

  const registrationMessage = {
    '2': `${t('put.devices.modal.insert.fingerprint-insert')} - ${t(
      'put.devices.record-error',
    )}`,
    '3': `${t('put.devices.modal.insert.fingerprint-insert')} - ${t(
      'put.devices.registered',
    )}`,
    '4': `${t('put.devices.modal.insert.fingerprint-insert')} - ${t(
      'put.devices.not-registered',
    )}`,
  };

  return (
    <Modal
      title={
        registrationMessage[fingerprint?.registrationStatus] ||
        `${t('put.devices.modal.insert.fingerprint-insert')} - ${'timeout'}`
      }
      open={open}
      toggle={onToggle}
    >
      <>
        <Flex
          justifyContent="center"
          mb="10px"
          alignItems="center"
          flexDirection="column"
        >
          {fingerprint?.registrationStatus === 3 ? (
            <Text color="secondary400">
              {t(
                'put.devices.modal.insert.fingerprint-successfully-registered',
              )}
            </Text>
          ) : (
            <Text color="secondary400" mb="10px">
              {t('put.devices.modal.insert.fingerprint-not-registered')}
            </Text>
          )}

          {fingerprint?.registrationStatus === 3 ? (
            <FeedbackImg src={successGif} alt="response status" />
          ) : (
            <FeedbackImg src={warningGif} alt="response status" />
          )}
        </Flex>

        {fingerprint?.registrationStatus === 3 ? (
          <Flex flex={1} justifyContent="center">
            <Flex width="180px">
              <KiperButton block onClick={onToggle}>
                Ok
              </KiperButton>
            </Flex>
          </Flex>
        ) : (
          <Flex flex={1} justifyContent="space-between">
            <KiperButton
              color="secondary"
              variant="outlined"
              onClick={onToggle}
            >
              {t('put.devices.modal.insert.cancel')}
            </KiperButton>
            <KiperButton onClick={onTryAgain}>
              {t('put.devices.modal.insert.try-again')}
            </KiperButton>
          </Flex>
        )}
      </>
    </Modal>
  );
}

PutUserFingerprintModalFeedback.propTypes = {
  onToggle: propTypes.func.isRequired,
  open: propTypes.bool,
  fingerprint: propTypes.object,
  onTryAgain: propTypes.func.isRequired,
};

PutUserFingerprintModalFeedback.defaultProps = {
  open: false,
  fingerprint: null,
};
