import { useContext, useEffect, useLayoutEffect, useMemo } from 'react';
import propTypes from 'prop-types';
import { useFormikContext } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Flex, Input } from '@kiper/ui';
import { useMutation } from 'react-apollo';
import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { eventTriage } from '@kiper/monitoring-graphql/attendance';
import { useGuidedAttendance } from '../../../hooks';
import { GuidedAttendanceContext, TriageContext } from '../../../store';
import { UserGuUnSelect } from '../../Select';
import StepStructure, { StepTextTypeColor } from '../StepStructure';

import { Label } from '../styles';
import { triageIds } from '../../../constants';

const StepAttendanceGetUnityLegacy = ({ event }) => {
  const { setContextTree } = useContext(GuidedAttendanceContext);
  const { fillAction, setTriageContext } = useContext(TriageContext);
  const { handleNextAction } = useGuidedAttendance();
  const { values, setFieldValue } = useFormikContext();
  const { t } = useTranslation('guided-attendance');
  const { toast } = useSwal();

  const schema = yup.object({
    stepAttendanceGetUnityLegacy: yup
      .object({
        unity: yup
          .object({
            value: yup.string(),
            label: yup.string(),
            parentName: yup.string(),
            treeNodeId: yup.number(),
          })
          .nullable(),
        dweller: yup.string().nullable(),
        unityGroup: yup.string().nullable(),
      })
      .nullable(),
  });

  const [updateTriage] = useMutation(eventTriage, {
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const handleChangeUnity = item => {
    if (!item) {
      setTriageContext({
        filterGUUnit: null,
      });
      return setFieldValue('stepAttendanceGetUnityLegacy', {
        ...values.stepAttendanceGetUnityLegacy,
        unity: null,
        unityGroup: null,
      });
    }

    setTriageContext({
      filterGUUnit: item,
    });
    fillAction('guUn', event.evendId);

    return setFieldValue('stepAttendanceGetUnityLegacy', {
      ...values.stepAttendanceGetUnityLegacy,
      unityGroup: item?.gu ? item.gu?.label : null,
      unity: {
        treeNodeId: item?.treeNodeId,
        value: item?.value,
        label: item?.label,
        parentName: item?.parentName,
      },
    });
  };

  const nextAction = async value => {
    const variables = {
      eventId: event?.eventId,
      triageId: value?.stepAttendanceGetReason?.id,
    };

    if (value?.stepAttendanceGetUnityLegacy) {
      const { stepAttendanceGetUnityLegacy } = value || {};
      const serializedTriageJson = stepAttendanceGetUnityLegacy?.unity
        ?.treeNodeId
        ? { guUnTreeNodeId: stepAttendanceGetUnityLegacy.unity.treeNodeId }
        : {};

      if (stepAttendanceGetUnityLegacy?.dweller) {
        serializedTriageJson.dweller = stepAttendanceGetUnityLegacy.dweller;
        setTriageContext({
          dweller: stepAttendanceGetUnityLegacy?.dweller,
        });
      }

      if (value?.stepAttendanceGetName?.name)
        serializedTriageJson.identifier = value.stepAttendanceGetName.name;

      if (Object.keys(serializedTriageJson).length > 0)
        variables.serializedTriage = JSON.stringify(serializedTriageJson);

      await updateTriage({
        variables,
      });
    }

    return handleNextAction({
      values: value,
      fieldValue: 'stepAttendanceGetUnityLegacy',
    });
  };

  const isExit = useMemo(
    () => values?.stepAttendanceGetReason?.id === triageIds.exit,
    [values?.stepAttendanceGetReason?.id],
  );

  useEffect(() => {
    if (isExit && values?.stepAttendanceGetName?.name) {
      setFieldValue(
        'stepAttendanceGetUnityLegacy.dweller',
        values?.stepAttendanceGetName?.name,
      );
    }
  }, [isExit, values?.stepAttendanceGetName?.name]);

  useLayoutEffect(() => {
    if (!values?.stepAttendanceGetUnityLegacy)
      setFieldValue('stepAttendanceGetUnityLegacy', {
        unity: null,
        dweller: null,
      });

    setContextTree({
      formikSchema: schema,
      nextAction: async stepData => {
        const nextStep = await nextAction(stepData);
        return { nextStep, currentStepData: stepData };
      },
    });
  }, []);

  return (
    <StepStructure
      type={StepTextTypeColor.SPEAK}
      text={t(`step-get-unity-legacy.${isExit ? 'exit-triage.' : ''}title`)}
      hasPreviousStepButton
      hasNextStepButton
    >
      <Flex gridGap="8px" width="100%">
        <Flex flexDirection="column" width="100%">
          <Label>
            {t(`step-get-unity-legacy.${isExit ? 'exit-triage.' : ''}unity`)}
          </Label>
          <UserGuUnSelect
            name="stepAttendanceGetUnityLegacy.unity"
            onChange={handleChangeUnity}
            sourceNodeId={event?.condominium?.treeNodeId}
            value={values?.stepAttendanceGetUnityLegacy?.unity}
            guLoad={false}
            autoFocus
          />
        </Flex>
        <Flex flexDirection="column" width="100%">
          <Label>
            {t(
              `step-get-unity-legacy.${
                isExit ? 'exit-triage.' : ''
              }name-of-dweller`,
            )}
          </Label>
          <Input
            placeholder={t(
              `step-get-unity-legacy.${
                isExit ? 'exit-triage.' : ''
              }dweller-placeholder`,
            )}
            name="stepAttendanceGetUnityLegacy.dweller"
            value={values?.stepAttendanceGetUnityLegacy?.dweller}
            onChange={setFieldValue}
          />
        </Flex>
      </Flex>
    </StepStructure>
  );
};

export default StepAttendanceGetUnityLegacy;

StepAttendanceGetUnityLegacy.propTypes = {
  event: propTypes.object,
};

StepAttendanceGetUnityLegacy.defaultProps = {
  event: null,
};
