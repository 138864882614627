import { Fragment, useMemo, useState } from 'react';
import { Button, CollapsableTable, Flex, Spinner, UserAvatar } from '@kiper/ui';
import propTypes from 'prop-types';
import { formatDateTimeUtc } from '@kiper/fns';
import { useSwal, useWindowDimensions } from '@kiper/hooks';
import { MdCheck, MdClose } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import logoTag from '../../../assets/images/device-tag.svg';
import logoController from '../../../assets/images/device-control.svg';
import { HeaderCollapse } from './components';
import { columnsHeader } from './settings';
import {
  useFetchDenyOrApproveUser,
  useFetchAccessProfilesNames,
} from './hooks';
import * as S from './styles';

const UnityListWithPendingApproval = ({
  condominium,
  unityUsers,
  unities,
  onOpenCollapse,
  loadingDataTable,
  loadingList,
  condominiumUsersQuantity,
  refetchUsersWithPendingApproval,
  refetchUnities,
}) => {
  const { t } = useTranslation('user');
  const { alertWarning, swal } = useSwal();
  const { width: windowsWidth } = useWindowDimensions();

  const [unityIdByUser, setUnityIdByUser] = useState(null);

  const {
    approveUser,
    denyUser,
    loadingApproveUser,
    loadingDenyUser,
  } = useFetchDenyOrApproveUser({
    unityUsers,
    refetchUsersWithPendingApproval,
    refetchUnities,
    condominium,
    unityIdByUser,
  });

  const [
    accessProfilesList,
    loadingAccessProfiles,
  ] = useFetchAccessProfilesNames({
    condominiumPersonContextId: condominium?.personContextId,
  });

  const formatAccessProfileName = unityInfo => {
    let unityDefaultAccessProfile = t('pending-approval.not-defined');
    let unityGroupDefaultAccessProfile = t('pending-approval.not-defined');

    if (loadingAccessProfiles || !accessProfilesList)
      return {
        unityDefaultAccessProfile,
        unityGroupDefaultAccessProfile,
      };

    const { unity, unityGroup } = unityInfo;

    if (!unity.defaultAccessProfile && unityGroup.defaultAccessProfile) {
      unityGroupDefaultAccessProfile =
        accessProfilesList[unityGroup.defaultAccessProfile];
      unityDefaultAccessProfile = t(
        'pending-approval.unity-default-access-profile-by-unity-group',
        {
          unityGroupDefaultAccessProfile,
          unityGroupName: unityGroup.name,
        },
      );
    } else if (unity.defaultAccessProfile && unityGroup.defaultAccessProfile) {
      unityGroupDefaultAccessProfile =
        accessProfilesList[unityGroup.defaultAccessProfile];
      unityDefaultAccessProfile =
        accessProfilesList[unity.defaultAccessProfile];
    } else if (unity.defaultAccessProfile && !unityGroup.defaultAccessProfile) {
      unityDefaultAccessProfile =
        accessProfilesList[unity.defaultAccessProfile];
    }

    return {
      unityDefaultAccessProfile,
      unityGroupDefaultAccessProfile,
    };
  };

  const unitiesList = useMemo(
    () =>
      unities.map(unityInfo => ({
        unityId: unityInfo.unity.id,
        headerInfo: {
          unity: unityInfo.unity.name,
          unityDefaultAccessProfile: formatAccessProfileName(unityInfo)
            .unityDefaultAccessProfile,
          unityGroup: unityInfo.unityGroup.name,
          unityGroupDefaultAccessProfile: formatAccessProfileName(unityInfo)
            .unityGroupDefaultAccessProfile,
          usersPendingApproval: t('pending-approval.users-quantity', {
            quantity: unityInfo.countPreRegister,
          }),
        },
        data: unityUsers[unityInfo.unity.id]?.map(user => ({
          id: user?.id,
          name: (
            <S.NameColumnTable>
              <UserAvatar size={32} name={user.name} round="100%" />
              {user.name}
            </S.NameColumnTable>
          ),
          userProfile: user?.profile?.name,
          cpf: user?.cpf || '--',
          phone: user?.phone,
          email: user?.email,
          tagsAndRfQuantity: (
            <Flex flexDirection="row" gridGap="16px" alignItems="center">
              <Flex flexDirection="column" gridGap="4px" alignItems="center">
                <img src={logoTag} width="auto" height="24px" alt="Tags" />
                {user?.tagQuantity}
              </Flex>
              <Flex flexDirection="column" gridGap="4px" alignItems="center">
                <img src={logoController} width="auto" height="24px" alt="RF" />
                {user?.controlQuantity}
              </Flex>
            </Flex>
          ),
          creationDate: formatDateTimeUtc(user?.creationDate),
        })),
      })),
    [
      unityUsers[unityIdByUser],
      unities,
      onOpenCollapse,
      unityUsers,
      accessProfilesList,
    ],
  );

  const isFullHd = useMemo(() => {
    return windowsWidth > 1366;
  }, [windowsWidth]);

  const handleToggleCollapse = id => {
    onOpenCollapse(id);
  };

  const handleDenyUser = ({ rowData, unityId }) => {
    setUnityIdByUser(unityId);
    alertWarning({
      title: t('pending-approval.deny-alert.title'),
      text: t('pending-approval.deny-alert.text'),
      cancelButtonText: t('pending-approval.deny-alert.cancel-button-text'),
      confirmButtonText: t('pending-approval.deny-alert.confirm-button-text'),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        denyUser({
          variables: { preRegisterId: rowData.id },
        });
      },
      allowOutsideClick: () => !swal.isLoading(),
    });
  };

  const handleApproveUser = ({ rowData, unityId }) => {
    setUnityIdByUser(unityId);
    approveUser({
      variables: {
        preRegisterId: rowData.id,
      },
    });
  };

  const actionRowContent = ({ collapseGroupId, rowData }) => (
    <S.RowAction>
      <Button
        icon={isFullHd ? null : <MdClose />}
        disabled={loadingApproveUser || loadingDenyUser}
        color="danger"
        onClick={() => handleDenyUser({ unityId: collapseGroupId, rowData })}
      >
        {isFullHd ? t('pending-approval.deny') : null}
      </Button>
      <Button
        icon={isFullHd ? null : <MdCheck />}
        disabled={loadingApproveUser || loadingDenyUser}
        onClick={() => handleApproveUser({ unityId: collapseGroupId, rowData })}
      >
        {isFullHd ? t('pending-approval.approve') : null}
      </Button>
    </S.RowAction>
  );

  actionRowContent.propTypes = {
    rowData: propTypes.any.isRequired,
    collapseGroupId: propTypes.oneOfType([propTypes.string, propTypes.number])
      .isRequired,
  };

  return (
    <Flex flexDirection="column" gridGap="16px">
      <S.CardTitle>
        {!loadingList
          ? t('pending-approval.condominium-pending-approvals', {
              condominium: condominium?.label,
              quantity: condominiumUsersQuantity,
            })
          : t('pending-approval.condominium-pending-approvals-loading')}
        {loadingList && <Spinner />}
      </S.CardTitle>
      {unitiesList.map(unity => (
        <Fragment key={unity.unityId}>
          <CollapsableTable
            actionRowContent={actionRowContent}
            initialOpen={!!unitiesList && unitiesList?.length === 1}
            columnsHeader={columnsHeader(t)}
            onToggle={handleToggleCollapse}
            header={<HeaderCollapse t={t} headerInfo={unity.headerInfo} />}
            data={unity?.data}
            id={unity.unityId}
            columnsBlackList={['id']}
            loadingDataTable={loadingDataTable}
          />
        </Fragment>
      ))}
    </Flex>
  );
};

export default UnityListWithPendingApproval;

UnityListWithPendingApproval.propTypes = {
  condominium: propTypes.any,
  loadingDataTable: propTypes.bool.isRequired,
  loadingList: propTypes.bool.isRequired,
  unityUsers: propTypes.object.isRequired,
  unities: propTypes.array.isRequired,
  onOpenCollapse: propTypes.func.isRequired,
  condominiumUsersQuantity: propTypes.number,
  refetchUsersWithPendingApproval: propTypes.func,
  refetchUnities: propTypes.func,
};

UnityListWithPendingApproval.defaultProps = {
  condominium: {
    label: '',
    value: '',
  },
  condominiumUsersQuantity: 0,
  refetchUsersWithPendingApproval: () => null,
  refetchUnities: () => null,
};
