import { Flex } from '@kiper/ui';
import propTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LeftHandIcon = ({ width, height }) => (
  <svg
    className="hand"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 156.032 234.048"
  >
    <g>
      <path
        d="M104.837,39.008a19.346,19.346,0,0,1,9.752,2.623V39.008a19.49,19.49,0,0,1,29.256-16.881V19.5a19.5,19.5,0,0,1,39.008,0v2.623a19.49,19.49,0,0,1,29.256,16.881V92.654a24.269,24.269,0,0,1,14.628-4.886A14.646,14.646,0,0,1,241.365,102.4v43.991a86.446,86.446,0,0,1-25.482,61.525l-16.13,16.14a34.389,34.389,0,0,1-24.136,10H138.969a53.7,53.7,0,0,1-53.636-53.636V58.512A19.523,19.523,0,0,1,104.837,39.008Zm-9.752,141.4A43.938,43.938,0,0,0,138.969,224.3h36.648a24.531,24.531,0,0,0,17.241-7.148l16.13-16.13a76.776,76.776,0,0,0,22.625-54.63V102.4a4.884,4.884,0,0,0-4.876-4.876,14.646,14.646,0,0,0-14.628,14.628V141.4a4.876,4.876,0,0,1-9.752,0V39.008a9.752,9.752,0,1,0-19.5,0V102.4a4.876,4.876,0,0,1-9.752,0V19.5a9.752,9.752,0,0,0-19.5,0V102.4a4.876,4.876,0,0,1-9.752,0V39.008a9.752,9.752,0,0,0-19.5,0V102.4a4.876,4.876,0,0,1-9.752,0V58.512a9.752,9.752,0,0,0-19.5,0Z"
        transform="translate(-85.333)"
      />
    </g>
  </svg>
);

LeftHandIcon.propTypes = {
  width: propTypes.string.isRequired,
  height: propTypes.string.isRequired,
};

export const RightHandIcon = ({ width, height }) => (
  <svg
    className="hand"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 156.031 234.048"
  >
    <g>
      <path
        d="M221.861,39.008a19.346,19.346,0,0,0-9.752,2.623V39.008a19.49,19.49,0,0,0-29.256-16.881V19.5a19.5,19.5,0,0,0-39.008,0v2.623a19.49,19.49,0,0,0-29.256,16.881V92.654a24.269,24.269,0,0,0-14.628-4.886A14.646,14.646,0,0,0,85.333,102.4v43.991a86.446,86.446,0,0,0,25.482,61.525l16.13,16.14a34.388,34.388,0,0,0,24.136,10h36.648a53.7,53.7,0,0,0,53.636-53.636V58.512A19.523,19.523,0,0,0,221.861,39.008Zm9.752,141.4A43.937,43.937,0,0,1,187.729,224.3H151.081a24.531,24.531,0,0,1-17.241-7.148l-16.13-16.13a76.776,76.776,0,0,1-22.624-54.63V102.4a4.884,4.884,0,0,1,4.876-4.876,14.646,14.646,0,0,1,14.628,14.628V141.4a4.876,4.876,0,0,0,9.752,0V39.008a9.752,9.752,0,1,1,19.5,0V102.4a4.876,4.876,0,1,0,9.752,0V19.5a9.752,9.752,0,0,1,19.5,0V102.4a4.876,4.876,0,1,0,9.752,0V39.008a9.752,9.752,0,0,1,19.5,0V102.4a4.876,4.876,0,1,0,9.752,0V58.512a9.752,9.752,0,0,1,19.5,0Z"
        transform="translate(-85.333)"
      />
    </g>
  </svg>
);

RightHandIcon.propTypes = {
  width: propTypes.string.isRequired,
  height: propTypes.string.isRequired,
};

const HandFinger = ({ height, left, onClick, width }) => (
  <Flex onClick={onClick}>
    <Container>
      {left ? (
        <LeftHandIcon width={width} height={height} />
      ) : (
        <RightHandIcon width={width} height={height} />
      )}
    </Container>
  </Flex>
);
HandFinger.defaultProps = {
  height: '100%',
  left: false,
  onClick: () => {},
  width: '100%',
};

HandFinger.propTypes = {
  height: propTypes.string,
  left: propTypes.bool,
  onClick: propTypes.func,
  width: propTypes.string,
};

export default HandFinger;
