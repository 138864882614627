import { useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Flex, Text, KiperButton } from '@kiper/ui';
import { useTranslation, Trans } from 'react-i18next';
import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { mutations } from '@kiper/monitoring-graphql/condominium/detail';
import { useMutation } from 'react-apollo';
import { auth } from '@kiper/monitoring-graphql';
import { MonitorSelect } from '../../../Select';
import useCurrentLoggedContext from '../../../../hooks/useCurrentLoggedContext';

import { UnlinkContainer } from './styles';
import { sendAmplitudeData } from '../../../../services/amplitude';

function MatrixSurveillance({ handleCancel }) {
  const [t] = useTranslation('modals/my-settings');
  const [state, setState] = useState(null);

  const { loggedContext } = useCurrentLoggedContext();
  const { toast } = useSwal();

  const surveillance = useMemo(
    () =>
      loggedContext?.personContextParams?.find(
        x => x.name === 'surveillanceMonitor',
      ),
    [],
  );

  const [onCreate, { loading }] = useMutation(
    mutations.personContextParamsCreate,
    {
      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length) {
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
        }
      },
      onCompleted: ({ personContextParamsCreate }) => {
        toast.fire({
          icon: 'success',
          title: t('common:save-success'),
        });
        const { id, value } = personContextParamsCreate;
        setState({
          ...personContextParamsCreate,
          id: +id,
          value: JSON.parse(value),
        });

        sendAmplitudeData('link monitor', {
          user: loggedContext?.email,
          partner: loggedContext?.partner?.name,
          monitor: JSON.parse(personContextParamsCreate?.value)?.monitor,
        });
      },
      update: (cache, { data: { personContextParamsCreate } }) => {
        const queryArgs = {
          query: auth.currentLoggedContext,
        };
        try {
          const newContext = loggedContext;

          const {
            value,
            id,
            name,
            creationDate,
            lastUpdate,
          } = personContextParamsCreate;

          const newValue = {
            monitor: JSON.parse(value).monitor,
            agentId: null,
            extension: null,
            password: null,
            user: null,
            __typename: 'PersonContextParamsValue',
          };

          const newParams = {
            id: +id,
            name,
            value: newValue,
            creationDate,
            lastUpdate,
            __typename: 'PersonContextParams',
          };

          if (newContext?.personContextParams?.length) {
            const index = newContext.personContextParams.findIndex(
              x => x.name === 'surveillanceMonitor',
            );

            if (index >= 0) {
              newContext.personContextParams[index] = {
                ...newContext.personContextParams[index],
                id: +id,
                value: {
                  ...newContext.personContextParams[index].value,
                  monitor: JSON.parse(value).monitor,
                },
              };
            } else {
              newContext.personContextParams = [
                ...newContext.personContextParams,
                newParams,
              ];
            }
          } else {
            newContext.personContextParams = [newParams];
          }

          return cache.writeQuery({
            ...queryArgs,
            data: {
              currentLoggedContext: newContext,
            },
          });
        } catch (err) {
          return err;
        }
      },
    },
  );

  const [onDelete, { loading: loadingUnlink }] = useMutation(
    mutations.personContextParamsDelete,
    {
      onCompleted: () => {
        toast.fire({
          icon: 'success',
          title: t('unlink-success'),
        });
        setState(null);
        setFieldValue('monitor', ''); // eslint-disable-line

        sendAmplitudeData('unlink monitor', {
          user: loggedContext?.email,
          partner: loggedContext?.partner?.name,
        });
      },
      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length) {
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
        }
      },
      update: cache => {
        const queryArgs = {
          query: auth.currentLoggedContext,
        };
        try {
          const modifiedContext = loggedContext;

          const index = modifiedContext.personContextParams.findIndex(
            x => x.id === surveillance.id,
          );
          if (index >= 0) modifiedContext.personContextParams.splice(index, 1);

          return cache.writeQuery({
            ...queryArgs,
            data: {
              currentLoggedContext: modifiedContext,
            },
          });
        } catch (err) {
          return err;
        }
      },
    },
  );

  const onSubmit = values => {
    if (values.monitor && loggedContext?.personContextId) {
      onCreate({
        variables: {
          data: {
            name: 'surveillanceMonitor',
            value: JSON.stringify({
              monitor: values.monitor?.value
                ? values.monitor.value
                : values.monitor,
            }),
            personContextId: loggedContext.personContextId,
          },
        },
      });
    }
  };

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      monitor: '',
    },
    validationSchema: yup.object({
      monitor: yup.string().required(),
    }),
    onSubmit,
  });

  useEffect(() => {
    if (surveillance?.value) {
      setState(surveillance);
      setFieldValue('monitor', surveillance.value.monitor);
    }
  }, [surveillance]);

  const handleChange = e => setFieldValue('monitor', e);

  const handleDelete = () => {
    if (state?.id)
      onDelete({
        variables: {
          id: state.id,
        },
      });
  };

  return (
    <Flex flexDirection="column" height="100%">
      <Flex flexDirection="column">
        <Text fontWeight={600} fontSize="16px" lineHeight="24px" mb="4px">
          {t('matrix-surveillance.link-matrix')}
        </Text>
        <Text fontSize="14px" lineHeight="20px" mb="16px">
          {t('matrix-surveillance.what-is-surveillance')}
        </Text>

        {!state && (
          <>
            <MonitorSelect
              name="monitor"
              value={values?.monitor}
              label={t('matrix-surveillance.monitor-select-label')}
              placeholder={t('common:select')}
              onChange={handleChange}
            />
            <Text fontSize="14px" lineHeight="16px" pt="5px" pl="8px">
              <Trans>{t('matrix-surveillance.monitor-helper')}</Trans>
            </Text>
          </>
        )}

        {!!state && (
          <UnlinkContainer>
            <Text fontSize="14px" lineHeight="20px" pr="10px">
              <Trans>
                {t('matrix-surveillance.unlink-monitor', {
                  name: state.value.monitor,
                })}
              </Trans>
            </Text>
            <KiperButton
              color="danger"
              variant="outlined"
              id="my-settings-btn-delete"
              data-cy="my-settings-btn-delete"
              onClick={handleDelete}
            >
              {loadingUnlink ? t('unlinking') : t('unlink')}
            </KiperButton>
          </UnlinkContainer>
        )}
      </Flex>
      {!state && (
        <Flex flexDirection="row-reverse" mt="16px">
          <Flex>
            <KiperButton
              color="secondary"
              variant="outlined"
              id="my-settings-btn-cancel"
              onClick={handleCancel}
            >
              {t('buttons:cancel')}
            </KiperButton>
            <KiperButton
              color="primary"
              id="my-settings-btn-save"
              data-cy="matriz-btn-save"
              type="submit"
              className="ml-2"
              loading={loading}
              onClick={handleSubmit}
              disabled={!values.monitor}
            >
              {loading
                ? t('saving', { name: t('matrix') })
                : t('save', { name: t('matrix') })}
            </KiperButton>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

export default MatrixSurveillance;

MatrixSurveillance.propTypes = {
  handleCancel: propTypes.func.isRequired,
};
