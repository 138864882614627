import { useQuery } from 'react-apollo';
import { dweller as dwellerGql } from '@kiper/monitoring-graphql';
import { runtimeConfig } from '@kiper/fns';
import propTypes from 'prop-types';
import { Loader } from '@kiper/ui';
import {
  PermissionDescription,
  PermissionItem,
  PermissionList,
} from './styles';

const ProfilePermissions = ({ profileId, t }) => {
  const permissions = useQuery(
    dwellerGql.register.queries.dwellerAccessDataProfileFeatures,
    {
      variables: {
        profileId,
        applicationKey: runtimeConfig.APPLICATION_KEY_MONITORING,
      },
      skip: !profileId,
    },
  );

  if (permissions.error)
    return (
      <PermissionDescription>
        {t('feedback.load-permissons-error')}
      </PermissionDescription>
    );

  if (permissions.loading) return <Loader />;

  return (
    <>
      {(permissions.data?.dwellerAccessDataProfileFeatures ?? []).map(
        (permission, i) => (
          <div key={i}>
            <PermissionDescription>
              {t(`permissions.${permission.fieldName}`, permission.name)}
            </PermissionDescription>
            <PermissionList>
              {permission.ableToRead ? (
                <PermissionItem>{t('put.view')}</PermissionItem>
              ) : null}
              {permission.ableToWrite ? (
                <PermissionItem>{t('put.edit')}</PermissionItem>
              ) : null}
            </PermissionList>
          </div>
        ),
      )}
    </>
  );
};

ProfilePermissions.propTypes = {
  profileId: propTypes.number.isRequired,
  t: propTypes.func.isRequired,
};

export default ProfilePermissions;
