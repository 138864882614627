const useLocalStorage = () => {
  const getLocalStorage = condominiumPersonContextId => {
    const placeId = localStorage.getItem(
      `condominium-${condominiumPersonContextId}-faceRegisterPlace`,
    );

    return placeId;
  };
  const setLocalStorage = (condominiumPersonContextId, placeId) => {
    localStorage.setItem(
      `condominium-${condominiumPersonContextId}-faceRegisterPlace`,
      placeId,
    );
  };

  return { getLocalStorage, setLocalStorage };
};

export default useLocalStorage;
