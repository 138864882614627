import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ReportForm from './Form';
import ReportFilter from './ReportFilter';
import { Row } from './styles';

const VehiclesReportForm = ({
  reportFilters,
  handleSubmit,
  loading,
  type,
  setCondominiumName,
  setHeaderFilters,
}) => {
  const [showAdvanced, expandAdvanced] = useState(false);

  const formikBag = useFormik({
    initialValues: { condominiumTreeNodeId: null },
    validationSchema: yup.object({
      condominiumTreeNodeId: yup.number().nullable(),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <ReportForm
      formikBag={formikBag}
      loading={loading}
      setHeaderFilters={setHeaderFilters}
      expandAdvanced={() => expandAdvanced(!showAdvanced)}
      hasAdvanced={false}
      showAdvanced={false}
    >
      <Row>
        {reportFilters
          .sort((a, b) => a.sequence - b.sequence)
          .map(({ id, key }) => (
            <ReportFilter
              key={id}
              type={key}
              formProps={formikBag}
              formType={type}
              setCondominiumName={setCondominiumName}
              setHeaderFilters={setHeaderFilters}
            />
          ))}
      </Row>
    </ReportForm>
  );
};

VehiclesReportForm.propTypes = {
  reportFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      key: PropTypes.string,
      isRequired: PropTypes.bool,
      sequence: PropTypes.number,
    }),
  ).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  type: PropTypes.string.isRequired,
  setCondominiumName: PropTypes.func.isRequired,
  setHeaderFilters: PropTypes.func.isRequired,
};

VehiclesReportForm.defaultProps = {
  loading: false,
};

export default VehiclesReportForm;
