import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import propTypes from 'prop-types';
import { ItemTypes } from './ItemTypes';
import { CardContainer } from './styles';

const Card = ({ type, index, moveCard, children, isEditable }) => {
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { type, index };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => isEditable,
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <CardContainer ref={ref} data-handler-id={handlerId} opacity={opacity}>
      {children}
    </CardContainer>
  );
};

Card.propTypes = {
  type: propTypes.string.isRequired,
  index: propTypes.number.isRequired,
  moveCard: propTypes.func.isRequired,
  children: propTypes.element.isRequired,
  isEditable: propTypes.bool.isRequired,
};

export default Card;
