/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useContext } from 'react';
import propTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@kiper/hooks';
import authContext from '../../services/auth/context';
import { MySettingsModal } from '../Modals';
import FeedbackModal from '../Modals/FeedbackModal';
import { ListMenuItem } from './styles';

const MenuItem = ({ to, title, icon: Icon, isCollapsed }) => {
  const [t] = useTranslation('menu');
  const match = useRouteMatch(to);
  const auth = useContext(authContext);
  const [openMySettingsModal, toggleOpenMySettingsModal] = useToggle(false);
  const [openFeedbackModal, toggleFeedbackModal] = useToggle(false);

  const renderComponent = () => {
    switch (title) {
      case 'logout':
        return (
          <div onClick={auth.signOut}>
            <Icon />
            {title && <span>{t(title)}</span>}
          </div>
        );
      case 'my-settings':
        return (
          <div
            data-cy="my-settings-menu-item"
            onClick={toggleOpenMySettingsModal}
          >
            <Icon />
            {title && <span>{t(title)}</span>}
          </div>
        );
      case 'feedback':
        return (
          <div data-cy="feedback-menu-item" onClick={toggleFeedbackModal}>
            <Icon />
            {title && <span>{t(title)}</span>}
          </div>
        );
      default:
        return (
          <>
            {to && (
              <Link to={to}>
                <Icon />
                {title && <span>{t(title)}</span>}
              </Link>
            )}
          </>
        );
    }
  };

  return (
    <>
      <ListMenuItem isCollapsed={isCollapsed} active={to && match}>
        {renderComponent()}
      </ListMenuItem>
      {openMySettingsModal && (
        <MySettingsModal
          open={openMySettingsModal}
          onToggle={toggleOpenMySettingsModal}
        />
      )}
      {openFeedbackModal && (
        <FeedbackModal
          open={openFeedbackModal}
          onToggle={toggleFeedbackModal}
        />
      )}
    </>
  );
};

export default MenuItem;

MenuItem.propTypes = {
  to: propTypes.string,
  isCollapsed: propTypes.bool,
  title: propTypes.string,
  icon: propTypes.oneOfType([
    propTypes.element,
    propTypes.func,
    propTypes.object,
  ]).isRequired,
};

MenuItem.defaultProps = {
  to: undefined,
  isCollapsed: false,
  title: null,
};
