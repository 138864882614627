import propTypes from 'prop-types';
import { Flex } from '@kiper/ui';
import { FilterButtonItem, PlaceScrollbar } from './styles';

const EventPlaces = ({ places, selectedPlace, setSelectedPlace }) => {
  return (
    <Flex mt="10px" flexDirection="column">
      {!!places?.length && (
        <Flex alignItems="center" width="100%">
          <PlaceScrollbar>
            {places.map(i => (
              <FilterButtonItem
                key={i.id}
                selected={i.id === selectedPlace}
                onClick={() => setSelectedPlace(i.id)}
              >
                {i.name}
              </FilterButtonItem>
            ))}
          </PlaceScrollbar>
        </Flex>
      )}
    </Flex>
  );
};

export default EventPlaces;

EventPlaces.propTypes = {
  places: propTypes.array.isRequired,
  setSelectedPlace: propTypes.func.isRequired,
  selectedPlace: propTypes.number,
};

EventPlaces.defaultProps = {
  selectedPlace: null,
};
