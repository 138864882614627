import styled from 'styled-components';
import { Col } from 'reactstrap';
import { ListGroupItem as lgi } from '@kiper/ui';

export const ItemCol = styled(Col)``;

export const ButtonsContainer = styled.div`
  margin: -5px 0;
  display: flex;
  align-items: center;
  justify-content: right;

  button {
    margin-left: 5px;
  }
`;

export const TableContainer = styled.div`
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgba(72, 94, 144, 0.16);
`;
