import { useGuidedAttendance } from '../../../../hooks';
import useInsertStepHistory from './useInsertStepHistory';
import useUpdateTriage from './useUpdateTriage';

const useNextAction = ({ event }) => {
  const { handleNextAction } = useGuidedAttendance();
  const { handleUpdateTriage } = useUpdateTriage(event);
  const { handleStepHistory } = useInsertStepHistory(event);

  const nextAction = async value => {
    const { stepAttendanceGetUnity } = value;
    const { unity, unityGroup } = stepAttendanceGetUnity;
    Promise.all([
      handleUpdateTriage(value),
      handleStepHistory(unity, unityGroup),
    ]);

    const handleNextFn = await handleNextAction({
      values: value,
      fieldValue: 'stepAttendanceGetUnity',
    });

    return handleNextFn;
  };

  return { nextAction };
};

export default useNextAction;
