import styled from 'styled-components';
// import { Label as label } from 'reactstrap';
import { Badge as badge, Label as label } from '@kiper/ui';

export const Row = styled.tr`
  cursor: pointer;
  & :hover {
    background: rgba(72, 94, 144, 0.16);
  }
`;

export const Label = styled(label)`
  width: 100%;
  margin: 0;
`;

export const Span = styled(badge)`
  padding: 5px;
`;
