import { useEffect, useMemo } from 'react';
import propTypes from 'prop-types';
import { useQuery, useLazyQuery, useMutation } from 'react-apollo';
import { auth, event } from '@kiper/monitoring-graphql';
import { runtimeConfig, apolloErrorHandler } from '@kiper/fns';
import { remove, types, setMap } from '@kiper/cookie';
import { useSwal, useToggle } from '@kiper/hooks';
import { useTranslation } from 'react-i18next';
import authContext from './context';
import { useCurrentLoggedContext } from '../../hooks';

const redirectTo = url => {
  if (window) window.location = url;
};

const onSignOut = ({ redirect = false }) => {
  remove([
    types.authorization,
    types.refresh,
    types.context,
    types.partnerContext,
    types.topNodeId,
    types.topContextId,
  ]);

  if (redirect) return redirectTo(runtimeConfig.RAZZLE_KIPER_ACCOUNT_REDIRECT);

  return redirectTo(runtimeConfig.RAZZLE_KIPER_ACCOUNT);
};

const handleError = (err, translation) => {
  const { toast } = useSwal();

  const formattedErrors = apolloErrorHandler(err);
  if (formattedErrors && formattedErrors.length) {
    toast.fire({
      title: formattedErrors
        .reduce((errors, current) => {
          return [...errors, translation(current) || current];
        }, [])
        .join('\n'),
      icon: 'error',
    });
  }

  setTimeout(() => {
    onSignOut({ redirect: true });
  }, 3000);
};

function AuthProvider({ children }) {
  const [t] = useTranslation('auth');
  const [loginSuccess, setLoginSuccess] = useToggle(false);

  const {
    loggedContext: currentLoggedContext,
    loading: loadingCacheContext,
  } = useCurrentLoggedContext();

  const { data, loading: loadingQuery } = useQuery(auth.whoAmIQuery, {
    onError: err => handleError(err, t),
    onCompleted: ({ whoAmI }) => {
      if (!whoAmI)
        return onSignOut({
          redirect: true,
        });
      setLoginSuccess();
      return whoAmI;
    },
  });

  const [signCookies] = useLazyQuery(auth.signS3Cookies, {
    onCompleted: ({ signS3Cookies }) => {
      setMap({
        [types.CloudFrontPolicy]: signS3Cookies.CloudFrontPolicy,
        [types.CloudFrontKeyPairId]: signS3Cookies.CloudFrontKeyPairId,
        [types.CloudFrontSignature]: signS3Cookies.CloudFrontSignature,
      });
    },
  });

  const [getEventTypeTreatmentMenu] = useLazyQuery(
    event.eventTypeTreatmentMenu,
    {
      fetchPolicy: 'cache-first',
    },
  );

  useEffect(() => {
    if (loginSuccess) {
      signCookies();
      getEventTypeTreatmentMenu();
    }
  }, [loginSuccess]);

  const loading = useMemo(() => loadingQuery || loadingCacheContext, [
    loadingQuery,
    loadingCacheContext,
  ]);

  const [signOut] = useMutation(auth.signOutMutation, {
    onCompleted: onSignOut,
    onError: onSignOut,
  });

  return (
    <authContext.Provider
      value={{
        ...data,
        currentLoggedContext,
        loading,
        signOut,
      }}
    >
      {children}
    </authContext.Provider>
  );
}

export default AuthProvider;

AuthProvider.propTypes = {
  children: propTypes.element.isRequired,
};

AuthProvider.whoAmIQuery = auth.whoAmIQuery;

AuthProvider.signOutMutation = auth.signOutMutation;
