import EventSettingsList from './List';
import EventSettingsPut from './Put';

const breadcrumb = (label, to) => ({ label, to });
const requiredPermissions = ['eventSettings'];

export default [
  {
    name: 'Event settings',
    path: '/event-settings',
    exact: true,
    component: EventSettingsList,
    breadcrumb: [
      breadcrumb('common:breadcrumb.settings', '/event-settings'),
      breadcrumb(
        'common:breadcrumb.set-configuration',
        '/event-settings/create',
      ),
    ],
    requiredPermissions,
  },
  {
    name: 'Event settings register',
    path: '/event-settings/create',
    exact: true,
    component: EventSettingsPut,
    breadcrumb: [
      breadcrumb('common:breadcrumb.settings', '/event-settings'),
      breadcrumb(
        'common:breadcrumb.set-configuration',
        '/event-settings/create',
      ),
    ],
    requiredPermissions,
  },
  {
    name: 'Event settings',
    path: '/event-settings/:id',
    exact: true,
    component: EventSettingsPut,
    breadcrumb: [
      breadcrumb('common:breadcrumb.settings', '/event-settings'),
      breadcrumb(
        'common:breadcrumb.set-configuration',
        '/event-settings/create',
      ),
    ],
    requiredPermissions,
  },
];
