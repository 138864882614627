import styled from 'styled-components';
import { FiLoader as loaderIcon } from 'react-icons/fi';
import { MdCheckCircle as checkIcon } from 'react-icons/md';
import { KiperButton } from '@kiper/ui';

export const Overlay = styled.div`
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  position: absolute;
  z-index: 1;
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.9) 100%,
    rgba(255, 255, 255, 1) 100%
  );

  @media print {
    display: none;
    height: 0;
    width: 0;
    z-index: 0;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;
`;

export const Title = styled.span`
  font-size: 24px;
  font-weight: 500;
  color: ${props => props.theme.colors.highBlack};
  margin: 16px;
`;

export const Message = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${props => props.theme.colors.highBlack};
  margin-bottom: 16px;
`;

export const LoaderIcon = styled(loaderIcon)`
  font-size: 60px;
`;

export const CheckIcon = styled(checkIcon)`
  font-size: 60px;
`;

export const Button = styled(KiperButton)`
  margin-left: ${props => (props.margin ? 20 : 0)}px;
`;
