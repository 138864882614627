import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';

import { useEffect, useState } from 'react';
import useViewRules from '../../hooks/useViewRules';
import { MenuOptionsContainer, NavLabel } from './styles';
import MenuItem from './MenuItem';
import menuGroups from './menuGroups';

const MenuOptions = ({ isCollapsed, isMouseIn }) => {
  const [menuOptions, setMenuOptions] = useState(menuGroups);
  const { permissions } = useViewRules();

  const [t] = useTranslation('menu');
  const hasPermission = (requiredPermissions = []) =>
    requiredPermissions.every(requiredPermission =>
      permissions.some(
        permission =>
          permission.applicationFeature.fieldName === requiredPermission &&
          permission.ableToRead &&
          permission.ableToWrite,
      ),
    );

  const hasSome = permissionGroup =>
    permissionGroup.items.some(({ requiredPermissions }) =>
      hasPermission(requiredPermissions),
    );

  useEffect(() => {
    setMenuOptions(prevMenuOptions => {
      const modifiedMenuOptions = [...prevMenuOptions];
      const menuIndex = modifiedMenuOptions.findIndex(
        option => option.title === 'event-attendance',
      );

      if (menuIndex >= 0) {
        const itemIndex = modifiedMenuOptions[menuIndex].items.findIndex(
          item => item.title === 'event-attendance-list',
        );
        if (itemIndex >= 0) {
          modifiedMenuOptions[menuIndex].items[itemIndex] = {
            ...modifiedMenuOptions[menuIndex].items[itemIndex],
            to: '/guided-attendance',
          };
        }
      }
      return modifiedMenuOptions;
    });
  }, []);

  return (
    <MenuOptionsContainer>
      {menuOptions.filter(hasSome).map((group, i) => {
        return (
          <div key={i}>
            {group.title && (
              <NavLabel isCollapsed={isCollapsed}>
                {!isCollapsed && t(`${group.title}`)}
              </NavLabel>
            )}

            {group.items
              .filter(({ requiredPermissions }) =>
                hasPermission(requiredPermissions),
              )
              .map(menu => (
                <MenuItem
                  isCollapsed={isCollapsed}
                  to={menu?.to}
                  title={!isCollapsed || isMouseIn ? menu.title : null}
                  icon={menu.icon}
                  key={menu.title}
                />
              ))}
          </div>
        );
      })}
    </MenuOptionsContainer>
  );
};

MenuOptions.propTypes = {
  isMouseIn: propTypes.bool,
  isCollapsed: propTypes.bool,
};

MenuOptions.defaultProps = {
  isMouseIn: false,
  isCollapsed: false,
};

export default MenuOptions;
