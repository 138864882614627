import propTypes from 'prop-types';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSwal } from '@kiper/hooks';
import { useMutation } from 'react-apollo';
import { apolloErrorHandler, formatDate } from '@kiper/fns';
import gql from 'graphql-tag';
import { Button, Flex, KiperButton, Label } from '@kiper/ui';

import { fingerprintRemoveV2 } from '@kiper/monitoring-graphql/fingerprint';
import { mutation } from '@kiper/monitoring-graphql/access_facial';
import { accessFingerprintRemove } from '@kiper/monitoring-graphql/access_fingerprint/mutation';

import { FaTrash as DeleteIcon } from 'react-icons/fa';
import { MdNotificationsActive } from 'react-icons/md';
import logoTag from '../../../../assets/images/device-tag.svg';
import logoBiometry from '../../../../assets/images/biometry.svg';
import logoFacial from '../../../../assets/images/facial/facial-logo.svg';
import logoMobile from '../../../../assets/images/mobile.webp';
import VehicularControlPanel from '../../../../components/VehicularControlPanel';
import { fingersHandEnum } from '../../../../constants';
import {
  DeviceDetails,
  ActionsContainer,
  ActionButton,
  Badge,
  BadgeLabel,
  BadgeBlackLabel,
} from './styles';

const StatusBadge = ({ isRegistered, fingerprintStatus, data, t }) => {
  if (data.type === 'fingerPrint') {
    return (
      <Badge $withoutMarginBottom color={isRegistered ? 'primary' : 'danger'}>
        <BadgeLabel>
          {fingerprintStatus || t('put.devices.not-registered')}
        </BadgeLabel>
      </Badge>
    );
  }

  if (data.type === 'facial') {
    switch (data.facialStatus) {
      case 0:
        return (
          <Badge color="warning">
            <BadgeBlackLabel>
              {t('put.devices.facial-status-inprogress')}
            </BadgeBlackLabel>
          </Badge>
        );
      case 1:
        return (
          <Badge color="primary">
            <BadgeLabel>{t('put.devices.facial-status-success')}</BadgeLabel>
          </Badge>
        );
      case 2:
        return (
          <Badge color="danger">
            <BadgeLabel>{t('put.devices.facial-status-error')}</BadgeLabel>
          </Badge>
        );
      default:
        throw new Error('invalid_status_facial');
    }
  }

  if (!data.blocked || isRegistered) {
    return (
      <Badge color="primary">
        <BadgeLabel>{t('put.devices.active')}</BadgeLabel>
      </Badge>
    );
  }

  return (
    <Badge color="danger">
      <BadgeLabel>{t('put.devices.locked')}</BadgeLabel>
    </Badge>
  );
};

StatusBadge.propTypes = {
  data: propTypes.object.isRequired,
  t: propTypes.func.isRequired,
  isRegistered: propTypes.bool.isRequired,
  fingerprintStatus: propTypes.string,
};
StatusBadge.defaultProps = {
  fingerprintStatus: null,
};

const DeviceData = ({
  data,
  onEdit,
  onDelete,
  editMode,
  isEdition,
  onSync,
  personId,
  replace,
  index,
  gadgetInfoDetails,
}) => {
  const [t] = useTranslation('user');
  const { toast, swal } = useSwal();

  const gadgetInfo = data?.mobile?.gadgetInfo ?? {};

  const fingerprintStatus = {
    '1': t('put.devices.record-started'),
    '2': t('put.devices.record-error'),
    '3': t('put.devices.registered'),
    '4': t('put.devices.not-registered'),
  };

  const isRegistered = data.description === '3'; // 3 equals registered status

  const { isRf, isTag, isFingerprint, isFacial, isMobile } = useMemo(
    () => ({
      isRf: data.type === 'rf',
      isTag: data.type === 'tag',
      isFingerprint: data.type === 'fingerPrint',
      isFacial: data.type === 'facial',
      isMobile: data.type === 'mobile',
    }),
    [data.type],
  );

  const { hasAtLeastOneMobileParam } = useMemo(
    () => ({
      hasAtLeastOneMobileParam:
        isMobile &&
        (data?.mobile?.version ||
          data?.mobile?.name ||
          data?.mobile?.os ||
          data?.lastAccessDateTime),
    }),
    [data.type],
  );

  const isCreation = !data.id;

  const onError = err => {
    const formattedErrors = apolloErrorHandler(err);
    if (typeof formattedErrors === 'string') {
      toast.fire({ title: formattedErrors, icon: 'error' });
      return;
    }
    if (formattedErrors && formattedErrors.length) {
      toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
  };

  const [remove] = useMutation(DeviceData.deviceDelete, {
    onCompleted: () => {
      toast.fire({
        icon: 'success',
        title: t('put.devices.modal.delete.success'),
      });
      onDelete();
    },
    onError,
  });

  const [removeFingerprintV2] = useMutation(fingerprintRemoveV2, {
    onCompleted: () => {
      toast.fire({
        icon: 'success',
        title: t('put.devices.modal.delete.success'),
      });
      onDelete();
    },
    onError,
  });

  const onCompleted = () => {
    toast.fire({
      icon: 'success',
      title: t(
        `put.devices.modal.lock.${
          data.blocked ? 'unlocked' : 'locked'
        }-success`,
      ),
    });
    replace(index, { ...data, blocked: !data.blocked });
  };

  const [lockTag] = useMutation(DeviceData.lockTagDevice, {
    onCompleted,
    onError,
  });

  const [lockRf] = useMutation(DeviceData.lockRfDevice, {
    onCompleted,
    onError,
  });

  const [removeFacial] = useMutation(mutation.accessFacialRemove, {
    onCompleted: () => {
      toast.fire({
        icon: 'success',
        title: t('put.devices.modal.delete.success'),
      });
      onDelete();
    },
    onError,
  });

  const [removeFingerprintGeneric] = useMutation(accessFingerprintRemove, {
    onCompleted: () => {
      toast.fire({
        icon: 'success',
        title: t('put.devices.modal.delete.success'),
      });
      onDelete();
    },
    onError,
  });

  const handleDelete = async () => {
    await swal.fire({
      title: t('put.devices.modal.delete.title'),
      text: t('put.devices.modal.delete.text'),
      cancelButtonText: t('put.devices.modal.delete.cancel'),
      confirmButtonText: t('put.devices.modal.delete.confirm'),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        if (isFingerprint) {
          if (!data.value) {
            return removeFingerprintGeneric({
              variables: {
                personId: +personId,
                fingerprintId: +data.id,
              },
            });
          }

          return removeFingerprintV2({
            variables: { accessFingerprintId: +data?.id },
          });
        }

        if (isFacial)
          return removeFacial({ variables: { deviceId: +data.id } });

        if (!isCreation)
          return remove({ variables: { id: +data.id, type: data.type } });

        return onDelete();
      },
      allowOutsideClick: () => !swal.isLoading(),
    });
  };

  const handleLockTag = () =>
    lockTag({
      variables: {
        device: {
          personId,
          id: data.id,
          tagId: data.value,
          blocked: !data.blocked,
        },
      },
    });

  const handleLockRf = () =>
    lockRf({
      variables: {
        device: {
          personId,
          id: data.id,
          rfId: data.value,
          rfCounter: data.counter,
          blocked: !data.blocked,
        },
      },
    });

  const handleLock = async () => {
    await swal.fire({
      title: t(
        `put.devices.modal.lock.title-${data.blocked ? 'unlock' : 'lock'}`,
      ),
      text: t('put.devices.modal.lock.text'),
      cancelButtonText: t('put.devices.modal.lock.cancel'),
      confirmButtonText: t('put.devices.modal.lock.confirm'),
      showLoaderOnConfirm: true,
      preConfirm: () =>
        data.type === 'tag' ? handleLockTag() : handleLockRf(),
      allowOutsideClick: () => !swal.isLoading(),
    });
  };

  const fingerName = t(
    `put.devices.finger-names.${fingersHandEnum[data.fingerIndex]}`,
  );

  const isHikvisionFingerprint = isFingerprint && !data.value;

  const handleStatusBadge = useCallback(() => {
    if (!isCreation) {
      return <StatusBadge t={t} isRegistered={isRegistered} data={data} />;
    }
    return null;
  }, [isCreation]);

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      height="100%"
    >
      <>
        {onDelete && !isMobile ? (
          <Flex justifyContent="flex-end" width="100%">
            <KiperButton
              color="secondary"
              variant="text"
              onClick={handleDelete}
              rounded
              icon={<DeleteIcon />}
            />
          </Flex>
        ) : null}
      </>
      <DeviceDetails>
        {isMobile && hasAtLeastOneMobileParam && (
          <>
            <img
              height={80}
              style={{ marginTop: '40px' }}
              src={logoMobile}
              alt={t('put.devices.app')}
            />
            <Label center>{t('put.devices.app')}</Label>
            <Label center info>
              {`${data.mobile.os ?? ''} ${data.mobile.name ?? ''} ${data.mobile
                .version ?? ''}`}
            </Label>
            {data.lastAccessDateTime && (
              <Label center info size="12px">
                {data.lastAccessDateTime &&
                  t('put.devices.last-update-in', {
                    time:
                      formatDate(data.lastAccessDateTime, 'L - HH:mm')
                        .formatted ?? '',
                  })}
              </Label>
            )}

            {!!gadgetInfo && !!Object.keys(gadgetInfo).length && (
              <Flex mt={16}>
                <Button
                  type="submit"
                  name="save"
                  color="primary"
                  disabled={false}
                  onClick={gadgetInfoDetails}
                >
                  {t('gadget-details.more-infos')}
                </Button>
              </Flex>
            )}
          </>
        )}
        {isRf && (
          <VehicularControlPanel
            translate={t}
            formatDate={formatDate}
            device={data}
            canSync={editMode || !isEdition}
            onSync={onSync}
            statusBadge={handleStatusBadge}
          />
        )}
        {isTag && (
          <>
            <img src={logoTag} alt="Tag" />
            <Label center>
              Tag
              {data.description && ` - ${data.description}`}
            </Label>
            <Label center info>{`ID ${data.value}`}</Label>
            {(data.lastAcessPlace || data.lastAccessDateTime) && (
              <Label center info size="12px">
                {data.lastAcessPlace &&
                  `${t('put.devices.lastAccessPlace')} "${
                    data.lastAcessPlace
                  }"`}
                <br />
                {data.lastAccessDateTime &&
                  `${t('put.devices.lastAccessDateTime')} ${
                    formatDate(data.lastAccessDateTime, 'L - HH:mm').formatted
                  }`}
              </Label>
            )}
            {!isCreation && (
              <StatusBadge isRegistered={isRegistered} data={data} t={t} />
            )}
          </>
        )}
        {isFingerprint && (
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
            gridGap="8px"
            width="100%"
            height="100%"
          >
            <Flex flexDirection="column" gridGap="8px" alignItems="center">
              <img
                width="76"
                height="76"
                style={{ marginBottom: '0' }}
                src={logoBiometry}
                alt="biometry"
              />
              <Label $withoutMarginBottom center>
                {`${t('put.devices.biometry')} ${
                  data.fingerIndex ? `- ${fingerName}` : ''
                }`}
              </Label>
              <Label $withoutMarginBottom center info>{`ID ${data.id}`}</Label>
              {data.value && (
                <>
                  <Label $withoutMarginBottom center info>
                    {`${data.value}`}
                  </Label>
                  <Label $withoutMarginBottom center info size="12px">
                    {data.lastAcessPlace &&
                      `${t('put.devices.lastAccessPlace')} "${
                        data.lastAcessPlace
                      }"`}
                    <br />
                    {data.lastAccessDateTime &&
                      `${t('put.devices.lastAccessDateTime')} ${
                        formatDate(data.lastAccessDateTime, 'L - HH:mm')
                          .formatted
                      }`}
                  </Label>
                </>
              )}
            </Flex>
            <Flex gridGap="4px">
              {data.panic && (
                <Badge $withoutMarginBottom color="eletricOrange">
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    gridGap="4px"
                  >
                    <MdNotificationsActive size={16} color="white" />
                    <BadgeLabel>{t('put.devices.panic-finger')}</BadgeLabel>
                  </Flex>
                </Badge>
              )}

              {!isCreation && (
                <StatusBadge
                  fingerprintStatus={fingerprintStatus[data.description]}
                  isRegistered={isRegistered}
                  data={data}
                  t={t}
                />
              )}
            </Flex>
          </Flex>
        )}

        {isFacial && (
          <>
            <img width="90" src={logoFacial} alt="facial" />
            <Label center>{t('put.devices.facial')}</Label>
            {(data.lastAcessPlace || data.lastAccessDateTime) && (
              <Label center info size="12px">
                {data.lastAcessPlace &&
                  `${t('put.devices.lastAccessPlace')} "${
                    data.lastAcessPlace
                  }"`}
                <br />
                {data.lastAccessDateTime &&
                  `${t('put.devices.lastAccessDateTime')} ${
                    formatDate(data.lastAccessDateTime, 'L - HH:mm').formatted
                  }`}
              </Label>
            )}
            {!isCreation && (
              <StatusBadge isRegistered={isRegistered} data={data} t={t} />
            )}
          </>
        )}
      </DeviceDetails>
      <ActionsContainer>
        {!(isHikvisionFingerprint || isMobile) && (
          <Button
            type="button"
            color="primary"
            onClick={() => {
              return onEdit(data);
            }}
            disabled={data.blocked}
          >
            {t('put.devices.edit')}
          </Button>
        )}
        {!(isCreation || isFingerprint || isFacial || isMobile) && (
          <ActionButton
            outline
            color={data.blocked ? 'secondary' : 'danger'}
            onClick={handleLock}
          >
            <span>
              {data.blocked
                ? t('put.devices.unlock-button')
                : t('put.devices.lock-button')}
            </span>
          </ActionButton>
        )}
      </ActionsContainer>
    </Flex>
  );
};

export default DeviceData;

DeviceData.propTypes = {
  data: propTypes.object.isRequired,
  onEdit: propTypes.func.isRequired,
  onDelete: propTypes.func,
  editMode: propTypes.bool,
  isEdition: propTypes.bool.isRequired,
  onSync: propTypes.func.isRequired,
  personId: propTypes.any,
  replace: propTypes.func.isRequired,
  index: propTypes.number.isRequired,
  gadgetInfoDetails: propTypes.func.isRequired,
};

DeviceData.defaultProps = {
  editMode: false,
  onDelete: null,
  personId: null,
};

DeviceData.deviceDelete = gql`
  mutation deviceDelete($id: Int!, $type: String!) {
    deviceDelete(id: $id, type: $type)
  }
`;

DeviceData.lockTagDevice = gql`
  mutation lockTagDevice($device: LockTagInput) {
    lockTagDevice(device: $device)
  }
`;

DeviceData.lockRfDevice = gql`
  mutation lockRfDevice($device: LockRfInput) {
    lockRfDevice(device: $device)
  }
`;
