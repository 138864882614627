import propTypes from 'prop-types';
import { Container } from './styles';

const Tabbar = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Tabbar;

Tabbar.propTypes = {
  children: propTypes.any.isRequired,
};
