import styled from 'styled-components';
import { Text as text } from '@kiper/ui';

export const Flex = styled.div.attrs({ className: 'd-flex' })``;

export const Footer = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

export const FooterActions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const Text = styled(text)`
  display: block;
  white-space: break-spaces;
  word-break: break-word;
  line-break: auto;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400px;
  letter-spacing: 0.5px;
  line-height: 1.5;
  font-weight: 500;
`;

export const FlexJustifyCenter = styled(Flex).attrs({
  className: 'w-75 justify-content-between',
})``;

export const FlexAlignCenter = styled(Flex).attrs({
  className: 'align-items-center',
})``;

export const PureSelect = styled.select.attrs({
  className: 'form-control',
})``;

export const SelectTitle = styled.select.attrs({
  className: 'card-title',
});
