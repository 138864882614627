import { useState } from 'react';
import { useMutation } from 'react-apollo';

import { newClickToCall } from '@kiper/monitoring-graphql/attendance';
import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';

import { sendAmplitudeData } from '../services/amplitude';

import { destinationTypesByCode } from '../constants';

const useVoice = () => {
  const [feedback, setFeedback] = useState(null);
  const { toast } = useSwal();

  const [fetch, { loading }] = useMutation(newClickToCall, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      toast.fire({
        title: feedback,
        icon: 'success',
      });
      setFeedback(null);
    },
    onError: ({ networkError, graphQLErrors }) => {
      toast.fire({
        title: apolloErrorHandler({ networkError, graphQLErrors }),
        icon: 'error',
      });
      setFeedback(null);
    },
  });

  const clickToCall = ({
    feedbackText,
    voiceCallInput,
    eventId,
    eventCode,
    partner,
    condominium,
    amplitudeKey = 'click to call',
  }) => {
    sendAmplitudeData(amplitudeKey, {
      eventId,
      eventCode,
      partner,
      condominium,
      destination: voiceCallInput?.destination,
      type: destinationTypesByCode[`${voiceCallInput?.destinationKind}`],
    });

    setFeedback(feedbackText);
    fetch({
      variables: {
        voiceCallInput,
        eventId,
      },
    });
  };

  return { clickToCall, loading };
};

export default useVoice;
