import propTypes from 'prop-types';
import { Breadcrumb, Flex } from '@kiper/ui';
import { MdArrowBack } from 'react-icons/md';
import HeaderBody from '../HeaderBody';
import * as S from './styles';

const PageHeader = ({
  breadcrumb,
  title,
  headerButton,
  children,
  tabHeaderPage,
  searchUrlHistory,
  t,
}) => {
  const link = breadcrumb.length > 1 && breadcrumb.find(x => !!x.to);

  return (
    <HeaderBody
      mx="-25px"
      background="white"
      withPaddingBottom={!!children || !tabHeaderPage}
    >
      <>
        <Flex pl={link?.to ? '54px' : '24px'} pr="24px" flexDirection="column">
          {link?.to && (
            <Breadcrumb
              searchUrlHistory={searchUrlHistory}
              background="white"
              stack={breadcrumb}
              formatter={t}
            />
          )}
          <Flex
            alignItems="flex-start"
            gridGap="16px"
            ml={link?.to ? '-29px' : '0'}
          >
            {link?.to && (
              <Flex pt="8px" ml="-8px">
                <S.Link to={`${link?.to}${searchUrlHistory}`}>
                  <MdArrowBack size={20} />
                </S.Link>
              </Flex>
            )}
            <Flex
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="flex-start"
              width="100%"
              height="auto"
              gridGap="16px"
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                height="auto"
              >
                <S.Title>{title}</S.Title>
                {!!headerButton && headerButton}
              </Flex>
              {!!tabHeaderPage && tabHeaderPage}
            </Flex>
          </Flex>
        </Flex>
        {!!children && children}
      </>
    </HeaderBody>
  );
};

export default PageHeader;

PageHeader.propTypes = {
  breadcrumb: propTypes.array.isRequired,
  t: propTypes.func.isRequired,
  title: propTypes.oneOfType([
    propTypes.node,
    propTypes.element,
    propTypes.string,
  ]),
  searchUrlHistory: propTypes.string,
  children: propTypes.oneOfType([propTypes.node, propTypes.element]),
  headerButton: propTypes.element,
  tabHeaderPage: propTypes.element,
};

PageHeader.defaultProps = {
  children: null,
  tabHeaderPage: null,
  headerButton: null,
  title: '',
  searchUrlHistory: '',
};
