import styled from 'styled-components';
import { FormGroup as formGroup, Form as form } from 'reactstrap';

export const FormGroup = styled(formGroup)`
  margin-bottom: 12px;
`;

export const Form = styled(form)``;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;
