import PropTypes from 'prop-types';
import { UsersReport } from './Users';
import { EventsReport } from './EventsReport';
import { VehiclesReport } from './VehiclesReport';

const ReportResult = ({ type, ...restProps }) => {
  switch (type) {
    case 'usersReport':
      return <UsersReport {...restProps} />;
    case 'eventsReport':
      return <EventsReport {...restProps} />;
    case 'vehiclesReport':
      return <VehiclesReport {...restProps} />;
    default:
      return <></>;
  }
};

ReportResult.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ReportResult;
