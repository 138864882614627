import SystemUsersPage from './List';
import SystemUserPut from './Put';

const breadcrumb = (label, to) => ({ label, to });

const requiredPermissions = ['systemUsersManagement'];

export default [
  {
    name: 'List of system users',
    path: '/system-users',
    exact: true,
    component: SystemUsersPage,
    breadcrumb: [breadcrumb('common:breadcrumb.system-users', '/system-users')],
    requiredPermissions,
  },
  {
    name: 'System user register',
    path: '/system-user/register',
    exact: true,
    component: SystemUserPut,
    breadcrumb: [
      breadcrumb('common:breadcrumb.system-users', '/system-users'),
      breadcrumb('common:breadcrumb.create', '/system-users/register'),
    ],
    requiredPermissions,
  },
  {
    name: 'System user detail',
    path: '/system-user/:personId',
    exact: true,
    component: SystemUserPut,
    breadcrumb: [
      breadcrumb('common:breadcrumb.system-users', '/system-users'),
      breadcrumb('common:breadcrumb.edit', '/system-user/:personId'),
    ],
    requiredPermissions,
  },
];
