import { useContext, useRef, useState } from 'react';
import propTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useFilters } from '@kiper/hooks';

import { Stepper } from '@kiper/ui';

import context from '../../../pages/Condominiums/Detail/CondominiumDetailContext';

import ChangeAccessProfileModalSelectResidents from './ChangeAccessProfileModalSelectResidents';
import { Modal } from './styles';
import ChangeAccessProfileModalSelectProfile from './ChangeAccessProfileModalSelectProfile';
import ChangeAccessProfileModalConfirm from './ChangeAccessProfileModalConfirm';

function ChangeAccessProfileModal({ handleToggle }) {
  const { t } = useTranslation('condominium');

  const [activeStep, setActiveStep] = useState(0);

  const columns = useRef([
    {
      name: t('details.modal.access-profile.change-profile.table.name'),
      align: 'left',
      width: '50%',
    },
    {
      name: t('details.modal.access-profile.change-profile.table.unity-group'),
      align: 'left',
      width: '25%',
    },
  ]);

  const [unity, setUnity] = useState(null);
  const [accessProfile, setAccessProfile] = useState([]);
  const [accessProfileTo, setAccessProfileTo] = useState(null);

  const { condominium } = useContext(context);

  const initialFilters = {
    page: 1,
    pagesize: 5,
    orderby: 'condominiumName',
    ordination: false,
    sourceNodeId: condominium.treeNodeId,
    searchText: '',
    accessProfileIds: [],
  };

  const { filters, handlePageChange, handlePageSize, setFilters } = useFilters(
    initialFilters,
  );

  const steps = [
    {
      title: t('details.modal.access-profile.change-profile.select-residents'),
      component: ChangeAccessProfileModalSelectResidents,
      props: {
        columns,
        condominium,
        handlePageChange,
        handlePageSize,
        filters,
        setFilters,
        accessProfile,
        setAccessProfile,
        unity,
        setUnity,
        setActiveStep,
        handleToggle,
      },
    },
    {
      title: t('details.modal.access-profile.change-profile.access-profile'),
      component: ChangeAccessProfileModalSelectProfile,
      props: {
        columns,
        condominium,
        handlePageChange,
        handlePageSize,
        filters,
        accessProfileTo,
        setAccessProfileTo,
        setActiveStep,
      },
    },
    {
      title: t('details.modal.access-profile.change-profile.confirmation'),
      component: ChangeAccessProfileModalConfirm,
      props: {
        columns,
        handlePageChange,
        handlePageSize,
        filters,
        setFilters,
        accessProfile,
        accessProfileTo,
        setActiveStep,
        handleToggle,
      },
    },
  ];

  return (
    <Modal open toggle={handleToggle} size="xl">
      <Stepper
        activeStep={activeStep}
        setActiveStep={() => {}}
        steps={steps}
        px="24px"
        pt="24px"
      />
    </Modal>
  );
}

export default ChangeAccessProfileModal;

ChangeAccessProfileModal.propTypes = {
  handleToggle: propTypes.func.isRequired,
};
