import propTypes from 'prop-types';
import { Spinner } from '@kiper/ui';
import AttendancePlayback from './AttendancePlayback';
import AttendanceCall from './AttendanceCall';

import * as S from './styles';

const GuidedAttendanceDetailInfo = ({ event, componentName, loading }) => {
  const component = {
    AttendanceCall: <AttendanceCall event={event} />,
    AttendancePlayback: <AttendancePlayback event={event} />,
  };

  return (
    <S.Wrapper>
      {loading ? (
        <S.LoadingContainer>
          <Spinner size={28} color="highBlack" />
        </S.LoadingContainer>
      ) : (
        component[componentName]
      )}
    </S.Wrapper>
  );
};

export default GuidedAttendanceDetailInfo;

GuidedAttendanceDetailInfo.propTypes = {
  event: propTypes.object,
  componentName: propTypes.string.isRequired,
  loading: propTypes.bool.isRequired,
};

GuidedAttendanceDetailInfo.defaultProps = {
  event: {},
};
