import { formatUtcHours } from '@kiper/fns';
import { userProfile } from '../../../constants';

const formatDaysOfWeek = days => {
  const daysOfWeek = [];

  days.forEach((day, index) => day && daysOfWeek.push(index));

  return daysOfWeek;
};

export const scrollTo = (reference, params) =>
  reference.current?.scrollIntoView({
    behavior: 'smooth',
    block: 'end',
    inline: 'nearest',
    ...params,
  });

export const formatAllowedAccesses = allowedAccesses => {
  const allowedAccessFormated = allowedAccesses
    ? allowedAccesses.map(y => ({
        ...y,
        start: {
          ...y.start,
          hours: formatUtcHours({ time: y.start.time, localToUtc00: false }),
          time: `${formatUtcHours({
            time: y.start.time,
            localToUtc00: false,
          })}:${y.start.time.split(':')[1]}`,
        },
        end: {
          ...y.end,
          hours: formatUtcHours({ time: y.end.time, localToUtc00: false }),
          time: `${formatUtcHours({ time: y.end.time, localToUtc00: false })}:${
            y.end.time.split(':')[1]
          }`,
        },
      }))
    : [];

  return allowedAccessFormated;
};

export const formatAccess = (access, isFieldVisible) => {
  const formattedAccess = {
    nodeProfile: {
      profileId: Number(access.profile.value),
      parentTreeNodeId: access.unity?.treeNodeId
        ? Number(access.unity.treeNodeId)
        : Number(access.condominium.value),
      newUnityGroup: access.unityGroup?.isNew
        ? {
            parentTreeNodeId: Number(access.condominium.value),
            placeName: access.unityGroup?.label,
          }
        : null,
      newUnity: access.unity?.isNew
        ? {
            parentTreeNodeId: Number(access.unityGroup?.treeNodeId),
            placeName: access.unity?.label,
          }
        : null,
    },
    accessProfiles: access.accessProfiles.map(ap => Number(ap.value)),
    accessibilityTime: access.accessibilityTime ?? 3,
    permissions: access?.permissions?.some(
      p => p.ableToReadIsUpdated || p.ableToWriteIsUpdated,
    )
      ? access.permissions.map(y => {
          const result = y;
          delete result.profilePermissionId;
          delete result.name;
          delete result.ableToReadIsUpdated;
          delete result.ableToWriteIsUpdated;
          delete result.fieldName;
          delete result.__typename; // eslint-disable-line
          return result;
        })
      : null,
    allowedAccesses: isFieldVisible?.('allowedAccesses', access.profile.value)
      ? access.allowedAccesses?.map(y => {
          const gmt = (new Date().getTimezoneOffset() / 60) * -1;
          return {
            ...y,
            start: {
              hours: Number(y.start.hours),
              minutes: Number(y.start.minutes),
            },
            end: {
              hours: Number(y.end.hours),
              minutes: Number(y.end.minutes),
            },
            daysOfWeek: formatDaysOfWeek(y.daysOfWeek),
            gmt,
          };
        })
      : null,
    contactSchedule:
      access.profile.value === userProfile.janitor &&
      !!access?.contactSchedule?.length
        ? access.contactSchedule.map(y => {
            const gmt = (new Date().getTimezoneOffset() / 60) * -1;
            return {
              ...y,
              start: {
                hours: Number(y.start.hours),
                minutes: Number(y.start.minutes),
              },
              end: {
                hours: Number(y.end.hours),
                minutes: Number(y.end.minutes),
              },
              daysOfWeek: formatDaysOfWeek(y.daysOfWeek),
              gmt,
            };
          })
        : null,
    callPriority: access.callPriority ?? 1,
    observation: access.observation,
    placeTemporaryAccess: access.placeTemporaryAccess.map(tempAccess => ({
      startDate: tempAccess.startDate.replace(' ', 'T'),
      endDate: tempAccess.endDate.replace(' ', 'T'),
      place: tempAccess.place.map(p => ({
        id: Number(p.id),
        temporaryAccessId: p.temporaryAccessId
          ? Number(p.temporaryAccessId)
          : null,
      })),
    })),
    bookingTime:
      isFieldVisible?.('bookingTime', access.profile.value) &&
      !!access.bookingTime?.initDate &&
      !!access.bookingTime?.endDate
        ? access.bookingTime
        : null,
    contactOrder: access.contactOrder,
  };

  return formattedAccess;
};

export const formatAccesses = (accesses, isFieldVisible) => {
  return accesses.map(x => formatAccess(x, isFieldVisible));
};
