import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { insertStepHistory } from '@kiper/monitoring-graphql/guided_attendance';
import { useMutation } from 'react-apollo';

const useMutations = () => {
  const { toast } = useSwal();

  const [stepHistory] = useMutation(insertStepHistory, {
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  return { stepHistory };
};

export default useMutations;
