import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { eventGenerator } from '@kiper/monitoring-graphql';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';

const useEventGeneratorMutations = () => {
  const [t] = useTranslation('event-generator');
  const { toast } = useSwal();

  const onCompleted = () =>
    toast.fire({ title: t('feedback.success'), icon: 'success' });

  const onError = err => {
    const formattedErrors = apolloErrorHandler(err);
    if (formattedErrors && formattedErrors.length) {
      toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
  };

  const [answeredCall, { loading: loadingAnsweredCall }] = useMutation(
    eventGenerator.mutation.answeredCall,
    {
      onCompleted,
      onError,
    },
  );

  const [
    createEventTemplate,
    { loading: loadingCreateEventTemplate },
  ] = useMutation(eventGenerator.mutation.eventGenerate, {
    onCompleted,
    onError,
  });

  return {
    answeredCall,
    createEventTemplate,
    loadingSubmit: loadingAnsweredCall || loadingCreateEventTemplate,
  };
};

export default useEventGeneratorMutations;
