import { ErrorMessage, Input, Label } from '@kiper/ui';
import propTypes from 'prop-types';
import { FormGroup } from './styles';

const UserDeviceRf = ({ formik, t, existDevice }) => {
  const { values, setFieldValue, touched, errors } = formik;
  return (
    <>
      <FormGroup>
        <Label>{t('put.devices.modal.insert.insert-id')}</Label>
        <Input
          type="text"
          className="form-control"
          placeholder={t(
            'put.devices.modal.insert.vehicular-control-id-placeholder',
          )}
          name="value"
          value={values.value}
          onChange={setFieldValue}
          invalid={(touched.value && !!errors.value) || existDevice}
          disabled={!values.type || !!values.id}
        />
        {values.type && touched.value && errors.value && (
          <ErrorMessage>{errors.value}</ErrorMessage>
        )}
        {existDevice && (
          <ErrorMessage>
            {t('put.devices.modal.insert.errors.device-exist')}
          </ErrorMessage>
        )}
      </FormGroup>
      <FormGroup>
        <Label>{t('put.devices.modal.insert.input-counter')}</Label>
        <Input
          type="number"
          className="form-control"
          name="counter"
          value={values.counter}
          onChange={(field, value) => setFieldValue(field, Number(value || 0))}
          invalid={touched.counter && !!errors.counter}
        />
        {touched.counter && errors.counter && (
          <ErrorMessage>{errors.counter}</ErrorMessage>
        )}
      </FormGroup>
      <FormGroup>
        <Label>{t('put.devices.modal.insert.input-description')}</Label>
        <Input
          type="text"
          className="form-control"
          name="description"
          value={values.description}
          onChange={(field, value) => setFieldValue(field, value)}
          invalid={touched.description && !!errors.description}
        />
        {touched.description && errors.description && (
          <ErrorMessage>{errors.description}</ErrorMessage>
        )}
      </FormGroup>
    </>
  );
};

export default UserDeviceRf;

UserDeviceRf.propTypes = {
  formik: propTypes.shape({
    values: propTypes.object,
    errors: propTypes.object,
    touched: propTypes.object,
    setFieldValue: propTypes.func,
  }).isRequired,
  t: propTypes.func.isRequired,
  existDevice: propTypes.bool.isRequired,
};
