import { useMemo } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { auth } from '@kiper/monitoring-graphql';
import { types, get, set, remove } from '@kiper/cookie';

export default function useCurrentLoggedContext() {
  const { data: whoData, loading: loadingWho } = useQuery(auth.whoAmIQuery, {
    fetchPolicy: 'cache-first',
  });
  const { data: currentLoggedData, loading: loadingCurrent } = useQuery(
    auth.currentLoggedContext,
  );
  const [changeContextFn, { loading: loadingSettingCookies }] = useMutation(
    auth.switchContextMutation,
  );

  const loading = useMemo(() => loadingWho || loadingCurrent, [
    loadingWho,
    loadingCurrent,
  ]);

  const lastContextId = get(types.lastContext);

  const currentLogged = useMemo(
    () => currentLoggedData && currentLoggedData.currentLoggedContext,
    [currentLoggedData],
  );

  const loggedContext = useMemo(() => {
    if (!whoData && !currentLoggedData) return null;

    if (lastContextId && whoData) {
      const context = whoData.whoAmI.find(
        x => x.personContextId === lastContextId,
      );

      if (context) {
        if (!loading && currentLogged?.personContextId !== lastContextId) {
          changeContextFn({ variables: { currentLoggedContext: context } });
        }
        return context;
      }

      remove(types.lastContext);
    }

    set(types.lastContext, currentLogged?.personContextId);

    return currentLogged;
  }, [lastContextId, whoData, currentLoggedData]);

  return {
    loggedContext,
    loading,
    loadingSettingCookies,
  };
}
