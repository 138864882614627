import { useState } from 'react';
import propTypes from 'prop-types';
import { ContainedTab, TabContent, TabPane } from '@kiper/ui';
import { MdOutlineCameraAlt, MdOutlineMap } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import AttendanceInfo from './AttendanceInfo';
import VideoCamerasGrid from '../../../../../components/VideoCamerasGrid';
import CondoMap from './CondoMap';
import * as S from './styles';

const TabsEnum = Object.freeze({
  CAMERAS: 'cams',
  MAP: 'map',
});

const tabs = t => [
  {
    tabId: TabsEnum.CAMERAS,
    title: t('details-tabs.cameras'),
    showTab: true,
    icon: <MdOutlineCameraAlt size={28} />,
  },
  {
    tabId: TabsEnum.MAP,
    title: t('details-tabs.map'),
    showTab: true,
    icon: <MdOutlineMap size={28} />,
  },
];

const AttendanceCall = ({ event }) => {
  const { t } = useTranslation('guided-attendance');

  const [activeTab, setActiveTab] = useState(TabsEnum.CAMERAS);

  const handleChangeTab = tab => {
    setActiveTab(tab);
  };

  return (
    <>
      <AttendanceInfo event={event} />
      <S.AttendanceAssetsContainer>
        <ContainedTab
          activeTab={activeTab}
          tabs={tabs(t)}
          onChangeTab={handleChangeTab}
        />
        <TabContent activeTab={activeTab}>
          {activeTab === TabsEnum.CAMERAS && (
            <TabPane tabId={TabsEnum.CAMERAS}>
              <S.GridContainer>
                <VideoCamerasGrid eventId={event?.eventId} event={event} />
              </S.GridContainer>
            </TabPane>
          )}
          {activeTab === TabsEnum.MAP && (
            <TabPane tabId={TabsEnum.MAP}>
              <S.MapWrapper>
                <CondoMap event={event} isShow={TabsEnum.MAP === activeTab} />
              </S.MapWrapper>
            </TabPane>
          )}
        </TabContent>
      </S.AttendanceAssetsContainer>
    </>
  );
};

export default AttendanceCall;

AttendanceCall.propTypes = {
  event: propTypes.object,
};

AttendanceCall.defaultProps = {
  event: {},
};
