import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { MdRemove } from 'react-icons/md';
import { Button, ErrorMessage, Label } from '@kiper/ui';
import propTypes from 'prop-types';
import CondominiumAccessProfileSelect from '../../../../../../components/CondominiumAccessProfileSelect';
import { FormGroup } from '../../../styles';
import { AccessProfileContainer, Actions } from './styles';

export default function AccessProfiles({
  values,
  handleChange,
  isCreation,
  editMode,
  touched,
  errors,
}) {
  const selectAccessProfileRef = useRef();
  const [t] = useTranslation('user');

  const onChangeAccessProfile = (accessProfileId, index) => {
    const newAccessProfiles = [...values.accessProfiles];
    newAccessProfiles.splice(index, 1, accessProfileId);
    handleChange('accessProfiles', newAccessProfiles);
  };

  const removeAccessProfile = index => {
    const newAccessProfiles = [...values.accessProfiles];
    newAccessProfiles.splice(index, 1);
    handleChange('accessProfiles', newAccessProfiles);
  };

  useEffect(() => {
    if (selectAccessProfileRef?.current?.value) {
      const accessProfiles = [selectAccessProfileRef.current.value];
      handleChange('accessProfiles', accessProfiles);
    }
    return () => null;
  }, [selectAccessProfileRef?.current?.value]);

  return (
    <FormGroup>
      <Label info block>
        {t('put.access-data.access-profile')}
      </Label>
      {!!values.accessProfiles.length &&
        values.accessProfiles.map((accessProfile, index) => (
          <AccessProfileContainer key={index}>
            <CondominiumAccessProfileSelect
              ref={selectAccessProfileRef}
              personContextId={values?.condominium?.personContextId || null}
              placeholder={t('put.access-data.access-profile-select')}
              except={values.accessProfiles}
              value={accessProfile}
              onChange={e => onChangeAccessProfile(e, index)}
              name={`accessProfile-${index}`}
              invalid={
                !!values.condominium &&
                touched?.accessProfiles?.[index] &&
                !!errors?.accessProfiles?.[index]
              }
              editMode={!isCreation && !editMode}
            />
            {(isCreation || editMode) && (
              <Actions>
                {values.accessProfiles.length > 1 && (
                  <Button
                    color="danger"
                    outline
                    icon
                    radius
                    disabled={!values.condominium}
                    onClick={() => removeAccessProfile(index)}
                  >
                    <MdRemove />
                  </Button>
                )}
              </Actions>
            )}
          </AccessProfileContainer>
        ))}
      {!!values.condominium &&
        touched?.accessProfiles &&
        !!errors.accessProfiles && (
          <ErrorMessage>{t('common:feedback.required-field')}</ErrorMessage>
        )}
    </FormGroup>
  );
}

AccessProfiles.propTypes = {
  values: propTypes.object.isRequired,
  touched: propTypes.object,
  errors: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  isCreation: propTypes.bool.isRequired,
  editMode: propTypes.bool.isRequired,
};

AccessProfiles.defaultProps = {
  touched: undefined,
};
