import { useEffect, useMemo } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-apollo';
import { apolloErrorHandler } from '@kiper/fns';
import {
  queries,
  mutations,
} from '@kiper/monitoring-graphql/condominium/detail';
import { useSwal } from '@kiper/hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Form, FormGroup } from 'reactstrap';
import { Page, Breadcrumb, Input as CustomInput } from '@kiper/ui';
import { FiAlertCircle as AlertIcon } from 'react-icons/fi';
import useCurrentLoggedContext from '../../../../hooks/useCurrentLoggedContext';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Label,
  Flex,
  Button,
  CancelButton,
  Alert,
} from './styles';

const MESSAGE_ALIAS = {
  emailToAskTagRf: 'details.generalEmailSettings',
};

export default function EmailToAsk({ route }) {
  const [t] = useTranslation('system-settings');
  const history = useHistory();
  const location = useLocation();
  const { toast } = useSwal();
  const { loggedContext } = useCurrentLoggedContext();

  const personContextId = useMemo(() => {
    return +loggedContext?.partner?.personContextId;
  }, [loggedContext]);

  const { content, messageAlias } = useMemo(() => {
    return {
      content: location.state ?? {},
      messageAlias: MESSAGE_ALIAS[`${location?.state?.name}`],
    };
  }, [location]);

  const breadcrumb = useMemo(() => {
    const modifiedBreadcrumb = [...route.breadcrumb];
    modifiedBreadcrumb.splice(modifiedBreadcrumb.length - 1, 1);
    modifiedBreadcrumb.push({
      label: t(`${messageAlias}.breadcrumb`),
      to: '',
    });
    return modifiedBreadcrumb;
  }, [route]);

  const handleBack = () => history.goBack();

  const onError = err => {
    const formattedErrors = apolloErrorHandler(err);
    if (formattedErrors?.length) {
      toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
  };

  const { data } = useQuery(queries.personContextParams, {
    fetchPolicy: 'no-cache',
    variables: {
      personContextId,
      paramName: content?.name,
    },
  });

  const [onSave, { loading }] = useMutation(
    mutations.personContextParamsCreate,
    {
      onError,
      onCompleted: () => {
        toast.fire({
          icon: 'success',
          title: t('details.generalEmailSettings.updated-successfully'),
        });
      },
    },
  );

  const save = values => {
    return onSave({
      variables: {
        data: {
          personContextId,
          name: content?.name,
          value: JSON.stringify(values.email.trim()),
        },
      },
    });
  };

  const {
    values,
    setFieldValue,
    handleSubmit,
    errors,
    isValid,
    dirty,
    setValues,
  } = useFormik({
    initialValues: { email: '' },
    validationSchema: yup.object({
      email: yup
        .string()
        .email(t('details.generalEmailSettings.feedback-email'))
        .matches(
          /^[\d\w@.\-/+]*$/,
          t('details.generalEmailSettings.feedback-email'),
        )
        .nullable(),
    }),
    onSubmit: save,
  });

  useEffect(() => {
    if (data && data.personContextParams) {
      setValues({
        email: data.personContextParams?.value?.replace(/('|")/g, '') ?? '',
      });
    }
  }, [data]);

  return (
    <Page>
      <Breadcrumb stack={breadcrumb} formatter={t} />
      <Page.Title>{t(`${messageAlias}.title`)}</Page.Title>
      <hr />
      <Card>
        <CardHeader>
          <CardTitle>
            {t('details.generalEmailSettings.email-ask-title')}
          </CardTitle>
        </CardHeader>
        <CardBody>
          {content?.description && (
            <Alert>
              <AlertIcon />
              <Label>{content?.description}</Label>
            </Alert>
          )}
          <Form onSubmit={handleSubmit}>
            <Label>{t('details.generalEmailSettings.email-ask-label')}</Label>
            <FormGroup>
              <CustomInput
                id="email"
                name="email"
                type="text"
                className="form-control"
                placeholder={t('details.generalEmailSettings.enter-email')}
                value={values.email}
                onChange={setFieldValue}
                invalid={!!errors.email}
              />
            </FormGroup>
            <Flex justify="flex-end" margin="15px 0 0 0">
              <CancelButton
                type="button"
                color="secondary"
                outline
                onClick={handleBack}
              >
                {t('buttons:cancel')}
              </CancelButton>
              <Button
                type="submit"
                loading={loading}
                color="primary"
                className="mr-3"
                disabled={loading || !(isValid && dirty)}
              >
                {loading ? t('buttons:saving') : t('buttons:save')}
              </Button>
            </Flex>
          </Form>
        </CardBody>
      </Card>
    </Page>
  );
}

EmailToAsk.propTypes = {
  route: propTypes.object.isRequired,
};
