import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  width: 100%;
`;

export const TableContainer = styled.div`
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgba(72, 94, 144, 0.16);
`;
