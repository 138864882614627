import propTypes from 'prop-types';
import * as S from '../styles';

const unityDetailsDescription = t => ({
  unityGroup: t('pending-approval.unity-group'),
  unityGroupDefaultAccessProfile: t(
    'pending-approval.unity-group-default-access-profile',
  ),
  unity: t('pending-approval.unity'),
  unityDefaultAccessProfile: t('pending-approval.unity-default-access-profile'),
  usersPendingApproval: t('pending-approval.users-pending-approval'),
});

const HeaderCollapse = ({ t, headerInfo }) => {
  const headerInfoEntries = Object.entries(headerInfo) || [];
  return (
    <S.HeaderWrapper>
      {headerInfoEntries &&
        headerInfoEntries.map(info => {
          const [contentDescription, contentValue] = info;
          return (
            <S.UnityDetailsItem
              percentageWidth={100 / (headerInfoEntries.length || 1)}
              key={unityDetailsDescription(t)[contentDescription]}
            >
              <S.UnityDetailsTextContent>
                {contentValue || '--'}
              </S.UnityDetailsTextContent>
              <S.UnityDetailsTextDescription>
                {unityDetailsDescription(t)[contentDescription]}
              </S.UnityDetailsTextDescription>
            </S.UnityDetailsItem>
          );
        })}
    </S.HeaderWrapper>
  );
};

HeaderCollapse.propTypes = {
  t: propTypes.func.isRequired,
  headerInfo: propTypes.any.isRequired,
};
export default HeaderCollapse;
