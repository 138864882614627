import { useState, useMemo } from 'react';
import propTypes from 'prop-types';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormGroup, Form } from 'reactstrap';

import { MdAdd, MdArrowBack } from 'react-icons/md';
import {
  Label,
  Select,
  Flex,
  KiperButton,
  Button,
  JsonTree,
  ErrorMessage,
  Toggler,
  Text,
} from '@kiper/ui';
import { useCurrentLoggedContext, useRemoteConfig } from '../../hooks';
import {
  useEventGeneratorFields,
  useEventGeneratorQueries,
  useEventGeneratorSubmitForm,
  useEventGeneratorTemplate,
  useEventGeneratorValidationSchema,
} from './hooks';

import { eventTypes } from '../../constants';

import PersonSelect from '../../components/PersonSelect';
import PageHeader from '../../components/PageHeader';
import EventFields from './EventFields';
import EventRegister from './EventRegister';

const EventGenerator = ({ route, history }) => {
  const { loggedContext } = useCurrentLoggedContext();
  const [creationMode, setCreationMode] = useState(false);
  const [t] = useTranslation('event-generator');

  const ableToGenerator = useRemoteConfig({
    path: 'eventGenerator.ableGenerator',
    partner: Number(loggedContext?.partner?.personContextId),
    user: Number(loggedContext?.personContextId),
  });

  if (!ableToGenerator) {
    history.push('/');
  }

  const { eventTemplate, handleChangeTemplate } = useEventGeneratorTemplate();

  const {
    eventTemplateOptions,
    loadingEventTemplates,
    condominium,
    setCondominium,
  } = useEventGeneratorQueries();

  const {
    validationSchema,
    videoCall,
    setVideoCall,
  } = useEventGeneratorValidationSchema();

  const { onSubmit, loadingSubmit } = useEventGeneratorSubmitForm(
    eventTemplate,
    condominium,
    videoCall,
  );

  const { handleSubmit, values, setFieldValue, errors, touched } = useFormik({
    initialValues: {
      deviceExtension: '',
      sipAccountId: '',
      template: {},
    },
    validationSchema: validationSchema(eventTemplate?.eventType),
    onSubmit,
  });

  const {
    handleGetEventFields,
    handleChangeVideoCall,
  } = useEventGeneratorFields(values);

  const templateErrors = useMemo(
    () =>
      errors?.template &&
      Object.keys(errors?.template).map(key => errors?.template[key]),
    [errors],
  );

  return (
    <>
      <PageHeader
        breadcrumb={route.breadcrumb}
        t={t}
        title={!creationMode ? t('title') : t('register-new-event')}
        headerButton={
          !creationMode ? (
            <Button
              size="sm"
              onClick={() => setCreationMode(true)}
              icon={<MdAdd />}
            />
          ) : (
            <Button
              size="sm"
              variant="text"
              color="black"
              onClick={() => setCreationMode(false)}
              icon={<MdArrowBack />}
            />
          )
        }
      />
      <Flex flexDirection="column" mx="-25px" p="16px" mt="-1rem">
        {!creationMode ? (
          <Form onSubmit={handleSubmit}>
            <Flex gridGap="24px">
              <Flex w="250px">
                <FormGroup>
                  <Label>{t('select-condominium')}</Label>
                  <PersonSelect
                    width="300px"
                    placeholder={t('select-condominium')}
                    name="sourceNodeId"
                    sourceNodeId={loggedContext.topNodeId}
                    fieldName={['condominium']}
                    value={condominium}
                    onChange={e => {
                      setCondominium(e);
                    }}
                  />
                </FormGroup>
              </Flex>
              {condominium && (
                <Flex>
                  <FormGroup>
                    <Label>{t('select-event')}</Label>
                    <Select
                      options={eventTemplateOptions}
                      isClearable
                      name="eventTemplate"
                      onChange={event =>
                        handleChangeTemplate(event, setFieldValue)
                      }
                      value={eventTemplate}
                      placeholder={t('select-event')}
                      loading={loadingEventTemplates}
                      getOptionValue={option => option.eventType}
                      getOptionLabel={option => option.description}
                    />
                  </FormGroup>
                </Flex>
              )}
              {eventTemplate && (
                <>
                  {eventTemplate?.eventType !== eventTypes.answeredCall ? (
                    <FormGroup>
                      <Label>{t('event-template')}</Label>
                      <JsonTree
                        values={values.template}
                        handleChange={e => setFieldValue('template', e)}
                        name="template"
                      />
                      {templateErrors?.length &&
                        templateErrors.map((error, index) => (
                          <ErrorMessage key={index}>{error}</ErrorMessage>
                        ))}
                    </FormGroup>
                  ) : (
                    <>
                      <Flex
                        gridGap="8px"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Text>{t('event.video-call')}</Text>
                        <Toggler
                          id="videoCall"
                          name="videoCall"
                          value={videoCall}
                          checked={videoCall}
                          onChange={() =>
                            handleChangeVideoCall(setVideoCall, setFieldValue)
                          }
                        />
                      </Flex>
                      <EventFields
                        fields={handleGetEventFields(
                          eventTemplate.eventType,
                          videoCall,
                        )}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                      />
                    </>
                  )}
                </>
              )}
            </Flex>
            <Flex justifyContent="flex-end">
              <KiperButton
                color="primary"
                type="submit"
                disabled={loadingSubmit}
              >
                {t('fire-event')}
              </KiperButton>
            </Flex>
          </Form>
        ) : (
          <EventRegister setCreationMode={setCreationMode} />
        )}
      </Flex>
    </>
  );
};

export default EventGenerator;

EventGenerator.propTypes = {
  route: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
};
