import { useEffect, useState, useCallback, useContext } from 'react';
import { firstBy } from 'thenby';
import { useLazyQuery } from 'react-apollo';
import { getLastAttendancesByEventType } from '@kiper/monitoring-graphql/guided_attendance';
import { SocketContext } from '../services/socket';

const lastAttendanceAction = Object.freeze({
  NEW_LAST_ATTENDANCE: 0,
  UPDATE_LAST_ATTENDANCE: 1,
});

export const lastAttendancesStatus = Object.freeze({
  ONGOING: 'OnGoing',
  UNDETECTED_INTRUSION: 'UndetectedIntrusion',
  INTRUSION_DETECTED: 'IntrusionDetected',
  WAITING_ATTENDANCE: 'WaitingAttendance',
});

export default function useLastAttendancesByEventType({
  eventType,
  condominiumPersonContextId,
}) {
  const { subscribe, unsubscribe, sendMessage } = useContext(SocketContext);

  const [lastAttendances, setLastAttendances] = useState([]);

  const [getData, { loading }] = useLazyQuery(getLastAttendancesByEventType, {
    fetchPolicy: 'no-cache',
    skip: !eventType || !condominiumPersonContextId,
    onCompleted: data => {
      if (data?.lastAttendancesByEventType?.length)
        setLastAttendances(data?.lastAttendancesByEventType);
    },
  });

  useEffect(() => {
    if (!lastAttendances?.length) {
      getData({
        variables: {
          eventType,
          condominiumPersonContextId,
        },
      });
    }
  }, []);

  const onMessage = ({ action, item }) => {
    switch (action) {
      case lastAttendanceAction.NEW_LAST_ATTENDANCE:
        setLastAttendances(lastAttendance => [...lastAttendance, item]);
        break;
      case lastAttendanceAction.UPDATE_LAST_ATTENDANCE:
        setLastAttendances(previousLastAttendances =>
          previousLastAttendances.map(lastAttendance =>
            lastAttendance.eventId === item.eventId ? item : lastAttendance,
          ),
        );
        break;
      default:
        break;
    }
  };

  const handleSort = useCallback(
    lastAttendancesList =>
      lastAttendancesList?.sort(firstBy('creationDate', 'desc')),
    [lastAttendances],
  );

  useEffect(() => {
    let channel;

    if (condominiumPersonContextId) {
      channel = `condominium.${condominiumPersonContextId}.${eventType}`;
      subscribe(channel, onMessage);
      sendMessage('lastAttendance', {
        channel,
      });
    }
    return () => {
      unsubscribe(channel);
    };
  }, []);

  return {
    lastAttendances: handleSort(lastAttendances),
    loading,
  };
}
