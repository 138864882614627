import { useRef, useMemo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Loader, Button, Text, KiperButton, Spinner, Tooltip } from '@kiper/ui';
import { FormikProvider, useFormik } from 'formik';
import { FaEnvelope, FaSpinner } from 'react-icons/fa';
import { MdCancel, MdEdit, MdLibraryAdd, MdSave } from 'react-icons/md';
import * as yup from 'yup';
import { useMutation, useLazyQuery } from 'react-apollo';
import { useSwal } from '@kiper/hooks';
import { dweller as dwellerGql } from '@kiper/monitoring-graphql';
import { apolloErrorHandler, apolloDataErrorHandler } from '@kiper/fns';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import UserInfo from './UserInfo';
import Accesses from './Accesses';
import useViewRules from '../../../hooks/useViewRules';
import UserAccess from './UserAccess';
import { formatAccesses } from './helpers';

import {
  BlockFullWidth,
  Card,
  CardHeader,
  CardBody,
  FloatActions,
  Form,
  ButtonContainer,
} from './styles';
import PageHeader from '../../../components/PageHeader';

const treeNodeSchema = required => {
  if (required)
    return yup.object({
      value: yup.string().required(),
      id: yup.number().nullable(),
      name: yup.string().nullable(),
    });

  return yup
    .object({
      value: yup.string(),
      id: yup.number().nullable(),
      name: yup.string().nullable(),
    })
    .nullable();
};

const timeSchema = yup.object().shape({
  hours: yup.string().required(),
  minutes: yup.string().required(),
  time: yup.string().required(),
});

const intervalTimeSchema = yup.object().shape({
  id: yup.number(),
  start: timeSchema,
  end: timeSchema,
  gmt: yup.number(),
  daysOfWeek: yup.array(yup.bool()),
});

const permissionsSchema = yup.object().shape({
  applicationFeatureId: yup.number(),
  ableToRead: yup.bool().nullable(),
  ableToWrite: yup.bool().nullable(),
  name: yup.string(),
  allowsEdit: yup.bool(),
  ableToReadIsUpdated: yup.bool(),
  ableToWriteIsUpdated: yup.bool(),
});

const AT_LEAST_TWO_WORDS = /(\w.+\s).+/gi;

const initialAccess = {
  profile: '',
  condominium: '',
  unityGroup: '',
  unity: '',
  accessProfiles: [''],
  allowedAccesses: [],
  permissions: [],
  accessibilityTime: 3,
};

const initialDocuments = {
  documentTypeId: '',
  value: '',
  isInserted: true,
};

export default function UserPut({ history, match, route }) {
  const [t] = useTranslation('user');

  useTranslation('modals/user-facial-modal');

  const createNewOne = useRef(false);
  const { toast, linkToast } = useSwal();
  const [editMode, setEditMode] = useState(false);
  const { permission } = useViewRules({ path: 'users' });
  const { personId } = match.params;
  const [savedEmail, setSavedEmail] = useState(null);
  const [savedDocuments, setSavedDocuments] = useState([]);

  const isEdition = useMemo(() => !!personId, [personId]);
  const accessesFormRef = useRef(null);

  const searchUrlHistory = history?.location?.state?.fromSearch ?? '';

  const is = (fieldName, profile, state) =>
    profile &&
    permission &&
    permission.applicationFeature &&
    permission.applicationFeature.params.manageDwellerProfiles[profile] &&
    permission.applicationFeature.params.manageDwellerProfiles[profile][
      fieldName
    ] &&
    permission.applicationFeature.params.manageDwellerProfiles[profile][
      fieldName
    ][state];

  const isFieldVisible = (fieldName, value) => is(fieldName, value, 'visible');

  const isFieldRequired = (fieldName, value) =>
    is(fieldName, value, 'required');

  const [create, { loading: loadingCreate }] = useMutation(
    dwellerGql.register.mutations.createDweller,
    {
      onCompleted: () => {
        toast.fire({ icon: 'success', title: t('put.success-message') });
        if (!createNewOne.current) {
          history.goBack();
        } else {
          // eslint-disable-next-line no-use-before-define
          formikBag.resetForm({
            values: {
              accesses: [initialAccess],
              documents: [initialDocuments],
              additionalContacts: [],
              devices: [],
            },
          });
          // eslint-disable-next-line no-unused-expressions
          accessesFormRef?.current?.(initialAccess);
          createNewOne.current = false;
        }
      },
      onError: err => {
        const formattedErrors = apolloDataErrorHandler(err);
        if (formattedErrors?.specificities) {
          linkToast({
            showCloseButton: true,
            title: formattedErrors.message,
            icon: 'error',
            linkLabel: formattedErrors.specificities.personName,
            href: `/users/${formattedErrors.specificities.personId}`,
            timer: 0,
          });
        } else if (formattedErrors && formattedErrors.length) {
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
        }
      },
    },
  );

  const onSubmit = async ({
    name,
    phone,
    email,
    devices,
    accesses,
    documents,
    additionalContacts,
  }) => {
    // eslint-disable-next-line no-use-before-define
    if (formikBag.isValid) {
      const dweller = {
        name,
        phone: phone?.replace(/\s/gi, ''),
        email: email?.toLowerCase(),
        devices,
        accesses: formatAccesses(accesses, isFieldVisible),
        documents: documents
          .filter(({ documentTypeId, value }) => documentTypeId && value)
          .map(({ __typename, ...doc }) => doc),
        additionalContacts: additionalContacts.length
          ? additionalContacts.map(additionalContact => ({
              ...additionalContact,
              contact: additionalContact.contact.replace(/\s/gi, ''),
            }))
          : additionalContacts,
      };

      create({
        variables: {
          dweller,
        },
      });
    }
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .test({
        message: t('feedback.name-two-words'),
        test: x => x && x.match(AT_LEAST_TWO_WORDS),
      })
      .required(t('common:feedback.required-field')),
    documents: yup.array().of(
      yup.object({
        id: yup.string().nullable(),
        documentTypeId: yup.string(),
        value: yup.string(),
      }),
    ),
    additionalContacts: yup.array().of(
      yup.object({
        id: yup.number().nullable(),
        contactTypeId: yup.number(),
        contact: yup.string().test({
          message: t('feedback.phone'),
          test: a => a && isPossiblePhoneNumber(a.replace(/\s/gi, '')),
        }),
      }),
    ),
    phone: yup
      .string()
      .test({
        message: t('feedback.phone'),
        test: a => (a ? isPossiblePhoneNumber(a.replace(/\s/gi, '')) : true),
      })
      .nullable(),
    email: yup
      .string()
      .email(t('feedback.email'))
      .matches(/^[\d\w@.\-/+]*$/, t('feedback.email')) // yup's email won't validate some characters
      .nullable(),
    devices: yup.array().of(
      yup
        .object({
          type: yup.string().oneOf(['rf', 'tag', 'facial', 'fingerPrint']),
          id: yup.string(),
          blocked: yup.bool(),
          counter: yup
            .string()
            .nullable()
            .when('type', type =>
              type === 'rf'
                ? yup.string().required(t('common:feedback.required-field'))
                : yup.string().nullable(),
            ),
        })
        .required(t('common:feedback.required-field')),
    ),
    accesses: yup
      .array()
      .of(
        yup.object({
          id: yup.number(),
          isBlocked: yup.bool(),
          blockedReason: yup.string(),
          profile: treeNodeSchema(true),
          condominium: treeNodeSchema(true).required(),
          unityGroup: yup
            .mixed()
            .when('profile', ({ value }) =>
              isFieldRequired('unityGroup', value)
                ? treeNodeSchema(true).required()
                : treeNodeSchema(),
            ),
          unity: yup
            .mixed()
            .when('profile', ({ value }) =>
              isFieldRequired('unity', value)
                ? treeNodeSchema(true).required()
                : treeNodeSchema(),
            ),
          accessProfiles: yup.mixed().when('profile', ({ value }) =>
            isFieldRequired('accessProfile', value)
              ? yup
                  .array()
                  .of(treeNodeSchema(true).required())
                  .required(t('common:feedback.required-field'))
              : yup.array().of(treeNodeSchema()),
          ),
          allowedAccesses: yup.mixed().when('profile', ({ value }) =>
            isFieldRequired('allowedAccesses', value)
              ? yup
                  .array()
                  .of(intervalTimeSchema)
                  .nullable()
              : yup
                  .array()
                  .of(intervalTimeSchema)
                  .nullable(),
          ),
          permissions: yup.mixed().when('profile', ({ value }) =>
            isFieldRequired('features', value)
              ? yup
                  .array()
                  .of(permissionsSchema)
                  .nullable()
                  .required()
              : yup
                  .array()
                  .of(permissionsSchema)
                  .nullable(),
          ),
          permissionsUpdated: yup.bool(),
          accessibilityTime: yup.number().required(),
          callPriority: yup.number().default(1),
          observation: yup.string(),
        }),
      )
      .required(t('common:feedback.required-field')),
  });

  const formikBag = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      name: '',
      phone: null,
      devices: [],
      accesses: [],
      documents: [],
      additionalContacts: [],
    },
  });

  const [fetch, { loading }] = useLazyQuery(dwellerGql.detail.queries.dweller, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ dweller }) => {
      setSavedEmail(dweller.email || null);
      setSavedDocuments(dweller.documents || []);
      formikBag.setValues(dweller);
    },
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const [fetchSendEmail, { loading: loadingSendEmail }] = useLazyQuery(
    dwellerGql.register.queries.resendEmail,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        toast.fire({ icon: 'success', title: t('put.success-email-message') });
      },
      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length) {
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
        }
      },
    },
  );

  const handleCreateNewOne = e => {
    if (formikBag.isValid) {
      createNewOne.current = true;
      formikBag.handleSubmit(e);
    }
  };

  const handleEditMode = () => {
    formikBag.setFieldValue(
      'additionalContacts',
      formikBag?.values?.additionalContacts?.filter(i => !!i.id),
    );

    setEditMode(x => !x);
  };

  const handleSendEmail = () => {
    fetchSendEmail({
      variables: {
        email: savedEmail,
      },
    });
  };

  const refetch = () =>
    fetch({
      variables: {
        personId: Number(personId),
      },
    });

  useEffect(() => {
    if (isEdition) refetch();
  }, [isEdition]);

  const pageTitle = useMemo(() => {
    if (isEdition) return 'put.edit-title';
    return 'put.create-title';
  }, [isEdition]);

  return (
    <BlockFullWidth>
      <PageHeader
        searchUrlHistory={searchUrlHistory}
        breadcrumb={route.breadcrumb}
        t={t}
        title={t(pageTitle)}
      />
      <FormikProvider value={formikBag}>
        <Form
          className="m-auto form col-12 col-xl-9"
          onSubmit={formikBag.handleSubmit}
          noValidate
        >
          <Card>
            <CardHeader>
              <Text fontSize="16px" fontWeight="500">
                {t('put.main-info')}
              </Text>
              {!loading && !editMode && isEdition && (
                <ButtonContainer>
                  <KiperButton
                    color="secondary"
                    variant="text"
                    onClick={handleSendEmail}
                    loading={loadingSendEmail}
                    rounded
                    disabled={!savedEmail}
                    icon={
                      loadingSendEmail ? (
                        <FaSpinner size={14} />
                      ) : (
                        <FaEnvelope />
                      )
                    }
                  />

                  <KiperButton
                    color="secondary"
                    variant="text"
                    onClick={handleEditMode}
                    rounded
                    icon={<MdEdit />}
                  />
                </ButtonContainer>
              )}
            </CardHeader>
            <CardBody>
              {loading ? (
                <Loader />
              ) : (
                <UserInfo
                  isEdition={isEdition}
                  handleEditMode={handleEditMode}
                  editMode={editMode}
                  savedEmail={savedEmail}
                  savedDocuments={savedDocuments}
                  onSaveEmail={email => {
                    setSavedEmail(email);
                    refetch();
                  }}
                />
              )}
            </CardBody>
          </Card>

          {!loading && (
            <>
              <UserAccess
                isEdition={isEdition}
                editMode={editMode}
                refetch={refetch}
              />
              <Accesses
                user={formikBag.values}
                isEdition={isEdition}
                formRef={accessesFormRef}
              />

              {!isEdition && (
                <FloatActions>
                  <>
                    <Tooltip placement="left" target="cancel-button">
                      {t('put.cancel')}
                    </Tooltip>
                    <Link to="/users">
                      <Button
                        id="cancel-button"
                        size="xl"
                        rounded
                        type="button"
                        color="danger"
                      >
                        <MdCancel />
                      </Button>
                    </Link>
                  </>

                  <>
                    <Tooltip
                      placement="left"
                      target="save-and-create-new-button"
                    >
                      {t('put.save-and-create-a-new')}
                    </Tooltip>
                    <Button
                      id="save-and-create-new-button"
                      type="button"
                      color="primary"
                      rounded
                      size="xl"
                      disabled={!formikBag.isValid || loadingCreate}
                      name="save-and-create-new"
                      onClick={handleCreateNewOne}
                    >
                      <MdLibraryAdd />
                    </Button>
                  </>

                  <>
                    <Tooltip placement="left" target="save-button">
                      {loadingCreate ? t('buttons:saving') : t('buttons:save')}
                    </Tooltip>
                    <Button
                      id="save-button"
                      rounded
                      type="submit"
                      name="save"
                      color="primary"
                      loading={loadingCreate}
                      disabled={!formikBag.isValid || loadingCreate}
                      size="xl"
                    >
                      {loadingCreate ? <Spinner /> : <MdSave />}
                    </Button>
                  </>
                </FloatActions>
              )}
            </>
          )}
        </Form>
      </FormikProvider>
    </BlockFullWidth>
  );
}

UserPut.propTypes = {
  history: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
  route: propTypes.object.isRequired,
};
