import styled from 'styled-components';
import { Select } from '@kiper/ui';

export const StyledSelect = styled(Select)`
  width: 100%;
`;

export const GroupLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const GroupBadge = styled.span`
  background-color: ${props => props.theme.colors.secondary200};
  border-radius: 2em;
  color: ${props => props.theme.colors.secondary800};
  display: inline-block;
  font-size: 12;
  font-weight: normal;
  line-height: 1;
  min-width: 1;
  padding: 0.16666666666667em 0.5em;
  text-align: center;
`;
