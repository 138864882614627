import styled, { css } from 'styled-components';

export const ItemWrapper = styled.div`
  padding: 0 2px;
  display: block;
`;

export const Item = styled.button`
  padding: 3px 8px;
  border-radius: 100px;
  width: max-content;
  font-size: 12px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.primary300};
  color: ${props => props.theme.colors.primary500};
  cursor: pointer;
  outline: none !important;

  ${props =>
    props.selected
      ? css`
          background-color: ${props.theme.colors.primary500};
          color: ${props.theme.colors.white};
        `
      : ''}
`;
