import { memo } from 'react';
import {
  CheckmarksSelect,
  DateTimePicker,
  ErrorMessage,
  Flex,
  KiperButton,
  Label,
} from '@kiper/ui';
import propTypes from 'prop-types';
import { MdDelete } from 'react-icons/md';
import { useFormPeriodDate, useFormPlacesSelect } from '../hooks';

const FormTemporaryAccess = ({
  t,
  onChange,
  temporaryAccessValue,
  onRemoveSelection,
  onAddSelection,
  availableOptionsPlaces,
  onDelete,
  loadingPlaces,
  errors,
}) => {
  const { dates, handleChangeDate } = useFormPeriodDate({
    temporaryAccessValue,
    onChange,
  });

  const {
    selectedPlacesOptions,
    individualPlaceOptions,
    placesOptionsControl,
    placesId,
  } = useFormPlacesSelect({
    onChange,
    onRemoveSelection,
    onAddSelection,
    temporaryAccessValue,
    availableOptionsPlaces,
  });

  return (
    <Flex gridGap="8px" p="16px" flexDirection="column" width="100%">
      <Flex gridGap="16px" width="100%" alignItems="center">
        <Flex gridGap="8px" flexDirection="column" width="100%">
          <Flex gridGap="8px" justifyContent="flex-start" width="100%">
            <Flex flexDirection="column" justifyContent="flex-end" width="100%">
              <Label>
                {t('put.access-data.place-temporary-access.checkmarks.label')}
              </Label>
              <CheckmarksSelect
                preventEmptySelect
                id={placesId}
                name={placesId}
                emptyOptionsListText={t(
                  'put.access-data.place-temporary-access.checkmarks.empty',
                )}
                placeholder={t(
                  'put.access-data.place-temporary-access.checkmarks.placeholder',
                )}
                loading={loadingPlaces}
                options={individualPlaceOptions}
                onRemoveSelection={placesOptionsControl.handleRemoveSelection}
                onAddSelection={placesOptionsControl.handleAddSelection}
                selectedOptions={selectedPlacesOptions}
                error={errors?.place}
              />
            </Flex>
          </Flex>
          <Flex gridGap="8px" width="100%">
            <Flex flexDirection="column" justifyContent="flex-end" width="100%">
              <Label>{t('put.access-data.start')}</Label>
              <DateTimePicker
                value={dates.startDate}
                onChange={dateValue =>
                  handleChangeDate({ name: 'startDate', dateValue })
                }
              />
            </Flex>
            <Flex flexDirection="column" justifyContent="flex-end" width="100%">
              <Label>{t('put.access-data.end')}</Label>
              <DateTimePicker
                value={dates.endDate}
                onChange={dateValue =>
                  handleChangeDate({ name: 'endDate', dateValue })
                }
              />
            </Flex>
          </Flex>
        </Flex>
        <KiperButton
          rounded
          onClick={() => onDelete(selectedPlacesOptions)}
          icon={<MdDelete />}
          color="danger"
          variant="text"
        />
      </Flex>
      {typeof errors === 'string' && !!errors && (
        <ErrorMessage>{errors}</ErrorMessage>
      )}
    </Flex>
  );
};

FormTemporaryAccess.propTypes = {
  t: propTypes.func.isRequired,
  loadingPlaces: propTypes.bool.isRequired,
  errors: propTypes.any,
  onDelete: propTypes.func.isRequired,
  onChange: propTypes.func.isRequired,
  onRemoveSelection: propTypes.func.isRequired,
  onAddSelection: propTypes.func.isRequired,
  temporaryAccessValue: propTypes.shape({
    place: propTypes.arrayOf(
      propTypes.shape({
        id: propTypes.any.isRequired,
        name: propTypes.string.isRequired,
      }),
    ).isRequired,
    startDate: propTypes.string,
    endDate: propTypes.string,
  }).isRequired,
  availableOptionsPlaces: propTypes.arrayOf(
    propTypes.shape({
      value: propTypes.any.isRequired,
      label: propTypes.string.isRequired,
    }),
  ).isRequired,
};

FormTemporaryAccess.defaultProps = {
  errors: {},
};

export default memo(FormTemporaryAccess);
