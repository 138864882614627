import propTypes from 'prop-types';

const makeReturn = template => JSON.stringify(template);

const event103 = params => {
  if (!params?.esn) return null;

  const datetime = new Date().toLocaleString('sv-SE', {
    hour12: false,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  const template = {
    cmd: 'door_breakin',
    gw_type: 'K4',
    params: {
      device: {
        id: 539,
        type: 'ipwall',
        door_id: 1,
      },
    },
    id: String(Math.floor(Math.random() * 100001)),
    esn: params.esn,
    datetime,
  };

  return makeReturn(template);
};

const event176 = params => {
  if (!params?.rfId) return null;

  const template = {
    cmd: 'send_access_code_rf',
    gw_type: 'K4',
    params: {
      battery_status: 0,
      from_ipwall_id: 539,
      counter_cpu: 9999,
      access: {
        allowed: false,
        reason: -20,
      },
      rf_id: params.rfId,
      button_id: 1,
      counter: 622,
    },
  };

  return makeReturn(template);
};

const event102 = params => ({
  agent: params.agentId,
  condo: params?.condominiumPersonContextId?.toString(),
  exten: params.deviceExtension,
  callId: String(Math.floor(Math.random() * 100001)),
  interphone: params.deviceExtension,
});

const eventVideoCall = params => ({
  agent: params.agentId,
  callId: String(Math.floor(Math.random() * 100001)),
  interphone: params?.sipAccountId,
});

const eventsPayloads = {
  103: event103,
  176: event176,
  102: event102,
  videoCall: eventVideoCall,
};

const useEventGenerator = () => {
  const handleEventAction = params => {
    const eventAction = eventsPayloads[params.eventType];
    const payload = eventAction(params);
    return payload;
  };

  return { handleEventAction };
};

export default useEventGenerator;

useEventGenerator.propTypes = {
  eventType: propTypes.string.isRequired,
  params: propTypes.object,
};

useEventGenerator.defaultProps = {
  params: {},
};
