import propTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { attendance } from '@kiper/monitoring-graphql';
import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { useMemo } from 'react';
import { StyledSelect } from './styles';

const UntreatedEvent = ({
  isClearable,
  value,
  menuPlacement,
  fullwidth,
  ...props
}) => {
  const { toast } = useSwal();

  const { data, loading } = useQuery(attendance.untreatedEventSelect, {
    fetchPolicy: 'cache-and-network',
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const options = useMemo(() => data?.untreatedEventSelect || [], [data]);

  return (
    <StyledSelect
      {...props}
      $fullwidth={fullwidth}
      id="select-untreated-event"
      menuPlacement={menuPlacement}
      value={value}
      isClearable={isClearable}
      isLoading={loading}
      options={options}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.description}
    />
  );
};

export default UntreatedEvent;

UntreatedEvent.propTypes = {
  value: propTypes.any,
  isClearable: propTypes.bool,
  fullwidth: propTypes.bool,
  menuPlacement: propTypes.string,
};

UntreatedEvent.defaultProps = {
  isClearable: false,
  fullwidth: false,
  menuPlacement: 'bottom',
  value: null,
};
