import propTypes from 'prop-types';
import { Flex } from '@kiper/ui';
import { Trans } from 'react-i18next';
import { registerFacialStatus } from '../../../constants';
import { FeedbackButton, Img, RuleText } from './styles';

export const ModalContentDefault = ({ t, onClick, feedbackData }) => (
  <>
    <Img width="160" src={feedbackData.image} alt={feedbackData.status} />
    <FeedbackButton color="primary" onClick={onClick} centered>
      {feedbackData.status === registerFacialStatus.success
        ? t('feedback.back-user-screen')
        : t('feedback.try-again')}
    </FeedbackButton>
  </>
);

export const ModalContentWithRules = ({ t, onClick, feedbackData }) => (
  <Flex flexDirection="row" justifyContent="space-evenly">
    <Img src={feedbackData.image} alt={feedbackData.message} />
    <Flex flexDirection="column" gridGap="16px">
      {feedbackData.rules.map((rule, index) => (
        <Flex key={index} alignItems="center">
          <Flex p="6px" bg="gray50" alignItems="center" borderRadius="8px">
            {rule.icon}
          </Flex>
          <RuleText>
            <Trans>{rule.description}</Trans>
          </RuleText>
        </Flex>
      ))}

      <FeedbackButton color="primary" onClick={onClick} centered>
        {t('feedback.try-again')}
      </FeedbackButton>
    </Flex>
  </Flex>
);

ModalContentDefault.propTypes = {
  onClick: propTypes.func.isRequired,
  feedbackData: propTypes.shape({
    title: propTypes.string.isRequired,
    subtitle: propTypes.string.isRequired,
    image: propTypes.string.isRequired,
    status: propTypes.string.isRequired,
  }).isRequired,
  t: propTypes.func.isRequired,
};

ModalContentWithRules.propTypes = {
  onClick: propTypes.func.isRequired,
  feedbackData: propTypes.shape({
    title: propTypes.string.isRequired,
    subtitle: propTypes.string.isRequired,
    image: propTypes.string.isRequired,
    rules: propTypes.array.isRequired,
    status: propTypes.string.isRequired,
    message: propTypes.string.isRequired,
  }).isRequired,
  t: propTypes.func.isRequired,
};
