import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  compare,
  isValidDate,
  formatDateUtc,
  toDateType,
  formatDateTime,
} from '@kiper/fns';
import { DateTimePicker, ErrorMessage } from '@kiper/ui';
import { useFormikContext } from 'formik';
import { Label, FlexCol, DatePeriodWrapper } from './styles';

const BookingTime = ({ values, handleChange, isEditable }) => {
  const [t] = useTranslation('user');
  const [isInvalidRange, setInvalidRange] = useState(false);
  const [renderedInitialValues, setRenderedInitialValues] = useState(false);
  const [dates, setDates] = useState({
    initDate: '',
    endDate: '',
  });

  const getDateUtc = date => {
    if (!date) return '';

    return formatDateUtc({
      date: new Date(date),
      format: 'YYYY-MM-DD HH:mm:ss',
    });
  };

  const setFieldValue = ({ name, value }) => {
    setDates({ ...dates, [name]: value });
    handleChange('bookingTime', { ...values, [name]: getDateUtc(value) });
  };

  const isValidDateRange = () => {
    const { initDate, endDate } = dates;
    const { isAfter } = compare(new Date(initDate), new Date(endDate));
    return !isAfter;
  };

  const { errors, setFieldError } = useFormikContext();

  useEffect(() => {
    let valid = true;

    const validDates = Object.values(dates).every(date =>
      isValidDate(new Date(date)),
    );

    if (validDates) valid = isValidDateRange();

    setInvalidRange(!valid);

    if (!valid && !errors?.bookingTime) {
      setFieldError('bookingTime', t('put.access-data.invalid-date-range'));
    }
  }, [dates, errors]);

  useEffect(() => {
    if (!renderedInitialValues && (!dates.initDate || !dates.endDate)) {
      const newDates = {
        initDate: '',
        endDate: '',
      };

      if (isValidDate(values.initDate)) {
        newDates.initDate = toDateType(values.initDate);
      }
      if (isValidDate(values.endDate)) {
        newDates.endDate = toDateType(values.endDate);
      }

      setRenderedInitialValues(true);
      setDates({ ...newDates });
    }
  }, [values]);

  const getDateLabel = date => (isValidDate(date) ? formatDateTime(date) : '');

  const { initDateLabel, endDateLabel } = useMemo(() => {
    return {
      initDateLabel: getDateLabel(dates.initDate),
      endDateLabel: getDateLabel(dates.endDate),
    };
  }, [dates]);

  if (!isEditable && !initDateLabel && !endDateLabel) return null;

  return (
    <>
      <Label style={{ fontSize: '14px' }}>
        {t('put.access-data.temporary-period')}
      </Label>
      <DatePeriodWrapper>
        <FlexCol>
          <Label>{t('put.access-data.start')}</Label>
          {isEditable ? (
            <DateTimePicker
              value={dates.initDate}
              onChange={value => setFieldValue({ name: 'initDate', value })}
            />
          ) : (
            <Label>{initDateLabel}</Label>
          )}
        </FlexCol>
        <FlexCol>
          <Label>{t('put.access-data.end')}</Label>
          {isEditable ? (
            <DateTimePicker
              value={dates.endDate}
              onChange={value => setFieldValue({ name: 'endDate', value })}
            />
          ) : (
            <Label>{endDateLabel}</Label>
          )}
        </FlexCol>
        {isInvalidRange && (
          <ErrorMessage>{t('put.access-data.invalid-date-range')}</ErrorMessage>
        )}
      </DatePeriodWrapper>
    </>
  );
};

BookingTime.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
};

BookingTime.defaultProps = {
  isEditable: false,
};

export default BookingTime;
