import propTypes from 'prop-types';
import { useContext } from 'react';
import { Container } from './styles';
import TriageFilter from './TriageFilter';
import SubtriageFilter from './SubtriageFilter';
import { CondominiumRuleFilterContext } from '../../../../../store';

const CondominiumRuleFilters = ({ condominiumPersonContextId }) => {
  const { filteredTriage, filteredSubtriage, setFilterContext } = useContext(
    CondominiumRuleFilterContext,
  );

  return (
    <Container>
      <TriageFilter
        filteredTriage={filteredTriage}
        setFilterContext={setFilterContext}
      />
      {filteredTriage && (
        <SubtriageFilter
          filteredTriage={filteredTriage}
          filteredSubtriage={filteredSubtriage}
          setFilterContext={setFilterContext}
          condominiumPersonContextId={condominiumPersonContextId}
        />
      )}
    </Container>
  );
};

export default CondominiumRuleFilters;
CondominiumRuleFilters.propTypes = {
  subtriage: propTypes.shape({
    id: propTypes.number,
    description: propTypes.string,
    descriptionEs: propTypes.string,
    descriptionEn: propTypes.string,
  }).isRequired,
  triage: propTypes.shape({
    id: propTypes.number,
    description: propTypes.string,
    descriptionEs: propTypes.string,
    descriptionEn: propTypes.string,
  }).isRequired,
  condominiumPersonContextId: propTypes.number.isRequired,
};
