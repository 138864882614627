import { useState } from 'react';
import { useMutation } from 'react-apollo';
import propTypes from 'prop-types';
import { Modal, Flex } from '@kiper/ui';
import { useSwal } from '@kiper/hooks';
import { apolloErrorHandler } from '@kiper/fns';
import sharedGql from '@kiper/shared-schema/src/graphql';
import { Form } from 'reactstrap';
import {
  RadioButtonContainer,
  RadioGroup,
  RadioLabel,
  Label,
  KiperRadioCustomButton,
  Button,
} from './styles';

const modal = ({ open, toggle, t, modalSettings }) => {
  const options = [0];
  if (modalSettings.hasCoPartner) options.push(1);
  if (!modalSettings.partnerConfig) options.push(2);

  const [selectedOption, selectOption] = useState(
    options[modalSettings.routedToType],
  );

  const { toast } = useSwal();

  const [fetch, { loading }] = useMutation(
    sharedGql.eventSettings.eventSettingRoute,
    {
      onCompleted: () => {
        toggle(true);
        toast.fire({
          icon: 'success',
          title: t('modal.feedback-success'),
        });
      },
      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length) {
          toast.fire({
            title: formattedErrors
              .map(text => t(text, { message: t('modal.feedback-error') }))
              .join('\n'),
            icon: 'error',
          });
        }
      },
    },
  );

  const handleSubmit = event => {
    event.preventDefault();
    fetch({
      variables: {
        eventSettingRoute: {
          id: modalSettings.id,
          personContextId: Number(modalSettings.personContextId),
          eventType: Number(modalSettings.eventType),
          name: 'routedTo',
          value: `{"routedToType": ${selectedOption}}`,
        },
      },
    });
  };

  return (
    <Modal
      title={t('modal.title')}
      open={open}
      toggle={() => toggle(false)}
      size="md"
    >
      <Form onSubmit={handleSubmit}>
        <Flex flexDirection="column">
          <Label>{t('modal.sub-title')}</Label>
          <RadioGroup>
            {options.map(option => (
              <RadioButtonContainer check key={option}>
                <RadioLabel>
                  <KiperRadioCustomButton
                    type="radio"
                    checked={option === selectedOption}
                    value={option}
                    name="options"
                    onChange={() => selectOption(option)}
                    label={t(`modal.options.${option}`)}
                  />
                  {t(`modal.options.${option}`)}
                </RadioLabel>
              </RadioButtonContainer>
            ))}
          </RadioGroup>

          <Flex justifyContent="flex-end">
            <Button
              type="button"
              color="neutral"
              variant="outlined"
              onClick={() => toggle(false)}
            >
              {t('buttons:cancel')}
            </Button>

            <Button
              type="submit"
              color="primary"
              variant="contained"
              loading={loading}
              disabled={loading}
            >
              {loading ? t('buttons:confirming') : t('buttons:confirm')}
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Modal>
  );
};

export default modal;

modal.propTypes = {
  toggle: propTypes.func.isRequired,
  open: propTypes.bool.isRequired,
  t: propTypes.func.isRequired,
  modalSettings: propTypes.object.isRequired,
};
