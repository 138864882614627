import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export default function useFilters(
  initialFilters,
  { useFilterParams } = {
    useFilterParams: false,
  },
) {
  const history = useHistory();
  const location = useLocation();

  const searchQueryRef = useRef(new URLSearchParams(location.search));

  const [filters, setFilters] = useState(initialFilters);

  const historyPush = ({ search }) =>
    history.push({
      search,
    });

  const removeParam = paramName => {
    searchQueryRef.current.delete(paramName);
    historyPush({ search: searchQueryRef.current.toString() });
  };

  const appendParam = ({ paramName, queryValue }) => {
    if (!useFilterParams) return;

    searchQueryRef.current = new URLSearchParams(location.search);

    if (!searchQueryRef.current.has(paramName)) {
      searchQueryRef.current.append(paramName, queryValue);
    } else {
      searchQueryRef.current.set(paramName, queryValue);
    }

    historyPush({ search: searchQueryRef.current.toString() });
  };

  const handleChangeInitialPagination = () => {
    const hasLimitPage = searchQueryRef.current.has('limitPage');
    const hasPage = searchQueryRef.current.has('page');
    setFilters(x => ({
      ...x,
      page: hasPage
        ? Number(searchQueryRef.current.get('page'))
        : initialFilters.page,
      pagesize: hasLimitPage
        ? Number(searchQueryRef.current.get('limitPage'))
        : initialFilters.pagesize,
    }));
  };

  const handlePageChange = quantity => {
    const pageNumber = Number(filters.page) + Number(quantity);
    appendParam({
      paramName: 'page',
      queryValue: String(pageNumber),
    });
    setFilters(x => ({
      ...x,
      page: Number(x.page) + Number(quantity),
    }));
  };

  const handlePageSize = pagesize => {
    appendParam({
      paramName: 'limitPage',
      queryValue: String(pagesize),
    });
    setFilters(x => ({
      ...x,
      pagesize: Number(pagesize),
      page: 1,
    }));
    removeParam('page');
  };

  const resetPages = () => setFilters(x => ({ ...x, page: 1 }));

  const handleNextPage = () => {
    handlePageChange(1);
  };
  const handlePreviousPage = () => handlePageChange(-1);

  const isLastPage = useMemo(() => {
    const { page, totalPages } = filters || {};
    return page === totalPages;
  }, [filters]);

  const isFirstPage = useMemo(() => filters.page === 1, [filters.page]);

  const isUniquePage = useMemo(() => filters.totalPages === 1, [
    filters.totalPages,
    filters.page,
  ]);

  const handleChange = (name, value) => {
    setFilters(x => ({
      ...x,
      [name]: value,
      page: searchQueryRef.current.has('page')
        ? Number(searchQueryRef.current.get('page'))
        : 1,
    }));
  };

  useEffect(() => {
    if (useFilterParams) handleChangeInitialPagination();
  }, []);

  const types = {
    STRING: 1,
    DATE_TIME: 2,
    NUMBER: 3,
    BOOLEAN: 4,
  };

  const handleSort = orderby =>
    setFilters(x => ({
      ...x,
      orderby,
      ordination: orderby !== x.orderby ? false : !x.ordination,
    }));

  return {
    filters,
    types,
    isLastPage,
    isFirstPage,
    isUniquePage,
    resetPages,
    handlePageChange,
    handlePageSize,
    handleChange,
    handleSort,
    handlePreviousPage,
    handleNextPage,
    setFilters,
  };
}
