import { useCallback, useMemo, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useFormikContext, FieldArray } from 'formik';
import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo';
import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { useTranslation } from 'react-i18next';
import Contact from './Contact';

export const CONTACT_OPERATION = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
};

const phoneFieldName = 'mobilePhone';

const contactTypesQuery = gql`
  query contactTypes {
    contactTypes {
      id
      name
      fieldName
    }
  }
`;

const removeContactMutation = gql`
  mutation dwellerRemoveAdditionalContact($id: ID) {
    dwellerRemoveAdditionalContact(id: $id)
  }
`;

const Contacts = ({ editMode, isEdition }) => {
  const { values, errors, setFieldTouched, touched } = useFormikContext();
  const [oldAdditionalContacts, setOldAdditionalContacts] = useState([]);

  useEffect(() => {
    if (editMode) {
      setOldAdditionalContacts(values?.additionalContacts);
    }
  }, [editMode]);

  const { toast, confirm } = useSwal();
  const [t] = useTranslation('user');

  const { data: contactTypesData } = useQuery(contactTypesQuery);

  const contactTypeId = useMemo(
    () =>
      contactTypesData?.contactTypes?.find(i => i.fieldName === phoneFieldName)
        ?.id,
    [contactTypesData],
  );

  const isLast = useCallback(
    index => index === values?.additionalContacts?.length - 1,
    [values?.additionalContacts],
  );

  const isFirst = useCallback(index => index === 0, [
    values?.additionalContacts,
  ]);

  const [removeContact] = useMutation(removeContactMutation, {
    onCompleted: ({ dwellerRemoveAdditionalContact }) => {
      if (dwellerRemoveAdditionalContact)
        toast.fire({
          title: t('put.additional-contacts.remove-success-message'),
          icon: 'success',
        });
    },
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const askForRemove = async item => {
    const prefix = 'put.additional-contacts.remove-dialog';

    const removeConfirmation = await confirm({
      title: t(`${prefix}.title`),
      text: t(`${prefix}.text`, { phone: item.contact }),
      cancelButtonText: t('buttons:no'),
      confirmButtonText: t('buttons:yes'),
    });

    if (removeConfirmation)
      return removeContact({ variables: { id: item.id } });
    return false;
  };

  const onRemove = item => askForRemove(item);
  return (
    <FieldArray
      name="additionalContacts"
      render={({ push, replace, remove }) => (
        <>
          {values?.additionalContacts.map((item, index) => (
            <Contact
              name={`additionalContacts[${index}]`}
              value={item.contact}
              handleChange={contact => {
                return replace(index, {
                  ...item,
                  contactTypeId,
                  contact,
                  isInserted: !item.id,
                  isUpdated:
                    oldAdditionalContacts?.[index]?.contact !==
                    contact?.replace(/\s/gi, '')
                      ? !!item.id
                      : false,
                });
              }}
              touched={touched?.additionalContacts?.[index]?.contact}
              error={errors?.additionalContacts?.[index]?.contact}
              index={index}
              key={`additional-contact-${index}`}
              isLast={isLast(index)}
              showBothActions={
                (isFirst(index) && values?.additionalContacts?.length === 1) ||
                isLast(index)
              }
              push={() => push({ contactTypeId, contact: '' })}
              remove={async () => {
                if (item.id) {
                  const result = await onRemove(item);
                  if (result?.data?.dwellerRemoveAdditionalContact)
                    remove(index);
                } else {
                  remove(index);
                }
              }}
              editMode={editMode}
              isEdition={isEdition}
              setFieldTouched={setFieldTouched}
            />
          ))}
        </>
      )}
    />
  );
};

Contacts.propTypes = {
  editMode: propTypes.bool,
  isEdition: propTypes.bool,
};

Contacts.defaultProps = {
  editMode: false,
  isEdition: false,
};

export default Contacts;
