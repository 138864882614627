import { useMemo, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useToggle } from '@kiper/hooks';
import { useQuery, useMutation } from 'react-apollo';
import { auth } from '@kiper/monitoring-graphql';
import { types, set } from '@kiper/cookie';
import { Dropdown, StyledSelect, DropdownToggle } from './styles';
import useCurrentLoggedContext from '../../hooks/useCurrentLoggedContext';

export default function SwitchContext() {
  const [isDropdownOpened, toggleDropdown] = useToggle(false);
  const { loggedContext: currentContext } = useCurrentLoggedContext();
  const location = useLocation();
  const history = useHistory();
  const [changeContextFn] = useMutation(auth.switchContextMutation, {
    onCompleted: () => history.go(0),
  });
  const [valueSelected, setValueSelected] = useState(null);

  const changeContext = currentLoggedContext => {
    const match = location.pathname.match(/^\/([^/]*).*$/);
    if (match) history.push(`/${match[1]}`);

    set(types.lastContext, currentLoggedContext.personContextId);

    return changeContextFn({ variables: { currentLoggedContext } });
  };

  const { data } = useQuery(auth.whoAmIQuery, { fetchPolicy: 'cache-only' });

  const getSelectedName = ctx => {
    if (ctx.condominium) return ctx.condominium.name;
    if (ctx.partner) return ctx.partner.name;
    if (ctx.partnerGroup) return ctx.partnerGroup.name;
    return '';
  };

  const avaiableContexts = useMemo(() => {
    if (!data) return [];

    return data.whoAmI.reduce((acc, ctx) => {
      if (
        currentContext &&
        ctx.personContextId !== currentContext.personContextId
      ) {
        acc.push({
          ...ctx,
          selectName: `${getSelectedName(ctx)} (${ctx.profileName})`,
        });
      }

      return acc;
    }, []);
  }, [data, currentContext]);

  const setCurrentLoggedContext = contextId => {
    const context = avaiableContexts.find(
      ctx => ctx.topContextId === contextId,
    );

    if (context) changeContext(context);

    toggleDropdown();
  };

  const toSelectValue = context => {
    const { topContextId, selectName } = context;
    return {
      value: topContextId,
      label: selectName ?? getSelectedName(context),
    };
  };

  useEffect(() => {
    if (currentContext) setValueSelected(toSelectValue(currentContext));
  }, [currentContext]);

  const emptyFn = () => null;

  return (
    <>
      {isDropdownOpened ? (
        <>
          <StyledSelect
            value={valueSelected}
            options={avaiableContexts.map(toSelectValue)}
            onChange={item => setCurrentLoggedContext(item.value)}
            hideSelectedOptions
            autoFocus
            openMenuOnFocus
            onBlur={() => toggleDropdown()}
          />
        </>
      ) : (
        <Dropdown
          id="menu-context-select"
          toggle={(avaiableContexts.length && toggleDropdown) || emptyFn}
        >
          <DropdownToggle
            tag="span"
            data-toggle="dropdown"
            aria-expanded={isDropdownOpened}
            caret={!!avaiableContexts.length}
          >
            {currentContext && getSelectedName(currentContext)}
          </DropdownToggle>
        </Dropdown>
      )}
    </>
  );
}
