import styled from 'styled-components';
import { Text as text, KiperButton } from '@kiper/ui';
import { Form as form } from 'reactstrap';

export const Text = styled(text)`
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.25px;
  margin-bottom: 8px;

  color: ${props => props.theme.colors.highBlack};
`;

export const Button = styled(KiperButton)`
  & + & {
    margin-left: 16px;
  }
`;

export const Form = styled(form)``;
