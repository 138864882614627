import { useTranslation } from 'react-i18next';
import { useSwal } from '@kiper/hooks';
import { useCurrentLoggedContext, useEventGenerator } from '../../../hooks';
import { eventTypes } from '../../../constants';
import useEventGeneratorMutations from './useEventGeneratorMutations';

const personContextParamsName = Object.freeze({
  kiperVoiceAgent: 'kiperVoiceAgent',
  voiceToken: 'voiceToken',
});

const eventTypeVideoCall = 'videoCall';

const useEventGeneratorSubmitForm = (eventTemplate, condominium, videoCall) => {
  const { loggedContext } = useCurrentLoggedContext();
  const [t] = useTranslation('event-generator');
  const { toast } = useSwal();

  const { handleEventAction } = useEventGenerator();
  const {
    answeredCall,
    loadingSubmit,
    createEventTemplate,
  } = useEventGeneratorMutations();

  const getAgentId = () => {
    return loggedContext?.personContextParams?.find(
      x => x.name === personContextParamsName.kiperVoiceAgent,
    )?.value?.agentId;
  };

  const getVoiceToken = () => {
    return loggedContext?.partner?.personContextParams?.find(
      x => x.name === personContextParamsName.voiceToken,
    )?.value?.voiceToken;
  };

  const showToast = agentId => {
    toast.fire({
      title: !agentId ? t('feedback.no-agent') : t('feedback.no-voice-token'),
      icon: 'error',
    });
  };

  const createParams = (agentId, values) => {
    const params = { agentId };

    if (videoCall && values?.sipAccountId) {
      params.eventType = eventTypeVideoCall;
      params.sipAccountId = values?.sipAccountId;
    } else {
      params.eventType = eventTemplate?.eventType;
      params.condominiumPersonContextId = condominium?.contextId;
      params.deviceExtension = values?.deviceExtension;
    }

    return params;
  };

  const onSubmit = values => {
    if (eventTemplate.eventType === eventTypes.answeredCall) {
      const agentId = getAgentId();
      const voiceToken = getVoiceToken();

      if (!agentId || !voiceToken) {
        showToast(agentId);
        return;
      }

      const params = createParams(agentId, values);
      const body = handleEventAction(params);

      answeredCall({
        variables: {
          voiceToken,
          body,
        },
      });
    } else {
      const template = values?.template;
      createEventTemplate({
        variables: {
          template,
        },
      });
    }
  };

  return { onSubmit, loadingSubmit };
};

export default useEventGeneratorSubmitForm;
