import { createContext, useReducer, useMemo, useState } from 'react';
import propTypes from 'prop-types';

export const GuidedAttendanceContext = createContext();

const INITIAL_STATE = {
  tree: null,
  currentStep: null,
  data: null,
  previousStep: null,
  previousData: null,
  nextAction: null,
  formikSchema: null,
  historyPath: [],
};

export const GuidedAttendanceProvider = ({ children }) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [contextTree, setContextTree] = useReducer((state, action) => {
    const copyState = { ...state };
    const newState = { ...copyState, ...action };

    return newState;
  }, INITIAL_STATE);

  const handleClearData = () => {
    setContextTree({ ...INITIAL_STATE });
  };

  const value = useMemo(
    () => ({
      handleClearData,
      contextTree,
      setContextTree,
      loadingSubmit,
      setLoadingSubmit,
    }),
    [
      handleClearData,
      contextTree,
      setContextTree,
      loadingSubmit,
      setLoadingSubmit,
    ],
  );

  return (
    <GuidedAttendanceContext.Provider value={value}>
      {children}
    </GuidedAttendanceContext.Provider>
  );
};

export default { GuidedAttendanceContext, GuidedAttendanceProvider };

GuidedAttendanceProvider.propTypes = {
  children: propTypes.oneOfType([propTypes.node, propTypes.element]),
};

GuidedAttendanceProvider.defaultProps = {
  children: undefined,
};
