import styled from 'styled-components';

import { Select } from '@kiper/ui';

export const StyledSelect = styled(Select)`
  width: 100%;
  .select__menu {
    z-index: 999999;
  }

  .select__single-value {
    width: 100%;
  }

  .select__value-container {
    padding: 2px 6px;
  }
`;

export const GroupLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const GroupLabel = styled.span``;

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  justify-content: space-between;
`;

export const Option = styled.span`
  height: auto;
`;

export const OptionDescription = styled.span`
  opacity: 0.6;
  height: auto;
`;
