import styled from 'styled-components';
import {
  Form as form,
  Button as button,
  FormGroup as formGroup,
  Col as col,
  Input as input,
} from 'reactstrap';

export const LinkWrapper = styled.div`
  height: auto;

  a {
    display: inline-flex;
    height: 36px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 16px;

  a {
    text-decoration: none;
  }
`;

export const Form = styled(form)`
  flex: 1;
`;

export const Input = styled(input)`
  border-radius: 8px;
  border-color: ${props => props.theme.colors.secondary400};
  width: 100% !important;
  ::placeholder {
    color: ${props => props.theme.colors.secondary400};
  }
`;

export const Col = styled(col)`
  padding-left: 0;
`;

export const Button = styled(button)`
  &:active {
    transform: translateY(2px);
  }
`;

export const FormGroup = styled(formGroup)`
  margin-bottom: 0;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: block;
  width: 100%;
`;

export const TableContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgba(72, 94, 144, 0.16);
`;
