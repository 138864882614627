import { useState } from 'react';
import propTypes from 'prop-types';
import { Flex } from '@kiper/ui';
import { useHistory } from 'react-router-dom';
import { EventContainer } from './styles';
import EventDetailsHeader from './Header';
import { EventRecord } from './EventRecord';
import EventDetailsBodyV2 from './Body/EventDetailsBodyV2';
import EventTriage from './EventTriage';
import { eventTypes } from '../../../constants';
import {
  useCurrentLoggedContext,
  useAttendanceRemoteConfig,
} from '../../../hooks';

const AttendanceDetailV2 = ({
  formik,
  event,
  eventCameras,
  hasAlertVisible,
  profilePopoverVisible,
  toggleEventTimelineModalOpen,
  usersModalOpen,
  toggleIotModalOpen,
  toggleLockerHistoryModalOpen,
  toggleEventActivityModalOpen,
  handleToggleUserPopover,
  isInAttendance,
  inAttendanceOnHold,
  dropEvent,
  loadingFinish,
  t,
}) => {
  const [activeTab, setActiveTab] = useState('commands');
  const [hadTriage, setHadTriage] = useState(false);
  const history = useHistory();
  const { loggedContext } = useCurrentLoggedContext();

  const isFallback = history?.location?.pathname.includes('fallback');

  const toggleTab = tab => {
    if (tab !== activeTab) {
      setActiveTab(tab);
    }
  };

  const { showEventTriage } = useAttendanceRemoteConfig(
    loggedContext,
    event?.condominium,
  );

  return (
    <Flex
      bg="white"
      height={hasAlertVisible ? 'calc(100vh - 60px)' : '100vh'}
      width="100%"
      flex={1}
      flexDirection="column"
    >
      <EventContainer mx="auto" fallback={+isFallback}>
        <EventDetailsHeader
          formik={formik}
          event={event}
          popoverVisible={profilePopoverVisible === event?.person}
          onClickAvatar={() => handleToggleUserPopover(event.person)}
          t={t}
          onClickLockerHistory={toggleLockerHistoryModalOpen}
          onClickEventTimeline={toggleEventTimelineModalOpen}
          onClickEventActivity={toggleEventActivityModalOpen}
          onClickSettings={() => {}}
          onClickIot={toggleIotModalOpen}
          disabled={!isInAttendance && !inAttendanceOnHold}
          toggleTab={toggleTab}
        />

        {showEventTriage &&
        !hadTriage &&
        !event.triage &&
        event?.eventType === eventTypes.answeredCall ? (
          <EventTriage
            formikBag={formik}
            completeTriage={() => setHadTriage(true)}
            event={event}
            toggleTab={toggleTab}
          />
        ) : (
          <EventDetailsBodyV2
            formik={formik}
            event={event}
            eventCameras={eventCameras}
            isInAttendance={isInAttendance}
            activeTab={activeTab}
            toggleTab={toggleTab}
          />
        )}
        {event && (
          <EventRecord
            event={event}
            drop={dropEvent}
            loading={loadingFinish}
            usersModalOpen={usersModalOpen}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            {...formik}
          />
        )}
      </EventContainer>
    </Flex>
  );
};

export default AttendanceDetailV2;

AttendanceDetailV2.propTypes = {
  formik: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  eventCameras: propTypes.array.isRequired,
  hasAlertVisible: propTypes.bool.isRequired,
  toggleEventTimelineModalOpen: propTypes.func.isRequired,
  toggleIotModalOpen: propTypes.func.isRequired,
  toggleEventActivityModalOpen: propTypes.func.isRequired,
  toggleLockerHistoryModalOpen: propTypes.func.isRequired,
  handleToggleUserPopover: propTypes.func.isRequired,
  dropEvent: propTypes.func.isRequired,
  t: propTypes.func.isRequired,
  usersModalOpen: propTypes.bool.isRequired,
  isInAttendance: propTypes.bool.isRequired,
  inAttendanceOnHold: propTypes.bool.isRequired,
  loadingFinish: propTypes.bool.isRequired,
  profilePopoverVisible: propTypes.bool,
};

AttendanceDetailV2.defaultProps = {
  profilePopoverVisible: false,
};
