import { Flex, KiperButton, Text as text, Tooltip as tooltip } from '@kiper/ui';
import styled, { css } from 'styled-components';

const EVENT_STYLED_STATUS = Object.freeze({
  scheduledAttend: 'scheduled-attend',
  scheduledNotAttend: 'scheduled-not-attend',
  telephonyAttend: 'telephony-attend',
  telephonyNotAttend: 'telephony-not-attend',
  criticalAttend: 'critical-attend',
  criticalNotAttend: 'critical-not-attend',
  nonCriticalAttend: 'non-critical-attend',
  nonCriticalNotAttend: 'non-critical-not-attend',
});

const styleMap = {
  'scheduled-attend': theme => css`
    background-color: ${theme.colors.info500};
    border-color: ${theme.colors.info500};
  `,
  'scheduled-not-attend': theme => css`
    background-color: ${theme.colors.info50};
    border-color: ${theme.colors.info500};

    :hover {
      background-color: ${theme.colors.info100};
    }

    div {
      span.event-title {
        color: ${theme.colors.info500};
      }
    }
  `,
  'telephony-attend': theme => css`
    background-color: ${theme.colors.violet500};
    border-color: ${theme.colors.violet500};
  `,
  'telephony-not-attend': theme => css`
    background-color: ${theme.colors.violet50};
    border-color: ${theme.colors.violet500};

    :hover {
      background-color: ${theme.colors.violet100};
    }

    div {
      span.event-title {
        color: ${theme.colors.violet500};
      }
    }
  `,
  'non-critical-attend': theme => css`
    background-color: ${theme.colors.primary500};
    border-color: ${theme.colors.primary500};
  `,
  'non-critical-not-attend': theme => css`
    background-color: ${theme.colors.white};
    border-color: ${theme.colors.secondary500};

    :hover {
      background-color: ${theme.colors.secondary100};
    }
  `,
  'critical-attend': theme => css`
    background-color: ${theme.colors.dangerSecondary};
    border-color: ${theme.colors.dangerSecondary};
  `,
  'critical-not-attend': theme => css`
    background-color: ${theme.colors.danger50};
    border-color: ${theme.colors.danger500};

    :hover {
      background-color: ${theme.colors.danger100};
    }

    div {
      span.event-title {
        color: ${theme.colors.danger500};
      }
    }
  `,
};

const getEventStyleStatusKey = ({
  isEventScheduled,
  attend,
  isTelephony,
  critical,
}) => {
  if (isEventScheduled) {
    return attend
      ? EVENT_STYLED_STATUS.scheduledAttend
      : EVENT_STYLED_STATUS.scheduledNotAttend;
  }
  if (isTelephony) {
    return attend
      ? EVENT_STYLED_STATUS.telephonyAttend
      : EVENT_STYLED_STATUS.telephonyNotAttend;
  }
  if (critical) {
    return attend
      ? EVENT_STYLED_STATUS.criticalAttend
      : EVENT_STYLED_STATUS.criticalNotAttend;
  }
  return attend
    ? EVENT_STYLED_STATUS.nonCriticalAttend
    : EVENT_STYLED_STATUS.nonCriticalNotAttend;
};

const handleGetEventStyle = params => {
  const key = getEventStyleStatusKey(params);
  const styleFunction = styleMap[key];
  return styleFunction ? styleFunction(params.theme) : null;
};

export const Container = styled(Flex)`
  flex-direction: column;
  gap: 12px;
  padding: 8px;
  align-items: center;
  border-right: 1px solid ${props => props.theme.colors.secondary200};
  max-width: 64px;
  min-width: 64px;
  height: ${props => (props.fallback ? '100vh' : 'calc(100vh - 76px)')};
`;

export const Ul = styled.ul`
  padding-inline-start: 15px;
`;

export const Li = styled.li`
  text-align: start;
`;

export const SimplifiedEventListWrapper = styled(Flex)`
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 100%;
  overflow-y: ${props => (props.hovered ? 'unset' : 'auto')};
`;

export const EventDateText = styled(text)`
  font-family: Lato;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.25px;
`;

export const EventContainer = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid;
  padding: 8px;
  width: 194px;
  min-width: 194px;
  max-width: 194px;
  max-height: min-content;
  z-index: 99;
  cursor: pointer;

  color: ${props =>
    props.attend ? props.theme.colors.white : props.theme.colors.highBlack};

  ${({ critical, attend, theme, isLoading, isTelephony, isEventScheduled }) => {
    if (isLoading) {
      return css`
        background-color: ${theme.colors.warningPrimary};
        border-color: ${theme.colors.warningPrimary};
        color: ${theme.colors.white};
      `;
    }
    return handleGetEventStyle({
      critical,
      attend,
      theme,
      isTelephony,
      isEventScheduled,
    });
  }}
`;

export const SimplifiedEventCard = styled(Flex)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.theme.colors.secondary300};
  border-radius: 4px;
  height: 32px;
  width: 100%;
  padding: 8px;
  transition: all 0.8s;
  color: ${props =>
    props.attend ? props.theme.colors.white : props.theme.colors.highBlack};

  ${({ critical, attend, theme, isTelephony, isEventScheduled }) => {
    return handleGetEventStyle({
      critical,
      attend,
      theme,
      isTelephony,
      isEventScheduled,
    });
  }}
`;

export const TextEllipsis = styled(text)`
  opacity: 0.8;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  cursor: default;
`;

export const Tooltip = styled(tooltip)`
  z-index: 999;
`;

export const Button = styled(KiperButton)`
  border-radius: 4px;
  width: fit-content;
  z-index: 99;
`;
