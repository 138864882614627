import { Box, Text } from '@kiper/ui';
import { Card, Container } from './styles';
import reportEmptyState from '../../assets/images/report-list-empty-state.svg';
import { useTranslation } from 'react-i18next';

const EmptyState = () => {
  const [t] = useTranslation('report');
  return (
    <Card>
      <Container>
        <img src={reportEmptyState} />
        <Box mt="16px">
          <Text fontSize={20} fontWeight={500}>
            {t('details.list-empty-state.title')}
          </Text>
        </Box>
        <Box mt="6px">
          <Text fontSize={16} fontWeight={400}>
            {t('details.list-empty-state.message')}
          </Text>
        </Box>
      </Container>
    </Card>
  );
};

export default EmptyState;
