import styled, { css } from 'styled-components';
import { KiperButton, Button as btn } from '@kiper/ui';
import { FiCornerUpLeft } from 'react-icons/fi';
import { Form as form } from 'reactstrap';

export const Button = styled(KiperButton)`
  margin-right: 10px;
`;

export const DropIcon = styled(FiCornerUpLeft)`
  height: 18px;
  width: 18px;
  margin-right: 8px;
`;

export const ButtonLink = styled(btn)`
  padding: 0px;
  text-align: center;
  vertical-align: initial;
  color: ${props => props.theme.colors.highBlack};
  margin-right: 10px;

  &:hover {
    text-decoration: underline;
  }
`;

export const Form = styled(form)`
  ${props =>
    props.expand &&
    css`
      height: 100%;
    `}
`;
