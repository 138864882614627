import { useState, useEffect } from 'react';
import useSocketClient from './useSocketClient';
import { getHeaders, updateTokens } from '../services/auth/tokens';

const useAuthenticatedSocketClient = ({ url, options, subscribe }) => {
  const [headers, setHeaders] = useState(getHeaders());
  const { loading, error } = useSocketClient({
    url,
    subscribe,
    options: { ...options, query: headers },
  });

  useEffect(() => {
    if (error?.message === 'Request failed with status code 401') {
      updateTokens().then(() => setHeaders(getHeaders()));
    }
  }, [error]);

  return { loading, error };
};

export default useAuthenticatedSocketClient;
