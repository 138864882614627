import propTypes from 'prop-types';
import Container from './styles';

const HeaderBody = ({ background, children, withPaddingBottom, ...props }) => (
  <Container
    withPaddingBottom={withPaddingBottom}
    {...props}
    className="sticky-top"
    background={background}
  >
    {children}
  </Container>
);

export default HeaderBody;

HeaderBody.propTypes = {
  children: propTypes.node.isRequired,
  withPaddingBottom: propTypes.bool,
  background: propTypes.string,
};

HeaderBody.defaultProps = {
  background: null,
  withPaddingBottom: false,
};
