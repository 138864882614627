import styled from 'styled-components';

export const TableContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: ${props => props.height || 'auto'};
  width: 100%;

  overflow-y: auto;

  thead > tr > th {
    background: ${props => props.theme.colors.secondary100};
    color: ${props => props.theme.colors.blackShades(0.7)};
  }
`;

export const TableFooter = styled.div`
  width: 100%;
  height: auto;
`;

export const Tr = styled.tr``;

export const TableCell = styled.td``;

export const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 8px;
`;

export const EmptyStateTitle = styled.h2`
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;

  color: ${props => props.theme.colors.blackShades(0.7)};
`;

export const EmptyStateDescription = styled.p`
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  width: 50%;
  color: ${props => props.theme.colors.blackShades(0.7)};
`;
