import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { Card, UserAvatar, Label, Grid } from '@kiper/ui';
import KiperAccount from '../../../components/KiperAccount';
import { Row, SpaceBetWeen } from './styles';

const HostInfo = ({ host }) => {
  const [t] = useTranslation('invite');

  return (
    <Card>
      <Card.Header>
        <Card.Title>{t('host.infoTitle')}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Grid.Row>
          <Grid.Col xs={4} align="center">
            <UserAvatar
              size={125}
              round="100%"
              src={host.email && `//avatar.kiper.com.br/${host.email}`}
              name={host.name}
            />
          </Grid.Col>
          <Grid.Col xs={8}>
            <Row>
              <div>
                <Label info>{t('host.name')}</Label>
                <Label>{host.name}</Label>
                {host.email && <KiperAccount />}
              </div>
            </Row>
            <Row>
              <div>
                <Label info>{t('host.email')}</Label>
                <Label>{host.email}</Label>
              </div>
            </Row>
            <Row last>
              <SpaceBetWeen>
                <div>
                  <Label info>{t('host.condominium')}</Label>
                  <Label>{host.condominium}</Label>
                </div>
                <div>
                  <Label info>{t('host.unitygroup-unity')}</Label>
                  <Label>
                    {host.unityGroup
                      ? `${host?.unityGroup} | ${host?.unity}`
                      : t('common:feedback.not-contain')}
                  </Label>
                </div>
              </SpaceBetWeen>
            </Row>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </Card>
  );
};

HostInfo.propTypes = {
  host: propTypes.object.isRequired,
};

export default HostInfo;
