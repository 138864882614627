import { formatDateTimeUtc } from '@kiper/fns';
import { Badge, Flex, Label } from '@kiper/ui';
import propTypes from 'prop-types';

const TemporaryAccessItem = ({ t, value }) => {
  return (
    <Flex gridGap="8px" p="16px" flexDirection="column" width="100%">
      <Flex gridGap="4px" justifyContent="flex-start" width="100%">
        {value.place.map(place => (
          <Badge
            fontColor="primary500"
            color="white"
            bordercolor="primary500"
            key={place.id}
          >
            {place.name}
          </Badge>
        ))}
      </Flex>
      <Flex justifyContent="space-between" flex="1">
        <Flex flexDirection="column">
          <Label info block>
            {t('put.access-data.start')}
          </Label>
          <Label $withoutMarginBottom>
            {formatDateTimeUtc(value.startDate)}
          </Label>
        </Flex>
        <Flex flexDirection="column">
          <Label info block>
            {t('put.access-data.end')}
          </Label>
          <Label $withoutMarginBottom>{formatDateTimeUtc(value.endDate)}</Label>
        </Flex>
      </Flex>
    </Flex>
  );
};

TemporaryAccessItem.propTypes = {
  t: propTypes.func.isRequired,
  value: propTypes.shape({
    place: propTypes.arrayOf(
      propTypes.shape({
        id: propTypes.any.isRequired,
        name: propTypes.string.isRequired,
      }),
    ),
    startDate: propTypes.string,
    endDate: propTypes.string,
  }).isRequired,
};

export default TemporaryAccessItem;
