import { useContext } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { ThumbsUp, UserCheck, Monitor } from 'react-feather';
import { MdArrowForward } from 'react-icons/md';
import { ThemeContext } from 'styled-components';

import { Flex, KiperButton, Text } from '@kiper/ui';

import { HandStyle, RowStyle } from './styles';

import Hand from './Hand';

export default function Instructions({ setInstruction }) {
  const [t] = useTranslation('user');
  const theme = useContext(ThemeContext);

  return (
    <>
      <Row>
        <Col md="6">
          <HandStyle>
            <Hand height="250px" />
          </HandStyle>
        </Col>
        <Col md="6" className="mt-4">
          <RowStyle>
            <Flex flex={1} justifyContent="space-between">
              <ThumbsUp
                className="mr-2"
                color={theme.colors.primary500}
                fill={theme.colors.highWhite}
                size={42}
              />
              <Text>{t('put.devices.modal.instructions.instruction-one')}</Text>
            </Flex>
          </RowStyle>
          <RowStyle>
            <Flex flex={1} justifyContent="space-between">
              <Monitor
                className="mr-2"
                color={theme.colors.primary500}
                fill={theme.colors.highWhite}
                size={42}
              />
              <Text>{t('put.devices.modal.instructions.instruction-two')}</Text>
            </Flex>
          </RowStyle>
          <RowStyle>
            <Flex flex={1} justifyContent="space-between">
              <UserCheck
                className="mr-2"
                color={theme.colors.primary500}
                fill={theme.colors.highWhite}
                size={42}
              />
              <Text>
                {t('put.devices.modal.instructions.instruction-three')}
              </Text>
            </Flex>
          </RowStyle>
          <RowStyle>
            <KiperButton
              color="primary"
              block
              onClick={() => setInstruction(false)}
            >
              <Flex flex={1} justifyContent="space-between">
                <span>
                  {t('put.devices.modal.instructions.instructions-next')}
                </span>
                <MdArrowForward
                  color={theme.colors.primary500}
                  fill={theme.colors.highWhite}
                  size={30}
                />
              </Flex>
            </KiperButton>
          </RowStyle>
        </Col>
      </Row>
    </>
  );
}

Instructions.propTypes = {
  setInstruction: propTypes.func.isRequired,
};
