import { useCallback, useContext, useMemo } from 'react';
import propTypes from 'prop-types';
import * as yup from 'yup';
import { useQuery, useMutation } from 'react-apollo';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Modal, Input as CustomInput, Flex, ErrorMessage } from '@kiper/ui';
import { getWaitTimers, putOnHold } from '@kiper/monitoring-graphql/event';

import { formatTimerLabel, apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { useHistory } from 'react-router-dom';
import { Text, Button, Form, KiperRadioCustomButton } from './styles';

import attendanceContext from '../../../pages/EventAttendance/attendanceContext';
import { useCurrentLoggedContext, useRemoteConfig } from '../../../hooks';
import { eventTypes } from '../../../constants';
import { EventAttendanceContext } from '../../../store';

const transformContextObject = (context, isFallback) => {
  const result = {
    attendanceList: {
      allEvents: isFallback ? context.events : context.attendanceList.allEvents,
    },
    play: {
      getEvent: context.play.getEvent,
    },
    loading: context.loading,
    activeTab: context?.tabControl?.activeTab,
    setActiveTab: context?.tabControl?.setActiveTab,
    TAB_IDS: context?.tabControl?.TAB_IDS,
  };

  return result;
};

const WaitingEventModal = ({ onToggle, event, drop }) => {
  const [t] = useTranslation('modals/waiting-event-modal');
  const { toast } = useSwal();

  const { loggedContext } = useCurrentLoggedContext();
  const history = useHistory();
  const isFallback = history?.location?.pathname.includes('fallback');

  const context = isFallback
    ? useContext(EventAttendanceContext)
    : useContext(attendanceContext);

  const {
    loading,
    attendanceList: { allEvents },
    play: { getEvent },
    activeTab,
    setActiveTab,
    TAB_IDS,
  } = transformContextObject(context, isFallback);

  const { data, loading: waitTimersLoading } = useQuery(getWaitTimers, {
    fetchPolicy: 'cache-first',
  });

  const getNextEvent = useCallback(async () => {
    if (!isFallback && allEvents?.length) {
      for (let i = 0; i < allEvents?.length; i += 1) {
        const isTechnicalReviewQueue =
          activeTab === TAB_IDS.ON_TECHNICAL_REVIEW_QUEUE;

        if (!allEvents[i].attendant && !isTechnicalReviewQueue) {
          try {
            await getEvent(allEvents[i].eventId); // eslint-disable-line
            if (activeTab === TAB_IDS.ON_HOLD_QUEUE)
              setActiveTab(TAB_IDS.ATTENDANCE_QUEUE);
            break;
          } catch (err) {
            continue; // eslint-disable-line
          }
        }
      }
    }
  }, [allEvents]);

  const enableEventAutomaticCatch = useRemoteConfig({
    path: 'attendance.eventAutomaticCatch',
    partner: Number(loggedContext?.partner?.personContextId),
    user: Number(loggedContext?.personContextId),
  });

  const [post, { loading: loadingSubmiting }] = useMutation(putOnHold, {
    onCompleted: () => {
      toast.fire({
        icon: 'success',
        title: t('common:save-success'),
      });
      drop().then(() => {
        setTimeout(() => onToggle(), 100);
      });
    },

    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const onSubmit = values => {
    post({
      variables: { eventId: event.eventId, putOnHoldBody: { ...values } },
    }).then(
      () =>
        enableEventAutomaticCatch &&
        event.eventType !== eventTypes.attendanceAdditional &&
        event.eventType !== eventTypes.answeredCall &&
        getNextEvent(),
    );
  };

  const timers = useMemo(() => data?.waitingEventTimers?.waitTimers ?? [], [
    data,
  ]);

  const { setFieldValue, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      reason: '',
      waitTime: null,
    },
    validationSchema: yup.object({
      reason: yup.string().required(t('reason-required')),
      waitTime: yup
        .number()
        .required()
        .typeError(t('time-required')),
    }),
    onSubmit,
  });

  const handleChangeTimer = e =>
    setFieldValue(e.current.name, +e.current.value);

  const { waitTimeError, reasonError } = useMemo(
    () => ({
      waitTimeError: touched?.waitTime && !!errors?.waitTime,
      reasonError: touched?.reason && !!errors?.reason,
    }),
    [errors, touched],
  );

  return (
    <Modal title={t('title')} open toggle={onToggle} size="md">
      <Flex flexDirection="column">
        <Form onSubmit={handleSubmit}>
          <Flex flexDirection="column" mb="24px">
            <Flex flexDirection="column" mb="24px">
              <Text>{t('how-long')}</Text>
              <Flex>
                {!waitTimersLoading &&
                  timers.length &&
                  timers.map(time => (
                    <KiperRadioCustomButton
                      key={time}
                      checked={time === values.waitTime}
                      value={time}
                      name="waitTime"
                      onChange={handleChangeTimer}
                      label={formatTimerLabel(time)}
                    />
                  ))}
              </Flex>
              {waitTimeError && <ErrorMessage>{errors.waitTime}</ErrorMessage>}
            </Flex>
            <Flex flexDirection="column">
              <Text>{t('what-reason')}</Text>
              <CustomInput
                type="textarea"
                placeholder={t('reason-placeholder')}
                onChange={setFieldValue}
                name="reason"
                value={values.reason}
                rows={4}
              />
              {reasonError && <ErrorMessage>{errors.reason}</ErrorMessage>}
            </Flex>
          </Flex>
          <Flex justifyContent="flex-end">
            <Button
              type="button"
              color="neutral"
              variant="outlined"
              onClick={onToggle}
            >
              {t('buttons:cancel')}
            </Button>

            <Button
              type="submit"
              color="primary"
              variant="contained"
              loading={loadingSubmiting || loading}
              disabled={loadingSubmiting || loading}
            >
              {loadingSubmiting || loading
                ? t('buttons:confirming')
                : t('buttons:confirm')}
            </Button>
          </Flex>
        </Form>
      </Flex>
    </Modal>
  );
};

export default WaitingEventModal;

WaitingEventModal.propTypes = {
  onToggle: propTypes.func.isRequired,
  event: propTypes.object.isRequired,
  drop: propTypes.func.isRequired,
};
