import { apolloErrorHandler } from '@kiper/fns';
import { insertStepHistory } from '@kiper/monitoring-graphql/guided_attendance';
import { useContext } from 'react';
import { useMutation } from 'react-apollo';
import { useSwal } from '@kiper/hooks';
import { eventTriage } from '@kiper/monitoring-graphql/attendance';
import { GuidedAttendanceContext } from '../../../../store';

const useMutations = () => {
  const { contextTree } = useContext(GuidedAttendanceContext);
  const { toast } = useSwal();

  const [createTriage] = useMutation(eventTriage, {
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const [stepHistory] = useMutation(insertStepHistory, {
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const handleInsertStepHistory = (value, eventId) => {
    const { stepAttendanceGetReason } = value;
    const stepId = contextTree?.currentStep?.id;
    const triageId = stepAttendanceGetReason?.id;

    const triage = {
      triageId,
      description: {
        pt: stepAttendanceGetReason?.description,
        en: stepAttendanceGetReason?.descriptionEn,
        es: stepAttendanceGetReason?.descriptionEs,
      },
    };

    return stepHistory({
      variables: {
        eventId,
        stepId,
        data: JSON.stringify(triage),
      },
    });
  };

  const handleCreateTriage = async (value, eventId) => {
    const variables = {
      eventId,
      triageId: value?.stepAttendanceGetReason?.id,
    };

    if (value?.stepAttendanceGetName?.name) {
      variables.serializedTriage = JSON.stringify({
        identifier: value.stepAttendanceGetName.name,
      });
    }

    await createTriage({
      variables,
    });
  };

  return { handleInsertStepHistory, handleCreateTriage };
};

export default useMutations;
