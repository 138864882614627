import { useMemo } from 'react';
import { daysMap, daysOfWeekEnum } from '../../../../../constants';

const useSchedule = ({ schedule }) => {
  const convertTimeRestrictions = array => {
    if (!array || !array.length) return [];
    const tempResult = Object.fromEntries(daysOfWeekEnum.map(day => [day, []]));

    const currentDayIndex = new Date().getDay();
    const currentDayName = daysMap[currentDayIndex];

    const orderedDays = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ];

    array.forEach(entry => {
      const { daysOfWeek, startTime, endTime } = entry;
      if (daysOfWeek?.length) {
        daysOfWeek.forEach(day => {
          const dayName = daysMap[day];
          tempResult[dayName].push(`${startTime}-${endTime}`);
        });
      }
    });
    const result = orderedDays.map(day => ({
      dayOfWeek: day,
      time: tempResult[day].join('  '),
      currentDay: day === currentDayName,
    }));

    return result;
  };

  const schedules = useMemo(() => {
    if (schedule?.value) {
      const parsed = convertTimeRestrictions(JSON.parse(schedule?.value));
      return parsed;
    }
    return [];
  }, []);

  return { schedules };
};

export default useSchedule;
