/* eslint-disable import/prefer-default-export */
import { KiperButton as Button } from '@kiper/ui';
import Creatable from 'react-select/creatable';
import styled from 'styled-components';
import { InputGroup } from 'reactstrap';

export const ButtonIcon = styled(Button)`
  border: none;
`;

export const Select = styled(Creatable)`
  width: 100%;
`;

export const InputContainer = styled(InputGroup)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FlexContainer = styled.div`
  flex: 1;
`;

export const IconContainer = styled.div`
  width: 26px;
  margin-left: 5px;
`;
