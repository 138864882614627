import propTypes from 'prop-types';
import Input from '../Input';
import Label from '../Label';

export default function EditableInput({ isEditActive, value, ...props }) {
  if (!isEditActive) return <Label>{value}</Label>;
  return <Input {...props} value={value} />;
}

EditableInput.propTypes = {
  value: propTypes.any,
  isEditActive: propTypes.bool,
};

EditableInput.defaultProps = {
  value: undefined,
  isEditActive: false,
};
