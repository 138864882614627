import {
  Flex as flex,
  Text,
  Badge as badge,
  Button as button,
} from '@kiper/ui';
import styled from 'styled-components';

export const Container = styled(flex)`
  flex-direction: column;
`;

export const Title = styled(Text)`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: ${props => props.theme.colors.highBlack};

  @media screen and (max-width: 1366px) {
    font-size: 18px;
  }
`;

export const BadgeText = styled(Text)`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: ${props => props.theme.colors.highBlack};
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const GuidelinesTitle = styled(Text)`
  color: ${props => props.theme.colors.blackShades(0.8)};
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
`;

export const HowActText = styled(Text)`
  line-break: auto;
  white-space: break-spaces;
  word-break: break-word;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: ${props => props.theme.colors.blackShades(0.8)};

  max-height: 60px;
`;

export const Badge = styled(badge).attrs({
  color: 'info500',
  bordercolor: 'info500',
})`
  @media screen and (max-width: 1280px) {
    max-width: 231px;
  }

  @media screen and (max-width: 1366px) {
    max-width: 253px;
  }
`;

export const Question = styled(Text)`
  color: ${props => props.theme.colors.highBlack};
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 400;
`;

export const Button = styled(button)`
  padding-left: 12px;
  padding-right: 12px;
  span {
    color: ${props => props.theme.colors.blackShades(0.8)} !important;
    font-weight: 600;
  }
`;

export const Box = styled(flex)`
  gap: 20px;
  flex-direction: column;

  @media screen and (max-width: 1366px) {
    gap: 12px;
  }
`;

export const CameraContainer = styled(flex)`
  gap: 12px;
  flex-direction: column;

  @media screen and (max-width: 1366px) {
    gap: 8px;
  }
`;

export const AlertText = styled(Text)`
  font-family: Nunito Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: ${props => props.theme.colors.dangerSecondary};
`;
