import { Flex, Text as text } from '@kiper/ui';
import styled from 'styled-components';
import { Form as form } from 'reactstrap';

export const Container = styled(Flex)`
  flex: 1;
  border-right: 1px solid;
  border-color: ${({ theme }) => theme.colors.secondary300};
  height: 100%;
  width: 100%;
`;

export const ContentContainer = styled(Flex)`
  height: 100%;
  width: 100%;
  padding-top: 16px;
  margin: 0 auto;
`;

export const StepContainer = styled(Flex)`
  flex-direction: column;
  gap: 32px;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  @media screen and (max-width: 1600px) {
    gap: 16px;
  }
`;

export const Form = styled(form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const SpinnerContainer = styled(Flex)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -128px;

  @media screen and (max-width: 1600px) {
    margin-top: -64px;
  }
`;

export const TextEllipsis = styled(text)`
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  cursor: default;
  color: ${props => props.theme.colors.secondary700};
`;
