import { useContext } from 'react';
import { useGuidedAttendance } from '../../../../hooks';
import { GuidedAttendanceContext, TriageContext } from '../../../../store';
import useMutations from './useMutations';

const useNextAction = ({ event }) => {
  const { handleNextAction } = useGuidedAttendance();
  const { contextTree } = useContext(GuidedAttendanceContext);
  const { setTriageContext } = useContext(TriageContext);
  const { stepHistory } = useMutations();

  const handleInsertStepHistory = value => {
    const eventId = event?.eventId;
    const stepId = contextTree?.currentStep?.id;

    const document = value?.stepDwellerValidation?.dweller?.documents?.find(
      doc => value?.stepDwellerValidation[doc.type] === doc.value,
    );

    const data = {
      dweller: value?.stepDwellerValidation?.dweller,
      dwellerValidationStatus:
        value?.stepDwellerValidation?.dwellerValidationStatus,
      documentType: document?.type,
    };

    return stepHistory({
      variables: {
        eventId,
        stepId,
        data: JSON.stringify(data),
      },
    });
  };

  const handlePopulateDwellerInLog = values => {
    const dwellerName = values?.stepDwellerValidation?.dweller?.name;

    if (dwellerName) {
      setTriageContext({
        dweller: dwellerName,
      });
    }
  };

  const nextAction = async stepData => {
    handlePopulateDwellerInLog(stepData);
    handleInsertStepHistory(stepData);
    const handleNextFn = await handleNextAction({
      values: stepData,
      fieldValue: 'stepDwellerValidation',
    });

    return handleNextFn;
  };
  return { nextAction };
};

export default useNextAction;
