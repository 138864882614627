import { useMemo } from 'react';
import { queries } from '@kiper/monitoring-graphql/triage';
import { useQuery } from 'react-apollo';
import { useGetDescriptions } from '../../QuestionsAndAnswers/hooks';

const triageType = Object.freeze({
  LEGACY: 'legacy',
  NEW: 'new',
  LEGACY_AND_NEW: 'legacyAndNew',
});

const useGetTriages = ({ filteredTriage }) => {
  const { data, loading } = useQuery(queries.triages, {
    fetchPolicy: 'cache-first',
  });
  const { handleGetDescription } = useGetDescriptions();

  const handleMoveSelectedToFirstPosition = (array, selectedItem) => {
    if (!array?.length) return [];
    if (!selectedItem) return array;
    const filteredArray = array?.filter(item => item?.id !== selectedItem?.id);

    return [selectedItem, ...filteredArray];
  };

  const handleFilterTriage = triage => {
    const { serializedParams } = triage;
    if (!serializedParams) return false;

    const params = JSON.parse(serializedParams);

    const isLegacyAndNew = params?.triageType === triageType.LEGACY_AND_NEW;
    const isNew = params?.triageType === triageType.NEW;

    return isLegacyAndNew || isNew;
  };

  const triages = useMemo(() => {
    const filteredTriages = data?.triages
      ?.filter(t => handleFilterTriage(t))
      ?.map(triage => ({
        ...triage,
        id: +triage.id,
        description: handleGetDescription(triage),
      }));

    return handleMoveSelectedToFirstPosition(filteredTriages, filteredTriage);
  }, [data]);

  return { triages, loading };
};

export default useGetTriages;
