import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tr, Td, DataContainer, ColumnData, Container } from './styles';

const typeMapping = {
  0: 'car',
  1: 'motorcycle',
};

export const DefaultChildren = ({ primaryData, secondaryData }) => {
  return (
    <DataContainer>
      <ColumnData>{primaryData}</ColumnData>
      <ColumnData>{secondaryData}</ColumnData>
    </DataContainer>
  );
};

DefaultChildren.propTypes = {
  primaryData: propTypes.string,
  secondaryData: propTypes.string,
};

DefaultChildren.defaultProps = {
  primaryData: null,
  secondaryData: null,
};

export const VehicleTypeChildren = ({ primaryData, secondaryData }) => {
  const [t] = useTranslation('report');
  return (
    <DataContainer>
      <ColumnData>
        {t(`vehicles.columns.vehicle-type-${typeMapping[primaryData]}`)}
      </ColumnData>
      <ColumnData>{secondaryData}</ColumnData>
    </DataContainer>
  );
};

VehicleTypeChildren.propTypes = {
  primaryData: propTypes.number,
  secondaryData: propTypes.string,
};

VehicleTypeChildren.defaultProps = {
  primaryData: null,
  secondaryData: null,
};

const VehicleRow = ({ data, columns, last }) => (
  <Tr last={last}>
    {columns?.map(
      (
        {
          width,
          children: Children,
          primary,
          secondary,
          onClick,
          tooltipMessage,
        },
        index,
      ) => (
        <Td width={width} key={`vehicle-report-column-${index}`}>
          <Container>
            <Children
              primaryData={data[primary]}
              secondaryData={data[secondary]}
              onClick={onClick}
              tooltipMessage={tooltipMessage}
              {...data}
            />
          </Container>
        </Td>
      ),
    )}
  </Tr>
);

VehicleRow.propTypes = {
  data: propTypes.any.isRequired,
  columns: propTypes.array.isRequired,
  last: propTypes.bool.isRequired,
};

export default VehicleRow;
