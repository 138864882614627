import propTypes from 'prop-types';
import { ErrorMessage, Flex, Text } from '@kiper/ui';
import { Input, SuccessText } from '../styles';

const DocumentInput = ({
  document,
  value,
  touch,
  error,
  setFieldValue,
  handleMaxCharacters,
  handleValidateDocument,
  checkFieldBeenValidated,
  t,
}) => {
  return (
    <Flex key={document.type} gridGap="8px" flexDirection="column">
      <Text>
        {t(`step-dweller-validation.documents.inform-${document.type}`)}
      </Text>
      <Input
        type="text"
        placeholder={t(
          `step-dweller-validation.documents.inform-${document.type}`,
        )}
        name={`stepDwellerValidation.${document.type}`}
        value={value(`${document?.type}`) || ''}
        onChange={setFieldValue}
        maxLength={handleMaxCharacters(document?.type)}
        onKeyUp={handleValidateDocument}
        invalid={!!touch(`${document?.type}`) && !!error(`${document?.type}`)}
        valid={checkFieldBeenValidated(document.type)}
        disabled={checkFieldBeenValidated(document.type)}
      />
      {checkFieldBeenValidated(document?.type) && (
        <SuccessText>
          {t('step-dweller-validation.feedback.success.document-validated', {
            document: t(`step-dweller-validation.documents.${document?.type}`),
          })}
        </SuccessText>
      )}
      {touch(`${document?.type}`) && error(`${document?.type}`) && (
        <ErrorMessage>
          {t(`step-dweller-validation.feedback.error.invalid-document`, {
            document: t(`step-dweller-validation.documents.${document?.type}`),
          })}
        </ErrorMessage>
      )}
    </Flex>
  );
};

export default DocumentInput;

DocumentInput.propTypes = {
  document: propTypes.shape({
    type: propTypes.string,
    value: propTypes.string,
  }).isRequired,
  value: propTypes.func.isRequired,
  touch: propTypes.func.isRequired,
  error: propTypes.func.isRequired,
  setFieldValue: propTypes.func.isRequired,
  handleMaxCharacters: propTypes.func.isRequired,
  handleValidateDocument: propTypes.func.isRequired,
  checkFieldBeenValidated: propTypes.func.isRequired,
  t: propTypes.func.isRequired,
};
