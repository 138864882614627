import { createContext, useReducer, useMemo, useEffect } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { sendAmplitudeData } from '../../services/amplitude';

export const TriageContext = createContext();

const INITIAL_STATE = {
  filterRulesIds: [],
  filterText: '',
  firstFillAction: true,
  filterGUUnit: null,
  triage: null,
  identifier: null,
  dweller: null,
  eventId: null,
};

export const TriageProvider = ({ children }) => {
  const { t } = useTranslation('event_attendance');
  const history = useHistory();

  const [triageContext, setTriageContext] = useReducer((state, action) => {
    const copyState = { ...state };
    const newState = { ...copyState, ...action };

    return newState;
  }, INITIAL_STATE);

  const fillAction = (inputName, eventId) => {
    if (triageContext.firstFillAction) {
      sendAmplitudeData('triage first fill', {
        eventId,
        input: inputName,
      });
      setTriageContext({ firstFillAction: false });
    }
  };

  const handleClear = () => {
    setTriageContext({ ...INITIAL_STATE });
  };

  useEffect(() => {
    const { triage, filterGUUnit, eventId } = triageContext;
    const pathname = history?.location?.pathname;

    if (pathname.includes(eventId) && !!triage?.textLog) {
      const log = triage?.textLog;
      const unityGroupName = filterGUUnit?.gu?.label;
      const unityName = filterGUUnit?.label;
      const unityAndUnityGroup = unityGroupName
        ? `${unityName} - ${unityGroupName}`
        : unityName;

      const reportValue = t(log, {
        identifier: triageContext?.identifier || '     ',
        gu_un: unityAndUnityGroup || '     ',
        dweller: triageContext?.dweller || '     ',
      });

      localStorage.setItem(eventId, reportValue);
    }
  }, [
    triageContext?.eventId,
    triageContext?.filterGUUnit,
    triageContext?.dweller,
  ]);

  const value = useMemo(
    () => ({
      handleClear,
      triageContext,
      setTriageContext,
      fillAction,
    }),
    [triageContext, setTriageContext, handleClear],
  );

  return (
    <TriageContext.Provider value={value}>{children}</TriageContext.Provider>
  );
};

export default { TriageContext, TriageProvider };

TriageProvider.propTypes = {
  children: propTypes.oneOfType([propTypes.node, propTypes.element]),
};

TriageProvider.defaultProps = {
  children: undefined,
};
