import { useState } from 'react';
import propTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Modal, Flex, Box, Text } from '@kiper/ui';
import { FiMonitor as MatrixIcon, FiPhone as PhoneIcon } from 'react-icons/fi';
import { Nav, NavItem, NavLink, TabContent, TabPane } from './styles';
import MatrixSurveillance from './MatrixSurveillance';
import TelephonyLink from './TelephonyLink';

function MySettingsModal({ onToggle, open }) {
  const [activeTab, setActiveTab] = useState('matrixSurveillance');
  const [t] = useTranslation('modals/my-settings');

  const toggleTab = tab => {
    if (tab !== activeTab) {
      setActiveTab(tab);
    }
  };

  return (
    <Modal
      open={open}
      id="my-settings-modal"
      data-cy="my-settings-modal"
      title={t('title')}
      toggle={onToggle}
      size="lg"
    >
      <Flex height="542px">
        <Flex width="280px" pr="16px">
          <Box width="100%">
            <Nav vertical pills>
              <NavItem>
                <NavLink
                  active={activeTab === 'matrixSurveillance'}
                  onClick={() => toggleTab('matrixSurveillance')}
                  data-cy="matrix-surveillance"
                >
                  <MatrixIcon />
                  <Text ml="8px" fontSize={14} lineHeight="16px">
                    {t('matrix-surveillance.label')}
                  </Text>
                </NavLink>
                <NavLink
                  active={activeTab === 'telephonyLink'}
                  onClick={() => toggleTab('telephonyLink')}
                  data-cy="telephony-link"
                >
                  <PhoneIcon />
                  <Text ml="8px" fontSize={14} lineHeight="16px">
                    {t('telephony-link.title-menu')}
                  </Text>
                </NavLink>
              </NavItem>
            </Nav>
          </Box>
        </Flex>
        <Flex width="100%" height="100%" flexDirection="column">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="matrixSurveillance">
              <MatrixSurveillance handleCancel={onToggle} />
            </TabPane>
            <TabPane tabId="telephonyLink">
              <TelephonyLink handleCancel={onToggle} />
            </TabPane>
          </TabContent>
        </Flex>
      </Flex>
    </Modal>
  );
}

export default MySettingsModal;

MySettingsModal.propTypes = {
  onToggle: propTypes.func.isRequired,
  open: propTypes.bool.isRequired,
};
