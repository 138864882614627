import { useEffect, useState } from 'react';
import { formatDateUtc, isValidDate, toDateType } from '@kiper/fns';

export const useFormPeriodDate = ({ temporaryAccessValue, onChange }) => {
  const [renderedInitialValues, setRenderedInitialValues] = useState(false);
  const [dates, setDates] = useState({
    startDate: '',
    endDate: '',
  });

  const getDateUtc = date => {
    if (!date) return '';

    return formatDateUtc({
      date: new Date(date),
      format: 'YYYY-MM-DD HH:mm:ss',
    });
  };

  useEffect(() => {
    if (!renderedInitialValues && (!dates.startDate || !dates.endDate)) {
      const newDates = {
        startDate: '',
        endDate: '',
      };

      if (isValidDate(temporaryAccessValue.startDate)) {
        newDates.startDate = toDateType(temporaryAccessValue.startDate);
      }
      if (isValidDate(temporaryAccessValue.endDate)) {
        newDates.endDate = toDateType(temporaryAccessValue.endDate);
      }

      setRenderedInitialValues(true);
      setDates({ ...newDates });
    }
  }, [temporaryAccessValue]);

  const handleChangeDate = ({ name, dateValue }) => {
    setDates(prevState => ({
      ...prevState,
      [name]: dateValue,
    }));
    onChange({ ...temporaryAccessValue, [name]: getDateUtc(dateValue) });
  };

  return {
    dates,
    handleChangeDate,
  };
};
