import { useMemo } from 'react';
import { useMutation } from 'react-apollo';
import { adamSender } from '@kiper/monitoring-graphql';
import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { useViewRules } from '../../../../../hooks';

export const useSync = (condominium, t) => {
  const { toast } = useSwal();

  const { permission, loading: loadingPermission } = useViewRules({
    path: 'condominiumSync',
  });

  const enableSyncAction = useMemo(
    () => !loadingPermission && permission?.ableToWrite,
    [permission],
  );

  const [syncUsers, { loading: loadingSync }] = useMutation(adamSender.sync, {
    variables: {
      identifier: +condominium.personContextId,
      type: 'condominium',
    },
    onError: args => {
      const formattedErrors = apolloErrorHandler(args);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
    onCompleted: () =>
      toast.fire({ title: t('common:sync-success'), icon: 'success' }),
  });

  const [resyncCondominium, { loading: loadingResync }] = useMutation(
    adamSender.condominiumResync,
    {
      variables: { condominiumContextId: condominium.personContextId },
      onError: args => {
        const formattedErrors = apolloErrorHandler(args);
        if (formattedErrors && formattedErrors.length) {
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
        }
      },
      onCompleted: () => {
        toast.fire({ title: t('common:sync-success'), icon: 'success' });
      },
    },
  );

  return {
    syncUsers,
    resyncCondominium,
    loadingSync,
    loadingResync,
    enableSyncAction,
  };
};
