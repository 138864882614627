import {
  useRef,
  useEffect,
  useMemo,
  useContext,
  useState,
  useLayoutEffect,
} from 'react';
import propTypes from 'prop-types';
import { useLazyQuery } from 'react-apollo';
import { MdVideocamOff } from 'react-icons/md';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Flex, Spinner, Text } from '@kiper/ui';
import { query } from '@kiper/monitoring-graphql/camera';
import { useSwal } from '@kiper/hooks';
import { event as eventsGql } from '@kiper/monitoring-graphql';
import { apolloErrorHandler } from '@kiper/fns';
import useVideo from '../../hooks/useVideo';
import * as S from './styles';

const SpinnerLoading = ({ hasPlayerControl, white, iconColor }) => {
  return (
    <S.SpinnerContainer white={white} $hasVideoPlayerControl={hasPlayerControl}>
      <Spinner size={24} color={iconColor} />
    </S.SpinnerContainer>
  );
};

const VideoCamerasGrid = ({
  event,
  eventId,
  singleCameraId,
  hasPlayerControl,
  playbackAsInitialPlayerControl,
}) => {
  const [t] = useTranslation('event_attendance');
  const { toast } = useSwal();

  const theme = useContext(ThemeContext);
  const [cameraOffline, setCameraOffline] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [isImgLoading, setIsImgLoading] = useState(true);
  const videoRef = useRef(null);

  const {
    isLive,
    frozen,
    time,
    onLiveClicked,
    onFrozenClicked,
    onRewind,
    onForward,
    onTurnLiveOn,
    resetTime,
  } = useVideo({
    eventDate: event?.eventDate,
  });

  const handleImageLoaded = () => {
    setIsImgLoading(false);
  };

  const handleError = () => {
    setCameraOffline(true);
    handleImageLoaded();
  };

  const handleSelectCamera = cameras => {
    const eventPlaceId = event?.place?.id;

    const cameraPrincipalSamePlace = cameras.find(camera => {
      const isPrincipal = JSON.parse(camera.serializedParams)?.isPrincipal;
      return camera.placeId === eventPlaceId && isPrincipal;
    });

    if (cameraPrincipalSamePlace)
      return setSelectedCamera(cameraPrincipalSamePlace);

    const cameraOnlineSamePlace = cameras.find(camera => {
      const { isOnline } = camera;
      const isSamePlace = eventPlaceId === camera.placeId;
      return isOnline && isSamePlace;
    });
    if (cameraOnlineSamePlace) return setSelectedCamera(cameraOnlineSamePlace);

    const cameraPrincipalAndOnlineWithoutPlace = cameras.find(camera => {
      const isPrincipal = JSON.parse(camera.serializedParams)?.isPrincipal;
      const { isOnline } = camera;
      return isPrincipal && isOnline;
    });
    if (cameraPrincipalAndOnlineWithoutPlace)
      return setSelectedCamera(cameraPrincipalAndOnlineWithoutPlace);

    const firstCameraOnline = cameras.find(camera => {
      const { isOnline } = camera;
      return isOnline;
    });
    if (firstCameraOnline) return setSelectedCamera(firstCameraOnline);

    return handleError();
  };

  const [getCameras, { loading: loadingCameras }] = useLazyQuery(
    eventsGql.getSingleCameras,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ eventSingleCameras }) => {
        if (eventSingleCameras?.length) handleSelectCamera(eventSingleCameras);
      },
      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length)
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      },
    },
  );

  const [liveFetch, { loading }] = useLazyQuery(query.cameraLive, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ cameraLive: live }) => {
      if (videoRef.current) videoRef.current.src = live?.url;
    },
    onError: () => {
      setCameraOffline(true);
    },
  });

  const fetchImage = ({ startTime }) => {
    liveFetch({
      variables: {
        cameraId: singleCameraId ? String(singleCameraId) : selectedCamera.id,
        startDateTime: !isLive || !!startTime ? startTime : null,
        condominiumPersonContextId: event?.condominium?.personContext?.id,
      },
    });
  };

  const cameraError = useMemo(() => cameraOffline && !loading, [
    cameraOffline,
    loading,
  ]);

  useEffect(() => {
    onLiveClicked(!playbackAsInitialPlayerControl);
    onTurnLiveOn();

    if (selectedCamera?.id) {
      if (videoRef.current) videoRef.current.src = '';

      fetchImage({ startTime: null });
    }
  }, [selectedCamera]);

  useLayoutEffect(() => {
    if (!singleCameraId) getCameras({ variables: { eventId } });
    else fetchImage({ startTime: time });

    return () => {
      if (videoRef?.current) videoRef.current.src = '';
    };
  }, []);

  if (loadingCameras)
    return (
      <S.Wrapper>
        <SpinnerLoading
          hasPlayerControl={hasPlayerControl}
          white
          iconColor="highBlack"
        />
      </S.Wrapper>
    );

  const handleRewind = async () => {
    const newTime = await onRewind();
    fetchImage({ startTime: newTime });
  };

  const handleForward = async () => {
    const newTime = await onForward();
    fetchImage({ startTime: newTime });
  };

  const handleLive = () => {
    onLiveClicked(true);
    fetchImage({ startTime: null });
  };

  const handlePlayback = () => {
    onLiveClicked(false);
    resetTime();
    fetchImage({ startTime: event?.eventDate });
  };

  const getBadgeColor = () => {
    if (isLive && !frozen) return 'danger';
    if (frozen) return 'secondary';
    return 'warning';
  };

  const getBadgeText = () => {
    if (isLive && !frozen) return t('details.cameras.player.live');
    if (frozen) return t('details.cameras.player.frozen');
    return t('details.cameras.player.playback');
  };

  return (
    <S.Wrapper>
      <S.PlayerWrapper>
        {loading ? (
          <SpinnerLoading
            hasPlayerControl={hasPlayerControl}
            iconColor="white"
          />
        ) : (
          <>
            {cameraError && (
              <S.VideoNotFound>
                <MdVideocamOff size={40} color={theme.colors.white} />
                <Text fontSize="14px" color={theme.colors.white}>
                  {t('details.cameras.no-camera-available')}
                </Text>
              </S.VideoNotFound>
            )}
            {!loadingCameras && !loading && !cameraOffline && (
              <>
                {isImgLoading && (
                  <SpinnerLoading
                    hasPlayerControl={hasPlayerControl}
                    iconColor="white"
                  />
                )}
                <S.ImgContainer
                  $hasVideoPlayerControl={hasPlayerControl}
                  loading={Number(isImgLoading)}
                  live={t('details.cameras.player.live')}
                >
                  <S.VideoImg
                    $hasVideoPlayerControl={hasPlayerControl}
                    ref={videoRef}
                    alt={selectedCamera && selectedCamera.name}
                    onError={handleError}
                    onLoad={handleImageLoaded}
                    isloading={Number(isImgLoading)}
                  />
                </S.ImgContainer>
              </>
            )}
          </>
        )}
        {hasPlayerControl && (
          <S.PlayerContainer $hasVideoPlayerControl={hasPlayerControl}>
            {!cameraError && (
              <Flex flex={1}>
                <S.Badge color={getBadgeColor()}>{getBadgeText()}</S.Badge>
              </Flex>
            )}
            <Flex flex={1} justifyContent="center" gridGap="8px">
              {!isLive && !frozen && (
                <S.ReplayIcon size="30" color="white" onClick={handleRewind} />
              )}
              {frozen ? (
                <S.PlayIcon size="30" color="white" onClick={onFrozenClicked} />
              ) : (
                <S.PauseIcon
                  size="30"
                  color="white"
                  onClick={onFrozenClicked}
                />
              )}
              {!isLive && !frozen && (
                <S.ForwardIcon
                  size="30"
                  color="white"
                  onClick={handleForward}
                />
              )}
            </Flex>
            <Flex flex={1} justifyContent="flex-end">
              {isLive ? (
                <S.Button type="button" size="sm" onClick={handlePlayback}>
                  <S.FastRewindIcon />
                  {t('details.cameras.player.playbackButton')}
                </S.Button>
              ) : (
                <S.Button type="button" size="sm" onClick={handleLive}>
                  {t('details.cameras.player.liveButton')}
                  <S.FastForwardIcon />
                </S.Button>
              )}
            </Flex>
          </S.PlayerContainer>
        )}
      </S.PlayerWrapper>
    </S.Wrapper>
  );
};

SpinnerLoading.propTypes = {
  hasPlayerControl: propTypes.bool,
  white: propTypes.bool,
  iconColor: propTypes.string,
};

SpinnerLoading.defaultProps = {
  hasPlayerControl: false,
  white: false,
  iconColor: 'highBlack',
};

VideoCamerasGrid.propTypes = {
  event: propTypes.any.isRequired,
  eventId: propTypes.any,
  hasPlayerControl: propTypes.bool,
  playbackAsInitialPlayerControl: propTypes.bool,
  singleCameraId: propTypes.any,
};

VideoCamerasGrid.defaultProps = {
  hasPlayerControl: false,
  playbackAsInitialPlayerControl: false,
  singleCameraId: null,
  eventId: null,
};

export default VideoCamerasGrid;
