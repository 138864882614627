import styled from 'styled-components';
import { Flex, Text } from '@kiper/ui';

export const Container = styled(Flex)`
  gap: 4px;
  flex-direction: column;
  padding: 8px;
  width: 100%;
  height: 100%;
`;

export const LoadingWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled(Flex)`
  flex-direction: column;
  flex-wrap: wrap;
`;

export const HeaderTitle = styled(Text)`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  width: max-content;
  color: ${({ theme }) => theme.colors.blackShades(0.8)};
  opacity: 0.9;
`;

export const Description = styled(Text)`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.highBlack};
`;
