import { useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Modal, Text } from '@kiper/ui';

import { Users } from '../../Lists';

import { Container } from './styles';

function UsersModal({ event, title, onToggle, formik }) {
  const [t] = useTranslation('user');

  const [amount, setAmount] = useState(t('list.users-amount', { amount: '0' }));

  const titleElement = (
    <>
      <Text mr="4px">{title}</Text>
      <Text fontSize="12px" color="secondary500">
        {`${amount}`}
      </Text>
    </>
  );

  return (
    <Modal title={titleElement} open toggle={onToggle} size="xl">
      <Container>
        <Users event={event} formik={formik} setAmount={setAmount} />
      </Container>
    </Modal>
  );
}

export default UsersModal;

UsersModal.propTypes = {
  event: propTypes.object.isRequired,
  title: propTypes.string.isRequired,
  onToggle: propTypes.func.isRequired,
  formik: propTypes.object.isRequired,
};
