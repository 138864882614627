import { useEffect, useLayoutEffect, useRef, useState } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const useWindowDimension = () => {
  const tableRef = useRef();
  const [maxHeightDropDown, setMaxHeightDropDown] = useState(null);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useLayoutEffect(() => {
    const height = tableRef?.current?.getBoundingClientRect().height;
    const bottom = tableRef?.current?.getBoundingClientRect().bottom;
    const overflowDiff = bottom - windowDimensions.height;
    const newMaxHeight = height - overflowDiff;

    if (newMaxHeight) setMaxHeightDropDown(`${newMaxHeight}px`);
  }, [tableRef?.current, windowDimensions]);

  return { maxHeightDropDown, tableRef };
};

export default useWindowDimension;
