import { useQuery } from 'react-apollo';

import { queries } from '@kiper/monitoring-graphql/triage';
import { useMemo } from 'react';

const useQueries = ({ values }) => {
  const { data, loading } = useQuery(queries.subtriages, {
    fetchPolicy: 'network-only',
    skip: !values?.stepAttendanceGetReason?.id,
    variables: {
      triageId: values?.stepAttendanceGetReason?.id,
    },
  });

  const subtriages = useMemo(() => data?.subtriages, [data]);

  return { subtriages, loading };
};

export default useQueries;
