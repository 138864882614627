import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Spinner } from '@kiper/ui';
import { useFormikContext } from 'formik';
import { useGuidedAttendance } from '../../hooks';
import { GuidedAttendanceContext } from '../../store';
import { ButtonReturnStep } from './styles';

export const ReturnStepButton = () => {
  const { handlePreviousStep } = useGuidedAttendance();
  const { values, setValues } = useFormikContext();
  const { t } = useTranslation('buttons');

  const handleBackStep = e => {
    handlePreviousStep(values, setValues);
    e.stopPropagation();
  };

  return <ButtonReturnStep title={t('go-to-back')} onClick={handleBackStep} />;
};

export const NextStepButton = () => {
  const { loadingSubmit } = useContext(GuidedAttendanceContext);
  const { t } = useTranslation('buttons');

  return (
    <Flex alignSelf="flex-start">
      <Button
        size="sm"
        type="submit"
        loading={loadingSubmit}
        disabled={loadingSubmit}
      >
        {loadingSubmit ? (
          <Flex gridGap="8px" alignItems="center">
            {t('continue')}
            <Spinner />
          </Flex>
        ) : (
          t('continue')
        )}
      </Button>
    </Flex>
  );
};

export default { ReturnStepButton, NextStepButton };
