import propTypes from 'prop-types';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FormGroup, Form } from 'reactstrap';
import { Button, ErrorMessage, Flex, Input, JsonTree, Label } from '@kiper/ui';
import { eventGenerator } from '@kiper/monitoring-graphql';
import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';

const EventRegister = ({ setCreationMode }) => {
  const [t] = useTranslation('event-generator');
  const { toast } = useSwal();

  const [post, { loading }] = useMutation(
    eventGenerator.mutation.eventTemplateInsert,
    {
      onCompleted: () => {
        setCreationMode(false);
      },
      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length) {
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
        }
      },
    },
  );
  const onSubmit = values => {
    const eventTemplate = {
      ...values,
      eventType: +values.eventType,
      template: JSON.stringify(values.template),
    };
    post({ variables: { eventTemplate } });
  };

  const { values, setFieldValue, touched, errors, handleSubmit } = useFormik({
    initialValues: {
      eventType: '',
      description: '',
      template: {
        cmd: '',
        esn: '',
        params: {
          createdByEventGenerator: true,
        },
      },
    },
    onSubmit,
  });

  return (
    <Form onSubmit={handleSubmit}>
      <Flex gridGap="24px">
        <Flex>
          <FormGroup>
            <Label>{t('register.event-type')}</Label>
            <Input
              placeholder={t('register.event-type-placeholder')}
              name="eventType"
              onChange={setFieldValue}
              value={values.eventType}
              invalid={touched?.eventType && !!errors?.eventType}
            />
            {touched?.eventType && !!errors?.eventType && (
              <ErrorMessage>{errors?.eventType}</ErrorMessage>
            )}
          </FormGroup>
        </Flex>
        <Flex>
          <FormGroup>
            <Label>{t('register.event-description')}</Label>
            <Input
              placeholder={t('register.event-description-placeholder')}
              name="description"
              onChange={setFieldValue}
              value={values.description}
              invalid={touched?.description && !!errors?.description}
            />
            {touched?.description && !!errors?.description && (
              <ErrorMessage>{errors?.description}</ErrorMessage>
            )}
          </FormGroup>
        </Flex>
        <Flex>
          <FormGroup>
            <Label>{t('event-template')}</Label>
            <JsonTree
              values={values.template}
              handleChange={e => setFieldValue('template', e)}
              name="template"
            />
            {touched?.template && !!errors?.template && (
              <ErrorMessage>{errors?.template}</ErrorMessage>
            )}
          </FormGroup>
        </Flex>
      </Flex>
      <Flex justifyContent="flex-end">
        <Button color="primary" type="submit" disabled={loading}>
          {t('buttons:save')}
        </Button>
      </Flex>
    </Form>
  );
};

export default EventRegister;

EventRegister.propTypes = {
  setCreationMode: propTypes.func.isRequired,
};
