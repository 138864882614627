import { useRef } from 'react';
import propTypes from 'prop-types';
import { Emoji, Input, Container } from './styles';

function SatisfactionRating({ emoji, value, handleChange, satisfactionValue }) {
  const ref = useRef(null);

  return (
    <Container>
      <Input
        ref={ref}
        key={value}
        type="radio"
        id={value}
        name="satisfactionRating"
        value={value}
        data-testid={`satisfaction-rating-${value}`}
      />
      <Emoji
        variant="text"
        checked={+value === +satisfactionValue}
        onClick={() => handleChange(ref)}
        data-testid={`emoji-button-${value}`}
      >
        {emoji}
      </Emoji>
    </Container>
  );
}

export default SatisfactionRating;

SatisfactionRating.propTypes = {
  emoji: propTypes.string.isRequired,
  value: propTypes.number.isRequired,
  handleChange: propTypes.func.isRequired,
  satisfactionValue: propTypes.number,
};

SatisfactionRating.defaultProps = {
  satisfactionValue: null,
};
