import styled from 'styled-components';
import {
  KiperButton,
  Badge as badge,
  NavItem as navItem,
  NavLink as navLink,
  Nav as nav,
} from '@kiper/ui';
import { FiBell, FiClock } from 'react-icons/fi';
import { MdOutlineConstruction } from 'react-icons/md';

export const AddButton = styled(KiperButton)`
  padding: 0px 3px;
  width: 26px;
  height: 26px;
`;

export const EmptyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalButton = styled(KiperButton)``;

export const ModalButtonContainerList = styled.div`
  overflow: hidden;
  height: 100px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0 -20px;
`;

export const ModalButtonContainerListShadow = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 8px 0px rgba(51, 50, 51, 1);
`;

export const ClockIcon = styled(FiClock).attrs(props => ({
  color: props.active
    ? props.theme.colors.primary500
    : props.theme.colors.lowBlack,
  size: 20,
}))``;

export const BellIcon = styled(FiBell).attrs(props => ({
  color: props.active
    ? props.theme.colors.primary500
    : props.theme.colors.lowBlack,
  size: 20,
}))``;

export const TechnicalAlertsIcon = styled(MdOutlineConstruction).attrs(
  props => ({
    color: props.active
      ? props.theme.colors.primary500
      : props.theme.colors.lowBlack,
    size: 20,
  }),
)``;

export const Badge = styled(badge)`
  position: absolute;
  border-radius: 20px;
  width: auto;
  height: 18px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.dangerSecondary};
  font-weight: 700;
  font-size: 10px;
  right: 4px;
  top: 4px;
  margin-top: -10px;
  padding: 0 6px;
`;

export const NavItem = styled(navItem)`
  width: 64.33px;
  margin-left: 0 !important;
`;

export const NavLink = styled(navLink)`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  padding: 5px !important;
`;

export const Nav = styled(nav)`
  margin-top: 12px;
  padding: 0 8px;
`;

export const Button = styled(KiperButton)`
  height: 28px;
  width: 28px;
  min-width: 28px !important;
`;
