import { useMemo } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-apollo';
import { Loader } from '@kiper/ui';
import { queries } from '@kiper/monitoring-graphql/condominium/detail';
import { EmptyStateIcon } from '../../../../components/EmptyStateIcon';
import {
  CloseIcon,
  FileIcon,
  Popover,
  PopoverHeader,
  PopoverBody,
  NotesText,
} from './styles';

function NotesPopover({ isOpen, toggle, condominium, ...props }) {
  const [t] = useTranslation('event_attendance');

  const { data: personContextParams, loading } = useQuery(
    queries.condominiumNotes,
    {
      fetchPolicy: 'network-only',
      variables: { condominiumId: `${condominium.personContext.id}` },
    },
  );

  const data = useMemo(
    () =>
      !loading &&
      personContextParams?.condominiumNotes?.length &&
      personContextParams?.condominiumNotes.filter(x => x.name === 'notes')[0],
    [personContextParams],
  );

  const notes = useMemo(() => data?.value && JSON.parse(data?.value).notes, [
    data,
  ]);

  return (
    <Popover isOpen={isOpen} toggle={toggle} target="notes-popover" {...props}>
      <PopoverHeader>
        {t('details.header.actions.notes-title')}
        <CloseIcon onClick={toggle} />
      </PopoverHeader>
      <PopoverBody>
        {loading && <Loader />}
        {!loading && notes && <NotesText>{notes}</NotesText>}
        {!loading && !notes && (
          <EmptyStateIcon
            icon={FileIcon}
            title={t('common:empty-state.title')}
            text={t('details.header.actions.notes-empty-state', {
              condominiumName: condominium?.name,
            })}
          />
        )}
      </PopoverBody>
    </Popover>
  );
}

export default NotesPopover;

NotesPopover.propTypes = {
  isOpen: propTypes.bool.isRequired,
  toggle: propTypes.func.isRequired,
  condominium: propTypes.shape({
    personContext: propTypes.shape({
      id: propTypes.number,
    }),
    name: propTypes.string,
  }),
};

NotesPopover.defaultProps = {
  condominium: null,
};
