import propTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from 'react-apollo';
import { useSwal } from '@kiper/hooks';
import { Label, CustomInput, Flex } from '@kiper/ui';
import { MdPhone } from 'react-icons/md';
import { person as personGql } from '@kiper/monitoring-graphql';
import { useMemo } from 'react';
import { AddIcon, IconButton } from './styles';

const PersonPhoneInput = ({
  label,
  placeholder,
  disabled,
  checkPerson,
  onRedirectClick,
  setFieldTouched,
  onChange,
}) => {
  const [t] = useTranslation('user');
  const { confirm } = useSwal();
  const { values, setFieldValue, errors, touched } = useFormikContext();

  const [checkPersonExistByPhone] = useLazyQuery(
    personGql.checkPersonExistByPhone,
    {
      fetchPolicy: 'no-cache',
      onCompleted: async ({ checkPersonExistByPhone: userInfo }) => {
        const shouldRedirect = await confirm({
          title: t('put.info-check-exist-user.title', {
            userName: userInfo.name,
          }),
          text: t('put.info-check-exist-user.text'),
          cancelButtonText: t('put.info-check-exist-user.cancel'),
          confirmButtonText: t('put.info-check-exist-user.confirm'),
        });

        if (shouldRedirect) onRedirectClick(userInfo);
        else setFieldValue('phone', '');
      },
    },
  );

  const addContact = () => {
    onChange('additionalContacts', [...values.additionalContacts, {}]);
  };

  const showPhoneButton = useMemo(() => !values?.additionalContacts?.length, [
    values,
  ]);

  const handleBlurPhone = event => {
    if (checkPerson) {
      const phone = event.target.value;
      checkPersonExistByPhone({
        variables: {
          phone: phone.replace(/\s/gi, ''),
        },
      });
    }
  };

  return (
    <>
      <Label info block>
        {label}
      </Label>
      {disabled ? (
        <Label>{values.phone}</Label>
      ) : (
        <>
          <Flex alignItems="center">
            <CustomInput
              isPhoneNumber
              icon={<MdPhone />}
              className="form-control"
              type="text"
              placeholder={placeholder}
              name="phone"
              id="phone"
              value={values.phone || ''}
              onChange={(name, value) => {
                onChange(name, value);
              }}
              error={touched?.phone && errors.phone}
              onInput={() => setFieldTouched('phone', true, true)}
              onBlur={handleBlurPhone}
            />
            {showPhoneButton && (
              <IconButton
                color="primary"
                variant="out"
                rounded
                onClick={addContact}
                disabled={errors && !!errors.phone && touched && touched.phone}
              >
                <AddIcon />
              </IconButton>
            )}
          </Flex>
        </>
      )}
    </>
  );
};

PersonPhoneInput.propTypes = {
  label: propTypes.string.isRequired,
  placeholder: propTypes.string.isRequired,
  onRedirectClick: propTypes.func.isRequired,
  disabled: propTypes.bool,
  setFieldTouched: propTypes.func,
  checkPerson: propTypes.bool,
  onChange: propTypes.func.isRequired,
};

PersonPhoneInput.defaultProps = {
  disabled: false,
  setFieldTouched: () => null,
  checkPerson: true,
};

export default PersonPhoneInput;
