import propTypes from 'prop-types';
import { Tooltip, KiperButton } from '@kiper/ui';
import { useSwal } from '@kiper/hooks';
import { invite as inviteGql } from '@kiper/monitoring-graphql';
import { MdOutlineLink } from 'react-icons/md';
import { useLazyQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useFormatApolloErrorHandler } from '../../hooks';

export default function CopyInviteGuestUrl({ guestId, disabled }) {
  const { toast } = useSwal();
  const { onError } = useFormatApolloErrorHandler();
  const [t] = useTranslation('invite');

  const copyTextToClipboard = url => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(url);
    }
    return new Promise((resolve, reject) => {
      const success = document.execCommand('copy', true, url);
      if (success) {
        resolve();
      } else {
        reject(new Error(t('guest-list.url-invite-copied-error')));
      }
    });
  };

  const handleCopyText = url => {
    if (!url) return null;
    return copyTextToClipboard(url)
      .then(() => {
        toast.fire({
          title: t('guest-list.url-invite-copied'),
          icon: 'success',
        });
      })
      .catch(error => {
        toast.fire({
          title: error.message,
          icon: 'success',
        });
      });
  };

  const [fetchUrlGuestInvite, { loading }] = useLazyQuery(
    inviteGql.inviteGuestUrl,
    {
      variables: { guestId },
      fetchPolicy: 'network-only',
      onError,
      onCompleted: ({ inviteGuestUrl }) => {
        if (!inviteGuestUrl) return;
        handleCopyText(inviteGuestUrl);
      },
    },
  );

  return (
    <>
      <KiperButton
        name="copy-guest-invite-url"
        id="copy-guest-invite-url"
        disabled={disabled || loading}
        size="sm"
        color="primary"
        icon={<MdOutlineLink />}
        onClick={fetchUrlGuestInvite}
        loading={loading}
      />

      {!disabled && (
        <Tooltip placement="right" target="copy-guest-invite-url">
          {t('guest-list.url-invite-copy')}
        </Tooltip>
      )}
    </>
  );
}

CopyInviteGuestUrl.propTypes = {
  guestId: propTypes.string.isRequired,
  disabled: propTypes.bool.isRequired,
};
