import { differenceInMinutes, formatDateUtc, parseZonedTime } from '@kiper/fns';
import propTypes, { shape, string, number, oneOf } from 'prop-types';
import * as UI from '@kiper/ui';
import { useQuery } from 'react-apollo';
import { queries as triageQueries } from '@kiper/monitoring-graphql/triage';
import { useEffect, useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { continuousAttendanceStatus } from '../../../../hooks/useContinuousAttendance';
import { useCurrentLoggedContext } from '../../../../hooks/index';
import * as S from './styles';
import { sendAmplitudeData } from '../../../../services/amplitude/index';

const PREFIX_TRANSLATE = 'details.continuous-attendance';

const statusAttendanceSettings = t => ({
  [continuousAttendanceStatus.ONGOING]: {
    icon: (
      <UI.Flex>
        <S.OnGoingIcon
          id={`continuous-attendance-${continuousAttendanceStatus.ONGOING}`}
        />
      </UI.Flex>
    ),
    tooltip: '',
  },
  [continuousAttendanceStatus.AUTHORIZED]: {
    icon: (
      <UI.Flex>
        <S.AuthorizedIcon
          id={`continuous-attendance-${continuousAttendanceStatus.AUTHORIZED}`}
        />
      </UI.Flex>
    ),
    tooltip: t(`${PREFIX_TRANSLATE}.attendance-authorized-tooltip`),
  },
  [continuousAttendanceStatus.NOT_AUTHORIZED]: {
    icon: (
      <UI.Flex>
        <S.NotAuthorizedIcon
          id={`continuous-attendance-${continuousAttendanceStatus.NOT_AUTHORIZED}`}
        />
      </UI.Flex>
    ),
    tooltip: t(`${PREFIX_TRANSLATE}.attendance-unauthorized-tooltip`),
  },
  [continuousAttendanceStatus.UNAVAILABLE_CONTACTS]: {
    icon: (
      <UI.Flex>
        <S.UnavailableContactsIcon
          id={`continuous-attendance-${continuousAttendanceStatus.UNAVAILABLE_CONTACTS}`}
        />
      </UI.Flex>
    ),
    tooltip: t(`${PREFIX_TRANSLATE}.attendance-unavailable-contacts-tooltip`),
  },
});

const ContinuousAttendanceItem = ({
  attendance,
  t,
  eventId,
  setAttendanceDetailData,
}) => {
  const { loggedContext } = useCurrentLoggedContext();
  const [triageName, setTriageName] = useState(null);

  const handleAttendanceDweller = ({ abbreviateIdentifier = true }) => {
    const { identifier, unity, unityGroup } = attendance;
    let result = '';
    const firstNameIdentifier = () => identifier.trim().split(' ')[0];

    if (identifier)
      result = `${
        abbreviateIdentifier ? firstNameIdentifier() : identifier.trim()
      }`;
    if (unityGroup?.name) {
      result += result ? `, ${unityGroup?.name}` : unityGroup?.name;
    }
    if (unity?.name) result += `, ${unity?.name}`;

    return result || t(`${PREFIX_TRANSLATE}.uninformed`);
  };

  const getLastMinutesAttendance = () => {
    const minutes = -differenceInMinutes(
      parseZonedTime(attendance.creationDate),
      parseZonedTime(
        formatDateUtc({
          date: new Date(),
          format: 'YYYY-MM-DD HH:mm:ss',
        }),
      ),
    );
    if (minutes === 0) {
      return t(`${PREFIX_TRANSLATE}.approximate-value`);
    }
    return t(`${PREFIX_TRANSLATE}.attendance-completed-time`, {
      time: String(minutes).padStart(2, '0'),
    });
  };

  const { data: triagesData, loading: loadingTriages } = useQuery(
    triageQueries.triages,
    {
      fetchPolicy: 'cache-first',
    },
  );

  useEffect(() => {
    if (triagesData?.triages?.length) {
      const triage = triagesData.triages.find(
        x => +x.id === attendance.triageId,
      );
      setTriageName(triage?.description);
    }
  }, [attendance, triagesData]);

  const handleShowDetail = () => {
    setAttendanceDetailData({ ...attendance, triageName });
    sendAmplitudeData('details continuous attendances', {
      attendance,
      triageName,
      user: loggedContext?.personContextId,
      partner: loggedContext?.partner?.name,
    });
  };

  return (
    <S.ListItem>
      <UI.Flex alignItems="center" justifyContent="space-between" width="100%">
        <UI.Flex gridGap="8px" alignItems="center" justifyContent="flex-start">
          {statusAttendanceSettings(t)[attendance.status].icon}
          {!!statusAttendanceSettings(t)[attendance.status].tooltip && (
            <UI.Tooltip
              placement="top"
              target={`continuous-attendance-${attendance.status}`}
            >
              <UI.Text fontWeight="400" fontSize="14px" lineHeight="20px">
                {statusAttendanceSettings(t)[attendance.status].tooltip}
              </UI.Text>
            </UI.Tooltip>
          )}
          <UI.Flex
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            gridGap="4px"
          >
            {attendance?.eventId && (
              <>
                <UI.Tooltip
                  placement="top"
                  target={`continuous-attendance-${attendance.eventId}`}
                >
                  <UI.Text fontWeight="400" fontSize="14px" lineHeight="20px">
                    {handleAttendanceDweller({ abbreviateIdentifier: false })}
                  </UI.Text>
                </UI.Tooltip>
                <S.AttendanceSubject
                  id={`continuous-attendance-${attendance.eventId}`}
                >
                  {handleAttendanceDweller({ abbreviateIdentifier: true })}
                </S.AttendanceSubject>
              </>
            )}
          </UI.Flex>
        </UI.Flex>
        {attendance.attendant.id === loggedContext.personId &&
        attendance?.eventId === eventId ? (
          <S.Chip>{t(`${PREFIX_TRANSLATE}.current-attendant-chip`)}</S.Chip>
        ) : (
          <UI.Flex
            flexDirection="column"
            gridGap="4px"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <S.SecondaryText>{getLastMinutesAttendance()}</S.SecondaryText>
          </UI.Flex>
        )}
      </UI.Flex>
      <UI.Flex justifyContent="space-between" width="100%">
        {loadingTriages && (
          <UI.Flex ml="32px" mt="8px">
            <UI.Spinner size={14} />
          </UI.Flex>
        )}
        <UI.Flex
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <S.SecondaryText ml="32px">{triageName}</S.SecondaryText>
          {triageName && (
            <UI.Button
              color="black"
              variant="text"
              onClick={handleShowDetail}
              size="sm"
              rounded
            >
              <MdKeyboardArrowRight size={20} />
            </UI.Button>
          )}
        </UI.Flex>
      </UI.Flex>
    </S.ListItem>
  );
};

export default ContinuousAttendanceItem;

ContinuousAttendanceItem.propTypes = {
  t: propTypes.func.isRequired,
  attendance: shape({
    creationDate: string.isRequired,
    eventId: string.isRequired,
    dweller: string,
    identifier: string,
    unity: shape({
      name: string,
    }),
    unityGroup: shape({
      name: string,
    }),
    status: oneOf([...Object.values(continuousAttendanceStatus)]).isRequired,
    attendant: shape({
      id: number.isRequired,
    }).isRequired,
  }).isRequired,
  eventId: propTypes.string.isRequired,
  setAttendanceDetailData: propTypes.func.isRequired,
};
