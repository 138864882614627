import propTypes from 'prop-types';
import * as UI from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-apollo';
import { condominiumEventTypeHowToAct } from '@kiper/monitoring-graphql/condominium/event/query';
import { useMemo } from 'react';
import { EmptyStateIcon } from '../../EmptyStateIcon';
import * as S from './styles';

import { ListIcon, Label } from './styles';

const HowAct = ({ condominiumPersonContextId, eventType }) => {
  const [t] = useTranslation('event_attendance');

  const { data, loading } = useQuery(condominiumEventTypeHowToAct, {
    fetchPolicy: 'no-cache',
    variables: {
      condominiumContextId: condominiumPersonContextId,
      eventType,
    },
  });

  const howToAct = useMemo(() => data?.condominiumEventTypeHowToAct?.howToAct, [
    data,
  ]);

  return (
    <S.Wrapper>
      <UI.SplitSidebar.HeaderPane hasBorder>
        {t('details.how-treat.how-to-act')}
      </UI.SplitSidebar.HeaderPane>
      {loading ? (
        <UI.Flex
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <UI.Spinner size={28} />
        </UI.Flex>
      ) : (
        <S.Content>
          {howToAct ? (
            <Label>{howToAct}</Label>
          ) : (
            <EmptyStateIcon
              mt={56}
              icon={ListIcon}
              title={t('details.how-treat.empty-state.how-to-act.title')}
              text={t('details.how-treat.empty-state.how-to-act.text')}
            />
          )}
        </S.Content>
      )}
    </S.Wrapper>
  );
};

export default HowAct;

HowAct.propTypes = {
  condominiumPersonContextId: propTypes.number.isRequired,
  eventType: propTypes.number.isRequired,
};
