import { Alert, EmptyState, KiperButton } from '@kiper/ui';
import propTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FiWifiOff } from 'react-icons/fi';
import { renderRoutes } from 'react-router-config';
import { useRouteMatch } from 'react-router-dom';
import { useToggle } from '@kiper/hooks';
import Popup from '../../components/Popup';
import useAttendanceSocketConnection from '../../hooks/useAttendanceSocketConnection';
import attendancePlaceHolder from '../../assets/images/empty-state-attendance.svg';
import {
  CondominiumRuleFilterProvider,
  EventAttendanceContext,
  EventAttendanceProvider,
  GuidedAttendanceProvider,
  TriageProvider,
} from '../../store';
import EventList from './EventList';

import { useCurrentLoggedContext } from '../../hooks';
import { NewAttendanceModal } from '../../components/Modals';
import { useCreateNewAttendance } from './EventList/hooks';
import * as S from './styles';

const GuidedAttendanceWrapper = props => {
  return (
    <EventAttendanceProvider>
      <GuidedAttendanceProvider>
        <TriageProvider>
          <CondominiumRuleFilterProvider>
            <GuidedAttendance {...props} />
          </CondominiumRuleFilterProvider>
        </TriageProvider>
      </GuidedAttendanceProvider>
    </EventAttendanceProvider>
  );
};

const GuidedAttendance = ({ route }) => {
  const {
    popupData,
    handleClearPopupData,
    play,
    handleFilterByCondominiumContextId,
    eventInAttendance,
  } = useContext(EventAttendanceContext);
  const [openNewEventModal, toggleNewEventModal] = useToggle(false);
  const { loggedContext } = useCurrentLoggedContext();

  const { socketError } = useAttendanceSocketConnection();
  const { t } = useTranslation('event_attendance');

  const handlePopupAction = () => {
    play.getEvent(popupData.eventId);
  };

  const currentEventId = useRouteMatch({
    path: '/guided-attendance/:id?',
  })?.params?.id;

  useEffect(() => {
    if (eventInAttendance) {
      handleFilterByCondominiumContextId(
        eventInAttendance?.condominium?.personContextId,
      );
    }
  }, [eventInAttendance]);

  const { handleActionToCreateNewAttendance, loading } = useCreateNewAttendance(
    {
      loggedContext,
    },
  );

  return (
    <S.Container>
      {socketError && (
        <Alert
          background="dangerSecondary"
          icon={FiWifiOff}
          secondaryText={
            socketError?.message === 'reconnecting...'
              ? t('reconnecting')
              : undefined
          }
          text={t('server-offline')}
          fixed
        />
      )}
      <EventList
        hide={!!currentEventId}
        toggleNewEventModal={toggleNewEventModal}
      />

      {currentEventId ? (
        renderRoutes(route.routes)
      ) : (
        <S.EmptyContainer>
          <EmptyState
            placeholder={attendancePlaceHolder}
            text={t('list.empty-state.title')}
            message={t('list.empty-state.message')}
          >
            <KiperButton
              onClick={() =>
                handleActionToCreateNewAttendance(toggleNewEventModal)
              }
              disabled={loading}
            >
              {t('newAttendance')}
            </KiperButton>
          </EmptyState>
        </S.EmptyContainer>
      )}

      {popupData.visible && popupData.condoName && (
        <>
          {popupData.inAttendance ? (
            <Popup
              title={t('answered-call')}
              subtitle={popupData.condoName}
              onAction={handlePopupAction}
              buttonText={t('go-to-event')}
              options={{
                visible: popupData.visible,
                hide: () => handleClearPopupData(),
              }}
            />
          ) : (
            <Popup
              title={t('answered-call')}
              subtitle={popupData.condoName}
              onAction={handlePopupAction}
              options={{
                visible: popupData.visible,
                delay: 5000,
                hide: () => handleClearPopupData(),
              }}
            />
          )}
        </>
      )}
      {openNewEventModal && (
        <NewAttendanceModal
          open={openNewEventModal}
          handleToggle={toggleNewEventModal}
        />
      )}
    </S.Container>
  );
};

export default GuidedAttendanceWrapper;

GuidedAttendance.propTypes = {
  route: propTypes.object.isRequired,
};
