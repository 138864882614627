import styled, { css } from 'styled-components';
import {
  Input as input,
  Form as form,
  FormGroup as formGroup,
  Col as col,
  Row as row,
} from 'reactstrap';

import { MdPeopleOutline, MdMailOutline } from 'react-icons/md';

import {
  Label as label,
  Tooltip,
  Input as CustomInput,
  Badge,
  TabContent as tab,
  Table as table,
} from '@kiper/ui';

export const StyledInput = styled(CustomInput)`
  border-radius: 8px;
  border-color: ${props => props.theme.colors.secondary300} !important;
`;

export const StyledBadge = styled(Badge)`
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.dangerSecondary};
  align-items: center;
  border-radius: 20px;
  width: 18px;
  height: 18px;
  font-weight: 700;
  font-size: 10px;
  right: 0;
  top: 0;
  justify-content: center;
`;

export const UsersIcon = styled(MdPeopleOutline)`
  ${props =>
    props.active ? props.theme.colors.primary500 : props.theme.colors.lowBlack}
`;

export const InvitesIcon = styled(MdMailOutline)`
  ${props =>
    props.active ? props.theme.colors.primary500 : props.theme.colors.lowBlack}
`;

export const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    max-width: 300px;
  }
`;

export const Container = styled.div``;

export const Input = styled(input)`
  border-color: ${props => props.theme.colors.secondary400};
  width: 100%;
  ::placeholder {
    color: ${props => props.theme.colors.mediumBlack};
  }
`;

export const Form = styled(form)``;

export const FormGroup = styled(formGroup)``;

export const Col = styled(col)`
  ${props =>
    props.border &&
    css`
      border-right: 1px solid ${props.theme.colors.secondary300};
      padding-left: 5px;
    `}
`;

export const SearchCol = styled(col)`
  padding: 0;
  margin-bottom: 15px;
`;

export const TableContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  width: 100%;

  th {
    position: sticky;
    top: 0;
    background: ${props => props.theme.colors.secondary200};
    border-bottom: 2px solid ${props => props.theme.colors.secondary600};
    z-index: 1;
  }
`;

export const Label = styled(label)`
  margin: 0;
`;

export const NameLabel = styled(Label)`
  margin: 0 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
`;

export const SmallLabel = styled(Label)`
  font-size: 12px;
`;

export const UserDetailContainer = styled.td`
  background: ${props => props.theme.colors.secondary50};
  padding: 0;
`;

export const Row = styled(row)`
  margin: 0;
`;

export const Tr = styled.tr`
  border-left: none;
  border-right: none;
`;

export const Table = styled(table)`
  border-bottom: none;
`;

export const CondoInfo = styled.div`
  margin-top: 15px;
`;

export const Actions = styled(CondoInfo)`
  display: flex;
  justify-content: center;
`;

export const DivGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const NameContainer = styled.div`
  max-width: 500px;
  display: flex;
`;

export const PhoneContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ObservationText = styled(label)`
  display: block;
  white-space: break-spaces;
  word-break: break-word;
  line-break: auto;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400px;
  letter-spacing: 0.5px;
  line-height: 1.5;
  font-weight: 500;
`;

export const TabContent = styled(tab)`
  position: relative;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: auto;
  width: 100%;
  border-radius: 8px;
  color: ${props => props.theme.colors.mediumHighBlack};
  padding: 8px;
  background: ${props => props.theme.colors.infoPrimaryShades`0.1`};
  border: 1px solid ${props => props.theme.colors.infoPrimaryShades`0.16`};
  border-radius: 8px;
  gap: 12px;
`;

export const AllowedAccessUserMessage = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  width: 100%;
`;
