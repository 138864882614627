import {
  Card as card,
  CardHeader as cardHeader,
  CardBody as cardBody,
  Col as col,
} from 'reactstrap';
import { H5 as h5, Label } from '@kiper/ui';
import styled, { css } from 'styled-components';
import { MdEdit } from 'react-icons/md';
import { FiTrash } from 'react-icons/fi';

export const Card = styled(card)`
  margin: 10px 0;
  width: 60%;

  ${props =>
    props.border
      ? css`
          border-color: ${props.border};
        `
      : null}
`;

export const CardHeader = styled(cardHeader)`
  background: transparent;
  padding: 15px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardBody = styled(cardBody)`
  padding: 20px 10px;
`;

export const H5 = styled(h5)`
  margin-bottom: 0;
`;

export const Col = styled(col)`
  margin: 16px 0;
`;

export const AddMoreContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  background-color: inherit;
  border: ${props =>
    `1px ${props.theme.colors.secondary400} ${
      props.device ? 'solid' : 'dashed'
    }`};

  img {
    margin-bottom: 10px;
  }
  height: 222px;
  padding: 5px 20px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${props => props.theme.colors.secondary300};
  border-radius: 4px;
`;

export const ItemActionsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: flex-end;
`;

export const EditIcon = styled(MdEdit)`
  margin: 0 10px;
  cursor: pointer;
`;

export const RemoveIcon = styled(FiTrash)`
  cursor: pointer;
`;

export const WrapContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ServerName = styled(Label)`
  font-size: 14px;
  font-weight: 700;
  color: ${props => props.theme.colors.mediumBlack};
`;

export const ServerAddress = styled(Label)`
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.colors.mediumBlack};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
