import { useMemo } from 'react';
import { ErrorMessage, AccessRestrictions } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import AddMore from '../../../AddMore';
import { useAddItem, useCheckDaysOfWeek, useDeleteItem } from './hooks';
import { gmt } from './helpers';
import { FormGroup, AddMoreContainer } from '../../../styles';
import * as S from './styles';

export default function ContactSchedule({
  values,
  handleChange,
  isEditable,
  errors,
}) {
  const [t] = useTranslation('user');

  const handleAddMoreItem = useAddItem({
    values,
    handleChange,
  });
  const handleDeleteItem = useDeleteItem({ values, handleChange });
  const { checkDaysOfWeekIsEmpty } = useCheckDaysOfWeek({ errors });

  const hasErrorContactPeriod = useMemo(
    () => typeof errors?.contactSchedule === 'string',
    [errors],
  );

  if (!isEditable && !values.length) return null;

  return (
    <>
      <S.LabelTitle>
        {`${t('put.access-data.contact-schedule')} (GMT${gmt(values)})`}
      </S.LabelTitle>
      <S.LabelDescription>
        {`${t('put.access-data.contact-schedule-description')}`}
      </S.LabelDescription>
      <FormGroup>
        <S.Wrapper>
          {values?.map((permission, index) => (
            <S.Item key={crypto.randomUUID()}>
              <AccessRestrictions
                fullwidth
                isEditable={isEditable}
                t={t}
                index={index}
                accessRestriction={permission}
                handleRemove={handleDeleteItem}
                handleChange={permissionTimeData => {
                  handleChange('contactSchedule', [...permissionTimeData]);
                }}
                accessRestrictions={values}
                invalid={checkDaysOfWeekIsEmpty(index)}
              />
              {checkDaysOfWeekIsEmpty(index) && (
                <ErrorMessage>
                  {t('put.access-data.time-permissions.feedback-days-of-week')}
                </ErrorMessage>
              )}
            </S.Item>
          ))}
        </S.Wrapper>

        {isEditable && !(values?.length >= 3) && (
          <AddMoreContainer md={12}>
            <AddMore
              text={t(
                `put.access-data.time-permissions.add-${
                  values && !!values.length ? 'another-' : ''
                }contact-period`,
              )}
              onClick={handleAddMoreItem}
            />
          </AddMoreContainer>
        )}
        {hasErrorContactPeriod && (
          <ErrorMessage>
            {t('put.access-data.time-permissions.error-message')}
          </ErrorMessage>
        )}
      </FormGroup>
    </>
  );
}

ContactSchedule.propTypes = {
  values: propTypes.array,
  handleChange: propTypes.func.isRequired,
  isEditable: propTypes.bool,
  errors: propTypes.object,
};

ContactSchedule.defaultProps = {
  values: [],
  isEditable: false,
  errors: {},
};
