import { useContext } from 'react';
import {
  Spinner,
  Popover,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  KiperButton,
  Flex,
} from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { CloseIcon, Card, Backdrop, WhiteLabelRow } from './styles';
import emergencyCommandContext from '../../emergencyCommandContext';

const EventAttendanceDetail = ({ isOpen, onClose }) => {
  const [t] = useTranslation('event_attendance');
  const emergency = useContext(emergencyCommandContext);

  return (
    <>
      {isOpen && <Backdrop />}
      <Popover
        data-cy="emergency-popper"
        id="emergency-popper"
        isOpen={isOpen}
        toggle={onClose}
        target="emergency-popover"
        variant="danger"
      >
        <PopoverHeader variant="danger">
          {t('emergency.title')}
          <CloseIcon
            data-cy="closeEmergency"
            onClick={onClose}
            variant="danger"
          />
        </PopoverHeader>
        <PopoverBody variant="danger">{t('emergency.message')}</PopoverBody>
        <PopoverFooter>
          <Flex flex={1} justifyContent="space-between">
            <KiperButton
              color="emergency"
              id="active-emergency"
              data-cy="active-emergency"
              // loading={emergency.isLoading}
              onClick={() => emergency.activate().then(() => onClose())}
            >
              {t(`details.header.emergency.activate`)}
            </KiperButton>
            <KiperButton
              color="emergency"
              id="desactive-emergency"
              data-cy="desactive-emergency"
              // loading={emergency.isLoading}
              onClick={() => emergency.deactivate().then(() => onClose())}
            >
              {t(`details.header.emergency.deactivate`)}
            </KiperButton>
          </Flex>
        </PopoverFooter>
      </Popover>
    </>
  );
};

export default EventAttendanceDetail;

EventAttendanceDetail.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

const LoadingLabel = ({ label }) => (
  <WhiteLabelRow>
    <Spinner size={18} />
    <span>{label}</span>
  </WhiteLabelRow>
);

LoadingLabel.propTypes = {
  label: propTypes.string.isRequired,
};

const IconCard = ({ icon, children }) => (
  <Card>
    <Row>
      <Col md={1}>{icon}</Col>
      <Col>{children}</Col>
    </Row>
  </Card>
);

IconCard.propTypes = {
  icon: propTypes.element.isRequired,
  children: propTypes.element.isRequired,
};
