import propTypes from 'prop-types';
import { useContext } from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { Option, OptionWrapper } from './styles';

const HorizontalFilterOption = ({ itemId, description, selected, onClick }) => {
  const visibility = useContext(VisibilityContext);
  return (
    <OptionWrapper>
      <Option
        onClick={() => onClick(visibility)}
        itemId={itemId}
        tabIndex={0}
        selected={selected}
      >
        {description}
      </Option>
    </OptionWrapper>
  );
};

export default HorizontalFilterOption;

HorizontalFilterOption.propTypes = {
  description: propTypes.string.isRequired,
  onClick: propTypes.func.isRequired,
  itemId: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired,
  selected: propTypes.bool.isRequired,
};
