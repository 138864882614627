import { useMemo } from 'react';
import { formatOnlyDateUtc, formatHoursAndMinutesUtc } from '@kiper/fns';
import { useTranslation } from 'react-i18next';
import { lockerDeliveryStatus } from '../../../../constants';

const useFormatLockerDeliveryHistoryData = historyDeliveryData => {
  const [t] = useTranslation('modals/locker-delivery-history-modal');

  const { collection, pagination } = historyDeliveryData || {};

  const formatHistoryData = infoData => {
    const depositDate =
      infoData.depositDate && formatOnlyDateUtc(infoData.depositDate);
    const depositTime =
      infoData.depositDate && formatHoursAndMinutesUtc(infoData.depositDate);
    const status =
      infoData.status &&
      t(`table.delivery-status.${lockerDeliveryStatus[infoData.status]}`);
    const withdrawalDate =
      infoData.withdrawalDate && formatOnlyDateUtc(infoData.withdrawalDate);
    const withdrawalTime =
      infoData.withdrawalDate &&
      formatHoursAndMinutesUtc(infoData.withdrawalDate);

    return {
      ...infoData,
      status,
      depositDate,
      depositTime,
      withdrawalDate,
      withdrawalTime,
    };
  };

  const formattedHistoryData = useMemo(
    () => (collection?.length ? collection.map(formatHistoryData) : []),
    [collection],
  );

  return [formattedHistoryData, pagination];
};

export default useFormatLockerDeliveryHistoryData;
