const unexpectedNetworkError = 'Unexpected network error';

export default function({ graphQLErrors, networkError }) {
  if (networkError) return `[Network error]: ${networkError}`;

  if (graphQLErrors && graphQLErrors.length) {
    return graphQLErrors.reduce((errors, current) => {
      if (current && current.extensions && current.extensions.response) {
        if (current.extensions.response.specificities) {
          return current.extensions.response;
        }
        if (current.extensions.response.message) {
          errors.push(current.extensions.response.message);
        } else {
          errors.push(current.message);
        }

        return errors;
      }
      return errors;
    }, []);
  }

  return unexpectedNetworkError;
}

const extractCurrentResponse = response =>
  Array.isArray(response) ? response[0] : response;

export const apolloDataErrorHandlerV2 = ({ graphQLErrors, networkError }) => {
  if (networkError) return `[Network error]: ${networkError}`;

  if (!graphQLErrors || !graphQLErrors.length) return unexpectedNetworkError;

  const gqlErrors = graphQLErrors.reduce((errors, current) => {
    if (!current || !current.extensions || !current.extensions.response)
      return errors;

    const response = extractCurrentResponse(current.extensions.response);

    if (!response) return errors;

    return {
      message: response.message || current.message || '',
      details: response.details || current.details || '',
      specificities: response.specificities || current.specificities || '',
    };
  }, []);

  if (!gqlErrors || (Array.isArray(gqlErrors) && !gqlErrors.length))
    return unexpectedNetworkError;

  return gqlErrors;
};
