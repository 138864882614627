import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@kiper/ui';
import { useInfiniteScroll } from './hooks';

const renderListItemDefault = props => {
  return (
    <li ref={props?.ref} {...props}>
      {props?.index}
    </li>
  );
};

const InfiniteScrollList = ({
  variables,
  query,
  renderListItem,
  onCompletedCallback,
  onErrorCallback,
}) => {
  const { t } = useTranslation();

  const { state, loader, hasMore, lastBookElementRef } = useInfiniteScroll(
    query,
    variables,
    onCompletedCallback,
    onErrorCallback,
  );

  return (
    <>
      {state.map((item, index) => {
        if (state.length === index + 1) {
          return renderListItem({
            ref: lastBookElementRef,
            item,
            index,
            key: index,
          });
        }
        return renderListItem({
          item,
          index,
          key: index,
        });
      })}
      {loader && (
        <li colSpan={12}>
          <Flex justifyContent="center">
            <Text color="secondary400" fontSize="12px">
              {t('common:loading')}
            </Text>
          </Flex>
        </li>
      )}

      {!state?.length && !loader && !hasMore && (
        <li colSpan={12}>
          <Flex justifyContent="center">
            <Text color="secondary400" fontSize="12px">
              {t('common:no-items-found')}
            </Text>
          </Flex>
        </li>
      )}
    </>
  );
};

export default InfiniteScrollList;

InfiniteScrollList.propTypes = {
  renderListItem: propTypes.func,
  variables: propTypes.object,
  query: propTypes.object,
  onCompletedCallback: propTypes.func,
  onErrorCallback: propTypes.func,
};

InfiniteScrollList.defaultProps = {
  renderListItem: renderListItemDefault,
  variables: null,
  query: null,
  onCompletedCallback: null,
  onErrorCallback: null,
};
