import { useContext, createContext, useState, useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import socketIOClient from 'socket.io-client';
import { runtimeConfig } from '@kiper/fns';
import { get, types, setMap } from '@kiper/cookie';
import { updateTokens } from '../auth/tokens';
import AuthContext from '../auth/context';

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const { currentLoggedContext } = useContext(AuthContext);
  const url = runtimeConfig.RAZZLE_WS_URL;

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const client = useRef(null);
  const createClient = () => {
    if (client.current) client.current.close();
    return socketIOClient(url, {
      transports: ['websocket'],
      upgrade: false,
      reconnectionDelayMax: 1200,
      query: {
        version: '2.0',
      },
    });
  };

  useEffect(() => {
    if (currentLoggedContext) {
      const hasContextCookie = get(types.context);
      if (!hasContextCookie) {
        setMap({
          [types.context]: currentLoggedContext.personContextId,
          [types.partnerContext]:
            currentLoggedContext?.partner?.personContextId,
        });
      }

      client.current = createClient();

      client.current.on('connect', () => {
        setError(null);
        setLoading(false);
      });

      client.current.on('connect_error', () => {
        setError({ message: 'reconnecting...' });
        setLoading(true);
      });

      // param: reason
      client.current.on('disconnect', () => {
        setError({ message: 'disconnected' });
        setLoading(true);
      });

      client.current.on('error', currentError => {
        setError(currentError);
        setLoading(false);
      });

      client.current.on('reconnect', () => {
        setError(null);
        setLoading(false);
      });
    }
  }, [currentLoggedContext]);

  useEffect(() => {
    if (error?.message === 'Request failed with status code 401') {
      updateTokens();
    }
  }, [error]);

  const addListener = (event, fn) => {
    client.current.on(event, fn);
  };

  const removeListener = (event, fn) => {
    client.current.removeListener(event, fn);
  };

  const subscribe = (topic, fn) => {
    client.current.on(topic, fn);
  };

  const unsubscribe = topic => {
    client.current.off(topic);
  };

  const sendMessage = (channel, message) => {
    client.current.emit(channel, message);
  };

  return (
    <SocketContext.Provider
      value={{
        loading,
        error,
        subscribe,
        unsubscribe,
        sendMessage,
        addListener,
        removeListener,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

SocketProvider.propTypes = {
  children: propTypes.oneOfType([propTypes.node, propTypes.element]),
};

SocketProvider.defaultProps = {
  children: undefined,
};
