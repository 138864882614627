import { useMemo } from 'react';
import propTypes from 'prop-types';
import { Badge, KiperButton, MdIcons } from '@kiper/ui';
import { useMutation } from 'react-apollo';
import { MdBlock } from 'react-icons/md';
import { useSwal } from '@kiper/hooks';
import { dweller as dwellerGql } from '@kiper/monitoring-graphql';
import { useTranslation } from 'react-i18next';
import { apolloErrorHandler, getUserLang } from '@kiper/fns';
import theme from '@kiper/theme';
import * as S from './styles';
import { profileTypes, profileColor } from '../../../../constants';
import UserInfo from '../../../../components/UserInfo';

const BlockedListItem = ({ data, onSync }) => {
  const [t] = useTranslation('user');
  const { confirm, toast } = useSwal();

  const [unblockAccess, { loading }] = useMutation(
    dwellerGql.detail.mutations.dwellerUnblockAppAccess,
    {
      onCompleted: () => onSync(),
      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length)
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      },
    },
  );

  const onUnblockUser = async () => {
    const unblock = await confirm({
      title: t('blocked.unblock-modal.title'),
      text: t('blocked.unblock-modal.text'),
      cancelButtonText: t('blocked.unblock-modal.cancel-button'),
      confirmButtonText: t('blocked.unblock-modal.confirm-button'),
    });

    if (unblock) {
      unblockAccess({
        variables: { personContextId: Number(data?.personContextId) },
      });
    }
  };

  const blockedDate = useMemo(() => {
    if (!data?.blockedon) return '-';

    const date = new Date(data.blockedon);
    return `${date.toLocaleDateString(getUserLang(), {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    })} - ${date.toLocaleTimeString(getUserLang(), {
      hour: 'numeric',
      minute: 'numeric',
    })}`;
  }, [data?.blockedon]);

  return (
    <S.Line>
      <td align="left">{data?.condominiumName}</td>
      <td align="left">
        <S.Label block>{data?.unityGroupName}</S.Label>
        <S.Label block>{data?.unityName}</S.Label>
      </td>
      <td align="left">
        <UserInfo user={data} />
      </td>
      <td align="left">
        {data?.profileFieldName && (
          <Badge color={profileColor[data?.profileFieldName]}>
            {t(`common:profiles.${profileTypes[data?.profileFieldName]}`)}
          </Badge>
        )}
      </td>
      <td align="center">
        <S.DivBlockedName>
          <MdBlock size={24} color={theme.colorDangerButton} />
          <S.Label block>
            {`${data?.blockername} - `}
            <S.Label block>{data?.blockerprofile}</S.Label>
          </S.Label>
        </S.DivBlockedName>
      </td>
      <td align="center">
        <S.Label block>{blockedDate}</S.Label>
      </td>
      <td align="center">
        <KiperButton
          icon={<MdIcons mdIconName="lock_open" color="primary" />}
          color="primary"
          onClick={onUnblockUser}
          disabled={loading}
        />
      </td>
    </S.Line>
  );
};

export default BlockedListItem;

BlockedListItem.propTypes = {
  data: propTypes.object.isRequired,
  onSync: propTypes.func.isRequired,
};
