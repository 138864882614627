import { useMemo, useState } from 'react';
import propTypes from 'prop-types';

import moment from 'moment';

import { useTranslation } from 'react-i18next';

import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { MdChevronRight } from 'react-icons/md';

import { Badge, Flex, KiperButton, Text, Tooltip } from '@kiper/ui';
import { useToggleData, useToggle, useDebounce } from '@kiper/hooks';
import { formatDate, phoneMask } from '@kiper/fns';

import {
  profileTypes,
  profileColor,
  destinationTypes,
} from '../../../../constants';
import NoKiperVoiceAgentModal from '../../../Modals/NoKiperVoiceAgentModal';
import NoKiperVoiceModal from '../../../Modals/NoKiperVoiceModal';
import useCurrentLoggedContext from '../../../../hooks/useCurrentLoggedContext';

import UsersHostDetail from '../UsersHosts/UsersHostsDetail';

import { useVoice } from '../../../../hooks';
import { sendAmplitudeData } from '../../../../services/amplitude';

import { Tr } from '../styles';
import { Actions } from './styles';

function UsersGuestsRow({ trRef, item, event, formik, onToggle, ...props }) {
  const [openedRows, toggleOpenedRow] = useToggleData({});
  const { t } = useTranslation('event_attendance');
  const [
    openNoKiperVoiceAgentModal,
    toggleOpenNoKiperVoiceAgentModal,
  ] = useToggle(false);
  const [openNoKiperVoiceModal, toggleOpenNoKiperVoiceModal] = useToggle(false);
  const [loading, setLoading] = useState(false);

  const { loggedContext } = useCurrentLoggedContext();
  const debounce = useDebounce({ delay: 2000 });

  const handleDebounce = option => {
    setLoading(option);
    debounce(() => {
      setLoading(false);
    });
  };

  const personDocuments = item?.personDocuments?.map(document =>
    JSON.parse(document),
  );

  const guestDocuments = item?.guestParams?.map(document =>
    JSON.parse(document),
  );

  const { extension, prefixPabx, codeAta } = useMemo(
    () => ({
      extension: item?.personContextParamsPrefixPabx,
      prefixPabx: item.unityGroupSerializedParams
        ? JSON.parse(item?.unityGroupSerializedParams)?.prefixPABX
        : null,
      codeAta:
        item.unityGroupSerializedParams &&
        JSON.parse(item?.unityGroupSerializedParams)?.codeAta,
    }),
    [item],
  );

  const intercomNumber = useMemo(
    () => extension && `${prefixPabx ?? ''}${extension}`,
    [extension, prefixPabx],
  );

  const additionalContacts = item?.additionalContacts?.map(contact =>
    JSON.parse(contact),
  );

  const { clickToCall } = useVoice();

  const hasKiperVoiceAgent = !!loggedContext?.personContextParams?.find(
    i => i.name === 'kiperVoiceAgent' && i.id,
  );

  const hasKiperVoice = loggedContext?.partner?.hasKiperVoice;

  // eslint-disable-next-line
  const handleClickToCall = callObject => {
    if (!hasKiperVoice) {
      return toggleOpenNoKiperVoiceModal();
    }

    if (hasKiperVoiceAgent) {
      setLoading(true);

      const voiceCallInput = {
        ...callObject,
        destination: String(callObject.destination),
        [!!item?.personContextId && 'personContextId']: item.personContextId,
      };

      if (
        voiceCallInput.destinationKind === destinationTypes?.intercomAta &&
        codeAta
      ) {
        voiceCallInput.codAta = codeAta;
      }

      const feedbackText =
        callObject.destinationKind === destinationTypes.intercomAta
          ? t('making-call-to-extension', {
              number: callObject.destination,
            })
          : t('making-call-to', { number: callObject.destination });

      clickToCall({
        voiceCallInput,
        eventId: event?.eventId,
        feedbackText,
        partner: event?.partner?.name,
        condominium: event?.condominium?.name,
        eventCode: event?.eventType,
      });

      formik.setFieldValue(
        'report',
        formik?.values?.report?.concat(
          `${t('report-call-text', {
            date: moment().format('DD/MM/YYYY hh:mm:ss'),
            operator: loggedContext?.name,
            destination: item?.personName,
            phone: callObject?.destination,
          })}\n`,
        ),
      );
    } else {
      toggleOpenNoKiperVoiceAgentModal();
    }
  };

  const guestStatus = {
    fifteenMinutes: t('user:list.guest-status.fifteenMinutes'),
    thirtyMinutes: t('user:list.guest-status.thirtyMinutes'),
    hour: t('user:list.guest-status.hour'),
    active: t('user:list.guest-status.authorized'),
  };

  const [hovered, setHover] = useState(false);

  const authorizedOrGuest = item?.guestPhones?.[0]
    ? t(`common:profiles.${profileTypes.guest}`)
    : t(`common:profiles.${profileTypes.authorized}`);

  return (
    <>
      <Tr
        ref={trRef}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className="voice-list-user"
      >
        <td align="left">
          <Flex flexDirection="column">
            <Text mb="5px" fontSize="14px">
              {item?.unityName || ''}
            </Text>
            <Text fontSize="12px" color="secondary400">
              {item?.unityGroupName || ''}
            </Text>
          </Flex>
        </td>
        <td align="left">
          <Flex flexDirection="column">
            <Text mb="5px" fontSize="14px">
              {item?.hostName}
            </Text>
            <Flex>
              <Flex mr="4px">
                <Badge color={profileColor.resident}>
                  {t(`common:profiles.${profileTypes.resident}`)}
                </Badge>
              </Flex>
            </Flex>
          </Flex>
        </td>
        <td align="left">
          <Flex flexDirection="column">
            <Text mb="5px" fontSize="14px">
              {item?.guestName}
            </Text>
            <Flex>
              <Flex mr="4px">
                <Badge color={profileColor.guest}>{authorizedOrGuest}</Badge>
              </Flex>
            </Flex>
          </Flex>
        </td>
        <td>
          <Flex flexDirection="column">
            <Text mb="5px" fontSize="14px">
              {phoneMask(item?.guestPhones?.[0]).formatted}
            </Text>
            <Text fontSize="12px" color="secondary500">
              {guestDocuments?.length
                ? `${guestDocuments?.[0]?.documentType}: ${guestDocuments?.[0]?.document}`
                : '-'}
            </Text>
          </Flex>
        </td>

        <td>
          <Flex flexDirection="column">
            <Text mb="5px" fontSize="14px">
              {formatDate(item?.startDate, 'DD/MM - HH:mm').formatted}
            </Text>
            <Flex>
              <Flex mr="4px">
                <Badge
                  color={
                    item?.inviteStatus === 'active'
                      ? 'primary500'
                      : 'warning100'
                  }
                >
                  {guestStatus[item?.inviteStatus]}
                </Badge>
              </Flex>
            </Flex>
          </Flex>
        </td>
        <td>
          <Flex alignItems="center">
            {formatDate(item?.endDate, 'DD/MM - HH:mm').formatted}
            {hovered && (
              <Actions id={`report-visitor-button-${item?.personContextId}`}>
                <KiperButton
                  rounded
                  onClick={() => {
                    formik.setFieldValue(
                      'report',
                      formik?.values?.report?.concat(
                        `${t('report-visitor', {
                          date: formatDate(moment(), 'DD/MM - HH:mm').formatted,
                          guest: item?.guestName,
                          unity: item?.unityName,
                          host: item?.hostName,
                        })}\n`,
                      ),
                    );
                    sendAmplitudeData('register invite log', {
                      user: loggedContext?.email,
                      partner: loggedContext?.partner?.name,
                      condominium: event?.condominium?.name,
                      guest: item?.guestName,
                      unit: item?.unityName,
                      host: item?.hostName,
                    });
                    onToggle();
                  }}
                  variant="out"
                  icon={<MdChevronRight />}
                />
                <Tooltip
                  placement="bottom"
                  target={`report-visitor-button-${item?.personContextId}`}
                >
                  {t('report-visitor-log')}
                </Tooltip>
                <KiperButton
                  variant="text"
                  color="neutral"
                  rounded
                  icon={
                    openedRows[item?.personContextId] ? (
                      <FiChevronUp />
                    ) : (
                      <FiChevronDown />
                    )
                  }
                  onClick={() => toggleOpenedRow(item?.personContextId)}
                />
              </Actions>
            )}
          </Flex>
        </td>
      </Tr>
      {openedRows[item.personContextId] && (
        <tr className="collapse show">
          <UsersHostDetail
            colsSpan={7}
            intercomNumber={intercomNumber}
            loading={loading}
            handleClickToCall={handleClickToCall}
            handleDebounce={handleDebounce}
            priority={item?.personContextParamsCallPriority}
            observation={item?.personContextParamsObservation}
            data={{
              personName: item?.hostName,
              personPhone: item?.hostPhone,
              personEmail: item?.hostEmail,
              personDocuments,
              profileFieldName: 'resident',
              additionalContacts,
              personContextCreationDate: item?.personContextCreationDateHost,
            }}
            {...props}
          />
        </tr>
      )}

      {openNoKiperVoiceAgentModal && (
        <NoKiperVoiceAgentModal
          open={openNoKiperVoiceAgentModal}
          onToggle={toggleOpenNoKiperVoiceAgentModal}
        />
      )}

      {openNoKiperVoiceModal && (
        <NoKiperVoiceModal
          open={openNoKiperVoiceModal}
          onToggle={toggleOpenNoKiperVoiceModal}
        />
      )}
    </>
  );
}

export default UsersGuestsRow;

UsersGuestsRow.propTypes = {
  item: propTypes.object.isRequired,
  event: propTypes.object,
  onToggle: propTypes.func,
  formik: propTypes.shape({
    values: propTypes.object,
    setFieldValue: propTypes.func,
  }),
  trRef: propTypes.any,
};

UsersGuestsRow.defaultProps = {
  event: null,
  formik: null,
  onToggle: () => {},
  trRef: null,
};
