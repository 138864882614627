import { useCallback, useState } from 'react';
import propTypes from 'prop-types';
import update from 'immutability-helper';
import { FiHome, FiPhone, FiLock, FiUnlock } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

import { KiperButton } from '@kiper/ui';

import Card from './Card';
import {
  Container,
  Flex,
  Item,
  Line,
  Contact,
  IconContainer,
  FlexRow,
} from './styles';

const DragDropContainer = ({
  cards,
  setCards,
  handleToogleContactBlock,
  isEditable,
}) => {
  const [changed, setChanged] = useState(false);
  const [mouseOver, setMouseOver] = useState();
  const [t] = useTranslation('user');

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = cards[dragIndex];
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      );

      setChanged(true);
    },
    [cards],
  );

  const renderButtonIcon = item => {
    const blocked = item.order === 3;
    const mouseOverType = mouseOver ? mouseOver[item.type] : false;
    const blockedIcon =
      (blocked && !mouseOverType) || (!blocked && mouseOverType);

    return blockedIcon ? <FiLock size={16} /> : <FiUnlock size={16} />;
  };

  const renderCard = (item, index) => (
    <Card
      key={item.type}
      index={index}
      type={item.type}
      moveCard={moveCard}
      isEditable={isEditable}
    >
      <Item blocked={item.order === 3} isEditable={isEditable}>
        <FlexRow>
          <Flex>
            <Line />
            <Line />
          </Flex>
          <Contact>
            <IconContainer>
              {item.type === 'intercom' ? (
                <FiHome size={18} />
              ) : (
                <FiPhone size={18} />
              )}
            </IconContainer>
            <Flex>
              <b>{t(`put.access-data.contact-order.item-${item.type}`)}</b>
              <span>{item.phoneValue}</span>
            </Flex>
          </Contact>
        </FlexRow>
        <Flex>
          <KiperButton
            // blocked={(item.order === 3).toString()}
            icon={renderButtonIcon(item)}
            disabled={!isEditable}
            onClick={() => handleToogleContactBlock(item)}
            onMouseEnter={() => setMouseOver({ [item.type]: true })}
            onMouseLeave={() => setMouseOver({ [item.type]: false })}
          />
        </Flex>
      </Item>
    </Card>
  );

  return (
    <Container hasChanges={changed}>
      {cards.map((card, i) => renderCard(card, i))}
    </Container>
  );
};

DragDropContainer.propTypes = {
  cards: propTypes.array.isRequired,
  setCards: propTypes.func.isRequired,
  handleToogleContactBlock: propTypes.func.isRequired,
  isEditable: propTypes.bool,
};

DragDropContainer.defaultProps = {
  isEditable: false,
};

export default DragDropContainer;
