import styled from 'styled-components';
import { Form as form } from 'reactstrap';
import { Flex } from '@kiper/ui';

export const Container = styled(Flex)`
  padding: 16px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.gray50};
  background: ${props => props.theme.colors.white};
`;

export const Form = styled(form)`
  width: 100%;
`;
