import { Flex } from '@kiper/ui';
import styled from 'styled-components';

export const Header = styled(Flex)`
  padding: 8px 16px;
  height: 76px;
  max-height: 76px;
  min-height: 76px;
  width: 100%;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.colors.secondary300};
  justify-content: space-between;
  align-items: center;
`;
