import propTypes from 'prop-types';
import { useMemo, useLayoutEffect, useRef, useState } from 'react';
import { useSwal, useWindowDimensions } from '@kiper/hooks';
import { apolloErrorHandler } from '@kiper/fns';
import { useQuery } from 'react-apollo';
import { queries as condoMapQueries } from '@kiper/monitoring-graphql/condo_map';
import { Flex, Spinner } from '@kiper/ui';
import Place from './Place';
import {
  useDeviceStatus,
  useDevices,
  useCurrentLoggedContext,
} from '../../../../../../hooks';

import * as S from './styles';
import CondoMapEmptyState from './CondoMapEmptyState';

const CondoMap = ({ isShow, event }) => {
  const { loggedContext } = useCurrentLoggedContext();
  const { width } = useWindowDimensions();
  const { toast } = useSwal();

  const [sizeMap, setSizeMap] = useState(0);
  const mapContainerRef = useRef(null);

  const { data: dataCondoMap, loading: loadingMap } = useQuery(
    condoMapQueries.condoMap,
    {
      skip: !event?.condominium?.personContextId,
      fetchPolicy: 'no-cache',
      variables: {
        condominiumPersonContextId: event?.condominium?.personContextId,
      },
      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length) {
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
        }
      },
    },
  );

  const eventMap = useMemo(
    () =>
      dataCondoMap?.condoMap
        ? { condominium: dataCondoMap?.condoMap, place: { id: event?.placeId } }
        : {},
    [dataCondoMap],
  );

  const { places, selectedPlace, setSelectedPlace } = useDevices({
    event: eventMap,
  });

  const { deviceStatus } = useDeviceStatus({ places });

  const urlMap = useMemo(
    () =>
      eventMap?.condominium?.personContext?.params?.find(
        x => x.name === 'urlMap',
      )?.value,
    [eventMap],
  );

  useLayoutEffect(() => {
    if (places?.length && mapContainerRef?.current) {
      const height = mapContainerRef.current.clientHeight;
      setSizeMap(height);
    }
  }, [isShow, width, places]);

  if (!places?.length) return null;

  if (loadingMap)
    return (
      <S.Container>
        <Flex
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner size={28} />
        </Flex>
      </S.Container>
    );

  return (
    <S.Container>
      {urlMap ? (
        <S.MapContainer ref={mapContainerRef} url={urlMap}>
          {places.map((place, i) => (
            <Place
              sizeMap={sizeMap}
              key={i}
              place={place}
              selectedPlace={selectedPlace}
              setSelectedPlace={setSelectedPlace}
              event={event}
              loggedContext={loggedContext}
              deviceStatus={deviceStatus}
            />
          ))}
        </S.MapContainer>
      ) : (
        <CondoMapEmptyState />
      )}
    </S.Container>
  );
};

export default CondoMap;

CondoMap.propTypes = {
  isShow: propTypes.bool,
  event: propTypes.object,
};

CondoMap.defaultProps = {
  isShow: false,
  event: {},
};
