import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { Input as CustomInput, KiperButton } from '@kiper/ui';
import { Link } from 'react-router-dom';
import PersonSelect from '../PersonSelect';
import { Container, Form, Input, FormGroup, Col } from './styles';
import useCurrentLoggedContext from '../../hooks/useCurrentLoggedContext';
import PageHeader from '../PageHeader';

const PageActions = ({
  filters,
  handleChange,
  route,
  entity,
  registerLink,
  onlySearch,
}) => {
  const [t] = useTranslation(entity);
  const { loggedContext } = useCurrentLoggedContext();

  const handleKeyDown = event => {
    if (event.key === 'Enter' && event.shiftKey === false)
      event.preventDefault();
  };

  return (
    <PageHeader breadcrumb={route.breadcrumb} t={t} title={t(`list.title`)}>
      <Container row>
        <Form inline onKeyDown={handleKeyDown}>
          {!onlySearch && (
            <Col>
              <FormGroup>
                <PersonSelect
                  placeholder={t(`list.condominium-select`)}
                  name="sourceNodeId"
                  sourceNodeId={filters?.sourceNodeId}
                  fieldName={['condominium']}
                  value={filters?.sourceNodeId}
                  isClearable
                  onChange={e =>
                    handleChange(
                      'sourceNodeId',
                      e ? Number(e.value) : loggedContext.topNodeId,
                    )
                  }
                />
              </FormGroup>
            </Col>
          )}
          <Col>
            <FormGroup>
              <CustomInput
                type="text"
                component={Input}
                name="searchText"
                placeholder={t('common:search')}
                value={filters.searchText}
                delay={600}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
        </Form>
        {registerLink && registerLink !== '/condominiums/register' && (
          <Link to={registerLink}>
            <KiperButton type="button" color="primary">
              {t(`list.register-${entity}`)}
            </KiperButton>
          </Link>
        )}
      </Container>
    </PageHeader>
  );
};

export default PageActions;

PageActions.propTypes = {
  filters: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  route: propTypes.object.isRequired,
  entity: propTypes.string.isRequired,
  registerLink: propTypes.string,
  onlySearch: propTypes.bool,
};

PageActions.defaultProps = {
  registerLink: null,
  onlySearch: false,
};
