import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CustomInput } from '@kiper/ui';
import { MdPhone } from 'react-icons/md';
import {
  FormGroup,
  Label,
  Flex,
  IconButton,
  AddIcon,
  RemoveIcon,
} from './styles';

const Contact = ({
  index,
  value,
  error,
  touched,
  editMode,
  isEdition,
  isLast,
  showBothActions,
  handleChange,
  setFieldTouched,
  push,
  remove,
}) => {
  const [t] = useTranslation('user');
  const additionalPhoneNumber = false; // Quando precisar liberar telefones adicionais, remover flag.

  return (
    <FormGroup>
      <Label info block>
        {t('put.additional-phone', { index: index + 1 })}
      </Label>
      {!editMode && isEdition ? (
        <Label>{value}</Label>
      ) : (
        <>
          <Flex alignItems="center">
            <CustomInput
              required
              isPhoneNumber
              autoFocus
              className="form-control"
              type="text"
              placeholder={t('put.placeholders.phone')}
              name="contact"
              id={`additional-contact-${index}`}
              value={value}
              onChange={(_, val) => handleChange(val)}
              error={touched && !!error ? error : ''}
              icon={<MdPhone />}
              onInput={() =>
                setFieldTouched(
                  `additionalContacts[${index}].contact`,
                  true,
                  true,
                )
              }
            />
            {showBothActions && (
              <IconButton
                size="sm"
                color="danger"
                variant="out"
                rounded
                onClick={remove}
              >
                <RemoveIcon />
              </IconButton>
            )}
            {additionalPhoneNumber && (
              <IconButton
                size="sm"
                color={isLast ? 'primary' : 'danger'}
                outline
                icon
                radius
                onClick={isLast ? push : remove}
                disabled={!!error}
              >
                {isLast ? <AddIcon /> : <RemoveIcon />}
              </IconButton>
            )}
          </Flex>
        </>
      )}
    </FormGroup>
  );
};

Contact.propTypes = {
  index: propTypes.number.isRequired,
  value: propTypes.string,
  error: propTypes.string,
  touched: propTypes.bool,
  editMode: propTypes.bool,
  isEdition: propTypes.bool,
  isLast: propTypes.bool,
  showBothActions: propTypes.bool,
  handleChange: propTypes.func.isRequired,
  push: propTypes.func.isRequired,
  remove: propTypes.func.isRequired,
  setFieldTouched: propTypes.func.isRequired,
};

Contact.defaultProps = {
  value: '',
  error: '',
  editMode: false,
  touched: false,
  isEdition: false,
  isLast: true,
  showBothActions: true,
};

export default Contact;
