import { createContext, useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { Flex, Spinner, SplitSidebar } from '@kiper/ui';
import { queries } from '@kiper/monitoring-graphql/condominium/detail';
import { useTranslation } from 'react-i18next';
import { MdOutlineStickyNote2, MdFilterList } from 'react-icons/md';
import { useToggle } from '@kiper/hooks';
import { EmptyStateIcon } from '../../EmptyStateIcon';
import { sendAmplitudeData } from '../../../services/amplitude';
import CondominiumRule from './CondominiumRule';
import { CategoryFilter } from './Filters/Category';
import { TextFilter } from './Filters/Text';
import useRulesFilters from '../../../hooks/useRulesFilters';
import * as S from './styles';

export const CondominiumRulesContext = createContext();

const CondominiumRules = ({
  condominiumName,
  condominiumPersonContextId,
  event,
}) => {
  const [rules, setRules] = useState([]);
  const {
    filteredRules,
    textFilter,
    setTextFilter,
    categoriesFilter,
    setCategoriesFilter,
  } = useRulesFilters(rules);
  const [categories, setCategories] = useState([]);
  const [enabledFilters, toggleEnabledFilters] = useToggle(false);
  const [t] = useTranslation('event_attendance');

  const { data, loading } = useQuery(queries.condominiumRules, {
    fetchPolicy: 'no-cache',
    skip: !condominiumPersonContextId,
    variables: {
      condominiumId: condominiumPersonContextId,
    },
  });

  const toggleFilterCategory = (content, isCategorySelected = false) => {
    let newCategoriesFilter = [];

    if (Array.isArray(content)) {
      newCategoriesFilter = content;
    } else {
      newCategoriesFilter = [...categoriesFilter];

      if (!isCategorySelected) {
        sendAmplitudeData(`condoRules filter by category`, {
          eventType: event?.eventType,
          categoryId: content?.id,
          categoryName: content?.name,
        });
        newCategoriesFilter.push(content);
      } else {
        newCategoriesFilter = newCategoriesFilter.filter(
          cat => cat.id !== content.id,
        );
      }
    }

    setCategoriesFilter(newCategoriesFilter);
  };

  useEffect(() => {
    const condoRules = data?.condominiumRules || [];
    setRules(condoRules);
  }, [data]);

  return (
    <CondominiumRulesContext.Provider
      value={{
        rules,
        filteredRules,
        textFilter,
        setTextFilter,
        categoriesFilter,
        setCategoriesFilter: toggleFilterCategory,
        categories,
        setCategories,
      }}
    >
      <S.Wrapper>
        <SplitSidebar.HeaderPane justifyContent="space-between" hasBorder>
          {t('details.how-treat.condominium-rules')}
          <S.Button
            variant="text"
            filters={enabledFilters}
            icon={<MdFilterList />}
            onClick={toggleEnabledFilters}
          />
        </SplitSidebar.HeaderPane>
        {loading && (
          <Flex
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner size={28} />
          </Flex>
        )}
        {!loading && enabledFilters && <TextFilter />}
        <CategoryFilter enabledFilters={enabledFilters} event={event} />

        {!loading &&
          !!filteredRules?.length &&
          filteredRules.map((rule, index) => (
            <CondominiumRule
              rule={rule}
              key={index}
              enabledFilters={enabledFilters}
            />
          ))}

        {!loading && !filteredRules?.length && (
          <EmptyStateIcon
            mt={56}
            icon={MdOutlineStickyNote2}
            title={t('details.how-treat.empty-state.condominium-rules.title')}
            text={t('details.how-treat.empty-state.condominium-rules.text', {
              condominiumName,
            })}
          />
        )}
      </S.Wrapper>
    </CondominiumRulesContext.Provider>
  );
};

export default CondominiumRules;

CondominiumRules.propTypes = {
  event: propTypes.object,
  condominiumPersonContextId: propTypes.number,
  condominiumName: propTypes.string,
};

CondominiumRules.defaultProps = {
  event: null,
  condominiumName: null,
  condominiumPersonContextId: null,
};
