import { useContext } from 'react';
import { User as UserIcon } from 'react-feather';
import { UncontrolledDropdown, DropdownMenu } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { AnnounceKit } from '@kiper/announcekit';

import { personApplications } from '@kiper/monitoring-graphql/permission';

import { useQuery } from 'react-apollo';

import { Flex, KiperButton } from '@kiper/ui';

import authContext from '../../services/auth/context';

import ProductHub from '../ProductHub';

import { HeaderContainer, Nav, DropdownToggle } from './styles';

const Header = () => {
  const { signOut, currentLoggedContext } = useContext(authContext);
  const [t] = useTranslation('menu');

  const { data } = useQuery(personApplications, {
    variables: {
      personId: currentLoggedContext.personId,
    },
  });

  return (
    <HeaderContainer className="content-header">
      <Nav>
        <AnnounceKit label={t('news')} user={currentLoggedContext} />
        <Flex mr="4px" pt="1px">
          <ProductHub
            applicationName="Monitoring"
            personApplications={data?.personApplications}
          />
        </Flex>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            <UserIcon color="#fff" />
          </DropdownToggle>
          <DropdownMenu right>
            <KiperButton block onClick={signOut} variant="text" color="neutral">
              {t('logout')}
            </KiperButton>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
