import { useMutation } from 'react-apollo';
import { dweller as dwellerGql } from '@kiper/monitoring-graphql';
import { useTranslation } from 'react-i18next';
import { useSwal } from '@kiper/hooks';
import { useFormatApolloErrorHandler } from '../../../../../hooks';

export const useBlockAccess = ({ user }, callback) => {
  const { t } = useTranslation('user');
  const { swal, toast } = useSwal();
  const { onError } = useFormatApolloErrorHandler();

  const [blockAccess] = useMutation(
    dwellerGql.detail.mutations.dwellerBlockAppAccess,
    {
      onError,
      onCompleted: () => {
        toast.fire({
          title: t('put.access-data.block-user-access.success-blocked'),
          icon: 'success',
        });
      },
    },
  );

  const [unblockAccess] = useMutation(
    dwellerGql.detail.mutations.dwellerUnblockAppAccess,
    {
      onError,
      onCompleted: () => {
        toast.fire({
          title: t('put.access-data.block-user-access.success-unblocked'),
          icon: 'success',
        });
      },
    },
  );

  const onBlockAccess = async ({ id, ...values }) => {
    await blockAccess({
      variables: {
        personContextId: Number(id),
        reason: values?.blockedReason,
      },
    }).then(() => callback({ id, ...values }));
  };

  const onUnblockAccess = async ({ id, ...values }) => {
    await unblockAccess({
      variables: { personContextId: Number(id) },
    }).then(() => callback({ id, ...values }));
  };

  const showBlockAlert = async value => {
    await swal.fire({
      title: t('put.access-data.block-user-access.confirm-block-title'),
      text: t('put.access-data.block-user-access.confirm-block-description'),
      cancelButtonText: t('buttons:cancel'),
      confirmButtonText: t('buttons:confirm'),
      showLoaderOnConfirm: true,
      preConfirm: () => onBlockAccess({ id: value.id, ...value }),
      allowOutsideClick: () => !swal.isLoading(),
    });
  };

  const showUnblockAlert = async value => {
    await swal.fire({
      title: t('put.access-data.block-user-access.confirm-unblock-title'),
      text: t('put.access-data.block-user-access.confirm-unblock-description'),
      cancelButtonText: t('buttons:cancel'),
      confirmButtonText: t('buttons:confirm'),
      showLoaderOnConfirm: true,
      preConfirm: () => onUnblockAccess({ ...value }),
      allowOutsideClick: () => !swal.isLoading(),
    });
  };

  const handleBlockAppAccessSubmit = ({ id, ...values }) => {
    const currentAccessContext = user?.accesses?.find?.(
      userAccess => String(id) === userAccess.id,
    );

    if (currentAccessContext?.isBlocked === values.isBlocked) return;
    if (!values.isBlocked) {
      showUnblockAlert({ id, ...values });
      return;
    }

    showBlockAlert({ id, ...values });
  };

  return {
    handleBlockAppAccessSubmit,
  };
};
