import { createContext, useReducer, useMemo, useEffect } from 'react';
import propTypes from 'prop-types';

export const CondominiumRuleFilterContext = createContext();

const INITIAL_STATE = {
  triages: [],
  subtriages: [],
  questions: [],
  filteredTriage: null,
  filteredSubtriage: null,
  selectedTriage: null,
  selectedSubtriage: null,
  loading: false,
};

export const CondominiumRuleFilterProvider = ({ children }) => {
  const [filterContext, setFilterContext] = useReducer((state, action) => {
    const copyState = { ...state };
    const newState = { ...copyState, ...action };

    return newState;
  }, INITIAL_STATE);

  const handleClearFilter = () => {
    setFilterContext({ ...INITIAL_STATE });
  };

  useEffect(() => {
    const { filteredSubtriage } = filterContext;
    if (!filteredSubtriage) {
      setFilterContext({
        questions: [],
      });
    }
  }, [filterContext.filteredSubtriage]);

  const value = useMemo(
    () => ({
      ...filterContext,
      handleClearFilter,
      setFilterContext,
    }),
    [handleClearFilter, filterContext, setFilterContext],
  );

  return (
    <CondominiumRuleFilterContext.Provider value={value}>
      {children}
    </CondominiumRuleFilterContext.Provider>
  );
};

export default { CondominiumRuleFilterContext, CondominiumRuleFilterProvider };

CondominiumRuleFilterProvider.propTypes = {
  children: propTypes.oneOfType([propTypes.node, propTypes.element]),
};

CondominiumRuleFilterProvider.defaultProps = {
  children: <></>,
};
