import { useState, useRef, useMemo, useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { partnerDwellers } from '@kiper/monitoring-graphql/dweller/list/query';
import { Table, TableLoader, KiperButton } from '@kiper/ui';
import { Link } from 'react-router-dom';
import { useFilters } from '@kiper/hooks';
import ListItem from './ListItem';
import emptyStatePlaceholder from '../../../assets/images/empty-state-users.svg';
import initEmptyStatePlaceholder from '../../../assets/images/empty-state-users-initial.svg';
import Pagination from '../../../components/Pagination';
import UsersPageActions, { tabIds } from '../../../components/UsersPageActions';
import EmptyState from '../../../components/EmptyState';
import useCurrentLoggedContext from '../../../hooks/useCurrentLoggedContext';

import * as S from './styles';
import { userProfile } from '../../../constants';
import UsersFilter from './UsersFilter/UsersFilter';

const column = x => `list.table-header.${x}`;

const initialFilters = {
  searchText: '',
  page: 1,
  pagesize: 10,
  orderby: 'condominiumName',
  ordination: false,
  profileIds: [],
};

export default function List({ route }) {
  const [t] = useTranslation('user');
  const { loggedContext } = useCurrentLoggedContext();

  const [emptyList, setEmptyList] = useState(false);

  const {
    filters,
    handlePageChange,
    handlePageSize,
    handleChange,
  } = useFilters(
    {
      ...initialFilters,
      sourceNodeId: loggedContext.topNodeId,
    },
    {
      useFilterParams: true,
    },
  );

  const columns = useRef([
    {
      name: t(column('condominium')),
      align: 'left',
      width: '25%',
    },
    {
      name: t(column('unity-group')),
      align: 'left',
      width: '10%',
    },
    {
      name: t(column('name')),
      align: 'left',
      width: '20%',
    },
    {
      name: t(column('profile')),
      align: 'left',
      width: '10%',
    },
    {
      name: t(column('access-type')),
      align: 'left',
      width: '10%',
    },
    {
      name: t(column('sync')),
      align: 'center',
      width: '5%',
    },
    { key: 6, name: '', align: 'center', width: '10%' },
  ]);

  const [getDwellers, { data, loading, refetch }] = useLazyQuery(
    partnerDwellers,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => setEmptyList(false),
    },
  );

  const {
    partnerDwellers: { collection, pagination },
  } = useMemo(
    () => data ?? { partnerDwellers: { collection: [], pagination: {} } },
    [data],
  );

  const userProfilesWithCondominiumAsPartner =
    loggedContext.profileId === userProfile.doorman ||
    loggedContext.profileId === userProfile.condominiumMaster;

  useEffect(() => {
    const { searchText, sourceNodeId } = filters;
    const canSearchNewDwellers =
      !!searchText ||
      sourceNodeId !== loggedContext.topNodeId ||
      userProfilesWithCondominiumAsPartner;

    if (canSearchNewDwellers) getDwellers({ variables: { filters } });
    else setEmptyList(true);

    return () => null;
  }, [filters]);

  const registerLink = '/users/register';

  return (
    <S.Wrapper>
      <UsersPageActions
        t={t}
        route={route}
        title={t('list.title')}
        tabId={tabIds.USERS_LIST}
        headerAction={
          registerLink && (
            <S.LinkWrapper>
              <Link to={registerLink}>
                <KiperButton type="button" expandable>
                  {t(`list.register-user`)}
                </KiperButton>
              </Link>
            </S.LinkWrapper>
          )
        }
      >
        <>
          <UsersFilter handleChange={handleChange} filters={filters} />

          {(emptyList && !loading) ||
          (collection && !collection[0] && !loading) ? (
            <>
              {emptyList ? (
                <EmptyState
                  placeholder={initEmptyStatePlaceholder}
                  text={t('list.init-empty-state')}
                />
              ) : (
                <EmptyState
                  placeholder={emptyStatePlaceholder}
                  action={{
                    action: '/users/register',
                    text: t('list.register-user'),
                  }}
                  text={t('list.empty-state')}
                />
              )}
            </>
          ) : (
            <S.TableContainer>
              <Table columns={columns}>
                {!loading && collection && (
                  <tbody>
                    {collection.map(item => (
                      <ListItem
                        key={item.personContextId}
                        data={item}
                        onSync={refetch}
                      />
                    ))}
                  </tbody>
                )}
              </Table>
              {loading && <TableLoader />}
              <Pagination
                filters={filters}
                pagination={pagination}
                handlePageSize={handlePageSize}
                next={() => handlePageChange(1)}
                previous={() => handlePageChange(-1)}
              />
            </S.TableContainer>
          )}
        </>
      </UsersPageActions>
    </S.Wrapper>
  );
}

List.propTypes = {
  route: propTypes.object.isRequired,
};
