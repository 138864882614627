import styled from 'styled-components';
import { Button as button } from '@kiper/ui';

export const Container = styled.div`
  display: flex;
  margin: 0 -15px;
  flex-wrap: wrap;
`;

export const CardContainer = styled.div`
  opacity: ${props => props.opacity};
  width: 33.3333%;
  margin: 0 15px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled(button)``;

export const Header = styled.div`
  margin: 15px 0 10px 0;
  display: flex;
  flex-direction: column;
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${props => props.theme.colors.secondary300};
  border-radius: 4px;
  padding: 10px;
  background-color: ${props =>
    props.blocked ? props.theme.colors.secondary300 : props.theme.colors.white};
  cursor: ${props => (props.isEditable ? 'move' : 'auto')};
  cursor: ${props => (props.isEditable ? 'grab' : 'auto')};
  cursor: ${props => (props.isEditable ? '-moz-grab' : 'auto')};
  cursor: ${props => (props.isEditable ? '-webkit-grab' : 'auto')};

  &:active {
    cursor: ${props => (props.isEditable ? 'grabbing' : 'auto')};
    cursor: ${props => (props.isEditable ? '-moz-grabbing' : 'auto')};
    cursor: ${props => (props.isEditable ? '-webkit-grabbing' : 'auto')};
  }
`;

export const Line = styled.div`
  background-color: ${props => props.theme.colors.secondary600};
  border-radius: 4px;
  width: 15px;
  height: 2px;
  margin-bottom: 2px;
`;

export const Contact = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;

export const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid ${props => props.theme.colors.primary800};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;

  svg {
    color: ${props => props.theme.colors.primary800};
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
