import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex, Input, Label } from '@kiper/ui';
import { UserGuUnSelect } from '../../../../Select';

const FilterFields = ({ event, values, setFieldValue }) => {
  const { t } = useTranslation('guided-attendance');

  const handleChangeUnity = unity => {
    if (unity) {
      const { id, label, value } = unity;

      setFieldValue('stepAttendanceGetUnity.filters.unity', {
        id,
        label,
        value,
      });
    } else {
      setFieldValue('stepAttendanceGetUnity.filters.unity', '');
    }
  };

  return (
    <Flex width="100%" flex={1} gridGap="8px">
      <Flex flexDirection="column" flex={0.5}>
        <Label>{t('step-get-unity.select-unity')}</Label>
        <UserGuUnSelect
          name="stepAttendanceGetUnity.filters.unity"
          placeholder={t('step-get-unity.select-unity')}
          onChange={handleChangeUnity}
          sourceNodeId={event?.condominium?.treeNodeId}
          value={values?.stepAttendanceGetUnity?.filters?.unity}
          guLoad={false}
          autoFocus
        />
      </Flex>
      <Flex flexDirection="column" flex={0.5}>
        <Label>{t('step-get-unity.enter-the-dweller-name')}</Label>
        <Input
          type="text"
          name="stepAttendanceGetUnity.filters.searchName"
          placeholder={t('step-get-unity.enter-the-dweller-name')}
          value={values?.stepAttendanceGetUnity?.filters?.searchName}
          delay={600}
          onChange={setFieldValue}
          autoComplete="off"
        />
      </Flex>
    </Flex>
  );
};

export default FilterFields;

FilterFields.propTypes = {
  event: propTypes.object.isRequired,
  values: propTypes.object.isRequired,
  setFieldValue: propTypes.func.isRequired,
};
