import styled from 'styled-components';
import { FormGroup as formGroup, Form as form } from 'reactstrap';
import { FiAlertCircle } from 'react-icons/fi';
import InputGroupText from 'reactstrap/lib/InputGroupText';

export { Select } from '@kiper/ui';

export const InputGroupTextStyled = styled(InputGroupText)`
  padding: 0;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border: none;
  background-color: ${({ theme }) => theme.colors.secondary100};
`;

export const FormGroup = styled(formGroup)``;

export const Form = styled(form)``;

export const Alert = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${props => props.theme.colors.warningAlert};
  color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;

  svg {
    min-width: 25px;
    height: auto;
  }
  label {
    margin: 0;
  }
`;

export const AlertIcon = styled(FiAlertCircle)``;

export const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;
