import BlockedList from './BlockedList';
import UsersPage from './List';
import PendingApproval from './PendingApproval';
import UserPut from './Put';

const breadcrumb = (label, to) => ({ label, to });
const requiredPermissions = ['users'];

export default [
  {
    name: 'List of users',
    path: '/users',
    exact: true,
    component: UsersPage,
    breadcrumb: [breadcrumb('common:breadcrumb.users', '/users')],
    requiredPermissions,
  },
  {
    name: 'Pending Approval Users',
    path: '/users/pending-approval',
    exact: true,
    component: PendingApproval,
    breadcrumb: [],
    requiredPermissions,
  },
  {
    name: 'List of blocked users',
    path: '/users/blocked',
    exact: true,
    component: BlockedList,
    breadcrumb: [breadcrumb('common:breadcrumb.users', '/users/blocked')],
    requiredPermissions,
  },
  {
    name: 'User register',
    path: '/users/register',
    exact: true,
    component: UserPut,
    breadcrumb: [
      breadcrumb('common:breadcrumb.users', '/users'),
      breadcrumb('common:breadcrumb.create', '/users/register'),
    ],
    requiredPermissions,
  },
  {
    name: 'User detail',
    path: '/users/:personId',
    exact: true,
    component: UserPut,
    breadcrumb: [
      breadcrumb('common:breadcrumb.users', '/users'),
      breadcrumb('common:breadcrumb.edit', '/users/:personId'),
    ],
    requiredPermissions,
  },
];
