import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { createAttendance } from '@kiper/monitoring-graphql/attendance';
import { useMutation } from 'react-apollo';
import { userProfile } from '../../../../constants';

const useCreateNewAttendance = ({ loggedContext }) => {
  const { toast } = useSwal();
  const [create, { loading }] = useMutation(createAttendance, {
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const handleActionToCreateNewAttendance = toggleNewEventModal => {
    const doormanProfiles = [
      userProfile.doorman,
      userProfile.doormanMonitoring,
    ];
    const { condominium, profileId } = loggedContext;

    if (condominium?.personContextId && doormanProfiles.includes(profileId))
      return create({
        variables: {
          condominiumId: Number(condominium?.personContextId),
        },
      });

    return toggleNewEventModal();
  };

  return { handleActionToCreateNewAttendance, loading };
};

export default useCreateNewAttendance;
