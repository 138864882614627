export const formatDeviceModelName = device => {
  let deviceModelName = '';

  if (device?.deviceModel?.name) deviceModelName = device.deviceModel?.name;
  else if (device?.deviceType?.name) deviceModelName = device.deviceType?.name;

  return deviceModelName;
};

export const formatDeviceTypeLabel = device => {
  if (device?.label) return device.label;

  let label = '';

  label += formatDeviceModelName(device);
  if (device.serialNumber) label += ` (ESN: ${device.serialNumber})`;
  if (device.name) label += ` - ${device.name}`;

  return label;
};
