import { Label } from '@kiper/ui';
import styled from 'styled-components';

export const Container = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
  width: 100%;
  background-color: #fff;
  border: 1px solid ${props => props.theme.colors.secondary300};
  border-radius: 4px;
  padding: 10px;
`;

export const RemoveAction = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const Item = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin-bottom: 8px;
`;

export const LabelTitle = styled(Label)`
  font-size: 14px;
  font-weight: 600;
`;

export const LabelDescription = styled(Label)`
  font-size: 12px;
`;
