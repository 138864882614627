import { useEffect } from 'react';
import propTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import {
  Input as CustomInput,
  Flex,
  KiperButton,
  Pagination,
  Table,
  TableLoader,
  Text,
  Badge,
} from '@kiper/ui';
import { useTheme } from 'styled-components';

import { queries } from '@kiper/monitoring-graphql/dweller/list';
import { useLazyQuery } from 'react-apollo';

import { FormGroup, Input, TableContainer } from './styles';
import { UserGuUnSelect } from '../../Select';
import AccessProfileSelect from '../../AccessProfileSelect/AccessProfileSelect';
import { profileColor, profileTypes } from '../../../constants';

const ChangeAccessProfileModalSelectResidents = ({
  columns,
  condominium,
  handlePageChange,
  handlePageSize,
  handleToggle,
  filters,
  setFilters,
  unity,
  setUnity,
  accessProfile,
  setAccessProfile,
  setActiveStep,
}) => {
  const { t } = useTranslation('condominium');

  const [fetch, { data, loading }] = useLazyQuery(queries.partnerDwellers, {
    fetchPolicy: 'no-cache',
  });

  const handleChange = (name, value) =>
    setFilters(x => ({ ...x, [name]: value, page: 1 }));

  const getDwellers = () => {
    let newFilters = { ...filters, accessProfileIds: [] };
    if (filters.accessProfileIds?.length) {
      newFilters = {
        ...filters,
        accessProfileIds: filters.accessProfileIds.map(x => x.value),
      };
    }
    fetch({
      variables: {
        filters: newFilters,
      },
    });
  };

  useEffect(() => {
    getDwellers();
  }, []);

  useEffect(() => {
    getDwellers();
  }, [
    filters.pagesize,
    filters.page,
    filters.accessProfileIds,
    filters.searchText,
    filters.sourceNodeId,
  ]);

  const theme = useTheme();

  return (
    <Flex
      flexDirection="column"
      px="24px"
      pt="24px"
      mt="16px"
      borderTop={`1px solid ${theme.colors.secondary200}`}
    >
      <Text fontSize="20px">
        {t('details.modal.access-profile.change-profile.select-residents')}
      </Text>
      <Text mt="20px" fontSize="16px">
        {t(
          'details.modal.access-profile.change-profile.select-residents-subtitle',
        )}
      </Text>
      <Flex flexDirection="column">
        <Flex mb="16px">
          <Flex flex={2}>
            <FormGroup>
              <UserGuUnSelect
                name="unity"
                onChange={e => {
                  handleChange(
                    'sourceNodeId',
                    e ? e?.treeNodeId : condominium.treeNodeId,
                  );
                  setUnity(e);
                }}
                sourceNodeId={filters.sourceNodeId}
                value={unity}
                isDisabled={!condominium}
              />
            </FormGroup>
          </Flex>

          <Flex flex={2}>
            <FormGroup>
              <AccessProfileSelect
                width="100%"
                classNamePrefix="select-profile"
                isMulti
                isClearable
                placeholder={t(
                  `details.modal.access-profile.change-profile.access-profile`,
                )}
                name="accessProfile"
                onChange={e => {
                  handleChange('accessProfileIds', e || []);
                  setAccessProfile(e || []);
                }}
                value={accessProfile}
                sourceNodeId={condominium?.treeNodeId}
              />
            </FormGroup>
          </Flex>

          <Flex flex={2}>
            <FormGroup>
              <CustomInput
                width="100%"
                type="text"
                component={Input}
                name="searchText"
                placeholder={t('common:search')}
                value={filters.searchText}
                delay={600}
                onChange={handleChange}
              />
            </FormGroup>
          </Flex>
        </Flex>
        {data?.partnerDwellers?.pagination && (
          <Flex
            justifyContent="center"
            mb="12px"
            bg="secondary200"
            borderRadius="4px"
            p="10px"
          >
            {data?.partnerDwellers?.pagination?.totalResults > 1 ||
            data?.partnerDwellers?.pagination?.totalResults === 0 ? (
              <Text fontSize="16px" fontWeight="bold">
                {t(
                  'details.modal.access-profile.change-profile.amount-persons-selected',
                  {
                    amount: data?.partnerDwellers?.pagination?.totalResults,
                  },
                )}
              </Text>
            ) : (
              <Text fontSize="16px" fontWeight="bold">
                {t(
                  'details.modal.access-profile.change-profile.amount-person-selected',
                  {
                    amount: data?.partnerDwellers?.pagination?.totalResults,
                  },
                )}
              </Text>
            )}
          </Flex>
        )}
        <TableContainer>
          <Table columns={columns} hover={false}>
            {!loading && !!data?.partnerDwellers?.collection?.length && (
              <tbody>
                {data?.partnerDwellers?.collection?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Flex flexDirection="row">
                        <Text fontSize="14px" mr="8px">
                          {item.name}
                        </Text>
                        <Badge color={profileColor[item.profileFieldName]}>
                          {t(
                            `common:profiles.${
                              profileTypes[item.profileFieldName]
                            }`,
                          )}
                        </Badge>
                      </Flex>
                    </td>
                    <td>
                      <Flex flexDirection="column">
                        <Text fontSize="14px">{item.unityName}</Text>
                        <Text fontSize="12px" color="secondary800">
                          {item.unityGroupName}
                        </Text>
                      </Flex>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>
          {loading && <TableLoader />}
          <Pagination
            translate={t}
            pagination={data?.partnerDwellers?.pagination}
            handlePageSize={handlePageSize}
            next={() => handlePageChange(1)}
            previous={() => handlePageChange(-1)}
          />
        </TableContainer>
      </Flex>
      <Flex justifyContent="space-between" width="100%" flex={1} py="20px">
        <KiperButton
          type="button"
          variant="outlined"
          onClick={() => handleToggle(false)}
        >
          {t('details.modal.access-profile.change-profile.cancel')}
        </KiperButton>

        <Flex ml="16px">
          <KiperButton
            type="submit"
            disabled={data?.partnerDwellers?.pagination?.totalResults === 0}
            onClick={() => setActiveStep(activeStep => activeStep + 1)}
          >
            {t('details.modal.access-profile.change-profile.continue')}
          </KiperButton>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ChangeAccessProfileModalSelectResidents;

ChangeAccessProfileModalSelectResidents.propTypes = {
  columns: propTypes.object.isRequired,
  condominium: propTypes.object.isRequired,
  handlePageChange: propTypes.func.isRequired,
  handlePageSize: propTypes.func.isRequired,
  handleToggle: propTypes.func.isRequired,
  filters: propTypes.object.isRequired,
  setFilters: propTypes.func.isRequired,
  unity: propTypes.object,
  setUnity: propTypes.func.isRequired,
  accessProfile: propTypes.array.isRequired,
  setAccessProfile: propTypes.func.isRequired,
  setActiveStep: propTypes.func.isRequired,
};

ChangeAccessProfileModalSelectResidents.defaultProps = {
  unity: null,
};
