import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex } from '@kiper/ui';
import { Grid, ScheduleContainer, Text } from '../styles';
import useSchedule from './hooks/useSchedule';

const ScheduleType = ({ question, answer }) => {
  const { t } = useTranslation('guided-attendance');

  const translateDaysOfWeekPrefix = key =>
    t(`quick-menu.condominium-rule-questions-awswers.days-of-week.${key}`);
  const translatePrefix = key =>
    t(`quick-menu.condominium-rule-questions-awswers.${key}`);

  const { schedules } = useSchedule({ answer });

  return (
    <ScheduleContainer>
      <Text>{question}</Text>
      <Flex flexDirection="column">
        {!!schedules?.length &&
          schedules.map(({ dayOfWeek, time, currentDay }) => (
            <Grid key={dayOfWeek}>
              <Text weight={currentDay ? 800 : 400}>
                {translateDaysOfWeekPrefix(dayOfWeek)}
              </Text>
              <Text weight={currentDay ? 800 : 400}>
                {time || translatePrefix('unauthorized')}
              </Text>
            </Grid>
          ))}
      </Flex>
    </ScheduleContainer>
  );
};

export default ScheduleType;

ScheduleType.propTypes = {
  question: propTypes.string.isRequired,
  answer: propTypes.shape({
    scheduleAnswer: propTypes.string.isRequired,
  }).isRequired,
};
