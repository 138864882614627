import { ErrorMessage, Label, Select } from '@kiper/ui';
import propTypes from 'prop-types';
import { query } from '@kiper/monitoring-graphql/access_facial';
import { useLazyQuery } from 'react-apollo';
import { useEffect, useState } from 'react';
import { FormGroup } from './styles';

const PREFIX = 'put.devices.modal.insert';

const UserDeviceFacial = ({ formik, t, personId }) => {
  const { values, setFieldValue, errors, touched } = formik;
  const [condominiums, setCondominiums] = useState([]);
  const [places, setPlaces] = useState([]);

  const [fetch, { loading }] = useLazyQuery(query.accessFacialCondominiums, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ accessFacialCondominiums }) => {
      const formattedCondominiums = accessFacialCondominiums.map(condo => {
        const formattedPlaces = [];
        if (condo?.places?.length) {
          condo.places.forEach(place => {
            if (place?.devices?.length) {
              place.devices.forEach(device =>
                formattedPlaces.push({
                  deviceId: +device.id,
                  isOnline: device.isOnline,
                  concatName: `${place.name} - ${device.name} - ${
                    device.isOnline ? 'Online' : 'Offline'
                  }`,
                }),
              );
            }
            return formattedPlaces;
          });
        }
        return { ...condo, places: formattedPlaces };
      });

      setCondominiums(formattedCondominiums);
    },
  });

  useEffect(() => {
    fetch({
      variables: {
        personId,
      },
    });
  }, []);

  useEffect(() => {
    if (values?.facialCondominium) {
      const facialPlaces = values?.facialCondominium?.places;
      setPlaces(facialPlaces);
      if (facialPlaces.length === 1) {
        setFieldValue('facialPlace', facialPlaces[0]);
      }
    }
  }, [values?.facialCondominium]);

  useEffect(() => {
    if (condominiums.length === 1) {
      setFieldValue('facialCondominium', condominiums[0]);
    }
  }, [condominiums]);

  return (
    <>
      <FormGroup>
        <Label htmlFor="facialCondominium" block info>
          {t(`${PREFIX}.condominium`)}
        </Label>
        <Select
          width="100%"
          placeholder={t(`${PREFIX}.condominium-placeholder`)}
          name="facialCondominium"
          options={condominiums}
          value={values?.facialCondominium}
          onChange={e => setFieldValue('facialCondominium', e)}
          isLoading={loading}
          invalid={errors?.facialCondominium}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.name}
        />
        {!!touched?.facialCondominium && !!errors?.facialCondominium && (
          <ErrorMessage>{t('common:feedback.required-field')}</ErrorMessage>
        )}
      </FormGroup>
      <FormGroup>
        <Label htmlFor="condominium" block info>
          {t(`${PREFIX}.facial.place`)}
        </Label>
        <Select
          width="100%"
          placeholder={t(`${PREFIX}.facial.place-placeholder`)}
          name="facialPlace"
          options={places}
          value={values?.facialPlace}
          onChange={e => setFieldValue('facialPlace', e)}
          invalid={errors?.facialPlace}
          isDisabled={!places.length}
          isOptionDisabled={option => option.isOnline === false}
          getOptionValue={option => option.deviceId}
          getOptionLabel={option => option.concatName}
        />
        {!!touched?.facialPlace && !!errors?.facialPlace && (
          <ErrorMessage>{t('common:feedback.required-field')}</ErrorMessage>
        )}
      </FormGroup>
    </>
  );
};

export default UserDeviceFacial;

UserDeviceFacial.propTypes = {
  formik: propTypes.shape({
    values: propTypes.object,
    errors: propTypes.object,
    touched: propTypes.object,
    setFieldValue: propTypes.func,
  }).isRequired,
  t: propTypes.func.isRequired,
  personId: propTypes.number.isRequired,
};
