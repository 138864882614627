import { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import * as yup from 'yup';
import { AlertBar, MdIcons } from '@kiper/ui';
import { GuidedAttendanceContext } from '../../../store';
import { useGuidedAttendance } from '../../../hooks';

import StepStructure, { StepTextTypeColor } from '../StepStructure';

const StepMessageOpenDoors = () => {
  const { t } = useTranslation('guided-attendance');
  const { setFieldValue } = useFormikContext();
  const { handleNextAction } = useGuidedAttendance();

  const { setContextTree } = useContext(GuidedAttendanceContext);

  const schema = yup.object({
    stepMessageOpenDoors: yup.object({
      finishGuidedAttendance: yup.bool(),
    }),
  });

  const nextAction = async stepData => {
    const nextStep = await handleNextAction({
      values: stepData,
      fieldValue: 'stepMessageOpenDoors',
    });
    return { nextStep, currentStepData: stepData };
  };

  useEffect(() => {
    setFieldValue('stepMessageOpenDoors', {
      finishGuidedAttendance: true,
    });

    setContextTree({
      formikSchema: schema,
      nextAction,
    });
  }, []);

  return (
    <StepStructure
      type={StepTextTypeColor.SPEAK}
      text={t('step-message-open-doors.title')}
      hasPreviousStepButton
      hasNextStepButton
    >
      <AlertBar
        width="100%"
        color="black"
        background="guidedAttendance.text.action"
        title={<Trans>{t('step-message-open-doors.subtitle')}</Trans>}
        icon={<MdIcons mdIconName="info" size="20px" />}
      />
    </StepStructure>
  );
};

export default StepMessageOpenDoors;
