import { useTranslation } from 'react-i18next';
import emptyStateContinuousAttendanceIcon from '../../../../assets/images/empty-state-continuous-attendance.svg';
import * as S from './styles';

const EmptyState = () => {
  const [t] = useTranslation('event_attendance');

  return (
    <S.WrapperEmptyState>
      <S.IconWrapper>
        <S.EmptyStateIcon
          src={emptyStateContinuousAttendanceIcon}
          alt="empty state icon"
        />
      </S.IconWrapper>
      <S.TextEmptyState>
        {t('details.continuous-attendance.empty-state')}
      </S.TextEmptyState>
    </S.WrapperEmptyState>
  );
};

export default EmptyState;
