import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-apollo';
import { event as eventGql } from '@kiper/monitoring-graphql';
import { useFilters } from '@kiper/hooks';
import {
  Modal,
  Input as CustomInput,
  PlaceHolder,
  ScrollBar,
  Pagination,
} from '@kiper/ui';

import { sendAmplitudeData } from '../../../services/amplitude';

import EventRow from './EventRow';
import { Container, Col, Input } from './styles';

const initialFilters = {
  page: 1,
  pagesize: 10,
  ordination: false,
};

const loaderStyle = {
  height: '104px',
  width: '1036px',
  marginLeft: '56px',
  marginBottom: '8px',
  borderRadius: '4px',
};

const EventTimelineModal = ({ eventId, onToggle }) => {
  const [t] = useTranslation('modals/event-timeline-modal');

  const {
    filters,
    handlePageChange,
    handlePageSize,
    handleChange,
  } = useFilters(initialFilters);

  const { data, loading } = useQuery(eventGql.eventTimeline, {
    fetchPolicy: 'network-only',
    variables: {
      eventId,
      filters,
    },
  });

  const { eventTimeline } = data || { eventTimeline: {} };
  const { collection, pagination } = eventTimeline;

  return (
    <Modal title={t('title')} open toggle={onToggle} size="xl">
      <Container>
        <Col>
          <CustomInput
            data-cy="searchText"
            type="text"
            component={Input}
            name="searchText"
            placeholder={t('common:search')}
            value={filters.searchText}
            delay={600}
            onChange={(name, value) => {
              sendAmplitudeData('filter event history', {
                searchText: value,
              });
              handleChange(name, value);
            }}
          />
        </Col>
        <ScrollBar>
          {!loading && collection && (
            <>
              {collection.map((event, index) => (
                <EventRow event={event} key={index} />
              ))}
            </>
          )}
          {loading && <PlaceHolder style={loaderStyle} type="line" lines={6} />}
        </ScrollBar>
        <Pagination
          translate={t}
          pagination={pagination}
          handlePageSize={handlePageSize}
          next={() => handlePageChange(1)}
          previous={() => handlePageChange(-1)}
        />
      </Container>
    </Modal>
  );
};

export default EventTimelineModal;

EventTimelineModal.propTypes = {
  eventId: propTypes.string.isRequired,
  onToggle: propTypes.func.isRequired,
};
