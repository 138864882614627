import { useContext, useEffect, useLayoutEffect } from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';
import { GuidedAttendanceContext } from '../../../../store';

const useInitialization = ({ nextAction, t }) => {
  const { setContextTree } = useContext(GuidedAttendanceContext);
  const {
    values,
    setFieldValue,
    errors,
    touched,
    handleSubmit,
  } = useFormikContext();

  const schema = yup.object({
    stepGetOnlyUnity: yup.object({
      unity: yup
        .object({
          value: yup.string().required(t('step-get-only-unity.feedback-error')),
          label: yup.string(),
          parentName: yup.string(),
          treeNodeId: yup.number(),
        })
        .required(),
      unityGroup: yup.string().nullable(),
    }),
  });

  const invalidForm =
    !!touched.stepGetOnlyUnity?.unity && errors.stepGetOnlyUnity?.unity;

  useEffect(() => {
    if (values.stepGetOnlyUnity?.unity) {
      handleSubmit();
    }
  }, [values.stepGetOnlyUnity?.unity]);

  useLayoutEffect(() => {
    if (!values?.stepGetOnlyUnity)
      setFieldValue('stepGetOnlyUnity', {
        unity: '',
        unityGroup: null,
      });

    setContextTree({
      formikSchema: schema,
      nextAction: async stepData => {
        const nextStep = await nextAction(stepData);
        return { nextStep, currentStepData: stepData };
      },
    });
  }, []);

  return { values, setFieldValue, errors, touched, invalidForm };
};

export default useInitialization;
