import { useState, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import propTypes from 'prop-types';
import { getUserLang, formatDistanceDate } from '@kiper/fns';
import { Flex, KiperButton, Badge } from '@kiper/ui';
import { sendAmplitudeData } from '../../../services/amplitude';

import {
  EventRowContainer,
  EventStatus,
  EventInfoContainer,
  EventInfo,
  TextInfo,
  TextTitle,
  CalendarIcon,
  DwellerIcon,
  AttendantIcon,
  LocationIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  BellIcon,
  PhoneIcon,
} from './styles';
import EventDetail from './EventDetail';

function EventRow({ event }) {
  const [t] = useTranslation('modals/event-timeline-modal');
  const [isOpen, toggle] = useState(null);

  const lang = useMemo(() => getUserLang().split('-')[0], []);

  const { isTelephony, isCritical } = useMemo(
    () => ({
      isTelephony: event?.eventType === 102,
      isCritical: event?.isCritical,
    }),
    [event],
  );

  return (
    <EventRowContainer>
      <EventStatus telephony={isTelephony} critical={isCritical}>
        {!isTelephony ? <BellIcon size={20} /> : <PhoneIcon size={20} />}
      </EventStatus>

      <EventInfoContainer telephony={isTelephony} critical={isCritical}>
        <EventInfo>
          <Flex flexDirection="column">
            <Flex mb="8px">
              <TextTitle
                mr="16px"
                critical={isCritical}
                telephony={isTelephony}
              >
                {`${event?.eventType} - ${event?.eventTypeDescription[lang]}`}
              </TextTitle>
              {event?.place?.name && (
                <Badge color="info" className="mr-3" pill>
                  <LocationIcon />
                  {event?.place?.name}
                </Badge>
              )}
            </Flex>
            {(!!event?.creationDate || !!event?.dweller?.name) && (
              <Flex mb="8px">
                {!!event?.creationDate && (
                  <>
                    <CalendarIcon size={18} />
                    <TextInfo mr="16px">
                      {formatDistanceDate(event?.creationDate, t)}
                    </TextInfo>
                  </>
                )}
                {!!event?.dweller?.name && (
                  <>
                    <DwellerIcon size={18} />
                    <TextInfo>
                      <Trans>
                        {t('shot-by', { name: event.dweller.name })}
                      </Trans>
                    </TextInfo>
                  </>
                )}
              </Flex>
            )}
            {!!event?.attendant?.name && (
              <Flex mb="8px">
                <AttendantIcon size={18} />
                <TextInfo>
                  <Trans>
                    {t('attended-by', { name: event.attendant.name })}
                  </Trans>
                </TextInfo>
              </Flex>
            )}
          </Flex>
          <KiperButton
            rounded
            variant="text"
            color="neutral"
            icon={
              isOpen ? (
                <ChevronUpIcon size={24} />
              ) : (
                <ChevronDownIcon size={24} />
              )
            }
            onClick={() => {
              sendAmplitudeData('expand history event', {
                eventType: `${event?.eventType} - ${event?.eventTypeDescription[lang]}`,
              });
              toggle(isOpen === event.eventId ? null : event.eventId);
            }}
          />
        </EventInfo>
        {isOpen && isOpen === event.eventId && (
          <EventDetail eventId={event.eventId} eventDate={event.creationDate} />
        )}
      </EventInfoContainer>
    </EventRowContainer>
  );
}

export default EventRow;

EventRow.propTypes = {
  event: propTypes.object.isRequired,
};
