import propTypes from 'prop-types';
import { Flex } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import useSchedule from './hooks/useSchedule';
import * as S from './styles';

const CondoInfoCaretaker = ({ icon, caretaker }) => {
  const [condominiumTranslate] = useTranslation('condominium');
  const [t] = useTranslation('common');
  const [collapse, setCollapse] = useState(false);

  const toggleCollapseInfo = () => {
    setCollapse(!collapse);
  };

  const schedule = caretaker.personContextParams.find(
    x => x.name === 'contactSchedule',
  );

  const { schedules } = useSchedule({ schedule });

  return (
    <S.CondoInfoCollapseContainer>
      <Flex gridGap="8px">
        <span>{icon}</span>
        <Flex flexGrow="1" gridGap="4px">
          <strong>
            {condominiumTranslate('information.condominium-workers.caretaker')}
          </strong>
          <S.CaretakerSectionHeader onClick={toggleCollapseInfo}>
            <S.InfoDescription>{caretaker.name}</S.InfoDescription>
            <S.CollapseIcon size={10} collapse={collapse} />
          </S.CaretakerSectionHeader>
        </Flex>
      </Flex>
      {collapse && (
        <S.CaretakerSectionSchedule collapse={collapse}>
          <table width="100%">
            <thead>
              <tr>
                <th colSpan="2">
                  {condominiumTranslate('information.caretaker-schedules')}
                </th>
              </tr>
            </thead>
            <tbody>
              {schedules.map(item => (
                <S.TableRow key={item.dayOfWeek} isBold={item.currentDay}>
                  <td>{t(`week.${item.dayOfWeek}`)}</td>
                  <td>
                    {item.time.length > 0
                      ? item.time
                      : condominiumTranslate('information.without-schedule')}
                  </td>
                </S.TableRow>
              ))}
            </tbody>
          </table>
        </S.CaretakerSectionSchedule>
      )}
    </S.CondoInfoCollapseContainer>
  );
};

export default CondoInfoCaretaker;

CondoInfoCaretaker.propTypes = {
  icon: propTypes.element.isRequired,
  caretaker: propTypes.object.isRequired,
};
