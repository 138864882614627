import { Flex, Modal } from '@kiper/ui';
import propTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { registerFacialStatus } from '../../../constants';
import { ModalContentDefault, ModalContentWithRules } from './ModalContent';
import { Subtitle } from './styles';

const UserFacialFeedbackModal = ({
  open,
  onToggle,
  onCloseAllModals,
  feedbackData,
  setFeedbackData,
  refetchDevices,
}) => {
  const [t] = useTranslation('modals/user-facial-modal');

  const handleClick = () => {
    if (feedbackData.status === registerFacialStatus.success) {
      refetchDevices();
    }
    setFeedbackData(null);
    onToggle();
  };

  return (
    <Modal title={feedbackData.title} open={open} toggle={onCloseAllModals}>
      <Flex
        flexDirection="column"
        justifyContent="center"
        mb="36px"
        alignItems="center"
      >
        <Trans>
          <Subtitle>{feedbackData.subtitle}</Subtitle>
        </Trans>
        <Flex
          flexDirection="column"
          width="100%"
          gridGap="36px"
          alignItems="center"
        >
          <>
            {feedbackData.status !== registerFacialStatus.errorWithMessage ? (
              <ModalContentDefault
                t={t}
                onClick={handleClick}
                feedbackData={feedbackData}
              />
            ) : (
              <ModalContentWithRules
                t={t}
                onClick={handleClick}
                feedbackData={feedbackData}
              />
            )}
          </>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default UserFacialFeedbackModal;

UserFacialFeedbackModal.propTypes = {
  open: propTypes.bool.isRequired,
  onToggle: propTypes.func.isRequired,
  onCloseAllModals: propTypes.func.isRequired,
  feedbackData: propTypes.shape({
    title: propTypes.string.isRequired,
    subtitle: propTypes.string.isRequired,
    image: propTypes.string.isRequired,
    status: propTypes.string.isRequired,
  }).isRequired,
  setFeedbackData: propTypes.func.isRequired,
  refetchDevices: propTypes.func.isRequired,
};
