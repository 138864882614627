import PropTypes from 'prop-types';
import { Label } from '@kiper/ui';
import Select from './styles';

const CustomSelect = ({
  data,
  name,
  onChange,
  value,
  isEditable,
  ...props
}) => {
  if (!isEditable) return <Label block>{value?.label}</Label>;

  return (
    <Select
      {...props}
      name={name}
      selectOption={onChange}
      options={data}
      value={value}
      onChange={onChange}
    />
  );
};
CustomSelect.propTypes = {
  data: PropTypes.array.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
};

CustomSelect.defaultProps = {
  isEditable: true,
};

export default CustomSelect;
