import propTypes from 'prop-types';
import { Container } from './styles';
import Features from '../Features';

const MobileFunctionalities = ({
  isEditable,
  indexAccesses,
  values,
  handleChange,
}) => {
  return (
    <Container>
      {values.permissions && !!values.permissions.length && (
        <Features
          values={values}
          handleChange={handleChange}
          indexAccesses={indexAccesses}
          isEditable={isEditable}
        />
      )}
    </Container>
  );
};

MobileFunctionalities.propTypes = {
  values: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  indexAccesses: propTypes.number.isRequired,
  isEditable: propTypes.bool.isRequired,
};

export default MobileFunctionalities;
