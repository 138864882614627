import propTypes from 'prop-types';
import { useMutation } from 'react-apollo';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { mutation } from '@kiper/monitoring-graphql/guided_attendance_settings';
import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { Button, ErrorMessage, Flex, Label, Select } from '@kiper/ui';
import { useCurrentLoggedContext } from '../../../hooks';
import PersonSelect from '../../../components/PersonSelect';
import PageHeader from '../../../components/PageHeader';

import * as S from './styles';

function GuidedAttendanceSettingsEnableCondominium({ history, route }) {
  const { loggedContext } = useCurrentLoggedContext();
  const { toast } = useSwal();
  const { t } = useTranslation('guided-attendance-settings');

  const schema = yup.object({
    condominium: yup.object().shape({
      contextId: yup.number().required(t('common:feedback.required-field')),
      label: yup.string(),
    }),
    eventType: yup.object().shape({
      value: yup.number().required(t('common:feedback.required-field')),
      label: yup.string(),
    }),
  });

  const eventOptions = [
    { value: 900, label: `900 - ${t('event:900')}` },
    { value: 102, label: `102 - ${t('event:102')}` },
    { value: 810, label: `810 - ${t('event:810')}` },
  ];

  const [enable, { loading }] = useMutation(mutation.enableCondominium, {
    onCompleted: () => {
      history.push('/guided-attendance-settings');
      toast.fire({
        title: t('register.success'),
        icon: 'success',
      });
    },
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
        handleReset(); // eslint-disable-line
      }
    },
  });

  const onSubmit = values => {
    enable({
      variables: {
        guidedAttendanceSettings: {
          condominiumPersonContextId: values.condominium.contextId,
          eventType: values.eventType.value,
        },
      },
    });
  };

  const {
    handleSubmit,
    values,
    setFieldValue,
    errors,
    touched,
    handleReset,
  } = useFormik({
    validationSchema: schema,
    initialValues: {
      condominium: '',
      eventType: '',
    },
    onSubmit,
  });

  return (
    <>
      <PageHeader
        breadcrumb={route.breadcrumb}
        t={t}
        title={t('register.header-title')}
      />
      <S.Container>
        <S.Form onSubmit={handleSubmit}>
          <Flex w="100%" gridGap="16px">
            <Flex flexDirection="column">
              <Label>{t('register.select-condominium')}</Label>
              <PersonSelect
                width="300px"
                placeholder={t('register.select-condominium')}
                name="sourceNodeId"
                sourceNodeId={loggedContext.topNodeId}
                fieldName={['condominium']}
                value={values?.condominium}
                onChange={e => setFieldValue('condominium', e)}
                invalid={touched?.condominium && !!errors?.condominium}
                getOptionValue={option => option?.contextId}
                getOptionLabel={option =>
                  `${option.personContextId} - ${option.label}`
                }
              />

              {!!touched?.condominium && !!errors?.condominium && (
                <ErrorMessage>{errors.condominium?.contextId}</ErrorMessage>
              )}
            </Flex>
            <Flex flexDirection="column">
              <Label>{t('register.select-event')}</Label>
              <Select
                options={eventOptions}
                isClearable
                name="eventType"
                onChange={e => setFieldValue('eventType', e)}
                value={values?.eventType}
                placeholder={t('register.select-event')}
                loading={false}
                invalid={touched.eventType && !!errors.eventType}
              />
              {!!touched.eventType && !!errors.eventType && (
                <ErrorMessage>{errors.eventType?.value}</ErrorMessage>
              )}
            </Flex>
          </Flex>
          <Flex w="100%" justifyContent="flex-end">
            <Button color="primary" type="submit" disabled={loading}>
              {loading ? t('buttons:saving') : t('enable-condominium')}
            </Button>
          </Flex>
        </S.Form>
      </S.Container>
    </>
  );
}

export default GuidedAttendanceSettingsEnableCondominium;
GuidedAttendanceSettingsEnableCondominium.propTypes = {
  route: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
};
