import { ErrorMessage, Label, Select } from '@kiper/ui';
import { useEffect, useState, useMemo } from 'react';
import propTypes from 'prop-types';
import { useLazyQuery } from 'react-apollo';
import { accessFingerprintCondominiumsV2 as query } from '@kiper/monitoring-graphql/fingerprint';
import { FormGroup } from './styles';
import { accessTypesDict } from '../../constants';

const PREFIX = 'put.devices.modal.insert';

const fingerprintsTypesEnum = {
  VIRDI: 'fpTerminal',
};

const UserDeviceFingerprint = ({
  formik,
  t,
  personId,
  isEdition,
  devicesValues,
}) => {
  const { values, setFieldValue, errors, touched } = formik;
  const [condominiums, setCondominiums] = useState([]);
  const [fpPlaces, setFpPlaces] = useState([]);

  const [fetch, { loading }] = useLazyQuery(query, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ accessFingerprintCondominiumsV2 }) => {
      const formattedCondominiums = accessFingerprintCondominiumsV2.map(
        condo => {
          const formattedPlaces = [];
          if (condo?.places?.length) {
            condo.places.forEach(place => {
              if (place?.devices?.length) {
                place.devices.forEach(device =>
                  formattedPlaces.push({
                    deviceId: +device.id,
                    concatName: `${place.name} - ${device.name}`,
                    model: device.model,
                  }),
                );
              }
              return formattedPlaces;
            });
          }
          return {
            ...condo,
            places: formattedPlaces,
          };
        },
      );

      setCondominiums(formattedCondominiums);
    },
  });

  useEffect(() => {
    fetch({
      variables: {
        personId,
      },
    });
  }, []);

  useEffect(() => {
    if (values?.fingerprintCondominium) {
      const fingerprintPlaces = values?.fingerprintCondominium?.places;
      setFpPlaces(fingerprintPlaces);
      if (fingerprintPlaces.length === 1) {
        setFieldValue('fingerprintPlace', fingerprintPlaces[0]);
      }
    }
  }, [values?.fingerprintCondominium]);

  useEffect(() => {
    if (isEdition && !!condominiums.length) {
      // Resgata condominio através do nome caso for uma edição
      const condoFound = condominiums.find(
        condo => condo.condominiumName === values.value,
      );
      if (condoFound) {
        setFieldValue('fingerprintCondominium', condoFound);
      }
    }

    if (condominiums.length === 1 && !isEdition) {
      setFieldValue('fingerprintCondominium', condominiums[0]);
    }
  }, [condominiums]);

  // Resgata todos os places que são VIRDI e que possuem biometria VIRDI cadastrada
  const disabledPlaceOptions = useMemo(() => {
    if (!isEdition && !!fpPlaces.length && !!devicesValues.length) {
      const findPlacesAlreadyRegisteredInCondominium = devicesValues.filter(
        deviceValue =>
          deviceValue.type === accessTypesDict.fingerPrint &&
          deviceValue.value === values?.fingerprintCondominium?.condominiumName,
      );

      if (findPlacesAlreadyRegisteredInCondominium.length) {
        const disabledFpPlaces = fpPlaces.reduce((accPlaces, currFpPlace) => {
          const isVirdiFp = currFpPlace.model === fingerprintsTypesEnum.VIRDI;
          if (isVirdiFp) {
            accPlaces.push(currFpPlace.concatName);
          }
          return accPlaces;
        }, []);

        return disabledFpPlaces;
      }
    }
    return [];
  }, [devicesValues, values?.fingerprintPlace, fpPlaces]);

  const handleChangeCondominium = e => {
    setFieldValue('fingerprintCondominium', e);
    if (values?.fingerprintPlace) setFieldValue('fingerprintPlace', null);
  };

  return (
    <>
      <FormGroup>
        <Label htmlFor="condominium" block info>
          {t(`${PREFIX}.condominium`)}
        </Label>
        <Select
          width="100%"
          placeholder={t(`${PREFIX}.condominium-placeholder`)}
          name="fingerprintCondominium"
          options={condominiums}
          value={values?.fingerprintCondominium}
          onChange={handleChangeCondominium}
          isLoading={loading}
          invalid={errors?.fingerprintCondominium}
          getOptionValue={option => option.condominiumId}
          getOptionLabel={option => option.condominiumName}
          isDisabled={isEdition && !!values?.fingerprintCondominium}
        />
        {errors?.fingerprintCondominium && (
          <ErrorMessage>{t('common:feedback.required-field')}</ErrorMessage>
        )}
      </FormGroup>
      <FormGroup>
        <Label htmlFor="condominium" block info>
          {t('put.devices.modal.insert.environments')}
        </Label>
        <Select
          width="100%"
          placeholder={t(`${PREFIX}.places-placeholder`)}
          name="fingerprintPlace"
          options={fpPlaces}
          value={values?.fingerprintPlace}
          onChange={e => setFieldValue('fingerprintPlace', e)}
          invalid={errors?.fingerprintPlace}
          isDisabled={!fpPlaces.length}
          getOptionValue={option => option.deviceId}
          getOptionLabel={option => option.concatName}
          isOptionDisabled={option =>
            !!disabledPlaceOptions?.length &&
            option.concatName.includes(disabledPlaceOptions)
          }
        />
        {!!touched?.fingerprintPlace && !!errors?.fingerprintPlace && (
          <ErrorMessage>{t('common:feedback.required-field')}</ErrorMessage>
        )}
      </FormGroup>
    </>
  );
};

export default UserDeviceFingerprint;

UserDeviceFingerprint.propTypes = {
  formik: propTypes.shape({
    values: propTypes.object,
    errors: propTypes.object,
    touched: propTypes.object,
    setFieldValue: propTypes.func,
  }).isRequired,
  t: propTypes.func.isRequired,
  personId: propTypes.number.isRequired,
  isEdition: propTypes.bool.isRequired,
  devicesValues: propTypes.array,
};

UserDeviceFingerprint.defaultProps = {
  devicesValues: [],
};
