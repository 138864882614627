import styled from 'styled-components';
import { Flex as flex, Text as text } from '@kiper/ui';

export const Container = styled(flex)`
  padding: 8px;
  width: 100%;
`;

export const Wrapper = styled(flex)`
  width: 100%;
  padding: 16px 8px;
  border-radius: 4px;
  align-items: center;
  gap: 8px;
  background-color: ${props => props.theme.colors.warning100};
`;

export const Text = styled(text)`
  font-family: Lato;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${props => props.theme.colors.highBlack};
`;
