import styled from 'styled-components';
import {
  Card as card,
  Label as label,
  KiperButton as button,
  Badge as badge,
} from '@kiper/ui';
import { Col as col } from 'reactstrap';
import { MdEdit } from 'react-icons/md';
import { FiTrash } from 'react-icons/fi';

export const Button = styled(button)`
  margin-left: 10px;
`;

export const CancelButton = styled(Button)`
  color: ${props => props.theme.colors.secondary800};
`;

export const Card = styled(card)`
  width: 660px;
  margin: 0;
`;

export const CardHeader = styled(card.Header)`
  padding: 20px 10px;
  background: transparent;
`;

export const CardTitle = styled(card.Title)``;

export const CardBody = styled(card.Body)``;

export const Label = styled(label)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
`;

export const Col = styled(col)`
  margin: 16px 0;
`;

export const AddMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  background-color: inherit;
  border: ${props =>
    `1px ${props.theme.colors.secondary400} ${
      props.device ? 'solid' : 'dashed'
    }`};

  height: 222px;
  padding: 16px;
  gap: 16px;
`;

export const ItemActionsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

export const EditIcon = styled(MdEdit)`
  cursor: pointer;
`;

export const RemoveIcon = styled(FiTrash)`
  cursor: pointer;
`;

export const WrapContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ServerName = styled(Label)`
  font-size: 14px;
  font-weight: 700;
  color: ${props => props.theme.colors.mediumBlack};
  margin: 0;
`;

export const ServerAddress = styled(Label)`
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.colors.mediumBlack};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  margin: 0;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 8px;
`;

export const Badge = styled(badge)`
  padding: 6px;
  margin: 8px 0 0;
`;

export const BadgeLabel = styled.span`
  color: ${props => props.theme.colors.white};
  line-height: 1;
`;
