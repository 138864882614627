import { useContext, useMemo, useState } from 'react';
import { Divider, MdIcons } from '@kiper/ui';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EventAttendanceContext } from '../../store';
import SimplifiedEventCard from './SimplifiedEventCard';

import * as S from './styles';

const SimplifiedEventList = () => {
  const {
    eventQueue,
    eventInAttendance,
    filteredByCondominiumContextId,
    handleClearEventFilter,
  } = useContext(EventAttendanceContext);

  const [eventOnHover, setEventOnHover] = useState(null);
  const [buttonFilterOnHover, setButtonFilterOnHover] = useState(false);
  const history = useHistory();
  const { t } = useTranslation('guided-attendance');
  const isFallback = history?.location?.pathname.includes('fallback');

  const { events, inAttendance } = useMemo(
    () => ({
      events: eventQueue,
      inAttendance: !!eventInAttendance && eventInAttendance,
    }),
    [eventQueue],
  );

  return (
    <S.Container fallback={+isFallback}>
      <MdIcons color="greenSurface" mdIconName="support_agent" />
      <S.SimplifiedEventListWrapper
        hovered={+!!eventOnHover || +!!buttonFilterOnHover}
      >
        <SimplifiedEventCard
          key={inAttendance?.eventId}
          event={inAttendance}
          eventOnHover={eventOnHover}
          setEventOnHover={setEventOnHover}
        />
        <Divider />
        {events?.map(event => (
          <SimplifiedEventCard
            key={event?.eventId}
            event={event}
            eventOnHover={eventOnHover}
            setEventOnHover={setEventOnHover}
          />
        ))}
        {filteredByCondominiumContextId && (
          <S.Button
            onMouseEnter={() => setButtonFilterOnHover(true)}
            onMouseLeave={() => setButtonFilterOnHover(false)}
            onClick={handleClearEventFilter}
            color="violet"
            size="sm"
            block={!buttonFilterOnHover}
            icon={<MdIcons mdIconName="close" size="16px" color="white" />}
          >
            {buttonFilterOnHover && t('event-list.remove-filter')}
          </S.Button>
        )}
      </S.SimplifiedEventListWrapper>
    </S.Container>
  );
};

export default SimplifiedEventList;
