import propTypes from 'prop-types';
import { useFormikContext, ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from 'react-apollo';
import { useSwal } from '@kiper/hooks';
import {
  Input as DebouncedInput,
  Label,
  Tooltip,
  ErrorMessage as ErrorMessageComponent,
} from '@kiper/ui';
import { person as personGql } from '@kiper/monitoring-graphql';
import { FormGroup, ToolTipIcon, ToolTipContainer } from './styles';

const KiperEmailInput = ({
  disabled,
  checkPerson,
  showToolTip,
  onRedirectClick,
  sourceTreeNodeId,
  ...props
}) => {
  const [t] = useTranslation('common');
  const { confirm } = useSwal();
  const { values, setFieldValue, errors, touched } = useFormikContext();

  const [checkPersonExist] = useLazyQuery(personGql.checkPersonExist, {
    fetchPolicy: 'no-cache',
    onCompleted: async ({ checkPersonExist: userInfo }) => {
      const shouldRedirect = await confirm({
        title: t('kiper-email-input.existing-user.title', {
          userName: userInfo.name,
        }),
        text: t('kiper-email-input.existing-user.text'),
        cancelButtonText: t('kiper-email-input.existing-user.cancel'),
        confirmButtonText: t('kiper-email-input.existing-user.confirm'),
      });

      if (shouldRedirect) onRedirectClick(userInfo);
      else setFieldValue('email', '');
    },
  });

  return (
    <FormGroup>
      <ToolTipContainer>
        <Label info block>
          {t('kiper-email-input.email')}
        </Label>
        {showToolTip && !disabled ? (
          <>
            <ToolTipIcon id="emailToolTip">?</ToolTipIcon>
            <Tooltip placement="right" target="emailToolTip">
              {t('kiper-email-input.tooltip.email')}
            </Tooltip>
          </>
        ) : null}
      </ToolTipContainer>
      {disabled ? (
        <Label>{values.email}</Label>
      ) : (
        <>
          <DebouncedInput
            className="form-control"
            type="email"
            name="email"
            id="email"
            onChange={setFieldValue}
            value={values.email}
            placeholder={t('kiper-email-input.placeholders.email')}
            delay={1000}
            invalid={errors && errors.email && touched && touched.email}
            onBlur={event => {
              if (checkPerson)
                checkPersonExist({
                  variables: {
                    email: event.target.value,
                    sourceTreeNodeId,
                  },
                });
            }}
            {...props}
          />
          <ErrorMessage component={ErrorMessageComponent} name="email" />
        </>
      )}
    </FormGroup>
  );
};

KiperEmailInput.propTypes = {
  onRedirectClick: propTypes.func.isRequired,
  sourceTreeNodeId: propTypes.number,
  disabled: propTypes.bool,
  showToolTip: propTypes.bool,
  checkPerson: propTypes.bool,
};

KiperEmailInput.defaultProps = {
  sourceTreeNodeId: null,
  disabled: false,
  showToolTip: false,
  checkPerson: true,
};

export default KiperEmailInput;
