import { useCallback, useEffect, useRef, useState } from 'react';
import { useLazyQuery } from 'react-apollo';

const useInfiniteScroll = (
  query,
  variables,
  onCompletedCallback,
  onErrorCallback,
) => {
  const [state, setState] = useState([]);
  const [loader, setLoader] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);

  const observer = useRef();

  const [fetchMore] = useLazyQuery(query, {
    fetchPolicy: 'no-cache',
    onCompleted: res => {
      setState(prevData => {
        if (!prevData) return [];
        if (prevData?.length < 1) return Object.values(res)[0]?.collection;

        if (Object.values(res)[0]?.collection?.length) {
          const newArray = [
            ...new Set([...prevData, ...Object.values(res)[0]?.collection]),
          ];

          return newArray;
        }

        return prevData;
      });

      const pagination = Object.values(res)[0]?.pagination;
      if (pagination?.hasNextPage) setHasMore(true);
      else setHasMore(pagination?.totalPages > pagination?.page);

      if (onCompletedCallback) onCompletedCallback(res);

      setLoader(false);
    },
    onError: err => {
      if (onErrorCallback) onErrorCallback(err);

      setLoader(false);
    },
  });

  useEffect(() => {
    setLoader(true);
    if (variables)
      fetchMore({
        variables: {
          ...variables,
          filters: { ...variables.filters, page },
        },
      });
  }, [
    page,
    variables?.filters?.searchText,
    variables?.filters?.sourceNodeId,
    variables?.filters?.searchName,
    variables?.filters?.unityId,
  ]);

  useEffect(() => {
    setState([]);
    setPage(1);
  }, [
    variables?.filters?.searchText,
    variables?.filters?.sourceNodeId,
    variables?.filters?.searchName,
    variables?.filters?.unityId,
  ]);

  const lastBookElementRef = useCallback(
    node => {
      if (loader) return;

      if (!hasMore) return;

      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          setPage(f => f + 1);
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [loader, hasMore],
  );

  return {
    state,
    loader,
    hasMore,
    lastBookElementRef,
  };
};

export default useInfiniteScroll;
