import { useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useFormikContext, FieldArray } from 'formik';
import { useTheme } from 'styled-components';

import { MdAdd } from 'react-icons/md';

import { Flex, KiperButton, Text } from '@kiper/ui';

import propTypes from 'prop-types';
import AccessData from './AccessData';
import { scrollTo } from '../helpers';

export default function Accesses({ isEdition, formRef, ...props }) {
  const [t] = useTranslation('user');
  const { values, setFieldValue } = useFormikContext();
  const theme = useTheme();

  const testeRef = useRef(null);

  useEffect(() => {
    if (!isEdition) {
      setFieldValue('accesses', [
        ...values.accesses,
        {
          profile: '',
          isBlocked: false,
          blockedReason: '',
          blockedByPersonContextId: 0,
          condominium: '',
          unityGroup: '',
          unity: '',
          accessProfiles: [''],
          placeTemporaryAccess: [],
          allowedAccesses: [],
          contactSchedule: [],
          permissions: [],
          accessibilityTime: 3,
          callPriority: 1,
          observation: '',
        },
      ]);
    }
  }, [isEdition]);

  return (
    <Flex
      mt="10px"
      ref={testeRef}
      bg="white"
      borderRadius="8px"
      flex={1}
      flexDirection="column"
      py="22px"
      border={`1px solid ${theme.colors.secondary200}`}
    >
      <Flex
        px="15px"
        pb="10px"
        borderBottom={`1px solid ${theme.colors.secondary200}`}
        mb="10px"
        alignItems="center"
        justifyContent="space-between"
        flex={1}
      >
        <Text fontSize="16px" fontWeight="500">
          {t('put.access-data.title')}
        </Text>
        <KiperButton
          onClick={() => {
            setFieldValue(
              'accesses',
              values.accesses.concat({
                profile: '',
                isBlocked: false,
                blockedReason: '',
                blockedByPersonContextId: 0,
                condominium: '',
                unityGroup: '',
                unity: '',
                accessProfiles: [''],
                placeTemporaryAccess: [],
                allowedAccesses: [],
                contactSchedule: [],
                permissions: [],
                accessibilityTime: 3,
                callPriority: 1,
                observation: '',
              }),
            );
            setTimeout(() => scrollTo(testeRef), 100);
          }}
          rightIcon={<MdAdd />}
        >
          {t('put.access-data.add')}
        </KiperButton>
      </Flex>
      <Flex width="100%" p="16px" gridGap="8px" flexDirection="column">
        <FieldArray
          name="accesses"
          render={({ remove, replace }) => (
            <>
              {values.accesses &&
                values.accesses.map((access, index) => (
                  <AccessData
                    key={access.id ?? `${JSON.stringify}_${index}`}
                    onRemove={() => remove(index)}
                    onChange={args => replace(index, args)}
                    access={access}
                    indexAccesses={index}
                    name={`accesses[${index}]`}
                    isEdition={isEdition}
                    formRef={formRef}
                    {...props}
                  />
                ))}
            </>
          )}
        />
      </Flex>
    </Flex>
  );
}

Accesses.propTypes = {
  isEdition: propTypes.bool.isRequired,
  formRef: propTypes.object,
};

Accesses.defaultProps = {
  formRef: () => {},
};
