import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import {
  getUnitCallPriority,
  getCondominiumCallPriority,
} from '@kiper/monitoring-graphql/guided_attendance';

const contactTypes = {
  UNITY: 'unit',
  CONDOMINIUM: 'condominium',
};

const useQueries = ({ unityId, condominiumPersonContextId }) => {
  const [typeOfContacts, setTypeOfContacts] = useState(null);
  const [callPriorities, setCallPriorities] = useState([]);

  const [
    getUnitCallPriorities,
    { loading: loadingUnityCallPriority },
  ] = useLazyQuery(getUnitCallPriority, {
    fetchPolicy: 'network-only',
    onCompleted: ({ unitiesCallPriority }) => {
      if (unitiesCallPriority?.length) setCallPriorities(unitiesCallPriority);
    },
  });

  const [
    getCondominiumCallPriorities,
    { loading: loadingCondominiumCallPriority },
  ] = useLazyQuery(getCondominiumCallPriority, {
    fetchPolicy: 'network-only',
    onCompleted: ({ condominiumCallPriority }) => {
      if (condominiumCallPriority?.length)
        setCallPriorities(condominiumCallPriority);
    },
  });

  const loading = useMemo(
    () => loadingUnityCallPriority || loadingCondominiumCallPriority,
    [loadingUnityCallPriority, loadingCondominiumCallPriority],
  );

  const handleGetUnityCallPriority = () => {
    setTypeOfContacts(contactTypes.UNITY);
    getUnitCallPriorities({
      variables: {
        unityId,
      },
    });
  };

  const handleGetCondominiumCallPriority = () => {
    setTypeOfContacts(contactTypes.CONDOMINIUM);
    getCondominiumCallPriorities({
      variables: {
        condominiumPersonContextId,
      },
    });
  };

  const handleGetCallPriorities = contactType => {
    if (contactType === typeOfContacts) return;
    setCallPriorities([]);
    switch (contactType) {
      case contactTypes.UNITY:
        handleGetUnityCallPriority();
        break;
      case contactTypes.CONDOMINIUM:
        handleGetCondominiumCallPriority();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!typeOfContacts) {
      handleGetUnityCallPriority();
    }
  }, []);

  return {
    callPriorities,
    loading,
    handleGetCallPriorities,
    contactTypes,
    typeOfContacts,
  };
};

export default useQueries;
