import propTypes from 'prop-types';
import { Badge, Tooltip, KiperButton } from '@kiper/ui';
import { useMutation } from 'react-apollo';
import { FaTimes, FaCheck } from 'react-icons/fa';
import { MdDelete, MdEdit } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { useSwal } from '@kiper/hooks';
import { dweller as dwellerGql } from '@kiper/monitoring-graphql';
import { useTranslation } from 'react-i18next';
import { apolloErrorHandler } from '@kiper/fns';
import { Label, Div, Line, SyncStatus } from './styles';
import { profileTypes, profileColor } from '../../../../constants';
import PersonSync from '../../../../components/PersonSync';
import AccessTypes from '../../../../components/AccessTypes';
import UserInfo from '../../../../components/UserInfo';

const ListItem = ({ data, onSync }) => {
  const location = useLocation();

  const [t] = useTranslation('user');
  const { confirm, toast } = useSwal();
  const [deleteAction, { loading }] = useMutation(
    dwellerGql.detail.mutations.deleteAccess,
    {
      variables: {
        personContextId: Number(data.personContextId),
      },
      onCompleted: () => onSync(),
      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length)
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      },
    },
  );

  const onDelete = async () => {
    const deleted = await confirm({
      title: t('put.access-data.modal.delete.title'),
      text: t('put.access-data.modal.delete.text'),
      cancelButtonText: t('put.access-data.modal.delete.cancel'),
      confirmButtonText: t('put.access-data.modal.delete.confirm'),
    });

    if (deleted) deleteAction();
  };

  return (
    <Line>
      <td align="left">{data.condominiumName}</td>
      <td align="left">
        <Label block>{data.unityGroupName}</Label>
        <Label block>{data.unityName}</Label>
      </td>
      <td align="left">
        <UserInfo user={data} />
      </td>
      <td align="left">
        {data.profileFieldName && (
          <Badge color={profileColor[data.profileFieldName]}>
            {t(`common:profiles.${profileTypes[data.profileFieldName]}`)}
          </Badge>
        )}
      </td>
      <td align="center">
        <AccessTypes {...data} />
      </td>
      <td align="center">
        {data.syncStatus === 2 ? (
          <>
            <SyncStatus synchronized id={`syncStatus${data.personContextId}`}>
              <FaCheck />
            </SyncStatus>
            <Tooltip
              placement="top"
              target={`syncStatus${data.personContextId}`}
            >
              {t('list.user-synced')}
            </Tooltip>
          </>
        ) : (
          <>
            <SyncStatus id={`syncStatus${data.personContextId}`}>
              <FaTimes />
            </SyncStatus>
            <Tooltip
              placement="top"
              target={`syncStatus${data.personContextId}`}
            >
              {t('list.user-not-synced')}
            </Tooltip>
          </>
        )}
      </td>
      <td align="center">
        <Div>
          <PersonSync
            personContextId={Number(data.personContextId)}
            syncStatus={data.syncStatus}
            onSync={onSync}
          />
          <KiperButton
            icon={<MdDelete />}
            color="danger"
            onClick={onDelete}
            disabled={loading}
          />

          <Link
            to={{
              pathname: `/users/${data.personId}`,
              state: { fromSearch: location.search },
            }}
          >
            <KiperButton icon={<MdEdit />} />
          </Link>
        </Div>
      </td>
    </Line>
  );
};

export default ListItem;

ListItem.propTypes = {
  data: propTypes.object.isRequired,
  onSync: propTypes.func.isRequired,
};
