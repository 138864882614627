import { useState, useEffect, useMemo } from 'react';
import {
  Card,
  H5,
  ListGroupItem,
  ListGroup,
  Label,
  Input,
  Toggler,
  Slider,
  KiperButton,
  ErrorMessage,
} from '@kiper/ui';
import { MdRemove, MdAdd } from 'react-icons/md';
import { Col, FormGroup, Row, Form } from 'reactstrap';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormikProvider, useFormik, Field } from 'formik';
import * as yup from 'yup';
import {
  FlexJustifyCenter,
  FlexAlignCenter,
  Footer,
  Flex,
  Text,
  FooterActions,
} from './styles';
import CustomSelect from '../../../components/EventSelect';

export default function EventSettingsPutPage({
  t,
  onSubmit,
  createEventType,
  events = [],
  initialValue,
  onDelete,
  cancelUrl,
  origin,
  isEditable,
  AlertInfo,
  children,
  isDisabled,
}) {
  const [phrases, setPhrases] = useState([]);
  const [monitorEvent, setMonitorEvent] = useState(false);
  const [finishAnother, setFinishAnother] = useState(false);

  const filteredValues = initialValue;
  delete filteredValues?.routedTo;
  delete filteredValues?.configuredby;

  const initialValues = initialValue
    ? { ...filteredValues, shortCut: '', message: '' }
    : {
        eventType: createEventType,
        priority: 1,
        showPopUp: false,
        showAlert: false,
        automaticClose: true,
        automaticCloseMessage: '',
        isCritical: false,
        closeEvent: '',
        personContextId: null,
        closeEventMessage: '',
        attendantScript: '',
        attendantDefaultMessages: [],
        shortCut: '',
        message: '',
      };

  const validationSchema = useMemo(
    () =>
      yup.object({
        shortCut: yup
          .string()
          .matches(/^[a-z0-9]+$/i, t('common:feedback.alphanumeric-field')),
        ...(finishAnother
          ? {
              closeEventMessage: yup
                .string()
                .required(t('common:feedback.required-field')),
              closeEvent: yup
                .mixed()
                .required(t('common:feedback.required-field')),
            }
          : {}),
        ...(monitorEvent
          ? {}
          : {
              automaticCloseMessage: yup
                .string()
                .nullable()
                .required(t('common:feedback.required-field')),
            }),
      }),
    [finishAnother, monitorEvent],
  );

  const formikBag = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ shortCut, message, ...values }) =>
      onSubmit({
        ...values,
        closeEvent: finishAnother ? values?.closeEvent : null,
        closeEventMessage: finishAnother ? values?.closeEventMessage : null,
        isCritical: monitorEvent ? values?.isCritical : false,
        showPopUp: monitorEvent ? values?.showPopUp : false,
        attendantScript: monitorEvent ? values?.attendantScript : null,
        automaticCloseMessage: !monitorEvent
          ? values?.automaticCloseMessage
          : null,
        attendantDefaultMessages:
          shortCut && message ? [...phrases, { shortCut, message }] : phrases,
      }),
  });

  const { values, errors, touched, handleSubmit, validateField } = formikBag;

  const handleChange = (field, value) => formikBag.setFieldValue(field, value);

  const addPhrase = async () => {
    await validateField('shortCut');

    if (errors.shortCut) return;

    const { shortCut, message } = values;

    setPhrases(x => [...x, { shortCut, message, delete: false }]);
    handleChange('shortCut', '');
    handleChange('message', '');
  };

  const removePhrase = phrase => {
    if (phrase.id) {
      setPhrases(x =>
        x.map(i => {
          return {
            ...i,
            delete: i.delete || JSON.stringify(phrase) === JSON.stringify(i),
          };
        }),
      );
    } else {
      setPhrases(x =>
        x.filter(i => JSON.stringify(phrase) !== JSON.stringify(i)),
      );
    }
  };

  const { isCritical, showPopUp } = values; // showAlert

  useEffect(() => {
    handleChange('automaticClose', !monitorEvent);
  }, [monitorEvent]);

  const handleChangeMonitorEvent = value => {
    setMonitorEvent(value);
    handleChange('automaticClose', value);
  };

  useEffect(() => {
    setMonitorEvent(!initialValues.automaticClose);
    setFinishAnother(!!initialValues.closeEvent);
    setPhrases(
      initialValues.attendantDefaultMessages.map(i => ({
        ...i,
        delete: false,
      })),
    );
  }, []);

  useEffect(() => {
    if (origin) {
      handleChange('personContextId', {
        ...origin,
        value: origin.contextId,
      });
    }
  }, [origin]);

  const getSelected = (arr, value) =>
    value &&
    arr &&
    !!arr.length &&
    (typeof value === 'number' || typeof value === 'string')
      ? arr.find(i => Number(i.value) === Number(value))
      : value;

  const addEventTypeLabel = ({ label, ...event }) => ({
    ...event,
    label: /^\d*\s-\s/.test(label) ? label : `${event.value} - ${label}`,
  });

  const eventType = getSelected(events, values.eventType);

  const filteredPhrases = useMemo(
    () => phrases.filter(i => !i.delete && !i.excludedDate),
    [phrases],
  );

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <FormikProvider value={formikBag}>
        <Card>
          <Card.Header>
            {children ? (
              <>
                <Col sm={3}>{children}</Col>
                <Col sm={9}>
                  <CustomSelect
                    name="eventType"
                    placeholder={t('put.select-event')}
                    data={events.map(addEventTypeLabel)}
                    onChange={e => handleChange('eventType', e)}
                    value={eventType ? addEventTypeLabel(eventType) : ''}
                    isEditable={isEditable}
                    isDisabled={isDisabled}
                  />
                </Col>
              </>
            ) : (
              <CustomSelect
                name="eventType"
                placeholder={t('put.select-event')}
                data={events.map(addEventTypeLabel)}
                onChange={e => handleChange('eventType', e)}
                value={eventType ? addEventTypeLabel(eventType) : ''}
                isEditable={isEditable}
                isDisabled={isDisabled}
              />
            )}
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                {origin && isEditable && (
                  <FormGroup>
                    <Label info block>
                      {t('put.origin')}
                    </Label>
                    <Label>{origin?.label}</Label>
                  </FormGroup>
                )}
                <FormGroup>
                  <Toggler
                    id="automaticClose"
                    label={t('put.monitor-this-event')}
                    onChange={() => handleChangeMonitorEvent(!monitorEvent)}
                    value={monitorEvent}
                    checked={monitorEvent}
                    disabled={!isEditable}
                  />
                </FormGroup>
                {!monitorEvent && (
                  <FormGroup>
                    <Label info>{t('put.automatic-close-placeholder')}</Label>
                    <textarea
                      id="automaticCloseMessage"
                      className="form-control"
                      placeholder={t('put.automatic-close-placeholder')}
                      rows={7}
                      onChange={e =>
                        handleChange('automaticCloseMessage', e.target.value)
                      }
                      value={values.automaticCloseMessage}
                      disabled={!isEditable}
                    />
                    {touched.automaticCloseMessage &&
                      !!errors.automaticCloseMessage && (
                        <ErrorMessage>
                          {errors.automaticCloseMessage}
                        </ErrorMessage>
                      )}
                  </FormGroup>
                )}
                {monitorEvent && (
                  <>
                    <FormGroup>
                      <>
                        <Label info htmlFor="priority">
                          {t('put.priority')}
                        </Label>
                        <Flex>
                          <Slider
                            id="priority"
                            min={1}
                            max={3}
                            className="d-block w-25"
                            onChange={e => {
                              const intValue = parseInt(e.target.value, 0);
                              handleChange('priority', intValue);
                            }}
                            value={values.priority}
                            disabled={!isEditable}
                          />
                          <Label className="ml-3">
                            {t(`list.priority-values.${values.priority}`)}
                          </Label>
                        </Flex>
                      </>
                    </FormGroup>
                    <FlexJustifyCenter>
                      <FormGroup>
                        <Toggler
                          id="isCritical"
                          label={t('put.critical-event')}
                          onChange={() =>
                            handleChange('isCritical', !isCritical)
                          }
                          value={isCritical}
                          checked={isCritical}
                          disabled={!isEditable}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Toggler
                          id="showPopUp"
                          label={t('put.notify-event')}
                          onChange={() => handleChange('showPopUp', !showPopUp)}
                          value={showPopUp}
                          checked={showPopUp}
                          disabled={!isEditable}
                        />
                      </FormGroup>
                    </FlexJustifyCenter>
                    <FormGroup>
                      <Label info>{t('put.how-to-act-event')}</Label>
                      <textarea
                        id="attendantScript"
                        name="attendantScript"
                        className="form-control"
                        placeholder={t('put.attendance-info-placeholder')}
                        rows={7}
                        onChange={e =>
                          handleChange('attendantScript', e.target.value)
                        }
                        value={values.attendantScript}
                        disabled={!isEditable}
                      />
                    </FormGroup>
                  </>
                )}
              </Col>
              <Col>
                {!!AlertInfo && isEditable && (
                  <AlertInfo value={values.personContextId} />
                )}
                <FormGroup>
                  <Toggler
                    id="closeEvent"
                    label={t('put.this-event-ends-another')}
                    onChange={() => setFinishAnother(!finishAnother)}
                    value={finishAnother}
                    checked={finishAnother}
                    disabled={!isEditable}
                  />
                </FormGroup>
                {finishAnother && (
                  <>
                    <FormGroup>
                      <Label info>{t('put.select-event-linked')}</Label>
                      <CustomSelect
                        placeholder={t('put.select-event-linked')}
                        data={events.map(addEventTypeLabel)}
                        onChange={e => handleChange('closeEvent', e)}
                        name="closeEvent"
                        value={getSelected(events, values.closeEvent)}
                        isEditable={isEditable}
                      />
                      {touched.closeEvent && !!errors.closeEvent && (
                        <ErrorMessage>{errors.closeEvent}</ErrorMessage>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label info>{t('put.close-with-message')}</Label>
                      <textarea
                        id="closeEventMessage"
                        className="form-control"
                        placeholder={t('put.close-with-message-placeholder')}
                        rows={3}
                        onChange={e =>
                          handleChange('closeEventMessage', e.target.value)
                        }
                        value={values.closeEventMessage}
                        disabled={!isEditable}
                      />
                      {touched.closeEventMessage &&
                        !!errors.closeEventMessage && (
                          <ErrorMessage>
                            {errors.closeEventMessage}
                          </ErrorMessage>
                        )}
                    </FormGroup>
                  </>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {monitorEvent && (
          <Card>
            <Card.Header>
              <H5 className="card-title mb-0">{t('put.record-shortcuts')}</H5>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem header>
                  <Col sm={2}>{t('put.shortcut')}</Col>
                  <Col>{t('put.phrase')}</Col>
                </ListGroupItem>
                <ListGroupItem header>
                  <FormGroup className="col-2 mb-0">
                    <Field name="shortCut">
                      {({ field }) => (
                        <Input
                          {...field}
                          onChange={handleChange}
                          disabled={!isEditable}
                        />
                      )}
                    </Field>
                    {touched.shortCut && !!errors.shortCut && (
                      <ErrorMessage>{errors.shortCut}</ErrorMessage>
                    )}
                  </FormGroup>
                  <FormGroup className="col mb-0">
                    <Input
                      name="message"
                      value={values.message}
                      onChange={handleChange}
                      disabled={!isEditable}
                    />
                  </FormGroup>
                  <FlexAlignCenter className="m-0 p-0">
                    <KiperButton
                      type="button"
                      onClick={addPhrase}
                      disabled={!values.shortCut}
                      icon={<MdAdd />}
                    />
                  </FlexAlignCenter>
                </ListGroupItem>
                {filteredPhrases.map((item, index) => (
                  <ListGroupItem key={index}>
                    <Col sm={2} className="text-center">
                      {item.shortCut}
                    </Col>
                    <Col className="text-left">
                      <Text>{item.message}</Text>
                    </Col>
                    <Col className="text-right">
                      <KiperButton
                        color="danger"
                        type="button"
                        onClick={() => removePhrase(item)}
                        disabled={!isEditable}
                        icon={<MdRemove />}
                      />
                    </Col>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        )}
      </FormikProvider>
      {isEditable && (
        <Footer>
          <KiperButton
            outline
            type="button"
            color="secondary"
            onClick={onDelete}
            disabled={!onDelete}
          >
            {t('put.delete')}
          </KiperButton>
          <FooterActions>
            <Link to={cancelUrl}>
              <KiperButton
                variant="out"
                color="secondary"
                type="button"
                style={{ marginRight: '10px' }}
              >
                {t('put.cancel-button')}
              </KiperButton>
            </Link>
            <KiperButton
              type="submit"
              color="primary"
              loading={formikBag.isSubmitting}
              disabled={formikBag.isSubmitting || !isEditable}
            >
              {formikBag.isSubmitting ? t('buttons:saving') : t('buttons:save')}
            </KiperButton>
          </FooterActions>
        </Footer>
      )}
    </Form>
  );
}

EventSettingsPutPage.defaultProps = {
  onDelete: null,
  initialValue: null,
};

EventSettingsPutPage.propTypes = {
  t: propTypes.func.isRequired,
  initialValue: propTypes.object,
  onSubmit: propTypes.func.isRequired,
  createEventType: propTypes.number,
  events: propTypes.array.isRequired,
  onDelete: propTypes.func,
  cancelUrl: propTypes.string.isRequired,
  origin: propTypes.object,
  AlertInfo: propTypes.func,
  isEditable: propTypes.bool,
  children: propTypes.object,
  isDisabled: propTypes.bool,
};

EventSettingsPutPage.defaultProps = {
  isEditable: true,
  AlertInfo: null,
  origin: null,
  children: null,
  isDisabled: false,
  createEventType: null,
};
