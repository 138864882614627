import propTypes from 'prop-types';
import { Flex, Spinner, Text } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import HorizontalFilterOptions from '../../../../HorizontalFilterOptions';
import { useGetTriages } from './hooks';
import { sendAmplitudeData } from '../../../../../services/amplitude';
import { Title } from './styles';

const translatePrefix = (t, key) =>
  t(`quick-menu.condominium-rule-questions-awswers.filter.${key}`);

const TriageFilter = ({ filteredTriage, setFilterContext }) => {
  const { t } = useTranslation('guided-attendance');
  const { triages, loading } = useGetTriages({
    filteredTriage,
  });

  const handleChange = triage => {
    if (triage?.id !== filteredTriage?.id) {
      setFilterContext({
        filteredTriage: triage,
        filteredSubtriage: null,
      });

      sendAmplitudeData('condominium rules filter: triage changed', triage);
    }
  };

  return (
    <Flex flexDirection="column" width="100%">
      <Title>{translatePrefix(t, 'triages')}</Title>
      {loading && (
        <Flex width="100%" alignItems="center">
          <Spinner size={24} />
        </Flex>
      )}
      {!loading && (
        <>
          {triages?.length ? (
            <HorizontalFilterOptions
              options={triages}
              handleChange={handleChange}
              optionSelected={filteredTriage}
            />
          ) : (
            <Text>{translatePrefix(t, 'triages-not-found')}</Text>
          )}
        </>
      )}
    </Flex>
  );
};

export default TriageFilter;

TriageFilter.propTypes = {
  filteredTriage: propTypes.object,
  setFilterContext: propTypes.func.isRequired,
};

TriageFilter.defaultProps = {
  filteredTriage: null,
};
