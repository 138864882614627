import { useMemo } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FaRegCalendarCheck, FaRegCalendarTimes } from 'react-icons/fa';
import { formatDate, compareDateFromNow } from '@kiper/fns';
import { useHistory } from 'react-router-dom';
import { inviteColor } from '../../../../constants';
import UserInfo from '../../../../components/UserInfo';

import { Row, Span, Label } from './styles';

const status = x => `status.${x}`;

const ListItem = ({ data }) => {
  const [t] = useTranslation('invite');
  const inviteEnd = useMemo(() => new Date(data.end), [data.end]);
  const history = useHistory();
  return (
    <Row onClick={() => history.push(`/invites/${data.id}`)}>
      <td align="left">{data.condominium && data.condominium.name}</td>
      <td align="left">
        <UserInfo user={data.host} />
      </td>
      <td align="left">
        <Label>{data.name}</Label>
        {compareDateFromNow(inviteEnd).isAfter ? (
          <Span color={inviteColor.status.active}>
            <FaRegCalendarCheck size={15} />
            {t(status('active'))}
          </Span>
        ) : (
          <Span color={inviteColor.status.inactive}>
            <FaRegCalendarTimes size={15} />
            {t(status('inactive'))}
          </Span>
        )}
      </td>
      <td align="left">
        <Label>{formatDate(data.start).formatted}</Label>
        <Label>{formatDate(data.start, 'HH:mm').formatted}</Label>
      </td>
      <td align="left">
        <Label>{formatDate(data.end).formatted}</Label>
        <Label>{formatDate(data.end, 'HH:mm').formatted}</Label>
      </td>
    </Row>
  );
};

export default ListItem;

ListItem.propTypes = {
  data: propTypes.object.isRequired,
};
