import { useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Flex, Text, KiperButton, ErrorMessage } from '@kiper/ui';
import { useTranslation, Trans } from 'react-i18next';
import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { mutations } from '@kiper/monitoring-graphql/condominium/detail';
import { auth } from '@kiper/monitoring-graphql';
import { useMutation } from 'react-apollo';
import useCurrentLoggedContext from '../../../../hooks/useCurrentLoggedContext';

import { sendAmplitudeData } from '../../../../services/amplitude';

import { UnlinkContainer, Label, Input } from './styles';

function TelephonyLink({ handleCancel }) {
  const [t] = useTranslation('modals/my-settings');
  const [state, setState] = useState(null);
  const { loggedContext } = useCurrentLoggedContext();
  const { toast } = useSwal();

  const kiperVoiceAgent = useMemo(
    () =>
      loggedContext?.personContextParams?.find(
        x => x.name === 'kiperVoiceAgent',
      ),
    [],
  );

  const [onCreate, { loading }] = useMutation(
    mutations.personContextParamsCreate,
    {
      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length) {
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
        }
      },
      onCompleted: ({ personContextParamsCreate }) => {
        toast.fire({
          icon: 'success',
          title: t('common:save-success'),
        });
        const { id, value } = personContextParamsCreate;
        setState({
          ...personContextParamsCreate,
          id: +id,
          value: JSON.parse(value),
        });

        sendAmplitudeData('link pabx', {
          user: loggedContext?.email,
          partner: loggedContext?.partner?.name,
          pabx: JSON.parse(personContextParamsCreate?.value)?.agentId,
        });
      },
      update: (cache, { data: { personContextParamsCreate } }) => {
        const queryArgs = {
          query: auth.currentLoggedContext,
        };
        try {
          const newContext = loggedContext;
          const {
            value,
            id,
            name,
            creationDate,
            lastUpdate,
          } = personContextParamsCreate;
          const newValue = {
            agentId: JSON.parse(value).agentId,
            extension: null,
            monitor: null,
            password: null,
            user: null,
            __typename: 'PersonContextParamsValue',
          };
          const newParams = {
            id: +id,
            name,
            value: newValue,
            creationDate,
            lastUpdate,
            __typename: 'PersonContextParams',
          };
          if (newContext.personContextParams?.length) {
            const index = newContext.personContextParams.findIndex(
              x => x.name === 'kiperVoiceAgent',
            );
            if (index >= 0) {
              newContext.personContextParams[index] = {
                ...newContext.personContextParams[index],
                id: +id,
                value: {
                  ...newContext.personContextParams[index].value,
                  kiperVoiceAgent: JSON.parse(value).agentId,
                },
              };
            } else {
              newContext.personContextParams = [
                ...newContext.personContextParams,
                newParams,
              ];
            }
          } else {
            newContext.personContextParams = [newParams];
          }
          return cache.writeQuery({
            ...queryArgs,
            data: {
              currentLoggedContext: newContext,
            },
          });
        } catch (err) {
          return err;
        }
      },
    },
  );

  const [onDelete, { loading: loadingUnlink }] = useMutation(
    mutations.personContextParamsDelete,
    {
      onCompleted: () => {
        toast.fire({
          icon: 'success',
          title: t('unlink-success'),
        });

        sendAmplitudeData('unlink pabx', {
          user: loggedContext?.email,
          partner: loggedContext?.partner?.name,
        });

        setState(null);
        setFieldValue('agent', ''); // eslint-disable-line
        setTouched({ agent: false }); // eslint-disable-line
      },
      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length) {
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
        }
      },
      update: cache => {
        const queryArgs = {
          query: auth.currentLoggedContext,
        };
        const modifiedContext = loggedContext;

        const index = modifiedContext?.personContextParams.findIndex(
          x => x.name === 'kiperVoiceAgent',
        );

        if (index >= 0) {
          modifiedContext.personContextParams.splice(index, 1);
        }

        cache.writeQuery({
          ...queryArgs,
          data: {
            currentLoggedContext: modifiedContext,
          },
        });
      },
    },
  );

  const onSubmit = values => {
    if (values.agent && loggedContext?.personContextId) {
      onCreate({
        variables: {
          data: {
            name: 'kiperVoiceAgent',
            value: JSON.stringify({
              agentId: values.agent,
            }),
            personContextId: loggedContext.personContextId,
          },
        },
      });
    }
  };

  const {
    values,
    handleSubmit,
    setFieldValue,
    errors,
    setTouched,
    touched,
  } = useFormik({
    initialValues: {
      agent: '',
    },
    validationSchema: yup.object({
      agent: yup
        .string()
        .matches(/^\d+$/, t('common:feedback.only-numbers'))
        .min(4, t('telephony-link.validation.min'))
        .max(5, t('telephony-link.validation.max'))
        .required(t('telephony-link.validation.required')),
    }),
    onSubmit,
  });

  useEffect(() => {
    if (kiperVoiceAgent?.value) {
      setState(kiperVoiceAgent);
      setFieldValue('agent', kiperVoiceAgent.value.agentId);
    }
  }, [kiperVoiceAgent]);

  const handleDelete = () => {
    if (state?.id)
      onDelete({
        variables: {
          id: state.id,
        },
      });
  };

  const handleOnlyNumber = event => {
    // does not let you type special characters in the number type input.
    if ([69, 107, 109, 187, 188, 189, 190, 229].includes(event?.which)) {
      event.preventDefault();
    }
  };

  return (
    <Flex flexDirection="column" height="100%">
      <Flex flexDirection="column">
        <Text fontWeight={600} fontSize="16px" lineHeight="24px" mb="4px">
          {t('telephony-link.title-header')}
        </Text>
        <Text fontSize="14px" lineHeight="20px" mb="16px">
          {t('telephony-link.intro')}
        </Text>

        {!state && (
          <>
            <Label>{t('telephony-link.enter-agent-code')}</Label>
            <Input
              type="number"
              className="form-control"
              name="agent"
              data-cy="input-agent"
              value={values.agent}
              onChange={setFieldValue}
              onKeyDown={handleOnlyNumber}
              disabled={!!state}
              invalid={touched.agent && !!errors.agent}
            />
            {touched.agent && !!errors.agent && (
              <ErrorMessage data-cy="agent-error-message">
                {errors.agent}
              </ErrorMessage>
            )}

            <Text fontSize="14px" lineHeight="16px" pt="5px" pl="8px">
              <Trans>{t('telephony-link.agent-code-helper')}</Trans>
            </Text>
          </>
        )}

        {!!state && (
          <UnlinkContainer>
            <Text fontSize="14px" lineHeight="20px" pr="10px">
              <Trans>
                {t('telephony-link.unlink-agent-code', {
                  id: state.value.agentId,
                })}
              </Trans>
            </Text>
            <KiperButton
              color="danger"
              variant="outlined"
              id="my-settings-btn-delete"
              data-cy="my-settings-btn-delete"
              onClick={handleDelete}
            >
              {loadingUnlink ? t('unlinking') : t('unlink')}
            </KiperButton>
          </UnlinkContainer>
        )}
      </Flex>
      {!state && (
        <Flex flexDirection="row-reverse" mt="16px">
          <Flex>
            <KiperButton
              color="secondary"
              variant="outlined"
              id="my-settings-btn-cancel"
              onClick={handleCancel}
            >
              {t('buttons:cancel')}
            </KiperButton>
            <KiperButton
              color="primary"
              id="my-settings-btn-save"
              data-cy="agent-btn-save"
              className="ml-2"
              type="submit"
              loading={loading}
              onClick={handleSubmit}
              disabled={(touched.agent && !!errors.agent) || !values.agent}
            >
              {loading
                ? t('saving', { name: t('link') })
                : t('save', { name: t('link') })}
            </KiperButton>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

export default TelephonyLink;

TelephonyLink.propTypes = {
  handleCancel: propTypes.func.isRequired,
};
