import { useEffect, useRef, useState } from 'react';

import propTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useSwal } from '@kiper/hooks';
import { useMutation } from 'react-apollo';

import { Flex, KiperButton, Text } from '@kiper/ui';
import { apolloErrorHandler } from '@kiper/fns';

import {
  fingerprintInsertV2,
  fingerprintPutV2,
} from '@kiper/monitoring-graphql/fingerprint';
import { queries } from '@kiper/monitoring-graphql/dweller/detail';
import Modal from '../Modal';

import useFingerprintReceived from '../../hooks/useFingerprintReceived';

import PutUserFingerprintModalFeedback from './PutUserFingerprintModalFeedback';

import fingerprintGif from '../../assets/images/fingerprint.gif';
import fingerprintFreeze from '../../assets/images/fingerprintFreeze.gif';

import { Img } from './styles';

const registrationStatus = {
  registertarted: 1,
  errorOnRegister: 2,
  registered: 3,
  notRegistered: 4,
};

export default function PutUserFingerprintModal({
  onToggle,
  condominium,
  deviceId,
  userId,
  accessFingerprintId,
  open,
  setFieldValue,
  devicesValues,
  onToggleParent,
}) {
  const [t] = useTranslation('user');
  const { toast } = useSwal();
  const [feedbackModalToggle, setFeedbackModalToggle] = useState(false);
  const [fingerprintReceived, setFingerprintReceived] = useState(null);
  const [isReadingFingerprint, setReadingFingerprint] = useState(false);
  const [fingerprintId, setFingerprintId] = useState(accessFingerprintId);
  const { fingerprint, resetFingerprint } = useFingerprintReceived();

  const timer = useRef(null);

  const queryArgs = {
    query: queries.dweller,
    variables: {
      personId: +userId,
    },
  };

  useEffect(() => {
    if (isReadingFingerprint) {
      timer.current = setTimeout(() => {
        setFeedbackModalToggle(true);
        setReadingFingerprint(false);
      }, 10000 * 6);

      if (
        (fingerprint?.registrationStatus ===
          registrationStatus.errorOnRegister ||
          fingerprint?.registrationStatus === registrationStatus.registered ||
          fingerprint?.registrationStatus ===
            registrationStatus.notRegistered) &&
        +fingerprint?.fingerprintId === fingerprintId
      ) {
        setFingerprintReceived(fingerprint);
        clearInterval(timer.current);
        setFeedbackModalToggle(true);
        setReadingFingerprint(false);
        setFieldValue(
          'devices',
          devicesValues?.map(device =>
            +device.id === +fingerprint?.fingerprintId
              ? { ...device, description: `${fingerprint?.registrationStatus}` }
              : device,
          ),
        );
      }
    }
    return () => {
      clearInterval(timer.current);
    };
  }, [isReadingFingerprint, fingerprint]);

  const [registerFingerprint] = useMutation(fingerprintInsertV2, {
    refetchQueries: [{ ...queryArgs }],
    onCompleted: res => {
      toast.fire({
        title: t('put.devices.modal.insert.fingerprint-ready-to-register'),
        icon: 'success',
      });
      setFingerprintId(+res?.fingerprintInsertV2?.id);
      setFieldValue('devices', [
        ...devicesValues,
        {
          ...res.fingerprintInsertV2,
          type: 'fingerPrint',
          description: res.fingerprintInsertV2.status,
          value: condominium?.condominiumName,
        },
      ]);
    },
    onError: err => {
      setReadingFingerprint(false);
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const [updateFingerprint] = useMutation(fingerprintPutV2, {
    refetchQueries: [{ ...queryArgs }],
    onCompleted: () => {
      toast.fire({
        title: t('put.devices.modal.insert.fingerprint-ready-to-register'),
        icon: 'success',
      });
    },
    onError: err => {
      setReadingFingerprint(false);
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const handleStartReadFingerprint = fingers => {
    setReadingFingerprint(true);
    if (fingerprintId) {
      return updateFingerprint({
        variables: {
          deviceId,
          accessFingerprintId: fingerprintId,
        },
      });
    }
    return registerFingerprint({
      variables: {
        params: {
          deviceId,
          condominiumId: +condominium?.condominiumId,
          personId: userId,
          fingers,
        },
      },
    });
  };

  const handleTryAgain = () => {
    setFeedbackModalToggle(false);
    setFingerprintReceived(null);
    resetFingerprint();
  };

  const handleToggleModal = () => {
    onToggle(false);
    onToggleParent(false);
    setReadingFingerprint(false);
  };

  const handleCloseFeedbackModal = () => {
    handleToggleModal();
    setFeedbackModalToggle(false);
    setFingerprintReceived(null);
    resetFingerprint();
  };

  return (
    <Modal
      title={`${t('put.devices.modal.insert.fingerprint-insert')} - ${t(
        'put.devices.waiting-to-record',
      )}`}
      open={open}
      toggle={handleToggleModal}
    >
      <>
        {feedbackModalToggle && (
          <PutUserFingerprintModalFeedback
            open={feedbackModalToggle}
            onToggle={handleCloseFeedbackModal}
            fingerprint={fingerprintReceived}
            onTryAgain={handleTryAgain}
          />
        )}

        <Flex mb="10px" flexDirection="column">
          <strong>{t('put.devices.modal.insert.first-access-finger')}</strong>
          <Text mb="10px">
            {t('put.devices.modal.insert.first-access-finger-description')}
          </Text>
          <strong>{t('put.devices.modal.insert.second-access-finger')}</strong>
          <Text>
            {t('put.devices.modal.insert.second-access-finger-description')}
          </Text>

          <Flex flex={1} justifyContent="center">
            <Img
              src={!isReadingFingerprint ? fingerprintFreeze : fingerprintGif}
              alt="fingerprint gif"
            />
          </Flex>

          <Text color="secondary400">
            {t('put.devices.modal.insert.sound-fingerprint-confirmation')}
          </Text>
        </Flex>

        <Flex flex={1} justifyContent="center">
          <KiperButton
            className="mr-3"
            color="primary"
            variant="outlined"
            onClick={() => handleStartReadFingerprint(1)}
            loading={isReadingFingerprint}
            disabled={isReadingFingerprint}
          >
            {isReadingFingerprint
              ? t('put.devices.modal.insert.waiting-fingerpint')
              : t('put.devices.modal.insert.register-one-fingerprint')}
          </KiperButton>

          <KiperButton
            color="primary"
            variant="outlined"
            onClick={() => handleStartReadFingerprint(2)}
            loading={isReadingFingerprint}
            disabled={isReadingFingerprint}
          >
            {isReadingFingerprint
              ? t('put.devices.modal.insert.waiting-fingerpint')
              : t('put.devices.modal.insert.register-fingerprint')}
          </KiperButton>
        </Flex>
      </>
    </Modal>
  );
}

PutUserFingerprintModal.propTypes = {
  onToggle: propTypes.func.isRequired,
  onToggleParent: propTypes.func,
  setFieldValue: propTypes.func,
  devicesValues: propTypes.array,
  condominium: propTypes.object,
  deviceId: propTypes.number,
  userId: propTypes.number,
  open: propTypes.bool.isRequired,
  accessFingerprintId: propTypes.number,
};

PutUserFingerprintModal.defaultProps = {
  onToggleParent: () => {},
  setFieldValue: () => {},
  devicesValues: [],
  condominium: null,
  userId: null,
  deviceId: null,
  accessFingerprintId: null,
};
