import { Fragment, useContext } from 'react';
import propTypes from 'prop-types';
import { Spinner } from '@kiper/ui';
import { useGetDescriptions, useQueries, useQuestionAnswerType } from './hooks';
import { CondominiumRuleFilterContext } from '../../../../../store';
import { AwaitingSubtriage } from './components';
import {
  Container,
  Description,
  HeaderTitle,
  LoadingWrapper,
  Wrapper,
} from './styles';

const QuestionsAndAnswers = ({ condominiumPersonContextId, eventId, t }) => {
  const {
    selectedTriage,
    selectedSubtriage,
    questions,
    loading,
    filteredTriage,
    filteredSubtriage,
  } = useContext(CondominiumRuleFilterContext);

  useQueries({
    condominiumPersonContextId,
    eventId,
  });

  const { handleQuestionAnswerType } = useQuestionAnswerType();

  const { handleGetDescription } = useGetDescriptions();

  const triageDescription = handleGetDescription(
    filteredTriage || selectedTriage,
  );

  const subtriageDescription = handleGetDescription(
    filteredSubtriage || selectedSubtriage,
  );

  if (!loading && !filteredSubtriage) {
    return <AwaitingSubtriage t={t} />;
  }

  return (
    <Container>
      {triageDescription && subtriageDescription && (
        <Wrapper>
          <HeaderTitle>{t('attendance')}</HeaderTitle>
          <Description>{`${triageDescription} - ${subtriageDescription}`}</Description>
        </Wrapper>
      )}

      {loading && (
        <LoadingWrapper>
          <Spinner size={28} />
        </LoadingWrapper>
      )}

      {!loading &&
        !!questions?.length &&
        questions?.map(question => (
          <Fragment key={question.id}>
            {handleQuestionAnswerType(question)}
          </Fragment>
        ))}
    </Container>
  );
};

export default QuestionsAndAnswers;

QuestionsAndAnswers.propTypes = {
  subtriage: propTypes.shape({
    id: propTypes.number,
    description: propTypes.string,
    descriptionEs: propTypes.string,
    descriptionEn: propTypes.string,
  }),
  triage: propTypes.shape({
    id: propTypes.number,
    description: propTypes.string,
    descriptionEs: propTypes.string,
    descriptionEn: propTypes.string,
  }),
  condominiumPersonContextId: propTypes.number.isRequired,
  t: propTypes.func.isRequired,
  eventId: propTypes.string,
};

QuestionsAndAnswers.defaultProps = {
  eventId: null,
  subtriage: null,
  triage: null,
};
