import { useEffect } from 'react';
import propTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import {
  Flex,
  KiperButton,
  Pagination,
  Table,
  TableLoader,
  Text,
  Badge,
} from '@kiper/ui';
import { useTheme } from 'styled-components';

import { queries } from '@kiper/monitoring-graphql/dweller/list';
import { useLazyQuery } from 'react-apollo';
import { FormGroup, TableContainer } from './styles';
import AccessProfileSelect from '../../AccessProfileSelect/AccessProfileSelect';
import { profileColor, profileTypes } from '../../../constants';

const ChangeAccessProfileModalSelectProfile = ({
  columns,
  condominium,
  handlePageChange,
  handlePageSize,
  filters,
  accessProfileTo,
  setAccessProfileTo,
  setActiveStep,
}) => {
  const { t } = useTranslation('condominium');

  const [fetch, { data, loading }] = useLazyQuery(queries.partnerDwellers);

  const getDwellers = () => {
    let newFilters = { ...filters, accessProfileIds: [] };
    if (filters.accessProfileIds?.length) {
      newFilters = {
        ...filters,
        accessProfileIds: filters.accessProfileIds.map(x => x.value),
      };
    }
    fetch({
      variables: {
        filters: newFilters,
      },
    });
  };

  useEffect(() => {
    getDwellers();
  }, []);

  useEffect(() => {
    getDwellers();
  }, [filters.pagesize, filters.page]);

  const theme = useTheme();

  return (
    <Flex
      flexDirection="column"
      px="24px"
      pt="24px"
      mt="16px"
      borderTop={`1px solid ${theme.colors.secondary200}`}
    >
      <Text fontSize="20px">
        {t('details.modal.access-profile.change-profile.select-access-profile')}
      </Text>
      <Text mt="20px" fontSize="16px">
        {t(
          'details.modal.access-profile.change-profile.select-access-profile-subtitle',
        )}
      </Text>
      <Flex flexDirection="column" mt="20px">
        <Flex mb="16px">
          <FormGroup>
            <AccessProfileSelect
              width="100%"
              classNamePrefix="select-profile"
              isClearable
              placeholder={t(
                `details.modal.access-profile.change-profile.access-profile`,
              )}
              name="accessProfile"
              onChange={e => {
                setAccessProfileTo(e);
              }}
              value={accessProfileTo}
              sourceNodeId={condominium?.treeNodeId}
            />
          </FormGroup>
        </Flex>

        {data?.partnerDwellers?.pagination && (
          <Flex
            justifyContent="center"
            mb="12px"
            bg="secondary200"
            borderRadius="4px"
            p="10px"
          >
            {data?.partnerDwellers?.pagination?.totalResults > 1 ? (
              <Text fontSize="16px" fontWeight="bold">
                {t(
                  'details.modal.access-profile.change-profile.amount-persons-selected',
                  {
                    amount: data?.partnerDwellers?.pagination?.totalResults,
                  },
                )}
              </Text>
            ) : (
              <Text fontSize="16px" fontWeight="bold">
                {t(
                  'details.modal.access-profile.change-profile.amount-person-selected',
                  {
                    amount: data?.partnerDwellers?.pagination?.totalResults,
                  },
                )}
              </Text>
            )}
          </Flex>
        )}
        <TableContainer>
          <Table columns={columns} hover={false}>
            {!loading && !!data?.partnerDwellers?.collection?.length && (
              <tbody>
                {data?.partnerDwellers?.collection?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Flex>
                        <Text fontSize="14px" mr="8px">
                          {item.name}
                        </Text>
                        <Badge color={profileColor[item.profileFieldName]}>
                          {t(
                            `common:profiles.${
                              profileTypes[item.profileFieldName]
                            }`,
                          )}
                        </Badge>
                      </Flex>
                    </td>
                    <td>
                      <Flex flexDirection="column">
                        <Text fontSize="14px">{item.unityName}</Text>
                        <Text fontSize="12px" color="secondary800">
                          {item.unityGroupName}
                        </Text>
                      </Flex>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>
          {loading && <TableLoader />}
          <Pagination
            translate={t}
            pagination={data?.partnerDwellers?.pagination}
            handlePageSize={handlePageSize}
            next={() => handlePageChange(1)}
            previous={() => handlePageChange(-1)}
          />
        </TableContainer>
      </Flex>
      <Flex justifyContent="space-between" width="100%" flex={1} py="20px">
        <KiperButton
          type="button"
          variant="outlined"
          onClick={() => setActiveStep(activeStep => activeStep - 1)}
        >
          {t('common:back')}
        </KiperButton>
        <Flex ml="16px">
          <KiperButton
            disabled={!accessProfileTo}
            type="submit"
            onClick={() => setActiveStep(activeStep => activeStep + 1)}
          >
            {t('details.modal.access-profile.change-profile.continue')}
          </KiperButton>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ChangeAccessProfileModalSelectProfile;

ChangeAccessProfileModalSelectProfile.propTypes = {
  columns: propTypes.object.isRequired,
  condominium: propTypes.object.isRequired,
  handlePageChange: propTypes.func.isRequired,
  handlePageSize: propTypes.func.isRequired,
  filters: propTypes.object.isRequired,
  accessProfileTo: propTypes.object,
  setAccessProfileTo: propTypes.func.isRequired,
  setActiveStep: propTypes.func.isRequired,
};

ChangeAccessProfileModalSelectProfile.defaultProps = {
  accessProfileTo: null,
};
