import propTypes from 'prop-types';
import * as S from './styles';

const OptionsSelector = ({ options, value, handleChange, name }) => {
  return (
    <S.OptionsContainer>
      {!!options?.length &&
        options?.map(option => (
          <S.Button
            key={option.id}
            id={`option-${option.id}`}
            name={name}
            label={option?.description}
            title={option?.description}
            value={option.id}
            variant="out"
            width="100%"
            checked={option.id === value}
            color={option.id === value ? 'primary' : 'neutral'}
            onChange={() => handleChange(option)}
            {...(option.icon && { icon: option.icon })}
          />
        ))}
    </S.OptionsContainer>
  );
};

OptionsSelector.propTypes = {
  options: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number.isRequired,
      description: propTypes.string.isRequired,
      icon: propTypes.node,
    }),
  ).isRequired,
  value: propTypes.number.isRequired,
  handleChange: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
};

export default OptionsSelector;
