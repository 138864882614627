import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Label } from '@kiper/ui';
import { DragDropContainer } from './DragAndDrop';
import { Container, Header } from './styles';

function ContactOrder({ values, user, handleChange, isEditable }) {
  const [t] = useTranslation('user');

  const initialValues = [
    {
      type: 'intercom',
      order: 0,
    },
    {
      type: 'main',
      order: 1,
    },
    {
      type: 'additional',
      order: 2,
    },
  ];

  const GetItems = () => {
    const { contactOrder } = values;

    if (!contactOrder) return initialValues;

    const items = [];

    for (const i in contactOrder) {
      let phoneValue = '';
      switch (i) {
        case 'main':
          phoneValue = user.phone;
          break;
        case 'additional':
          // eslint-disable-next-line prefer-destructuring
          phoneValue = user.additionalContacts[0]?.contact;
          break;
        default:
          break;
      }

      const item = {
        type: i,
        order: contactOrder[i],
        phoneValue,
      };

      items.push(item);
    }

    return items
      .filter(x => x.type !== '__typename')
      .sort((a, b) => a.order - b.order);
  };

  const [contacts, setContacts] = useState(GetItems());

  useEffect(() => {
    setContacts(GetItems());
  }, [values]);

  const handleChageContacts = valuesArr => {
    let contactOrder = {};
    valuesArr.forEach((item, index) => {
      contactOrder = {
        ...contactOrder,
        [item.type]: item.order !== 3 ? index : item.order,
      };
    });

    handleChange('contactOrder', contactOrder);
  };

  const handleToogleContactBlock = contact => {
    const i = contacts.indexOf(contact);
    const updatedContacts = contacts;
    updatedContacts[i] = {
      ...contact,
      order: contact.order === 3 ? 2 : 3,
    };

    handleChageContacts(updatedContacts);
  };

  return (
    <Container>
      <Header>
        <Label style={{ fontSize: '14px' }}>
          {t('put.access-data.contact-order.title')}
        </Label>
        <Label info style={{ fontSize: '14px' }}>
          {t('put.access-data.contact-order.info')}
        </Label>
      </Header>
      <DndProvider backend={HTML5Backend}>
        <DragDropContainer
          cards={Array.from(contacts)}
          originals={GetItems()}
          setCards={val => handleChageContacts(val)}
          handleToogleContactBlock={handleToogleContactBlock}
          isEditable={isEditable}
        />
      </DndProvider>
    </Container>
  );
}

ContactOrder.propTypes = {
  values: propTypes.object.isRequired,
  user: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  isEditable: propTypes.bool.isRequired,
};

export default ContactOrder;
