import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { insertStepHistory } from '@kiper/monitoring-graphql/guided_attendance';
import { useContext } from 'react';
import { useMutation } from 'react-apollo';
import { GuidedAttendanceContext } from '../../../../store';

const useInsertStepHistory = event => {
  const { contextTree } = useContext(GuidedAttendanceContext);
  const { toast } = useSwal();

  const [stepHistory] = useMutation(insertStepHistory, {
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const handleStepHistory = (unity, unityGroup) => {
    const eventId = event?.eventId;
    const stepId = contextTree?.currentStep?.id;
    const data = {
      unityId: unity?.id,
      unity: unity?.description,
      unityGroup: unityGroup?.description,
    };

    return stepHistory({
      variables: {
        eventId,
        stepId,
        data: JSON.stringify(data),
      },
    });
  };

  return { handleStepHistory };
};

export default useInsertStepHistory;
