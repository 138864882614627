import propTypes from 'prop-types';
import { CheckmarksSelect, CustomInput, Flex, Label } from '@kiper/ui';
import { MdSearch } from 'react-icons/md';
import { useEffect, useState } from 'react';
import { useDebounce, useWindowDimensions } from '@kiper/hooks';
import { useFormik } from 'formik';
import PageHeader from '../PageHeader';
import * as S from './styles';

const CondominiumsPageActions = ({
  filters,
  onChange,
  route,
  t,
  title,
  licensesOptions,
  statusOptions,
}) => {
  const [isFullHD, setIsFullHD] = useState(false);
  const debounce = useDebounce({ delay: 600 });

  const { width: windowsWidth } = useWindowDimensions();

  const formik = useFormik({
    initialValues: {
      ...filters,
    },
  });

  useEffect(() => {
    if (windowsWidth > 1600) return setIsFullHD(true);
    return setIsFullHD(false);
  }, [windowsWidth]);

  const handleKeyDown = event => {
    if (event.key === 'Enter' && event.shiftKey === false)
      event.preventDefault();
  };

  const handleChange = (name, value) => {
    formik.setFieldValue(name, value);
    debounce(() => onChange(name, value));
  };

  const licensesOptionsControl = {
    handleAddSelectionLicense: value =>
      handleChange('licenses', [...formik.values.licenses, value]),
    handleRemoveSelectionLicense: value => {
      const withoutRemovedLicense = formik.values.licenses.filter(
        license => license !== value,
      );
      handleChange('licenses', withoutRemovedLicense);
    },
    handleSelectedAllOptionsLicense: options => {
      const normalizedLicenses = options.map(option => option.value);
      handleChange('licenses', normalizedLicenses);
    },
  };

  const statusOptionsControl = {
    handleAddSelectionStatus: value =>
      handleChange('status', [...formik.values.status, value]),
    handleRemoveSelectionStatus: value => {
      const withoutRemovedStatus = formik.values.status.filter(
        status => status !== value,
      );
      handleChange('status', withoutRemovedStatus);
    },
  };

  return (
    <PageHeader t={t} breadcrumb={route.breadcrumb} title={title}>
      <Flex px="24px" pt="16px">
        <S.Form inline onKeyDown={handleKeyDown}>
          <S.FieldWrapper col={5}>
            <Label info block>
              {t('list.filters.label-name')}
            </Label>
            <CustomInput
              clearField
              type="text"
              icon={<MdSearch />}
              name="searchText"
              placeholder={t('common:search')}
              value={formik.values.searchText}
              onChange={handleChange}
            />
          </S.FieldWrapper>
          <S.FieldWrapper col={isFullHD ? 9 : 14}>
            <Label info block>
              {t('list.filters.label-licenses')}
            </Label>
            <CheckmarksSelect
              id="licenses"
              name="licenses"
              placeholder={t('common:search')}
              selectAllOptionsText={t('list.filters.select-all-options')}
              unselectAllOptionsText={t('list.filters.unselect-all-options')}
              options={licensesOptions}
              onRemoveSelection={
                licensesOptionsControl.handleRemoveSelectionLicense
              }
              onSelectedAllOptions={
                licensesOptionsControl.handleSelectedAllOptionsLicense
              }
              onAddSelection={licensesOptionsControl.handleAddSelectionLicense}
              selectedOptions={licensesOptions.filter(option =>
                formik.values.licenses.includes(option.value),
              )}
            />
          </S.FieldWrapper>
          <S.FieldWrapper col={5}>
            <Label info block>
              {t('list.filters.label-status')}
            </Label>
            <CheckmarksSelect
              preventEmptySelect
              id="status"
              name="status"
              placeholder={t('common:search')}
              options={statusOptions}
              onRemoveSelection={
                statusOptionsControl.handleRemoveSelectionStatus
              }
              onAddSelection={statusOptionsControl.handleAddSelectionStatus}
              selectedOptions={statusOptions.filter(option =>
                formik.values.status.includes(option.value),
              )}
            />
          </S.FieldWrapper>
        </S.Form>
      </Flex>
    </PageHeader>
  );
};

export default CondominiumsPageActions;

CondominiumsPageActions.propTypes = {
  filters: propTypes.object.isRequired,
  onChange: propTypes.func.isRequired,
  route: propTypes.object.isRequired,
  title: propTypes.string.isRequired,
  t: propTypes.func.isRequired,
  licensesOptions: propTypes.array.isRequired,
  statusOptions: propTypes.array.isRequired,
};
