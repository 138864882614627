import { useContext } from 'react';
import propTypes from 'prop-types';

import { ThemeContext } from 'styled-components';

import { FiArrowRight } from 'react-icons/fi';

import { useToggle } from '@kiper/hooks';
import { Box, Flex, Text } from '@kiper/ui';

import {
  useCurrentLoggedContext,
  useVoice,
  useRemoteConfig,
} from '../../../../hooks';

import CamerasGrid from '../../../../components/CamerasGrid';

import NoKiperVoiceAgentModal from '../../../../components/Modals/NoKiperVoiceAgentModal';
import NoKiperVoiceModal from '../../../../components/Modals/NoKiperVoiceModal';

import {
  Container,
  GridContainer,
  Row,
  EmptyStateContainer,
  CamOffIcon,
  EmptyStateText,
  ScrollBar,
  ObservationContainer,
  ObservationScroll,
} from './styles';

import DeviceItem from './DeviceItem';

const Cameras = ({
  t,
  event,
  places,
  devices,
  cameras,
  interlocks,
  mainCamera,
  selectedPlace,
  setSelectedPlace,
  handleDevice,
  disabledActions,
  screenWidth,
  deviceStatus,
}) => {
  const theme = useContext(ThemeContext);
  const { clickToCall } = useVoice();
  const [
    openNoKiperVoiceAgentModal,
    toggleOpenNoKiperVoiceAgentModal,
  ] = useToggle(false);
  const [openNoKiperVoiceModal, toggleOpenNoKiperVoiceModal] = useToggle(false);

  const { loggedContext } = useCurrentLoggedContext();

  const enableNewKeepOpenCommand = useRemoteConfig({
    path: 'attendance.keepOpenWithDelay',
    partner: Number(loggedContext?.partner?.personContextId),
    user: Number(loggedContext?.personContextId),
  });

  const hasKiperVoiceAgent = !!loggedContext?.personContextParams?.find(
    item => item.name === 'kiperVoiceAgent' && item.id,
  );

  const hasKiperVoice = loggedContext?.partner?.hasKiperVoice;

  const currentPlace = places.find(p => p.id === selectedPlace);

  // eslint-disable-next-line
  const handleClickToCall = callObject => {
    // change the true statement for a kiper voice validator
    if (!hasKiperVoice) {
      return toggleOpenNoKiperVoiceModal();
    }

    if (hasKiperVoiceAgent) {
      const feedbackText = t('making-call-to-extension', {
        number: callObject.destination,
      });

      clickToCall({
        voiceCallInput: {
          ...callObject,
          destination: String(callObject.destination),
        },
        eventId: event?.eventId,
        feedbackText,
        partner: event?.partner?.name,
        condominium: event?.condominium?.name,
        eventCode: event?.eventType,
      });
    } else {
      toggleOpenNoKiperVoiceAgentModal();
    }
  };

  return (
    <Container>
      {screenWidth >= 1920 && currentPlace?.comments && (
        <Flex width="100%">
          <ObservationContainer>
            <ObservationScroll>
              <Text fontSize="16px" fontWeight="600" color="infoPrimary">
                {t('user:put.observation')}
              </Text>
              <Text fontSize="14px">{currentPlace?.comments}</Text>
            </ObservationScroll>
          </ObservationContainer>
        </Flex>
      )}
      <Row>
        <Box pb="10px" display="flex" alignItems="center" width="100%">
          <ScrollBar>
            {(mainCamera || !!cameras?.length) && (
              <Flex flex={1} maxWidth="400px">
                <GridContainer>
                  <CamerasGrid
                    cameras={cameras}
                    selected={mainCamera}
                    event={event}
                  />
                </GridContainer>
              </Flex>
            )}
            {!cameras?.length && (
              <Flex flex={1} maxWidth="400px">
                <GridContainer>
                  <EmptyStateContainer>
                    <CamOffIcon size={40} color={theme.colors.mediumBlack} />
                    <EmptyStateText>
                      {t('details.cameras.empty-state')}
                    </EmptyStateText>
                  </EmptyStateContainer>
                </GridContainer>
              </Flex>
            )}

            <Flex flex={1} flexDirection="column">
              {screenWidth < 1920 && currentPlace?.comments && (
                <Flex pl="12px" width="100%">
                  <ObservationContainer>
                    <ObservationScroll>
                      <Text
                        fontSize="16px"
                        fontWeight="600"
                        color="infoPrimary"
                      >
                        {t('user:put.observation')}
                      </Text>
                      <Text fontSize="14px">{currentPlace?.comments}</Text>
                    </ObservationScroll>
                  </ObservationContainer>
                </Flex>
              )}
              {!!devices?.length && (
                <Flex
                  flexDirection="column"
                  px="12px"
                  width="100%"
                  maxWidth="100%"
                >
                  {devices.map((device, i) => (
                    <DeviceItem
                      key={i}
                      deviceStatus={deviceStatus}
                      device={device}
                      event={event}
                      placeName={device?.name}
                      handleDevice={handleDevice}
                      handleClickToCall={handleClickToCall}
                      disabledActions={disabledActions}
                      enableCommand={enableNewKeepOpenCommand}
                      t={t}
                    />
                  ))}
                </Flex>
              )}
              {!!interlocks?.length &&
                interlocks?.map((interlock, i) => (
                  <Flex key={i} pl="10px" mr="16px">
                    <Flex
                      border={`1px solid ${theme.colors.warningPrimary}`}
                      p="8px"
                      borderRadius="10px"
                      bg="orange100"
                      flexDirection="column"
                      width="100%"
                    >
                      <Text
                        fontSize="16px"
                        fontWeight="600"
                        color="warningPrimary"
                      >
                        {interlock?.name}
                      </Text>

                      {interlock?.places?.map((place, j) => (
                        <Flex key={j} alignItems="center">
                          <Text
                            textDecoration="underline"
                            my="6px"
                            mr="6px"
                            onClick={() => setSelectedPlace(place.id)}
                          >
                            {place?.name}
                          </Text>
                          <Box>
                            <FiArrowRight size="14px" />
                          </Box>
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                ))}
            </Flex>
          </ScrollBar>
        </Box>
      </Row>

      {openNoKiperVoiceAgentModal && (
        <NoKiperVoiceAgentModal
          open={openNoKiperVoiceAgentModal}
          onToggle={toggleOpenNoKiperVoiceAgentModal}
        />
      )}

      {openNoKiperVoiceModal && (
        <NoKiperVoiceModal
          open={openNoKiperVoiceModal}
          onToggle={toggleOpenNoKiperVoiceModal}
        />
      )}
    </Container>
  );
};

Cameras.propTypes = {
  t: propTypes.any.isRequired,
  setSelectedPlace: propTypes.func.isRequired,
  handleDevice: propTypes.func.isRequired,
  event: propTypes.object.isRequired,
  screenWidth: propTypes.number.isRequired,
  deviceStatus: propTypes.array.isRequired,
  places: propTypes.array,
  selectedPlace: propTypes.number,
  devices: propTypes.array,
  interlocks: propTypes.array,
  cameras: propTypes.array,
  mainCamera: propTypes.any,
  disabledActions: propTypes.bool,
};

Cameras.defaultProps = {
  places: [],
  devices: [],
  cameras: [],
  interlocks: [],
  selectedPlace: null,
  mainCamera: null,
  disabledActions: false,
};

export default Cameras;
