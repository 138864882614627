import { useEffect } from 'react';
import propTypes from 'prop-types';
import { Flex } from '@kiper/ui';

import { useTranslation } from 'react-i18next';
import { Container, Title, Subtitle, Button } from './styles';

const Popup = ({ title, subtitle, options, buttonText, onAction, legacy }) => {
  const { visible, hide, delay } = options;

  const [t] = useTranslation('event_attendance');

  useEffect(() => {
    if (delay && visible) {
      if (!legacy) onAction();
      setTimeout(() => {
        hide();
      }, delay);
    }
  }, []);

  const handleAction = () => {
    onAction();
    setTimeout(() => {
      hide();
    }, 500);
  };

  return (
    <>
      {visible && (
        <Container>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <Flex>
            <Button variant="outlined" onClick={() => hide()}>
              {t('close-notice')}
            </Button>
            {buttonText && (
              <Button color="primary" onClick={handleAction}>
                {buttonText}
              </Button>
            )}
          </Flex>
        </Container>
      )}
    </>
  );
};

export default Popup;

Popup.propTypes = {
  title: propTypes.string.isRequired,
  subtitle: propTypes.string.isRequired,
  onAction: propTypes.func.isRequired,
  options: propTypes.shape({
    visible: propTypes.bool.isRequired,
    hide: propTypes.func.isRequired,
    delay: propTypes.number,
  }).isRequired,
  buttonText: propTypes.string,
  legacy: propTypes.bool,
};

Popup.defaultProps = {
  buttonText: null,
  legacy: false,
};
