import styled from 'styled-components';

const verticalGap = '12px';

export const GadgetInfoDetailsModalContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${verticalGap};
`;

export const GadgetInfoDetailsObjectContent = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const GadgetInfoDetailsObjectContentData = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-left: ${({ ignoreLevel }) => (ignoreLevel ? '0' : '16px')};
`;

export const GadgetInfoObjectTitle = styled.h3`
  width: 100%;
  font-weight: bold;
  padding: 6px 12px;
  background-color: ${({ theme }) => theme.colors.gray100};
  border-radius: 4px;
  margin: 10px 0;
  font-size: 16px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 16px;
    width: 1px;
    height: calc(100% - 16px);
    background-color: ${({ theme }) => theme.colors.gray100};
  }
`;

export const GadgetInfoObjectValueContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  min-height: 24px;
  gap: 16px;
`;

export const GadgetInfoObjectValueContentLabel = styled.span`
  display: flex;
  flex: 1;
  font-size: 16px;
  line-height: 14px;
  margin-bottom: 4px;
  color: ${props => props.theme.colors.black};
`;

export const GadgetInfoObjectValueContentStringValue = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: ${props => props.theme.colors.black};
`;
