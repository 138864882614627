import {
  Users as UsersIcon,
  Mail as InvitesIcon,
  Home as CondominiumIcon,
  MessageSquare as AttendanceIcon,
  User as MyUserIcon,
  Sliders as EventSettingsIcon,
  Settings as SystemSettingsIcon,
  PieChart as ReportIcon,
  LogOut as LogoutIcon,
  MessageSquare as MessageSquareIcon,
} from 'react-feather';

export default [
  {
    title: 'event-attendance',
    items: [
      {
        title: 'event-attendance-list',
        to: '/attendance',
        icon: AttendanceIcon,
        requiredPermissions: ['monitoringScreen'],
      },
    ],
  },
  {
    title: 'condominium-management',
    items: [
      {
        title: 'user-list',
        to: '/users',
        icon: UsersIcon,
        requiredPermissions: ['users'],
      },
      {
        title: 'invite-list',
        to: '/invites',
        icon: InvitesIcon,
        requiredPermissions: ['invitationManagement'],
      },
      {
        title: 'condominium-list',
        to: '/condominiums',
        icon: CondominiumIcon,
        requiredPermissions: ['condominiumManagement'],
      },
      {
        title: 'reports',
        to: '/reports',
        icon: ReportIcon,
        requiredPermissions: ['monitoringReports'],
      },
    ],
  },
  {
    title: 'system-user-list',
    items: [
      {
        title: 'system-users',
        to: '/system-users',
        icon: UsersIcon,
        requiredPermissions: ['systemUsersManagement'],
      },
      {
        title: 'event-settings',
        to: '/event-settings',
        icon: EventSettingsIcon,
        requiredPermissions: ['eventSettings'],
      },
      {
        title: 'system-settings',
        to: '/system-settings',
        icon: SystemSettingsIcon,
        requiredPermissions: ['systemSettings'],
      },
    ],
  },
  {
    title: 'my-user',
    items: [
      {
        title: 'my-settings',
        icon: MyUserIcon,
      },
      {
        title: 'feedback',
        icon: MessageSquareIcon,
      },
      {
        title: 'logout',
        icon: LogoutIcon,
      },
    ],
  },
];
