import styled from 'styled-components';
import { FaWifi } from 'react-icons/fa';
import { Flex } from '@kiper/ui';

export const VehicleContainer = styled.div``;

export const VehicleDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  height: 100%;
  align-items: center;
`;

export const VehicleCard = styled(Flex)`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 12px;
  padding: 24px;
  gap: 24px;
  min-height: 145px;
  &:hover {
    cursor: pointer;
  }
`;

export const VehicleLabel = styled.span`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray600};
`;

export const VehicleModel = styled.span`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray900};
`;

export const VehiclePlate = styled.span`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.primary600};
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content;
  padding: 10px;
`;

export const DeleteContainer = styled.div``;

export const VehicularTagIcon = styled(FaWifi)`
  margin-left: 10px;
  color: ${props => props.theme.colors.primary600};
  font-size: 16px;
`;
