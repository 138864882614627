import { get, types } from '@kiper/cookie';
import { runtimeConfig } from '@kiper/fns';

export default function authorizationHeaders(request) {
  const [
    authorization,
    userContextId,
    userPartnerContextId,
    topNodeId,
    topContextId,
  ] = get(
    [
      types.authorization,
      types.context,
      types.partnerContext,
      types.topNodeId,
      types.topContextId,
    ],
    request,
  );

  return {
    userContextId,
    userPartnerContextId,
    topNodeId,
    topContextId,
    authorization: (authorization && `Bearer ${authorization}`) || '',
    applicationKey: runtimeConfig.APPLICATION_KEY_MONITORING,
  };
}
