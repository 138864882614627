import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-apollo';
import { apolloErrorHandler } from '@kiper/fns';
import { systemSettings, auth } from '@kiper/monitoring-graphql';
import { useSwal, useToggle } from '@kiper/hooks';
import { Flex, Loader } from '@kiper/ui';
import { MdOutlinePhoneInTalk } from 'react-icons/md';
import useCurrentLoggedContext from '../../../../hooks/useCurrentLoggedContext';
import PageHeader from '../../../../components/PageHeader';
import { KiperVoiceModal } from './Modal';
import { AddMore } from '../../../../components/AddMore';
import * as S from './styles';

const MESSAGE_ALIAS = 'details.voiceSettings';
const PREFIX_ID = 'kiper-voice-settings';

const KiperVoice = ({ route }) => {
  const [t] = useTranslation('system-settings');
  const { toast, swal } = useSwal();
  const { loggedContext } = useCurrentLoggedContext();

  const [modalOpened, toggleModal] = useToggle(false);
  const [modalData, setModalData] = useState(null);

  const hasKiperVoice = loggedContext?.partner?.hasKiperVoice;

  const { data: allKiperVoiceData, loading: loadingData, refetch } = useQuery(
    systemSettings.allKiperVoiceSettings,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const allKiperVoiceSettings = useMemo(
    () =>
      (allKiperVoiceData?.allKiperVoiceSettings || []).map(setting => ({
        newVersion: !!setting?.serializedParams?.newVersion,
        isDefault: !!setting?.serializedParams?.isDefault,
        kiperVoiceUrl: setting?.serializedParams?.kiperVoiceUrl?.replace(
          /('|")/g,
          '',
        ),
        kiperVoiceStorageUrl: setting?.serializedParams?.kiperVoiceStorageUrl?.replace(
          /('|")/g,
          '',
        ),
        id: setting?.id ?? '',
        name: setting?.name ?? '',
      })),
    [allKiperVoiceData?.allKiperVoiceSettings],
  );

  const breadcrumb = useMemo(() => {
    const modifiedBreadcrumb = [...route.breadcrumb];
    modifiedBreadcrumb.splice(modifiedBreadcrumb.length - 1, 1);
    modifiedBreadcrumb.push({
      label: t(`${MESSAGE_ALIAS}.breadcrumb`),
      to: '',
    });
    return modifiedBreadcrumb;
  }, [route]);

  const onError = err => {
    const formattedErrors = apolloErrorHandler(err);
    if (formattedErrors?.length) {
      toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
  };

  const [onCreate, { loading: saving }] = useMutation(
    systemSettings.createKiperVoiceSettings,
    {
      onError,
      onCompleted: () => {
        toast.fire({
          icon: 'success',
          title: t('details.voiceSettings.create-success'),
        });
      },
      update: (cache, { data: { createKiperVoiceSettings } }) => {
        const queryArgs = {
          query: auth.currentLoggedContext,
        };
        if (!hasKiperVoice) {
          const newContext = loggedContext;
          if (createKiperVoiceSettings?.serializedParams?.kiperVoiceUrl) {
            newContext.partner.hasKiperVoice = true;
            cache.writeQuery({
              ...queryArgs,
              data: {
                currentLoggedContext: newContext,
              },
            });
          }
        }
      },
    },
  );

  const [onUpdate, { loading: updating }] = useMutation(
    systemSettings.updateKiperVoiceSettings,
    {
      onError,
      onCompleted: () => {
        toast.fire({
          icon: 'success',
          title: t('details.voiceSettings.update-success'),
        });
      },
      update: (cache, { data: { updateKiperVoiceSettings } }) => {
        const queryArgs = {
          query: auth.currentLoggedContext,
        };
        if (!hasKiperVoice) {
          const newContext = loggedContext;
          if (updateKiperVoiceSettings?.serializedParams?.kiperVoiceUrl) {
            newContext.partner.hasKiperVoice = true;
            cache.writeQuery({
              ...queryArgs,
              data: {
                currentLoggedContext: newContext,
              },
            });
          }
        }
      },
    },
  );

  const handleSave = async values => {
    let URL = values.kiperVoiceUrl;
    let storageURL = values.kiperVoiceStorageUrl;

    if (URL.slice(-1) !== '/') {
      URL = `${values.kiperVoiceUrl}/`;
    }

    if (storageURL && storageURL.slice(-1) !== '/') {
      storageURL = `${values.kiperVoiceStorageUrl}/`;
    }

    const payloadData = {
      serializedParams: {
        kiperVoiceUrl: URL.trim(),
        kiperVoiceStorageUrl: storageURL,
        newVersion: values.newVersion,
        isDefault: values.isDefault,
      },
    };

    if (!values.id)
      await onCreate({
        variables: {
          data: payloadData,
        },
      });
    else
      await onUpdate({
        variables: {
          data: { ...payloadData, id: values.id },
        },
      });

    setModalData(null);
    toggleModal(!modalOpened);
    await refetch();
  };

  const onRemoveCompleted = () => {
    toast.fire({
      title: t(`${MESSAGE_ALIAS}.remove-success`),
      icon: 'success',
    });
  };

  const onRemoveError = err => {
    const formattedErrors = apolloErrorHandler(err);
    if (formattedErrors && formattedErrors.length) {
      toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
  };

  const [remove] = useMutation(systemSettings.removeKiperVoiceSettings, {
    onCompleted: onRemoveCompleted,
    onError: onRemoveError,
  });

  const handleEditKiperVoice = value => {
    setModalData(value);
    toggleModal(!modalOpened);
  };

  const handleRemove = async kiperVoiceId => {
    await remove({ variables: { kiperVoiceId } });
    await refetch();
  };

  const showRemoveAlert = async value => {
    await swal.fire({
      title: t(`${MESSAGE_ALIAS}.alert-remove.title`),
      text: t(`${MESSAGE_ALIAS}.alert-remove.text`, { baseUrl: value.baseUrl }),
      cancelButtonText: t('buttons:cancel'),
      confirmButtonText: t('buttons:confirm'),
      showLoaderOnConfirm: true,
      preConfirm: () => handleRemove(value.id),
      allowOutsideClick: () => !swal.isLoading(),
    });
  };

  const loading = useMemo(() => saving || updating || loadingData, [
    saving,
    updating,
    loadingData,
  ]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageHeader
            breadcrumb={breadcrumb}
            t={t}
            title={t(`${MESSAGE_ALIAS}.title`)}
          />

          <S.Card>
            <S.CardHeader>
              <S.CardTitle id={`${PREFIX_ID}-subtitle`}>
                {t(`${MESSAGE_ALIAS}.subtitle`)}
              </S.CardTitle>
            </S.CardHeader>
            <S.CardBody>
              <S.WrapContainer>
                {allKiperVoiceSettings.map((kiperVoiceSetting, index) => (
                  <S.Col
                    sm={6}
                    key={kiperVoiceSetting.name + kiperVoiceSetting.id}
                  >
                    <S.AddMoreContainer device={1}>
                      <S.ItemActionsContainer>
                        <S.EditIcon
                          id={`kiper-voice-edit-icon-${index}`}
                          size={20}
                          color="black"
                          onClick={() =>
                            handleEditKiperVoice(kiperVoiceSetting)
                          }
                        />
                        <S.RemoveIcon
                          id={`kiper-voice-remove-icon-${index}`}
                          size={20}
                          color="black"
                          onClick={() => showRemoveAlert(kiperVoiceSetting)}
                        />
                      </S.ItemActionsContainer>
                      <S.InnerContainer>
                        <MdOutlinePhoneInTalk size={68} color="black" />
                        <S.ServerName>
                          {`Kiper Voice #${index + 1}`}
                        </S.ServerName>
                        <S.ServerAddress
                          id={`kiper-voice-server-host-label-${index}`}
                        >
                          {kiperVoiceSetting.kiperVoiceUrl}
                        </S.ServerAddress>

                        <Flex
                          justifyContent="center"
                          alignItems="center"
                          gridGap="16px"
                          width="100%"
                          height="fit-content"
                        >
                          {kiperVoiceSetting.isDefault && (
                            <S.Badge color="primary">
                              <S.BadgeLabel>
                                {t('details.voiceSettings.is-default')}
                              </S.BadgeLabel>
                            </S.Badge>
                          )}

                          {kiperVoiceSetting.newVersion && (
                            <S.Badge color="info">
                              <S.BadgeLabel>V2</S.BadgeLabel>
                            </S.Badge>
                          )}
                        </Flex>
                      </S.InnerContainer>
                    </S.AddMoreContainer>
                  </S.Col>
                ))}
                <S.Col sm={allKiperVoiceSettings.length ? 6 : 12}>
                  <S.AddMoreContainer onClick={() => toggleModal(!modalOpened)}>
                    <AddMore
                      id="add-more-kiper-voice-setting"
                      text={t(`${MESSAGE_ALIAS}.add-button`)}
                    />
                  </S.AddMoreContainer>
                </S.Col>
              </S.WrapContainer>
            </S.CardBody>
          </S.Card>
        </>
      )}
      {modalOpened && (
        <KiperVoiceModal
          onSave={handleSave}
          loading={loading}
          toggle={() => {
            setModalData(null);
            toggleModal(!modalOpened);
          }}
          opened={modalOpened}
          kiperVoiceData={modalData}
          refetch={refetch}
          id="kiper-voice-modal"
        />
      )}
    </>
  );
};

KiperVoice.propTypes = {
  route: PropTypes.any.isRequired,
};

export default KiperVoice;
