import { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import TabHeaderPage from '../../../../components/TabHeaderPage';
import Devices from '../Devices/Devices';
import useCurrentLoggedContext from '../../../../hooks/useCurrentLoggedContext';
import { Card, CardHeader, CardBody } from '../styles';
import Vehicles from '../Vehicles';

export const tabIds = Object.freeze({
  DEVICES: 'devices',
  VEHICLES: 'vehicles',
});

const UserAccessTab = ({ isEdition, editMode, refetch }) => {
  const [t] = useTranslation('user');
  const [activeTab, setActiveTab] = useState(tabIds.DEVICES);
  const { loggedContext } = useCurrentLoggedContext();

  const tabs = useMemo(() => {
    return [
      {
        title: t('put.devices.title'),
        tabId: tabIds.DEVICES,
        showTab: true,
        component: Devices,
      },
      {
        title: t(`put.vehicles.title`),
        tabId: tabIds.VEHICLES,
        showTab: true,
      },
    ];
  }, [loggedContext]);

  const handleChangeTab = useCallback(
    tab => {
      setActiveTab(tab);
    },
    [activeTab],
  );

  return (
    <Card>
      <CardHeader>
        {tabs.length && (
          <TabHeaderPage
            tabs={tabs}
            activeTab={activeTab}
            onChangeTab={handleChangeTab}
          />
        )}
      </CardHeader>
      <CardBody>
        {activeTab === tabIds.DEVICES ? (
          <Devices
            isEdition={isEdition}
            editMode={editMode}
            refetch={refetch}
          />
        ) : (
          <Vehicles />
        )}
      </CardBody>
    </Card>
  );
};

UserAccessTab.propTypes = {
  isEdition: propTypes.bool,
  editMode: propTypes.bool,
  refetch: propTypes.func.isRequired,
};

UserAccessTab.defaultProps = {
  isEdition: false,
  editMode: false,
};

export default UserAccessTab;
