import styled from 'styled-components';
import { Label, Button as button } from '@kiper/ui';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Img = styled.img``;

export const TextContainer = styled.div`
  max-width: 400px;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
`;

export const Title = styled(Label)`
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  line-height: 30px;
  color: ${props => props.theme.colors.highBlack};
`;

export const Subtitle = styled(Label)`
  font-size: 14px;
  color: ${props => props.theme.colors.highBlack};
`;

export const Button = styled(button)``;
