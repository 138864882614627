import { useMemo } from 'react';
import propTypes from 'prop-types';
import { useQuery } from 'react-apollo';

import { queries as condoQueries } from '@kiper/monitoring-graphql/condominium/detail';
import { Select } from '@kiper/ui';
import { placeTypes } from '../../../constants';

const PlaceDeviceSelect = ({
  condominiumContextId,
  invalid,
  value,
  disabledValues,
  isDisabled,
  ...props
}) => {
  const { data, loading } = useQuery(condoQueries.condominiumPlaces, {
    fetchPolicy: 'cache-first',
    skip: !condominiumContextId,
    variables: {
      condominiumId: condominiumContextId,
    },
  });

  const places = useMemo(() => {
    if (!data) return [];
    return data?.condominiumPlaces
      ?.filter(
        place =>
          place.placeType?.fieldName !== placeTypes.stock &&
          place.totalCameras > 0,
      )
      .map(place => ({
        ...place,
        isDisabled: disabledValues.some(
          disabledPlaceId => disabledPlaceId === place.id,
        ),
      }));
  }, [data, disabledValues]);

  const getValue = placeId => places?.filter(place => place.id === placeId);

  return (
    <Select
      {...props}
      width="100%"
      isDisabled={isDisabled}
      isLoading={loading}
      options={places}
      invalid={Number(invalid)}
      value={getValue(value?.id)}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
    />
  );
};

export default PlaceDeviceSelect;

PlaceDeviceSelect.propTypes = {
  condominiumContextId: propTypes.number,
  invalid: propTypes.bool,
  name: propTypes.string.isRequired,
  value: propTypes.any,
  disabledValues: propTypes.array,
  isDisabled: propTypes.bool,
};

PlaceDeviceSelect.defaultProps = {
  condominiumContextId: undefined,
  isDisabled: false,
  invalid: false,
  value: '',
  disabledValues: [],
};
