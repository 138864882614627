import { useContext, useEffect } from 'react';
import propTypes from 'prop-types';
import { useFormik, FormikProvider } from 'formik';
import { useQuery, useMutation } from 'react-apollo';
import { apolloErrorHandler, removeProperty } from '@kiper/fns';
import { guidedAttendance as query } from '@kiper/monitoring-graphql';
import { useSwal } from '@kiper/hooks';
import { insertGuidedAttendanceData } from '@kiper/monitoring-graphql/guided_attendance';
import { Flex, Spinner } from '@kiper/ui';
import { useGuidedAttendance } from '../../../../hooks';
import {
  CondominiumRuleFilterContext,
  EventAttendanceContext,
  GuidedAttendanceContext,
} from '../../../../store';
import {
  StepAttendanceGetName,
  StepAttendanceGetReason,
  StepAttendanceGetUnityLegacy,
  StepAttendanceCallDweller,
  StepRegisterServiceProvider,
  StepUnityOrCondo,
  StepFindRegister,
  StepPhotoRegister,
  StepMessageSyncTransientPerson,
  StepFallback,
  StepMessageOpenDoors,
  StepIntrusionDetection,
  StepGetOnlyUnity,
  StepDwellerValidation,
  StepOutOfHoursServiceProvider,
  StepGetSubtriage,
  StepAttendanceGetUnity,
} from '../../../../components/GuidedAttendanceSteps';
import GuidedAttendanceDetailHeader from './GuidedAttendanceDetailHeader';
import SimplifiedEventList from '../../../../components/SimplifiedEventList';
import { sendAmplitudeData } from '../../../../services/amplitude';
import * as S from './styles';

const GuidedAttendanceDetailContent = ({ history, event, ...props }) => {
  const { toast } = useSwal();
  const eventId = props.match.params.id;

  const { eventInAttendance } = useContext(EventAttendanceContext);
  const {
    contextTree: { data, tree, nextAction, currentStep, formikSchema },
    setContextTree,
    setLoadingSubmit,
    handleClearData,
  } = useContext(GuidedAttendanceContext);

  const { handleClearFilter } = useContext(CondominiumRuleFilterContext);

  const { getCurrentStep, handleNextStep } = useGuidedAttendance();

  const steps = {
    StepAttendanceGetName: <StepAttendanceGetName event={event} />,
    StepAttendanceGetReason: <StepAttendanceGetReason event={event} />,
    StepUnityOrCondo: <StepUnityOrCondo />,
    StepAttendanceGetUnity: <StepAttendanceGetUnity event={event} />,
    StepAttendanceGetUnityLegacy: (
      <StepAttendanceGetUnityLegacy event={event} />
    ),
    StepFindRegister: <StepFindRegister event={event} />,
    StepAttendanceCallDweller: <StepAttendanceCallDweller event={event} />,
    StepRegisterServiceProvider: <StepRegisterServiceProvider event={event} />,
    StepPhotoRegister: (
      <StepPhotoRegister history={history} event={event} eventId={eventId} />
    ),
    StepMessageSyncTransientPerson: (
      <StepMessageSyncTransientPerson event={event} eventId={eventId} />
    ),
    StepMessageOpenDoors: <StepMessageOpenDoors />,
    StepFallback: <StepFallback history={history} eventId={eventId} />,
    StepIntrusionDetection: <StepIntrusionDetection event={event} />,
    StepRegisterVisitorTransientPerson: (
      <StepRegisterServiceProvider event={event} />
    ),
    StepGetOnlyUnity: <StepGetOnlyUnity event={event} />,
    StepDwellerValidation: <StepDwellerValidation event={event} />,
    StepOutOfHoursServiceProvider: (
      <StepOutOfHoursServiceProvider event={event} />
    ),
    StepGetSubtriage: <StepGetSubtriage event={event} />,
  };

  const handleFallback = () => {
    history.push(`/guided-attendance/${eventId}/fallback`);
  };

  const [insertData] = useMutation(insertGuidedAttendanceData);

  const onSubmit = async formikValues => {
    setLoadingSubmit(true);
    const action = await nextAction(formikValues);

    if (!action) {
      setLoadingSubmit(false);
      return null;
    }

    const formikValuesWithCurrentStepData = {
      ...formikValues,
      ...action.currentStepData,
      isInFallback: !action?.nextStep?.nextAttendanceStep,
    };

    const showStepName =
      action?.nextStep?.nextAttendanceStep?.componentName ?? 'Fallback';

    sendAmplitudeData(`show ${showStepName}`, {
      event,
      origin: currentStep?.componentName,
    });
    insertData({
      variables: {
        data: {
          currentStep: action?.nextStep?.nextAttendanceStep?.id || 99,
          data: JSON.stringify(formikValuesWithCurrentStepData),
          rootAttendanceStepId: tree.attendanceStepId,
          eventId,
        },
      },
    });

    setLoadingSubmit(false);

    if (!action?.nextStep?.nextAttendanceStep) {
      return handleFallback();
    }

    return handleNextStep(
      action?.nextStep,
      removeProperty(formikValues, 'isInFallback'),
    );
  };

  const formikBag = useFormik({
    validationSchema: formikSchema,
    initialValues: data || {},
    onSubmit,
  });

  const { handleSubmit, setValues } = formikBag;
  const { loading } = useQuery(query.getGuidedAttendance, {
    skip: !event?.eventType && !event?.condominium?.personContextId,
    fetchPolicy: 'no-cache',
    variables: {
      eventId,
      eventType: event?.eventType,
      condominiumContextId: event?.condominium?.personContextId,
    },
    onCompleted: response => {
      if (!response) return null;

      const { guidedAttendance } = response;
      const newGuidedAttendance = removeProperty(
        guidedAttendance,
        '__typename',
      );

      const { tree: newTree, context } = newGuidedAttendance;

      const newValues = context?.data ? JSON.parse(context.data) : {};

      if (!guidedAttendance.tree || newValues?.isInFallback)
        return handleFallback();

      const { attendanceStep, attendanceStepId } = newTree;

      setValues(newValues);

      sendAmplitudeData(`show ${attendanceStep?.componentName}`, {
        event,
        origin: null,
      });

      let path = [];

      if (context?.data) {
        path = Object.keys(JSON.parse(context?.data)).filter(
          x => x !== 'isInFallback',
        );
      }

      const current = getCurrentStep(
        attendanceStep,
        context?.currentStep || attendanceStepId,
        path,
      );

      return setContextTree({
        tree: newGuidedAttendance.tree,
        currentStep: current,
        data: context?.data ? JSON.parse(context.data) : null,
        historyPath: path,
      });
    },
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  useEffect(() => {
    return () => {
      handleClearData();
      handleClearFilter();
    };
  }, []);

  return (
    <S.Container>
      <S.Form onSubmit={handleSubmit}>
        <FormikProvider value={formikBag}>
          <GuidedAttendanceDetailHeader event={event} />
          <Flex height="100%">
            {eventInAttendance && <SimplifiedEventList />}
            <S.ContentContainer>
              {loading ? (
                <S.SpinnerContainer>
                  <Spinner size={28} />
                </S.SpinnerContainer>
              ) : (
                <S.StepContainer>
                  {steps[currentStep?.componentName]}
                </S.StepContainer>
              )}
            </S.ContentContainer>
          </Flex>
        </FormikProvider>
      </S.Form>
    </S.Container>
  );
};

export default GuidedAttendanceDetailContent;

GuidedAttendanceDetailContent.propTypes = {
  match: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
};
