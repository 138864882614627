import propTypes from 'prop-types';
import KiperSpinner from '../KiperSpinner';
import { LoaderWrapper } from './styles';

export default function Loader({ fullHeight }) {
  return (
    <LoaderWrapper fullHeight={fullHeight}>
      <KiperSpinner />
    </LoaderWrapper>
  );
}

Loader.propTypes = {
  fullHeight: propTypes.bool,
};

Loader.defaultProps = {
  fullHeight: false,
};
