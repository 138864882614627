import { useRef } from 'react';
import propTypes from 'prop-types';
import { useFilters } from '@kiper/hooks';
import { TableLoader, Pagination, Table, EmptyState } from '@kiper/ui';
import { useQuery } from 'react-apollo';
import { invite as inviteGql } from '@kiper/monitoring-graphql';
import TableRow from './TableRow';
import { TableContainer } from '../styles';
import emptyStatePlaceholder from '../../../../assets/images/empty-state-users.svg';

const initialFilters = {
  page: 1,
  pagesize: 10,
  orderby: 'name',
  ordination: false,
};

const ListTable = ({ t, sourceNodeId, searchText }) => {
  const {
    filters,
    handlePageChange,
    handlePageSize,
    // eslint-disable-next-line no-unused-vars
    handleChange,
    handleSort,
  } = useFilters(initialFilters);

  const { data, loading } = useQuery(inviteGql.invites, {
    variables: { filters: { ...filters, searchText, sourceNodeId } },
  });

  const columns = useRef([
    {
      name: t('list-name'),
      align: 'left',
      width: '25%',
      order: 'name',
    },
    {
      name: t('host'),
      align: 'left',
      width: '25%',
      order: 'host.name',
    },
    {
      name: t('place'),
      align: 'left',
      width: '25%',
      order: 'condominium.name',
    },
    {
      name: t('access'),
      align: 'left',
      width: '15%',
    },
    {
      name: t('guests'),
      align: 'left',
      width: '5%',
    },
    { key: 4, name: '', align: 'right', width: '5%' },
  ]);

  const { collection = [], pagination } = data?.invites ?? {};

  if (loading)
    return (
      <TableContainer>
        <TableLoader />
      </TableContainer>
    );

  return (
    <TableContainer>
      {collection.length ? (
        <>
          <Table
            columns={columns}
            sort={{
              handleSort,
              ordination: filters.ordination,
              orderby: filters.orderBy,
            }}
          >
            <tbody>
              {collection.map(item => (
                <TableRow key={item.id} data={item} t={t} />
              ))}
            </tbody>
          </Table>
          <Pagination
            translate={t}
            pagination={pagination}
            handlePageSize={handlePageSize}
            next={() => handlePageChange(1)}
            previous={() => handlePageChange(-1)}
          />
        </>
      ) : (
        <EmptyState
          placeholder={emptyStatePlaceholder}
          text={t('empty-state')}
        />
      )}
    </TableContainer>
  );
};

export default ListTable;

ListTable.propTypes = {
  t: propTypes.func.isRequired,
  sourceNodeId: propTypes.number.isRequired,
  searchText: propTypes.string,
};

ListTable.defaultProps = {
  searchText: null,
};
