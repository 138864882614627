import { useGuidedAttendance, useInsertStepHistory } from '../../../../hooks';

const useNextAction = ({ event }) => {
  const { handleNextAction } = useGuidedAttendance();
  const { handleInsertStepHistory } = useInsertStepHistory();

  const nextAction = async stepData => {
    const handleNextFn = await handleNextAction({
      values: stepData,
      fieldValue: 'stepOutOfHoursServiceProvider',
    });

    const { stepOutOfHoursServiceProvider } = stepData;
    const data = {
      finishGuidedAttendance:
        stepOutOfHoursServiceProvider?.finishGuidedAttendance,
      hasEssentialService: stepOutOfHoursServiceProvider?.hasEssentialService,
    };

    if (stepOutOfHoursServiceProvider?.hasEssentialService) {
      handleInsertStepHistory(data, event.eventId);
    }

    return handleNextFn;
  };

  return { nextAction };
};

export default useNextAction;
