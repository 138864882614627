import { ErrorMessage, Input, Label } from '@kiper/ui';
import propTypes from 'prop-types';
import SelectAvailableTag from '../SelectAvailableTag';
import { FormGroup } from './styles';

const UserDeviceTag = ({
  formik,
  t,
  loggedContext,
  showOption,
  existDevice,
}) => {
  const { values, handleChange, setFieldValue, touched, errors } = formik;
  return (
    <>
      <FormGroup>
        <Label>{t('put.devices.modal.insert.insert-id')}</Label>
        {showOption('tag') ? (
          <SelectAvailableTag
            name="value"
            value={values.value}
            onChange={handleChange}
            invalid={touched.value && !!errors.value}
            partnerTreeNodeId={loggedContext.topNodeId}
            placeholder={t('put.devices.modal.insert.tag-id-placeholder')}
            isDisabled={!!values.id}
          />
        ) : (
          <>
            <Input
              type="text"
              className="form-control"
              placeholder={t('put.devices.modal.insert.tag-id-placeholder')}
              name="value"
              value={values.value}
              onChange={setFieldValue}
              invalid={(touched.value && !!errors.value) || existDevice}
              disabled={!values.type || !!values.id}
            />
            {values.type && touched.value && errors.value && (
              <ErrorMessage>{errors.value}</ErrorMessage>
            )}
          </>
        )}
        {existDevice && (
          <ErrorMessage>
            {t('put.devices.modal.insert.errors.device-exist')}
          </ErrorMessage>
        )}
      </FormGroup>
      <FormGroup>
        <Label>{t('put.devices.modal.insert.input-description')}</Label>
        <Input
          type="text"
          className="form-control"
          name="description"
          value={values.description}
          onChange={(field, value) => setFieldValue(field, value)}
          invalid={touched.description && !!errors.description}
        />
        {touched.description && errors.description && (
          <ErrorMessage>{errors.description}</ErrorMessage>
        )}
      </FormGroup>
    </>
  );
};

export default UserDeviceTag;

UserDeviceTag.propTypes = {
  formik: propTypes.shape({
    values: propTypes.object,
    errors: propTypes.object,
    touched: propTypes.object,
    setFieldValue: propTypes.func,
    handleChange: propTypes.func,
  }).isRequired,
  loggedContext: propTypes.object.isRequired,
  t: propTypes.func.isRequired,
  showOption: propTypes.func.isRequired,
  existDevice: propTypes.bool.isRequired,
};
