import propTypes from 'prop-types';
import { Button, Flex } from '@kiper/ui';
import { MdOutlineCheck, MdOutlineClose } from 'react-icons/md';
import { dwellerValidationStatus } from '../../../../constants';
import { NextStepButton } from '../../ActionStepButtons';

const ActionButtons = ({
  fieldsValidated,
  handleChangeInvalidValidationStatus,
  value,
  handleSubmit,
  t,
}) => {
  const noDwellerStatus =
    value('dwellerValidationStatus') !== dwellerValidationStatus.NO_DWELLERS;
  return (
    <Flex gridGap="8px" mt="16px">
      {noDwellerStatus ? (
        <>
          <Button
            icon={<MdOutlineClose />}
            variant="out"
            color="neutral"
            size="sm"
            disabled={!!fieldsValidated?.length}
            onClick={() =>
              handleChangeInvalidValidationStatus(
                dwellerValidationStatus.INVALID,
              )
            }
          >
            {t('step-dweller-validation.button.uninformed')}
          </Button>
          {value('dweller') && (
            <Button
              icon={<MdOutlineCheck />}
              variant="out"
              color={!fieldsValidated?.length ? 'neutral' : 'primary'}
              size="sm"
              disabled={!fieldsValidated?.length}
              onClick={handleSubmit}
            >
              {t('step-dweller-validation.button.confirmed')}
            </Button>
          )}
        </>
      ) : (
        <NextStepButton />
      )}
    </Flex>
  );
};

export default ActionButtons;

ActionButtons.propTypes = {
  fieldsValidated: propTypes.array.isRequired,
  handleChangeInvalidValidationStatus: propTypes.func.isRequired,
  value: propTypes.func.isRequired,
  handleSubmit: propTypes.func.isRequired,
  t: propTypes.func.isRequired,
};
