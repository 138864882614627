import styled from 'styled-components';
import { FormGroup as formGroup, Form as form } from 'reactstrap';

export const FormGroup = styled(formGroup)`
  margin-bottom: 12px;
`;

export const Form = styled(form)``;

export const Img = styled.img`
  width: 100%;
`;

export const FeedbackImg = styled.img`
  width: 50%;
`;
