import { useContext } from 'react';
import propTypes from 'prop-types';
import { SidebarContext } from '../Sidebar';
import { Container } from './styles';

const SidebarContent = ({ children }) => {
  const { active } = useContext(SidebarContext);

  return <Container display={active ? 'block' : 'none'}>{children}</Container>;
};

export default SidebarContent;

SidebarContent.propTypes = {
  children: propTypes.any.isRequired,
};
