import { useMemo } from 'react';
import { ErrorMessage, Label, InputMask, KiperButton } from '@kiper/ui';
import { useTheme } from 'styled-components';
import { MdDelete } from 'react-icons/md';
import { apolloErrorHandler } from '@kiper/fns';
import { useTranslation } from 'react-i18next';
import { useSwal } from '@kiper/hooks';
import propTypes from 'prop-types';
import { useMutation } from 'react-apollo';
import { allowedAccess as allowedAccessGql } from '@kiper/monitoring-graphql';
import AddMore from '../../../AddMore';
import { FormGroup, Col, Row, AddMoreContainer } from '../../../styles';
import { Container, RemoveAction, Item, Wrapper } from './styles';
import Week from '../../../../../../components/Week';

const gmt = values =>
  values?.find(i => !!i.gmt)?.gmt ?? (new Date().getTimezoneOffset() / 60) * -1;

export default function AccessRestrictions({
  values,
  handleChange,
  isEditable,
  errors,
}) {
  const [t] = useTranslation('user');
  const theme = useTheme();
  const { toast, confirm } = useSwal();

  const [removeAllowAccess] = useMutation(
    allowedAccessGql.removeAllowedAccess,
    {
      onCompleted: () => {
        toast.fire({
          icon: 'success',
          title: t('put.access-data.modal.delete-allow-access.success'),
        });
      },
      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length) {
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
        }
      },
    },
  );

  const add = timePermission => {
    handleChange('allowedAccesses', [...values, timePermission]);
  };

  const deleteItem = async (permission, index) => {
    const deleted = await confirm({
      title: t('put.access-data.modal.delete-allow-access.title'),
      text: t('put.access-data.modal.delete-allow-access.text'),
      cancelButtonText: t('put.access-data.modal.delete-allow-access.cancel'),
      confirmButtonText: t('put.access-data.modal.delete-allow-access.confirm'),
    });
    if (deleted) {
      if (permission.id) {
        removeAllowAccess({
          variables: {
            personContextAllowedAccessId: Number(permission.id),
          },
        });
      }

      const modifiedPermission = [...values];
      modifiedPermission.splice(index, 1);
      handleChange('allowedAccesses', modifiedPermission);
    }
  };

  const handleChangePermissionDay = (indexPressed, indexDay) => {
    const newIntervals = [...values];
    const interval = newIntervals[indexPressed];

    interval.daysOfWeek[indexDay] = !interval.daysOfWeek[indexDay];

    handleChange('allowedAccesses', newIntervals);
  };

  const validateTime = (property, hours, minutes, timeObject) => {
    if (hours > 23 || hours < 0 || minutes > 59 || minutes < 0) {
      toast.fire({
        title: t('put.access-data.time-permissions.invalid-time'),
        icon: 'error',
      });

      return false;
    }

    const { start, end } = timeObject;

    if (property === 'start') {
      const { hours: endHours, minutes: endMinutes } = end;

      const parsedEndHours = Number(endHours);
      const parsedEndMinutes = Number(endMinutes);

      if (
        hours > parsedEndHours ||
        (hours === parsedEndHours && minutes >= parsedEndMinutes)
      ) {
        toast.fire({
          title: t('put.access-data.time-permissions.feedback-start-time'),
          icon: 'error',
        });

        return false;
      }
    }

    if (property === 'end') {
      const { hours: startHours, minutes: startMinutes } = start;

      const parsedStartHours = Number(startHours);
      const parsedStartMinutes = Number(startMinutes);

      if (
        hours < parsedStartHours ||
        (hours === parsedStartHours && minutes <= parsedStartMinutes)
      ) {
        toast.fire({
          title: t('put.access-data.time-permissions.feedback-end-time'),
          icon: 'error',
        });

        return false;
      }
    }

    return true;
  };

  const handleChangeTime = (property, time, index, resetField) => {
    const modifiedTime = [...values];

    let [hours, minutes] = time.split(':');

    const parsedHours = Number(hours);
    const parsedMinutes = Number(minutes);

    const validTime = validateTime(
      property,
      parsedHours,
      parsedMinutes,
      modifiedTime[index],
    );

    if (!validTime) {
      const {
        hours: oldHours,
        minutes: oldMinutes,
        time: oldTime,
      } = modifiedTime[index][property];

      modifiedTime[index][property] = {
        hours: oldHours,
        minutes: oldMinutes,
        time: oldTime,
      };

      resetField(oldTime);
    } else {
      const trimmedHours = hours?.trim();
      const trimmedMinutes = minutes?.trim();

      const hoursLength = trimmedHours?.length;
      const minutesLength = trimmedMinutes?.length;

      if (!minutesLength) {
        minutes = '00';
        time = `${trimmedHours}:00`; // eslint-disable-line
      }
      if (!hoursLength) {
        hours = '00';
        time = `00:${trimmedMinutes}`; // eslint-disable-line
      }
      if (hoursLength === 1) {
        time = `0${trimmedHours}:${trimmedMinutes}`; // eslint-disable-line
      }
      if (minutesLength === 1) {
        time = `${trimmedHours}:0${trimmedMinutes}`; // eslint-disable-line
      }

      resetField(time);

      modifiedTime[index][property] = { hours, minutes, time };
    }

    handleChange('allowedAccesses', modifiedTime);
  };

  const checkDaysOfWeekIsEmpty = index => {
    if (errors?.allowedAccesses?.[index]?.daysOfWeek) return true;

    return false;
  };

  const hasErrorAccessPeriod = useMemo(
    () => typeof errors?.allowedAccesses === 'string',
    [errors],
  );

  if (!isEditable && !values.length) return null;

  return (
    <>
      <Label style={{ fontSize: '14px' }}>
        {`${t('put.access-data.access-period')} (GMT${gmt(values)})`}
      </Label>
      <FormGroup>
        <Wrapper>
          {values?.map((permission, index) => (
            <Item key={index}>
              <Container>
                {isEditable && (
                  <RemoveAction>
                    <KiperButton
                      onClick={() => deleteItem(permission, index)}
                      rounded
                      icon={<MdDelete />}
                      color="secondary"
                      variant="text"
                    />
                  </RemoveAction>
                )}
                <Row>
                  <Col xs={6}>
                    <Label info block>
                      {t('put.access-data.time-permissions.start-time')}
                    </Label>
                    {!isEditable ? (
                      <Label info bolder>
                        {permission.start.time}
                      </Label>
                    ) : (
                      <InputMask
                        mask="99:99"
                        value={permission.start.time}
                        delay={1000}
                        onChange={(val, fn) =>
                          handleChangeTime('start', val, index, fn)
                        }
                      />
                    )}
                  </Col>
                  <Col xs={6}>
                    <Label info block>
                      {t('put.access-data.time-permissions.end-time')}
                    </Label>
                    {!isEditable ? (
                      <Label info bolder>
                        {permission.end.time}
                      </Label>
                    ) : (
                      <>
                        <InputMask
                          mask="99:99"
                          value={permission.end.time}
                          delay={1000}
                          onChange={(val, fn) =>
                            handleChangeTime('end', val, index, fn)
                          }
                        />
                      </>
                    )}
                  </Col>
                </Row>
                <Col xs={9} style={{ marginTop: '20px' }}>
                  <Label info block>
                    {t('put.access-data.time-permissions.days-of-week')}
                  </Label>
                  <Week
                    pressedWeek={handleChangePermissionDay}
                    index={index}
                    actives={permission.daysOfWeek}
                    color={theme.colors.primary500}
                    disabled={!isEditable}
                    t={t}
                  />
                </Col>
                {checkDaysOfWeekIsEmpty(index) && (
                  <ErrorMessage>
                    {t(
                      'put.access-data.time-permissions.feedback-days-of-week',
                    )}
                  </ErrorMessage>
                )}
              </Container>
            </Item>
          ))}
        </Wrapper>

        {isEditable && !(values?.length >= 3) && (
          <AddMoreContainer md={12} style={{ padding: 0 }}>
            <AddMore
              text={t(
                `put.access-data.time-permissions.add-${
                  values && !!values.length ? 'another-' : ''
                }access-period`,
              )}
              onClick={() =>
                add({
                  start: {
                    hours: '08',
                    minutes: '00',
                    time: '08:00',
                  },
                  end: {
                    hours: '18',
                    minutes: '00',
                    time: '18:00',
                  },
                  daysOfWeek: Array.from({ length: 7 }, () => false),
                })
              }
            />
          </AddMoreContainer>
        )}
        {hasErrorAccessPeriod && (
          <ErrorMessage>
            {t('put.access-data.time-permissions.error-message')}
          </ErrorMessage>
        )}
      </FormGroup>
    </>
  );
}

AccessRestrictions.propTypes = {
  values: propTypes.array,
  handleChange: propTypes.func.isRequired,
  isEditable: propTypes.bool,
  errors: propTypes.object,
};

AccessRestrictions.defaultProps = {
  values: [],
  isEditable: false,
  errors: {},
};
