import { Label } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { Container, Header, CustomInput } from './styles';

export default function AccessibilityTime({
  values,
  handleChange,
  isEditable,
}) {
  const [t] = useTranslation('user');

  return (
    <Container>
      <Header>
        <Label style={{ fontSize: '14px' }}>
          {t('put.access-data.accessibility.title')}
        </Label>
        <Label info style={{ fontSize: '14px' }}>
          {t('put.access-data.accessibility.info')}
        </Label>
      </Header>
      <CustomInput
        disabled={!isEditable}
        type="range"
        id="accessibilityTime"
        name="accessibilityTime"
        min={3}
        max={15}
        value={values.accessibilityTime}
        onChange={e =>
          handleChange('accessibilityTime', Number(e.target.value))
        }
      />
      <Label style={{ fontSize: '14px' }}>
        {`${t('put.access-data.accessibility.opening-time')} ${
          values.accessibilityTime
        }`}
      </Label>
      <div />
    </Container>
  );
}

AccessibilityTime.propTypes = {
  values: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  isEditable: propTypes.bool.isRequired,
};
