import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { FiFileText } from 'react-icons/fi';
import {
  PopoverBody as popoverBody,
  Popover as popover,
  PopoverHeader as popoverHeader,
} from '@kiper/ui';
import { CustomInput as customInput } from 'reactstrap';

export const Popover = styled(popover)``;
export const PopoverHeader = styled(popoverHeader)``;
export const PopoverBody = styled(popoverBody)`
  max-height: 420px;
  overflow: auto;
`;

export const CloseIcon = styled(MdClose)`
  font-size: 24px;
  color: ${props =>
    props.variant === 'danger'
      ? props.theme.colors.whiteSurface
      : props.theme.colors.secondary300};
  cursor: pointer;
`;

export const FileIcon = styled(FiFileText)``;

export const CustomInput = styled(customInput)`
  label {
    cursor: pointer;
    color: ${props => props.theme.colors.whiteSurface};
    font-weight: bold;
    width: 100%;
  }
`;

export const Card = styled.div`
  background: ${props => props.theme.colors.whiteSurface};
  border-radius: 5px;
  padding: 15px;
  margin-top: 20px;
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const WhiteLabelRow = styled(FlexRow)`
  align-items: center;
  color: ${props => props.theme.colors.whiteSurface};
  font-weight: bold;

  span {
    padding-left: 10px;
  }
`;

export const NotesText = styled.p`
  white-space: break-spaces;
`;
