import { get, remove, types, setMap } from '@kiper/cookie';
import { runtimeConfig } from '@kiper/fns';
import { auth } from '@kiper/monitoring-graphql';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { from } from 'apollo-link';

export const getHeaders = () => {
  const [
    authorization,
    userContextId,
    userPartnerContextId,
    refresh,
    topNodeId,
    topContextId,
  ] = get([
    types.authorization,
    types.context,
    types.partnerContext,
    types.refresh,
    types.topNodeId,
    types.topContextId,
  ]);

  return {
    userContextId,
    userPartnerContextId,
    topNodeId,
    topContextId,
    authorization: (authorization && `Bearer ${authorization}`) || '',
    applicationKey: runtimeConfig.APPLICATION_KEY_MONITORING,
    refresh,
  };
};

export const updateTokens = async () => {
  try {
    const client = new ApolloClient({
      cache: new InMemoryCache(),
      link: from([createHttpLink({ uri: runtimeConfig.RAZZLE_GRAPHQL_URL })]),
    });

    const [refresh] = get([types.refresh]);
    const res = await client.mutate({
      mutation: auth.refreshTokenMutation,
      variables: { refreshToken: refresh },
    });

    const tokens = res && res.data && res.data.refreshToken;
    setMap({
      [types.authorization]: tokens.accessToken,
      [types.refresh]: tokens.refreshToken,
    });

    return tokens;
  } catch (e) {
    remove([
      types.partnerContext,
      types.authorization,
      types.refresh,
      types.context,
      types.topNodeId,
      types.topContextId,
    ]);

    throw e;
  }
};
