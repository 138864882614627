import { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';
import { GuidedAttendanceContext, TriageContext } from '../../../../store';

const useForm = ({ nextAction, t }) => {
  const { setContextTree } = useContext(GuidedAttendanceContext);
  const { fillAction, setTriageContext } = useContext(TriageContext);
  const [selectedTriage, setSelectedTriage] = useState(false);

  const {
    setFieldValue,
    handleSubmit,
    values,
    errors,
    touched,
  } = useFormikContext();

  const schema = yup.object({
    stepAttendanceGetReason: yup.object({
      id: yup.number().required(t('common:feedback.required-field')),
      type: yup.number().required(t('common:feedback.required-field')),
      description: yup.string(),
      descriptionEs: yup.string().nullable(),
      descriptionEn: yup.string().nullable(),
    }),
  });

  const handleChangeTriageContext = (triage, eventId) => {
    const serializedParams = JSON.parse(triage.serializedParams);
    const triageFilterRulesIds = serializedParams?.condoRuleCategoryFilter
      ? serializedParams.condoRuleCategoryFilter
      : [];

    setTriageContext({
      triage,
      filterRulesIds: triageFilterRulesIds,
      eventId,
    });

    fillAction('triage', eventId);
  };

  const handleChange = (triage, eventId) => {
    const triageObject = {
      id: +triage?.id,
      type: triage?.type,
      description: triage?.description,
      descriptionEs: triage?.descriptionEs,
      descriptionEn: triage?.descriptionEn,
    };
    setFieldValue('stepAttendanceGetReason', triageObject);
    setSelectedTriage(true);

    handleChangeTriageContext(triage, eventId);
  };

  useEffect(() => {
    if (selectedTriage && !!values?.stepAttendanceGetReason?.id) {
      handleSubmit();
    }
  }, [values?.stepAttendanceGetReason]);

  useEffect(() => {
    if (!values?.stepAttendanceGetReason) {
      setFieldValue('stepAttendanceGetReason', {});
    }
    setContextTree({
      formikSchema: schema,
      nextAction: async stepData => {
        const nextStep = await nextAction(stepData);
        return { nextStep, currentStepData: stepData };
      },
    });
  }, []);

  return { values, handleChange, errors, touched };
};

export default useForm;
