import { useState, useRef, useMemo, useEffect } from 'react';
import propTypes from 'prop-types';
import { useLazyQuery } from 'react-apollo';
import { Table, TableLoader } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import { useFilters } from '@kiper/hooks';
import { blockedPartnerDwellers } from '@kiper/monitoring-graphql/dweller/list/query';
import UsersPageActions, { tabIds } from '../../../components/UsersPageActions';
import * as ListStyles from '../List/styles';
import { useCurrentLoggedContext } from '../../../hooks';
import UsersFilter from '../List/UsersFilter';
import { userProfile } from '../../../constants';
import EmptyState from '../../../components/EmptyState';
import BlockedListItem from './BlockedListItem';
import Pagination from '../../../components/Pagination';
import * as S from './styles';
import emptyStatePlaceholder from '../../../assets/images/empty-state-users.svg';
import initEmptyStatePlaceholder from '../../../assets/images/empty-state-users-initial.svg';

const column = x => `list.table-header.${x}`;

const initialFilters = {
  searchText: '',
  page: 1,
  pagesize: 10,
  orderby: 'condominiumName',
  ordination: false,
  profileIds: [],
};

export default function BlockedList({ route }) {
  const [t] = useTranslation('user');
  const { loggedContext } = useCurrentLoggedContext();
  const [emptyList, setEmptyList] = useState(false);

  const [getBlockedDwellers, { data, loading, refetch }] = useLazyQuery(
    blockedPartnerDwellers,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => setEmptyList(false),
    },
  );

  const {
    blockedPartnerDwellers: { collection, pagination },
  } = useMemo(
    () =>
      data ?? { blockedPartnerDwellers: { collection: [], pagination: {} } },
    [data],
  );

  const {
    filters,
    handlePageChange,
    handlePageSize,
    handleChange,
  } = useFilters(
    {
      ...initialFilters,
      sourceNodeId: loggedContext?.topNodeId,
    },
    {
      useFilterParams: true,
    },
  );

  const columns = useRef([
    {
      name: t(column('condominium')),
      align: 'left',
      width: '25%',
    },
    {
      name: t(column('unity-group')),
      align: 'left',
      width: '10%',
    },
    {
      name: t(column('name')),
      align: 'left',
      width: '15%',
    },
    {
      name: t(column('profile')),
      align: 'left',
      width: '10%',
    },
    {
      name: t(column('blocked-by')),
      align: 'left',
      width: '15%',
    },
    {
      name: t(column('blocked-date')),
      align: 'center',
      width: '5%',
    },
    { key: 6, name: t(column('unlock')), align: 'center', width: '10%' },
  ]);

  const userProfilesWithCondominiumAsPartner =
    loggedContext?.profileId === userProfile.doorman ||
    loggedContext?.profileId === userProfile.condominiumMaster;

  useEffect(() => {
    const { searchText, sourceNodeId } = filters;
    const canSearchNewDwellers =
      !!searchText ||
      sourceNodeId !== loggedContext?.topNodeId ||
      userProfilesWithCondominiumAsPartner;

    if (canSearchNewDwellers) getBlockedDwellers({ variables: { filters } });
    else setEmptyList(true);

    return () => null;
  }, [filters]);

  return (
    <ListStyles.Wrapper>
      <UsersPageActions
        t={t}
        route={route}
        title={t('blocked.title')}
        tabId={tabIds.BLOCKED}
      >
        <UsersFilter filters={filters} handleChange={handleChange} />

        {(emptyList && !loading) ||
        (collection && !collection[0] && !loading) ? (
          <>
            {emptyList ? (
              <EmptyState
                placeholder={initEmptyStatePlaceholder}
                text={t('blocked.init-empty-state')}
              />
            ) : (
              <EmptyState
                placeholder={emptyStatePlaceholder}
                text={t('blocked.empty-state')}
              />
            )}
          </>
        ) : (
          <S.TableContainer>
            <Table columns={columns}>
              {!loading && collection && (
                <tbody>
                  {collection.map(item => (
                    <BlockedListItem
                      key={item.personContextId}
                      data={item}
                      onSync={refetch}
                    />
                  ))}
                </tbody>
              )}
            </Table>
            {loading && <TableLoader />}
            <Pagination
              filters={filters}
              pagination={pagination}
              handlePageSize={handlePageSize}
              next={() => handlePageChange(1)}
              previous={() => handlePageChange(-1)}
            />
          </S.TableContainer>
        )}
      </UsersPageActions>
    </ListStyles.Wrapper>
  );
}

BlockedList.propTypes = {
  route: propTypes.object.isRequired,
};
