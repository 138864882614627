export const useAddItem = ({ values, handleChange }) => {
  const addItem = timePermission => {
    handleChange('contactSchedule', [...values, timePermission]);
  };

  const handleAddMoreItem = () => {
    const newPeriodItem = {
      start: {
        hours: '08',
        minutes: '00',
        time: '08:00',
      },
      end: {
        hours: '18',
        minutes: '00',
        time: '18:00',
      },
      daysOfWeek: new Array(7).fill(false),
    };

    addItem({ ...newPeriodItem });
  };

  return handleAddMoreItem;
};
