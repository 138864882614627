import styled from 'styled-components';
import { Flex, Text, Input as input } from '@kiper/ui';

export const UnlinkContainer = styled(Flex)`
  border-radius: 8px;
  background: ${props => props.theme.colors.danger50};
  padding: 11px 16px;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
  color: ${props => props.theme.colors.mediumBlack};
`;

export const Input = styled(input)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;

  &:disabled {
    color: ${props => props.theme.colors.secondary500};
  }
`;
