import styled from 'styled-components';
import { FormGroup as formGroup, Form as form } from 'reactstrap';
import { FiAlertCircle } from 'react-icons/fi';
import { Label as label } from '@kiper/ui';

export const FormGroup = styled(formGroup)``;

export const Form = styled(form)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Alert = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: ${props => props.theme.colors.warningAlert};
  color: rgba(0, 0, 0, 0.7);
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 8px;

  svg {
    min-width: 24px;
    height: auto;
  }
  label {
    margin: 0;
  }
`;

export const AlertIcon = styled(FiAlertCircle)``;

export const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CheckBoxContent = styled.div`
  max-width: fit-content;
  display: flex;
  align-items: center;

  label {
    margin: 0 0 0 4px;
  }
`;

export const Tip = styled(label)`
  margin: 4px 0 0 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
`;
