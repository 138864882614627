import Stream from './Stream';

export default class Player {
  constructor(canvasRef, url, imgRef, opts = {}) {
    const options = opts;
    const canvas = canvasRef.current;

    function scaleRect(srcSize, dstSize) {
      const ratio = Math.min(
        dstSize.width / srcSize.width,
        dstSize.height / srcSize.height,
      );
      const newRect = {
        x: 0,
        y: 0,
        width: srcSize.width * ratio,
        height: srcSize.height * ratio,
      };
      newRect.x = dstSize.width / 2 - newRect.width / 2;
      newRect.y = dstSize.height / 2 - newRect.height / 2;
      return newRect;
    }

    function updateFrame(img) {
      if (img && img.complete) {
        const srcRect = {
          x: 0,
          y: 0,
          width: img.naturalWidth,
          height: img.naturalHeight,
        };
        const dstRect = scaleRect(srcRect, {
          width: canvas.width,
          height: canvas.height,
        });
        try {
          const context = canvas.getContext('2d');

          context.drawImage(
            img,
            srcRect.x,
            srcRect.y,
            srcRect.width,
            srcRect.height,
            dstRect.x,
            dstRect.y,
            dstRect.width,
            dstRect.height,
          );
        } catch (e) {
          this.stop();
          throw e;
        }
      } else {
        console.error(`Image is not yet loaded.`);
      }
    }

    options.url = url;
    options.onFrame = updateFrame;
    options.img = imgRef?.current;
    options.onStart = () => {};
    options.onStop = () => {};

    this.stream = new Stream(options);

    this.start = () => {
      this.stream.start();
    };

    this.stop = () => {
      this.stream.stop();
    };
  }
}
