import gql from 'graphql-tag';

export const condominiumEventTypeHowToAct = gql`
  query condominiumEventTypeHowToAct(
    $condominiumContextId: ID!
    $eventType: Int!
  ) {
    condominiumEventTypeHowToAct(
      condominiumContextId: $condominiumContextId
      eventType: $eventType
    ) {
      howToAct
    }
  }
`;
