import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import propTypes from 'prop-types';
import { Button } from '@kiper/ui';
import { useToggle } from '@kiper/hooks';
import { MdExpandMore, MdLock } from 'react-icons/md';
import { FaDoorOpen } from 'react-icons/fa';
import ConfirmResyncDevices from '../../Modal/ConfirmResyncDevices';
import { useSync } from '../hooks';

export const HeaderButton = ({ condominium, t, showResyncCond }) => {
  const [dropdownOpen, toggleDropdown] = useToggle(false);
  const [openConfirmResync, toggleConfirmResync] = useToggle(false);

  const {
    loadingSync,
    loadingResync,
    enableSyncAction,
    syncUsers,
    resyncCondominium,
  } = useSync(condominium, t);

  if (!!condominium?.personContextId && !enableSyncAction) return <></>;

  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle tag="a">
          <Button rightIcon={<MdExpandMore />}>
            {loadingSync && t('common:synchronizing')}
            {loadingResync && t('common:resynchronizing')}
            {!loadingSync && !loadingResync && t('details.synchronize')}
          </Button>
        </DropdownToggle>
        <DropdownMenu>
          <Button
            block
            disabled={loadingSync || loadingResync}
            onClick={syncUsers}
            variant="text"
            color="neutral"
            icon={<FaDoorOpen />}
          >
            {t('details.all-users')}
          </Button>
          {showResyncCond && (
            <Button
              block
              disabled={loadingSync || loadingResync}
              onClick={toggleConfirmResync}
              variant="text"
              color="neutral"
              icon={<MdLock />}
            >
              {t('details.resync')}
            </Button>
          )}
        </DropdownMenu>
      </Dropdown>
      <ConfirmResyncDevices
        onToggle={toggleConfirmResync}
        onConfirm={resyncCondominium}
        loading={loadingResync}
        open={openConfirmResync}
        t={t}
      />
    </>
  );
};

HeaderButton.propTypes = {
  condominium: propTypes.object.isRequired,
  t: propTypes.func.isRequired,
  showResyncCond: propTypes.bool.isRequired,
};
