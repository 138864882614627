import styled from 'styled-components';

export const AddMore = styled.div`
  background: inherit;
  padding: 16px 0;
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border: none;
  cursor: pointer;
`;

export const Text = styled.span`
  width: 100%;
  padding: 0;
  text-align: center;
`;
