import styled from 'styled-components';

export const Wrapper = styled.div`
  display: block;
  width: 100%;
`;

export const TableContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 8px;
  border-radius: 4x;
  border: 1px solid ${props => props.theme.colors.secondary200};
`;
