import { useState } from 'react';

const useModalsFilter = ({ initialFilters }) => {
  const [filters, setFilters] = useState(initialFilters);

  const handlePageChange = (modalName, quantity) =>
    setFilters(x => ({
      ...x,
      [modalName]: { ...x[modalName], page: x[modalName].page + quantity },
    }));

  const handlePageSize = (modalName, pagesize) =>
    setFilters(x => ({
      ...x,
      [modalName]: { ...x[modalName], pagesize: Number(pagesize), page: 1 },
    }));

  const handleChange = (modalName, name, value) =>
    setFilters(x => ({
      ...x,
      [modalName]: { ...x[modalName], [name]: value.trim(), page: 1 },
    }));

  const handleSort = (modalName, orderby) =>
    setFilters(x => ({
      ...x,
      [modalName]: {
        ...x[modalName],
        orderby,
        ordination: orderby !== x.orderby ? false : !x.ordination,
      },
    }));

  return {
    filters,
    handlePageChange,
    handlePageSize,
    handleChange,
    handleSort,
    setFilters,
  };
};

export default useModalsFilter;
