import styled, { css } from 'styled-components';
import { Box as box, Flex, ScrollBar } from '@kiper/ui';

export const Box = styled(box)`
  height: 100vh;
`;

export const EventQueueContainer = styled(Flex)`
  padding-bottom: 8px;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 38px);
`;

export const Container = styled(Box)`
  overflow: hidden;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 4px 0px 4px ${({ theme }) => theme.colors.hoverGrey};
  z-index: 5;
  width: ${props => (props.hide ? 0 : '210px')};
  min-width: ${props => !props.hide && '210px'};
  gap: 8px;
`;

export const Ul = styled.ul`
  padding-inline-start: 15px;
`;

export const Li = styled.li`
  text-align: start;
`;

export const EventContainer = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border-width: 1;
  border: 1px solid;
  padding: 8px;
  width: 194px;
  min-width: 194px;
  max-width: 194px;
  max-height: min-content;
  cursor: pointer;

  color: ${props =>
    props.attend ? props.theme.colors.white : props.theme.colors.highBlack};

  ${({ critical, attend, theme, isLoading, isTelephony }) => {
    if (isLoading) {
      return css`
        background-color: ${theme.colors.warningPrimary};
        border-color: ${theme.colors.warningPrimary};
        color: ${theme.colors.white};
      `;
    }

    if (isTelephony && attend) {
      return css`
        background-color: ${theme.colors.violet500};
        border-color: ${theme.colors.violet500};
      `;
    }

    if (isTelephony && !attend) {
      return css`
        background-color: ${theme.colors.violet50};
        border-color: ${theme.colors.violet500};

        :hover {
          background-color: ${theme.colors.violet100};
        }

        div {
          span.event-title {
            color: ${theme.colors.violet500};
          }
        }
      `;
    }

    if (!critical && attend) {
      return css`
        background-color: ${theme.colors.primary500};
        border-color: ${theme.colors.primary500};
      `;
    }

    if (!critical && !attend) {
      return css`
        background-color: ${theme.colors.white};
        border-color: ${theme.colors.secondary500};

        :hover {
          background-color: ${theme.colors.secondary100};
        }
      `;
    }

    if (critical && attend) {
      return css`
        background-color: ${theme.colors.dangerSecondary};
        border-color: ${theme.colors.dangerSecondary};
      `;
    }

    if (critical && !attend) {
      return css`
        background-color: ${theme.colors.danger50};
        border-color: ${theme.colors.danger500};

        :hover {
          background-color: ${theme.colors.danger100};
        }
        div {
          span.event-title {
            color: ${theme.colors.danger500};
          }
        }
      `;
    }

    return null;
  }}
`;

export const Scrollbar = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;
