import propTypes from 'prop-types';
import { Text } from '@kiper/ui';
import DocumentInput from './DocumentInput';

const DocumentsSection = ({
  value,
  touch,
  error,
  setFieldValue,
  handleMaxCharacters,
  handleValidateDocument,
  checkFieldBeenValidated,
  documents,
  t,
}) => (
  <>
    {!!value('dweller') && !documents?.length && (
      <Text>
        {t('step-dweller-validation.feedback.error.uregistered-document')}
      </Text>
    )}
    {!!value('dweller') &&
      !!documents?.length &&
      documents?.map(document => (
        <DocumentInput
          key={document.type}
          document={document}
          value={value}
          touch={touch}
          error={error}
          setFieldValue={setFieldValue}
          handleMaxCharacters={handleMaxCharacters}
          handleValidateDocument={handleValidateDocument}
          checkFieldBeenValidated={checkFieldBeenValidated}
          t={t}
        />
      ))}
  </>
);

export default DocumentsSection;

DocumentsSection.propTypes = {
  documents: propTypes.arrayOf(
    propTypes.shape({
      type: propTypes.string,
      value: propTypes.string,
    }),
  ).isRequired,
  value: propTypes.func.isRequired,
  t: propTypes.func.isRequired,
  touch: propTypes.func.isRequired,
  error: propTypes.func.isRequired,
  setFieldValue: propTypes.func.isRequired,
  handleMaxCharacters: propTypes.func.isRequired,
  handleValidateDocument: propTypes.func.isRequired,
  checkFieldBeenValidated: propTypes.func.isRequired,
};
