import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 -25px;
  height: auto;
  margin-bottom: 24px;
  z-index: 3;
  padding-top: 24px;
  ${props => `
    padding-bottom: ${props?.withPaddingBottom ? '24px' : 0};
    background-color: ${props.background || props.theme.colorContainer};
    border-bottom: 1px solid ${props.theme.colors.secondary300};
  `}
`;

export default Container;
