import styled from 'styled-components';
import {
  Form as form,
  Input as input,
  Button as button,
  FormGroup as formGroup,
  Col as col,
} from 'reactstrap';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 25px;
  margin-right: 25px;
  a {
    text-decoration: none;
  }
`;

export const Form = styled(form)``;

export const Input = styled(input)`
  border-color: ${props => props.theme.colors.secondary400};
  width: 250px !important;
  ::placeholder {
    color: ${props => props.theme.colors.secondary400};
  }
`;

export const Col = styled(col)`
  padding-left: 0;
`;

export const Button = styled(button)`
  &:active {
    transform: translateY(2px);
  }
`;

export const FormGroup = styled(formGroup)`
  margin-bottom: 0;
`;
