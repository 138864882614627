import { useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { Box } from '@kiper/ui';
import { useToggle } from '@kiper/hooks';
import { useTranslation } from 'react-i18next';
import EventRow, {
  DefaultChildren,
  EventChildren,
  OwnerChildren,
  ActionChildren,
  UnityGroupChildren,
} from './Row';
import {
  Card,
  LoaderIcon,
  ErrorIcon,
  HeaderContainer,
  Row,
  Col,
  Label,
  Ul,
  Li,
} from './styles';
import { ReportHeader } from '../../../../components/ReportHeader';
import { ReportTable } from '../../../../components/ReportTable';
import { SCREEN_STATE } from '../../hooks/useReportFilters';
import { ScreenState } from '../../ScreenState';
import reportExportingDone from '../../../../assets/images/report-export-done.svg';

import { EventActivityModal } from '../../../../components/Modals';
import { useFeatureFlag } from '../../../../hooks';

const CONDOMINIUM_FILTER_KEY = 'condominiumTreeNodeId';

const EventsReport = ({
  eventsData: data,
  loading,
  screenState,
  paginationFilters,
  handlePageSize,
  handlePageChange,
  exportPdf,
  exportXls,
  exportCsv,
  handleCancelPrint,
  handlePrint,
  header,
}) => {
  const [t] = useTranslation('report');

  const [dropdownOpen, toggleDropdown] = useToggle(false);
  const [eventActivityModalOpened, setEventActivityModalOpened] = useState(
    false,
  );
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [currentEventDate, setCurrentEventDate] = useState(null);

  const toggleEventActivityModalOpened = eventId => {
    if (!eventActivityModalOpened) {
      setSelectedEventId(eventId);
      const currentEventSelected = data?.collection.find(
        event => event.eventId === eventId,
      );
      setCurrentEventDate(currentEventSelected?.eventCreationDate);
    }
    setEventActivityModalOpened(x => !x);
  };

  const { showFeature } = useFeatureFlag({
    flag: 'attendance',
    feature: 'eventActivity',
  });

  const { pagination, collection } = useMemo(() => {
    return { collection: data?.collection, pagination: data?.pagination };
  }, [data]);

  const columns = [
    {
      primary: 'eventDate',
      secondary: 'eventTime',
      name: t('events.columns.occurrence-date'),
      align: 'left',
      width: '10%',
      children: DefaultChildren,
    },
    {
      primary: 'eventType',
      secondary: 'placeName',
      name: t('events.columns.description'),
      align: 'left',
      width: '15%',
      children: EventChildren,
    },
    {
      primary: 'personName',
      secondary: 'personProfile',
      additionalInformation: 'additionalInformation',
      name: t('events.columns.triggered-by'),
      align: 'left',
      width: '10%',
      children: OwnerChildren,
    },
    {
      primary: 'unityGroupName',
      secondary: 'unityName',
      additionalInformation: 'additionalInformation',
      name: t('events.columns.unity-name'),
      align: 'left',
      width: '10%',
      children: UnityGroupChildren,
    },
    {
      primary: 'treatmentEndDate',
      secondary: 'treatmentEndTime',
      name: t('events.columns.end-date'),
      align: 'left',
      width: '10%',
      children: DefaultChildren,
    },
    {
      primary: 'treatment',
      name: t('events.columns.treatment'),
      align: 'left',
      width: '30%',
      printWidth: '40%',
      children: DefaultChildren,
    },
    {
      primary: 'eventId',
      align: 'right',
      width: '5%',
      printWidth: '0',
      children: ActionChildren,
      onClick: toggleEventActivityModalOpened,
      tooltipMessage: t('events.columns.activity-history'),
    },
  ];

  const selectedFilters = useMemo(() => header?.selectedFilters ?? {}, [
    header,
  ]);

  const showSelectedFilters = useMemo(
    () =>
      !!selectedFilters &&
      !!Object.keys(selectedFilters)?.filter(
        i => i !== CONDOMINIUM_FILTER_KEY && !!selectedFilters[i],
      )?.length,
    [selectedFilters],
  );

  return (
    <Box>
      <HeaderContainer>
        <Row>
          <Col>
            <Label bold fontSize={14}>
              {t('events.header.title')}
            </Label>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Label>
              <b>{`${t('events.header.generation-date')} `}</b>
              {header.generationDate}
            </Label>
          </Col>
          <Col>
            <Label>
              <b>{`${t('events.header.condominium')} `}</b>
              {selectedFilters[CONDOMINIUM_FILTER_KEY]}
            </Label>
          </Col>
        </Row>
        {showSelectedFilters && (
          <>
            <Row>
              <Col>
                <Label bold>{t('events.header.used-filters')}</Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Ul>
                  {Object.keys(selectedFilters)
                    .filter(i => i !== CONDOMINIUM_FILTER_KEY)
                    .map(key => {
                      const filter = selectedFilters[key];
                      if (!filter) return <Li key={key} />;
                      if (Array.isArray(filter)) {
                        return (
                          <Li key={key}>
                            <Label>
                              <b>{`${t(`events.filters.${key}`)}: `}</b>
                              {filter.map(item => item).join(', ')}
                            </Label>
                          </Li>
                        );
                      }
                      return (
                        <Li key={key}>
                          <Label>
                            <b>{`${t(`events.filters.${key}`)}: `}</b>
                            {filter}
                          </Label>
                        </Li>
                      );
                    })}
                </Ul>
              </Col>
            </Row>
          </>
        )}
      </HeaderContainer>
      <Card>
        <ReportHeader
          title={t('users.title')}
          dropdownOpened={dropdownOpen}
          exportPdf={exportPdf}
          exportXls={exportXls}
          exportCsv={exportCsv}
          toggleDropdown={toggleDropdown}
          dropdownDisabled={loading || collection?.length === 0}
        />

        <ReportTable
          columns={columns}
          collection={collection}
          row={EventRow}
          printing={screenState !== SCREEN_STATE.DATA}
          pagination={pagination}
          filters={paginationFilters}
          handlePageSize={handlePageSize}
          handleNextPage={() =>
            Boolean(pagination?.hasNext) && handlePageChange(1)
          }
          handlePreviousPage={() =>
            Boolean(pagination?.hasPrevious) && handlePageChange(-1)
          }
          loading={loading}
        />
        {screenState === SCREEN_STATE.GENERATING && (
          <ScreenState
            title={t('common:report.exporting-title')}
            message={t('common:report.exporting-message')}
            image={<LoaderIcon />}
            actions={[
              {
                text: t('common:report.cancel-button'),
                color: 'secondary',
                onClick: handleCancelPrint,
              },
            ]}
          />
        )}
        {screenState === SCREEN_STATE.DONE && (
          <ScreenState
            title={t('common:report.ready-title')}
            message={t('common:report.ready-message')}
            image={<img src={reportExportingDone} alt="reportExportingDone" />}
            actions={[
              {
                text: t('common:report.cancel-button'),
                color: 'secondary',
                onClick: handleCancelPrint,
              },
              {
                text: t('common:report.download-button'),
                color: 'primary',
                onClick: handlePrint,
                margin: true,
              },
            ]}
          />
        )}
        {screenState === SCREEN_STATE.ERROR && (
          <ScreenState
            title={t('common:report.error-title')}
            message={t('common:report.error-message')}
            image={<ErrorIcon />}
            actions={[
              {
                text: t('common:report.cancel-button'),
                color: 'secondary',
                onClick: handleCancelPrint,
              },
            ]}
          />
        )}
        {showFeature && eventActivityModalOpened && (
          <EventActivityModal
            eventId={selectedEventId}
            eventDate={currentEventDate}
            onToggle={toggleEventActivityModalOpened}
          />
        )}
      </Card>
    </Box>
  );
};

EventsReport.propTypes = {
  loading: propTypes.bool,
  screenState: propTypes.string,
  eventsData: propTypes.object,
  paginationFilters: propTypes.object.isRequired,
  handlePageSize: propTypes.func.isRequired,
  handlePageChange: propTypes.func.isRequired,
  exportPdf: propTypes.func.isRequired,
  exportXls: propTypes.func.isRequired,
  exportCsv: propTypes.func.isRequired,
  handleCancelPrint: propTypes.func.isRequired,
  handlePrint: propTypes.func.isRequired,
  header: propTypes.object.isRequired,
};

EventsReport.defaultProps = {
  loading: false,
  screenState: SCREEN_STATE.EMPTY,
  eventsData: {
    collection: [],
    pagination: {
      page: 1,
      totalPages: 1,
      pageSize: 10,
      totalResults: 0,
      hasPrevious: false,
      hasNext: false,
    },
  },
};

export default EventsReport;
