import styled from 'styled-components';
import {
  ButtonDropdown as btnDropdown,
  DropdownToggle as dropdownToggle,
  DropdownMenu as dropdownMenu,
  DropdownItem as dropdownItem,
} from 'reactstrap';
import { Card as card } from '@kiper/ui';

const hideWhenPrint = `
  @media print {
    display: none;
    height: 0;
  }
`;

export const Header = styled(card.Header)`
  ${hideWhenPrint};
  border-bottom: 0;
`;

export const ReportTitle = styled.span`
  color: ${props => props.theme.colors.highBlack};
  font-size: 16px;
  font-weight: 600;
  ${hideWhenPrint};
`;

export const ButtonDropdown = styled(btnDropdown)`
  ${hideWhenPrint};
`;
export const DropdownToggle = styled(dropdownToggle)``;
export const DropdownMenu = styled(dropdownMenu)``;
