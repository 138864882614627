import styled from 'styled-components';
import { Select } from '@kiper/ui';

export const StyledSelect = styled(Select)`
  width: ${props => (props.$fullwidth ? '100%' : '200px')};
  max-width: ${props => (props.$fullwidth ? '100%' : '200px')};

  ${props => !props.$fullwidth && `margin-right: 10px;`};

  .select__placeholder {
    color: ${props => props.theme.colors.secondary900};
  }

  .select__control {
    border-radius: 8px;
    border-color: ${props => props.theme.colors.secondary500};
    background-color: ${props => props.theme.colors.secondary50};
    max-height: 36px;
    min-height: 34px;
    cursor: pointer;

    .select__value-container {
      margin-top: -3px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  svg {
    color: ${props => props.theme.colors.secondary900};
  }
`;
