import styled, { css } from 'styled-components';
import {
  Button,
  Flex,
  ListGroup as listGroup,
  ListGroupItem as listItem,
  Text as text,
} from '@kiper/ui';
import { Col, Input as InputRS } from 'reactstrap';
import { MdOutlineArrowForwardIos } from 'react-icons/md';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

export const OptionsContainer = styled(Flex)`
  gap: 16px;
  justify-content: flex-start;
  width: 100%;
`;

export const ListGroup = styled(listGroup)`
  width: 100%;
  overflow-y: auto;
  display: block;
  max-height: ${props =>
    !!props.maxHeight && `calc(${props.maxHeight} - 48px)`};
`;

export const ListGroupItem = styled(listItem)`
  overflow: hidden;
  padding: 12px;
  min-height: 54px;
  align-items: flex-start;
  & + & {
    border-top-width: 1px;
    margin-top: 8px;
  }
`;

export const LabelText = styled(text)`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  width: max-content;
  color: ${({ theme, color }) =>
    color ? theme.colors[color] : theme.colors.blackShades(0.8)};
  opacity: 0.9;
`;

export const ValueText = styled(text)`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: ${({ color, theme }) =>
    color ? theme.colors[color] : theme.colors.blackShades(0.9)};
`;

export const PlaceCol = styled(Col)`
  padding: 0;
  & + & {
    margin-left: 4px;
  }
`;

export const ListContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const EmptyStateContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 8px;

  @media screen and (min-width: 1366px) {
    gap: 8px;
  }

  @media screen and (min-width: 1920px) {
    gap: 16px;
  }
`;

export const Img = styled.img`
  @media screen and (min-width: 1366px) {
    width: 160px;
  }

  @media screen and (min-width: 1920px) {
    width: 220px;
  }
`;

export const TitleEmptyState = styled(text)`
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: ${props => props.theme.colors.highBlack};

  @media screen and (min-width: 1366px) {
    font-size: 20px;
    line-height: 27px;
  }

  @media screen and (min-width: 1920px) {
    font-size: 24px;
    line-height: 33px;
  }
`;

export const TextEmptyState = styled(text)`
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: ${props => props.theme.colors.highBlack};

  @media screen and (min-width: 1366px) {
    font-size: 14px;
    line-height: 19px;
  }

  @media screen and (min-width: 1920px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const SearchContainer = styled(Flex)`
  position: relative;
`;

export const ClearButton = styled(Button)`
  position: absolute;
  right: 0;

  &:hover {
    color: ${({ theme }) => theme.colors.danger500} !important;
    background: transparent !important;
  }
`;

export const NextStepButton = styled(Button)`
  &:hover {
    background: transparent !important;
  }
`;

export const Text = styled(text)`
  color: ${props => props.theme.colors.primary500};
  text-decoration-line: underline;
`;

export const WrapperSelectOptions = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: auto;
  top: 44px;
  box-sizing: border-box;
  background: ${props => props.theme.colors.white};
  z-index: 1;
  gap: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: ${props => !!props.maxHeight && `calc(${props.maxHeight} - 8px)`};
`;

export const ExpandMoreIcon = styled(MdOutlineArrowForwardIos)`
  transform: rotate(90deg);
`;
export const InputIcon = styled.div`
  pointer-events: none;
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.blackShades(0.8)};

  svg {
    height: 16px;
    width: auto;
  }
`;

export const WrapperSearchInput = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;

export const Input = styled(InputRS)`
  font-family: 'Nunito Sans', sans-serif;
  flex: 1;
  width: auto;
  box-sizing: border-box;
  height: 24px;
  padding: 8px;
  border-radius: 8px;
  border: none;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  outline: none;

  color: ${({ theme }) => theme.colors.blackShades(0.8)};

  &:disabled {
    background-color: inherit;
    opacity: 50%;
  }

  :focus,
  :focus-within,
  :focus-visible {
    outline: none;
    box-shadow: none !important;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary400};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    color: ${({ theme }) => theme.colors.blackShades(0.8)};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputGroup = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  height: 36px;
  width: auto;
  transition: border 0.1s ease-in;
  gap: 8px;
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.danger500 : theme.colors.secondary400};

  &:focus-visible,
  &:focus-within,
  &:focus {
    outline: none;

    ${({ theme }) => css`
      border: 1px solid ${theme.colors.primary500};
      svg {
        color: ${theme.colors.primary500};
        transition: color 0.2s ease-in;
      }

      button {
        border-left-color: ${theme.colors.primary500};
        transition: border-left-color 0.2s ease-in;
      }
    `}
  }
`;

export const IconButton = styled.button`
  all: unset;
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primary500};

  svg {
    height: 16px;
    width: auto;
  }

  cursor: pointer;
`;
