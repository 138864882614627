import propTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Modal, Flex, Box, Text, KiperButton } from '@kiper/ui';

import noKiperVoice from '../../../assets/images/noKiperVoice.svg';

function NoKiperVoiceModal({ onToggle, open }) {
  const [t] = useTranslation('modals/no-kiper-voice-modal');

  return (
    <Modal
      open={open}
      id="no-kiper-voice-modal"
      data-cy="no-kiper-voice-modal"
      toggle={onToggle}
      size="md"
    >
      <Flex
        py="50px"
        px="50px"
        flexDirection="column"
        aligntItems="center"
        width="100%"
      >
        <Flex mb="20px" justifyContent="center">
          <img
            style={{ maxWidth: '320px' }}
            src={noKiperVoice}
            alt={t('image-alt"')}
          />
        </Flex>
        <Text textAlign="center" mb="12px" fontSize="20px" fontWeight="500">
          {t('cannot-make-calls')}
        </Text>
        <Text textAlign="justify" mb="30px" fontSize="14px" fontWeight="400">
          {t('kiper-voice-not-linked')}
        </Text>
        <Flex justifyContent="center">
          <Box mr="10px">
            <KiperButton
              data-cy="close-kiper-voice-modal"
              onClick={onToggle}
              variant="outlined"
              color="secondary"
            >
              {t('close')}
            </KiperButton>
          </Box>
          <KiperButton
            data-cy="open-kiper-voice-manual"
            onClick={() =>
              window.open(
                'https://announcekit.app/kiper-monitoring/lancamentos/integracao-com-o-kiper-voice-2VREA0',
                '_blank',
              )
            }
          >
            {t('know-how-to-do')}
          </KiperButton>
        </Flex>
      </Flex>
    </Modal>
  );
}

export default NoKiperVoiceModal;

NoKiperVoiceModal.propTypes = {
  onToggle: propTypes.func.isRequired,
  open: propTypes.bool.isRequired,
};
