import { FormGroup as formgroup } from 'reactstrap';
import styled from 'styled-components';

export const FormGroup = styled(formgroup)`
  margin-bottom: 12px;
`;

export const ToolTipContainer = styled.div`
  display: flex;
  align-items: center;

  label {
    width: auto;
  }
`;

export const ToolTipIcon = styled.span`
  margin: -10px 5px 0;
  font-weight: 800;
  cursor: help;
  width: 15px;
  height: 15px;
  text-align: center;
  font-size: 11px;
  background-color: ${props => `${props.theme.colors.secondary400}`};
  color: #fff;
  border-radius: 50%;
`;
