import { ScrollBar } from '@kiper/ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.colors.white};
  height: 100vh;

  @media screen and (min-width: 1980px) {
    width: 500px;
  }

  @media screen and (max-width: 1920px) {
    width: 500px;
  }

  @media screen and (max-width: 1600px) {
    width: 250px;
  }
`;

export const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  width: 100%;
  height: 3.5rem;
  background-color: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.secondary300};
  color: ${props => props.theme.colors.highBlack};
  font-size: 16px;
`;

export const SidebarBody = styled.div`
  padding: 16px;
  color: ${props => props.theme.colors.highBlack};
  font-size: 16px;
  display: relative;
  width: 100%;
  height: 100%;
`;

export const Scroll = styled(ScrollBar)`
  width: 100%;
`;
