import { useMemo } from 'react';
import propTypes from 'prop-types';
import { Tooltip, KiperButton } from '@kiper/ui';
import { useSwal } from '@kiper/hooks';

import { FaSyncAlt } from 'react-icons/fa';
import { adamSender } from '@kiper/monitoring-graphql';
import { apolloErrorHandler } from '@kiper/fns';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';

export default function AdamSender({
  identifier,
  type,
  name,
  id,
  status,
  tooltip,
  refetch,
  ...props
}) {
  const { toast } = useSwal();
  const [t] = useTranslation('common');

  const [sync, { loading }] = useMutation(adamSender.sync, {
    variables: { identifier, type },
    onError: args => {
      const formattedErrors = apolloErrorHandler(args);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
    onCompleted: () => {
      toast.fire({ title: t('sync-success'), icon: 'success' });

      if (type === 'device') {
        refetch();
      }
    },
  });

  const isSynchronized = useMemo(
    () => [AdamSender.syncStatusMap.synchronizing].includes(status),
    [status],
  );
  return (
    <>
      <KiperButton
        {...props}
        name={name}
        id={id || name || 'default'}
        disabled={loading || isSynchronized}
        size="sm"
        color="primary"
        icon={<FaSyncAlt />}
        onClick={sync}
        loading={loading}
      />

      <Tooltip placement="right" target={id || name || 'default'}>
        {t(tooltip)}
      </Tooltip>
    </>
  );
}

AdamSender.propTypes = {
  identifier: propTypes.number.isRequired,
  type: propTypes.oneOf(['guest', 'invite', 'condominium', 'device'])
    .isRequired,
  status: propTypes.oneOf([0, 1, 2, 3]).isRequired,
  name: propTypes.string,
  tooltip: propTypes.string,
  id: propTypes.string,
  refetch: propTypes.func,
};

AdamSender.defaultProps = {
  tooltip: 'sync',
  name: '',
  id: '',
  refetch: () => {},
};

AdamSender.syncStatusOptions = [
  { id: 0, name: 'not_synced' },
  { id: 1, name: 'synchronizing' },
  { id: 2, name: 'synchronized' },
  { id: 3, name: 'cpu_not_found' },
];

AdamSender.syncStatusMap = AdamSender.syncStatusOptions.reduce((acc, curr) => {
  acc[curr.name] = curr.id;
  return acc;
}, {});
