import { useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { useMutation, useQuery } from 'react-apollo';
import { useFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { mutations, queries } from '@kiper/monitoring-graphql/dweller/detail';
import {
  inviteById,
  guestByInvite,
  inviteUpdate,
} from '@kiper/monitoring-graphql/invite';
import {
  DateTimePicker,
  Input,
  KiperButton,
  Flex,
  Modal,
  Text,
  ErrorMessage,
  CustomInput,
  Select,
} from '@kiper/ui';
import { MdAdd, MdPhone, MdRemove } from 'react-icons/md';
import { apolloErrorHandler, formatDate, isValidDate } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import RegisterGuestListModal from './RegisterGuestListModal';

const initialFilters = {
  page: 1,
  pagesize: 999,
  orderby: 'guest.name',
  ordination: false,
};

const tKey = sufix => `put.access-data.expected-guest.${sufix}`;

function ExpectedGuestModal({
  filters,
  editionMode,
  onToggle,
  personContextId,
  inviteId,
  user,
}) {
  const { t } = useTranslation('user');

  const { toast } = useSwal();

  const [showRegisterGuestListModal, setShowRegisterGuestListModal] = useState(
    false,
  );

  const { data: invite } = useQuery(inviteById, {
    variables: { inviteId },
    skip: !inviteId,
  });

  const { data: guests } = useQuery(guestByInvite, {
    variables: { inviteId, filters: initialFilters },
    skip: !inviteId,
  });

  const { data: accessProfiles, loading: loadingProfiles } = useQuery(
    queries.accessProfilesByDweller,
    {
      variables: { personContextId },
      skip: !personContextId,
    },
  );

  const [createExpectedGuest, { loading }] = useMutation(
    mutations.createExpectedGuestsByDweller,
    {
      onCompleted: () => {
        toast.fire({
          icon: 'success',
          title: t(tKey('feedbacks.created-successfully')),
        });
        onToggle(false);
      },
      update: (cache, res) => {
        const expectedGuestsQuery = {
          query: queries.expectedGuestsByDweller,
          variables: {
            personContextId,
            filters,
          },
        };

        try {
          const { expectedGuestsByDweller } = cache.readQuery(
            expectedGuestsQuery,
          );

          const newExpectedGuestsCollection =
            expectedGuestsByDweller.collection;

          const newExpectedGuest = {
            endDate: res?.data?.dwellerCreateExpectedGuest.end.slice('Z', -1),
            startDate: res?.data?.dwellerCreateExpectedGuest.start.slice(
              'Z',
              -1,
            ),
            inviteId: `${res?.data?.dwellerCreateExpectedGuest.id}`,
            hostName: res?.data?.dwellerCreateExpectedGuest.name,
            createPersonInvite:
              res?.data?.dwellerCreateExpectedGuest.createPersonInvite.name,
            inviteName: res?.data?.dwellerCreateExpectedGuest.name,
          };

          newExpectedGuestsCollection.push(newExpectedGuest);

          return cache.writeQuery({
            ...expectedGuestsQuery,
            data: {
              expectedGuestsByDweller: {
                ...expectedGuestsByDweller,
                collection: newExpectedGuestsCollection.sort((a, b) =>
                  moment(b.endDate).isAfter(a.endDate) ? 1 : -1,
                ),
                pagination: {
                  ...expectedGuestsByDweller.pagination,
                  totalResults:
                    expectedGuestsByDweller.pagination.totalResults + 1,
                },
              },
            },
          });
        } catch (err) {
          return err;
        }
      },
      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length) {
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
        }
      },
      refetchQueries: [],
    },
  );

  const [updateExpectedGuest, { loading: updateLoading }] = useMutation(
    inviteUpdate,
    {
      onCompleted: () => {
        toast.fire({
          icon: 'success',
          title: t(tKey('feedbacks.updated-successfully')),
        });
        onToggle(false);
      },
      refetchQueries: [
        { query: inviteById, variables: { inviteId } },
        {
          query: guestByInvite,
          variables: { inviteId, filters: initialFilters },
        },
      ],
      update: (cache, res) => {
        const expectedGuestsQuery = {
          query: queries.expectedGuestsByDweller,
          variables: {
            personContextId,
            filters,
          },
        };

        try {
          const { expectedGuestsByDweller } = cache.readQuery(
            expectedGuestsQuery,
          );

          const newExpectedGuest = {
            endDate: res?.data?.inviteUpdate.end.slice('Z', -1),
            startDate: res?.data?.inviteUpdate.start.slice('Z', -1),
            inviteId: `${res?.data?.inviteUpdate.id}`,
            hostName: res?.data?.inviteUpdate.name,
            createPersonInvite: res?.data?.inviteUpdate.createPersonInvite.name,
            inviteName: res?.data?.inviteUpdate.name,
          };

          const newExpectedGuestsCollection = expectedGuestsByDweller.collection
            .map(i =>
              i.inviteId === newExpectedGuest.inviteId ? newExpectedGuest : i,
            )
            .sort((a, b) => (moment(b.endDate).isAfter(a.endDate) ? 1 : -1));

          return cache.writeQuery({
            ...expectedGuestsQuery,
            data: {
              expectedGuestsByDweller: {
                ...expectedGuestsByDweller,
                collection: newExpectedGuestsCollection,
              },
            },
          });
        } catch (err) {
          return err;
        }
      },
      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length) {
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
        }
      },
    },
  );
  const onSubmit = values => {
    const inviteds = values?.guests?.map(guest => {
      if (editionMode) {
        return {
          id: guest.id || 0,
          name: guest.name,
          PhoneNumbers: guest?.phone
            ? [guest.phone?.replace(/\s/gi, '')]
            : null,
          documents: guest?.document
            ? [{ value: guest?.document, name: 'RG' }]
            : null,
        };
      }

      return {
        id: 0,
        name: guest.name,
        PhoneNumbers: guest?.phone ? [guest.phone?.replace(/\s/gi, '')] : null,
        documents: guest?.document
          ? [{ value: guest?.document, name: 'RG' }]
          : null,
      };
    });
    if (editionMode) {
      updateExpectedGuest({
        variables: {
          inviteId,
          expectedGuests: {
            id: inviteId,
            name: values.name,
            accessProfileId: values?.accessProfileId?.value,
            Start: values.start,
            End: values.end,
            Inviteds: inviteds,
          },
        },
      });
    } else {
      createExpectedGuest({
        variables: {
          personContextId,
          expectedGuests: {
            id: null,
            name: values.name,
            accessProfileId: values?.accessProfileId?.value,
            Start: values.start,
            End: values.end,
            Inviteds: inviteds,
          },
        },
      });
    }
  };

  const {
    values,
    setFieldValue,
    errors,
    touched,
    setValues,
    handleSubmit,
    setFieldTouched,
  } = useFormik({
    onSubmit,
    validationSchema: yup.object({
      name: yup.string().required(),
      accessProfileId: yup.object(),
      guests: yup.array().of(
        yup
          .object()
          .shape({
            name: yup.string().required(t('common:feedback.required-field')),
            document: yup.string().nullable(),
            phone: yup
              .string()
              .test({
                message: t('feedback.phone'),
                test: a =>
                  a ? isPossiblePhoneNumber(a.replace(/\s/gi, '')) : true,
              })
              .nullable(),
          })
          .nullable(),
      ),
      start: yup
        .string()
        .test(
          'oneOfRequired',
          t(tKey('table.invalid.date')),
          val => val && isValidDate(new Date(val)),
        )
        .required(t('common:feedback.required-field'))
        .nullable(),
      end: yup
        .string()
        .test(
          'oneOfRequired',
          t(tKey('table.invalid.date')),
          val => val && isValidDate(new Date(val)),
        )
        .required(t('common:feedback.required-field'))
        .nullable(),
    }),
    initialValues: {
      name: t(tKey('invite-from'), {
        username: user?.name || '',
      }),
      start: '',
      end: '',
      guests: [{ name: '', document: '', phone: '' }],
    },
  });

  const formatToDateTime = date =>
    new Date(formatDate(date, 'YYYY/MM/DD HH:mm').formatted);

  useEffect(() => {
    if (invite) {
      setValues({
        name: invite.invite.name,
        start: formatToDateTime(invite.invite.start),
        end: formatToDateTime(invite.invite.end),
        accessProfiles: accessProfiles?.accessProfilesByDweller?.map(item => ({
          label: item.name,
          value: item.id,
        })),
        accessProfileId: {
          label: invite?.invite?.accessProfiles?.[0]?.name,
          value: invite?.invite?.accessProfiles?.[0]?.id,
        },
        guests: guests?.guests?.collection?.map(item => {
          return {
            id: item.id,
            name: item.name,
            document: item?.guestParams?.[0]?.document,
            phone: item.phoneNumber,
          };
        }) || [{ name: '', document: '', phone: '' }],
      });
    } else {
      const accessProfile = accessProfiles?.accessProfilesByDweller?.[0];
      setValues({
        accessProfiles: accessProfiles?.accessProfilesByDweller?.map(item => ({
          label: item.name,
          value: item.id,
        })),
        accessProfileId: {
          label: accessProfile?.name,
          value: accessProfile?.id,
        },
        guests: [{ name: '', document: '', phone: '' }],
      });
    }
  }, [inviteId, invite, guests, accessProfiles]);

  const handleToggleModal = () => {
    setShowRegisterGuestListModal(!showRegisterGuestListModal);
  };

  const guestCount = useMemo(() => {
    return values.guests.filter(guest => guest.name).length;
  }, [values.guests]);

  const guestTranslationCounter =
    guestCount === 1
      ? 'put.access-data.expected-guest.guest.add-one-guest'
      : 'put.access-data.expected-guest.guest.add-guests';

  return (
    <Modal title={t(tKey('title-modal'))} open toggle={onToggle} size="xl">
      <Flex flexDirection="column">
        <Flex flex={1} flexDirection="column">
          <Flex flex={1} mb="8px" flexDirection="column">
            <Text mb="5px" color="mediumBlack" fontSize="14px">
              {t(tKey('table.name'))}
            </Text>
            <Input
              placeholder={t(tKey('name-placeholder'))}
              value={values?.name}
              type="text"
              name="name"
              onChange={(name, value) => setFieldValue(name, value)}
              invalid={!!errors?.name && touched?.name}
            />
          </Flex>
          <Flex flex={1}>
            <Flex flex={1} mb="8px" flexDirection="column">
              <Text mb="5px" color="mediumBlack" fontSize="14px">
                {t(tKey('table.start-date'))}
              </Text>
              <DateTimePicker
                flex={1}
                mr="15px"
                name="start"
                value={values?.start}
                onChange={value => {
                  setFieldValue('start', value);
                }}
                invalid={touched.start && !!errors.start}
              />
              {touched.start && !!errors.start && (
                <ErrorMessage>{errors.start}</ErrorMessage>
              )}
            </Flex>
            <Flex flex={1} flexDirection="column">
              <Text mb="5px" color="mediumBlack" fontSize="14px">
                {t(tKey('table.end-date'))}
              </Text>
              <DateTimePicker
                flex={1}
                name="end"
                value={values?.end}
                onChange={value => setFieldValue('end', value)}
                invalid={touched.end && !!errors.end}
              />
              {touched.end && !!errors.end && (
                <ErrorMessage>{errors.end}</ErrorMessage>
              )}
            </Flex>
          </Flex>
          {!!values?.accessProfiles?.length && (
            <Flex flex={1}>
              <Flex flex={1} flexDirection="column">
                <Text mb="5px" color="mediumBlack" fontSize="14px">
                  {t(tKey('table.access-profile'))}
                </Text>
                <Select
                  value={values?.accessProfileId}
                  name="accessProfileId"
                  onChange={value => setFieldValue('accessProfileId', value)}
                  isLoading={loadingProfiles}
                  options={values?.accessProfiles}
                  width="100%"
                />
              </Flex>
            </Flex>
          )}
          <Flex mt="20px" mb="8px" justifyContent="space-between">
            <Text fontSize="16px" fontWeight="600">
              {t(guestTranslationCounter, {
                guestCount,
              })}
            </Text>
            <KiperButton type="button" onClick={handleToggleModal}>
              {t(tKey('guest.add-guest-list'))}
            </KiperButton>
            {showRegisterGuestListModal && (
              <RegisterGuestListModal
                values={values}
                setFieldValue={setFieldValue}
                onToggle={handleToggleModal}
              />
            )}
          </Flex>

          <Flex flexDirection="column" flex={1}>
            {values?.guests?.map((guest, i) => {
              return (
                <Flex key={i}>
                  <Flex mb="10px" mr="16px" flex={1} flexDirection="column">
                    <Text mb="5px" color="mediumBlack" fontSize="14px">
                      {t(tKey('guest.name'))}
                    </Text>
                    <Input
                      placeholder={t(tKey('guest.name-placeholder'))}
                      value={values.guests?.[i]?.name}
                      type="text"
                      name={`guests[${i}].name`}
                      onChange={(name, value) => setFieldValue(name, value)}
                      invalid={
                        !!errors?.guests?.[i]?.name &&
                        touched?.guests?.[i]?.name
                      }
                    />
                    {touched?.guests?.[i]?.name &&
                      !!errors?.guests?.[i]?.name && (
                        <ErrorMessage>{errors?.guests?.[i]?.name}</ErrorMessage>
                      )}
                  </Flex>

                  <Flex flex={1} flexDirection="column" mr="15px">
                    <Text mb="5px" color="mediumBlack" fontSize="14px">
                      {t(tKey('guest.document'))}
                    </Text>

                    <Input
                      placeholder={t(tKey('guest.document-placeholder'))}
                      value={values.guests?.[i]?.document}
                      type="text"
                      name={`guests[${i}].document`}
                      onChange={(name, value) => setFieldValue(name, value)}
                      invalid={
                        !!errors?.guests?.[i]?.document &&
                        touched?.guests?.[i]?.document
                      }
                    />
                  </Flex>

                  <Flex flex={1} flexDirection="column" mr="8px">
                    <Text mb="5px" color="mediumBlack" fontSize="14px">
                      {t(tKey('guest.phone'))}
                    </Text>
                    <Flex alignItems="center">
                      <Flex flex={1} mr="8px">
                        <CustomInput
                          isPhoneNumber
                          placeholder={t(tKey('guest.phone-placeholder'))}
                          icon={<MdPhone />}
                          value={values.guests?.[i]?.phone}
                          type="text"
                          name={`guests[${i}].phone`}
                          onChange={(name, value) => setFieldValue(name, value)}
                          error={
                            touched?.guests?.[i]?.phone &&
                            errors?.guests?.[i]?.phone
                          }
                          onInput={() =>
                            setFieldTouched(`guests[${i}].phone`, true, true)
                          }
                        />
                      </Flex>
                      {values?.guests?.length > 1 && (
                        <KiperButton
                          size="sm"
                          rounded
                          color="danger"
                          variant="out"
                          icon={<MdRemove />}
                          onClick={() =>
                            setFieldValue(
                              'guests',
                              values.guests.filter((a, index) => i !== index),
                            )
                          }
                        />
                      )}
                      {i === values?.guests?.length - 1 && (
                        <Flex ml="6px">
                          <KiperButton
                            size="sm"
                            rounded
                            variant="out"
                            icon={<MdAdd />}
                            onClick={() =>
                              setFieldValue(
                                'guests',
                                values.guests.concat({
                                  name: '',
                                  document: '',
                                  phone: '',
                                }),
                              )
                            }
                          />
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
        <Flex justifyContent="flex-end" margin="15px 0 0 0">
          <KiperButton
            type="button"
            variant="outlined"
            onClick={() => {
              onToggle(false);
            }}
          >
            {t(tKey('cancel'))}
          </KiperButton>
          <Flex ml="16px">
            <KiperButton
              type="submit"
              disabled={loading || updateLoading}
              loading={loading || updateLoading}
              onClick={handleSubmit}
            >
              {loading || updateLoading
                ? t('buttons:saving')
                : t('buttons:save')}
            </KiperButton>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
}

export default ExpectedGuestModal;

ExpectedGuestModal.propTypes = {
  onToggle: propTypes.func.isRequired,
  editionMode: propTypes.bool,
  user: propTypes.object.isRequired,
  personContextId: propTypes.number.isRequired,
  inviteId: propTypes.number.isRequired,
  filters: propTypes.object.isRequired,
};

ExpectedGuestModal.defaultProps = {
  editionMode: false,
};
