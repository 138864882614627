import styled, { css } from 'styled-components';
import { KiperRadioCustomButton, Flex, KiperButton } from '@kiper/ui';
import { MdArrowForward } from 'react-icons/md';

export const FlexContent = styled(Flex)`
  border-top: 2px solid ${props => props.theme.colors.secondary300};
  margin: 0;
  padding: 16px;
  overflow: auto;
  background-color: ${props => props.theme.colors.white};
  height: 100%;
  width: calc(100% + 2px);
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;

  @media (max-width: 1465px) {
    max-width: 556px;
  }

  @media (max-width: 1260px) {
    max-width: 340px;
  }
`;

export const CameraWrapper = styled(Flex)`
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin: 0 8px 3rem 5px;
`;

export const OptionsContainer = styled(Flex)`
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
`;

export const Button = styled(KiperRadioCustomButton)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 10px;
  width: 100%;
  border-radius: 4px;
  margin: 6px 8px;
  box-sizing: border-box;
  width: calc(768px / 3 - 16px);
  border: 1px solid ${props => props.theme.colors.secondary800};
  transition: all 0.3s;

  @media (max-width: 1465px) {
    width: calc(556px / 3 - 16px);
  }

  @media (max-width: 1260px) {
    width: calc(340px / 3 - 12px);
    margin: 4px 5px;
  }

  ${props =>
    props.checked &&
    css`
      box-shadow: 0 0 0 1px ${props.theme.colors.primary500};
      background-color: ${props.theme.colors.primary20};
      border-color: ${props.theme.colors.primary500};
      color: ${props.theme.colors.primary500} !important;
      transition: all 0.3s;

      &:hover {
        background-color: ${props.theme.colors.primary50} !important;
      }
    `}
`;

export const Title = styled.div`
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  display: flex;
  align-items: center;
`;

export const TitleIcon = styled.span`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 2px solid ${props => props.theme.colors.primary500};
  border-radius: 100%;
  font-size: 20px;
  line-height: 24px;
  margin-left: -36px;
  margin-right: 16px;
  color: ${props => props.theme.colors.primary500};
`;

export const ContinueButton = styled(KiperButton)`
  min-width: 144px;
  height: 38px;
  border-color: ${props => props.theme.colors.secondary800};
  color: ${props => props.theme.colors.secondary700};
  transition: all 0.3s;

  span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  ${props =>
    props.active &&
    css`
      border-color: ${props.theme.colors.primary500};
      background-color: ${props.theme.colors.white};

      span {
        color: ${props.theme.colors.primary500} !important;
      }

      &:hover,
      &:focus {
        background-color: ${props.theme.colors.primary20};
        border-color: ${props.theme.colors.primary500};

        span {
          color: ${props.theme.colors.primary500} !important;
        }
      }
    `}
`;

export const ArrowFoward = styled(MdArrowForward)`
  margin-top: 1.5px !important;
`;

export const InfoText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.info100};
  padding: 10px;
  border-radius: 8px;
  margin-top: 5px;
  max-width: 75%;
  margin-right: 10px;
`;
