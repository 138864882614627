import styled from 'styled-components';
import { Label as label } from '@kiper/ui';
import { colors } from '@kiper/theme';

export const Label = styled(label)`
  margin: 0;
  font-size: 0.875rem;
`;

export const Div = styled.div`
  display: none;
  justify-content: space-evenly;
`;

export const Line = styled.tr`
  &:hover ${Div} {
    display: flex;
  }
`;

export const SyncStatus = styled.span`
  width: 20px;
  height: 20px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props =>
    props.synchronized ? colors.successPrimary : colors.dangerPrimary};
  border: 1px solid
    ${props =>
      props.synchronized ? colors.successPrimary : colors.dangerPrimary};
  border-radius: 50%;
`;
