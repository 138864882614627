import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Img,
  TextContainer,
  Title,
  Subtitle,
  Button,
} from './styles';
import emptyStateImg from '../../assets/images/not-allowed.svg';

const NotAllowed = () => {
  const [t] = useTranslation('common');
  const history = useHistory();

  return (
    <Container>
      <Img src={emptyStateImg} alt="not-allowed-page" />
      <TextContainer>
        <Title>{t('routes.not-allowed.title')}</Title>
        <Subtitle>{t('routes.not-allowed.subtitle')}</Subtitle>
      </TextContainer>
      <Button color="primary" onClick={() => history.push('/')}>
        {t('routes.not-allowed.action-button')}
      </Button>
    </Container>
  );
};

export default NotAllowed;
