import styled from 'styled-components';
import { Text } from '@kiper/ui';

import { Form as form, FormGroup as formGroup } from 'reactstrap';

export const Wrapper = styled.div``;

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 24px;

  gap: 32px;

  a {
    text-decoration: none;
  }
`;

export const Form = styled(form)`
  flex: 1;
`;

export const FormGroup = styled(formGroup)`
  margin: 0;
  flex: 1;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`;

export const UnityDetailsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;

  width: ${props => props.percentageWidth}%;
`;

export const UnityDetailsTextContent = styled(Text).attrs({
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: '600',
})`
  color: ${props => props.theme.colors.blackShades(0.87)};
`;

export const UnityDetailsTextDescription = styled(Text).attrs({
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: '400',
})`
  color: ${props => props.theme.colors.blackShades(0.7)};
`;

export const CardTitle = styled(Text).attrs({
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: '400',
})`
  color: ${props => props.theme.colors.blackShades(0.8)};
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const NameColumnTable = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
`;

export const RowAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px;
  gap: 4px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  button {
    cursor: pointer;
  }
`;
