import { useContext, useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { useFormikContext } from 'formik';
import * as yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import {
  Flex,
  ErrorMessage,
  InputGeneric,
  Spinner,
  MdIcons,
  Text,
} from '@kiper/ui';
import {
  createTransientPerson,
  getTransientPersonSettings,
  updateTransientPerson,
} from '@kiper/monitoring-graphql/guided_attendance';
import { useLazyQuery, useMutation } from 'react-apollo';
import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import {
  useGuidedAttendance,
  useTransientPersonSettings,
  useInsertStepHistory,
} from '../../../hooks';
import { GuidedAttendanceContext } from '../../../store';

import {
  transientPersonTypes,
  triageToTransientPersonType,
} from '../../../constants';
import StepStructure, { StepTextTypeColor } from '../StepStructure';

const FIELD_NAME = {
  contactPhoneNumber: 'contactPhoneNumber',
};

const StepRegisterServiceProvider = ({ event }) => {
  const { setContextTree, contextTree } = useContext(GuidedAttendanceContext);
  const { handleNextAction, handleGetTriageSelected } = useGuidedAttendance();
  const {
    handleGetMask,
    handleGetLabel,
    handleGetPlaceholder,
    handleGetInitialValues,
    handleGetValidationSchema,
    handleCreateTransientPersonDetails,
  } = useTransientPersonSettings();

  const { values, setFieldValue, errors, touched } = useFormikContext();
  const { handleInsertStepHistory } = useInsertStepHistory();

  const { t } = useTranslation('guided-attendance');
  const { toast } = useSwal();
  const [transientSettings, setTransientSettings] = useState([]);

  const validationSchema = schema =>
    yup.object({
      stepRegisterServiceProvider: yup.object(schema),
    });

  const { touch, error, value } = useMemo(
    () => ({
      touch: prop => touched?.stepRegisterServiceProvider?.[prop],
      error: prop => errors?.stepRegisterServiceProvider?.[prop],
      value: prop => values?.stepRegisterServiceProvider?.[prop],
    }),
    [touched, errors, values],
  );

  const transientPersonType =
    transientPersonTypes[
      triageToTransientPersonType[values?.stepAttendanceGetReason?.id]
    ] || transientPersonTypes['service-provider'];

  const [getTransientSettings, { loading: loadingSettings }] = useLazyQuery(
    getTransientPersonSettings,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ transientPersonSettings }) => {
        const initialValues = handleGetInitialValues({
          transientPersonType,
          name: values?.stepAttendanceGetName?.name,
          transientPersonSettings,
        });

        const schema = handleGetValidationSchema({
          transientPersonType,
          transientPersonSettings,
          t,
        });

        if (transientPersonSettings.length) {
          setTransientSettings(transientPersonSettings);
        }

        const stepRegisterServiceProviderData =
          contextTree?.data?.stepRegisterServiceProvider;

        if (!values?.stepRegisterServiceProvider) {
          if (stepRegisterServiceProviderData)
            setFieldValue('stepRegisterServiceProvider', {
              ...stepRegisterServiceProviderData,
            });
          else {
            setFieldValue('stepRegisterServiceProvider', initialValues);
          }
        }

        setContextTree({
          formikSchema: validationSchema(schema),
        });
      },
    },
  );

  const [create] = useMutation(createTransientPerson, {
    onCompleted: ({ insertTransientPerson }) => {
      if (insertTransientPerson) {
        const stepRegisterServiceProvider = {
          ...values.stepRegisterServiceProvider,
          transientPersonId: insertTransientPerson.id,
        };

        setFieldValue(
          'stepRegisterServiceProvider',
          stepRegisterServiceProvider,
        );

        const newContextData = {
          ...contextTree.data,
          stepRegisterServiceProvider,
        };

        setContextTree({
          data: newContextData,
        });
      }
    },
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const [update] = useMutation(updateTransientPerson, {
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  async function runCreateOrUpdateTransientPerson({
    transientPersonId,
    transientPerson,
  }) {
    if (transientPersonId) {
      const updated = await update({
        variables: {
          transientPerson,
          transientPersonId,
        },
      });

      const updatedTransientPersonId = updated?.data?.updateTransientPerson?.id;

      if (!updatedTransientPersonId) return null;

      return updatedTransientPersonId;
    }

    const created = await create({
      variables: {
        transientPerson: {
          ...transientPerson,
          registeredInCondominiumPersonContextId:
            event?.condominium?.personContextId,
        },
      },
    });

    const createdTransientPersonId = created?.data?.insertTransientPerson?.id;

    if (!createdTransientPersonId) return null;

    return createdTransientPersonId;
  }

  const handleCreateOrUpdateTransientPerson = async data => {
    const transientPersonId =
      data?.stepRegisterServiceProvider?.transientPersonId;

    let transientPerson = {
      name: data?.stepRegisterServiceProvider?.name,
      partnerPersonContextId: String(event?.partner?.personContextId),
      type: transientPersonType,
    };

    const transientPersonDetails = handleCreateTransientPersonDetails({
      transientPersonType,
      values: data?.stepRegisterServiceProvider,
    });

    if (transientPersonDetails.length) {
      transientPerson = { ...transientPerson, transientPersonDetails };
    }

    const responseCreateOrUpdate = await runCreateOrUpdateTransientPerson({
      transientPersonId,
      transientPerson,
    });

    return responseCreateOrUpdate;
  };

  const handleChange = (name, val) => setFieldValue(name, val);

  const submitCurrentSetpHistory = (steps, eventId) => {
    const { stepRegisterServiceProvider } = steps;
    const data = {
      name: stepRegisterServiceProvider?.name,
      contactNumber: stepRegisterServiceProvider?.contactNumber,
      documentCpf: stepRegisterServiceProvider?.documentCpf,
      documentRg: stepRegisterServiceProvider?.documentRg,
      vehiclePlate: stepRegisterServiceProvider?.vehiclePlate,
      companyName: stepRegisterServiceProvider?.companyName,
    };

    handleInsertStepHistory(data, eventId);
  };

  const nextAction = async stepData => {
    const transientPersonId = await handleCreateOrUpdateTransientPerson(
      stepData,
    );

    if (!transientPersonId) {
      return null;
    }

    const currentStepData = {
      ...stepData,
      stepRegisterServiceProvider: {
        ...stepData?.stepRegisterServiceProvider,
        transientPersonId,
      },
    };

    submitCurrentSetpHistory(stepData, event.eventId);

    const nextStep = handleNextAction({
      values: currentStepData,
      fieldValue: 'stepRegisterServiceProvider',
    });

    return { nextStep, currentStepData };
  };

  useEffect(() => {
    getTransientSettings({
      variables: {
        condominiumContextId: event?.condominium?.personContextId,
        transientPersonType,
      },
    });

    setContextTree({
      nextAction,
    });
  }, []);
  return (
    <StepStructure
      type={StepTextTypeColor.SPEAK}
      text={t('step-register-service-provider.title', {
        name: value('name'),
      })}
      hasPreviousStepButton
      hasNextStepButton
    >
      <Flex flexDirection="column" alignSelf="flex-start">
        <Text>
          <Trans>
            {t('step-register-service-provider.data', {
              triage: t(
                `step-register-service-provider.triage.${handleGetTriageSelected(
                  values,
                )}`,
              ),
            })}
          </Trans>
        </Text>
        <Flex gridGap="4px">
          <MdIcons mdIconName="error" size="20px" color="orange500" />
          <Text fontSize="12px" color="orange500">
            <Trans>
              {t('step-register-service-provider.attention', {
                triage: t(
                  `step-register-service-provider.triage.${handleGetTriageSelected(
                    values,
                  )}`,
                ),
              })}
            </Trans>
          </Text>
        </Flex>
      </Flex>
      {!loadingSettings ? (
        <Flex gridGap="8px" width="100%" flexWrap="wrap">
          <Flex flexDirection="column" width="49%">
            <InputGeneric
              label={handleGetLabel(t, 'clientName')}
              placeholder={handleGetPlaceholder(t, 'clientName')}
              name="stepRegisterServiceProvider.name"
              value={value('name')}
              onChange={setFieldValue}
              invalid={!!touch('name') && !!error('name')}
              error={!!touch('name') && !!error('name')}
              autoComplete="off"
            />
            {!!touch('name') && !!error('name') && (
              <ErrorMessage>{error('name')}</ErrorMessage>
            )}
          </Flex>
          {transientPersonType === transientPersonTypes['service-provider'] && (
            <Flex flexDirection="column" width="49%">
              <InputGeneric
                id="step-attendance-register-service-provider-company"
                name="stepRegisterServiceProvider.companyName"
                label={handleGetLabel(t, 'companyName')}
                placeholder={handleGetPlaceholder(t, 'companyName')}
                value={value('companyName')}
                onChange={setFieldValue}
                autoFocus
                isRequired
                autoComplete="off"
                invalid={!!touch('companyName') && !!error('companyName')}
                error={!!touch('companyName') && !!error('companyName')}
              />
              {!!touch('companyName') && !!error('companyName') && (
                <ErrorMessage>{error('companyName')}</ErrorMessage>
              )}
            </Flex>
          )}
          {!!transientSettings.length &&
            transientSettings.map(({ name, isRequired }) => (
              <Flex key={name} flexDirection="column" width="49%">
                <InputGeneric
                  id={`step-attendance-register-service-provider-${name}`}
                  name={`stepRegisterServiceProvider.${name}`}
                  label={handleGetLabel(t, name)}
                  placeholder={handleGetPlaceholder(t, name)}
                  value={value(`${name}`)}
                  autoComplete="off"
                  isPhoneNumber={name === FIELD_NAME.contactPhoneNumber}
                  isRequired={isRequired}
                  mask={handleGetMask(name)}
                  onChange={
                    handleGetMask(name)
                      ? val =>
                          handleChange(
                            `stepRegisterServiceProvider.${name}`,
                            val,
                          )
                      : setFieldValue
                  }
                  invalid={!!touch(`${name}`) && !!error(`${name}`)}
                  error={!!touch(`${name}`) && !!error(`${name}`)}
                />
                {!!touch(`${name}`) && !!error(`${name}`) && (
                  <ErrorMessage>{error(`${name}`)}</ErrorMessage>
                )}
              </Flex>
            ))}
        </Flex>
      ) : (
        <Flex justifyContent="center" width="100%" py="20px">
          <Spinner size={24} />
        </Flex>
      )}
    </StepStructure>
  );
};

export default StepRegisterServiceProvider;

StepRegisterServiceProvider.propTypes = {
  event: propTypes.object,
};

StepRegisterServiceProvider.defaultProps = {
  event: null,
};
