import styled from 'styled-components';

export const Row = styled.div`
  margin-bottom: ${props => (props.last ? '0' : '15')}px;
  display: flex;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 15px;
  }
`;

export const SpaceBetWeen = styled.span`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 50%;
  margin-right: 15px;
  background: #e6f5ed;

  svg {
    color: #00ad76;
  }
`;
