import { useMemo } from 'react';
import { Box } from '@kiper/ui';
import { useToggle } from '@kiper/hooks';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { Card, LoaderIcon, ErrorIcon } from './styles';
import { SCREEN_STATE } from '../../hooks/useReportFilters';
import { ScreenState } from '../../ScreenState';
import { ReportHeader } from '../../../../components/ReportHeader';
import { ReportTable } from '../../../../components/ReportTable';
import reportExportingDone from '../../../../assets/images/report-export-done.svg';
import VehicleRow, { DefaultChildren, VehicleTypeChildren } from './Row';

const VehiclesReport = ({
  vehiclesData: data,
  loading,
  screenState,
  paginationFilters,
  handlePageSize,
  handlePageChange,
  exportPdf,
  exportXls,
  exportCsv,
  handleCancelPrint,
  handlePrint,
}) => {
  const [t] = useTranslation('report');

  const [dropdownOpen, toggleDropdown] = useToggle(false);
  const { pagination, collection } = useMemo(() => {
    return { collection: data?.collection, pagination: data?.pagination };
  }, [data]);

  const columns = [
    {
      primary: 'unityGroupName',
      name: t('vehicles.columns.unity-group-name'),
      align: 'left',
      width: '15%',
      children: DefaultChildren,
    },
    {
      primary: 'unityName',
      name: t('vehicles.columns.unity-name'),
      align: 'left',
      width: '15%',
      children: DefaultChildren,
    },
    {
      primary: 'vehicleType',
      name: t('vehicles.columns.vehicle-type'),
      align: 'left',
      width: '15%',
      children: VehicleTypeChildren,
    },
    {
      primary: 'vehicleModel',
      name: t('vehicles.columns.vehicle-model'),
      align: 'left',
      width: '20%',
      children: DefaultChildren,
    },
    {
      primary: 'vehiclePlate',
      name: t('vehicles.columns.vehicle-plate'),
      align: 'left',
      width: '15%',
      children: DefaultChildren,
    },
    {
      primary: 'vehiclePersonName',
      name: t('vehicles.columns.vehicle-person-name'),
      align: 'left',
      width: '20%',
      children: DefaultChildren,
    },
  ];

  return (
    <Box>
      <Card>
        <ReportHeader
          title={t('vehicles.title')}
          dropdownOpened={dropdownOpen}
          exportPdf={exportPdf}
          exportXls={exportXls}
          exportCsv={exportCsv}
          toggleDropdown={toggleDropdown}
          dropdownDisabled={loading || collection?.length === 0}
        />
        <ReportTable
          columns={columns}
          collection={collection}
          row={VehicleRow}
          printing={screenState !== SCREEN_STATE.DATA}
          pagination={pagination}
          filters={paginationFilters}
          handlePageSize={handlePageSize}
          handleNextPage={() =>
            Boolean(pagination?.hasNext) && handlePageChange(1)
          }
          handlePreviousPage={() =>
            Boolean(pagination?.hasPrevious) && handlePageChange(-1)
          }
          loading={loading}
        />
        {screenState === SCREEN_STATE.GENERATING && (
          <ScreenState
            title={t('common:report.exporting-title')}
            message={t('common:report.exporting-message')}
            image={<LoaderIcon />}
            actions={[
              {
                text: t('common:report.cancel-button'),
                color: 'secondary',
                onClick: handleCancelPrint,
              },
            ]}
          />
        )}
        {screenState === SCREEN_STATE.DONE && (
          <ScreenState
            title={t('common:report.ready-title')}
            message={t('common:report.ready-message')}
            image={<img src={reportExportingDone} alt="" />}
            actions={[
              {
                text: t('common:report.cancel-button'),
                color: 'secondary',
                onClick: handleCancelPrint,
              },
              {
                text: t('common:report.download-button'),
                color: 'primary',
                onClick: handlePrint,
                margin: true,
              },
            ]}
          />
        )}
        {screenState === SCREEN_STATE.ERROR && (
          <ScreenState
            title={t('common:report.error-title')}
            message={t('common:report.error-message')}
            image={<ErrorIcon />}
            actions={[
              {
                text: t('common:report.cancel-button'),
                color: 'secondary',
                onClick: handleCancelPrint,
              },
            ]}
          />
        )}
      </Card>
    </Box>
  );
};

VehiclesReport.propTypes = {
  loading: propTypes.bool,
  screenState: propTypes.string,
  vehiclesData: propTypes.object,
  paginationFilters: propTypes.object.isRequired,
  handlePageSize: propTypes.func.isRequired,
  handlePageChange: propTypes.func.isRequired,
  exportPdf: propTypes.func.isRequired,
  exportXls: propTypes.func.isRequired,
  exportCsv: propTypes.func.isRequired,
  handleCancelPrint: propTypes.func.isRequired,
  handlePrint: propTypes.func.isRequired,
};

VehiclesReport.defaultProps = {
  loading: false,
  screenState: SCREEN_STATE.EMPTY,
  vehiclesData: {
    collection: [],
    pagination: {
      page: 1,
      totalPages: 1,
      pageSize: 10,
      totalResults: 0,
      hasPrevious: false,
      hasNext: false,
    },
  },
};

export default VehiclesReport;
