import styled from 'styled-components';
import { Select } from '@kiper/ui';

export const StyledSelect = styled(Select)`
  width: 100%;
`;

export const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OpacitySpan = styled.span`
  opacity: 0.8;
`;
