import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useSwal } from '@kiper/hooks';
import { apolloErrorHandler } from '@kiper/fns';
import { adamSender as adamSenderGql } from '@kiper/monitoring-graphql';
import useCommand from './useCommand';

const useEmergency = ({ eventId, isFallback = false }) => {
  const { t } = useTranslation('event_attendance');
  const { toast } = useSwal();

  const [activate] = useMutation(adamSenderGql.startEmergency, {
    onCompleted: () => {
      toast.fire({
        title: t(
          'details.header.emergency.emergency-command-successfully-sent',
        ),
        icon: 'success',
      });
    },
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });
  const [desactivate] = useMutation(adamSenderGql.stopEmergency, {
    onCompleted: () => {
      toast.fire({
        title: t(
          'details.header.emergency.emergency-command-successfully-sent',
        ),
        icon: 'success',
      });
    },
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const commandFactory = (mutation, name) => async ({ event }) => {
    const condominiumContextId = event.condominium?.personContext?.id;

    const { data } = await mutation({
      variables: { condominiumContextId },
    });

    const hasSuccess = data[name];

    if (!hasSuccess) throw new Error('command failed');
  };

  const command = useCommand({
    eventId,
    eventTypes: [122, 168],
    getInitialStatus: event => !!event.inEmergency,
    activateRequest: commandFactory(activate, 'startEmergency'),
    deactivateRequest: commandFactory(desactivate, 'stopEmergency'),
    isFallback,
  });

  return command;
};

export default useEmergency;
