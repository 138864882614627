import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '@kiper/fns';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Row } from './styles';
import ReportForm from './Form';
import ReportFilter from './ReportFilter';

const UsersReportForm = ({
  reportFilters,
  handleSubmit,
  setXlsFileName,
  setCsvFileName,
  loading,
  type,
  setCondominiumName,
  setHeaderFilters,
}) => {
  const [showAdvanced, expandAdvanced] = useState(false);

  const formikBag = useFormik({
    initialValues: { condominiumTreeNodeId: null },
    validationSchema: yup.object({
      condominiumTreeNodeId: yup.number().nullable(),
    }),
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    const { formatted } = formatDate(new Date());
    const now = formatted.replaceAll('/', '-');

    setXlsFileName(`${type} - ${now}.xlsx`);
    setCsvFileName(`${type} - ${now}.csv`);
  }, []);

  const hasAdvanced = useMemo(
    () => reportFilters?.some(({ isAdvanced }) => isAdvanced),
    [reportFilters],
  );

  const filters = useMemo(() => {
    if (hasAdvanced && !showAdvanced) {
      return reportFilters.filter(({ isAdvanced }) => !isAdvanced);
    }
    return reportFilters;
  }, [reportFilters, showAdvanced]);

  return (
    <ReportForm
      formikBag={formikBag}
      loading={loading}
      expandAdvanced={() => expandAdvanced(!showAdvanced)}
      hasAdvanced={hasAdvanced}
      showAdvanced={showAdvanced}
      setHeaderFilters={setHeaderFilters}
    >
      <Row>
        {filters
          .sort((a, b) => a.sequence - b.sequence)
          .map(({ id, key }) => (
            <ReportFilter
              key={id}
              type={key}
              formProps={formikBag}
              formType={type}
              setCondominiumName={setCondominiumName}
              setHeaderFilters={setHeaderFilters}
            />
          ))}
      </Row>
    </ReportForm>
  );
};

UsersReportForm.propTypes = {
  reportFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      key: PropTypes.string,
      isRequired: PropTypes.bool,
      sequence: PropTypes.number,
    }),
  ).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setCsvFileName: PropTypes.func.isRequired,
  setXlsFileName: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  type: PropTypes.string.isRequired,
  setCondominiumName: PropTypes.func.isRequired,
  setHeaderFilters: PropTypes.func.isRequired,
};

UsersReportForm.defaultProps = {
  loading: false,
};

export default UsersReportForm;
