import { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { FiAlertCircle as AlertIcon } from 'react-icons/fi';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  StyledLink,
  CardContent,
  CardIcon,
  Label,
  Alert,
  AlertWrapper,
} from './styles';

const MESSAGE_ALIAS = {
  emailToAskTagRf: 'list.generalEmailSettings',
  digifortIntegration: 'list.imageSettings',
  kiperVoiceIntegration: 'list.voiceSettings',
  alarmTransmitter: 'list.alarmSettings',
};

const SettingsCard = ({ title, content, icon: Icon }) => {
  const [t] = useTranslation('system-settings');

  const language = useMemo(() => {
    return i18next.language.split('-')[0];
  }, [i18next]);

  const getDescriptionByLanguage = data => {
    return data[`${language}`] ?? '';
  };

  return (
    <Card>
      <CardIcon>
        <Icon />
      </CardIcon>
      <CardContent>
        <CardHeader>
          <CardTitle>{title}</CardTitle>
          <hr />
        </CardHeader>
        <CardBody>
          {content.map((current, key) => {
            const { name } = current;
            const messageAlias = MESSAGE_ALIAS[`${name}`];
            const description = getDescriptionByLanguage(current.description);
            return (
              <Fragment key={key}>
                <StyledLink
                  id={name}
                  to={{
                    pathname: `/system-settings/${name}`,
                    state: { ...current, description },
                  }}
                >
                  {t(`${messageAlias}.link`)}
                </StyledLink>
              </Fragment>
            );
          })}
          {content.map((current, key) => {
            const { name } = current;
            const description = getDescriptionByLanguage(current.description);

            return description ? (
              <Fragment key={`${key}-alert`}>
                <AlertWrapper id={`${name}-alert`}>
                  <Alert>
                    <AlertIcon />
                    <Label>{description}</Label>
                  </Alert>
                </AlertWrapper>
              </Fragment>
            ) : (
              <></>
            );
          })}
        </CardBody>
      </CardContent>
    </Card>
  );
};

SettingsCard.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.array.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
};

export default SettingsCard;
