import { useMemo, useCallback, useLayoutEffect, useEffect } from 'react';
import propTypes from 'prop-types';
import { queries as placeSelectQueries } from '@kiper/monitoring-graphql/place_select';
import { createFilter } from 'react-select';
import { useFilterSearchParams } from '@kiper/hooks';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-apollo';
import { MdClose } from 'react-icons/md';
import { Box } from '@kiper/ui';
import * as S from './styles';

const formatGroupLabel = data => (
  <S.GroupLabelWrapper>
    <S.GroupLabel>{data.label}</S.GroupLabel>
    {data.secondaryLabel && data.options.length >= 2 && (
      <S.GroupLabel>{data.secondaryLabel}</S.GroupLabel>
    )}
  </S.GroupLabelWrapper>
);

const formatOptionLabel = option => (
  <S.OptionWrapper>
    <S.Option>{option.label}</S.Option>
    {option?.gu?.label && (
      <S.OptionDescription>{option.gu.label}</S.OptionDescription>
    )}
  </S.OptionWrapper>
);

export default function UserGuUnSelect({
  sourceNodeId,
  onChange,
  value,
  selectRef,
  isDisabled,
  isClearable,
  guUnFilter = null,
  guLoad = true,
  isSearchFilter = false,
  filterLinkedFields = [],
  name,
  ...props
}) {
  const { t } = useTranslation('condominium');

  const { data, loading } = useQuery(placeSelectQueries.userGuUnPlaceSelect, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: !sourceNodeId,
    variables: {
      filters: {
        sourceNodeId,
        fieldName: ['unity', 'unityGroup'],
        withLabel: true,
      },
    },
  });

  const places = useMemo(() => {
    if (!data) return [];

    const options = [
      {
        secondaryLabel: '',
        label: t('details.unity-type'),
        options: [
          ...data.placeSelect
            .filter(place => place.type === 'unity')
            .map(place => {
              return {
                ...place,
                id: place?.entityId,
                value: `${place.treeNodeId} ${data.placeSelect.find(
                  b => b.treeNodeId === place.parentTreeNodeId,
                )?.label || ''} ${place.label}`,
                parentName:
                  data.placeSelect.find(
                    b => b.treeNodeId === place.parentTreeNodeId,
                  )?.label || '',
                gu: data.placeSelect.find(
                  u =>
                    u.type === 'unityGroup' &&
                    u.treeNodeId === place.parentTreeNodeId,
                ),
              };
            }),
        ],
      },
    ];

    const [unities] = options;

    if (guLoad) {
      unities.secondaryLabel = t('details.unity-group-type');
      options.push({
        label: t('details.unity-group-type'),
        options: [
          ...data.placeSelect
            .filter(u => u.type === 'unityGroup')
            .map(b => {
              return {
                ...b,
                value: `${b.treeNodeId} ${b.label}`,
              };
            }),
        ],
      });

      unities.options.sort((a, b) => {
        const labelA = a.gu?.label.toUpperCase();
        const labelB = b.gu?.label.toUpperCase();

        if (labelA < labelB) return -1;
        if (labelA > labelB) return 1;
        return 0;
      });
    }

    return options;
  }, [data]);

  const {
    appendParam,
    removeParam,
    setSelectUnityFilterValueFromQuery,
  } = useFilterSearchParams({
    key: name,
    options: places,
    filterLinkedFields,
    onChange: ({ queryValue }) => onChange(queryValue),
  });

  const ClearIndicator = useCallback(() => {
    return (
      <Box onClick={() => onChange(null)} mr="4px">
        <MdClose size={20} />
      </Box>
    );
  }, [value]);

  useEffect(() => {
    if (isSearchFilter) setSelectUnityFilterValueFromQuery();
  }, [data]);

  useLayoutEffect(() => {
    if (guUnFilter) onChange(guUnFilter);
  }, []);

  const placeholderSelect = guLoad
    ? 'details.unity-group.search'
    : 'details.unity-group.search-only-unity';

  const handleChange = eventData => {
    if (isSearchFilter) {
      if (eventData?.value) {
        appendParam({ queryValue: eventData.value });
      } else {
        removeParam();
      }
    }

    if (onChange) onChange(eventData);
  };

  const getValue = () => {
    if (typeof value === 'number' || typeof value === 'string') {
      const [firstPlace] = places || [];
      return firstPlace?.options?.find(option => option?.treeNodeId === value);
    }
    return value;
  };

  return (
    <S.StyledSelect
      width="100%"
      isDisabled={isDisabled}
      ref={selectRef}
      isClearable={isClearable}
      onChange={handleChange}
      classNamePrefix="select-unity"
      isLoading={loading}
      placeholder={t(placeholderSelect)}
      options={places}
      filterOption={createFilter({
        matchFrom: 'any',
        stringify: option => `${option.label}`,
      })}
      name={name}
      value={getValue()}
      formatOptionLabel={formatOptionLabel}
      formatGroupLabel={formatGroupLabel}
      components={ClearIndicator}
      {...props}
    />
  );
}

UserGuUnSelect.propTypes = {
  sourceNodeId: propTypes.number,
  onChange: propTypes.func.isRequired,
  value: propTypes.any,
  selectRef: propTypes.any,
  isDisabled: propTypes.bool,
  isClearable: propTypes.bool,
  guUnFilter: propTypes.oneOfType([propTypes.object, propTypes.number]),
  guLoad: propTypes.bool,
  isSearchFilter: propTypes.bool,
  name: propTypes.string,
  filterLinkedFields: propTypes.array,
};

UserGuUnSelect.defaultProps = {
  sourceNodeId: null,
  selectRef: null,
  value: null,
  isDisabled: false,
  isClearable: true,
  guUnFilter: null,
  guLoad: true,
  isSearchFilter: false,
  name: '',
  filterLinkedFields: [],
};
