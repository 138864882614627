import propTypes from 'prop-types';
import theme from '@kiper/theme';
import { differenceInMinutes, formatDateUtc, parseZonedTime } from '@kiper/fns';
import { Flex, MdIcons, Text, Tooltip } from '@kiper/ui';
import { useCurrentLoggedContext } from '../../../../../hooks';
import { lastAttendancesStatus } from '../../../../../hooks/useLastAttendancesByEventType';

import * as S from '../styles';

const PREFIX_TRANSLATE = 'details.last-attendances';

const statusAttendanceSettings = t => ({
  [lastAttendancesStatus.ONGOING]: {
    icon: (
      <Flex
        id={`last-attendances-intrusion-detection-${lastAttendancesStatus.ONGOING}`}
      >
        <S.OnGoingIcon />
      </Flex>
    ),
    tooltip: t(`${PREFIX_TRANSLATE}.810.status.on-going`),
  },
  [lastAttendancesStatus.UNDETECTED_INTRUSION]: {
    icon: (
      <Flex
        id={`last-attendances-intrusion-detection-${lastAttendancesStatus.UNDETECTED_INTRUSION}`}
      >
        <MdIcons
          mdIconName="zone_person_idle"
          color={theme.colors.primary500}
        />
      </Flex>
    ),
    tooltip: t(`${PREFIX_TRANSLATE}.810.status.undetected-intrusion`),
  },
  [lastAttendancesStatus.INTRUSION_DETECTED]: {
    icon: (
      <Flex
        id={`last-attendances-intrusion-detection-${lastAttendancesStatus.INTRUSION_DETECTED}`}
      >
        <MdIcons
          mdIconName="zone_person_alert"
          color={theme.colors.danger500}
        />
      </Flex>
    ),
    tooltip: t(`${PREFIX_TRANSLATE}.810.status.intrusion-detected`),
  },
  [lastAttendancesStatus.WAITING_ATTENDANCE]: {
    icon: (
      <Flex
        id={`last-attendances-intrusion-detection-${lastAttendancesStatus.WAITING_ATTENDANCE}`}
      >
        <S.WaitingAttendanceIcon />
      </Flex>
    ),
    tooltip: t(`${PREFIX_TRANSLATE}.810.status.waiting-attendance`),
  },
});

const IntrusionDetectionListItem = ({ attendance, t, eventId }) => {
  const { loggedContext } = useCurrentLoggedContext();

  const additionalInformation = JSON.parse(attendance?.additionalInformation);

  const getLastMinutesAttendance = () => {
    const minutes = -differenceInMinutes(
      parseZonedTime(attendance.creationDate),
      parseZonedTime(
        formatDateUtc({
          date: new Date(),
          format: 'YYYY-MM-DD HH:mm:ss',
        }),
      ),
    );
    if (minutes === 0) {
      return t(`${PREFIX_TRANSLATE}.approximate-value`);
    }
    return t(`${PREFIX_TRANSLATE}.attendance-completed-time`, {
      time: String(minutes).padStart(2, '0'),
    });
  };

  const zoneName = additionalInformation?.camera
    ? t(`${PREFIX_TRANSLATE}.810.zone-information`, {
        channelId: String(additionalInformation?.camera?.channelId).padStart(
          2,
          '0',
        ),
        camName: additionalInformation?.camera?.camName,
      })
    : t(`${PREFIX_TRANSLATE}.810.zone-not-found`);

  const attendantName = attendance?.attendant?.name
    ? t(`${PREFIX_TRANSLATE}.810.attendant-name`, {
        attendantName: attendance?.attendant.name,
      })
    : '--';

  const attendanceByMyself =
    attendance?.attendant?.id === Number(loggedContext.personContextId) &&
    attendance?.eventId === eventId;

  return (
    <S.ListItem>
      <Flex gridGap="8px" width="100%">
        <Flex justifyContent="flex-start">
          {statusAttendanceSettings(t)[attendance.status].icon}
          {!!statusAttendanceSettings(t)[attendance.status].tooltip && (
            <Tooltip
              placement="top"
              target={`last-attendances-intrusion-detection-${attendance.status}`}
            >
              <Text fontWeight="400" fontSize="14px" lineHeight="20px">
                {statusAttendanceSettings(t)[attendance.status].tooltip}
              </Text>
            </Tooltip>
          )}
        </Flex>

        <Flex alignItems="flex-start" justifyContent="flex-start" width="100%">
          {attendance?.eventId && (
            <S.AttendanceSubject title={zoneName}>
              {zoneName}
            </S.AttendanceSubject>
          )}
        </Flex>
        <Flex justifyContent="flex-end">
          {attendanceByMyself ? (
            <S.Chip>{t(`${PREFIX_TRANSLATE}.current-attendant-chip`)}</S.Chip>
          ) : (
            <S.SecondaryText>{getLastMinutesAttendance()}</S.SecondaryText>
          )}
        </Flex>
      </Flex>
      <Flex ml="32px">
        <S.SecondaryText>{attendantName}</S.SecondaryText>
      </Flex>
    </S.ListItem>
  );
};

export default IntrusionDetectionListItem;

IntrusionDetectionListItem.propTypes = {
  t: propTypes.func.isRequired,
  eventId: propTypes.string.isRequired,
  attendance: propTypes.arrayOf(
    propTypes.shape({
      additionalInformation: propTypes.string.isRequired,
      eventId: propTypes.string.isRequired,
      attendant: propTypes.shape({
        name: propTypes.string.isRequired,
        id: propTypes.number.isRequired,
        email: propTypes.string.isRequired,
      }).isRequired,
      status: propTypes.string.isRequired,
      creationDate: propTypes.string.isRequired,
    }),
  ).isRequired,
};
