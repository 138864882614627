import styled from 'styled-components';
import { FaChevronUp } from 'react-icons/fa';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  align-items: flex-start;
`;

export const CondoInfoContainerBase = styled.div`
  display: flex;
  font-size: 12px;
  width: 100%;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.warning50};
  cursor: default;

  &:hover {
    background-color: ${props => props.theme.colors.warning100};
  }
`;

export const CondoInfoContainer = styled(CondoInfoContainerBase)`
  align-items: center;
`;

export const CondoInfoCollapseContainer = styled(CondoInfoContainerBase)`
  flex-direction: column;
`;

export const WrapperIcon = styled.div``;

export const WrapperInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoDescription = styled.div``;

export const BadgeOutlined = styled.div`
  display: flex;
  white-space: nowrap;
  padding: 1px 4px;
  color: ${props => props.theme.colors.gray900};
  border: ${props => props.theme.colors.gray900} solid 1px;
  border-radius: 12px;
`;

export const CaretakerSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  grid-gap: 4px;
  cursor: pointer;
`;

export const CaretakerSectionSchedule = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
`;

export const CollapseIcon = styled(FaChevronUp)`
  ${props => props.collapse && 'transform: rotate(180deg);'}
  transition: all 0.1s ease-out;
`;

export const TableRow = styled.tr`
  font-weight: ${props => (props.isBold ? 'bold' : 'normal')};
`;
