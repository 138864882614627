import { useContext } from 'react';
import { TriageContext } from '../../../../store';

const useChangeUnity = ({ values, setFieldValue, event }) => {
  const { fillAction, setTriageContext } = useContext(TriageContext);

  const handleChangeUnity = value => {
    if (!value) {
      setTriageContext({
        filterGUUnit: null,
      });
      return setFieldValue('stepGetOnlyUnity', {
        ...values.stepGetOnlyUnity,
        unity: '',
        unityGroup: null,
      });
    }

    setTriageContext({
      filterGUUnit: value,
    });
    fillAction('guUn', event?.evendId);

    return setFieldValue('stepGetOnlyUnity', {
      ...values.stepGetOnlyUnity,
      unityGroup: value?.gu ? value.gu?.label : null,
      unity: {
        id: value?.id,
        treeNodeId: value?.treeNodeId,
        value: value?.value,
        label: value?.label,
        parentName: value?.parentName,
      },
    });
  };

  return { handleChangeUnity };
};

export default useChangeUnity;
