import propTypes from 'prop-types';
import { Text } from '@kiper/ui';

const NoDwellersMessage = ({ t }) => (
  <Text color="danger500">
    {t('step-dweller-validation.feedback.error.unit-not-available-residents')}
  </Text>
);

export default NoDwellersMessage;

NoDwellersMessage.propTypes = {
  t: propTypes.func.isRequired,
};
