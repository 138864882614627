import styled, { css, keyframes } from 'styled-components';
import { doorStatusTypes } from '../../../../../constants';

const pulse = keyframes`
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1.1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
`;

const handleBackgroundType = (status, theme) => {
  switch (status) {
    case doorStatusTypes.Opened:
      return theme.colors.warning500;
    case doorStatusTypes.KeepOpened:
      return theme.colors.orange500;
    default:
      return theme.colors.secondary400;
  }
};

export const PlaceStyled = styled.div`
  position: absolute;
  border-radius: 100%;
  cursor: pointer;

  ${({ coordinate, selected, doorStatus, theme }) =>
    !!coordinate &&
    css`
      width: 32px;
      height: 32px;
      top: ${`${coordinate.y}px`};
      left: ${`${coordinate.x}px`};
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${() => handleBackgroundType(doorStatus, theme)};
      transition: box-shadow 0.2s, color 0.3s;

      ${selected &&
        css`
          color: ${theme.colors.violet900};
          box-shadow: 0 0 0 2px ${theme.colors.white},
            0 0 0 4px ${theme.colors.violet900};
        `}
    `}

  ${({ origin, coordinate, theme }) =>
    !!coordinate &&
    origin &&
    css`
      box-shadow: 0 0 0 0 ${theme.colors.highBlack};
      transform: scale(1);
      animation: ${pulse} 2s infinite;
    `}
`;
