import propTypes from 'prop-types';
import { KiperButton, Badge, Flex } from '@kiper/ui';
import { useMutation } from 'react-apollo';
import { MdDelete, MdEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useSwal } from '@kiper/hooks';
import { formatDate, apolloErrorHandler } from '@kiper/fns';
import { systemUser as systemUserGql } from '@kiper/monitoring-graphql';
import { useTranslation } from 'react-i18next';
import { profileColor, profileTypes } from '../../../constants';
import UserInfo from '../../../components/UserInfo';
import { Label } from './styles';

const SystemUserItem = ({ data, onDeleted, canDelete }) => {
  const [t] = useTranslation('system-user');
  const { toast, confirm } = useSwal();
  const [deleteAction, { loading }] = useMutation(
    systemUserGql.systemUserRemove,
    {
      variables: { personContextId: Number(data.personContextId) },
      onCompleted: () => {
        onDeleted();
        toast.fire({
          title: t('list.modal.delete.success', { userName: `${data.name}` }),
          icon: 'success',
        });
      },
      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length) {
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
        }
      },
    },
  );
  const onDelete = async () => {
    const deleted = await confirm({
      title: t('list.modal.delete.title'),
      text: t('list.modal.delete.text', { userName: `${data.name}` }),
      cancelButtonText: t('buttons:cancel'),
      confirmButtonText: t('buttons:confirm'),
    });

    if (deleted) deleteAction();
  };
  return (
    <tr>
      <td align="left">
        <UserInfo user={data} />
      </td>
      <td align="left">
        {data?.profile?.fieldName && (
          <Badge color={profileColor[data.profile.fieldName]}>
            {t(`common:profiles.${profileTypes[data.profile.fieldName]}`)}
          </Badge>
        )}
      </td>
      <td align="left">
        <Label info>{formatDate(data.creationDate).formatted}</Label>
      </td>
      <td align="left">
        <Flex>
          <Flex mr="4px">
            <KiperButton
              rounded
              icon={<MdDelete />}
              color="danger"
              onClick={onDelete}
              disabled={!canDelete || loading}
            />
          </Flex>
          <Link to={`/system-user/${data.personId}`}>
            <KiperButton rounded icon={<MdEdit />} data-test="edit-button" />
          </Link>
        </Flex>
      </td>
    </tr>
  );
};

export default SystemUserItem;

SystemUserItem.propTypes = {
  data: propTypes.object.isRequired,
  canDelete: propTypes.bool,
  onDeleted: propTypes.func.isRequired,
};

SystemUserItem.defaultProps = { canDelete: true };
