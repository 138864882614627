import styled from 'styled-components';

export const Container = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
  width: 100%;
  background-color: #fff;
  border: 1px solid ${props => props.theme.colors.secondary300};
  border-radius: 4px;
  padding: 10px;
`;

export const RemoveAction = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const Item = styled.div`
  display: flex;
  flex: 0 50%;
  width: 100%;
`;

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
