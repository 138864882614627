import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LockerDeliveryHistoryTable } from './components';
import { Modal } from './styles';

const LockerHistoryModal = ({ onToggle, condominiumPersonContextId }) => {
  const [t] = useTranslation('modals/locker-delivery-history-modal');

  return (
    <Modal title={t('title')} open toggle={onToggle} size="xl">
      <LockerDeliveryHistoryTable
        condominiumPersonContextId={condominiumPersonContextId}
      />
    </Modal>
  );
};

export default LockerHistoryModal;

LockerHistoryModal.propTypes = {
  onToggle: propTypes.func.isRequired,
  condominiumPersonContextId: propTypes.any.isRequired,
};
