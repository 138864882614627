import EventsList from './List';
import EventsDetail from './Detail';

const breadcrumb = (label, to) => ({ label, to });

const requiredPermissions = ['monitoringScreen'];

export default [
  {
    name: 'Event attendance',
    path: '/attendance',
    component: EventsList,
    breadcrumb: [breadcrumb('common:breadcrumb.attendance', '/attendance')],
    requiredPermissions,
    routes: [
      {
        name: 'Event detail',
        path: '/attendance/:id?',
        exact: true,
        component: EventsDetail,
      },
    ],
  },
];
