import { Fragment, useMemo } from 'react';
import propTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Badge } from '@kiper/ui';
import { condominiumParamsTypes, erpLicenses } from '../../../constants';
import { LicenseBadges } from '../../../components/LicenseBadge';
import { getCondominiumParam } from '../../../helpers';
import * as S from './styles';

export default function CondominiumListItem({ condominium }) {
  const history = useHistory();
  const [t] = useTranslation('condominium');

  const condominiumAddress = useMemo(() => {
    const defaultAddress = {
      streetName: {},
      district: {},
      city: {},
      region: {},
      regionSmallName: {},
    };

    if (!condominium || !condominium.addresses) return '';

    const { streetName, district, city, region, regionSmallName } =
      condominium.addresses.find(x => x.isPrincipal) || defaultAddress;

    const addressStartGroup = [streetName, district?.name].filter(item => item);
    const addressEndGroup = [
      city?.name,
      regionSmallName?.name || region?.name,
    ].filter(item => item);
    const separatorAddress =
      addressStartGroup.length && addressEndGroup.length ? ' - ' : '';

    return (
      addressStartGroup.join(', ') +
      separatorAddress +
      addressEndGroup.join(', ')
    );
  }, [condominium]);

  const { licenseParams } = useMemo(() => {
    const filteredParams = getCondominiumParam({
      paramsList: condominium?.params,
      paramName: condominiumParamsTypes.ERP_LICENSE,
    });
    if (filteredParams.length) {
      const { value } = filteredParams[0];
      return { licenseParams: JSON.parse(value) };
    }
    return { licenseParams: [] };
  }, [condominium]);

  const { monitoringEnabled } = useMemo(() => {
    const filteredParams = getCondominiumParam({
      paramsList: condominium?.params,
      paramName: condominiumParamsTypes.MONITORING_ENABLED,
    });
    if (filteredParams.length) {
      const { value } = filteredParams[0];
      return { monitoringEnabled: JSON.parse(value) };
    }
    return { monitoringEnabled: !filteredParams.length };
  }, [condominium]);

  const monitoringEnabledStatus = monitoringEnabled ? (
    <Badge color="primary500">{t('list.activation-status-types.active')}</Badge>
  ) : (
    <Badge color="secondary200">
      {t('list.activation-status-types.under-implementation')}
    </Badge>
  );

  return (
    <S.ClickableRow
      data-cy="condominium-list-item"
      onClick={() =>
        history.push(`/condominiums/${condominium.personContextId}`)
      }
    >
      <td>{condominium.name}</td>
      <td>{condominiumAddress}</td>
      <S.Td>
        {licenseParams?.licenses?.length ? (
          licenseParams.licenses.map(license => (
            <Fragment key={crypto.randomUUID()}>
              {license?.licenseType && (
                <LicenseBadges licenseName={license.licenseType} t={t} />
              )}
            </Fragment>
          ))
        ) : (
          <LicenseBadges licenseName={erpLicenses.nonexistent} t={t} />
        )}
      </S.Td>
      <td>{monitoringEnabledStatus}</td>
    </S.ClickableRow>
  );
}

CondominiumListItem.propTypes = {
  condominium: propTypes.object.isRequired,
};
