import propTypes from 'prop-types';
import { useFormikContext, ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { MdPhone } from 'react-icons/md';
import {
  UserAvatar,
  Input as DebouncedInput,
  Label,
  ErrorMessage as ErrorMessageComponent,
  Button,
  CustomInput,
} from '@kiper/ui';
import {
  Col,
  MainInfoContainer,
  AvatarContainer,
  FormGroup,
  Flex,
  PermissionsContainer,
  PermissionDescription,
  CondoSelect,
} from './styles';
import ApplicationProfileSelect from './ApplicationProfileSelect';
import ProfilePermissions from './ProfilePermissions';
import KiperEmailInput from '../../../components/KiperEmailInput';
import useCurrentLoggedContext from '../../../hooks/useCurrentLoggedContext';

const UserInfo = ({
  isEdition,
  editMode,
  onCancel,
  profiles,
  isLoading,
  shouldCreate,
  canSetOperatedCondo,
  needSetOperatedCondo,
}) => {
  const [t] = useTranslation('system-user');
  const history = useHistory();
  const {
    values,
    setFieldValue,
    errors,
    touched,
    isSubmitting,
    isValid,
    handleSubmit,
    setFieldTouched,
  } = useFormikContext();

  const { loggedContext } = useCurrentLoggedContext();
  const sourceTreeNodeId = loggedContext.topNodeId;

  return (
    <>
      <MainInfoContainer isEdition={isEdition}>
        <AvatarContainer xs={4}>
          <UserAvatar
            size={125}
            round="100%"
            src={values.email && `//avatar.kiper.com.br/${values.email}`}
            name={values.name}
          />
        </AvatarContainer>
        <Col xs={8}>
          <KiperEmailInput
            disabled={isEdition}
            sourceTreeNodeId={sourceTreeNodeId}
            onRedirectClick={userInfo =>
              history.push(
                userInfo.id ? `/system-user/${userInfo.id}` : '/system-users',
              )
            }
          />
          <FormGroup>
            <Label info block>
              {t('put.name')}
            </Label>
            {!editMode && isEdition ? (
              <Label>{values.name}</Label>
            ) : (
              <>
                <DebouncedInput
                  className="form-control"
                  type="text"
                  placeholder={t('put.placeholders.name')}
                  name="name"
                  id="name"
                  value={values.name}
                  onChange={setFieldValue}
                  delay={400}
                  invalid={touched.name && !!errors.name}
                />
                <ErrorMessage component={ErrorMessageComponent} name="name" />
              </>
            )}
          </FormGroup>
          <FormGroup>
            <Label info block>
              {t('put.phone')}
            </Label>
            {!editMode && isEdition ? (
              <Label>{values.phone}</Label>
            ) : (
              <>
                <CustomInput
                  required
                  isPhoneNumber
                  icon={<MdPhone />}
                  className="form-control"
                  type="text"
                  placeholder={t('put.placeholders.phone')}
                  name="phone"
                  id="phone"
                  value={values.phone}
                  onChange={setFieldValue}
                  onInput={() => setFieldTouched('phone', true, true)}
                  error={touched?.phone && errors.phone}
                />
              </>
            )}
          </FormGroup>
        </Col>
      </MainInfoContainer>
      <PermissionsContainer>
        {!isLoading && isEdition && !editMode && shouldCreate ? (
          <PermissionDescription>
            {t('put.add-profile-id-note')}
          </PermissionDescription>
        ) : (
          <>
            <FormGroup>
              <Label info block>
                {t('put.select-profile')}
              </Label>
              <ApplicationProfileSelect
                options={profiles}
                isLoading={isLoading}
                value={values.profileId}
                onChange={value => setFieldValue('profileId', value)}
                disabled={!editMode && isEdition}
              />
              {touched.profileId && !!errors.profileId && (
                <ErrorMessage
                  name="profileId"
                  component={ErrorMessageComponent}
                />
              )}
            </FormGroup>
            {values.profileId ? (
              <ProfilePermissions
                profileId={parseInt(values.profileId, 10)}
                t={t}
              />
            ) : null}
          </>
        )}
        {canSetOperatedCondo ? (
          <FormGroup>
            <Label info>{t('put.operated-condominium')}</Label>
            <CondoSelect
              placeholder={t('put.placeholders.operated-condominium')}
              name="sourceNodeId"
              sourceNodeId={sourceTreeNodeId}
              fieldName={['condominium']}
              isDisabled={!editMode && isEdition}
              value={values.parentTreeNodeId}
              isClearable={
                values.parentTreeNodeId !== sourceTreeNodeId &&
                !needSetOperatedCondo
              }
              onChange={e =>
                setFieldValue(
                  'parentTreeNodeId',
                  e?.action === 'clear' ? sourceTreeNodeId : e?.value,
                )
              }
              additionalOptions={
                needSetOperatedCondo
                  ? []
                  : [
                      {
                        label: t('put.all-condos'),
                        value: sourceTreeNodeId,
                        personContextId: sourceTreeNodeId,
                      },
                    ]
              }
            />
            <ErrorMessage
              component={ErrorMessageComponent}
              name="parentTreeNodeId"
            />
          </FormGroup>
        ) : null}
      </PermissionsContainer>
      {editMode && (
        <Flex justify="flex-end" margin="15px 0 0 0">
          <Button
            type="button"
            color="secondary"
            outline
            className="mr-3"
            onClick={onCancel}
          >
            {t('put.cancel')}
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={handleSubmit}
            loading={isSubmitting}
            disabled={isSubmitting || !isValid}
          >
            {isSubmitting ? t('buttons:saving') : t('buttons:save')}
          </Button>
        </Flex>
      )}
    </>
  );
};

export default UserInfo;

UserInfo.propTypes = {
  isEdition: propTypes.bool,
  editMode: propTypes.bool.isRequired,
  onCancel: propTypes.func.isRequired,
  profiles: propTypes.arrayOf(propTypes.object).isRequired,
  isLoading: propTypes.bool.isRequired,
  shouldCreate: propTypes.bool.isRequired,
  canSetOperatedCondo: propTypes.bool.isRequired,
  needSetOperatedCondo: propTypes.bool.isRequired,
};

UserInfo.defaultProps = {
  isEdition: false,
};
