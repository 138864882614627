import propTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { KiperButton } from '@kiper/ui';
import { Container, Img, Col, EmptyInfo } from './styles';

const EmptyState = ({ placeholder, action, text }) => (
  <Container>
    <Img src={placeholder} alt={text} />
    <Col md={6}>
      <EmptyInfo>{text}</EmptyInfo>
      {action && (
        <Link id="test" to={action.action}>
          <KiperButton>{action.text}</KiperButton>
        </Link>
      )}
    </Col>
  </Container>
);

export default EmptyState;

EmptyState.propTypes = {
  text: propTypes.string.isRequired,
  action: propTypes.object,
  placeholder: propTypes.string.isRequired,
};

EmptyState.defaultProps = {
  action: null,
};
