import styled from 'styled-components';
import { FormGroup as formGroup, Form as form } from 'reactstrap';
import { Input as input } from '@kiper/ui';

export const FormGroup = styled(formGroup)`
  margin-bottom: 0;
  width: 100%;
`;

export const Form = styled(form)`
  flex: 1;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 16px;

  a {
    text-decoration: none;
  }
`;

export const Input = styled(input)`
  border-radius: 8px;
  border-color: ${props => props.theme.colors.secondary400};
  width: 100% !important;
  ::placeholder {
    color: ${props => props.theme.colors.secondary400};
  }
`;
