import { useQuery, useMutation } from 'react-apollo';
import { accessRf as accessRfGql } from '@kiper/monitoring-graphql';
import { useSwal } from '@kiper/hooks';
import { useTranslation } from 'react-i18next';

const useAccessRf = deviceId => {
  const [t] = useTranslation('vehicular-control');
  const { toast } = useSwal();

  const { data, loading, error } = useQuery(accessRfGql.accessRf, {
    fetchPolicy: 'network-only',
    variables: { deviceId },
    skip: !deviceId,
  });

  const [startSync, { loading: isSynchronizing }] = useMutation(
    accessRfGql.sync,
    {
      onCompleted: () =>
        toast.fire({ title: t('sync.success'), icon: 'success' }),
      onError: () => toast.fire({ title: t('sync.fail'), icon: 'error' }),
    },
  );

  return {
    sync: (id = deviceId) =>
      startSync({ variables: { deviceId: id } }).then(
        res => res.data?.accessRfSync,
      ),
    device: data?.accessRf,
    isSynchronizing,
    loading,
    error,
  };
};

export default useAccessRf;
