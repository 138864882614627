import propTypes from 'prop-types';
import { HorizontalScroll } from '../HorizontalScroll';
import HorizontalFilterOption from './HorizontalFilterOption';

const HorizontalFilterOptions = ({ options, handleChange, optionSelected }) => {
  return (
    <HorizontalScroll>
      {!!options?.length &&
        options.map(option => (
          <HorizontalFilterOption
            key={`element-${option.id}`}
            itemId={`element-${option.id}`}
            description={option.description}
            selected={!!optionSelected && +optionSelected?.id === +option?.id}
            onClick={() => handleChange(option)}
          />
        ))}
    </HorizontalScroll>
  );
};

export default HorizontalFilterOptions;

HorizontalFilterOptions.propTypes = {
  options: propTypes.array.isRequired,
  handleChange: propTypes.func.isRequired,
  optionSelected: propTypes.object,
};

HorizontalFilterOptions.defaultProps = {
  optionSelected: null,
};
