import styled from 'styled-components';
import { MdOutlineCheckCircleOutline, MdHighlightOff } from 'react-icons/md';
import { RiRecordCircleLine } from 'react-icons/ri';
import { Text } from '@kiper/ui';
import { FiPhoneOff } from 'react-icons/fi';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

export const AttendancesList = styled.ul`
  all: unset;
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 8px;
`;

export const ListItem = styled.li`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
`;

export const AttendanceSubject = styled.span`
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.25px;
  flex-wrap: wrap;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
`;

export const SecondaryText = styled(Text)`
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  width: max-content;
  color: ${props => props.theme.colors.highBlack};
`;

export const Chip = styled.small`
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  color: ${props => props.theme.colors.mediumHighBlack};
  border-radius: 32px;
  padding: 0 16px;
  height: auto;
  width: auto;
  border: 1px solid ${props => props.theme.colors.mediumHighBlack};
`;

export const WrapperEmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 8px;
`;

export const TextEmptyState = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: max-content;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: ${props => props.theme.colors.black};
`;

export const IconWrapper = styled.div`
  height: max-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmptyStateIcon = styled.img`
  width: auto;
  object-fit: contain;

  @media screen and (min-width: 1980px) {
    height: 200px;
  }
  @media screen and (max-width: 1920px) {
    height: 200px;
  }
  @media screen and (max-width: 1600px) {
    height: 150px;
  }
`;

export const TextEllipsis = styled(Text)`
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 20px;
  cursor: default;
`;

export const OnGoingIcon = styled(RiRecordCircleLine).attrs(props => ({
  color: props.theme.colors.violet900,
  size: 24,
}))`
  outline: none;
`;

export const AuthorizedIcon = styled(MdOutlineCheckCircleOutline).attrs(
  props => ({
    color: props.theme.colors.primary500,
    size: 24,
  }),
)`
  outline: none;
`;

export const NotAuthorizedIcon = styled(MdHighlightOff).attrs(props => ({
  color: props.theme.colors.danger500,
  size: 24,
}))`
  outline: none;
`;

export const UnavailableContactsIcon = styled(FiPhoneOff).attrs(props => ({
  color: props.theme.colors.warning500,
  size: 24,
}))`
  outline: none;
`;
