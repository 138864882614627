import SytemConfigGrid from './Grid';
import detailRoutes from './Detail';

const breadcrumb = (label, to) => ({ label, to });
const requiredPermissions = ['systemSettings'];

export default [
  {
    name: 'System Setup',
    path: '/system-settings',
    exact: true,
    component: SytemConfigGrid,
    breadcrumb: [
      breadcrumb('common:breadcrumb.system-settings', '/system-settings'),
    ],
    requiredPermissions,
  },
  ...detailRoutes,
];
