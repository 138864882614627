import { auth } from '@kiper/monitoring-graphql';
import { get, types, setMap } from '@kiper/cookie';

const currentLoggedContext = async (_, args, { cache }) => {
  const { whoAmI } = await cache.readQuery({
    query: auth.whoAmIQuery,
  });

  const context = get(types.context);

  if (context) return whoAmI.find(x => x.personContextId === context);

  const currentContext = whoAmI[0];

  cache.writeQuery({
    query: auth.currentLoggedContext,
    data: { currentContext },
  });

  setMap({
    [types.context]: currentContext.personContextId,
    [types.partnerContext]: currentContext.partner.personContextId,
    [types.topNodeId]: currentContext.topNodeId,
    [types.topContextId]: currentContext.topContextId,
  });
  return currentContext;
};

export default currentLoggedContext;
