import { useEffect, useState, useMemo } from 'react';
import propTypes from 'prop-types';
import { useToggle } from '@kiper/hooks';
import { event as query } from '@kiper/monitoring-graphql';
import { FiBook, FiFileText } from 'react-icons/fi';
import { useQuery } from 'react-apollo';
import Sidebar from '../../../../components/Sidebar/Sidebar';
import SidebarContent from '../../../../components/Sidebar/SidedarContent';
import Tabbar from '../../../../components/Sidebar/Tabbar';
import TabbarItem from '../../../../components/Sidebar/Tabbar/TabbarItem';
import useRemoteConfig from '../../../../hooks/useRemoteConfig';
import { useCurrentLoggedContext } from '../../../../hooks';
import { sendAmplitudeData } from '../../../../services/amplitude';
import {
  CondominiumRules,
  CondominiumNotes,
  HowAct,
} from '../../../../components/QuickMenuParts';

function QuickMenu({ condominium, attendantScript, event }) {
  const [active, onToggle] = useToggle();
  const { loggedContext } = useCurrentLoggedContext();
  const [registerAccessAmplitude, setRegisterAccessAmplitude] = useState(false);

  const showCondominiumRules = useRemoteConfig({
    path: 'attendance.condominiumRules',
    condominium: Number(condominium?.personContext?.id),
    user: Number(loggedContext?.personContextId),
  });

  const condoRuleActivated = useMemo(
    () =>
      condominium?.personContext?.params?.some(
        param => param.name === 'condominiumRule' && param.value?.activated,
      ),
    [condominium],
  );

  const [selected, setSelected] = useState(
    showCondominiumRules && condoRuleActivated ? 'how-act' : 'condo-notes',
  );

  const { data, loading } = useQuery(query.eventTypeTreatmentMenu, {
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    onToggle();
  }, []);

  useEffect(() => {
    if (!loading && data?.eventTypeTreatmentMenu?.length) {
      const eventMenu = data.eventTypeTreatmentMenu.find(
        eventTreatmentMenu => eventTreatmentMenu.eventType === event?.eventType,
      );
      if (eventMenu) {
        setSelected(eventMenu.fieldName);
      }
    }
  }, [data]);

  const { condoRules, condoNotes, howAct } = useMemo(
    () => ({
      condoRules: selected === 'condo-rules',
      condoNotes: selected === 'condo-notes',
      howAct: selected === 'how-act',
    }),
    [selected],
  );

  useEffect(() => {
    if (registerAccessAmplitude) {
      sendAmplitudeData(`quick menu select option`, {
        pages: selected,
        condominium: Number(condominium?.personContext?.id),
        user: Number(loggedContext?.personContextId),
      });
    } else {
      setRegisterAccessAmplitude(true);
    }
  }, [condoRules, condoNotes, howAct]);

  return (
    <Sidebar
      onToggle={onToggle}
      active={active}
      selected={selected}
      setSelected={setSelected}
    >
      <SidebarContent>
        {showCondominiumRules && condoRules && condoRuleActivated && (
          <CondominiumRules condominium={condominium} event={event} />
        )}
        {condoNotes && !condoRuleActivated && (
          <CondominiumNotes condominium={condominium} />
        )}
        {howAct && <HowAct attendantScript={attendantScript} />}
      </SidebarContent>
      <Tabbar>
        {showCondominiumRules && condoRuleActivated && (
          <TabbarItem id="condo-rules">
            <FiBook size={24} />
          </TabbarItem>
        )}
        {!condoRuleActivated && (
          <TabbarItem id="condo-notes">
            <FiBook size={24} />
          </TabbarItem>
        )}
        <TabbarItem id="how-act">
          <FiFileText size={24} />
        </TabbarItem>
      </Tabbar>
    </Sidebar>
  );
}

export default QuickMenu;

QuickMenu.propTypes = {
  event: propTypes.object,
  condominium: propTypes.shape({
    personContext: propTypes.shape({
      id: propTypes.number,
      params: propTypes.array,
    }),
    name: propTypes.string,
  }),
  attendantScript: propTypes.string,
};

QuickMenu.defaultProps = {
  event: null,
  condominium: null,
  attendantScript: null,
};
