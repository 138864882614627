import {
  useRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useContext,
  useState,
} from 'react';
import propTypes from 'prop-types';
import { useLazyQuery } from 'react-apollo';
import { MdVideocamOff } from 'react-icons/md';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Flex, Spinner } from '@kiper/ui';
import { query } from '@kiper/monitoring-graphql/camera';
import useVideo from '../../hooks/useVideo';
import {
  Container,
  SelectedCameraContainer,
  SpinnerContainer,
  VideoImg,
  VideoNotFound,
  PlayerContainer,
  Button,
  PlayIcon,
  PauseIcon,
  ForwardIcon,
  ReplayIcon,
  FastRewindIcon,
  FastForwardIcon,
  Badge,
} from './styles';
import { sendAmplitudeData } from '../../services/amplitude';
import { useCurrentLoggedContext } from '../../hooks';
import CamerasList from './CamerasList';

const CamerasGrid = ({ cameras, selected, event }) => {
  const [t] = useTranslation('event_attendance');
  const theme = useContext(ThemeContext);
  const [cameraOffline, setCameraOffline] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState(selected);
  const videoRef = useRef(null);
  const { loggedContext } = useCurrentLoggedContext();

  const {
    time,
    isLive,
    frozen,
    onLiveClicked,
    onFrozenClicked,
    onRewind,
    onForward,
    resetTime,
    onTurnLiveOn,
  } = useVideo({
    eventDate: event?.videoStartDate,
  });

  const isAnsweredCall = event?.eventType === 102;

  const [liveFetch, { loading: liveLoading }] = useLazyQuery(query.cameraLive, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ cameraLive: live }) => {
      if (videoRef.current) {
        videoRef.current.src = live?.url;
      }
    },
  });

  const [thumbnailFetch, { loading: thumbnailLoading }] = useLazyQuery(
    query.cameraThumbnail,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ cameraPlayerThumbnail }) => {
        if (videoRef.current) {
          videoRef.current.src = cameraPlayerThumbnail?.url;
        }
      },
    },
  );

  const selectCamera = camera => {
    resetTime();
    setSelectedCamera(camera);
  };

  const fetchImage = newTime => {
    if (selectedCamera?.id) {
      liveFetch({
        variables: {
          cameraId: selectedCamera.id,
          startDateTime: !isLive ? newTime : null,
          condominiumPersonContextId: event?.condominium?.personContext?.id,
        },
      });
    }
  };

  useEffect(() => setCameraOffline(false), [cameras]);

  useEffect(() => {
    if (isAnsweredCall) {
      onLiveClicked(true);
      onTurnLiveOn();
    }
  }, [selectedCamera, isAnsweredCall]);

  useEffect(() => {
    if (selected) {
      setSelectedCamera(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (selectedCamera?.id) {
      if (videoRef.current) {
        videoRef.current.src = '';
      }
      if (frozen) {
        thumbnailFetch({
          variables: {
            cameraId: selectedCamera?.id,
            dateTime: !isLive ? time : null,
            condominiumPersonContextId: event?.condominium?.personContext?.id,
          },
        });
      } else {
        fetchImage(time);
      }
    }
  }, [selectedCamera, frozen, isLive]);

  const cameraError = useMemo(
    () => cameraOffline && !liveLoading && !thumbnailLoading,
    [cameraOffline, liveLoading, thumbnailLoading],
  );

  const loading = useMemo(() => liveLoading || thumbnailLoading, [
    liveLoading,
    thumbnailLoading,
  ]);

  const handleRewind = async () => {
    const newTime = await onRewind();

    fetchImage(newTime);
  };

  const handleForward = async () => {
    const newTime = await onForward();

    fetchImage(newTime);
  };

  const getBadgeColor = () => {
    if (isLive && !frozen) {
      return 'danger';
    }
    if (frozen) {
      return 'secondary';
    }
    return 'warning';
  };

  const getBadgeText = () => {
    if ((isLive && !frozen) || isAnsweredCall) {
      return t('details.cameras.player.live');
    }
    if (frozen) {
      return t('details.cameras.player.frozen');
    }
    return t('details.cameras.player.playback');
  };

  useLayoutEffect(() => {
    return () => {
      if (videoRef?.current) {
        videoRef.current.src = '';
      }
    };
  }, []);

  const handleClickThumbnail = cam => {
    selectCamera(cam);
    sendAmplitudeData('changed selected cam', {
      eventId: event?.eventId,
      condominium: event?.condominium?.name,
      user: loggedContext?.personContextId,
      partner: loggedContext?.partner?.name,
    });
  };

  return (
    <Container>
      <SelectedCameraContainer
        loading={Number(loading)}
        isOffline={!!cameraOffline}
      >
        {loading && (
          <SpinnerContainer>
            <Spinner size={24} color={theme.colors.secondary50} />
          </SpinnerContainer>
        )}
        {cameraError && !loading && (
          <VideoNotFound>
            <MdVideocamOff size={40} />
          </VideoNotFound>
        )}
        {!loading && !cameraError && (
          <VideoImg
            ref={videoRef}
            alt={selectedCamera && selectedCamera.name}
            onError={() => setCameraOffline(true)}
          />
        )}
      </SelectedCameraContainer>
      {!cameraError && (
        <PlayerContainer>
          {!loading && !cameraError && (
            <Flex flex={1}>
              <Badge color={getBadgeColor()}>{getBadgeText()}</Badge>
            </Flex>
          )}
          {!loading && (
            <Flex
              flex={isAnsweredCall ? 1.2 : 1}
              justifyContent={isAnsweredCall ? 'flex-start' : 'center'}
            >
              {!isLive && !frozen && !isAnsweredCall && (
                <ReplayIcon size="30" color="white" onClick={handleRewind} />
              )}
              {frozen ? (
                <PlayIcon size="30" color="white" onClick={onFrozenClicked} />
              ) : (
                <PauseIcon size="30" color="white" onClick={onFrozenClicked} />
              )}
              {!isLive && !frozen && !isAnsweredCall && (
                <ForwardIcon size="30" color="white" onClick={handleForward} />
              )}
            </Flex>
          )}
          {!loading && !isAnsweredCall && (
            <Flex flex={1} justifyContent="flex-end">
              {isLive ? (
                <Button
                  type="button"
                  size="sm"
                  onClick={() => onLiveClicked(false)}
                >
                  <FastRewindIcon />
                  {t('details.cameras.player.playbackButton')}
                </Button>
              ) : (
                <Button
                  type="button"
                  size="sm"
                  onClick={() => onLiveClicked(true)}
                >
                  {t('details.cameras.player.liveButton')}
                  <FastForwardIcon />
                </Button>
              )}
            </Flex>
          )}
        </PlayerContainer>
      )}
      <CamerasList
        handleClickThumbnail={handleClickThumbnail}
        cameras={cameras}
        isLive={isLive}
        time={time}
        event={event}
      />
    </Container>
  );
};

CamerasGrid.propTypes = {
  cameras: propTypes.array,
  selected: propTypes.object,
  event: propTypes.any.isRequired,
};

CamerasGrid.defaultProps = {
  cameras: [],
  selected: undefined,
};

export default CamerasGrid;
