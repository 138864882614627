import { useMemo } from 'react';
import propTypes from 'prop-types';
import { showPlayback } from '@kiper/monitoring-graphql/attendance';
import { useTranslation, Trans } from 'react-i18next';
import { Flex, Text } from '@kiper/ui';
import {
  formatDateTimeUtc,
  apolloErrorHandler,
  formatDateTime,
} from '@kiper/fns';
import { useMutation } from 'react-apollo';
import { useSwal } from '@kiper/hooks';
import { profileTypes } from '../../../constants';
import { activityTypeControl } from './activityTypeControl';

const EventActivityItem = ({ activity, event }) => {
  const [t] = useTranslation('modals/event-activity-modal');
  const { toast } = useSwal();

  const [fetchPlayback, { loading: loadingShowPlayback }] = useMutation(
    showPlayback,
    {
      fetchPolicy: 'no-cache',
      variables: {
        eventId: event?.eventId,
        eventDate: formatDateTime(event.creationDate, 'YYYY-MM-DD HH:mm:ss'),
      },
      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length) {
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
        }
      },
    },
  );

  const {
    iconStatus,
    primaryText,
    secondaryText,
    optionalText,
    additionalText,
    action,
  } = useMemo(() => {
    const {
      attendantPersonContext,
      creationDate,
      activityTypeId,
      additionalInformation,
    } = activity;

    let text;

    if (creationDate) {
      text = `${formatDateTimeUtc(creationDate)}`;
    }
    if (attendantPersonContext?.profile?.fieldName) {
      text += ` - ${t(
        `common:profiles.${
          profileTypes[attendantPersonContext.profile.fieldName]
        }`,
      )}: `;
    }
    if (attendantPersonContext?.person?.name) {
      text += attendantPersonContext.person.name;
    }

    return activityTypeControl({
      event,
      fetchPlayback,
      loadingShowPlayback,
      activityTypeId,
      additionalInformation,
      t,
      text,
    });
  });

  return (
    <Flex width="100%" gridGap="12px">
      {iconStatus}
      <Flex
        justifyContent="space-between"
        flexWrap="wrap"
        width="100%"
        pr="8px"
      >
        <Flex flex={1} flexDirection="column" width="100%">
          <Text fontSize="12px" color="secondary700" wordBreak="break-word">
            {primaryText}
          </Text>
          <Trans>
            <Text
              fontSize="14px"
              fontWeight="600"
              color="highBlack"
              wordBreak="break-word"
            >
              {secondaryText}
            </Text>
          </Trans>
          {optionalText && (
            <Text fontSize="14px" color="secondary700" wordBreak="break-word">
              {optionalText}
            </Text>
          )}
          {additionalText}
        </Flex>
        {action}
      </Flex>
    </Flex>
  );
};

export default EventActivityItem;

EventActivityItem.propTypes = {
  activity: propTypes.any.isRequired,
  event: propTypes.any.isRequired,
};
