import propTypes from 'prop-types';
import { MdInfoOutline } from 'react-icons/md';
import { Trans } from 'react-i18next';
import { Flex } from '@kiper/ui';
import { Container, Text, Wrapper } from './styles';

const AwaitingSubtriage = ({ t }) => {
  return (
    <Container>
      <Wrapper>
        <Flex justifyContent="flex-start">
          <MdInfoOutline size="24px" />
        </Flex>
        <Text>
          <Trans>{t('awaiting-subtriage')}</Trans>
        </Text>
      </Wrapper>
    </Container>
  );
};

export default AwaitingSubtriage;

AwaitingSubtriage.propTypes = {
  t: propTypes.func.isRequired,
};
