import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;

  /* top: 0;
  right: 0;
  bottom: 0; */

  /* @media screen and (min-width: 1980px) {
    max-width: 550px;
  }
  @media screen and (max-width: 1920px) {
    max-width: 550px;
  }
  @media screen and (max-width: 1600px) {
    max-width: 380px;
  } */
`;
