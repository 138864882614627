import styled from 'styled-components';
import { Col as col } from 'reactstrap';
import { Flex } from '../styles';

export const Input = styled.input`
  width: 250px !important;
`;

export const SpaceBetween = styled(Flex)`
  justify-content: space-between;
  margin-top: 10px;
`;

export const Col = styled(col)`
  padding-left: 0;
`;

export const ClickableRow = styled.tr`
  cursor: pointer;
`;

export const Td = styled.td`
  display: inline-flex;
  gap: 4px;
  height: auto;
  width: 100%;
`;
