export const IOT_COMMANDS = {
  OPEN: 'open',
  CLOSE: 'close',
  OPEN_KEEP: 'open/keep',
};

export const ALARM_COMMANDS = {
  ACTIVATE: 'activate',
  DEACTIVATE: 'deactivate',
};

export const RELAYS_TYPES = {
  ALARM: 'ALARM',
  IOT: 'IOT',
};
