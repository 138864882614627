import propTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { camera } from '@kiper/monitoring-graphql';
import { Select } from '@kiper/ui';
import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { Label } from './styles';

export default function Monitor({ isClearable, value, label, ...props }) {
  const { toast } = useSwal();

  const { data, loading } = useQuery(camera.query.monitorSelect, {
    fetchPolicy: 'network-only',
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const getValue = () => {
    return value &&
      data?.monitorSelect?.length &&
      (typeof value === 'number' || typeof value === 'string')
      ? data?.monitorSelect?.find(i => i.value === value)
      : value;
  };

  return (
    <>
      {label && <Label>{label}</Label>}
      <Select
        id="select-monitor"
        {...props}
        value={getValue()}
        isClearable={isClearable}
        isLoading={loading}
        options={data?.monitorSelect || []}
      />
    </>
  );
}

Monitor.propTypes = {
  value: propTypes.any,
  isClearable: propTypes.bool,
  label: propTypes.string,
};

Monitor.defaultProps = {
  isClearable: false,
  value: null,
  label: null,
};
