import { useContext } from 'react';
import propTypes from 'prop-types';
import { PlusCircle } from 'react-feather';
import { ThemeContext } from 'styled-components';
import { AddMore as AddMoreContainer } from './styles';

const AddMore = ({ size, text, ...props }) => {
  const theme = useContext(ThemeContext);
  return (
    <AddMoreContainer type="button" {...props}>
      <PlusCircle
        color={theme.colors.highWhite}
        fill={theme.colors.primary500}
        size={size}
      />
      {text}
    </AddMoreContainer>
  );
};

export default AddMore;

AddMore.propTypes = {
  text: propTypes.string.isRequired,
  size: propTypes.number,
};

AddMore.defaultProps = {
  size: 42,
};
