import { Flex, Text } from '@kiper/ui';
import styled from 'styled-components';

export const Title = styled(Text)`
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.25px;
`;

export const ImageContainer = styled.div`
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.gray900};

  @media screen and (min-width: 1366px) {
    height: 220px;
  }

  @media screen and (min-width: 1920px) {
    height: 317px;
  }
`;

export const Thumbnail = styled.img`
  border-radius: 8px;
  object-fit: cover;

  @media screen and (min-width: 1366px) {
    height: 220px;
  }

  @media screen and (min-width: 1920px) {
    height: 317px;
  }
`;

export const ThumbnailContainer = styled(Flex)`
  position: relative;
  background-color: ${props => props.theme.colors.gray900};
  border-radius: 8px;
`;
