import propTypes from 'prop-types';

import { Trans, useTranslation } from 'react-i18next';

import { Modal, Flex, Box, Text, KiperButton } from '@kiper/ui';
import { useToggle } from '@kiper/hooks';

import noExtension from '../../../assets/images/noExtension.svg';
import MySettingsModal from '../MySettingsModal';

function NoKiperVoiceAgentModal({ onToggle, open }) {
  const [t] = useTranslation('modals/no-extension-modal');
  const [openMySettingsModal, toggleOpenMySettingsModal] = useToggle(false);

  return (
    <Modal
      open={open}
      id="no-extension-modal"
      data-cy="no-extension-modal"
      toggle={onToggle}
      size="md"
    >
      <>
        <Flex
          py="50px"
          px="50px"
          flexDirection="column"
          aligntItems="center"
          width="100%"
        >
          <Flex mb="20px" justifyContent="center">
            <img
              style={{ maxWidth: '320px' }}
              src={noExtension}
              alt={t('image-alt')}
            />
          </Flex>
          <Text textAlign="center" mb="12px" fontSize="20px" fontWeight="500">
            {t('cannot-make-calls')}
          </Text>
          <Text textAlign="justify" mb="30px" fontSize="14px" fontWeight="400">
            <Trans>{t('extension-not-linked')}</Trans>
          </Text>
          <Flex justifyContent="center">
            <Box mr="10px">
              <KiperButton
                data-cy="close-extension-modal"
                onClick={onToggle}
                variant="outlined"
                color="secondary"
              >
                {t('close')}
              </KiperButton>
            </Box>
            <KiperButton
              data-cy="open-my-settings-btn"
              onClick={() => {
                toggleOpenMySettingsModal();
              }}
            >
              {t('open-my-settings')}
            </KiperButton>
          </Flex>
        </Flex>
        {openMySettingsModal && (
          <MySettingsModal
            open={openMySettingsModal}
            onToggle={() => {
              toggleOpenMySettingsModal();
              onToggle();
            }}
          />
        )}
      </>
    </Modal>
  );
}

export default NoKiperVoiceAgentModal;

NoKiperVoiceAgentModal.propTypes = {
  onToggle: propTypes.func.isRequired,
  open: propTypes.bool.isRequired,
};
