import styled, { css } from 'styled-components';
import { Box, KiperRadioCustomButton } from '@kiper/ui';

export const OptionsContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px 8px;
  width: 100%;
`;

export const Button = styled(KiperRadioCustomButton)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 10px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.colors.secondary800};
  transition: all 0.3s;
  width: 100%;

  @media screen and (max-width: 1366px) {
    width: 150px;
  }

  ${props =>
    props.checked &&
    css`
      box-shadow: 0 0 0 1px ${props.theme.colors.primary500};
      background-color: ${props.theme.colors.primary20};
      border-color: ${props.theme.colors.primary500};
      color: ${props.theme.colors.primary500} !important;
      transition: all 0.3s;

      &:hover {
        background-color: ${props.theme.colors.primary50} !important;
      }
    `}
`;
