import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import * as queries from '@kiper/monitoring-graphql/guided_attendance';
import { useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { dwellerValidationStatus } from '../../../../constants';

const useFetchGetDwellers = ({
  unityId,
  handleChangeInvalidValidationStatus,
}) => {
  const { toast } = useSwal();
  const [dwellers, setDwellers] = useState([]);

  const [getDwellers, { loading }] = useLazyQuery(
    queries.dwellersWithDocuments,
    {
      onCompleted: ({ dwellersWithDocuments }) => {
        setDwellers(dwellersWithDocuments);
        if (dwellersWithDocuments?.length === 0) {
          handleChangeInvalidValidationStatus(
            dwellerValidationStatus.NO_DWELLERS,
          );
        }
      },
      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length) {
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
        }
      },
    },
  );

  useEffect(() => getDwellers({ variables: { unityId } }), []);

  return {
    loading,
    dwellers,
  };
};

export default useFetchGetDwellers;
