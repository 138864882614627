export const tTableColumnText = text => `table.column.${text}`;
function Column(name, align, width, order) {
  this.name = name;
  this.align = align;
  this.width = width;
  this.order = order;
}

const ALIGN_LEFT = 'left';

const WIDTH_DEPOSIT_DATE = '13%';
const WIDTH_GROUP_UNITY_NAME = '22%';
const WIDTH_UNITY_NAME = '22%';
const WIDTH_STATUS = '20%';
const WIDTH_WITHDRAWAL_DATE = '13%';
const WIDTH_WITHDRAWAL_CODE = '15%';

export const createColumns = t => [
  new Column(
    t(tTableColumnText('deposit-date')),
    ALIGN_LEFT,
    WIDTH_DEPOSIT_DATE,
  ),
  new Column(
    t(tTableColumnText('group-unity-name')),
    ALIGN_LEFT,
    WIDTH_GROUP_UNITY_NAME,
  ),
  new Column(t(tTableColumnText('unity-name')), ALIGN_LEFT, WIDTH_UNITY_NAME),
  new Column(t(tTableColumnText('status')), ALIGN_LEFT, WIDTH_STATUS),
  new Column(
    t(tTableColumnText('withdrawal-date')),
    ALIGN_LEFT,
    WIDTH_WITHDRAWAL_DATE,
  ),
  new Column(
    t(tTableColumnText('withdrawal-code')),
    ALIGN_LEFT,
    WIDTH_WITHDRAWAL_CODE,
  ),
];

export const INITIAL_FILTERS = {
  page: 1,
  pageSize: 10,
};
