import propTypes from 'prop-types';
import { Flex, Spinner, Text } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import HorizontalFilterOptions from '../../../../HorizontalFilterOptions';
import { useGetQuestions, useGetSubtriages } from './hooks';
import { sendAmplitudeData } from '../../../../../services/amplitude';
import { Title } from './styles';

const translatePrefix = (t, key) =>
  t(`quick-menu.condominium-rule-questions-awswers.filter.${key}`);

const SubtriageFilter = ({
  filteredTriage,
  filteredSubtriage,
  setFilterContext,
  condominiumPersonContextId,
}) => {
  const { t } = useTranslation('guided-attendance');
  const { subtriages, loading } = useGetSubtriages({
    filteredTriage,
    filteredSubtriage,
  });
  const { getQuestions } = useGetQuestions({ setFilterContext });

  const handleChange = subtriage => {
    if (subtriage?.id !== filteredSubtriage?.id) {
      setFilterContext({
        filteredSubtriage: subtriage,
        loading: true,
      });
      getQuestions({
        variables: {
          condominiumPersonContextId,
          subtriageId: subtriage?.id,
        },
      });
      sendAmplitudeData(
        'condominium rules filter: subtriage changed',
        subtriage,
      );
    }
  };

  return (
    <Flex flexDirection="column" width="100%">
      <Title>{translatePrefix(t, 'subtriages')}</Title>
      {loading && (
        <Flex width="100%" alignItems="center">
          <Spinner size={24} />
        </Flex>
      )}
      {!loading && (
        <>
          {subtriages?.length ? (
            <HorizontalFilterOptions
              options={subtriages}
              handleChange={handleChange}
              optionSelected={filteredSubtriage}
            />
          ) : (
            <Text>{translatePrefix(t, 'subtriages-not-found')}</Text>
          )}
        </>
      )}
    </Flex>
  );
};

export default SubtriageFilter;

SubtriageFilter.propTypes = {
  filteredTriage: propTypes.object,
  filteredSubtriage: propTypes.object,
  setFilterContext: propTypes.func.isRequired,
  condominiumPersonContextId: propTypes.number.isRequired,
};

SubtriageFilter.defaultProps = {
  filteredTriage: null,
  filteredSubtriage: null,
};
