import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Page, Breadcrumb } from '@kiper/ui';
import propTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { templateParams } from '@kiper/monitoring-graphql/template_params';
import {
  MdMail as MailIcon,
  MdLocalPhone as PhoneIcon,
  MdVideocam as VideoIcon,
} from 'react-icons/md';
import { AiFillBell as AlarmIcon } from 'react-icons/ai';
import SettingsCard from '../../../components/SettingsCard';
import {
  MainInfoWrapper,
  MainInfo,
  MainInfoImage,
  InfoLabel,
  GridContainer,
} from './styles';

import mainInfoImage from '../../../assets/images/sistem-config-main-info.svg';

const MESSAGE_ALIAS = {
  generalEmailSettings: 'list.generalEmailSettings',
  imageSettings: 'list.imageSettings',
  voiceSettings: 'list.voiceSettings',
  alarmSettings: 'list.alarmSettings',
};

const ICON = {
  generalEmailSettings: MailIcon,
  imageSettings: VideoIcon,
  voiceSettings: PhoneIcon,
  alarmSettings: AlarmIcon,
};

export default function SystemSettingsList({ route }) {
  const [t] = useTranslation('system-settings');

  const { data } = useQuery(templateParams, {
    variables: {
      profileId: 8, // TODO usar personContextId do contexto loggedContext quando endpoint for alterado
    },
  });

  const groupByCategories = values => {
    const categories = new Set();

    return values.reduce((result, current) => {
      const category = current?.category;

      if (category && !categories.has(category)) {
        categories.add(category);

        return [
          ...result,
          {
            category,
            items: values
              .filter(item => item?.category === category)
              .map(({ category: cat, ...restAttributtes }) => {
                return { ...restAttributtes };
              }),
          },
        ];
      }

      return result;
    }, []);
  };

  const groupedData = useMemo(() => {
    if (!data || !data.templateParams) return [];

    return groupByCategories(data.templateParams);
  }, [data]);

  return (
    <Page>
      <Breadcrumb stack={route.breadcrumb} formatter={t} />
      <Page.Title>{t('list.title')}</Page.Title>

      <MainInfoWrapper>
        <MainInfo xs="auto" sm={6} md={8}>
          <InfoLabel>{t('list.main-info')}</InfoLabel>
        </MainInfo>
        <MainInfoImage xs="auto" sm={2} md={4}>
          <img src={mainInfoImage} alt="mainInfo" />
        </MainInfoImage>
      </MainInfoWrapper>

      <hr />

      <GridContainer>
        {groupedData.map((current, key) => {
          const { category, items } = current;
          const messageAlias = MESSAGE_ALIAS[`${category}`];

          return (
            <SettingsCard
              key={key}
              title={t(`${messageAlias}.title`)}
              content={items}
              icon={ICON[category]}
            />
          );
        })}
      </GridContainer>
    </Page>
  );
}

SystemSettingsList.propTypes = {
  route: propTypes.object.isRequired,
};
