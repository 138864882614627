import { getQuestionsAndAnswers } from '@kiper/monitoring-graphql/guided_attendance';
import { useLazyQuery } from 'react-apollo';

const useGetQuestions = ({ setFilterContext }) => {
  const [getQuestions] = useLazyQuery(getQuestionsAndAnswers, {
    fetchPolicy: 'network-only',
    onCompleted: ({ condominiumRuleQuestionsAndAnswers }) => {
      if (condominiumRuleQuestionsAndAnswers?.length) {
        setFilterContext({
          questions: condominiumRuleQuestionsAndAnswers,
          loading: false,
        });
      }
    },
    onError: () => {
      setFilterContext({
        loading: false,
      });
    },
  });

  return { getQuestions };
};

export default useGetQuestions;
