import { useContext, useMemo } from 'react';
import propTypes from 'prop-types';
import { useMutation } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  insertGuidedAttendanceData,
  updateIntrusionDetectionStatus,
} from '@kiper/monitoring-graphql/guided_attendance';

import { MdOutlinePlace } from 'react-icons/md';
import { Flex } from '@kiper/ui';

import { GuidedAttendanceContext } from '../../../store';
import { useLastAttendancesByEventType } from '../../../hooks';
import useCameraInfo from './hooks/useCameraInfo';

import * as S from './styles';

const StepIntrusionDetection = ({ event }) => {
  const history = useHistory();
  const { t } = useTranslation('guided-attendance');

  const {
    contextTree: { tree, currentStep },
  } = useContext(GuidedAttendanceContext);

  const { lastAttendances } = useLastAttendancesByEventType({
    eventType: event?.eventType,
    condominiumPersonContextId: event?.condominium?.personContextId,
  });

  const { cameraId, cameraName, howAct, zoneInformation } = useCameraInfo({
    event,
  });

  const [insertRedisData, { loading: loadingInsertRedis }] = useMutation(
    insertGuidedAttendanceData,
    {
      onCompleted: () => {
        history.push(`/guided-attendance/${event?.eventId}/fallback`);
      },
    },
  );

  const [updateStatus, { loading: loadingStatus }] = useMutation(
    updateIntrusionDetectionStatus,
  );

  const loading = useMemo(() => loadingInsertRedis || loadingStatus, [
    loadingInsertRedis,
    loadingStatus,
  ]);

  const handleFallback = () => {
    insertRedisData({
      variables: {
        data: {
          currentStep: currentStep?.id || 99,
          data: JSON.stringify({ isInFallback: true }),
          rootAttendanceStepId: tree.attendanceStepId,
          eventId: event?.eventId,
        },
      },
    });
  };

  const handleUpdateIntrusionDetectionStatus = ({ intrusionDetected }) => {
    updateStatus({
      variables: {
        eventId: event.eventId,
        intrusionDetected,
      },
    });

    if (intrusionDetected) handleFallback();
  };

  return (
    <S.Container>
      <S.Box>
        <S.Title>
          {t('step-intrusion-detection.title', {
            eventType: t(
              `step-intrusion-detection.event-type.${event?.eventType}`,
            ),
          })}
        </S.Title>
        <Flex gridGap="8px">
          {event?.place?.name && (
            <S.Badge color="info500" bordercolor="info500">
              <MdOutlinePlace size={14} color="black" />
              <S.BadgeText title={event?.place?.name}>
                {event?.place?.name}
              </S.BadgeText>
            </S.Badge>
          )}
          {zoneInformation && (
            <S.Badge color="info500" bordercolor="info500">
              <MdOutlinePlace size={14} color="black" />
              <S.BadgeText title={zoneInformation}>
                {zoneInformation}
              </S.BadgeText>
            </S.Badge>
          )}
        </Flex>
        {lastAttendances?.length > 1 && (
          <S.AlertText>
            {t('step-intrusion-detection.alert', {
              count: lastAttendances.length,
            })}
          </S.AlertText>
        )}
        <S.CameraContainer>
          <S.GuidelinesTitle>
            {t('step-intrusion-detection.camera-orientation')}
          </S.GuidelinesTitle>
          {howAct && <S.HowActText title={howAct}>{howAct}</S.HowActText>}

          <S.Question>
            {cameraId && cameraName
              ? t('step-intrusion-detection.question')
              : t('step-intrusion-detection.camera-not-found')}
          </S.Question>

          <Flex gridGap="12px">
            <S.Button
              type="button"
              size="sm"
              color="secondary"
              outline
              disabled={loading}
              onClick={() =>
                handleUpdateIntrusionDetectionStatus({
                  intrusionDetected: true,
                })
              }
            >
              {t('step-intrusion-detection.actions.has-risk-situation')}
            </S.Button>
            <S.Button
              type="button"
              size="sm"
              color="secondary"
              outline
              disabled={loading}
              onClick={() =>
                handleUpdateIntrusionDetectionStatus({
                  intrusionDetected: false,
                })
              }
            >
              {t('step-intrusion-detection.actions.no-risk-situation')}
            </S.Button>
          </Flex>
        </S.CameraContainer>
      </S.Box>
    </S.Container>
  );
};

export default StepIntrusionDetection;

StepIntrusionDetection.propTypes = {
  event: propTypes.shape({
    eventId: propTypes.string,
    eventType: propTypes.number,
    eventDate: propTypes.string,
    condominium: propTypes.shape({
      personContextId: propTypes.number,
    }),
    place: propTypes.shape({
      id: propTypes.number,
      name: propTypes.string,
      serializedParams: propTypes.string,
    }),
    additionalInformation: propTypes.string,
  }).isRequired,
};
