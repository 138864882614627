import { Redirect } from 'react-router-dom';

import Root from '../components/Root';
import UsersPage from './Users';
import InvitesPage from './Invites';
import CondominiumsPages from './Condominiums';
import EventsPage from './EventAttendance';
import SystemUsersPage from './SystemUsers';
import EventSettings from './EventSettings';
import SystemSettingsPages from './SystemSettings';
import ReportPages from './Report';
import EventGeneratorPages from './EventGenerator';
import GuidedAttendancePages from './GuidedAttendance';
import GuidedAttendanceSettingsPages from './GuidedAttendanceSettings';
import { useViewRules } from '../hooks';

const routes = [
  ...UsersPage,
  ...InvitesPage,
  ...CondominiumsPages,
  ...EventsPage,
  ...SystemUsersPage,
  ...EventSettings,
  ...SystemSettingsPages,
  ...ReportPages,
  ...EventGeneratorPages,
  ...GuidedAttendancePages,
  ...GuidedAttendanceSettingsPages,
];

const RouteRedirect = () => {
  const { permissions } = useViewRules();

  const allowedRoutes = routes
    ?.map(x =>
      x?.requiredPermissions?.find(y =>
        permissions?.find(z => z.applicationFeature.fieldName === y),
      )
        ? x
        : null,
    )
    .filter(z => z);

  return <Redirect to={allowedRoutes[0]?.path} />;
};

export default [
  {
    component: Root,
    routes: [
      ...routes,
      {
        path: '/',
        exact: true,
        render: () => <RouteRedirect />, //eslint-disable-line
      },
    ],
  },
];
