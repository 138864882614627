import styled, { css } from 'styled-components';
import { Label } from '@kiper/ui';

export const WeekContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  ${props =>
    props.onlyLabels &&
    props.disabled &&
    css`
      justify-content: unset;
      gap: 3px;
    `}
`;

export const WeekItem = styled.div`
  align-items: center;
  justify-content: center;
  /* border-color: #26905f; */
`;

export const LabelValue = styled(Label)`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
`;
