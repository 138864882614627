import { useContext } from 'react';
import propTypes from 'prop-types';
import { useToggleData } from '@kiper/hooks';
import { ThemeContext } from 'styled-components';
import { FaAngleDoubleDown, FaAngleUp } from 'react-icons/fa';
import { KiperButton } from '@kiper/ui';
import { Label } from '../styles';
import RowDetail from './RowDetail';
import FreeAccess from '../../../Badges/FreeAccess';

const InviteRow = ({ data, t }) => {
  const [openedRows, toggleOpenedRow] = useToggleData({});
  const theme = useContext(ThemeContext);
  return (
    <>
      <tr>
        <td align="left">
          <Label block info>
            {data?.name}
          </Label>
        </td>
        <td align="left">
          <Label block info>
            {data?.host?.name}
          </Label>
        </td>
        <td align="left">
          <Label block info>
            {data?.condominium?.name}
          </Label>
        </td>
        <td align="left">
          <FreeAccess free={data?.isFreeAccess} />
        </td>
        <td align="center">
          <Label block info>
            {data?.inviteds?.length ?? ''}
          </Label>
        </td>
        <td align="right">
          {data?.inviteds?.length ? (
            // TODO aplicar variante de botão circular
            <KiperButton
              variant="text"
              color="neutral"
              rounded
              onClick={() => toggleOpenedRow(data.personContextId)}
            >
              {openedRows[data.personContextId] ? (
                <FaAngleUp size={16} color={theme.colors.secondary700} />
              ) : (
                <FaAngleDoubleDown
                  size={16}
                  color={theme.colors.secondary700}
                />
              )}
            </KiperButton>
          ) : null}
        </td>
      </tr>
      {data?.inviteds?.length && openedRows[data.personContextId] ? (
        <tr className="collapse show">
          <RowDetail data={data} theme={theme} t={t} />
        </tr>
      ) : null}
    </>
  );
};

export default InviteRow;

InviteRow.propTypes = {
  data: propTypes.shape({
    profileName: propTypes.string,
    personContextId: propTypes.string,
  }).isRequired,
  t: propTypes.func.isRequired,
};
