import propTypes from 'prop-types';
import { Flex, Text } from '@kiper/ui';
import { FiClock } from 'react-icons/fi';
import { formatTimerLabel } from '@kiper/fns';
import { useTranslation } from 'react-i18next';
import { profileTypes } from '../../../../constants';

const EventOnHold = ({ event }) => {
  const [t] = useTranslation('event_attendance');
  const onHoldTime =
    event?.onHold?.additionalInformation &&
    JSON.parse(event?.onHold.additionalInformation)?.waitTimer;

  return (
    <Flex
      mx="16px"
      mt="16px"
      p="16px"
      borderRadius="8px"
      flex={1}
      bg="orange50"
    >
      <Flex width="24px">
        <FiClock size={24} />
      </Flex>
      <Flex ml="10px" flexDirection="column">
        <Text fontWeight="bold">
          {event?.onHold?.onHoldStatus
            ? t('on-hold-time-user', {
                time: formatTimerLabel(onHoldTime || ''),
                user: event?.onHold?.attendant?.name || '',
                typeUser: event?.onHold?.attendant?.personContexts?.[0]?.profile
                  ?.fieldName
                  ? t(
                      `common:profiles.${
                        profileTypes[
                          event?.onHold?.attendant?.personContexts?.[0]?.profile
                            ?.fieldName
                        ]
                      }`,
                    )
                  : '',
              })
            : t('on-hold-time-user-after', {
                time: formatTimerLabel(onHoldTime || ''),
                user: event?.onHold?.attendant?.name || '',
                typeUser: event?.onHold?.attendant?.personContexts?.[0]?.profile
                  ?.fieldName
                  ? t(
                      `common:profiles.${
                        profileTypes[
                          event?.onHold?.attendant?.personContexts?.[0]?.profile
                            ?.fieldName
                        ]
                      }`,
                    )
                  : '',
              })}
        </Text>
        <Text>
          {event?.onHold &&
            JSON.parse(event?.onHold?.additionalInformation)?.reason}
        </Text>
      </Flex>
    </Flex>
  );
};

export default EventOnHold;

EventOnHold.propTypes = {
  event: propTypes.object.isRequired,
};
