import propTypes from 'prop-types';
import { Popover, PopoverHeader, PopoverBody } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from './styles';

function HowToActPopover({ isOpen, toggle, attendantScript, ...props }) {
  const [t] = useTranslation('event_attendance');

  return (
    <Popover
      isOpen={isOpen}
      toggle={toggle}
      target="how-to-act-popover"
      {...props}
    >
      <PopoverHeader>
        {t('details.header.how-to-act-popover-title')}
        <CloseIcon onClick={toggle} />
      </PopoverHeader>
      <PopoverBody>
        <p>
          {attendantScript ||
            t('details.header.actions.no-attendant-script-message')}
        </p>
      </PopoverBody>
    </Popover>
  );
}

export default HowToActPopover;

HowToActPopover.propTypes = {
  isOpen: propTypes.bool.isRequired,
  toggle: propTypes.func.isRequired,
  attendantScript: propTypes.string,
};

HowToActPopover.defaultProps = {
  attendantScript: null,
};
