import styled from 'styled-components';
import { KiperButton, Label as label, Flex } from '@kiper/ui';
import { FormGroup as formgroup, Input } from 'reactstrap';

export const Button = styled(KiperButton)`
  & + & {
    margin-left: 10px;
  }
`;

export const Label = styled(label)`
  margin-bottom: 0;
`;

export const RadioLabel = styled(label)`
  margin-bottom: 0;
`;

export const RadioButtonContainer = styled(formgroup)`
  margin-right: 20px;
`;

export const RadioGroup = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
`;

export const KiperRadioCustomButton = styled(Input)``;
