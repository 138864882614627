import propTypes from 'prop-types';
import { Flex } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

const CondoInfoManager = ({ icon, management, syndic }) => {
  const [t] = useTranslation('condominium');

  return (
    <S.CondoInfoContainer>
      <span>{icon}</span>
      <S.WrapperInfo>
        <Flex gridGap="4px">
          <strong>
            {t('information.condominium-workers.management-company')}
          </strong>
          <S.InfoDescription>{management}</S.InfoDescription>
        </Flex>
      </S.WrapperInfo>
    </S.CondoInfoContainer>
  );
};

export default CondoInfoManager;

CondoInfoManager.propTypes = {
  icon: propTypes.element.isRequired,
  management: propTypes.object.isRequired,
  syndic: propTypes.object.isRequired,
};
