import propTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { event as eventGql } from '@kiper/monitoring-graphql';
import { FiXCircle } from 'react-icons/fi';

import { apolloErrorHandler, formatDateTime } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { PlaceHolder } from '@kiper/ui';
import { useMemo } from 'react';
import { Container, ActivityContainer, Modal } from './styles';
import EventActivityItem from './EventActivityItem';
import { EmptyStateIcon } from '../../EmptyStateIcon';

const loaderStyle = {
  height: '39px',
  width: '100%',
  marginBottom: '12px',
  borderRadius: '4px',
};

const EventActivityModal = ({ onToggle, eventId, eventDate }) => {
  const [t] = useTranslation('modals/event-activity-modal');
  const { toast } = useSwal();

  const { data, loading } = useQuery(eventGql.eventActivity, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId,
      eventDate: eventDate
        ? formatDateTime(eventDate, 'YYYY-MM-DD HH:mm:ss')
        : null,
    },
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const { eventActivity } = data || { eventActivity: {} };

  const sortedActivities = useMemo(() => {
    return eventActivity?.activities?.sort((a, b) => {
      return new Date(a.creationDate) - new Date(b.creationDate);
    });
  }, [eventActivity]);

  return (
    <Modal title={t('title')} open toggle={onToggle} size="lg">
      <Container>
        {!loading && sortedActivities.length && (
          <ActivityContainer>
            {sortedActivities.map((activity, i) => (
              <EventActivityItem
                key={i}
                event={eventActivity}
                activity={activity}
              />
            ))}
          </ActivityContainer>
        )}
        {loading && <PlaceHolder style={loaderStyle} type="line" lines={6} />}
        {!loading && sortedActivities.length < 1 && (
          <EmptyStateIcon
            m="36px 8px 36px 8px"
            icon={FiXCircle}
            title={t('no-activity')}
            text={t('no-activity-detailed')}
          />
        )}
      </Container>
    </Modal>
  );
};

export default EventActivityModal;

EventActivityModal.propTypes = {
  onToggle: propTypes.func.isRequired,
  eventId: propTypes.string.isRequired,
  eventDate: propTypes.string,
};

EventActivityModal.defaultProps = { eventDate: null };
