import propTypes from 'prop-types';
import { Label, Select } from '@kiper/ui';
import { useTranslation } from 'react-i18next';

export default function ApplicationProfileSelect({
  value,
  onChange,
  disabled,
  options,
  ...props
}) {
  const [t] = useTranslation('system-user');

  const selectedOption =
    value && options.length
      ? options.find(option => option.value === value) || null
      : null;

  return disabled ? (
    <Label>{selectedOption?.label}</Label>
  ) : (
    <Select
      {...props}
      placeholder={t('put.placeholders.select-profile')}
      onChange={option => onChange(option.value)}
      value={selectedOption}
      options={options}
    />
  );
}

ApplicationProfileSelect.propTypes = {
  disabled: propTypes.bool,
  value: propTypes.any,
  onChange: propTypes.func.isRequired,
  options: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string.isRequired,
      value: propTypes.any.isRequired,
    }),
  ).isRequired,
};

ApplicationProfileSelect.defaultProps = {
  value: null,
  disabled: false,
};
