import propTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Dropdown } from 'reactstrap';
import { KiperButton } from '@kiper/ui';
import { MdExpandMore } from 'react-icons/md';

import { Header, ReportTitle, DropdownToggle, DropdownMenu } from './styles';

const exportType = {
  pdf: 'pdf',
  xls: 'xls',
  csv: 'csv',
};

const ReportHeader = ({
  title,
  dropdownOpened,
  dropdownDisabled,
  exportPdf,
  exportXls,
  exportCsv,
  toggleDropdown,
}) => {
  const [t] = useTranslation('common');

  const handleExport = type => {
    switch (type) {
      case exportType.pdf:
        exportPdf();
        break;
      case exportType.xls:
        exportXls();
        break;
      case exportType.csv:
        exportCsv();
        break;
      default:
        break;
    }
    toggleDropdown();
  };

  return (
    <Header>
      <ReportTitle>{title}</ReportTitle>
      <Dropdown
        isOpen={dropdownOpened}
        toggle={toggleDropdown}
        disabled={dropdownDisabled}
      >
        <DropdownToggle tag="a">
          <KiperButton disabled={dropdownDisabled} rightIcon={<MdExpandMore />}>
            {t('report.export')}
          </KiperButton>
        </DropdownToggle>
        <DropdownMenu>
          <KiperButton
            block
            data-cy="export-pdf-report"
            variant="text"
            color="neutral"
            onClick={() => handleExport(exportType.pdf)}
            disabled={dropdownDisabled}
          >
            {t('report.pdf')}
          </KiperButton>

          <KiperButton
            block
            variant="text"
            color="neutral"
            onClick={() => handleExport(exportType.xls)}
            disabled={dropdownDisabled}
          >
            {t('report.xls')}
          </KiperButton>

          <KiperButton
            block
            variant="text"
            color="neutral"
            onClick={() => handleExport(exportType.csv)}
            disabled={dropdownDisabled}
          >
            {t('report.csv')}
          </KiperButton>
        </DropdownMenu>
      </Dropdown>
    </Header>
  );
};

ReportHeader.propTypes = {
  title: propTypes.string.isRequired,
  dropdownOpened: propTypes.bool,
  dropdownDisabled: propTypes.bool,
  exportPdf: propTypes.func.isRequired,
  exportXls: propTypes.func.isRequired,
  exportCsv: propTypes.func.isRequired,
  toggleDropdown: propTypes.func.isRequired,
};

ReportHeader.defaultProps = {
  dropdownOpened: false,
  dropdownDisabled: true,
};

export default ReportHeader;
