import styled, { css } from 'styled-components';
import { Card as card, Label as label, KiperButton as button } from '@kiper/ui';

export const Card = styled(card)`
  width: 660px;
  margin: 0;
`;

export const CardHeader = styled(card.Header)`
  padding: 20px 10px;
  background: transparent;
`;

export const CardTitle = styled(card.Title)``;

export const CardBody = styled(card.Body)``;

export const Label = styled(label)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
`;

export const Flex = styled.div`
  display: flex;
  ${props =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
`;

export const Button = styled(button)`
  margin-left: 10px;
`;

export const CancelButton = styled(Button)`
  color: ${props => props.theme.colors.secondary800};
`;

export const Alert = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${props => props.theme.colors.warningAlert};
  color: black;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 10px;

  svg {
    width: 25px;
    height: 25px;
  }
  label {
    margin: 0;
  }
`;
