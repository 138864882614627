import styled from 'styled-components';
import { Flex, Text } from '@kiper/ui';

export const Wrapper = styled(Flex).attrs({
  padding: '16px 24px',
  alignItems: 'flex-start',
  flexDirection: 'column',
  gridGap: '8px',
})`
  font-family: 'Nunito Sans', sans-serif;
  height: max-content;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.secondary300};
  padding: 8px;
`;

export const InfoTitle = styled.h1`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  width: 100%;
  color: ${({ theme }) => theme.colors.highBlack};
  margin: 0;
  display: flex;
  align-items: center;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary300};
`;

export const InfoDataWrapper = styled.ul`
  all: unset;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
`;

export const InfoDataItem = styled.li`
  all: unset;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const LabelText = styled(Text)`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  width: max-content;
  color: ${({ theme }) => theme.colors.blackShades(0.8)};
  opacity: 0.9;
`;

export const ValueText = styled(Text)`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.blackShades(0.9)};
`;
