import styled, { css, keyframes } from 'styled-components';
import {
  Toggler as toggler,
  Button as button,
  Badge as badge,
} from '@kiper/ui';
import {
  MdReplay10,
  MdForward10,
  MdPlayCircleFilled,
  MdPauseCircleFilled,
  MdFastRewind,
  MdFastForward,
} from 'react-icons/md';

import { FiMaximize2 } from 'react-icons/fi';

const rotate = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

const Flex = styled.div`
  display: flex;
`;

const FlexCol = styled(Flex)`
  flex-direction: column;
`;

export const Container = styled(Flex)`
  flex: 1;
  flex-direction: column;
`;

export const SelectedCameraContainer = styled(FlexCol)`
  position: relative;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  min-height: 280px;
  background-color: ${props => props.theme.colors.mediumHighBlack};
  ${props =>
    props.liveLoading ||
    (props.thumbnailLoading &&
      css`
        svg {
          animation: ${rotate} 2s linear infinite;
        }
        img {
          opacity: 0.33;
        }
      `)};

  ${props =>
    props.isOffline &&
    css`
      img {
        opacity: 0.33;
      }
    `}
`;

export const SpinnerContainer = styled.div`
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const CameraItem = styled(FlexCol)`
  position: relative;
  justify-content: center;
  height: 78px;
  width: 120px;
  background-color: ${props => props.theme.colors.mediumHighBlack};
  border: 1px solid ${props => props.theme.colors.secondary400};
  cursor: pointer;
  margin-right: 3px;
  border-radius: 8px;
`;

export const Img = styled.img`
  width: 100%;
  border-radius: 8px;
  max-width: 507px;
`;

export const VideoImg = styled(Img)`
  max-height: 100%;
  max-width: 507px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
`;

export const ImageNotFound = styled.div`
  color: ${props => props.theme.colors.secondary50};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 78px;
`;

export const VideoNotFound = styled(ImageNotFound)`
  height: 280px;
  width: 100%;
`;

export const CameraListContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  flex-direction: row;
  align-items: center;
  overflow: auto;
  white-space: nowrap;
  overflow-y: hidden;
  width: 100%;
`;

export const CameraListThumbContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PlayerContainer = styled.div`
  height: 40px;
  background-color: ${props => props.theme.colors.blackPlayer};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 4px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const Toggler = styled(toggler)``;

export const Button = styled(button)`
  min-width: 120px;
  height: 26px;
  display: flex;
  align-items: center;
`;

export const PlayerCommandsContainer = styled.div``;

export const ReplayIcon = styled(MdReplay10)`
  cursor: pointer;
`;

export const ForwardIcon = styled(MdForward10)`
  cursor: pointer;
`;

export const PlayIcon = styled(MdPlayCircleFilled)`
  cursor: pointer;
  margin: 0 10px;
`;

export const PauseIcon = styled(MdPauseCircleFilled)`
  cursor: pointer;
  margin: 0 10px;
`;

export const FastRewindIcon = styled(MdFastRewind)`
  margin-right: 5px;
  cursor: pointer;
`;

export const FastForwardIcon = styled(MdFastForward)`
  margin-left: 5px;
  cursor: pointer;
`;

export const Badge = styled(badge)`
  text-transform: uppercase;
  color: white;
`;

export const ExpandIcon = styled(FiMaximize2)`
  position: absolute;
`;

export const ThumbContainer = styled.div`
  position: relative;
`;

export const ImageOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  border-radius: 8px;
  transition: 0.5s ease;
  background-color: ${props => props.theme.colors.lowBlack};
  :hover {
    opacity: 1;
  }
`;
