import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Label, Loader, Button } from '@kiper/ui';
import { DeviceCounterContainer } from './styles';
import logoRf from '../../assets/images/device-control.svg';
import useAccessRf from '../../hooks/useAccessRf';

const VehicularControlPanel = ({
  deviceId,
  device,
  formatDate,
  translate,
  canSync,
  onSync,
  transparent,
  disabled,
  statusBadge: StatusBadge,
}) => {
  const { isSynchronizing, sync, device: savedDevice, loading } = useAccessRf(
    deviceId,
  );
  const [t] = useTranslation('vehicular-control');

  if (loading) return <Loader fullHeight />;

  const {
    value,
    counter,
    id,
    description,
    lastAcessPlace,
    lastAccessDateTime,
  } = savedDevice || device || {};

  return (
    <>
      <img src={logoRf} alt={t('vehicular-control')} />
      <Label center>
        {t('vehicular-control')}
        {description && ` - ${description}`}
      </Label>
      <Label center info>{`ID ${value}`}</Label>
      {(lastAcessPlace || lastAccessDateTime) && (
        <Label center info size="12px">
          {lastAcessPlace &&
            `${translate('put.devices.lastAccessPlace')} "${lastAcessPlace}"`}
          <br />
          {lastAccessDateTime &&
            `${translate('put.devices.lastAccessDateTime')} ${
              formatDate(lastAccessDateTime, 'L - HH:mm').formatted
            }`}
        </Label>
      )}
      {StatusBadge && <StatusBadge />}
      <DeviceCounterContainer transparent={transparent}>
        <Label center info>
          {`${t('current-count')}: ${counter}`}
        </Label>
        {/* {id && (
          <Label center info>
            {lastCounter || lastCounter === 0
              ? `${t('last-count')}: ${lastCounter}`
              : t('waiting-first-use')}
          </Label>
        )} */}
        {canSync && id && (
          <Button
            color="primary"
            outline
            loading={isSynchronizing}
            disabled={disabled || isSynchronizing}
            onClick={() => sync(id).then(onSync)}
          >
            {t(isSynchronizing ? 'sync.synchronizing' : 'sync.do-sync')}
          </Button>
        )}
      </DeviceCounterContainer>
    </>
  );
};

VehicularControlPanel.propTypes = {
  deviceId: propTypes.number,
  device: propTypes.object,
  canSync: propTypes.bool,
  onSync: propTypes.func,
  transparent: propTypes.bool,
  disabled: propTypes.bool,
  statusBadge: propTypes.func,
  formatDate: propTypes.func,
  translate: propTypes.func,
};

VehicularControlPanel.defaultProps = {
  deviceId: null,
  device: null,
  canSync: true,
  onSync: null,
  transparent: false,
  disabled: false,
  statusBadge: null,
  formatDate: null,
  translate: null,
};

export default VehicularControlPanel;
