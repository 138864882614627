import { Label } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { TextArea } from './styles';

const Observation = ({ values, handleChange, isEditable }) => {
  const [t] = useTranslation('user');
  return (
    <>
      <Label style={{ fontSize: '14px' }}>
        {t('put.access-data.observation.label')}
      </Label>
      <TextArea
        disabled={!isEditable}
        type="textarea"
        name="observation"
        id="observation"
        value={values.observation}
        onChange={handleChange}
        rows={5}
        delay={300}
      />
    </>
  );
};

Observation.propTypes = {
  values: propTypes.any.isRequired,
  handleChange: propTypes.func.isRequired,
  isEditable: propTypes.bool.isRequired,
};

export default Observation;
