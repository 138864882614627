import {
  useContext,
  useEffect,
  useState,
  useMemo,
  useLayoutEffect,
  useRef,
} from 'react';
import propTypes from 'prop-types';
import { useFormikContext } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { MdOutlineClose, MdSearch } from 'react-icons/md';
import { Button, Flex, Spinner } from '@kiper/ui';
import { useDebounce, useSwal } from '@kiper/hooks';
import { useLazyQuery, useMutation } from 'react-apollo';
import {
  getPreRegisteredAuthorizationsByType,
  insertStepHistory,
} from '@kiper/monitoring-graphql/guided_attendance';
import { eventTriage } from '@kiper/monitoring-graphql/attendance';
import { apolloErrorHandler, removeProperty } from '@kiper/fns';
import { useGuidedAttendance } from '../../../hooks';
import { GuidedAttendanceContext, TriageContext } from '../../../store';
import FindRegisterItem from './FindRegisterItem';
import StepFindRegisterEmptyState from './StepFindRegisterEmptyState';
import {
  authorizationStatus,
  transientPersonTypes,
  triageToTransientPersonType,
} from '../../../constants';
import { sendAmplitudeData } from '../../../services/amplitude/index';
import StepStructure, { StepTextTypeColor } from '../StepStructure';

import { Label } from '../styles';
import * as S from './styles';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const StepFindRegister = ({ event }) => {
  const selectRef = useRef();
  const { handleNextAction } = useGuidedAttendance();
  const { t } = useTranslation('guided-attendance');
  const { toast } = useSwal();

  const { setContextTree, contextTree } = useContext(GuidedAttendanceContext);
  const { setTriageContext } = useContext(TriageContext);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  const [maxHeightDropDown, setMaxHeightDropDown] = useState(null);

  const [options, setOptions] = useState([]);
  const [firstOptionsData, setFirstOptionsData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [optionSelected, setOptionSelected] = useState(null);

  const debounce = useDebounce({ delay: 800 });

  const {
    values,
    setFieldValue,
    setValues,
    handleSubmit,
    isSubmitting,
  } = useFormikContext();

  const schema = yup.object({
    stepFindRegister: yup.object({
      status: yup
        .number()
        .test(
          'is-not-null',
          t('step-find-register.feedback-error'),
          value => value !== null,
        )
        .required(t('step-find-register.feedback-error'))
        .nullable(),
    }),
  });

  const transientPersonType =
    transientPersonTypes[
      triageToTransientPersonType[values?.stepAttendanceGetReason?.id]
    ] || transientPersonTypes['service-provider'];

  const [getAuthorizations, { loading }] = useLazyQuery(
    getPreRegisteredAuthorizationsByType,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ preRegisteredAuthorizationsByType }) => {
        if (!firstOptionsData.length) {
          setFirstOptionsData(preRegisteredAuthorizationsByType);
        }
        setOptions(preRegisteredAuthorizationsByType);
      },
    },
  );

  const [updateTriage] = useMutation(eventTriage, {
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const [stepHistory] = useMutation(insertStepHistory, {
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useLayoutEffect(() => {
    const height = selectRef?.current?.getBoundingClientRect().height;
    const bottom = selectRef?.current?.getBoundingClientRect().bottom;
    const overflowDiff = bottom - windowDimensions.height;
    const newMaxHeight = height - overflowDiff;

    if (newMaxHeight) setMaxHeightDropDown(`${newMaxHeight}px`);
  }, [selectRef?.current, windowDimensions]);

  const handleSearch = inputValue => {
    setSearchText(inputValue);
    const unityId = values?.stepAttendanceGetUnity?.unity?.id;
    if (inputValue?.length > 0)
      debounce(() => {
        getAuthorizations({
          variables: { unityId, transientPersonType, searchText: inputValue },
        });
      });
  };

  const handleSelectOption = (e, option) => {
    e.preventDefault();
    e.stopPropagation();

    sendAmplitudeData('selected pre registered person', {
      ...option,
    });

    setValues({
      ...values,
      stepFindRegister: removeProperty(option, '__typename'),
      stepAttendanceGetName: {
        name: option?.name,
        oldName: values?.stepAttendanceGetName?.name,
      },
    });

    setSearchText(option?.name);
    setOptionSelected(option);
  };

  const handleCleanSearch = e => {
    e.preventDefault();
    const oldName = values?.stepAttendanceGetName?.oldName;

    if (oldName) {
      setValues({
        ...values,
        stepFindRegister: { status: null },
        stepAttendanceGetName: {
          name: oldName,
          oldName: null,
        },
      });
    }

    setSearchText('');
    setOptionSelected(null);
  };

  const handleChangeStatusToUnregistered = e => {
    e.preventDefault();
    setFieldValue('stepFindRegister', {
      status: authorizationStatus.UNREGISTERED,
    });
  };

  const handleUpdateTriage = value => {
    if (!value?.stepAttendanceGetUnity) return null;

    const variables = {
      eventId: event?.eventId,
      triageId: value?.stepAttendanceGetReason?.id,
    };

    const { stepAttendanceGetUnity } = value || {};
    const serializedTriageJson = stepAttendanceGetUnity?.unity?.treeNodeId
      ? { guUnTreeNodeId: stepAttendanceGetUnity.unity.treeNodeId }
      : {};

    if (value?.stepAttendanceGetName?.name)
      serializedTriageJson.identifier = value.stepAttendanceGetName.name;

    if (Object.keys(serializedTriageJson).length > 0)
      variables.serializedTriage = JSON.stringify(serializedTriageJson);

    return updateTriage({
      variables,
    });
  };

  const handleStepHistory = value => {
    const eventId = event?.eventId;
    const stepId = contextTree?.currentStep?.id;

    const data = {
      name: value?.name,
      profileName: value?.profileName,
      status: value?.status,
    };

    return stepHistory({
      variables: {
        eventId,
        stepId,
        data: JSON.stringify(data),
      },
    });
  };

  const nextAction = async value => {
    setTriageContext({
      identifier: value.stepAttendanceGetName.name,
    });

    Promise.all([
      handleUpdateTriage(value),
      handleStepHistory(value?.stepFindRegister),
    ]);

    return handleNextAction({
      values: value,
      fieldValue: 'stepFindRegister',
    });
  };

  const { showEmptyState, showOptions } = useMemo(
    () => ({
      showEmptyState:
        !loading && !!searchText && !optionSelected && !options.length,
      showOptions: !loading && !optionSelected && !!options.length,
    }),
    [loading, options, optionSelected],
  );

  useEffect(() => {
    if (!searchText?.length) {
      setOptions(firstOptionsData);
    }
  }, [searchText]);

  useEffect(() => {
    const status = values?.stepFindRegister?.status;

    if (status >= 0) handleSubmit();
  }, [values?.stepFindRegister?.status]);

  useEffect(() => {
    if (
      !values?.stepFindRegister ||
      values?.stepFindRegister?.status === authorizationStatus.UNREGISTERED
    ) {
      setFieldValue('stepFindRegister', {
        status: null,
      });
    }

    setContextTree({
      formikSchema: schema,
      nextAction: async stepData => {
        const nextStep = await nextAction(stepData);
        return { nextStep, currentStepData: stepData };
      },
    });
  }, []);

  useEffect(() => {
    const unityId = values?.stepAttendanceGetUnity?.unity?.id;
    if (unityId && transientPersonType) {
      getAuthorizations({
        variables: { unityId, transientPersonType },
      });
    }
  }, [values?.stepAttendanceGetUnity?.unity?.id, transientPersonType]);

  const showDropDownOptions = loading || showOptions || showEmptyState;

  return (
    <StepStructure
      type={StepTextTypeColor.VERIFY}
      text={t('step-find-register.title', {
        name: values?.stepAttendanceGetName?.name,
      })}
      hasPreviousStepButton
    >
      <Flex flexDirection="column" width="100%">
        <Flex justifyContent="space-between">
          <Label>{t('step-find-register.search-by-customer')}</Label>
          <Flex gridGap="4px">
            <S.LabelText>{t('step-find-register.search-by')}</S.LabelText>
            <S.LabelText
              color="link"
              onClick={() => handleSearch(values?.stepAttendanceGetName?.name)}
            >
              {values?.stepAttendanceGetName?.name}
            </S.LabelText>
          </Flex>
        </Flex>
        <S.Wrapper>
          <S.InputGroup>
            <S.InputIcon onClick={e => e.stopPropagation()}>
              <MdSearch />
            </S.InputIcon>
            <S.WrapperSearchInput>
              <S.Input
                type="text"
                value={searchText}
                name="searchText"
                id="searchText"
                placeholder={t('step-find-register.search-by-customer')}
                onChange={e => handleSearch(e.target.value)}
                autoComplete="off"
                autoFocus
                disabled={!!optionSelected}
              />
            </S.WrapperSearchInput>
            <Flex gridGap="8px">
              {(!!optionSelected || !!searchText) && (
                <S.IconButton onClick={handleCleanSearch}>
                  <MdOutlineClose />
                </S.IconButton>
              )}
              <S.InputIcon>
                <S.ExpandMoreIcon />
              </S.InputIcon>
            </Flex>
          </S.InputGroup>
          {showDropDownOptions && (
            <S.WrapperSelectOptions
              ref={selectRef}
              maxHeight={maxHeightDropDown}
            >
              {loading && (
                <Flex justifyContent="center" width="100%" py="20px">
                  <Spinner size={24} />
                </Flex>
              )}
              {showOptions && (
                <Flex flexDirection="column" gridGap="8px">
                  <S.ListContainer>
                    <S.ListGroup maxHeight={maxHeightDropDown}>
                      {options.map(option => (
                        <S.ListGroupItem
                          key={option.name + crypto.randomUUID()}
                        >
                          <FindRegisterItem
                            item={option}
                            handleSelectOption={handleSelectOption}
                          />
                        </S.ListGroupItem>
                      ))}
                    </S.ListGroup>
                  </S.ListContainer>
                </Flex>
              )}
              {!loading && !showEmptyState && (
                <Button
                  size="sm"
                  color="primary"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  onClick={handleChangeStatusToUnregistered}
                  centered
                  block
                >
                  {t('step-find-register.not-found-next-step')}
                  {isSubmitting && <Spinner />}
                </Button>
              )}
              {showEmptyState && (
                <S.ListContainer>
                  <StepFindRegisterEmptyState
                    title={t('step-find-register.empty-state.title')}
                    text={t('step-find-register.empty-state.text')}
                    action={t('step-find-register.empty-state.action')}
                    handleAction={handleChangeStatusToUnregistered}
                    isSubmitting={isSubmitting}
                  />
                </S.ListContainer>
              )}
            </S.WrapperSelectOptions>
          )}
        </S.Wrapper>
      </Flex>
    </StepStructure>
  );
};

export default StepFindRegister;

StepFindRegister.propTypes = {
  event: propTypes.shape({
    eventId: propTypes.string,
  }),
};

StepFindRegister.defaultProps = {
  event: null,
};
