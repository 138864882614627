import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from 'react-apollo';
import { getDwellersForReport } from '@kiper/monitoring-graphql/dweller/list/query';
import { useTranslation } from 'react-i18next';
import {
  StyledSelect,
  OptionWrapper,
  Option,
  OptionDescription,
} from './styles';

const formatOptionLabel = option => (
  <OptionWrapper>
    <Option>{option.label}</Option>
    {option?.description && (
      <OptionDescription>{option.description}</OptionDescription>
    )}
  </OptionWrapper>
);

const ReportUnityPerson = ({ handleChange, value, sourceNodeId, ...props }) => {
  const [t] = useTranslation('report');
  const [dataCollection, setDataCollection] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isFocused, setFocus] = useState(false);

  const [search, { loading }] = useLazyQuery(getDwellersForReport, {
    fetchPolicy: 'network-only',
    onCompleted: ({ dwellersForReport }) =>
      setDataCollection(dwellersForReport),
  });

  const { groupedData, options } = useMemo(() => {
    if (!dataCollection?.length) return { groupedData: {}, options: [] };

    const dwellers = groupedData?.dwellers || [];
    const unityGroups = groupedData?.unityGroups || [];
    const unitys = groupedData?.unitys || [];

    for (const data of dataCollection) {
      const unityGroup = data?.unityGroup || {};
      const unity = data?.unity || {};

      let unityGroupDesc = '';
      let unityDesc = '';
      let dwellerDesc = '';

      if (unityGroup?.name) {
        if (unityGroup?.placeTypeLabel) {
          unityGroupDesc = `${unityGroup.placeTypeLabel}: ${unityGroup.name}`;
        } else {
          unityGroupDesc = unityGroup.name;
        }
      }

      if (unity?.name) {
        if (unity?.placeTypeLabel) {
          unityDesc = `${unity.placeTypeLabel}: ${unity.name}`;
        } else {
          unityDesc = unity.name;
        }
      }

      if (data?.profileName) {
        dwellerDesc = `${data.profileName}`;
        dwellerDesc += unityDesc ? `, ${unityDesc}` : '';
        dwellerDesc += unityGroupDesc ? `, ${unityGroupDesc}` : '';
      }

      const hasDweller = dwellers.some(
        dweller => dweller.value === data?.treeNodeId,
      );

      if (!hasDweller) {
        dwellers.push({
          value: data.treeNodeId,
          label: `${data.name}`,
          description: dwellerDesc,
        });
      }

      if (unityGroup?.treeNodeId) {
        const hasUnityGroup = unityGroups.some(
          group => group.value === unityGroup.treeNodeId,
        );
        if (!hasUnityGroup) {
          unityGroups.push({
            value: unityGroup.treeNodeId,
            label: unityGroupDesc,
          });
        }
      }

      if (unity?.treeNodeId) {
        const hasUnity = unitys.some(unit => unit.value === unity.treeNodeId);
        if (!hasUnity) {
          unitys.push({
            value: unity.treeNodeId,
            label: unityDesc,
            description: unityGroupDesc,
          });
        }
      }
    }

    return {
      groupedData: {
        dwellers: { label: t('details.dweller'), options: dwellers },
        unityGroups: {
          label: t('details.unity-group'),
          options: unityGroups,
        },
        unitys: { label: t('details.unity'), options: unitys },
      },
      options: [...dwellers, ...unityGroups, ...unitys],
    };
  }, [dataCollection]);

  const handleSearch = searchText => {
    if (!searchText) setDataCollection([]);

    setSearchValue(searchText);

    if (searchText && sourceNodeId) {
      search({
        variables: {
          searchText: searchText.trim() ?? '',
          sourceNodeId,
        },
      });
    }
  };

  const isOpen = useMemo(() => isFocused && searchValue?.trim(), [
    isFocused,
    searchValue,
  ]);

  const handleFocus = () => {
    setFocus(true);
  };

  const handleBlur = () => {
    setFocus(false);
    setDataCollection([]);
  };

  return (
    <StyledSelect
      {...props}
      options={Object.values(groupedData)}
      value={value && options.find(i => i.value === value.value)}
      onChange={handleChange}
      inputValue={searchValue}
      onInputChange={handleSearch}
      delay={600}
      onFocus={handleFocus}
      menuIsOpen={isOpen}
      onBlur={handleBlur}
      isClearable
      isLoading={loading}
      isDisabled={!sourceNodeId}
      formatOptionLabel={formatOptionLabel}
    />
  );
};

ReportUnityPerson.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  sourceNodeId: PropTypes.number,
};

ReportUnityPerson.defaultProps = {
  sourceNodeId: null,
  value: null,
};

export default ReportUnityPerson;
