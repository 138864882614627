import styled, { css } from 'styled-components';
import { Table as table, Row as row, Col as col } from 'reactstrap';
import { Card as card } from '@kiper/ui';

const hideWhenPrint = `
  @media print {
    display: none;
    height: 0;
  }
`;

export const Table = styled.table`
  margin-bottom: 0 !important;
  border-radius: 8px !important;
  table {
    page-break-inside: auto;
    tr {
      page-break-inside: avoid;
      page-break-after: auto;
    }
    thead {
      display: table-header-group;
    }
    tfoot {
      display: table-footer-group;
    }
  }
`;

export const Thead = styled.thead``;

export const Th = styled.th`
  background-color: ${props => props.theme.colors.secondary100};
  height: 38px;
  padding-left: 10px;
  @media screen {
    width: ${props => props.width};
  }

  @media print {
    width: ${props => props.printWidth || props.width};
  }
`;

export const Tr = styled.tr`
  border-bottom: 1px solid ${props => props.theme.colors.secondary100};
`;

export const Td = styled.td`
  max-width: 0;
`;

const fontSize = css`
  @media print {
    font-size: 10px;
  }
  @media screen {
    font-size: 14px;
  }
`;

export const ColumnTitle = styled.span`
  ${fontSize};
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DataContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const ColumnData = styled.span`
  ${fontSize};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const TableCard = styled(card)`
  padding: 0;

  ${props =>
    props.print &&
    css`
      @media screen {
        height: 40vh;
        overflow: hidden;
      }
    `};
  @media print {
    margin: 0;
    border: 0;
  }
`;

export const PaginationContainer = styled.div`
  ${hideWhenPrint};
`;
