import { useQuery } from 'react-apollo';

import { queries } from '@kiper/monitoring-graphql/condominium/detail';
import { useCallback, useMemo } from 'react';
import { accessAfterHoursOption } from '../../../../constants';

const useQueries = ({ condominiumPersonContextId, translate }) => {
  const { data, loading } = useQuery(queries.getCondominiumAccessHours, {
    skip: !condominiumPersonContextId,
    fetchPolicy: 'network-only',
    variables: {
      condominiumPersonContextId,
    },
  });

  const accessAfterHours = useMemo(
    () => data?.condominiumAccessHours?.accessAfterHours,
    [data],
  );

  const handleGetInfo = useCallback(() => {
    switch (accessAfterHours) {
      case accessAfterHoursOption.REQUEST_AUTHORIZATION_SYNDIC: {
        return translate('request-authorization-syndic');
      }
      case accessAfterHoursOption.NOTIFY_SYNDIC: {
        return translate('notify-syndic');
      }
      case accessAfterHoursOption.NOTIFY_DWELLER: {
        return translate('notify-dweller');
      }
      case accessAfterHoursOption.DISMISS_SERVICE_PROVIDER: {
        return translate('notify-service-provider');
      }
      default:
        return '';
    }
  }, [accessAfterHours]);

  return { handleGetInfo, loading };
};

export default useQueries;
