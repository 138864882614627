import { useState, useEffect } from 'react';

const useNetworkStatus = () => {
  const [online, setOnline] = useState(
    typeof window !== 'undefined' ? navigator.onLine : true,
  );

  useEffect(() => {
    const onLineHandler = () => {
      setOnline(true);
    };

    const offLineHandler = () => {
      setOnline(false);
    };

    window.addEventListener('online', onLineHandler);

    window.addEventListener('offline', offLineHandler);

    return () => {
      window.removeEventListener('online', onLineHandler);

      window.removeEventListener('offline', offLineHandler);
    };
  }, []);

  return { networkStatus: online };
};

export default useNetworkStatus;
