import { useMemo, useState, useEffect } from 'react';
import propTypes from 'prop-types';
import * as UI from '@kiper/ui';
import {
  MdOutlinePlace,
  MdOutlineDoorBack,
  MdKeyboardVoice,
} from 'react-icons/md';
import { RiDoorOpenLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { firstBy } from 'thenby';
import { PlaceStyled } from './styles';
import { sendAmplitudeData } from '../../../../../services/amplitude';
import { deviceIdTypes, doorStatusTypes } from '../../../../../constants';

const TooltipPlaceContent = ({ doorStatus, doorKeepOpenedReason, t }) => {
  if (doorStatus === doorStatusTypes.KeepOpened)
    return (
      <>
        <UI.Text fontWeight="600" fontSize="14px" lineHeight="20px">
          {t('details.cameras.commands.kept-open-badge-tooltip')}
        </UI.Text>
        <br />
        <UI.Text fontWeight="400" fontSize="14px" lineHeight="20px">
          {doorKeepOpenedReason}
        </UI.Text>
      </>
    );

  return null;
};

TooltipPlaceContent.propTypes = {
  doorStatus: propTypes.string.isRequired,
  doorKeepOpenedReason: propTypes.string.isRequired,
  t: propTypes.func.isRequired,
};

const Place = ({
  place,
  selectedPlace,
  setSelectedPlace,
  event,
  loggedContext,
  deviceStatus,
  ...props
}) => {
  const [t] = useTranslation('event_attendance');

  const [iconStatus, setIconStatus] = useState(<MdOutlineDoorBack />);
  const [doorStatus, setDoorStatus] = useState(null);
  const [doorKeepOpenedReason, setDoorKeepOpenedReason] = useState(null);

  const isFacialDevice = device =>
    device?.deviceTypeId === deviceIdTypes.facial ||
    device?.deviceTypeId === deviceIdTypes.facialIntelbras;

  const checkIfDeviceIsDoorController = device => {
    const deviceIsNotOnlyAudio =
      device.deviceTypeId !== deviceIdTypes.interphone &&
      device?.deviceTypeId !== deviceIdTypes.facial &&
      device?.deviceTypeId !== deviceIdTypes.facialIntelbras &&
      !JSON.parse(device?.serializedParams)?.onlyAudio;

    if (deviceIsNotOnlyAudio) return 3;

    const facialWithAllowOpening =
      isFacialDevice(device) &&
      device?.parentDeviceId === device?.id &&
      !JSON.parse(device?.serializedParams)?.notAllowOpening;

    if (facialWithAllowOpening) return 2;

    const interphoneWithUrlToOpen =
      device.deviceTypeId === deviceIdTypes.interphone &&
      !!JSON.parse(device?.serializedParams)?.urlToOpen;

    if (interphoneWithUrlToOpen) return 1;

    return 0;
  };

  const { device, coordinate, isOrigin } = useMemo(() => {
    const sortedDevice = place?.devices
      ?.filter(
        x =>
          x.deviceTypeId === deviceIdTypes.vehicular ||
          x.deviceTypeId === deviceIdTypes.accessVehicular ||
          x.deviceTypeId === deviceIdTypes.kvoice ||
          x.deviceTypeId === deviceIdTypes.interphone ||
          x.deviceTypeId === deviceIdTypes.access ||
          x.deviceTypeId === deviceIdTypes.indoor ||
          x.deviceTypeId === deviceIdTypes.kvoiceT ||
          x.deviceTypeId === deviceIdTypes.facial ||
          x.deviceTypeId === deviceIdTypes.facialIntelbras,
      )
      ?.sort(
        firstBy(d => checkIfDeviceIsDoorController(d), { direction: 'desc' }),
      );

    return {
      device: sortedDevice?.length ? sortedDevice[0] : null,
      coordinate: JSON.parse(place?.serializedParams)?.coordinate,
      isOrigin: event?.place?.id === place.id,
    };
  }, []);

  const deviceIsOnlyAudio = useMemo(() => {
    if (!device) return false;

    const { deviceTypeId, parentDeviceId, id, serializedParams } = device;
    const parsedParams = serializedParams ? JSON.parse(serializedParams) : {};

    const isInterphoneWithoutUrlToOpen =
      !parsedParams?.urlToOpen && deviceTypeId === deviceIdTypes.interphone;

    const facialControllerWithNotAllowOpening =
      isFacialDevice(device) &&
      parentDeviceId === id &&
      parsedParams?.notAllowOpening;

    return (
      parsedParams?.onlyAudio ||
      isInterphoneWithoutUrlToOpen ||
      facialControllerWithNotAllowOpening
    );
  }, [device]);

  useEffect(() => {
    const hasStatus = deviceStatus?.find(
      x =>
        x.serialNumber === device?.serialNumber &&
        x.messageData.doorId === device?.doorId,
    );

    const facialWithNotAllowOpening =
      isFacialDevice(device) &&
      device?.parentDeviceId === device?.id &&
      JSON.parse(device?.serializedParams)?.notAllowOpening;

    if (!facialWithNotAllowOpening)
      setDoorStatus(hasStatus?.messageData?.doorStatus || device?.doorStatus);

    setDoorKeepOpenedReason(
      hasStatus?.messageData?.keepOpenedReason || device?.keepOpenedReason,
    );
  }, [device, deviceStatus]);

  useEffect(() => {
    let status = doorStatus;
    if (isOrigin) status = 'isOrigin';
    else if (deviceIsOnlyAudio) status = 'isOnlyAudio';

    const iconOptions = {
      isOrigin: <MdOutlinePlace />,
      isOnlyAudio: <MdKeyboardVoice />,
      [doorStatusTypes.Closed]: <MdOutlineDoorBack />,
      [doorStatusTypes.Opened]: <RiDoorOpenLine />,
      [doorStatusTypes.KeepOpened]: <RiDoorOpenLine />,
    };

    if (status) {
      setIconStatus(iconOptions[status]);
    }
  }, [isOrigin, doorStatus, deviceIsOnlyAudio]);

  const handleClick = () => {
    setSelectedPlace(place.id);

    sendAmplitudeData('changed place by map', {
      eventId: event?.eventId,
      place: place?.name,
      condominium: event?.condominium?.name,
      user: loggedContext?.personContextId,
    });
  };

  return (
    <>
      <PlaceStyled
        id={`map-place-${place.id}`}
        onClick={handleClick}
        origin={Number(event?.place?.id === place.id)}
        coordinate={coordinate}
        selected={selectedPlace === place.id}
        doorStatus={doorStatus}
        {...props}
      >
        {coordinate && iconStatus}
      </PlaceStyled>
      {doorStatus === doorStatusTypes.KeepOpened && doorKeepOpenedReason && (
        <UI.Tooltip placement="top" target={`map-place-${place.id}`}>
          <TooltipPlaceContent
            t={t}
            doorStatus={doorStatus}
            doorKeepOpenedReason={doorKeepOpenedReason}
          />
        </UI.Tooltip>
      )}
    </>
  );
};

export default Place;

Place.propTypes = {
  place: propTypes.object.isRequired,
  selectedPlace: propTypes.number,
  setSelectedPlace: propTypes.func.isRequired,
  event: propTypes.object.isRequired,
  loggedContext: propTypes.object.isRequired,
  deviceStatus: propTypes.array.isRequired,
};

Place.defaultProps = {
  selectedPlace: null,
};
