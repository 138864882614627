import { useContext } from 'react';
import { FiBell, FiClock } from 'react-icons/fi';
import { EventAttendanceContext } from '../../../../store';

const useTabs = () => {
  const {
    eventQueue,
    eventOnHoldQueue,
    tabControl: { TAB_IDS, activeTab, setActiveTab },
  } = useContext(EventAttendanceContext);

  const tabs = [
    {
      title: null,
      tabId: TAB_IDS.ATTENDANCE_QUEUE,
      showTab: true,
      icon: <FiBell size={20} />,
      count: eventQueue?.length,
    },
    {
      title: null,
      tabId: TAB_IDS.ON_HOLD_QUEUE,
      showTab: true,
      icon: <FiClock size={20} />,
      count: eventOnHoldQueue.length,
    },
  ];

  const handleChangeTab = tab => {
    if (tab !== activeTab) setActiveTab(tab);
  };

  return {
    tabs,
    handleChangeTab,
  };
};

export default useTabs;
