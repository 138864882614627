import styled from 'styled-components';
import {
  Dropdown as dropdown,
  DropdownToggle as dropdownToggle,
} from 'reactstrap';
import { Select } from '@kiper/ui';
// eslint-disable-next-line
export const Button = styled.button`
  background: transparent;
  border: none;
  width: 100%;
  text-align: left;
  &:hover {
  }
  &:focus {
    outline: 0;
  }
`;

export const Dropdown = styled(dropdown)`
  .dropdown-toggle {
    white-space: normal !important;
  }
`;

export const DropdownToggle = styled(dropdownToggle)`
  word-wrap: normal !important;
  font-size: 14px;
`;

export const StyledSelect = styled(Select)`
  width: 165px;
  font-size: 14px;
`;
