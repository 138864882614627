import styled from 'styled-components';

import { Popover } from 'reactstrap';
import { UserAvatar, Flex as flex } from '@kiper/ui';

export const PopoverStyled = styled(Popover)``;

export const Title = styled.div`
  margin: 8px 0px 8px 8px;
  font-weight: 500;
  font-size: 18px;
`;

export const Icon = styled(UserAvatar)``;

export const IconText = styled.div`
  font-size: 14px;
`;

export const Flex = styled(flex)`
  gap: 10px;
  align-items: center;
  padding: 8px;
  display: flex;

  :hover {
    background-color: ${props => props.theme.colors.gray5};
    font-weight: 500;
  }
`;
