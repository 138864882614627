import styled, { css, keyframes } from 'styled-components';
import { Flex as flex } from '@kiper/ui';

const blink = keyframes`  {
	50% {
    opacity: 0.0;
  }
}`;

const Box = styled(flex)`
  ${({ radius }) =>
    radius &&
    css`
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    `}
`;

const Flex = styled(Box)`
  position: relative;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  width: 200px;
  max-width: 200px;
  max-height: 100px;
`;

export const Container = styled(flex)`
  flex-direction: column;
  width: 200px;
  max-width: 200px;
  max-height: 100px;

  ${({ showStatus }) =>
    showStatus &&
    css`
      &::before {
        content: '';
        width: 12px;
        height: 12px;
        position: absolute;
        border-radius: 100%;
        background-color: ${props => props.theme.colors.danger500};
        top: 0;
        right: 8px;
        margin: 5px;
        animation: ${blink} 1s step-start 0s infinite;
      }
    `}
`;

export const SpinnerContainer = styled(Flex)`
  background-color: ${props => props.theme.colors.mediumHighBlack};
  color: ${props => props.theme.colors.white};
`;

export const CameraNotFound = styled(Flex)`
  background-color: ${props => props.theme.colors.secondary200};
  color: ${props => props.theme.colors.mediumHighBlack};
  ${({ radius }) =>
    !radius &&
    css`
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    `}
`;

export const Img = styled.img`
  max-height: 100px;
  max-width: 200px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  ${({ radius }) =>
    radius &&
    css`
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    `}
`;

export const PlaceContainer = styled(Box)`
  align-items: center;
  justify-content: center;
  padding: 3px;
  background-color: ${props => props.theme.colors.blackPlayer};
  color: ${props => props.theme.colors.white};
`;
