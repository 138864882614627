import { useState, useMemo, useEffect } from 'react';
import propTypes from 'prop-types';
import { useLazyQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { query } from '@kiper/monitoring-graphql/camera';
import { Flex, Spinner } from '@kiper/ui';
import { MdVideocamOff } from 'react-icons/md';
import VideoCamerasGrid from '../../../../../components/VideoCamerasGrid';

import * as S from './styles';

const AttendancePlayback = ({ event }) => {
  const { t } = useTranslation('guided-attendance');
  const [thumbnailOffline, setThumbnailOffline] = useState(false);

  const camera = useMemo(() => {
    if (
      event?.additionalInformation &&
      !!JSON.parse(event.additionalInformation)?.camera
    ) {
      return JSON.parse(event.additionalInformation).camera;
    }
    return null;
  }, [event]);

  const [getThumbnail, { data, loading }] = useLazyQuery(
    query.cameraThumbnail,
    {
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    if (camera?.id) {
      getThumbnail({
        variables: {
          cameraId: String(camera.id),
          dateTime: event?.eventDate,
          condominiumPersonContextId: event?.condominium?.personContextId,
        },
      });
    }
  }, [camera]);

  const handleThumbnailError = () => {
    setThumbnailOffline(true);
  };

  return (
    <Flex flexDirection="column" gridGap="24px">
      <Flex flexDirection="column" gridGap="16px">
        <S.Title>{t('attendance-playback.moment-of-shooting')}</S.Title>
        {loading && (
          <S.ImageContainer>
            <Spinner size={24} color="white" />
          </S.ImageContainer>
        )}
        {!loading && (thumbnailOffline || !data?.cameraPlayerThumbnail?.url) && (
          <S.ImageContainer>
            <MdVideocamOff size={24} color="white" />
          </S.ImageContainer>
        )}
        {!loading && !thumbnailOffline && (
          <S.ThumbnailContainer>
            {data?.cameraPlayerThumbnail?.url && (
              <S.Thumbnail
                src={data?.cameraPlayerThumbnail?.url}
                onError={handleThumbnailError}
                alt={camera?.name}
              />
            )}
          </S.ThumbnailContainer>
        )}
      </Flex>
      <Flex flexDirection="column" gridGap="16px">
        <S.Title>{t('attendance-playback.shot-playback')}</S.Title>
        {camera?.id ? (
          <VideoCamerasGrid
            hasPlayerControl
            playbackAsInitialPlayerControl
            singleCameraId={camera.id}
            event={event}
          />
        ) : (
          <S.ImageContainer>
            <MdVideocamOff size={24} color="white" />
          </S.ImageContainer>
        )}
      </Flex>
    </Flex>
  );
};

export default AttendancePlayback;

AttendancePlayback.propTypes = {
  event: propTypes.object.isRequired,
};
