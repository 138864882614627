import styled from 'styled-components';
import { Form as form } from 'reactstrap';
import { Input } from '@kiper/ui';

export const Form = styled(form)`
  width: 100%;
`;

export const FormGroups = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 8px;
  width: 100%;
`;
export const FormGroup = styled.div``;

export const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
`;

export const UppercaseInput = styled(Input)`
  text-transform: uppercase;
`;
