import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { Button, Flex, Spinner, SplitSidebar } from '@kiper/ui';
import { MdArrowBack } from 'react-icons/md';
import EmptyState from './EmptyState';
import { useContinuousAttendance } from '../../../../hooks/index';
import ContinuousAttendanceItem from './ContinuousAttendanceItem';
import ContinuousAttendanceDetail from './ContinuousAttendanceDetail';
import * as S from './styles';

const ContinuousAttendance = ({
  eventId,
  attendances,
  condominiumPersonContextId,
}) => {
  const [t] = useTranslation('event_attendance');
  const { continuousAttendances, loading } = useContinuousAttendance({
    attendances,
    condominiumPersonContextId,
  });

  const [attendanceDetailData, setAttendanceDetailData] = useState(null);

  const renderHeader = () => {
    if (!attendanceDetailData) {
      return t('details.continuous-attendance.last-attendances');
    }
    return (
      <>
        <Button
          color="black"
          variant="text"
          onClick={() => setAttendanceDetailData(null)}
          size="sm"
          rounded
        >
          <MdArrowBack size={20} />
        </Button>
        {t('details.continuous-attendance.attendance-detail')}
      </>
    );
  };

  const renderBody = () => {
    if (attendanceDetailData) {
      return (
        <ContinuousAttendanceDetail t={t} attendance={attendanceDetailData} />
      );
    }
    if (!continuousAttendances || !continuousAttendances.length) {
      return <EmptyState />;
    }
    return (
      <Flex width="100%" height="100%" p="8px">
        <S.AttendancesList>
          {continuousAttendances.map((attendance, index) => (
            <ContinuousAttendanceItem
              key={index}
              t={t}
              attendance={attendance}
              eventId={eventId}
              setAttendanceDetailData={setAttendanceDetailData}
            />
          ))}
        </S.AttendancesList>
      </Flex>
    );
  };

  return (
    <S.Wrapper>
      <SplitSidebar.HeaderPane hasBorder>
        {renderHeader()}
      </SplitSidebar.HeaderPane>
      {loading && !continuousAttendances?.length ? (
        <Flex
          alignItems="center"
          width="100%"
          height="100%"
          justifyContent="center"
        >
          <Spinner size="28px" />
        </Flex>
      ) : (
        <Flex width="100%" height="100%" overflowY="auto" overflowX="hidden">
          {renderBody()}
        </Flex>
      )}
    </S.Wrapper>
  );
};

export default ContinuousAttendance;

ContinuousAttendance.propTypes = {
  eventId: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
  condominiumPersonContextId: propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
  ]).isRequired,
  attendances: propTypes.array,
};

ContinuousAttendance.defaultProps = {
  attendances: [],
};
