import propTypes from 'prop-types';
import { Button, Flex, Spinner } from '@kiper/ui';
import { Trans } from 'react-i18next';
import * as S from './styles';
import emptyStateImage from '../../../assets/images/empty-state-step-get-unity.svg';

const StepFindRegisterEmptyState = ({
  title,
  text,
  action,
  handleAction,
  isSubmitting,
}) => {
  return (
    <S.EmptyStateContainer>
      <S.Img src={emptyStateImage} alt="empty-state-map" />
      <Flex flexDirection="column" gridGap="8px">
        <Trans>
          <S.TitleEmptyState>{title}</S.TitleEmptyState>
          <S.TextEmptyState>{text}</S.TextEmptyState>
        </Trans>
      </Flex>
      <Button size="sm" onClick={handleAction} disabled={isSubmitting}>
        {action}
        {isSubmitting && <Spinner />}
      </Button>
    </S.EmptyStateContainer>
  );
};

export default StepFindRegisterEmptyState;

StepFindRegisterEmptyState.propTypes = {
  title: propTypes.string.isRequired,
  text: propTypes.string.isRequired,
  action: propTypes.string.isRequired,
  handleAction: propTypes.func.isRequired,
  isSubmitting: propTypes.bool.isRequired,
};
