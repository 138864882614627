import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { Label as label } from '@kiper/ui';

export const Label = styled(label)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
  color: ${props => props.theme.colors.mediumHighBlack};
  margin-bottom: 5px !important;
  justify-content: unset !important;
`;

export const FlexCol = styled(Col)`
  display: flex;
  flex-direction: column;
  padding: 0;
  justify-content: flex-end;
`;

export const DatePeriodWrapper = styled(Row)`
  width: 100%;
  display: flex;
  gap: 10px;
  margin: 10px 0 20px;
`;
