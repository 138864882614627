import { useState } from 'react';
import propTypes from 'prop-types';
import { Modal, Input as CustomInput } from '@kiper/ui';
import { Row, Col } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { Input, ModalHeader, Container } from './styles';
import ListTable from './ListTable';

const InvitesModal = ({ onToggle, sourceNodeId }) => {
  const [t] = useTranslation('modals/invites-modal');
  const [searchText, setSearchText] = useState('');

  return (
    <Modal title={t('title')} open toggle={onToggle} size="lg">
      <ModalHeader>
        <Row>
          <Col>
            <CustomInput
              data-cy="searchText"
              type="text"
              component={Input}
              name="searchText"
              placeholder={t('search-text')}
              value={searchText}
              delay={600}
              onChange={(_, value) => setSearchText(value)}
            />
          </Col>
        </Row>
      </ModalHeader>
      <Container>
        <ListTable t={t} searchText={searchText} sourceNodeId={sourceNodeId} />
      </Container>
    </Modal>
  );
};

export default InvitesModal;

InvitesModal.propTypes = {
  onToggle: propTypes.func.isRequired,
  sourceNodeId: propTypes.number.isRequired,
};
