import { useState, useEffect } from 'react';
import propTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import {
  Flex,
  KiperButton,
  Pagination,
  Table,
  TableLoader,
  Text,
  Badge,
} from '@kiper/ui';
import { useTheme } from 'styled-components';

import { queries } from '@kiper/monitoring-graphql/dweller/list';
import { mutations } from '@kiper/monitoring-graphql/condominium/detail';
import { useLazyQuery, useMutation } from 'react-apollo';
import { useFormik } from 'formik';
import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { TableContainer } from './styles';
import { profileColor, profileTypes } from '../../../constants';

const ChangeAccessProfileModalConfirm = ({
  columns,
  handlePageChange,
  handlePageSize,
  filters,
  accessProfile,
  accessProfileTo,
  setActiveStep,
  handleToggle,
}) => {
  const { t } = useTranslation('condominium');
  const theme = useTheme();
  const { toast } = useSwal();
  const [isFinished, setIsFinished] = useState(false);

  const [fetch, { data, loading }] = useLazyQuery(queries.partnerDwellers);
  const [changeAccessProfile, { loading: loadingPost }] = useMutation(
    mutations.changeAccessProfile,
    {
      onCompleted: () => {
        setIsFinished(true);
      },
      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length) {
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
        }
      },
    },
  );

  const getDwellers = () => {
    let newFilters = { ...filters, accessProfileIds: [] };
    if (filters.accessProfileIds?.length) {
      newFilters = {
        ...filters,
        accessProfileIds: filters.accessProfileIds.map(x => x.value),
      };
    }
    fetch({
      variables: {
        filters: newFilters,
      },
    });
  };

  useEffect(() => {
    getDwellers();
  }, []);

  useEffect(() => {
    getDwellers();
  }, [filters.pagesize, filters.page]);

  const onSubmit = () => {
    changeAccessProfile({
      variables: {
        changeAccessProfileObject: {
          accessProfileIds: accessProfile?.map(x => x.value) || [],
          accessProfileId: accessProfileTo.value,
          sourceNodeId: filters.sourceNodeId,
          searchText: filters.searchText,
        },
      },
    });
  };

  const formik = useFormik({
    initialValues: { filters, accessProfile: accessProfileTo },
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Flex
        flexDirection="column"
        px="24px"
        pt="24px"
        mt="16px"
        borderTop={`1px solid ${theme.colors.secondary200}`}
      >
        <Text color={isFinished ? 'primary500' : 'highBlack'} fontSize="20px">
          {isFinished
            ? t(
                'details.modal.access-profile.change-profile.succesfully-changed',
              )
            : t('details.modal.access-profile.change-profile.confirmation')}
        </Text>
        <Flex flexDirection="row" mt="20px">
          <Text mt="20px" fontSize="16px">
            {isFinished
              ? t(
                  'details.modal.access-profile.change-profile.succesfully-changed-subtitle',
                )
              : t(
                  'details.modal.access-profile.change-profile.wanna-change-profile',
                )}
          </Text>
          <Text fontWeight="bold" mt="20px" fontSize="16px" ml="5px">
            {accessProfileTo.label}
          </Text>
        </Flex>

        <Flex flexDirection="column" mt="20px">
          {data?.partnerDwellers?.pagination && (
            <Flex
              justifyContent="center"
              mb="12px"
              bg="secondary200"
              borderRadius="4px"
              p="10px"
            >
              {data?.partnerDwellers?.pagination?.totalResults > 1 ? (
                <Text fontSize="16px" fontWeight="bold">
                  {t(
                    'details.modal.access-profile.change-profile.amount-persons-selected',
                    {
                      amount: data?.partnerDwellers?.pagination?.totalResults,
                    },
                  )}
                </Text>
              ) : (
                <Text fontSize="16px" fontWeight="bold">
                  {t(
                    'details.modal.access-profile.change-profile.amount-person-selected',
                    {
                      amount: data?.partnerDwellers?.pagination?.totalResults,
                    },
                  )}
                </Text>
              )}
            </Flex>
          )}
          <TableContainer>
            <Table columns={columns} hover={false}>
              {!loading && !!data?.partnerDwellers?.collection?.length && (
                <tbody>
                  {data?.partnerDwellers?.collection?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <Flex>
                          <Text fontSize="14px" mr="8px">
                            {item.name}
                          </Text>
                          <Badge color={profileColor[item.profileFieldName]}>
                            {t(
                              `common:profiles.${
                                profileTypes[item.profileFieldName]
                              }`,
                            )}
                          </Badge>
                        </Flex>
                      </td>
                      <td>
                        <Flex flexDirection="column">
                          <Text fontSize="14px">{item.unityName}</Text>
                          <Text fontSize="12px" color="secondary800">
                            {item.unityGroupName}
                          </Text>
                        </Flex>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
            {loading && <TableLoader />}
            <Pagination
              translate={t}
              pagination={data?.partnerDwellers?.pagination}
              handlePageSize={handlePageSize}
              next={() => handlePageChange(1)}
              previous={() => handlePageChange(-1)}
            />
          </TableContainer>
        </Flex>
        <Flex justifyContent="space-between" width="100%" flex={1} py="20px">
          {isFinished ? (
            <KiperButton
              type="button"
              variant="outlined"
              onClick={() => handleToggle(false)}
            >
              {t('details.modal.access-profile.change-profile.close')}
            </KiperButton>
          ) : (
            <>
              <KiperButton
                type="button"
                variant="outlined"
                onClick={() => setActiveStep(activeStep => activeStep - 1)}
              >
                {t('common:back')}
              </KiperButton>
              <Flex ml="16px">
                <KiperButton type="submit" disabled={loadingPost}>
                  {loadingPost
                    ? t('details.modal.access-profile.change-profile.saving')
                    : t('details.modal.access-profile.change-profile.confirm')}
                </KiperButton>
              </Flex>
            </>
          )}
        </Flex>
      </Flex>
    </form>
  );
};

export default ChangeAccessProfileModalConfirm;

ChangeAccessProfileModalConfirm.propTypes = {
  columns: propTypes.object.isRequired,
  handlePageChange: propTypes.func.isRequired,
  handlePageSize: propTypes.func.isRequired,
  filters: propTypes.object.isRequired,
  accessProfile: propTypes.array.isRequired,
  accessProfileTo: propTypes.object,
  setActiveStep: propTypes.func.isRequired,
  handleToggle: propTypes.func.isRequired,
};

ChangeAccessProfileModalConfirm.defaultProps = {
  accessProfileTo: null,
};
