import { createContext } from 'react';
import propTypes from 'prop-types';
import { Container } from './styles';

export const SidebarContext = createContext();

const Sidebar = ({ onToggle, active, selected, setSelected, children }) => {
  const onSelect = value => {
    if (!active || value === selected) {
      onToggle();
    }
    setSelected(value);
  };

  return (
    <SidebarContext.Provider value={{ onToggle, active, onSelect, selected }}>
      <Container>{children}</Container>
    </SidebarContext.Provider>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  onToggle: propTypes.func.isRequired,
  setSelected: propTypes.func.isRequired,
  active: propTypes.bool.isRequired,
  selected: propTypes.string,
  children: propTypes.any.isRequired,
};

Sidebar.defaultProps = {
  selected: null,
};
