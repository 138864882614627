import styled from 'styled-components';
import { Label as label } from '@kiper/ui';

export const Container = styled.div`
  display: block;
  width: 100%;
  word-break: break-all;
`;

export const Label = styled(label)`
  margin: 0;
  font-size: 0.875rem;
`;

export const TableContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgba(72, 94, 144, 0.16);
`;
