import { useWindowDimensions } from '@kiper/hooks';
import { useMemo } from 'react';
import { useCurrentLoggedContext, useVoice } from '../../../../../../hooks';
import { destinationTypes } from '../../../../../../constants';

const loggedContextParams = Object.freeze({
  kiperVoiceAgent: 'kiperVoiceAgent',
});

const HD_WIDTH = 1366;

const useActionButtons = (event, t, toggleModal, modalAction) => {
  const { width: windowsWidth } = useWindowDimensions();
  const { loggedContext } = useCurrentLoggedContext();
  const { clickToCall, loading: loadingCall } = useVoice();

  const { hasKiperVoice, hasKiperVoiceAgent } = useMemo(
    () => ({
      hasKiperVoice: loggedContext?.partner?.hasKiperVoice,
      hasKiperVoiceAgent: !!loggedContext?.personContextParams?.find(
        param => param.name === loggedContextParams.kiperVoiceAgent && param.id,
      ),
    }),
    [loggedContext],
  );

  const { deviceExtension } = useMemo(
    () => ({
      deviceExtension:
        event?.device &&
        event?.device?.serializedParams &&
        JSON.parse(event?.device?.serializedParams)?.extension,
    }),
    [event],
  );

  const resolutionIsHigherThanHD = windowsWidth > HD_WIDTH;

  const handleClickToCall = () => {
    if (!hasKiperVoice) return toggleModal(modalAction.NO_KIPER_VOICE);
    if (!hasKiperVoiceAgent)
      return toggleModal(modalAction.NO_KIPER_VOICE_AGENT);

    const callObject = {
      destination: deviceExtension,
      destinationKind: destinationTypes.intercom,
    };

    const feedbackText = t('step-call-dwellers.making-call-to-extension', {
      number: callObject.destination,
    });

    return clickToCall({
      voiceCallInput: callObject,
      feedbackText,
      eventId: event?.eventId,
      partner: event?.partner?.name,
      condominium: event?.condominium?.name,
      eventCode: event?.eventType,
      amplitudeKey: 'Click to call guided attendance',
    });
  };

  return {
    deviceExtension,
    resolutionIsHigherThanHD,
    handleClickToCall,
    loadingCall,
  };
};

export default useActionButtons;
