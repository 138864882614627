import styled, { css } from 'styled-components';
import { Input as input, Col as col, Row as row } from 'reactstrap';
import { Label as KLabel, Box } from '@kiper/ui';

export const Container = styled(Box)`
  max-height: 605px;
  overflow: auto;
`;

export const Input = styled(input)`
  border-color: ${props => props.theme.colors.secondary400};
  width: 100% !important;
  ::placeholder {
    color: ${props => props.theme.colors.mediumBlack};
  }
`;

export const Col = styled(col)`
  ${props =>
    props.border &&
    css`
      border-right: 1px solid ${props.theme.colors.secondary300};
      padding-left: 5px;
    `}
`;

export const TableContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 5px;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.colors.secondary400};

  div.table-responsive-lg {
    position: relative;
    max-height: 544px;
    overflow: auto;
    border-radius: 5px;
  }
  th {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
  }
`;

export const Label = styled(({ bold, ...props }) => <KLabel {...props} />)`
  margin: 0;

  ${props =>
    props.bold &&
    css`
      font-weight: bold;
    `}
`;

export const DetailContainer = styled.td`
  background: ${props => props.theme.colors.secondary50};
  padding: 0;

  && .table-responsive-lg {
    overflow: visible;
  }
`;

export const Row = styled(row)`
  margin: 0;
`;

export const GuestInfo = styled.div`
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
`;

export const ModalHeader = styled.div`
  padding-top: 4px;
  padding-bottom: 20px;
`;
