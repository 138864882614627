import propTypes from 'prop-types';
import { useContext } from 'react';
import { withErrorBoundary } from '@kiper/ui';
import useEmergency from '../../EventAttendance/useEmergency';
import EmergencyCommandContext from '../../EventAttendance/emergencyCommandContext';
import EventAttendanceDetail from '../../EventAttendance/Detail/EventAttendanceDetail';
import { SocketContext } from '../../../services/socket';
import AttendanceFallbackErrorBoundary from '../../../components/AttendanceFallbackErrorBoundary';

const GuidedAttendanceFallback = ({ match }) => {
  const eventId = match.params.id;

  const emergencyCommand = useEmergency({
    eventId,
    isFallback: true,
  });

  const { error } = useContext(SocketContext);

  return (
    <EmergencyCommandContext.Provider value={emergencyCommand}>
      <EventAttendanceDetail match={match} socketError={error} />
    </EmergencyCommandContext.Provider>
  );
};

GuidedAttendanceFallback.propTypes = {
  match: propTypes.object.isRequired,
};

const GuidedAttendanceFallbackComponent = withErrorBoundary(
  GuidedAttendanceFallback,
  {
    CustomErrorComponent: props => (
      <AttendanceFallbackErrorBoundary
        backToRoute="/guided-attendance"
        {...props}
      />
    ),
  },
);

export default GuidedAttendanceFallbackComponent;
