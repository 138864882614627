import { useMemo, useContext, useState } from 'react';
import { Loader } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import propTypes from 'prop-types';
import { useQuery, useMutation } from 'react-apollo';
import { systemSettings as gqlOperations } from '@kiper/monitoring-graphql';
import { useSwal, useToggle } from '@kiper/hooks';
import { apolloErrorHandler } from '@kiper/fns';
import {
  Card,
  CardHeader,
  CardBody,
  H5,
  AddMoreContainer,
  ItemActionsContainer,
  EditIcon,
  RemoveIcon,
  WrapContainer,
  ServerName,
  ServerAddress,
  Col,
  InnerContainer,
} from './styles';
import { AddMore } from '../../../../components/AddMore';
import digifortResource from '../../../../assets/images/digifort.svg';
import { DigifortModal } from './Modal';
import PageHeader from '../../../../components/PageHeader';

const MESSAGE_ALIAS = 'details.imageSettings';

const Digifort = ({ route }) => {
  const [t] = useTranslation('system-settings');
  const theme = useContext(ThemeContext);

  const { swal, toast } = useSwal();

  const [modalOpened, toggleModal] = useToggle(false);
  const [modalData, setModalData] = useState(null);

  const breadcrumb = useMemo(() => {
    const modifiedBreadcrumb = [...route.breadcrumb];
    modifiedBreadcrumb.splice(modifiedBreadcrumb.length - 1, 1);
    modifiedBreadcrumb.push({
      label: t(`${MESSAGE_ALIAS}.breadcrumb`),
      to: '',
    });
    return modifiedBreadcrumb;
  }, [route]);

  const { data, loading, refetch } = useQuery(gqlOperations.listVideoSettings);

  const videoSettingsList = useMemo(() => {
    if (data?.listVideoSettings?.length) {
      return data.listVideoSettings.map(i => ({
        id: i.id,
        ...i.serializedParams,
      }));
    }
    return [];
  }, [data]);

  const onRemoveCompleted = () => {
    toast.fire({
      title: t(`${MESSAGE_ALIAS}.remove-success`),
      icon: 'success',
    });
  };

  const onRemoveError = err => {
    const formattedErrors = apolloErrorHandler(err);
    if (formattedErrors && formattedErrors.length) {
      toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
  };

  const [remove] = useMutation(gqlOperations.removeVideoSetting, {
    onCompleted: onRemoveCompleted,
    onError: onRemoveError,
  });

  const onRemove = async id => {
    await remove({ variables: { id } });

    await refetch();
  };

  const showRemoveAlert = async value => {
    await swal.fire({
      title: t(`${MESSAGE_ALIAS}.alert-remove.title`),
      text: t(`${MESSAGE_ALIAS}.alert-remove.text`, { baseUrl: value.baseUrl }),
      cancelButtonText: t('buttons:cancel'),
      confirmButtonText: t('buttons:confirm'),
      showLoaderOnConfirm: true,
      preConfirm: () => onRemove(value.id),
      allowOutsideClick: () => !swal.isLoading(),
    });
  };

  const handleEdit = value => {
    setModalData(value);
    toggleModal(!modalOpened);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageHeader
            breadcrumb={breadcrumb}
            t={t}
            title={t(`${MESSAGE_ALIAS}.title`)}
          />

          <Card>
            <CardHeader>
              <H5 id="video-settings-subtitle">
                {t(`${MESSAGE_ALIAS}.subtitle`)}
              </H5>
            </CardHeader>
            <CardBody>
              <WrapContainer>
                {videoSettingsList.map((item, index) => (
                  <Col sm={6} key={item.id}>
                    <AddMoreContainer device={1}>
                      <ItemActionsContainer>
                        <EditIcon
                          id={`video-settings-edit-icon-${index}`}
                          size={20}
                          color={theme.colors.highBlack}
                          onClick={() => handleEdit(item)}
                        />
                        <RemoveIcon
                          id={`video-settings-remove-icon-${index}`}
                          size={20}
                          color={theme.colors.highBlack}
                          onClick={() => showRemoveAlert(item)}
                        />
                      </ItemActionsContainer>
                      <InnerContainer>
                        <img src={digifortResource} alt="digifort-icon" />
                        <ServerName>{`Digifort #${index + 1}`}</ServerName>
                        <ServerAddress
                          id={`video-settings-base-url-label-${index}`}
                        >
                          {item.baseUrl}
                        </ServerAddress>
                      </InnerContainer>
                    </AddMoreContainer>
                  </Col>
                ))}
                <Col sm={videoSettingsList.length ? 6 : 12}>
                  <AddMoreContainer onClick={() => toggleModal(!modalOpened)}>
                    <AddMore
                      id="add-more-video-settings"
                      text={t(`${MESSAGE_ALIAS}.add-button`)}
                    />
                  </AddMoreContainer>
                </Col>
              </WrapContainer>
            </CardBody>
          </Card>
        </>
      )}
      {modalOpened && (
        <DigifortModal
          toggle={() => {
            setModalData(null);
            toggleModal(!modalOpened);
          }}
          opened={modalOpened}
          data={modalData}
          refetch={refetch}
          id="video-settings-modal"
        />
      )}
    </>
  );
};

Digifort.propTypes = {
  route: propTypes.any.isRequired,
};

export default Digifort;
