import styled from 'styled-components';
import { Flex, Text } from '@kiper/ui';

export const FallbackWrapper = styled(Flex)`
  height: 100vh;
  flex: 1;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
`;

export const FallbackErrorMessage = styled(Text)``;
