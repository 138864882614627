import styled from 'styled-components';
import { Alert as alert } from '@kiper/ui';

export const Alert = styled(alert)`
  height: 60px;
  margin: 0;
  border-radius: 0;

  svg {
    width: 30px;
    height: 30px;
  }
`;
