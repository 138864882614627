import styled from 'styled-components';
import { Button as button, Badge as badge } from '@kiper/ui';

export const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Item = styled.div`
  padding: 5px;
  width: ${props =>
    props.devicesLength
      ? `calc(100%/${props.devicesLength > 1 ? 3 : 2})`
      : '100%'};
`;

export const DeviceDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content;
  padding: 10px;
`;

export const ActionButton = styled(button)`
  margin: 0 10px;
`;

export const Badge = styled(badge)`
  padding: 6px;
  margin-bottom: ${props => (props.$withoutMarginBottom ? 0 : '10px')};
`;

export const BadgeLabel = styled.span`
  color: ${props => props.theme.colors.white};
`;

export const BadgeBlackLabel = styled.span`
  color: ${props => props.theme.colors.black};
`;
