import styled from 'styled-components';
import { Badge as badge, KiperButton } from '@kiper/ui';

export const Badge = styled(badge)`
  text-transform: initial;
`;

export const BadgeAllowAccess = styled(badge)`
  text-transform: initial;
  color: ${props => props.theme.colors.danger500};
  background: white;
  border: 1px solid ${props => props.theme.colors.danger500};
`;

export const Button = styled(KiperButton)``;
