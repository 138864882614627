import propTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Modal, Flex, Box, Text, KiperButton } from '@kiper/ui';

function AlertOutsideContactTime({ onToggle, open, onConfirm, onCancel }) {
  const [t] = useTranslation('modals/alert-outside-contact-time-modal');

  return (
    <Modal
      open={open}
      id="alert-outside-contact-time-modal"
      toggle={onToggle}
      size="md"
    >
      <Flex flexDirection="column" alignItems="center" width="100%">
        <Text textAlign="center" mb="12px" fontSize="30px" fontWeight="600">
          {t('title')}
        </Text>
        <Text textAlign="center" mb="30px">
          <Trans>{t('subtitle')}</Trans>
        </Text>
        <Flex justifyContent="center">
          <Box mr="14px">
            <KiperButton
              onClick={onCancel}
              variant="outlined"
              color="secondary"
            >
              {t('cancel')}
            </KiperButton>
          </Box>
          <KiperButton onClick={onConfirm}>{t('call')}</KiperButton>
        </Flex>
      </Flex>
    </Modal>
  );
}

export default AlertOutsideContactTime;

AlertOutsideContactTime.propTypes = {
  onToggle: propTypes.func.isRequired,
  open: propTypes.bool.isRequired,
  onConfirm: propTypes.func.isRequired,
  onCancel: propTypes.func.isRequired,
};
