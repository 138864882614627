import styled, { css } from 'styled-components';
import { Input as input, Text } from '@kiper/ui';

export const Input = styled(input)`
  ${props =>
    (props.valid || props.disabled) &&
    css`
      border: 2px solid ${props.theme.colors.primary500} !important;
    `}
`;

export const SuccessText = styled(Text)`
  color: ${props => props.theme.colors.primary500};
  font-weight: bold;
`;
