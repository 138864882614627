import { useState } from 'react';
import Proptypes from 'prop-types';

import { Container, Tooltip } from './styles';

const defineColorPointFinger = finger => {
  return finger ? `#449D44` : 'gray';
};

const RightHandFingers = ({
  handleClickFinger,
  readingFinger,
  fingerEffect,
  fingersRegistered,
  t,
}) => {
  const [fingerOver, setFingerOver] = useState(undefined);

  const mIn = fingerIndex => () => {
    setFingerOver(fingerIndex);
  };

  const mOut = () => {
    setFingerOver(undefined);
  };

  return (
    <Container>
      {fingerOver !== undefined &&
        (fingersRegistered[fingerOver] ? (
          <Tooltip remove>{t(`remove_finger`)}</Tooltip>
        ) : (
          <Tooltip>{t(`put.devices.modal.instructions.add-finger`)}</Tooltip>
        ))}
      <svg
        className="hand"
        xmlns="http://www.w3.org/2000/svg"
        width="156.031"
        height="234.048"
        viewBox="0 0 156.031 234.048"
      >
        <g>
          {readingFinger && (
            <rect
              fill="red"
              className="bar"
              opacity="0.5"
              x="0"
              y="0%"
              width="156.032"
              height="3"
            />
          )}
        </g>

        <g className={readingFinger ? `reading` : ``}>
          <path
            className="highlight_path"
            d="M221.861,39.008a19.346,19.346,0,0,0-9.752,2.623V39.008a19.49,19.49,0,0,0-29.256-16.881V19.5a19.5,19.5,0,0,0-39.008,0v2.623a19.49,19.49,0,0,0-29.256,16.881V92.654a24.269,24.269,0,0,0-14.628-4.886A14.646,14.646,0,0,0,85.333,102.4v43.991a86.446,86.446,0,0,0,25.482,61.525l16.13,16.14a34.388,34.388,0,0,0,24.136,10h36.648a53.7,53.7,0,0,0,53.636-53.636V58.512A19.523,19.523,0,0,0,221.861,39.008Zm9.752,141.4A43.937,43.937,0,0,1,187.729,224.3H151.081a24.531,24.531,0,0,1-17.241-7.148l-16.13-16.13a76.776,76.776,0,0,1-22.624-54.63V102.4a4.884,4.884,0,0,1,4.876-4.876,14.646,14.646,0,0,1,14.628,14.628V141.4a4.876,4.876,0,0,0,9.752,0V39.008a9.752,9.752,0,1,1,19.5,0V102.4a4.876,4.876,0,1,0,9.752,0V19.5a9.752,9.752,0,0,1,19.5,0V102.4a4.876,4.876,0,1,0,9.752,0V39.008a9.752,9.752,0,0,1,19.5,0V102.4a4.876,4.876,0,1,0,9.752,0V58.512a9.752,9.752,0,0,1,19.5,0Z"
            transform="translate(-85.333)"
          />
        </g>

        <g>
          <g
            onClick={handleClickFinger(5)}
            onMouseEnter={mIn(5)}
            onMouseLeave={mOut}
          >
            <circle
              className={fingerEffect[5]}
              cy="108.47672"
              cx="18.01603"
              r="10px"
            />
            <ellipse
              ry="5.5"
              rx="5.5"
              cy="108.47672"
              cx="18.01603"
              fill={defineColorPointFinger(fingersRegistered[5])}
            />
            <path
              className="finger click"
              id="svg_5"
              d="m11.82811,97.74928l-1.29732,1.74998l-1.02469,3.24998l-0.25617,2.99998l0.51234,39.49971l20.23751,-1l-0.76851,-4.49996l0,-31.74977l-2.04937,-4.24997l-2.04937,-2.49998l-2.81788,-2.24998l-3.33022,-1.74999l-3.5864,-0.74999l-3.56992,1.24999z"
              fillOpacity="0"
              fill="#000000"
            />
          </g>

          <g
            onClick={handleClickFinger(6)}
            onMouseEnter={mIn(6)}
            onMouseLeave={mOut}
          >
            <circle
              className={fingerEffect[6]}
              cy="37.97724"
              cx="48.7658"
              r="10px"
            />
            <ellipse
              ry="5.5"
              rx="5.5"
              cy="37.97724"
              cx="48.7658"
              fill={defineColorPointFinger(fingersRegistered[6])}
            />
            <path
              className="finger click"
              d="m49.26572,28.99978l-2.51608,0.125l-1.99998,0.74999l-2.12498,1.49999l-1.62499,1.62499l-1.12499,1.99998l-0.74999,2.62498l0,2.62498l0,62.87452l19.62485,-0.125l-0.125,-65.7495l-0.375,-1.37499l-0.74999,-1.74999l-0.625,-1.12499l-0.87499,-1.12499l-2.24998,-1.24999l-2.12498,-1.12499l-2.35889,-0.5z"
              fillOpacity="0"
              fill="#050505"
            />
          </g>
          <g
            onClick={handleClickFinger(7)}
            onMouseEnter={mIn(7)}
            onMouseLeave={mOut}
          >
            <circle
              className={fingerEffect[7]}
              cy="19.72738"
              cx="78.39058"
              r="10px"
            />
            <ellipse
              ry="5.5"
              rx="5.5"
              cy="19.72738"
              cx="78.39058"
              fill={defineColorPointFinger(fingersRegistered[7])}
            />
            <path
              className="finger click"
              d="m78.5155,9.99992l-2.51608,0.15704l-1.99998,0.94223l-2.12499,1.88447l-1.62498,2.04151l-1.12499,2.51262l-0.75,3.29782l0,3.29782l0,78.99075l19.62485,-0.15704l-0.125,-82.60264l-0.37499,-1.72743l-0.75,-2.19855l-0.62499,-1.41335l-0.875,-1.41335l-2.24998,-1.57039l-2.12498,-1.41336l-2.35889,-0.62815z"
              fillOpacity="0"
              fill="#000000"
            />
          </g>

          <g
            onClick={handleClickFinger(8)}
            onMouseEnter={mIn(8)}
            onMouseLeave={mOut}
          >
            <circle
              className={fingerEffect[8]}
              cy="40.10223"
              cx="107.64035"
              r="10px"
            />
            <ellipse
              ry="5.5"
              rx="5.5"
              cy="40.10223"
              cx="107.64035"
              fill={defineColorPointFinger(fingersRegistered[8])}
            />
            <path
              className="finger click"
              d="m107.51528,28.62478l-2.51608,0.12669l-1.99998,0.76011l-2.12499,1.52022l-1.62498,1.64692l-1.12499,2.02696l-0.75,2.6604l0,2.66039l0,63.72274l19.62485,-0.12669l-0.125,-66.6365l-0.37499,-1.39355l-0.75,-1.7736l-0.62499,-1.14017l-0.875,-1.14017l-2.24998,-1.26686l-2.12498,-1.14017l-2.35889,-0.50674z"
              fillOpacity="0"
              fill="#000000"
            />
          </g>
          <g
            onClick={handleClickFinger(9)}
            onMouseEnter={mIn(9)}
            onMouseLeave={mOut}
          >
            <circle
              className={fingerEffect[9]}
              cy="57.72709"
              cx="137.14013"
              r="10px"
            />
            <ellipse
              ry="5.5"
              rx="5.5"
              cy="57.72709"
              cx="137.14013"
              fill={defineColorPointFinger(fingersRegistered[9])}
            />
            <path
              className="finger click"
              d="m136.76505,49.12463l-1.64108,0l-2.12498,0.5l-1.99998,0.99999l-1.49999,1.37499l-1.37499,1.62499l-1.12499,1.99998l-0.375,3.12498l0.125,43.74967l19.37485,0l0,-45.24965l-0.375,-2.24998l-1.24999,-1.99998l-1.24999,-1.62499l-1.99998,-1.24999l-1.99998,-0.87499l-2.48389,-0.125z"
              fillOpacity="0"
              fill="#000000"
            />
          </g>
        </g>
      </svg>
    </Container>
  );
};

RightHandFingers.propTypes = {
  handleClickFinger: Proptypes.func.isRequired,
  readingFinger: Proptypes.bool.isRequired,
  fingerEffect: Proptypes.arrayOf(Proptypes.string).isRequired,
  fingersRegistered: Proptypes.arrayOf(Proptypes.shape({})).isRequired,
  t: Proptypes.func.isRequired,
};

export default RightHandFingers;
