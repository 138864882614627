import { useState, useRef, useMemo, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { attendance as attendanceGql } from '@kiper/monitoring-graphql';
import { useMutation } from 'react-apollo';
import { firstBy } from 'thenby';
import { useTranslation } from 'react-i18next';
import { useSwal } from '@kiper/hooks';
import { getUserLang } from '@kiper/fns';
import useLoggedContext from './useCurrentLoggedContext';
import useNotification from './useNotification';
import logoShield from '../assets/images/logo-shield.png';
import { SocketContext } from '../services/socket';
import {
  actionRedisEventDescription,
  actionRedisEvents,
  eventTypes,
} from '../constants';
import { sendAmplitudeData } from '../services/amplitude';

export default function useAttendance() {
  const history = useHistory();
  const { loggedContext } = useLoggedContext();
  const {
    subscribe,
    unsubscribe,
    sendMessage,
    error,
    removeListener,
    addListener,
    loading: socketLoading,
  } = useContext(SocketContext);

  const [timeLeft, setTimeLeft] = useState(null);

  const condominiumStatusListeners = useRef([]);
  const [events, setEvents] = useState([]);
  const [eventsOnHold, setEventsOnHold] = useState([]);
  const [popupData, setPopupData] = useState({
    visible: false,
    eventId: null,
    condoName: null,
    inAttendance: null,
  });
  const [loading, setLoading] = useState(true);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [contextFilter, setContextFilter] = useState(null);
  const { showEvent } = useNotification();
  const [t] = useTranslation('event_attendance');
  const { toast } = useSwal();
  const lang = useMemo(() => getUserLang().split('-')[0], []);

  const hidePopup = () =>
    setPopupData({
      visible: false,
      eventId: null,
      condoName: null,
      isAttendance: null,
    });

  const resetFilters = () => {
    setContextFilter(null);
    setFilteredEvents([]);
  };

  // Recuperar um evento
  const [fetchEvent, getEventProps] = useMutation(attendanceGql.get);

  // Dropar um evento
  const [fetchDropEvent, getDropProps] = useMutation(attendanceGql.drop, {
    onCompleted: () => {
      resetFilters();
    },
  });

  // Finalizar um evento
  const [fetchFinishEvent, getFinishProps] = useMutation(attendanceGql.finish, {
    onCompleted: () => {
      toast.fire({
        icon: 'success',
        title: t('finished-successfully'),
      });

      const onlyInAttendance =
        filteredEvents.length === 1 && filteredEvents.some(x => x.attendant);

      if (onlyInAttendance) {
        resetFilters();
      }
    },
  });

  const formatEvents = (eventList, action) => {
    const eventIds = eventList
      .map(event => event.eventId)
      .sort(firstBy(id => id));

    const duplicateEvents = eventIds.filter(
      (item, index) => eventIds.indexOf(item) !== index,
    );

    const correctEventList = [...new Set(eventIds)].map(id =>
      eventList.find(event => event.eventId === id),
    );

    if (duplicateEvents.length) {
      sendAmplitudeData('duplicate event state', {
        duplicateEvents,
        action,
      });
    }

    return correctEventList;
  };

  const updateEvent = eventUpdated => {
    const eventDropped = !eventUpdated?.attendant;

    if (eventDropped) {
      resetFilters();
    }

    if (eventUpdated.onHold) {
      setEvents(previousEvents =>
        formatEvents(
          previousEvents,
          actionRedisEventDescription.UPDATE_EVENT_ON_HOLD,
        ).filter(event => event.eventId !== eventUpdated.eventId),
      );
      setEventsOnHold(previousEvents => {
        const hasEvent = previousEvents.some(
          x => x.eventId === eventUpdated.eventId,
        );
        if (hasEvent) {
          return previousEvents.map(event =>
            event.eventId === eventUpdated.eventId ? eventUpdated : event,
          );
        }
        return [...previousEvents, eventUpdated];
      });
    } else {
      setEvents(previousEvents => {
        const hasEvent = previousEvents.some(
          event => event.eventId === eventUpdated.eventId,
        );

        if (hasEvent) {
          return formatEvents(
            previousEvents,
            actionRedisEventDescription.UPDATE_EVENT,
          ).map(event =>
            event.eventId === eventUpdated.eventId ? eventUpdated : event,
          );
        }

        return formatEvents(
          [...previousEvents, eventUpdated],
          actionRedisEventDescription.UPDATE_EVENT_NOT_FOUND,
        );
      });

      setEventsOnHold(previousEventsOnHold =>
        previousEventsOnHold.filter(
          event => event.eventId !== eventUpdated.eventId,
        ),
      );
    }

    if (eventUpdated?.attendant?.id === +loggedContext.personContextId) {
      history.push(`/attendance/${eventUpdated.eventId}`);

      if (
        eventUpdated?.ownerId !== +loggedContext.personContextId &&
        eventUpdated?.eventType !== eventTypes.answeredCall
      ) {
        hidePopup();
      }
    }
  };

  const popularEvents = redisEvents => {
    if (redisEvents.length) {
      const queue = [
        ...new Set(redisEvents.map(event => event.eventId)),
      ].map(id => redisEvents.find(event => event.eventId === id));

      const mainQueue = formatEvents(
        redisEvents,
        actionRedisEventDescription.LIST_EVENT,
      ).filter(event => !event.onHold);

      const onHoldQueue = queue.filter(event => event.onHold);

      setEvents(mainQueue);
      setEventsOnHold(onHoldQueue);

      const inAttendant = redisEvents.find(
        event => event?.attendant?.id === +loggedContext.personContextId,
      );

      if (inAttendant) {
        history.push(`/attendance/${inAttendant.eventId}`);
      }
    }

    setLoading(false);
  };

  const onMessage = ({ action, item }) => {
    switch (action) {
      case actionRedisEvents.NEW_EVENT: // adiciona um item
        if (item.event.showAlert || item.event.showPopUp) {
          const title = item.event.eventTypeDescription[lang];
          if (item.event?.place?.name) {
            title.concat(` - ${item.event.place.name}`);
          }
          showEvent({
            title,
            options: {
              body: item.event?.condominium?.name,
              icon: logoShield,
            },
            notificationClick: () =>
              fetchEvent({ variables: { eventId: item.event.eventId } }),
          });
        }

        setEvents(previousEvents =>
          formatEvents(
            [...previousEvents, item.event],
            actionRedisEventDescription.NEW_EVENT,
          ),
        );

        // Redirect to event info, if event was created by logged user.
        if (
          item.event?.ownerId === +loggedContext.personContextId &&
          item.event.attendant?.id === +loggedContext.personContextId &&
          item?.event?.eventType === eventTypes.attendanceAdditional
        ) {
          history.push(`/attendance/${item.event.eventId}`);
        }

        if (
          item.event?.ownerId === +loggedContext.personContextId &&
          item?.event?.eventType === eventTypes.answeredCall
        ) {
          let inAttendance;
          setEvents(previousEvents => {
            inAttendance = previousEvents.find(
              x => x.attendant?.id === +loggedContext.personContextId,
            );

            return formatEvents(
              previousEvents,
              actionRedisEventDescription.NEW_EVENT_IN_ATTENDANCE,
            );
          });
          if (!inAttendance)
            fetchEvent({ variables: { eventId: item.event.eventId } });

          setPopupData({
            visible: true,
            eventId: item.event.eventId,
            condoName: item.event?.condominium?.name,
            inAttendance,
          });
        }
        break;

      case actionRedisEvents.UPDATE_EVENT: // update event
        updateEvent(item.event);
        break;

      case actionRedisEvents.REMOVE_EVENT: // remove um item
        setEvents(previousEvents =>
          formatEvents(
            previousEvents,
            actionRedisEventDescription.REMOVE_EVENT,
          ).filter(event => event.eventId !== item.event.eventId),
        );
        setEventsOnHold(previousEvents =>
          previousEvents.filter(event => event.eventId !== item.event.eventId),
        );
        break;

      case actionRedisEvents.LIST_EVENT: // lista de itens
        popularEvents(item.events);
        break;

      case actionRedisEvents.UPDATE_CONDOMINIUM_STATUS: // update condominum status
        condominiumStatusListeners.current.forEach(fn => fn(item));
        setEvents(previousEvents =>
          formatEvents(
            previousEvents,
            actionRedisEventDescription.UPDATE_CONDOMINIUM_STATUS,
          ),
        );
        break;

      default:
        setEvents(previousEvents =>
          formatEvents(previousEvents, actionRedisEventDescription.DEFAULT),
        );
        break;
    }
  };

  useEffect(() => {
    const socketConnected = () => {
      subscribe(loggedContext.personContextId, onMessage);
      sendMessage('attendentConnected', loggedContext.personContextId);
    };

    socketConnected();
    addListener('reconnect', socketConnected);

    return () => {
      removeListener('reconnect', socketConnected);
      unsubscribe(loggedContext.personContextId);
      sendMessage('attendentDisconnected', loggedContext.personContextId);
    };
  }, []);

  const eventQueue = useMemo(
    () => (filteredEvents.length ? filteredEvents : events),
    [filteredEvents, events],
  );

  const { inAttendance, inAttendanceOnHold, mainQueue, onHoldQueue } = useMemo(
    () => ({
      inAttendance: eventQueue?.find(
        event => event?.attendant?.id === +loggedContext.personContextId,
      ),
      inAttendanceOnHold: eventsOnHold.find(
        event => event?.attendant?.id === +loggedContext.personContextId,
      ),
      mainQueue: eventQueue
        ?.filter(
          event =>
            event?.attendant?.id !== Number(loggedContext.personContextId),
        )
        .sort(
          firstBy('ownerId', 'desc')
            .thenBy('isCritical', 'desc')
            .thenBy('priority', 'desc')
            .thenBy('eventDate'),
        )
        ?.slice(0, 100),
      onHoldQueue: eventsOnHold
        ?.filter(
          event =>
            event?.attendant?.id !== Number(loggedContext.personContextId),
        )
        .sort(firstBy('eventDate')),
    }),
    [events, filteredEvents, eventsOnHold],
  );

  useEffect(() => {
    if (!!events.length && (inAttendance || !!contextFilter)) {
      if (inAttendance?.eventType !== eventTypes.answeredCall) {
        const condominiumContextId =
          inAttendance?.condominium?.personContextId || contextFilter;

        const filtered = events.filter(
          e => e.condominium.personContextId === condominiumContextId,
        );

        if (condominiumContextId !== contextFilter) {
          setContextFilter(condominiumContextId);
        }
        setFilteredEvents(filtered);
      }
    } else if (!events.length) {
      resetFilters();
    }
  }, [events, inAttendance]);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }

    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return {
    loading:
      getEventProps.loading || getDropProps.loading || getFinishProps.loading,
    attendanceList: {
      error,
      inAttendance,
      inAttendanceOnHold,
      events: mainQueue,
      eventsOnHold: onHoldQueue,
      loading: loading || socketLoading,
      filteredEvents,
      resetFilters,
      allEvents: events,
    },
    play: {
      getEvent: eventId => fetchEvent({ variables: { eventId } }),
      ...getEventProps,
    },
    stop: {
      // TODO change the "STOP" message
      dropEvent: () => fetchDropEvent({ variables: { message: 'STOP' } }),
      ...getDropProps,
    },
    finish: {
      finishEvent: ({ message, untreatedEventOptionId }) =>
        fetchFinishEvent({ variables: { message, untreatedEventOptionId } }),
      ...getFinishProps,
    },
    condominiumStatus: {
      subscribe: callback => condominiumStatusListeners.current.push(callback),
      unsubscribe: callback => {
        condominiumStatusListeners.current = condominiumStatusListeners.current.filter(
          fn => fn !== callback,
        );
      },
    },
    popupData,
    hidePopup,
    timeLeft,
    setTimeLeft,
  };
}
