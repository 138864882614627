import styled from 'styled-components';
import { Flex, Text, Button as button } from '@kiper/ui';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-start;
`;

export const RuleContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  border-bottom: 1px ${props => props.theme.colors.secondary300} solid;
  padding: 8px;
  &:first-child {
    padding-top: ${({ filters }) => (filters ? '8px' : '0')};
  }
`;

export const Title = styled(Text)`
  font-family: Lato;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-right: 12px;
  color: ${props => props.theme.colors.highBlack};
`;

export const Description = styled(Text)`
  font-family: Lato;
  font-weight: 400;
  font-size: 14px;
  line-break: auto;
  line-height: 20px;
  white-space: break-spaces;
  word-break: break-word;
  color: ${props => props.theme.colors.mediumBlack};
`;

export const Button = styled(button).attrs(props => ({
  color: props.filters ? 'primary' : 'black',
}))`
  border: none;
  background: ${({ theme, filters }) =>
    filters ? theme.colors.primary50 : 'transparent'};
  &:hover {
    background: ${({ theme, filters }) =>
      filters ? theme.colors.primary100 : theme.colors.hoverGrey};
  }
`;
