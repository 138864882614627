import styled, { css } from 'styled-components';

import { Text as text, Flex } from '@kiper/ui';

export const Ul = styled.ul`
  padding-inline-start: 15px;
`;

export const Li = styled.li`
  text-align: start;
`;

export const TextEllipsis = styled(text)`
  opacity: 0.8;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  cursor: default;
`;

export const EventContainer = styled(Flex)`
  color: ${props =>
    props.attend ? props.theme.colors.white : props.theme.colors.highBlack};

  ${({ critical, attend, theme, isLoading, isTelephony, isEventScheduled }) => {
    if (isLoading) {
      return css`
        background-color: ${theme.colors.warningPrimary};
        border-color: ${theme.colors.warningPrimary};
        color: ${theme.colors.white};
      `;
    }

    if (isEventScheduled && attend) {
      return css`
        background-color: ${theme.colors.info500};
        border-color: ${theme.colors.info500};
      `;
    }

    if (isEventScheduled && !attend) {
      return css`
        background-color: ${theme.colors.info50};
        border-color: ${theme.colors.info500};

        :hover {
          background-color: ${theme.colors.info100};
        }

        div {
          span.event-title {
            color: ${theme.colors.info500};
          }
        }
      `;
    }

    if (isTelephony && attend) {
      return css`
        background-color: ${theme.colors.violet500};
        border-color: ${theme.colors.violet500};
      `;
    }

    if (isTelephony && !attend) {
      return css`
        background-color: ${theme.colors.violet50};
        border-color: ${theme.colors.violet500};

        :hover {
          background-color: ${theme.colors.violet100};
        }

        div {
          span.event-title {
            color: ${theme.colors.violet500};
          }
        }
      `;
    }

    if (!critical && attend) {
      return css`
        background-color: ${theme.colors.primary500};
        border-color: ${theme.colors.primary500};
      `;
    }

    if (!critical && !attend) {
      return css`
        background-color: ${theme.colors.white};
        border-color: ${theme.colors.secondary500};

        :hover {
          background-color: ${theme.colors.secondary100};
        }
      `;
    }

    if (critical && attend) {
      return css`
        background-color: ${theme.colors.dangerSecondary};
        border-color: ${theme.colors.dangerSecondary};
      `;
    }

    if (critical && !attend) {
      return css`
        background-color: ${theme.colors.danger50};
        border-color: ${theme.colors.danger500};

        :hover {
          background-color: ${theme.colors.danger100};
        }
        div {
          span.event-title {
            color: ${theme.colors.danger500};
          }
        }
      `;
    }

    return null;
  }}
`;
