import propTypes from 'prop-types';
import { Flex } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

const CondoInfoLicenses = ({ icon, licenses }) => {
  const [t] = useTranslation('condominium');

  return (
    <S.CondoInfoContainer>
      <span>{icon}</span>
      <S.WrapperInfo>
        <Flex flexDirection="column" gridGap="4px">
          <strong>{t('information.licenses')}</strong>
          <Flex gridGap="4px" flexWrap="wrap">
            {licenses ? (
              licenses.map(item => (
                <S.BadgeOutlined key={item.licenseType}>
                  <span>{t(`list.erp_licenses.${item.licenseType}`)}</span>
                </S.BadgeOutlined>
              ))
            ) : (
              <S.BadgeOutlined>
                {t('list.erp_licenses.nonexistent')}
              </S.BadgeOutlined>
            )}
          </Flex>
        </Flex>
      </S.WrapperInfo>
    </S.CondoInfoContainer>
  );
};

export default CondoInfoLicenses;

CondoInfoLicenses.propTypes = {
  icon: propTypes.element.isRequired,
  licenses: propTypes.array.isRequired,
};
