import authResolvers from './auth';

const allResolvers = [authResolvers];

const resolvers = { Query: {}, Mutation: {} };

allResolvers.forEach(resolver =>
  Object.keys(resolver).forEach(key => {
    resolvers[key] = {
      ...resolvers[key],
      ...resolver[key],
    };
  }),
);

export default resolvers;
