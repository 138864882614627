import propTypes from 'prop-types';
import { Flex } from '@kiper/ui';
import { EventContainer } from './styles';
import EventDetailsHeader from './Header';
import EventDetailsBody from './Body';
import { EventRecord } from './EventRecord';

const AttendanceDetail = ({
  formik,
  event,
  eventCameras,
  hasAlertVisible,
  profilePopoverVisible,
  toggleEventTimelineModalOpen,
  toggleUsersModalOpen,
  usersModalOpen,
  toggleIotModalOpen,
  toggleEventActivityModalOpen,
  handleToggleUserPopover,
  isInAttendance,
  inAttendanceOnHold,
  dropEvent,
  loadingFinish,
  t,
}) => {
  return (
    <Flex
      bg="white"
      height={hasAlertVisible ? 'calc(100vh - 60px)' : '100vh'}
      width="100%"
      flex={1}
      flexDirection="column"
    >
      <EventContainer mx="auto">
        <EventDetailsHeader
          formik={formik}
          event={event}
          popoverVisible={profilePopoverVisible === event?.person}
          onClickAvatar={() => handleToggleUserPopover(event.person)}
          t={t}
          onClickEventTimeline={toggleEventTimelineModalOpen}
          onClickEventActivity={toggleEventActivityModalOpen}
          onClickSettings={() => {}}
          onClickUsers={toggleUsersModalOpen}
          onClickIot={toggleIotModalOpen}
          disabled={!isInAttendance && !inAttendanceOnHold}
        />

        <EventDetailsBody
          event={event}
          eventCameras={eventCameras}
          isInAttendance={isInAttendance}
        />
        {event && (
          <EventRecord
            event={event}
            drop={dropEvent}
            loading={loadingFinish}
            usersModalOpen={usersModalOpen}
            {...formik}
          />
        )}
      </EventContainer>
    </Flex>
  );
};

export default AttendanceDetail;
AttendanceDetail.propTypes = {
  formik: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  eventCameras: propTypes.array.isRequired,
  hasAlertVisible: propTypes.bool.isRequired,
  toggleInvitesModalOpen: propTypes.func.isRequired,
  toggleEventTimelineModalOpen: propTypes.func.isRequired,
  toggleUsersModalOpen: propTypes.func.isRequired,
  toggleIotModalOpen: propTypes.func.isRequired,
  toggleEventActivityModalOpen: propTypes.func.isRequired,
  handleToggleUserPopover: propTypes.func.isRequired,
  dropEvent: propTypes.func.isRequired,
  t: propTypes.func.isRequired,
  usersModalOpen: propTypes.bool.isRequired,
  isInAttendance: propTypes.bool.isRequired,
  inAttendanceOnHold: propTypes.bool.isRequired,
  loadingFinish: propTypes.bool.isRequired,
  profilePopoverVisible: propTypes.bool,
};

AttendanceDetail.defaultProps = {
  profilePopoverVisible: false,
};
