import { runtimeConfig } from '@kiper/fns';
import firebase from '@kiper/firebase';

const monitoringConfig = {
  apiKey: runtimeConfig.FIREBASE_API_KEY,
  authDomain: runtimeConfig.FIREBASE_AUTH_DOMAIN,
  databaseUrl: runtimeConfig.FIREBASE_DATABASE_URL,
  projectId: runtimeConfig.FIREBASE_PROJECT_ID,
  storageBucket: runtimeConfig.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: runtimeConfig.FIREBASE_MESSAGING_SENDER_ID,
  appId: runtimeConfig.FIREBASE_APP_ID,
  measurementId: runtimeConfig.FIREBASE_MEASUREMENT_ID,
};

export const getFireInstance = () => firebase.getFirebase(monitoringConfig);

export const logEvent = (firebaseInstance, event, params) =>
  firebase.logEvent(firebaseInstance, event, params);

export const remoteConfig = firebaseInstance =>
  firebase.remoteConfig(firebaseInstance);
