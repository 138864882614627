import styled from 'styled-components';
import { Select } from '@kiper/ui';

const StyledSelect = styled(Select)`
  z-index: 1;

  .select__value-container {
    max-height: 36px;
    overflow: auto;
  }
`;

export default StyledSelect;
