import { useEffect, useMemo } from 'react';
import propTypes from 'prop-types';
import { queries } from '@kiper/monitoring-graphql/condominium/detail';

import { Spinner, Flex } from '@kiper/ui';

import { useLazyQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import SidebarPage from '../../Sidebar/SidebarPage/index';
import { EmptyStateIcon } from '../../EmptyStateIcon/index';

import { FileIcon, Label } from './styles';

const CondominiumNotes = ({ condominium }) => {
  const [t] = useTranslation('event_attendance');

  const [fetch, { data, loading }] = useLazyQuery(queries.condominiumNotes, {
    fetchPolicy: 'no-cache',
  });

  const notes = useMemo(
    () =>
      data?.condominiumNotes?.value
        ? JSON.parse(data.condominiumNotes.value).notes
        : null,
    [data],
  );

  useEffect(() => {
    const personContextId =
      condominium?.personContext?.id || condominium?.personContextId;
    if (personContextId) {
      fetch({
        variables: { condominiumId: `${personContextId}` },
      });
    }
  }, []);

  return (
    <SidebarPage header={t('details.how-treat.notes')}>
      {loading && (
        <Flex justifyContent="center">
          <Spinner />
        </Flex>
      )}
      {!loading && notes && <Label>{notes}</Label>}
      {!loading && !notes && (
        <EmptyStateIcon
          mt={56}
          icon={FileIcon}
          title={t('details.how-treat.empty-state.notes.title')}
          text={t('details.how-treat.empty-state.notes.text', {
            condominiumName: condominium?.name,
          })}
        />
      )}
    </SidebarPage>
  );
};

export default CondominiumNotes;

CondominiumNotes.propTypes = {
  condominium: propTypes.shape({
    personContext: propTypes.shape({
      id: propTypes.number,
    }),
    personContextId: propTypes.number,
    name: propTypes.string,
  }),
};

CondominiumNotes.defaultProps = {
  condominium: null,
};
