import propTypes from 'prop-types';
import { Flex } from '@kiper/ui';
import { RuleContainer, Title, Description } from './styles';

const CondominiumRule = ({ rule, enabledFilters }) => {
  return (
    <RuleContainer filters={enabledFilters}>
      <Flex mb="8px" alignItems="center">
        <Title>{rule?.title}</Title>
      </Flex>
      <Description>{rule?.description}</Description>
    </RuleContainer>
  );
};

export default CondominiumRule;

CondominiumRule.propTypes = {
  rule: propTypes.object.isRequired,
  enabledFilters: propTypes.bool.isRequired,
};
