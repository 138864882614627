import { useState } from 'react';
import { eventTypes } from '../../../constants';

const randomID = String(Math.floor(Math.random() * 100001));

const useEventGeneratorTemplate = () => {
  const [eventTemplate, setEventTemplate] = useState(null);

  const createNewTemplate = event => {
    return {
      ...event?.template,
      id: randomID,
    };
  };

  const handleTemplateForAnsweredCall = (event, newTemplate, setFieldValue) => {
    if (event?.eventType !== eventTypes.answeredCall) {
      return setFieldValue('template', newTemplate);
    }
    return setFieldValue('template', {});
  };

  const handleChangeTemplate = (event, setFieldValue) => {
    if (event) {
      const newTemplate = createNewTemplate(event);
      const newEventTemplate = { ...event, newTemplate };
      setEventTemplate(newEventTemplate);
      handleTemplateForAnsweredCall(event, newTemplate, setFieldValue);
    } else {
      setEventTemplate(null);
    }
  };

  return {
    eventTemplate,
    handleChangeTemplate,
  };
};

export default useEventGeneratorTemplate;
