import { Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import propTypes from 'prop-types';

const modal = ({ title, open, toggle, children, id, ...props }) => {
  return (
    <Modal isOpen={open} toggle={toggle} backdrop="static" id={id} {...props}>
      <ModalHeader toggle={toggle}>
        <Label style={{ margin: '0' }}>{title}</Label>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export default modal;

modal.propTypes = {
  title: propTypes.string.isRequired,
  toggle: propTypes.func.isRequired,
  open: propTypes.bool.isRequired,
  children: propTypes.element.isRequired,
  id: propTypes.string,
};

modal.defaultProps = {
  id: '',
};
