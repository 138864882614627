import styled, { css } from 'styled-components';
import {
  Box,
  Flex,
  KiperRadioCustomButton,
  Text as text,
  Spinner as spinner,
} from '@kiper/ui';
import { MdInfoOutline } from 'react-icons/md';
import fullHdPhotoMask from '../../../assets/images/guided-photo-mask-fullhd.svg';
import hdPhotoMask from '../../../assets/images/guided-photo-mask-hd2.svg';

export const InfoText = styled(text)`
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: ${props =>
    props.color
      ? props.theme.colors[props.color]
      : props.theme.colors.highBlack};
  line-height: normal;
`;

export const InfoIcon = styled(MdInfoOutline).attrs({
  size: 20,
})`
  color: ${props => props.theme.colors.warning700};
`;

export const Icon = styled(Box)`
  padding: 10px;
  background: ${({ theme }) => theme.colors.gray100};
  border-radius: 50%;
`;

export const Spinner = styled(spinner)`
  position: absolute;
  z-index: 1;
`;

export const Button = styled(KiperRadioCustomButton)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 10px;
  width: 180px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.colors.secondary800};
  transition: all 0.3s;

  @media screen and (min-width: 1920px) {
    width: 195px;
  }

  ${props =>
    props.checked &&
    css`
      box-shadow: 0 0 0 1px ${props.theme.colors.primary500};
      background-color: ${props.theme.colors.primary20};
      border-color: ${props.theme.colors.primary500};
      color: ${props.theme.colors.primary500} !important;
      transition: all 0.3s;

      &:hover {
        background-color: ${props.theme.colors.primary50} !important;
      }
    `}
`;

export const Wrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
`;

export const WrapperPlayer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 262px;
  max-height: 297px;
  background-color: ${props => props.theme.colors.gray900};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  @media screen and (min-width: 1920px) {
    height: 360px;
    max-height: 360px;
  }

  canvas {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

export const MJPEGPlayerMask = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  max-height: fit-content;
  max-width: fit-content;

  ${props =>
    !props.loading &&
    css`
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        opacity: 0.7;

        @media screen and (min-width: 1920px) {
          background-image: ${`url(${fullHdPhotoMask})`};
        }

        background-image: ${`url(${hdPhotoMask})`};
      }
    `}
`;

export const PlayerActionArea = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  background-color: ${props => props.theme.colors.gray900};
  width: 100%;
  height: auto;
  padding: 8px;
  gap: 4px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const LiveIndicator = styled.span`
  position: absolute;
  display: flex;
  border-radius: 8px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.danger500};
  width: fit-content;
  height: fit-content;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
`;

export const PlayerButton = styled.button`
  all: unset;
  display: flex;
  border-radius: 8px;
  box-sizing: border-box;
  color: ${({ theme, success }) =>
    success ? theme.colors.primary500 : theme.colors.blackShades(0.87)};
  background-color: ${props => props.theme.colors.white};
  width: fit-content;
  height: fit-content;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.5px;

  &:disabled {
    opacity: 0.5;
  }
`;
