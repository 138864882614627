import GuidedAttendance from './GuidedAttendance';
import GuidedAttendanceDetail from './GuidedAttendanceDetail';
import GuidedAttendanceFallback from './GuidedAttendanceFallback';

const requiredPermissions = ['monitoringScreen'];

export default [
  {
    name: 'Guided attendance',
    path: '/guided-attendance',
    component: GuidedAttendance,
    requiredPermissions,
    routes: [
      {
        name: 'Guided attendance detail',
        path: '/guided-attendance/:id?',
        exact: true,
        component: GuidedAttendanceDetail,
        requiredPermissions,
      },
      {
        name: 'Guided attendance fallback',
        path: '/guided-attendance/:id?/fallback',
        exact: true,
        component: GuidedAttendanceFallback,
        requiredPermissions,
      },
    ],
  },
];
