import { Label, Input, CheckBox, Button, Flex } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import propTypes from 'prop-types';
import Modal from '../../../../../components/Modal';
import * as S from './styles';

const MESSAGE_ALIAS = 'details.voiceSettings';
const PREFIX_ID = 'voiceSettings-settings';
const MESSAGE_PREFIX = 'details.imageSettings.modal';

const KiperVoiceModal = ({
  toggle,
  opened,
  kiperVoiceData,
  id,
  onSave,
  loading,
}) => {
  const [t] = useTranslation('system-settings');

  const onSubmit = values => onSave(values);

  const { values, setFieldValue, handleSubmit, errors } = useFormik({
    initialValues: kiperVoiceData ?? {
      kiperVoiceUrl: '',
      kiperVoiceStorageUrl: '',
      isDefault: false,
      newVersion: false,
    },
    validationSchema: yup.object({
      kiperVoiceUrl: yup.string().required(t('common:feedback.required-field')),
      kiperVoiceStorageUrl: yup.string().nullable(),
      isDefault: yup.boolean(),
      newVersion: yup.boolean(),
      name: yup.string(),
      id: yup.string(),
    }),
    onSubmit,
  });

  return (
    <Modal
      title={t(`${MESSAGE_PREFIX}.title`)}
      toggle={toggle}
      open={opened}
      id={id}
    >
      <S.Alert id={`${PREFIX_ID}-tip`}>
        <S.AlertIcon />
        <Label>{t(`${MESSAGE_ALIAS}.info`)}</Label>
      </S.Alert>
      <S.Form onSubmit={handleSubmit}>
        <Flex flexDirection="column">
          <Label>{t('details.voiceSettings.url-label')}</Label>
          <S.FormGroup>
            <Input
              name="kiperVoiceUrl"
              type="text"
              className="form-control"
              placeholder={t('details.voiceSettings.enter-url')}
              value={values.kiperVoiceUrl}
              onChange={setFieldValue}
              invalid={!!errors.kiperVoiceUrl}
            />
            <S.Tip>{t('details.voiceSettings.url-tip')}</S.Tip>
          </S.FormGroup>

          <Label>{t('details.voiceSettings.storage-url-label')}</Label>
          <S.FormGroup>
            <Input
              name="kiperVoiceStorageUrl"
              type="text"
              className="form-control"
              placeholder={t('details.voiceSettings.storage-enter-url')}
              value={values.kiperVoiceStorageUrl}
              onChange={setFieldValue}
            />
          </S.FormGroup>

          <Flex gridGap="8px" flexDirection="column" width="100%">
            <S.CheckBoxContent>
              <CheckBox
                id="isDefault"
                className="form-control"
                name="isDefault"
                checked={values.isDefault}
                onChange={e => setFieldValue('isDefault', e.target.checked)}
              />
              <Label for="isDefault" info block>
                {t('details.voiceSettings.is-default')}
              </Label>
            </S.CheckBoxContent>

            <S.CheckBoxContent>
              <CheckBox
                id="newVersion"
                className="form-control"
                name="newVersion"
                checked={values.newVersion}
                onChange={e => setFieldValue('newVersion', e.target.checked)}
              />
              <Label for="newVersion" info block>
                Click To Call V2
              </Label>
            </S.CheckBoxContent>
          </Flex>
        </Flex>

        <S.ModalActions>
          <Button
            color="secondary"
            outline
            className="mr-3"
            type="button"
            onClick={toggle}
          >
            {t('buttons:cancel')}
          </Button>

          <Button
            id="voiceSettings-modal-save-button"
            type="submit"
            loading={loading}
            color="primary"
            disabled={loading}
          >
            {loading ? t('buttons:saving') : t('buttons:save')}
          </Button>
        </S.ModalActions>
      </S.Form>
    </Modal>
  );
};

KiperVoiceModal.propTypes = {
  id: propTypes.string.isRequired,
  toggle: propTypes.func.isRequired,
  opened: propTypes.bool.isRequired,
  loading: propTypes.bool.isRequired,
  onSave: propTypes.func.isRequired,
  kiperVoiceData: propTypes.object,
};

KiperVoiceModal.defaultProps = {
  kiperVoiceData: null,
};

export default KiperVoiceModal;
