import { useEffect, useCallback } from 'react';
import { useApolloClient } from 'react-apollo';
import gql from 'graphql-tag';

const notificationGql = gql`
  query notification {
    notificationPermission @client
  }
`;

export default function useNotification() {
  const client = useApolloClient();
  const requestPermission = useCallback(async () => {
    const permission = await window.Notification.requestPermission();
    client.writeQuery({
      query: notificationGql,
      data: {
        notificationPermission: permission,
      },
    });
  }, [client]);

  const showEvent = async ({ title, options, notificationClick }) => {
    if (!window) return;

    const notification = new window.Notification(title, options);
    notification.addEventListener('click', event => {
      event.preventDefault();
      notificationClick();
      window.focus();
    });
    const registration = await window.navigator.serviceWorker.ready;
    registration.showNotification(notification);
  };

  const show = async (title, message) => {
    if (!window) return;

    const notification = new window.Notification(title, message);

    const registration = await window.navigator.serviceWorker.ready;
    registration.showNotification(notification);
  };

  useEffect(() => {
    if (!window || window.Notification.permission === 'granted') return;
    requestPermission();
  }, []);

  return {
    show,
    showEvent,
  };
}
