import { useMemo, useEffect, useContext } from 'react';
import { getUserLang } from '@kiper/fns';
import useLoggedContext from './useCurrentLoggedContext';
import useNotification from './useNotification';
import logoShield from '../assets/images/logo-shield.png';
import { SocketContext } from '../services/socket';
import { actionRedisEvents, eventTypes } from '../constants';
import { EventAttendanceContext } from '../store';

export default function useAttendanceSocketConnection() {
  const { loggedContext } = useLoggedContext();

  const {
    setData,
    ACTIONS,
    setPopupData,
    handleClearPopupData,
    play,
    condominiumStatusListeners,
  } = useContext(EventAttendanceContext);

  const {
    subscribe,
    unsubscribe,
    sendMessage,
    error,
    removeListener,
    addListener,
  } = useContext(SocketContext);

  const { showEvent } = useNotification();
  const lang = useMemo(() => getUserLang().split('-')[0], []);

  const handleNewEvent = event => {
    const showAlert = event.showAlert || event.showPopUp;
    const myEvent = event?.ownerId === +loggedContext.personContextId;
    const isAnsweredCall = event?.eventType === eventTypes.answeredCall;
    if (showAlert) {
      const title = event.eventTypeDescription[lang];
      if (event?.place?.name) {
        title.concat(` - ${event.place.name}`);
      }
      showEvent({
        title,
        options: {
          body: event?.condominium?.name,
          icon: logoShield,
        },
        notificationClick: () => play.getEvent(event.eventId),
      });
    }

    setData({ type: ACTIONS.NEW, data: event });

    if (myEvent && isAnsweredCall)
      setPopupData({
        visible: true,
        eventId: event.eventId,
        condoName: event?.condominium?.name,
      });
  };

  const handleUpdateEvent = eventUpdated => {
    const myEvent = eventUpdated?.ownerId === +loggedContext.personContextId;
    const isAnsweredCall = eventUpdated?.eventType === eventTypes.answeredCall;

    setData({ type: ACTIONS.UPDATE, data: eventUpdated });

    if (!myEvent && !isAnsweredCall) handleClearPopupData();
  };

  const handlePopularEvents = redisEvents => {
    if (redisEvents.length) {
      setData({
        events: [...new Set(redisEvents.map(event => event.eventId))].map(id =>
          redisEvents.find(event => event.eventId === id),
        ),
        loadingQueue: false,
      });
    } else {
      setData({ loadingQueue: false });
    }
  };

  const handleRemoveEvent = event => {
    setData({
      type: ACTIONS.REMOVE,
      data: event,
    });
  };

  const onMessage = ({ action, item }) => {
    switch (action) {
      case actionRedisEvents.NEW_EVENT:
        handleNewEvent(item.event);
        break;

      case actionRedisEvents.UPDATE_EVENT:
        handleUpdateEvent(item.event);
        break;

      case actionRedisEvents.REMOVE_EVENT:
        handleRemoveEvent(item.event);
        break;

      case actionRedisEvents.LIST_EVENT:
        handlePopularEvents(item.events);
        break;

      case actionRedisEvents.UPDATE_CONDOMINIUM_STATUS:
        condominiumStatusListeners.current.forEach(fn => fn(item));
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    const socketConnected = () => {
      subscribe(loggedContext.personContextId, onMessage);
      sendMessage('attendentConnected', loggedContext.personContextId);
    };

    socketConnected();
    addListener('reconnect', socketConnected);

    return () => {
      removeListener('reconnect', socketConnected);
      unsubscribe(loggedContext.personContextId);
      sendMessage('attendentDisconnected', loggedContext.personContextId);
    };
  }, []);

  return {
    socketError: error,
  };
}
