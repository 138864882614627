import { useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import propTypes from 'prop-types';
import { Loader, Grid } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import HostInfo from './HostInfo';
import EventInfo from './EventInfo';
import GuestList from './GuestList';
import PageHeader from '../../../components/PageHeader';

export default function InviteDetails({ match, route }) {
  const [t] = useTranslation('invite');
  const { id } = match.params;
  const { loading, data } = useQuery(InviteDetails.inviteQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      inviteId: Number(id),
    },
  });
  const invite = useMemo(() => {
    if (!data) return { host: {}, event: {} };

    const stored = data.invite;

    return {
      host: {
        ...stored.host,
        condominium: stored && stored.condominium && stored.condominium.name,
        unityGroup: stored && stored.unityGroup && stored.unityGroup.name,
        unity: stored && stored.unity && stored.unity.name,
      },
      event: {
        name: stored.name,
        start: stored.start,
        end: stored.end,
        isFreeAccess: stored.isFreeAccess,
      },
    };
  }, [data]);

  if (loading || !data) return <Loader fullHeight />;

  return (
    <>
      <PageHeader
        breadcrumb={route.breadcrumb}
        t={t}
        title={invite.event.name}
      />

      <Grid.Row>
        <Grid.Col xs={7} separator={1}>
          <HostInfo host={invite.host} />
        </Grid.Col>
        <Grid.Col xs={5}>
          <EventInfo event={invite.event} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col xs={12}>
          <GuestList inviteId={Number(id)} />
        </Grid.Col>
      </Grid.Row>
    </>
  );
}

InviteDetails.inviteQuery = gql`
  query invite($inviteId: Int!) {
    invite(inviteId: $inviteId) {
      id
      name
      start
      end
      isFreeAccess
      accessProfiles {
        id
        name
        description
      }
      host {
        id
        name
        email
      }
      condominium {
        id
        name
      }
      unityGroup {
        name
      }
      unity {
        name
      }
    }
  }
`;

InviteDetails.propTypes = {
  match: propTypes.object.isRequired,
  route: propTypes.object.isRequired,
};
