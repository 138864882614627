import { Button } from '@kiper/ui';
import { useHistory } from 'react-router-dom';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

const AttendanceFallbackErrorBoundary = ({ backToRoute, error }) => {
  const history = useHistory();
  const [t] = useTranslation('guided-attendance');
  const handleBackToEventsList = () => history.push(backToRoute);
  return (
    <S.FallbackWrapper>
      <S.FallbackErrorMessage
        fontWeight="600"
        fontSize="32px"
        lineHeight="32px"
      >
        {t('error-boundary-fallback.message-title')}
      </S.FallbackErrorMessage>
      <S.FallbackErrorMessage
        fontWeight="500"
        fontSize="20px"
        lineHeight="24px"
      >
        {t('error-boundary-fallback.message-description')}
      </S.FallbackErrorMessage>
      <Button onClick={handleBackToEventsList}>
        {t('error-boundary-fallback.back-button')}
      </Button>
      {error?.message && (
        <S.FallbackErrorMessage
          fontWeight="400"
          fontSize="14px"
          lineHeight="14px"
        >
          {`[${error.message}]`}
        </S.FallbackErrorMessage>
      )}
    </S.FallbackWrapper>
  );
};

AttendanceFallbackErrorBoundary.propTypes = {
  error: propTypes.shape({
    message: propTypes.string,
  }),
  backToRoute: propTypes.string.isRequired,
};

AttendanceFallbackErrorBoundary.defaultProps = {
  error: { message: '' },
};

export default AttendanceFallbackErrorBoundary;
