import { apolloErrorHandler } from '@kiper/fns';
import { insertStepHistory } from '@kiper/monitoring-graphql/guided_attendance';
import { useContext } from 'react';
import { useMutation } from 'react-apollo';
import { useSwal } from '@kiper/hooks';
import { GuidedAttendanceContext } from '../../../../store';

const useMutations = () => {
  const { contextTree } = useContext(GuidedAttendanceContext);
  const { toast } = useSwal();

  const [stepHistory] = useMutation(insertStepHistory, {
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const handleInsertStepHistory = (value, eventId) => {
    const { stepGetSubtriage } = value;
    const stepId = contextTree?.currentStep?.id;
    const subtriageId = stepGetSubtriage?.id;

    const subtriage = {
      subtriageId,
      description: {
        pt: stepGetSubtriage?.description,
        en: stepGetSubtriage?.descriptionEn,
        es: stepGetSubtriage?.descriptionEs,
      },
    };
    return stepHistory({
      variables: {
        eventId,
        stepId,
        data: JSON.stringify(subtriage),
      },
    });
  };

  return { handleInsertStepHistory };
};

export default useMutations;
