import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { Label as label } from '@kiper/ui';

export const MainInfoWrapper = styled(Row)`
  width: 100%;
  height: 95px;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.colors.infoLight};
  border-radius: 10px;
  white-space: pre-line;
`;

export const MainInfo = styled(Col)`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const MainInfoImage = styled(Col)`
  height: 100%;
  position: relative;

  img {
    width: 100%;
    height: 140px;
    position: absolute;
    bottom: 0;
  }
`;

export const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const InfoLabel = styled(label)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #000000 70%;
`;
