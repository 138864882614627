import styled, { css } from 'styled-components';
import { Box } from '@kiper/ui';

export const Container = styled(Box)`
  margin-left: 16px;
  margin-top: 10px;
`;

export const MapContainer = styled(Box)`
  font-size: 20px;
  border: 1px solid ${props => props.theme.colors.secondary300};
  border-radius: 5px;
  background-size: cover;
  width: 500px;
  height: 500px;
  position: relative;
  ${props =>
    props.url &&
    css`
      background-image: url(${`${props.url}`});
    `}
`;
