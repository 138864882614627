import { Text } from '@kiper/ui';
import styled from 'styled-components';
import { Link as link } from 'react-router-dom';

export const Title = styled(Text)`
  font-family: 'Lato';
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.5px;
  color: ${props => props.theme.colors.highBlack};
`;

export const Link = styled(link)`
  color: ${props => props.theme.colors.mediumHighBlack};
  &:hover {
    color: ${props => props.theme.colors.primary500};
  }
`;
