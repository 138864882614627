import styled, { css } from 'styled-components';
import {
  Nav as nav,
  NavItem as navItem,
  NavLink as navLink,
  DropdownToggle as dropdownToggle,
} from 'reactstrap';

export const HeaderContainer = styled.div`
  height: 60px;
  display: flex;
  justify-content: flex-end;
  background-color: ${props => props.theme.colorNavbar};
  border: none;

  @media print {
    display: none;
    height: 0;
  }
`;

export const Nav = styled(nav)`
  justify-content: space-between;
  align-items: center;
`;

export const NavItem = styled(navItem)`
  padding: 0.5rem;
`;

export const NavLink = styled(navLink)`
  color: #fff;
`;

export const DropdownToggle = styled(dropdownToggle)`
  &.nav-link {
    color: #fff !important;
  }
`;

export const Announcekit = styled.div.attrs({
  className: 'announcekit-widget',
})`
  color: ${props => props.theme.colors.white};
  padding-right: 20px;
`;
