import propTypes from 'prop-types';
import { TableLoader } from '@kiper/ui';
import Pagination from '../Pagination';
import {
  TableCard,
  Table,
  Thead,
  Tr,
  Th,
  ColumnTitle,
  PaginationContainer,
} from './styles';
import { ReportListEmptyState } from '../ReportListEmptyState';

const ReportTable = ({
  loading,
  collection,
  columns,
  printing,
  pagination,
  filters,
  handlePageSize,
  handleNextPage,
  handlePreviousPage,
  row: Row,
}) => (
  <>
    {collection?.length ? (
      <>
        <TableCard print={+printing}>
          {!loading && (
            <Table className="table-hover">
              <Thead>
                <Tr>
                  {columns.map((i, index) => (
                    <Th key={index} width={i.width} printWidth={i.printWidth}>
                      <ColumnTitle>{i.name}</ColumnTitle>
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <tbody>
                {collection?.map((item, index) => (
                  <Row
                    data={item}
                    index={index}
                    columns={columns}
                    key={`${JSON.stringify(item)}-${index}`}
                    last={index === collection?.length - 1}
                  />
                ))}
              </tbody>
            </Table>
          )}
          {loading && <TableLoader />}
        </TableCard>
        {!printing && (
          <PaginationContainer>
            <Pagination
              filters={filters}
              pagination={pagination}
              handlePageSize={handlePageSize}
              next={handleNextPage}
              previous={handlePreviousPage}
            />
          </PaginationContainer>
        )}
      </>
    ) : (
      <>
        {!loading && <ReportListEmptyState />}
        {loading && <TableLoader />}
      </>
    )}
  </>
);

ReportTable.propTypes = {
  collection: propTypes.array.isRequired,
  columns: propTypes.array.isRequired,
  row: propTypes.any.isRequired,
  printing: propTypes.bool,
  pagination: propTypes.object.isRequired,
  filters: propTypes.object.isRequired,
  handlePageSize: propTypes.func.isRequired,
  handleNextPage: propTypes.func.isRequired,
  handlePreviousPage: propTypes.func.isRequired,
  loading: propTypes.bool.isRequired,
};

ReportTable.defaultProps = {
  printing: false,
};

export default ReportTable;
