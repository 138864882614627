import propTypes from 'prop-types';
import { Modal, Flex, KiperButton, Spinner, Text } from '@kiper/ui';
import { useTranslation, Trans } from 'react-i18next';
import { MdCropFree, MdOutlineWbSunny, MdPeople } from 'react-icons/md';
import { useMutation } from 'react-apollo';
import { mutation } from '@kiper/monitoring-graphql/access_facial';
import { apolloErrorHandler } from '@kiper/fns';
import { useSwal, useToggle } from '@kiper/hooks';
import { useState } from 'react';
import UserFacialFeedbackModal from './UserFacialFeedbackModal';
import { registerFacialStatus } from '../../../constants';
import facialImage from '../../../assets/images/facial/facial.svg';
import facialNotCapturedImage from '../../../assets/images/facial/facial-not-captured.svg';
import facialErrorImage from '../../../assets/images/facial/facial-error.svg';
import facialTimeoutImage from '../../../assets/images/facial/facial-timeout.svg';
import facialSuccessImage from '../../../assets/images/facial/facial-success.svg';

import {
  Img,
  Subtitle,
  RuleText,
  HatDisabledIcon,
  HatDisabledContainer,
  WidthIcon,
} from './styles';
import { useCurrentLoggedContext } from '../../../hooks';
import { sendAmplitudeData } from '../../../services/amplitude';

const UserFacialModal = ({
  open,
  onToggle,
  personId,
  deviceId,
  refetchDevices,
  isEdit,
}) => {
  const [t] = useTranslation('modals/user-facial-modal');
  const [feedbackData, setFeedbackData] = useState(null);
  const { loggedContext } = useCurrentLoggedContext();
  const [feedbackModal, toggleFeedbackModal] = useToggle();
  const { toast } = useSwal();

  const feedbackErrorWithMessageContent = {
    FACE_NOT_CAPTURED: {
      title: t('feedback.face-not-captured.title'),
      subtitle: t('feedback.face-not-captured.subtitle'),
      image: facialNotCapturedImage,
      rules: [
        {
          icon: <WidthIcon size={25} />,
          description: t('feedback.face-not-captured.rule1'),
        },
        {
          icon: <MdOutlineWbSunny size={25} />,
          description: t('feedback.face-not-captured.rule2'),
        },
      ],
    },
  };

  const handleFeedbackStatus = ({ status, message }) => {
    const feedback = {
      [registerFacialStatus.success]: () => {
        setFeedbackData({
          title: t('feedback.success.title'),
          subtitle: t('feedback.success.subtitle'),
          image: facialSuccessImage,
          status,
        });
        toggleFeedbackModal();
      },
      [registerFacialStatus.timeout]: () => {
        setFeedbackData({
          title: t('feedback.timeout.title'),
          subtitle: t('feedback.timeout.subtitle'),
          image: facialTimeoutImage,
          status,
        });
        toggleFeedbackModal();
      },
      [registerFacialStatus.error]: () => {
        setFeedbackData({
          title: t('feedback.error.title'),
          subtitle: t('feedback.error.subtitle'),
          image: facialErrorImage,
          status,
        });
        toggleFeedbackModal();
      },
      [registerFacialStatus.errorWithMessage]: () => {
        setFeedbackData({
          ...feedbackErrorWithMessageContent[message],
          message,
          status,
        });
        toggleFeedbackModal();
      },
    };

    switch (status) {
      case registerFacialStatus.success:
        return feedback.success();
      case registerFacialStatus.error:
        return feedback.error();
      case registerFacialStatus.timeout:
        return feedback.timeout();
      case registerFacialStatus.errorWithMessage:
        return feedback.errorWithMessage();
      default:
        return feedback.error();
    }
  };

  const [register, { loading }] = useMutation(
    mutation.registerFacialIdentification,
    {
      onCompleted: ({ registerFacialIdentification }) => {
        const { response } = registerFacialIdentification;
        sendAmplitudeData('feedback facial status', {
          user: loggedContext?.email,
          partner: loggedContext?.partner?.name,
          status: response.status,
          message: response.message,
        });
        handleFeedbackStatus(response);
      },
      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length) {
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
        }
      },
    },
  );

  const handleRegisterFacial = () => {
    register({
      variables: {
        personId,
        deviceId,
      },
    });
  };

  const handleCloseAllModals = () => {
    toggleFeedbackModal();
    onToggle();
  };

  return (
    <Modal
      title={isEdit ? t('title-update') : t('title-registration')}
      open={open}
      toggle={onToggle}
    >
      <Flex flexDirection="column" justifyContent="center" mb="36px">
        <Subtitle>{t('subtitle')}</Subtitle>
        <Flex flexDirection="row" justifyContent="space-evenly">
          <Img src={facialImage} alt="facialImage" />
          <Flex flexDirection="column" gridGap="16px">
            <Flex alignItems="center">
              <Flex p="6px" bg="gray50" alignItems="center" borderRadius="8px">
                <MdCropFree size={25} />
              </Flex>
              <RuleText>
                <Trans>{t('rule1')}</Trans>
              </RuleText>
            </Flex>
            <Flex alignItems="center">
              <Flex p="6px" bg="gray50" alignItems="center" borderRadius="8px">
                <MdPeople size={25} />
              </Flex>
              <RuleText>{t('rule2')}</RuleText>
            </Flex>
            <Flex alignItems="center">
              <HatDisabledContainer>
                <HatDisabledIcon size={25} />
              </HatDisabledContainer>
              <RuleText>{t('rule3')}</RuleText>
            </Flex>

            <KiperButton
              color="primary"
              onClick={handleRegisterFacial}
              disabled={!!loading}
              centered
            >
              {loading ? (
                <>
                  <Text mr="8px">{t('capturing')}</Text>
                  <Spinner />
                </>
              ) : (
                t('start-capture')
              )}
            </KiperButton>
          </Flex>
        </Flex>
        {feedbackModal && feedbackData && (
          <UserFacialFeedbackModal
            feedbackData={feedbackData}
            setFeedbackData={setFeedbackData}
            open={feedbackModal}
            onCloseAllModals={handleCloseAllModals}
            onToggle={toggleFeedbackModal}
            refetchDevices={refetchDevices}
          />
        )}
      </Flex>
    </Modal>
  );
};

export default UserFacialModal;

UserFacialModal.propTypes = {
  open: propTypes.bool.isRequired,
  onToggle: propTypes.func.isRequired,
  personId: propTypes.number.isRequired,
  deviceId: propTypes.number.isRequired,
  refetchDevices: propTypes.func.isRequired,
  isEdit: propTypes.bool.isRequired,
};
