import styled from 'styled-components';
import { Text } from '@kiper/ui';
import { FiList } from 'react-icons/fi';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-start;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  padding: 8px;
`;

export const ListIcon = styled(FiList)``;

export const Label = styled(Text)`
  display: block;
  white-space: break-spaces;
  word-break: break-word;
  line-break: auto;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400px;
  letter-spacing: 0.5px;
  line-height: 1.5;
  font-weight: 500;
  color: ${props => props.theme.colors.highBlack};
`;
