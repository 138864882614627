import styled from 'styled-components';
import { Flex, Text as text } from '@kiper/ui';

export const Container = styled(Flex)`
  gap: 24px;
  flex-direction: column;
`;

export const Title = styled(text)`
  color: ${props => props.theme.colors.blackShades(0.8)};
  font-family: Nunito Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
`;

export const Subtitle = styled(text)`
  color: ${props => props.theme.colors.blackShades(0.8)};
  text-align: center;
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

export const WrapperIcon = styled(Flex)`
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border: 1px solid ${props => props.theme.colors.secondary200};
  border-radius: 10px;
`;

export const Text = styled(text)`
  font-family: Nunito Sans;
  color: ${({ theme, opacity }) => theme.colors.blackShades(opacity || 0.8)};
  font-weight: ${({ weight }) => weight || 400};
  font-size: ${({ size }) => size || '14px'};
  text-align: ${({ align }) => align || 'start'};
`;
