import styled from 'styled-components';
import { Flex } from '@kiper/ui';

export const UnlinkContainer = styled(Flex)`
  border-radius: 8px;
  background: ${props => props.theme.colors.danger50};
  padding: 11px 16px;
  justify-content: space-between;
  align-items: center;
`;
