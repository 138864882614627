import PropTypes from 'prop-types';
import UsersReportForm from './UsersReportForm';
import EventsReportForm from './EventsReportForm';
import VehiclesReportForm from './VehiclesReportForm';

const Form = ({ type, ...restProps }) => {
  switch (type) {
    case 'usersReport':
      return <UsersReportForm {...restProps} type={type} />;
    case 'eventsReport':
      return <EventsReportForm {...restProps} type={type} />;
    case 'vehiclesReport':
      return <VehiclesReportForm {...restProps} type={type} />;
    default:
      return null;
  }
};

Form.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Form;
