import styled, { css } from 'styled-components';
import {
  KiperRadioCustomButton,
  Flex,
  KiperButton,
  Text,
  Input as input,
} from '@kiper/ui';

export const Container = styled(Flex)`
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & + & {
    margin-left: 26px;
  }
`;

export const Button = styled(KiperRadioCustomButton)``;

export const Input = styled.input`
  opacity: 0;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

export const Emoji = styled(KiperButton)`
  position: relative;
  cursor: pointer;
  height: auto;
  padding: 2px;
  opacity: 0.34;
  border-radius: 50%;

  span {
    font-size: 24px;
    z-index: 2;
  }

  &:before {
    content: '';
    height: 42px;
    width: 42px;
    border-radius: 100%;
    background: transparent;
    position: absolute;
    z-index: 1;
  }

  ${props =>
    props.checked &&
    css`
      opacity: 1;

      &:hover {
        background: transparent;
      }

      &:before {
        background-color: ${props.theme.colors.hoverGrey};
      }
    `}
`;

export const Title = styled(Text)`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 16px;
  letter-spacing: 0.5px;
  color: ${props => props.theme.colors.highBlack};
`;

export const Textarea = styled(input)`
  resize: none;
  white-space: break-spaces;
  word-break: break-word;
`;

export const Info = styled(Text)`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${props => props.theme.colors.highBlack};
`;
