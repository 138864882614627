import { useState } from 'react';
import { useWindowDimensions } from '@kiper/hooks';
import { FiPhoneOff } from 'react-icons/fi';
import {
  MdOutlineCheck,
  MdOutlineClose,
  MdOutlineDoorBack,
} from 'react-icons/md';
import { callStatus } from '../../../../constants';

const useActionButtons = ({ setFieldValue, values, t }) => {
  const [selectedCallStatus, setSelectedCallStatus] = useState(false);
  const { width: windowsWidth } = useWindowDimensions();

  const handleChange = value => {
    setFieldValue('stepAttendanceCallDweller.callStatus', value);
    setSelectedCallStatus(true);
  };

  const actionButtons = [
    {
      value: callStatus.UNAVAILABLE_CONTACTS,
      description: t('step-call-dwellers.couldnt-contact'),
      icon: windowsWidth > 1366 ? <FiPhoneOff /> : null,
      checked:
        values?.stepAttendanceCallDweller?.callStatus ===
        callStatus.UNAVAILABLE_CONTACTS,
      action: value => handleChange(value),
    },
    {
      value: callStatus.AT_THE_CONCIERGE,
      description: t('step-call-dwellers.dweller-went-down'),
      icon: windowsWidth > 1366 ? <MdOutlineDoorBack /> : null,
      checked:
        values?.stepAttendanceCallDweller?.callStatus ===
        callStatus.AT_THE_CONCIERGE,
      action: value => handleChange(value),
    },
    {
      value: callStatus.NOT_AUTHORIZED,
      description: t('step-call-dwellers.not-authorized'),
      icon: windowsWidth > 1366 ? <MdOutlineClose /> : null,
      checked:
        values?.stepAttendanceCallDweller?.callStatus ===
        callStatus.NOT_AUTHORIZED,
      action: value => handleChange(value),
    },
    {
      value: callStatus.AUTHORIZED,
      description: t('step-call-dwellers.authorized'),
      icon: windowsWidth > 1366 ? <MdOutlineCheck /> : null,
      checked:
        values?.stepAttendanceCallDweller?.callStatus === callStatus.AUTHORIZED,
      action: value => handleChange(value),
    },
  ];

  return { actionButtons, selectedCallStatus };
};

export default useActionButtons;
