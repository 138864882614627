import { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { queries } from '@kiper/monitoring-graphql/condominium/detail';
import Select from './styles';

const AccessProfileSelect = ({
  isClearable,
  invalid,
  value,
  sourceNodeId,
  isDisabled,
  isEdition,
  onChange,
  ...props
}) => {
  const [accessProfiles, setAccessProfiles] = useState([]);

  const { data, loading } = useQuery(queries.accessProfilesByTreeNodeId, {
    fetchPolicy: 'network-only',
    skip: !sourceNodeId,
    variables: {
      sourceNodeId,
    },
  });

  const toSelectValue = accessProfile => {
    const { id, name } = accessProfile;
    if (!id || !name) return null;
    return {
      value: id,
      label: name,
    };
  };

  const setInitialValueOnEdit = accessProfileOptions => {
    const initialOptionValue = accessProfileOptions.find(
      accessProfile => accessProfile?.value === value,
    );
    if (initialOptionValue) onChange(initialOptionValue);
  };

  useEffect(() => {
    const { accessProfilesByTreeNodeId } = data || {};
    if (accessProfilesByTreeNodeId?.length) {
      const accessProfileOptions = accessProfilesByTreeNodeId.map(
        toSelectValue,
      );
      setAccessProfiles(accessProfileOptions);

      if (isEdition && accessProfileOptions?.length)
        setInitialValueOnEdit(accessProfileOptions);
    }
  }, [data, isEdition]);

  const getValue = val => {
    if (typeof val === 'number')
      return accessProfiles?.filter(
        accessProfile => accessProfile?.value === val,
      );
    return val;
  };

  return (
    <Select
      {...props}
      onChange={onChange}
      value={getValue(value)}
      isClearable={isClearable}
      isLoading={loading}
      options={accessProfiles}
      invalid={invalid}
      isDisabled={!sourceNodeId || isDisabled}
    />
  );
};

AccessProfileSelect.propTypes = {
  onChange: propTypes.func.isRequired,
  value: propTypes.any,
  isClearable: propTypes.bool,
  invalid: propTypes.bool,
  sourceNodeId: propTypes.number.isRequired,
  isDisabled: propTypes.bool,
  isEdition: propTypes.bool,
};

AccessProfileSelect.defaultProps = {
  isClearable: false,
  isEdition: false,
  invalid: false,
  value: null,
  isDisabled: false,
};

export default AccessProfileSelect;
