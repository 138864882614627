import EventGenerator from './EventGenerator';

const breadcrumb = (label, to) => ({ label, to });
const requiredPermissions = ['systemSettings'];

export default [
  {
    name: 'Event generator',
    path: '/gerador-de-eventos',
    exact: true,
    component: EventGenerator,
    breadcrumb: [
      breadcrumb('common:breadcrumb.event-generator', '/gerador-de-eventos'),
    ],
    requiredPermissions,
  },
];
