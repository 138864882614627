import { Fragment, useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { useLazyQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { Flex, Spinner, Text, KiperButton, MdIcons } from '@kiper/ui';
import { query } from '@kiper/monitoring-graphql/device';
import { BiChip } from 'react-icons/bi';
import CommandsDevice from '../../CommandsDevice/CommandsDevice';
import { doorStatusTypes } from '../../../constants';
import { IOT_COMMANDS, RELAYS_TYPES } from './settings';
import {
  useAttendanceRemoteConfig,
  useCurrentLoggedContext,
} from '../../../hooks';
import { useCommandPartition, useCommandRelay } from './hooks';
import { CommandPartitionActions } from './components';
import * as S from './styles';

const IotModalItem = ({ eventId, deviceId, deviceName, type, isOnline }) => {
  const { loggedContext } = useCurrentLoggedContext();

  const [device, setDevice] = useState(null);
  const [relayStatuses, setRelayStatuses] = useState({ pgms: [] });
  const { t } = useTranslation('modals/iot-modal');

  const { showAlarmPartitionCommands } = useAttendanceRemoteConfig(
    loggedContext,
  );

  const { handleCommandRelay } = useCommandRelay({ eventId });

  const {
    handleCommandPartition,
    loadingPartitionCommand,
  } = useCommandPartition({
    eventId,
    deviceId,
  });

  const isAlarmDevice = type === RELAYS_TYPES.ALARM;

  const [fetchRelayStatuses, { loading: relayStatusLoading }] = useLazyQuery(
    query.getRelayStatusCommand,
    {
      fetchPolicy: 'no-cache',
      variables: {
        eventId,
        deviceId,
      },
      onCompleted: data => setRelayStatuses(data.relayStatusCommand),
    },
  );

  const [fetchDevice, { loading }] = useLazyQuery(query.getDevice, {
    fetchPolicy: 'no-cache',
    variables: {
      deviceId,
    },
    onCompleted: data => setDevice(data.device),
  });

  useEffect(() => {
    fetchDevice();
    const deviceInterval = setInterval(() => fetchDevice(), 5000);

    let relayInterval = null;
    if (isAlarmDevice) {
      fetchRelayStatuses();
      relayInterval = setInterval(() => fetchRelayStatuses(), 5000);
    }

    return () => {
      clearInterval(deviceInterval);
      if (relayInterval) {
        clearInterval(relayInterval);
      }
    };
  }, []);

  const relaysIot = () => {
    if (
      device?.serializedParams &&
      JSON.parse(device.serializedParams)?.relays
    ) {
      const relaysObject = JSON.parse(device.serializedParams)?.relays;
      const iotOperationCommandsObject = JSON.parse(device.serializedParams)
        ?.iotOperationCommands;
      const status = JSON.parse(device.serializedParams)?.status?.relays;

      const relayStatus = ctx => {
        if (isOnline)
          return status && status[ctx]
            ? doorStatusTypes.On
            : doorStatusTypes.Off;
        return doorStatusTypes.Offline;
      };

      return Object.keys(relaysObject)?.reduce((acc, ctx) => {
        acc.push({
          id: ctx,
          description: relaysObject[ctx]?.description,
          status: relayStatus(ctx),
          enableCommands:
            iotOperationCommandsObject && iotOperationCommandsObject[ctx],
        });

        return acc;
      }, []);
    }
    return [];
  };

  const getAlarmRelayStatus = relayId => {
    let status = doorStatusTypes.Off;

    if (relayStatuses && relayStatuses.pgms) {
      relayStatuses.pgms.forEach(pgm => {
        if (Number(relayId) === pgm.number && pgm.status) {
          status = doorStatusTypes.On;
        }
      });
    }

    return status;
  };

  const relaysAlarm = () => {
    if (device?.serializedParams) {
      const contactIdConfiguration = JSON.parse(device.serializedParams)
        ?.contactIdConfiguration;
      if (contactIdConfiguration?.pgmsConfig) {
        const relaysObject = contactIdConfiguration?.pgmsConfig;

        return Object.keys(relaysObject)?.reduce((acc, ctx) => {
          acc.push({
            id: relaysObject[ctx]?.code,
            description: relaysObject[ctx]?.description,
            status: getAlarmRelayStatus(relaysObject[ctx]?.code),
          });

          return acc;
        }, []);
      }
    }
    return [];
  };

  const relays = useMemo(() => {
    switch (type) {
      case RELAYS_TYPES.ALARM:
        return relaysAlarm();
      case RELAYS_TYPES.IOT:
        return relaysIot();
      default:
        return [];
    }
  }, [device, relayStatuses]);

  const handleCommand = ({ command, relayId }) =>
    handleCommandRelay({
      command,
      deviceId,
      relayId,
      type,
    });

  const handleChangeCommandPartition = ({ command }) =>
    handleCommandPartition({
      command,
    });

  const commandsOptions = ({ relayId, disabled }) => ({
    turnOnCommand: {
      label: t('turn-on'),
      icon: <MdIcons mdIconName="lock_open_right" size="24" />,
      disabled: disabled || !isOnline,
      onClick: () => handleCommand({ command: IOT_COMMANDS.OPEN, relayId }),
    },
    pulseCommand: {
      label: t('pulse'),
      icon: <MdIcons mdIconName="escalator" size="24" />,
      disabled: disabled || !isOnline,
      onClick: () =>
        handleCommand({ command: IOT_COMMANDS.OPEN_KEEP, relayId }),
    },
    turnOffCommand: {
      label: t('turn-off'),
      icon: <MdIcons mdIconName="lock" size="24" />,
      disabled: disabled || !isOnline,
      onClick: () => handleCommand({ command: IOT_COMMANDS.CLOSE, relayId }),
    },
  });

  const sortedListCommands = ({ relayId, enableCommands }) => {
    const [turnOn, pulse, turnOff] = [
      commandsOptions({
        relayId,
        disabled: enableCommands && !enableCommands.turnOnIot,
      }).turnOnCommand,
      commandsOptions({
        relayId,
        disabled: enableCommands && !enableCommands.pulseIot,
      }).pulseCommand,
      commandsOptions({
        relayId,
        disabled: enableCommands && !enableCommands.turnOffIot,
      }).turnOffCommand,
    ];

    let sortedList = [turnOn, pulse, turnOff];

    const allCommandsEnabled =
      enableCommands?.turnOnIot &&
      enableCommands?.pulseIot &&
      enableCommands?.turnOffIot;

    const allCommandsDisabled =
      !enableCommands?.turnOnIot &&
      !enableCommands?.pulseIot &&
      !enableCommands?.turnOffIot;

    const showAllCommands = allCommandsEnabled || allCommandsDisabled;

    if (!enableCommands || showAllCommands) {
      const secondaryCommands = [sortedList[1], sortedList[2]];

      return {
        primary: sortedList[0],
        secondary: secondaryCommands,
      };
    }

    const withoutTurnOnCommand = !enableCommands.turnOnIot;
    const withoutPulseCommand = !enableCommands.pulseIot;
    const withoutTurnOffCommand = !enableCommands.turnOffIot;

    const onlyTurnOnCommand = withoutPulseCommand && withoutTurnOffCommand;
    const onlyPulseCommand = withoutTurnOnCommand && withoutTurnOffCommand;
    const onlyTurnOffCommand = withoutTurnOnCommand && withoutPulseCommand;

    if (withoutTurnOnCommand) sortedList = [pulse, turnOff];
    if (withoutPulseCommand) sortedList = [turnOn, turnOff];
    if (withoutTurnOffCommand) sortedList = [turnOn, pulse];
    if (onlyTurnOnCommand) sortedList = [turnOn];
    if (onlyPulseCommand) sortedList = [pulse];
    if (onlyTurnOffCommand) sortedList = [turnOff];

    const [primary, ...secondary] = sortedList;

    return { primary, secondary };
  };

  const forceFetchStatuses = () => {
    fetchDevice();

    if (isAlarmDevice) {
      fetchRelayStatuses();
    }
  };

  const primaryCommand = relay =>
    sortedListCommands({
      relayId: relay.id,
      enableCommands: relay.enableCommands,
    }).primary;

  const secondaryCommand = relay =>
    sortedListCommands({
      relayId: relay.id,
      enableCommands: relay.enableCommands,
    }).secondary;

  return (
    <S.IotItemWrapper>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex justifyContent="flex-start" alignItems="center" gridGap="8px">
          <Text fontSize="16px" fontWeight="600">
            {deviceName}
          </Text>
          {showAlarmPartitionCommands && isAlarmDevice && (
            <CommandPartitionActions
              t={t}
              loadingPartitionCommand={loadingPartitionCommand}
              onChangeCommandPartition={handleChangeCommandPartition}
            />
          )}
        </Flex>
        <KiperButton
          variant="text"
          onClick={() => forceFetchStatuses()}
          loading={loading || relayStatusLoading}
          disabled={loading || relayStatusLoading}
        >
          {loading || relayStatusLoading ? t('updating') : t('update-status')}
        </KiperButton>
      </Flex>

      {(loading || relayStatusLoading) && !relays.length && (
        <S.WrapperSpinner>
          <Spinner size={48} />
        </S.WrapperSpinner>
      )}

      {!!relays.length && (
        <S.WrapperRelays>
          {relays.map(
            relay =>
              relay?.description && (
                <Fragment key={relay.id}>
                  <CommandsDevice
                    key={relay.id}
                    t={t}
                    handleDevice={command =>
                      handleCommand({ command, relayId: relay.id })
                    }
                    primaryCommand={primaryCommand(relay)}
                    secondaryCommands={secondaryCommand(relay)}
                    icon={<BiChip size={20} />}
                    device={{
                      id: relay,
                      name: `${t('relay')}: ${relay.description}`,
                    }}
                    doorStatus={relay.status}
                    justifyContent="space-between"
                  />
                </Fragment>
              ),
          )}
        </S.WrapperRelays>
      )}
    </S.IotItemWrapper>
  );
};

export default IotModalItem;

IotModalItem.propTypes = {
  eventId: propTypes.string,
  deviceId: propTypes.number.isRequired,
  deviceName: propTypes.string.isRequired,
  type: propTypes.string.isRequired,
  isOnline: propTypes.bool,
};

IotModalItem.defaultProps = {
  eventId: null,
  isOnline: true,
};
