import styled from 'styled-components';

export const AccessProfileContainer = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    margin: 0 2px;
    padding: 0 3px;
  }
`;
