import styled, { css } from 'styled-components';

export const DeviceCounterContainer = styled.div`
  border-radius: 5px;

  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px 5px 10px;

  ${({ transparent }) =>
    transparent
      ? ''
      : css`
          border: solid 1px ${props => props.theme.colors.secondary300};
          background-color: ${props => props.theme.colors.secondary100};
        `}
`;
