import { useMemo } from 'react';
import { Label, Input, ErrorMessage, Button, Select } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';
import propTypes from 'prop-types';
import { systemSettings as gqlOperations } from '@kiper/monitoring-graphql';
import { useMutation } from 'react-apollo';
import { removeProperties, apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import Modal from '../../../../../components/Modal';
import { FormGroup, Form, Alert, AlertIcon, ModalActions } from './styles';

const MESSAGE_PREFIX = 'details.imageSettings.modal';

const AddDigifortServerModal = ({ toggle, opened, data, refetch, id }) => {
  const [t] = useTranslation('system-settings');
  const { toast } = useSwal();

  const onCompleted = operation => {
    toast.fire({
      title: t(`details.imageSettings.${operation}-success`),
      icon: 'success',
    });
  };

  const onError = err => {
    const formattedErrors = apolloErrorHandler(err);
    if (formattedErrors && formattedErrors.length) {
      toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
  };

  const [onSave, { loading: saveLoading }] = useMutation(
    gqlOperations.createVideoSetting,
    {
      onCompleted: () => onCompleted('save'),
      onError,
    },
  );

  const [onUpdate, { loading: updateLoading }] = useMutation(
    gqlOperations.updateVideoSetting,
    {
      onCompleted: () => onCompleted('update'),
      onError,
    },
  );

  const loading = saveLoading || updateLoading;

  const onSubmit = async values => {
    const formatedValues = {
      ...values,
      userName: values.userName.trim(),
      password: values.password.trim(),
    };

    if (data?.id) {
      await onUpdate({
        variables: {
          id: data.id,
          data: {
            serializedParams: {
              ...removeProperties(formatedValues, ['id', '__typename']),
              gmt: formatedValues.gmt.value,
            },
          },
        },
      });
    } else {
      await onSave({
        variables: {
          data: {
            serializedParams: {
              ...formatedValues,
              gmt: formatedValues.gmt.value,
            },
          },
        },
      });
    }
    toggle();
    refetch();
  };

  const validationSchema = yup.object({
    baseUrl: yup
      .string()
      .required(t(`${MESSAGE_PREFIX}.inputs.address.validation`)),
    userName: yup
      .string()
      .required(t(`${MESSAGE_PREFIX}.inputs.user.validation`)),
    password: yup
      .string()
      .required(t(`${MESSAGE_PREFIX}.inputs.password.validation`)),
    gmt: yup
      .object({
        value: yup.number(),
        label: yup.string(),
      })
      .required(t(`${MESSAGE_PREFIX}.inputs.timezone.validation`)),
  });

  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step,
    );

  const getTimezoneLabel = val =>
    val === 0 ? 'GMT' : val > 0 ? `UTC +${val}` : `UTC ${val}`; //eslint-disable-line

  const timezones = useMemo(() =>
    range(-11, 14, 1).map(i => ({
      label: getTimezoneLabel(i),
      value: i,
    })),
  );

  const formikBag = useFormik({
    initialValues: data
      ? { ...data, gmt: timezones.find(i => i.value === data.gmt) }
      : {
          baseUrl: '',
          userName: '',
          password: '',
          gmt: { value: -3, label: 'UTC -3' },
        },
    validationSchema,
    onSubmit,
  });

  const { handleSubmit, values, errors, touched, setFieldValue } = formikBag;

  return (
    <Modal
      title={t(`${MESSAGE_PREFIX}.title`)}
      toggle={toggle}
      open={opened}
      id={id}
    >
      <FormikProvider value={formikBag}>
        <Form className="form" onSubmit={handleSubmit} noValidate>
          <Alert id="video-settings-modal-tip">
            <AlertIcon />
            <Label>{t(`${MESSAGE_PREFIX}.tip`)}</Label>
          </Alert>
          <FormGroup>
            <Label>{t(`${MESSAGE_PREFIX}.inputs.address.label`)}</Label>
            <Input
              type="text"
              className="form-control"
              placeholder={t(`${MESSAGE_PREFIX}.inputs.address.placeholder`)}
              name="baseUrl"
              value={values.baseUrl}
              onChange={setFieldValue}
              invalid={touched.baseUrl && !!errors.baseUrl}
            />
            {touched.baseUrl && errors.baseUrl && (
              <ErrorMessage>{errors.baseUrl}</ErrorMessage>
            )}
          </FormGroup>
          <FormGroup>
            <Label>{t(`${MESSAGE_PREFIX}.inputs.user.label`)}</Label>
            <Input
              type="text"
              className="form-control"
              placeholder={t(`${MESSAGE_PREFIX}.inputs.user.placeholder`)}
              name="userName"
              value={values.userName}
              onChange={setFieldValue}
              invalid={touched.userName && !!errors.userName}
            />
            {touched.userName && errors.userName && (
              <ErrorMessage>{errors.userName}</ErrorMessage>
            )}
          </FormGroup>
          <FormGroup>
            <Label>{t(`${MESSAGE_PREFIX}.inputs.password.label`)}</Label>
            <Input
              type="password"
              className="form-control"
              placeholder={t(`${MESSAGE_PREFIX}.inputs.password.placeholder`)}
              name="password"
              value={values.password}
              onChange={setFieldValue}
              invalid={touched.password && !!errors.password}
            />
            {touched.password && errors.password && (
              <ErrorMessage>{errors.password}</ErrorMessage>
            )}
          </FormGroup>
          <FormGroup>
            <Label>{t(`${MESSAGE_PREFIX}.inputs.timezone.label`)}</Label>
            <Select
              id="video-settings-modal-timezone-select"
              placeholder={t(`${MESSAGE_PREFIX}.inputs.timezone.placeholder`)}
              value={values.gmt}
              isLoading={loading}
              options={timezones}
              onChange={obj => setFieldValue('gmt', obj)}
            />
          </FormGroup>
          <ModalActions>
            <Button
              color="secondary"
              outline
              className="mr-3"
              type="button"
              onClick={toggle}
            >
              {t('buttons:cancel')}
            </Button>

            <Button
              id="video-settings-modal-save-button"
              type="submit"
              loading={loading}
              color="primary"
              disabled={loading}
            >
              {loading ? t('buttons:saving') : t('buttons:save')}
            </Button>
          </ModalActions>
        </Form>
      </FormikProvider>
    </Modal>
  );
};

AddDigifortServerModal.propTypes = {
  id: propTypes.string.isRequired,
  toggle: propTypes.func.isRequired,
  opened: propTypes.bool.isRequired,
  refetch: propTypes.func.isRequired,
  data: propTypes.object,
};

AddDigifortServerModal.defaultProps = {
  data: null,
};

export default AddDigifortServerModal;
