import styled from 'styled-components';
import { Label as label } from '@kiper/ui';

export const Label = styled(label)`
  margin: 0;
  font-size: 14px;
`;

export const DivBlockedName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;

  ${Label} {
    text-align: left;
    font-size: 11px;
  }
`;

export const Line = styled.tr``;
