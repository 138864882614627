import { useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import { firstBy } from 'thenby';
import { useTranslation, Trans } from 'react-i18next';
import { useTheme } from 'styled-components';
import { FiPhone } from 'react-icons/fi';
import { MdOutlineAccessTime } from 'react-icons/md';
import { KiperButton, Text, Flex } from '@kiper/ui';
import { phoneMask } from '@kiper/fns';
import logoMobile from '../../../../assets/images/mobile.webp';
import {
  profileTypes,
  profileColor,
  destinationTypes,
  contactTypes,
} from '../../../../constants';
import UserInfoVertical from '../../../UserInfoVertical';
import {
  UserDetailContainer,
  ObservationText,
  AllowedAccessUserMessage,
  InfoWrapper,
} from '../styles';

function UsersHostsDetail({
  data,
  handleClickToCall,
  handleDebounce,
  loading,
  intercomNumber,
  priority,
  observation,
  allowedAccesses,
  colsSpan,
}) {
  const [t] = useTranslation('user');
  const theme = useTheme();
  const [allowedAccessTimeInfo, setAllowedAccessTimeInfo] = useState('');

  const additionalContacts = useMemo(() => {
    return (
      data?.additionalContacts?.filter(
        contact => contact.contactTypeId === contactTypes.phone,
      ) || []
    );
  }, [data]);

  const allowedAccessInfo = () => {
    let defaultMessage = t('list.allow-access-time-info.start-message');

    if (allowedAccesses?.today?.length) {
      defaultMessage += t('list.allow-access-time-info.today-message');

      allowedAccesses.today
        .sort(firstBy('startTime', 'asc'))
        .forEach((access, index, self) => {
          if (index === self.length - 1) {
            defaultMessage += t(
              'list.allow-access-time-info.time-access-last-of-list',
              { startTime: access.startTime, endTime: access.endTime },
            );
          } else {
            defaultMessage += t('list.allow-access-time-info.time-access', {
              startTime: access.startTime,
              endTime: access.endTime,
            });
          }
        });
    }

    if (allowedAccesses?.tomorrow?.length) {
      defaultMessage += t('list.allow-access-time-info.tomorrow-message');

      allowedAccesses.tomorrow
        .sort(firstBy('startTime', 'asc'))
        .forEach((access, index, self) => {
          if (index === self.length - 1) {
            defaultMessage += t(
              'list.allow-access-time-info.time-access-last-of-list',
              { startTime: access.startTime, endTime: access.endTime },
            );
          } else {
            defaultMessage += t('list.allow-access-time-info.time-access', {
              startTime: access.startTime,
              endTime: access.endTime,
            });
          }
        });
    }

    setAllowedAccessTimeInfo(defaultMessage);
  };

  useEffect(() => allowedAccessInfo(), []);

  const hasAllowedAccess =
    allowedAccesses &&
    (!!allowedAccesses?.today?.length || !!allowedAccesses?.tomorrow?.length);

  const showGadget = useMemo(() => {
    if (!data?.gadget) return false;
    const { appOs, appName, appVersion } = data?.gadget;
    if (!appOs || !appName || !appVersion) return false;

    return true;
  }, [data?.gadget]);

  const handleGadgetDescription = gadget => {
    const { appOs, appName, appVersion } = gadget;

    return `<strong>OS:</strong> ${appOs}  <strong>v.${appVersion}</strong> <br /> <strong>App:</strong> ${appName}`;
  };

  return (
    <UserDetailContainer colSpan={colsSpan}>
      <Flex>
        <Flex
          my="10px"
          px="24px"
          borderRight={`2px solid ${theme.colors.secondary300}`}
          justifyContent="center"
          alignItems="center"
          width="280px"
          flexDirection="column"
          gridGap="8px"
        >
          {data?.personName && data?.profileFieldName && (
            <UserInfoVertical
              name={data.personName}
              email={data.personEmail}
              profileName={t(
                `common:profiles.${profileTypes[data.profileFieldName]}`,
              )}
              profileColor={profileColor[data.profileFieldName]}
              id={data.personContextId}
            />
          )}
          {showGadget && (
            <InfoWrapper>
              <img src={logoMobile} height={28} alt="mobile" />
              <Flex flexDirection="column">
                <Text fontWeight="bolder">{t('put.devices.app')}</Text>
                <Trans>
                  <Text>{handleGadgetDescription(data.gadget)}</Text>
                </Trans>
              </Flex>
            </InfoWrapper>
          )}
          {hasAllowedAccess && (
            <InfoWrapper>
              <MdOutlineAccessTime size={28} />
              <AllowedAccessUserMessage>
                <Trans>{allowedAccessTimeInfo}</Trans>
              </AllowedAccessUserMessage>
            </InfoWrapper>
          )}
        </Flex>
        <Flex pl="20px" width="100%" flexDirection="column" flex={1}>
          <Flex
            width="100%"
            alignItems="baseline"
            maxWidth="545px"
            overflow="hidden"
            flexWrap="wrap"
          >
            <Flex flexDirection="column">
              <Text mb="5px" color="secondary600" fontSize="14px">
                {t('put.priority')}
              </Text>
              <Text mr="4px" fontSize="14px">
                {priority || '-'}
              </Text>
            </Flex>

            <Flex
              ml="30px"
              mr="30px"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Flex mr="16px" flexDirection="column" id="phone-container">
                <Text mb="5px" color="secondary600" fontSize="14px">
                  {t('put.intercom')}
                </Text>
                {intercomNumber ? (
                  <Text mr="4px" fontSize="14px" truncate>
                    {intercomNumber}
                  </Text>
                ) : (
                  '-'
                )}
              </Flex>
              {intercomNumber && (
                <KiperButton
                  size="sm"
                  disabled={loading === 'intercom-phone'}
                  variant="out"
                  rounded
                  icon={<FiPhone />}
                  onClick={() => {
                    handleClickToCall({
                      destination: intercomNumber,
                      destinationKind: destinationTypes.intercomAta,
                    });
                    handleDebounce('intercom-phone');
                  }}
                />
              )}
            </Flex>

            <Flex
              mt="16px"
              mr="30px"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Flex mr="16px" flexDirection="column" id="phone-container">
                <Text mb="5px" color="secondary600" fontSize="14px">
                  {t('put.phone')}
                </Text>
                <Text truncate mr="4px" fontSize="14px">
                  {data?.personPhone
                    ? phoneMask(data?.personPhone).formatted
                    : '-'}
                </Text>
              </Flex>
              {data?.personPhone && (
                <KiperButton
                  size="sm"
                  disabled={loading === 'person-phone'}
                  variant="out"
                  rounded
                  icon={<FiPhone />}
                  onClick={() => {
                    handleClickToCall({
                      destination: data?.personPhone,
                      destinationKind: destinationTypes.externalPhone,
                    });
                    handleDebounce('person-phone');
                  }}
                />
              )}
            </Flex>

            <Flex
              justifyContent="center"
              alignItems="flex-start"
              mt="16px"
              mr="30px"
            >
              <Flex mr="16px" flexDirection="column" id="phone-container">
                <Text mb="5px" color="secondary600" fontSize="14px">
                  {t('event_attendance:second-phone')}
                </Text>
                <Text truncate mr="4px" fontSize="14px">
                  {additionalContacts.length
                    ? phoneMask(additionalContacts[0]?.contact).formatted
                    : '-'}
                </Text>
              </Flex>
              {!!additionalContacts.length && (
                <KiperButton
                  size="sm"
                  disabled={loading === 'additional-phone'}
                  variant="out"
                  rounded
                  icon={<FiPhone />}
                  onClick={() => {
                    handleClickToCall({
                      destination: additionalContacts[0]?.contact,
                      destinationKind: destinationTypes.externalPhone,
                    });
                    handleDebounce('additional-phone');
                  }}
                />
              )}
            </Flex>
          </Flex>
          <Flex mr="15px" mt="16px">
            <Flex flexDirection="column">
              <Text mb="5px" color="secondary600" fontSize="14px">
                {t('put.email')}
              </Text>
              <Text mr="4px" fontSize="14px">
                {data.personEmail || '-'}
              </Text>
            </Flex>
          </Flex>
          {data?.personDocuments?.length && (
            <Flex mr="15px" mt="16px">
              {data?.personDocuments?.map(item => (
                <Flex
                  key={item.documentTypeName}
                  mr="15px"
                  flexDirection="column"
                >
                  <Text mb="5px" color="secondary600" fontSize="14px">
                    {item.documentTypeName}
                  </Text>
                  <Text mr="4px" fontSize="14px">
                    {item.value || '-'}
                  </Text>
                </Flex>
              ))}
            </Flex>
          )}
          <Flex mr="15px" mt="16px">
            <Flex flexDirection="column">
              <Text mb="5px" color="secondary600" fontSize="14px">
                {t('put.observation')}
              </Text>
              <ObservationText>{observation || '-'}</ObservationText>
            </Flex>
          </Flex>
          <Flex mr="15px" my="16px">
            <Text mr="15px" fontSize="12px" fontWeight="bold">
              {t('put.date')}
            </Text>
            <Text color="secondary600" fontSize="12px">
              {data?.personContextCreationDate
                ? moment(data?.personContextCreationDate).format(
                    'DD/MM/YYYY - hh:mm',
                  )
                : '-'}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </UserDetailContainer>
  );
}

export default UsersHostsDetail;

UsersHostsDetail.propTypes = {
  handleClickToCall: propTypes.func,
  handleDebounce: propTypes.func,
  loading: propTypes.any,
  intercomNumber: propTypes.string,
  priority: propTypes.number,
  colsSpan: propTypes.number.isRequired,
  observation: propTypes.string,
  allowedAccesses: propTypes.shape({
    today: propTypes.array,
    tomorrow: propTypes.array,
  }),
  data: propTypes.shape({
    name: propTypes.string,
    email: propTypes.string,
    phone: propTypes.string,
    profileId: propTypes.number,
    profileName: propTypes.string,
    personName: propTypes.string,
    personDocuments: propTypes.arrayOf(
      propTypes.shape({
        documentTypeName: propTypes.string,
        value: propTypes.string,
      }),
    ),
    personEmail: propTypes.string,
    personPhone: propTypes.string,
    profileFieldName: propTypes.string,
    personContextCreationDate: propTypes.string,
    additionalContacts: propTypes.array,
    profile: propTypes.shape({
      fieldName: propTypes.string,
    }),
    personContextId: propTypes.number,
    condominium: propTypes.shape({
      name: propTypes.string,
    }),
    unityGroup: propTypes.shape({
      name: propTypes.string,
      serializedParams: propTypes.object,
    }),
    unity: propTypes.shape({
      name: propTypes.string,
      serializedParams: propTypes.object,
    }),
    gadget: propTypes.shape({
      appOs: propTypes.string,
      appVersion: propTypes.string,
      appName: propTypes.string,
    }),
  }).isRequired,
};

UsersHostsDetail.defaultProps = {
  handleClickToCall: null,
  handleDebounce: null,
  loading: false,
  intercomNumber: null,
  priority: null,
  observation: null,
  allowedAccesses: null,
};
