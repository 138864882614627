import styled, { css } from 'styled-components';
import { Flex, Box, Text as text } from '@kiper/ui';

export const Container = styled(Flex)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const MapContainer = styled(Box)`
  font-size: 20px;
  border: 1px solid ${props => props.theme.colors.secondary300};
  border-radius: 8px;
  background-size: cover;
  height: 316px;
  width: 316px;
  position: relative;
  ${props =>
    props.url &&
    css`
      background-image: url(${`${props.url}`});
    `}

  @media screen and (min-width: 1920px) {
    height: 500px;
    width: 500px;
  }
`;

export const EmptyStateContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  align-items: center;

  @media screen and (min-width: 1366px) {
    gap: 24px;
  }

  @media screen and (min-width: 1920px) {
    gap: 32px;
  }
`;

export const Img = styled.img`
  @media screen and (min-width: 1366px) {
    width: 236px;
  }

  @media screen and (min-width: 1920px) {
    width: 372px;
  }
`;

export const Title = styled(text)`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: ${props => props.theme.colors.highBlack};

  @media screen and (min-width: 1366px) {
    font-size: 20px;
    line-height: 27px;
  }

  @media screen and (min-width: 1920px) {
    font-size: 24px;
    line-height: 33px;
  }
`;

export const Text = styled(text)`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: ${props => props.theme.colors.highBlack};

  @media screen and (min-width: 1366px) {
    font-size: 14px;
    line-height: 19px;
  }

  @media screen and (min-width: 1920px) {
    font-size: 16px;
    line-height: 22px;
  }
`;
