import { forwardRef, useMemo } from 'react';
import propTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { queries } from '@kiper/monitoring-graphql/condominium/detail';
import { useTranslation } from 'react-i18next';
import { Label } from '@kiper/ui';
import Select from './styles';

const CondominiumAccessProfileSelect = forwardRef(
  function CondominiumAccessProfileSelect(
    { except, value, personContextId, editMode, invalid, ...props },
    ref,
  ) {
    const [t] = useTranslation('common');
    const { data, loading } = useQuery(queries.accessProfiles, {
      fetchPolicy: 'network-only',
      skip: !personContextId,
      variables: {
        personContextId,
        onlyNoInvites: true,
      },
    });

    const isNanAccessProfileValue = accessProfileValue => {
      const forcedValueToNumber = Number(accessProfileValue);
      return Number.isNaN(forcedValueToNumber);
    };

    const filteredList = accessProfile => {
      const accessProfileValueId = isNanAccessProfileValue(accessProfile)
        ? accessProfile.id
        : Number(accessProfile);

      return (
        accessProfileValueId === value || !except.includes(accessProfileValueId)
      );
    };

    const accessProfiles = useMemo(() => {
      if (!data) return [];
      return data.accessProfiles.filter(filteredList).map(accessProfile => ({
        value: accessProfile.id,
        label: accessProfile.name,
      }));
    }, [data, except]);

    const selectValue = useMemo(() => {
      if (!value || !accessProfiles?.length) return null;

      if (isNanAccessProfileValue(value)) return value;

      const accessProfileValueById =
        accessProfiles.find(
          accessProfile => accessProfile.value === Number(value),
        ) || null;

      if (ref?.current) {
        // eslint-disable-next-line no-param-reassign
        ref.current = {
          ...ref.current,
          value: accessProfileValueById,
        };
      }

      return accessProfileValueById;
    }, [value, accessProfiles]);

    return (
      <>
        {editMode ? (
          <Label>{(value && value.label) || t('loading')}</Label>
        ) : (
          <Select
            ref={ref}
            {...props}
            classNamePrefix="access-profile"
            value={selectValue}
            isDisabled={!personContextId}
            isLoading={loading}
            options={accessProfiles}
            invalid={Number(invalid)}
          />
        )}
      </>
    );
  },
);

CondominiumAccessProfileSelect.propTypes = {
  except: propTypes.array,
  value: propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
    propTypes.object,
  ]),
  personContextId: propTypes.number,
  editMode: propTypes.bool,
  invalid: propTypes.bool,
};

CondominiumAccessProfileSelect.defaultProps = {
  except: [],
  value: '',
  personContextId: undefined,
  editMode: false,
  invalid: false,
};

export default CondominiumAccessProfileSelect;
