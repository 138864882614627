import { KiperButton } from '@kiper/ui';
import propTypes from 'prop-types';
import { ALARM_COMMANDS } from '../settings';

const CommandPartitionActions = ({
  onChangeCommandPartition,
  loadingPartitionCommand,
  t,
}) => (
  <>
    <KiperButton
      variant="out"
      onClick={() =>
        onChangeCommandPartition({
          command: ALARM_COMMANDS.ACTIVATE,
        })
      }
      loading={loadingPartitionCommand}
      disabled={loadingPartitionCommand}
    >
      {t('activate-partition')}
    </KiperButton>
    <KiperButton
      variant="out"
      onClick={() =>
        onChangeCommandPartition({
          command: ALARM_COMMANDS.DEACTIVATE,
        })
      }
      loading={loadingPartitionCommand}
      disabled={loadingPartitionCommand}
    >
      {t('deactivate-partition')}
    </KiperButton>
  </>
);

export default CommandPartitionActions;

CommandPartitionActions.propTypes = {
  onChangeCommandPartition: propTypes.func.isRequired,
  t: propTypes.func.isRequired,
  loadingPartitionCommand: propTypes.bool.isRequired,
};
