import { useEffect, useState } from 'react';
import { dwellerValidationStatus } from '../../../../constants';

const useStepDwellerValidation = ({
  t,
  value,
  values,
  setFieldValue,
  setContextTree,
  setFieldTouched,
  invalidDwellerValidationSchema,
  handleGetValidationSchema,
  handleMaxCharacters,
}) => {
  const [fieldsValidated, setFieldsValidated] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [title, setTitle] = useState(
    t('step-dweller-validation.title', {
      name: values?.stepAttendanceGetName?.name,
    }),
  );

  const getOriginalDocumentValue = documentType => {
    return value('dweller').documents.find(doc => doc.type === documentType)
      ?.value;
  };

  const isDocumentValueChanged = (originalValue, inputValue) => {
    return originalValue && originalValue !== inputValue;
  };

  const removeValidatedField = documentType => {
    setFieldsValidated(fields =>
      fields.filter(field => field !== documentType),
    );
  };

  const addValidatedField = documentType => {
    setFieldsValidated(fields => {
      if (!fields.includes(documentType)) {
        return [...fields, documentType];
      }
      return fields;
    });
  };

  const updateDocumentsAndSchema = (documentType, inputValue) => {
    const document = { type: documentType, value: inputValue };
    setDocuments([document]);
    const schema = handleGetValidationSchema([document]);
    setContextTree({ formikSchema: schema });
  };

  const handleValidateDocument = e => {
    const { name, value: inputValue } = e.target;
    const documentType = name?.split('.')?.[1];
    const maxCharacters = handleMaxCharacters(documentType);

    if (inputValue?.length !== maxCharacters) return;

    const originalDocumentValue = getOriginalDocumentValue(documentType);

    if (isDocumentValueChanged(originalDocumentValue, inputValue)) {
      setFieldTouched(name, true);
      removeValidatedField(documentType);
    } else {
      addValidatedField(documentType);
      updateDocumentsAndSchema(documentType, inputValue);
      setFieldValue(
        'stepDwellerValidation.dwellerValidationStatus',
        dwellerValidationStatus.VALID,
      );
    }
  };

  const checkFieldBeenValidated = documentType =>
    fieldsValidated?.some(field => field === documentType);

  const handleSelectDweller = dweller => {
    setFieldValue('stepDwellerValidation', {
      dweller,
      dwellerValidationStatus: dwellerValidationStatus.NONE,
    });
  };

  const handleChangeInvalidValidationStatus = validationStatus => {
    const schema = invalidDwellerValidationSchema;
    setContextTree({
      formikSchema: schema,
    });
    setFieldValue(
      'stepDwellerValidation.dwellerValidationStatus',
      validationStatus,
    );
  };

  useEffect(() => {
    const dweller = value('dweller');
    if (dweller?.documents?.length) {
      dweller.documents.forEach(document => {
        const documentTypeValue = value(`${document.type}`);
        setFieldValue(
          `stepDwellerValidation.${document.type}`,
          documentTypeValue || '',
        );
      });
      const schema = handleGetValidationSchema(dweller?.documents);
      setContextTree({
        formikSchema: schema,
      });
      setDocuments(dweller?.documents);
    } else {
      setDocuments([]);
    }
  }, [value('dweller')]);

  const handleGenerateTitle = dwellerDocuments => {
    const typeMessages = {
      cpf: t('step-dweller-validation.documents.cpf-info'),
      rg: t('step-dweller-validation.documents.rg-info'),
      phone: t('step-dweller-validation.documents.phone-info'),
    };

    const availableTypes = dwellerDocuments
      .map(doc => doc.type)
      .filter(type => typeMessages[type]);

    if (availableTypes.length === 0) {
      return '';
    }

    const titleParts = availableTypes.map(type => typeMessages[type]);
    const formattedDocuments = titleParts.join(
      ` ${t('step-dweller-validation.or')} `,
    );
    const titleResult = t('step-dweller-validation.documents.inform-me', {
      documents: formattedDocuments,
    });

    return setTitle(titleResult);
  };

  useEffect(() => {
    if (documents.length) {
      handleGenerateTitle(documents);
    } else {
      setTitle(
        t('step-dweller-validation.title', {
          name: values?.stepAttendanceGetName?.name,
        }),
      );
    }
  }, [documents]);

  return {
    fieldsValidated,
    handleValidateDocument,
    handleSelectDweller,
    handleChangeInvalidValidationStatus,
    checkFieldBeenValidated,
    documents,
    title,
  };
};

export default useStepDwellerValidation;
