import styled from 'styled-components';
import { Card as card, Label as label } from '@kiper/ui';
import { Link } from 'react-router-dom';

export const Card = styled(card)`
  width: 560px;
  flex-direction: row;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  margin-right: 20px;
`;

export const CardHeader = styled(card.Header)`
  padding: 20px 10px;
  background: white;
`;

export const CardTitle = styled(card.Title)``;

export const CardBody = styled(card.Body)`
  display: flex;
  flex-direction: column;
`;

export const CardContent = styled.div`
  width: 100%;
`;

export const CardIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  background-color: ${props => props.theme.colors.primary200};

  svg {
    width: 32px;
    height: auto;
    fill: ${props => props.theme.colors.greenSurface};
    color: ${props => props.theme.colors.primary200};
  }
`;

export const AlertWrapper = styled.div`
  display: none;
`;

export const StyledLink = styled(Link)`
  font-family: Nunito Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: left;

  text-decoration-line: underline;
  color: ${props => props.theme.colors.linkPurple};
  display: inline-block;
  margin-bottom: 20px;
  &:hover ~ #${props => `${props.id}-alert`} {
    display: block;
  }
`;

export const Label = styled(label)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
`;

export const Alert = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${props => props.theme.colors.infoAlert};
  color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 10px;

  svg {
    min-width: 25px;
    height: auto;
  }
  label {
    margin: 0;
  }
`;
