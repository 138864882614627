import { apolloErrorHandler } from '@kiper/fns';
import { insertStepHistory } from '@kiper/monitoring-graphql/guided_attendance';
import { useContext } from 'react';
import { useMutation } from 'react-apollo';
import { useSwal } from '@kiper/hooks';
import { GuidedAttendanceContext } from '../store';

const useInsertStepHistory = () => {
  const { contextTree } = useContext(GuidedAttendanceContext);
  const { toast } = useSwal();

  const [stepHistory] = useMutation(insertStepHistory, {
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
  });

  const handleInsertStepHistory = (data, eventId) => {
    const stepId = contextTree?.currentStep?.id;

    return stepHistory({
      variables: {
        eventId,
        stepId,
        data: JSON.stringify(data),
      },
    });
  };

  return { handleInsertStepHistory };
};

export default useInsertStepHistory;
