import { useRef, useEffect, useState, useContext } from 'react';
import { Link, Redirect } from 'react-router-dom';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Loader, KiperButton } from '@kiper/ui';
import { FormikProvider, useFormik } from 'formik';
import { MdEdit } from 'react-icons/md';
import * as yup from 'yup';
import { useMutation, useLazyQuery, useQuery } from 'react-apollo';
import { useSwal } from '@kiper/hooks';
import {
  systemUser as systemUserGql,
  applicationProfile as applicationProfileGql,
} from '@kiper/monitoring-graphql';
import {
  apolloErrorHandler,
  removeProperties,
  runtimeConfig,
} from '@kiper/fns';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import { ThemeContext } from 'styled-components';
import UserInfo from './UserInfo';
import {
  BlockFullWidth,
  H5,
  Card,
  CardHeader,
  CardBody,
  Flex,
  FooterActions,
  Form,
} from './styles';
import useViewRules from '../../../hooks/useViewRules';
import useCurrentLoggedContext from '../../../hooks/useCurrentLoggedContext';
import PageHeader from '../../../components/PageHeader';

const AT_LEAST_TWO_WORDS = /(\w.+\s).+/gi;

export default function UserPut({ history, match, route }) {
  const [t] = useTranslation('system-user');

  const createNewOne = useRef(false);
  const { toast } = useSwal();
  const theme = useContext(ThemeContext);
  const [editMode, setEditMode] = useState(false);
  const { personId } = match.params;
  const { loggedContext } = useCurrentLoggedContext();
  const sourceTreeNodeId = loggedContext.topNodeId;

  const isEdition = !!personId;

  const applicationProfiles = useQuery(
    applicationProfileGql.applicationProfiles,
    {
      variables: {
        applicationKey: runtimeConfig.APPLICATION_KEY_MONITORING,
      },
    },
  );

  const { permission, loading: loadingPermission } = useViewRules({
    path: 'systemUsersManagement',
  });

  if (
    !loadingPermission &&
    (!permission || !permission.ableToRead || !permission.ableToWrite)
  )
    return <Redirect to="/" />;

  const profiles =
    applicationProfiles?.data?.applicationProfiles?.map(
      ({ profile: { fieldName, id, name } }) => ({
        label: t(`profiles.${fieldName}`, name),
        value: id,
        fieldName,
      }),
    ) ?? [];

  const validationSchema = yup.object({
    name: yup
      .string()
      .test({
        message: t('feedback.name-two-words'),
        test: x => x && x.match(AT_LEAST_TWO_WORDS),
      })
      .required(t('common:feedback.required-field')),
    phone: yup
      .string()
      .test({
        message: t('feedback.phone'),
        test: a => a && isPossiblePhoneNumber(a.replace(/\s/gi, '')),
      })
      .required(t('common:feedback.required-field')),
    email: yup
      .string()
      .email(t('feedback.email'))
      .matches(/^[\d\w@.\-/+]*$/, t('feedback.email')) // yup's email won't validate some characters
      .required(t('common:feedback.required-field')),
    profileId: yup
      .mixed()
      .test(
        'is-valid-profile-id',
        t('common:feedback.required-field'),
        profileId =>
          profiles
            .map(profile => Number(profile.value))
            .includes(Number(profileId)),
      )
      .required(t('common:feedback.required-field')),
    parentTreeNodeId: yup.mixed().when({
      // eslint-disable-next-line no-use-before-define
      is: () => needSetOperatedCondo,
      then: yup.mixed().required(t('common:feedback.required-field')),
      otherwise: yup.mixed(),
    }),
  });

  const onSubmit = async values => {
    // eslint-disable-next-line no-use-before-define
    const mutation = shouldCreate ? create : update;
    const result = await mutation({
      variables: {
        user: removeProperties(
          {
            ...values,
            parentTreeNodeId: values.parentTreeNodeId ?? sourceTreeNodeId,
          },
          ['__typename'],
        ),
      },
    });

    // eslint-disable-next-line no-use-before-define
    const opName = shouldCreate ? 'systemUserCreate' : 'systemUserUpdate';
    const systemUser = result.data[opName];

    // eslint-disable-next-line no-use-before-define
    setValues({ systemUser });
  };

  const formikBag = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      name: '',
      email: '',
      phone: '',
      profileId: null,
      personContextId: null,
      parentTreeNodeId: null,
    },
  });

  const shouldCreate = !formikBag.values.personContextId;

  const { manageSystemUserProfiles } =
    permission?.applicationFeature?.params ?? {};

  const {
    visible: canSetOperatedCondo = false,
    required: needSetOperatedCondo = false,
  } = manageSystemUserProfiles?.[formikBag.values.profileId]?.condominium ?? {};

  const handleEditMode = () => setEditMode(x => !x);

  const onSaveSuccess = () =>
    toast.fire({ icon: 'success', title: t('put.success-message') });

  const onError = err => {
    const formattedErrors = apolloErrorHandler(err);
    if (formattedErrors && formattedErrors.length) {
      toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
  };

  const [create] = useMutation(systemUserGql.systemUserCreate, {
    onCompleted: () => {
      onSaveSuccess();

      if (isEdition && shouldCreate) {
        handleEditMode();
      } else {
        if (!createNewOne.current) history.goBack();
        createNewOne.current = false;
        setTimeout(() => formikBag.handleReset(), 0);
      }
    },
    onError,
  });

  const [update] = useMutation(systemUserGql.systemUserUpdate, {
    onCompleted: () => {
      onSaveSuccess();
      handleEditMode();
    },
    onError,
  });

  const setValues = ({ systemUser }) => {
    const { condominium, ...user } = systemUser;
    formikBag.setValues({
      ...user,
      parentTreeNodeId: condominium?.treeNodeId ?? sourceTreeNodeId,
    });
  };

  const [fetchById, { loading, refetch: refetchById }] = useLazyQuery(
    systemUserGql.systemUser,
    {
      fetchPolicy: 'network-only',
      onCompleted: setValues,
      onError,
    },
  );

  const handleCreateNewOne = e => {
    createNewOne.current = true;
    formikBag.handleSubmit(e);
  };

  useEffect(() => {
    if (isEdition)
      fetchById({
        variables: { personId: Number(personId) },
      });
  }, [isEdition]);

  useEffect(() => {
    if (!canSetOperatedCondo) formikBag.setFieldValue('parentTreeNodeId', null);
  }, [formikBag.values.profileId]);

  const pageTitle = isEdition ? 'put.edit-title' : 'put.create-title';

  return (
    <BlockFullWidth>
      <PageHeader breadcrumb={route.breadcrumb} t={t} title={t(pageTitle)} />
      <FormikProvider value={formikBag}>
        <Form className="form" onSubmit={formikBag.handleSubmit} noValidate>
          <Card>
            <CardHeader>
              <H5>{t('put.main-info')}</H5>
              {!loading && !editMode && isEdition && (
                <KiperButton
                  onClick={handleEditMode}
                  variant="text"
                  color="secondary"
                  rounded
                  data-test="edit-icon"
                >
                  <MdEdit color={theme.colors.secondary500} />
                </KiperButton>
              )}
            </CardHeader>
            <CardBody>
              {loading ? (
                <Loader />
              ) : (
                <UserInfo
                  isEdition={isEdition}
                  handleEditMode={handleEditMode}
                  editMode={editMode}
                  onCancel={() => {
                    handleEditMode();
                    refetchById()
                      .then(({ data }) => setValues(data))
                      .catch(() => window.location.reload());
                  }}
                  profiles={profiles}
                  isLoading={loading}
                  shouldCreate={shouldCreate}
                  canSetOperatedCondo={canSetOperatedCondo}
                  needSetOperatedCondo={needSetOperatedCondo}
                />
              )}
            </CardBody>
          </Card>
          {!loading && (
            <>
              {!isEdition && (
                <FooterActions>
                  <Flex>
                    <Link to="/system-users">
                      <KiperButton
                        type="button"
                        outline
                        color="secondary"
                        className="mr-3"
                      >
                        {t('put.cancel')}
                      </KiperButton>
                    </Link>

                    <KiperButton
                      type="button"
                      className="mr-3"
                      color="primary"
                      outline
                      disabled={formikBag.isSubmitting}
                      loading={formikBag.isSubmitting && createNewOne.current}
                      name="save-and-create-new"
                      onClick={handleCreateNewOne}
                    >
                      {t('put.save-and-create-a-new')}
                    </KiperButton>
                    <KiperButton
                      type="submit"
                      name="save"
                      color="primary"
                      loading={formikBag.isSubmitting && !createNewOne.current}
                      disabled={formikBag.isSubmitting}
                    >
                      {t(formikBag.isSubmitting ? 'put.saving' : 'put.save')}
                    </KiperButton>
                  </Flex>
                </FooterActions>
              )}
            </>
          )}
        </Form>
      </FormikProvider>
    </BlockFullWidth>
  );
}

UserPut.propTypes = {
  history: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
  route: propTypes.object.isRequired,
};
