import propTypes from 'prop-types';
import { Flex, KiperButton } from '@kiper/ui';
import { WeekContainer, LabelValue } from './styles';

const keys = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

const DayOfWeek = ({
  pressedWeek,
  indexInterval,
  label,
  day,
  active,
  disabled,
}) => (
  <Flex mr="4px">
    <KiperButton
      icon={<span>{label}</span>}
      variant={active ? 'contained' : 'out'}
      onClick={() => {
        pressedWeek(indexInterval, day);
      }}
      disabled={disabled}
    />
  </Flex>
);

const Week = ({
  pressedWeek,
  index,
  actives,
  color,
  disabled,
  onlyLabels,
  t,
  ...props
}) => {
  const mapActiveDays = data =>
    data.reduce((result, actived, i) => {
      if (actived) result.push(i);

      return result;
    }, []);

  return (
    <WeekContainer {...props} onlyLabels={onlyLabels} disabled={disabled}>
      {onlyLabels && disabled ? (
        <>
          {mapActiveDays(actives).map((activedIndex, i, arr) => (
            <LabelValue key={activedIndex}>
              {`${t(`common:week.${keys[activedIndex]}`)}${
                arr.length !== i + 1 ? ',' : ''
              }`}
            </LabelValue>
          ))}
        </>
      ) : (
        <>
          {actives.map((actived, i) => (
            <DayOfWeek
              pressedWeek={pressedWeek}
              indexInterval={index}
              day={i}
              label={t(`common:week.${keys[i]}`)}
              active={actived}
              key={String(i)}
              color={color}
              disabled={disabled}
            />
          ))}
        </>
      )}
    </WeekContainer>
  );
};

Week.propTypes = {
  pressedWeek: propTypes.func.isRequired,
  index: propTypes.number.isRequired,
  actives: propTypes.arrayOf(propTypes.bool).isRequired,
  color: propTypes.string.isRequired,
  disabled: propTypes.bool.isRequired,
  onlyLabels: propTypes.bool,
  t: propTypes.func.isRequired,
};

Week.defaultProps = {
  onlyLabels: false,
};

DayOfWeek.propTypes = {
  pressedWeek: propTypes.func.isRequired,
  indexInterval: propTypes.number.isRequired,
  label: propTypes.string.isRequired,
  day: propTypes.number.isRequired,
  active: propTypes.bool.isRequired,
  disabled: propTypes.bool.isRequired,
};

export default Week;
