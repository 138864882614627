import useRemoteConfig from '../useRemoteConfig';

const remoteConfigs = [];

const useReduceRemoteConfigFlags = (
  loggedContext,
  entityPaths,
  condominium = null,
) => {
  const paths = Object.values(entityPaths);

  for (let i = 0; i < paths.length; i++) {
    const path = paths[i];

    const config = useRemoteConfig({
      path,
      partner: Number(loggedContext?.partner?.personContextId),
      condominium: condominium && Number(condominium?.personContextId),
      user: Number(loggedContext?.personContextId),
    });

    remoteConfigs.push({ path, config });
  }

  return remoteConfigs.reduce((acc, { path, config }) => {
    acc[path] = config;
    return acc;
  }, {});
};

export default useReduceRemoteConfigFlags;
