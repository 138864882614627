import styled from 'styled-components';
import { Flex } from '@kiper/ui'

export const Container = styled(Flex)`
flex-direction: column;
overflow: auto;
@media screen and (max-height: 1440px) {
    max-height: 1222px;
  }
  @media screen and (max-height: 1080px) {
    max-height: 842px;
  }
  @media screen and (max-height: 900px) {
    max-height: 662px;
  }
  @media screen and (max-height: 768px) {
    max-height: 530px;
  }
  @media screen and (max-height: 720px) {
    max-height: 485px;
  }
  @media screen and (max-height: 700px) {
    max-height: 465px;
  }
  @media screen and (max-height: 625px) {
    max-height: 390px;
  } 
`;
