import styled, { css, keyframes } from 'styled-components';
import {
  Card as card,
  Badge as badge,
  KiperButton,
  Flex,
  Text,
} from '@kiper/ui';
import { FiLoader as loaderIcon } from 'react-icons/fi';
import { Row as row, Col as col } from 'reactstrap';
import {
  MdCheckCircle as checkIcon,
  MdErrorOutline as errorIcon,
} from 'react-icons/md';

const hideWhenPrint = `
  @media print {
    display: none;
    height: 0;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const fontSize = css`
  @media print {
    font-size: 10px;
  }
  @media screen {
    font-size: 14px;
  }
`;

export const Tr = styled.tr`
  ${props =>
    !props.last
      ? css`
          border-bottom: 1px solid ${props.theme.colors.secondary100};
        `
      : css`
          border-bottom: 0;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        `}
`;

export const Td = styled.td`
  white-space: pre-wrap;
  max-width: 0;
  padding: 5px 10px;
`;

export const DataContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const ColumnData = styled.span`
  ${fontSize};
  max-width: 100%;
  overflow-wrap: break-word;
  color: ${props => props.theme.colors.highBlack};
  ${props =>
    props.link &&
    css`
      text-decoration: underline;
    `}
`;

export const Badge = styled(badge)`
  ${hideWhenPrint};
`;

export const ProfileData = styled(ColumnData)`
  @media screen {
    display: none;
  }
`;

export const Card = styled(card)`
  border: 1px solid ${props => props.theme.colors.secondary100};
  @media print {
    padding: 0;
  }
`;

export const Overlay = styled.div`
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  position: absolute;
  z-index: 1;
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 1) 100%
  );

  @media print {
    display: none;
    height: 0;
    width: 0;
    z-index: 0;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 30%;
`;

export const ReportStateText = styled.span`
  font-size: 18px;
  font-weight: 400;
  color: ${props => props.theme.colors.highBlack};
  margin: 16px;
`;

export const LoaderIcon = styled(loaderIcon)`
  font-size: 40px;
  margin: 16px;
  animation: ${rotate} 2s linear infinite;
`;

export const CheckIcon = styled(checkIcon)`
  margin: 20px 0;
  font-size: 40px;
`;

export const ErrorIcon = styled(errorIcon)`
  font-size: 60px;
`;

export const HeaderContainer = styled.div`
  @media screen {
    display: none;
    height: 0;
  }
`;

export const Row = styled(row)``;

export const Col = styled(col)``;

export const Label = styled.span`
  ${props =>
    props.bold
      ? css`
          font-weight: 700;
        `
      : null};
  font-size: ${props => props.fontSize ?? 10}px;
  margin: 0;
`;

export const Button = styled(KiperButton)`
  width: auto;
  @media print {
    display: none;
    width: 0;
  }
`;

export const Container = styled(Flex)`
  align-items: center;
  justify-content: ${props =>
    props.hasContent ? 'space-between' : 'flex-end'};
`;

export const Ul = styled.ul``;

export const Li = styled.li`
  line-height: 1;
`;

export const ContentText = styled(Text)`
  font-size: 12px;
  color: ${props => props.theme.colors.mediumBlack};
`;
