import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useCurrentLoggedContext from '../../../../hooks/useCurrentLoggedContext';
import {
  Col,
  Label,
  ErrorMessage,
  CondominiumSelect,
  UnityPersonSelect,
  ProfileSelect,
  AccessProfileSelect,
  EventCategorySelect,
  PlaceSelect,
  Input,
  DatePeriodWrapper,
  FlexCol,
  DateTimePicker,
} from './styles';
import { placeTypes } from '../../../../constants';
import { ReportUnityPerson } from '../../../../components/Select';

const ReportFilter = ({ type, formProps, formType, setHeaderFilters }) => {
  const [t] = useTranslation('report');
  const { loggedContext } = useCurrentLoggedContext();

  const { values, setFieldValue, errors, touched } = formProps;

  const handleChangeCondominiumTreeNodeIdVehicle = event => {
    const data = event?.value ? +event.value : null;
    setFieldValue(type, data);
    setHeaderFilters(x => ({ ...x, [type]: event?.label }));
  };

  switch (type) {
    case 'condominiumTreeNodeId':
      return (
        <Col xs={2} sm={2} md={2}>
          <Label>{t('details.condominium')}</Label>
          <CondominiumSelect
            value={values[type]}
            name={type}
            placeholder={t('details.select-condominium')}
            sourceNodeId={loggedContext.topNodeId}
            fieldName={['condominium']}
            isClearable
            onChange={event => {
              const data = event?.value ? +event.value : null;
              setFieldValue(type, data);
              setHeaderFilters(x => ({ ...x, [type]: event?.label }));
            }}
            invalid={touched[type] && !!errors[type]}
          />
          {touched[type] && !!errors[type] && (
            <ErrorMessage>{t('details.feedback-condominium')}</ErrorMessage>
          )}
        </Col>
      );
    case 'nodeId':
      return (
        <Col width="420px" xs={4} sm={4} md={4}>
          <Label>{t('details.unity-dweller')}</Label>
          <ReportUnityPerson
            value={values[type]}
            handleChange={event => {
              const data = event?.value ? +event.value : null;
              setFieldValue(type, data);
              setHeaderFilters(x => ({ ...x, [type]: event?.label }));
            }}
            placeholder={t('details.unity-dweller-placeholder')}
            sourceNodeId={values.condominiumTreeNodeId}
          />
        </Col>
      );
    case 'initDate-endDate':
      return (
        <Col width="520px" xs={6} sm={6} md={5}>
          <DatePeriodWrapper>
            <FlexCol>
              <Label>{t('details.initial-date-label')}</Label>
              <DateTimePicker
                value={formProps.dates.initDate}
                onChange={value =>
                  formProps.setDateValue({ name: 'initDate', value })
                }
              />
            </FlexCol>
            <FlexCol>
              <Label>{t('details.end-date-label')}</Label>
              <DateTimePicker
                value={formProps.dates.endDate}
                onChange={value =>
                  formProps.setDateValue({ name: 'endDate', value })
                }
              />
            </FlexCol>
          </DatePeriodWrapper>
          {formProps.isInvalidRange && (
            <ErrorMessage>{t('details.feedback-invalid-range')}</ErrorMessage>
          )}
        </Col>
      );
    case 'profileIds':
      return (
        <Col width="420px" xs={4} sm={4} md={4}>
          <Label info block>
            {t('details.profile-user')}
          </Label>
          <ProfileSelect
            isMulti
            placeholder={t('details.select-profile')}
            name={type}
            isEditable
            value={values[type]}
            onChange={data => {
              setFieldValue(type, data);
              setHeaderFilters(x => ({
                ...x,
                [type]: data?.map(i => i?.label),
              }));
            }}
            isDisabled={!values.condominiumTreeNodeId}
          />
        </Col>
      );
    case 'accessProfileIds':
      return (
        <Col width="420px" xs={4} sm={4} md={4}>
          <Label info block>
            {t('details.access-profile')}
          </Label>
          <AccessProfileSelect
            isMulti
            isClearable
            placeholder={t('details.select-profile')}
            name={type}
            value={values[type]}
            onChange={data => {
              setFieldValue(type, data);
              setHeaderFilters(x => ({
                ...x,
                [type]: data?.map(i => i?.label),
              }));
            }}
            sourceNodeId={values.condominiumTreeNodeId}
            isDisabled={!values.condominiumTreeNodeId}
          />
        </Col>
      );
    case 'eventTypes':
      return (
        <Col width="420px" xs={4} sm={4} md={4}>
          <Label info block>
            {t('details.event-select')}
          </Label>
          <EventCategorySelect
            isMulti
            name={type}
            setFieldValue={data => {
              setFieldValue(type, data);
            }}
            setFieldLabel={data =>
              setHeaderFilters(x => ({ ...x, [type]: data }))
            }
            isDisabled={!values.condominiumTreeNodeId}
          />
        </Col>
      );
    case 'sourceNodeId':
      return (
        <Col width="300px" xs={2} sm={2} md={2}>
          <Label info block>
            {t('details.unity-select')}
          </Label>
          <UnityPersonSelect
            hasPerson={false}
            value={values[type]}
            handleChange={event => {
              const data = event?.value ? +event.value : null;
              setFieldValue(type, data);
              setHeaderFilters(x => ({ ...x, [type]: event?.label }));
            }}
            placeholder={t('details.unity-select-placeholder')}
            sourceNodeId={values.condominiumTreeNodeId}
          />
        </Col>
      );
    case 'placeId':
      return (
        <Col xs={2} sm={2} md={2}>
          <Label info block>
            {t('details.place-select')}
          </Label>
          <PlaceSelect
            isClearable
            name={type}
            value={values[type]}
            placeholder={t('details.place-select-placeholder')}
            sourceNodeId={values.condominiumTreeNodeId}
            usePlaceId
            fieldName={[
              placeTypes.vehicleGate,
              placeTypes.floodgate,
              placeTypes.commonArea,
              placeTypes.securityCabin,
              placeTypes.walkerGate,
              placeTypes.machineRoom,
            ]}
            isDisabled={!values.condominiumTreeNodeId}
            onChange={event => {
              const data = event?.value ? +event.value : null;
              setFieldValue(type, data);
              setHeaderFilters(x => ({ ...x, [type]: event?.label }));
            }}
          />
        </Col>
      );
    case 'searchText':
      return (
        <Col xs={2} sm={2} md={2}>
          <Label>
            {formType === 'usersReport'
              ? t('details.contact-detail')
              : t('details.register')}
          </Label>
          <Input
            name={type}
            value={values[type]}
            onChange={(field, val) => {
              setFieldValue(field, val);
              setHeaderFilters(x => ({ ...x, [type]: val }));
            }}
            placeholder={
              formType === 'usersReport'
                ? t('details.contact-detail-placeholder')
                : t('details.register-placeholder')
            }
            disabled={!values.condominiumTreeNodeId}
          />
        </Col>
      );
    case 'accessDeviceId':
      return (
        <Col xs={2} sm={2} md={2}>
          <Label info block>
            {t('details.access-device')}
          </Label>
          <Input
            name={type}
            value={values[type]}
            onChange={(field, val) => {
              setFieldValue(field, val);
              setHeaderFilters(x => ({ ...x, [type]: val }));
            }}
            placeholder={t('details.access-device-placeholder')}
            disabled={!values.condominiumTreeNodeId}
          />
        </Col>
      );
    case 'condominiumTreeNodeIdVehicle':
      return (
        <Col xs={2} sm={2} md={2}>
          <Label>{t('details.condominium')}</Label>
          <CondominiumSelect
            value={values[type]}
            name={type}
            placeholder={t('details.select-condominium')}
            sourceNodeId={loggedContext.topNodeId}
            fieldName={['condominium']}
            isClearable
            onChange={handleChangeCondominiumTreeNodeIdVehicle}
            invalid={touched[type] && !!errors[type]}
          />
          {touched[type] && !!errors[type] && (
            <ErrorMessage>{t('details.feedback-condominium')}</ErrorMessage>
          )}
        </Col>
      );
    case 'searchVehiclePlate':
      return (
        <Col xs={2} sm={2} md={2}>
          <Label>{t('details.search-plate')}</Label>
          <Input
            name={type}
            value={values[type]}
            onChange={(field, val) => {
              setFieldValue(field, val);
              setHeaderFilters(x => ({ ...x, [type]: val }));
            }}
            placeholder={t('details.search-plate')}
            disabled={!values.condominiumTreeNodeIdVehicle}
          />
        </Col>
      );
    default:
      return null;
  }
};

ReportFilter.propTypes = {
  type: PropTypes.string.isRequired,
  formProps: PropTypes.object.isRequired,
  formType: PropTypes.string,
  setHeaderFilters: PropTypes.func,
};
ReportFilter.defaultProps = {
  formType: null,
  setHeaderFilters: null,
};

export default ReportFilter;
