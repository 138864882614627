/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react';
import propTypes from 'prop-types';

import { Flex, Text, UserAvatar } from '@kiper/ui';
import {
  Container,
  Header,
  Body,
  HeaderContainer,
  AvatarContainer,
  ListMenuItem,
  CollapseIcon,
  ExpandIcon,
} from './styles';
import logoImageKiper from '../../assets/images/white-logo-kiper.svg';
import logoImage from '../../assets/images/white-logo.svg';
import MenuOptions from './MenuOptions';
import SwitchContext from '../SwitchContext';
import useCurrentLoggedContext from '../../hooks/useCurrentLoggedContext';

const Menu = ({ inAttendancePage, isCollapsed, toggleCollapse }) => {
  const [isMouseIn, setMouseIn] = useState(false);

  const { loggedContext } = useCurrentLoggedContext();

  useEffect(() => {
    if (inAttendancePage && !isCollapsed) toggleCollapse();
  }, [inAttendancePage]);

  return (
    <Container
      onMouseEnter={() => setMouseIn(true)}
      onMouseLeave={() => setMouseIn(false)}
      isMouseIn={isMouseIn}
      isCollapsed={isCollapsed}
    >
      <Header isCollapsed={isCollapsed} cursor={Number(!inAttendancePage)}>
        <HeaderContainer
          isCollapsed={isCollapsed}
          isMouseIn={isMouseIn}
          inAttendancePage={inAttendancePage}
        >
          <img
            height={isCollapsed && !isMouseIn ? 32 : 25}
            src={isCollapsed && !isMouseIn ? logoImage : logoImageKiper}
            alt="kiper"
          />
        </HeaderContainer>
      </Header>
      <Body isMouseIn={isMouseIn}>
        <AvatarContainer>
          <UserAvatar
            src={`//avatar.kiper.com.br/${loggedContext &&
              loggedContext.email}`}
            size={30}
            name={loggedContext && loggedContext.name}
            round="100%"
          />
          {(!isCollapsed || isMouseIn) && (
            <Flex ml="5px" flexDirection="column">
              <Text fontSize="14px" fontWeight="600">
                {loggedContext && loggedContext.name}
              </Text>
              {loggedContext && (
                <div>
                  <SwitchContext />
                  <Text fontSize="12px" color="secondary600">
                    {loggedContext.profileName}
                  </Text>
                </div>
              )}
            </Flex>
          )}
        </AvatarContainer>
        <MenuOptions isMouseIn={isMouseIn} isCollapsed={isCollapsed} />
        <Flex pb="40px" flex={1} alignItems="flex-end">
          {!inAttendancePage && (
            <ListMenuItem onClick={!inAttendancePage ? toggleCollapse : null}>
              {!isCollapsed ? <CollapseIcon /> : <ExpandIcon />}
              {(!isCollapsed || isMouseIn) && (
                <Text fontSize="12px" color="secondary800">
                  {isCollapsed ? 'Expandir menu' : 'Ocultar menu'}
                </Text>
              )}
            </ListMenuItem>
          )}
        </Flex>
      </Body>
    </Container>
  );
};

export default Menu;

Menu.propTypes = {
  inAttendancePage: propTypes.bool,
  isCollapsed: propTypes.bool,
  toggleCollapse: propTypes.func.isRequired,
};

Menu.defaultProps = {
  inAttendancePage: false,
  isCollapsed: false,
};
