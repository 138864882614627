import styled from 'styled-components';
import {
  Nav as RSNav,
  NavItem as RSNavItem,
  NavLink as RSNavLink,
  TabContent as RSTabContent,
  TabPane as RSTabPane,
} from 'reactstrap';

export const Nav = styled(RSNav)``;
export const NavItem = styled(RSNavItem)``;
export const NavLink = styled(RSNavLink)`
  cursor: pointer;

  &.active {
    background-color: ${props => props.theme.colors.primary500} !important;
  }
`;
export const TabContent = styled(RSTabContent)`
  height: 100%;
`;
export const TabPane = styled(RSTabPane)`
  height: 100%;
`;
