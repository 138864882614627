import propTypes from 'prop-types';
import { Button, Flex, PlaceHolder, Text, Tooltip } from '@kiper/ui';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiCheck, FiPlus } from 'react-icons/fi';
import { EventAttendanceContext } from '../../../store';
import { EmptyStateIcon } from '../../../components/EmptyStateIcon';
import { useCurrentLoggedContext } from '../../../hooks';
import EventCard from '../../../components/SimplifiedEventList/EventCard';
import { useCreateNewAttendance } from './hooks';
import { EventQueueContainer, Scrollbar } from './styles';

const loadingStyle = {
  height: '85px',
  width: '194px',
  margin: '8px',
  padding: '8px',
  borderRadius: '4px',
};

const EventAttendanceQueue = ({ toggleNewEventModal }) => {
  const { eventQueue, eventInAttendance, loadingQueue } = useContext(
    EventAttendanceContext,
  );
  const [t] = useTranslation('event_attendance');
  const { loggedContext } = useCurrentLoggedContext();
  const {
    handleActionToCreateNewAttendance,
    loading,
  } = useCreateNewAttendance({ loggedContext });

  const { events, inAttendance } = useMemo(
    () => ({
      events: eventQueue,
      inAttendance: !!eventInAttendance && eventInAttendance,
    }),
    [eventQueue],
  );

  return (
    <EventQueueContainer>
      {inAttendance && <EventCard event={inAttendance} margin="8px" />}
      <Flex
        m="12px 8px"
        height="34px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text
          truncate
          fontWeight="bold"
          fontSize={14}
          lineHeight="20px"
          color="primary500"
        >
          {t('attendanceQueue')}
        </Text>

        <Button
          id="add-new-attendance"
          icon={<FiPlus />}
          disabled={!!inAttendance || loading}
          onClick={() => handleActionToCreateNewAttendance(toggleNewEventModal)}
          variant="out"
          size="xs"
          rounded
        />
        <Tooltip placement="bottom" target="add-new-attendance">
          {t('add-new-attendance')}
        </Tooltip>
      </Flex>

      <Scrollbar>
        {loadingQueue && (
          <PlaceHolder style={loadingStyle} type="line" lines={5} />
        )}
        {!loadingQueue && !events.length && (
          <EmptyStateIcon
            m="56px 8px 8px 8px"
            icon={FiCheck}
            title={t('no-events')}
            text={t('no-events-detailed')}
          />
        )}

        {!loadingQueue &&
          events.map(event => <EventCard key={event.eventId} event={event} />)}
      </Scrollbar>
    </EventQueueContainer>
  );
};

export default EventAttendanceQueue;

EventAttendanceQueue.propTypes = {
  toggleNewEventModal: propTypes.func.isRequired,
};
