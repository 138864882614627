import styled, { css } from 'styled-components';
import { Alert as alert } from '@kiper/ui';

export const ContentBody = styled.div`
  height: calc(100vh - 60px);
  padding: 0 25px 24px;
  overflow-y: auto;
  ${props =>
    props.isAttendancePage &&
    css`
      height: 100%;
      padding: 0;
      overflow: hidden;
    `}
  @media print {
    overflow: initial;
    @page {
      size: A4 landscape;
    }
  }
`;

export const Alert = styled(alert)`
  height: 60px;
  margin: 0;
  border-radius: 0;

  svg {
    width: 30px;
    height: 30px;
  }
`;
